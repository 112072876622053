import { RevokeContractActionEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    action = 'action',

    nextContractId = 'next-contract-id',

    trucksRatePerKm = 'trucks-rate-per-km',
    boxesRatePerKm = 'boxes-rate-per-km',
    tiltsRatePerKm = 'tilts-rate-per-km',
}

export type FormValuesT = {
    [FieldsEnum.action]: RevokeContractActionEnum;

    [FieldsEnum.nextContractId]: CarrierContractIdT | null;

    [FieldsEnum.trucksRatePerKm]: string;
    [FieldsEnum.boxesRatePerKm]: string;
    [FieldsEnum.tiltsRatePerKm]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
