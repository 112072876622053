import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigSearchIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M15 20h170v90H15z" />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.05"
                    d="M176 20a7 7 0 110 14h-40a7 7 0 110 14h22a7 7 0 110 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 110 14H62a7 7 0 110-14H23a7 7 0 110-14h40a7 7 0 100-14H38a7 7 0 110-14h40a7 7 0 110-14h98zm0 28a7 7 0 110 14 7 7 0 010-14z"
                />
                <g strokeWidth="2.5" transform="translate(56 21)">
                    <circle cx="33.5" cy="33.5" r="33.5" fill="#8ABA2E" fillOpacity="0.3" stroke="#8ABA2E" />
                    <path
                        fill="#FFF"
                        stroke="#8ABA2E"
                        strokeLinecap="round"
                        d="M28.132 59.494c1.759.325 3.548.493 5.368.506C48.136 60 60 48.136 60 33.5S48.136 7 33.5 7c-3.76 0-7.336.783-10.576 2.195A26.605 26.605 0 009.896 21.442 26.391 26.391 0 007 33.5a26.403 26.403 0 006.684 17.596"
                    />
                    <path
                        stroke="#8ABA2E"
                        strokeLinecap="round"
                        d="M16.797 54.075a26.491 26.491 0 007.204 4.172M34 15c-1.723 0-3.392.23-4.979.659C20.943 17.847 15 25.229 15 34"
                    />
                    <path stroke="#8ABA2E" d="M61 60l6 6" />
                    <path
                        fill="#8ABA2E"
                        fillOpacity="0.3"
                        stroke="#8ABA2E"
                        d="M69.457 63.61c1.24 0 2.481.474 3.427 1.42h0L83.97 76.116a4.832 4.832 0 011.42 3.427c0 1.24-.474 2.48-1.42 3.427a4.835 4.835 0 01-3.427 1.42 4.833 4.833 0 01-3.427-1.42h0L66.03 71.884a4.832 4.832 0 01-1.42-3.427c0-1.24.474-2.48 1.42-3.427a4.835 4.835 0 013.427-1.42z"
                    />
                    <path stroke="#FFF" strokeLinecap="round" d="M71 67l11 11" />
                </g>
                <path
                    stroke="#8ABA2E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M149.5 47h-17.176m24.176 0h-2.221m-11.103 7.777H135M48.5 68H31.324M25.5 68h-4.221m31.897 8.777H45"
                />
            </g>
        </svg>
    );
};

BigSearchIcon.displayName = 'BigSearchIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigSearchIcon;
