import { SharableStateT } from '../models';
import { initialStateMachineItemState, StateMachineItemStateT } from './slice';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { getStateMachineEntityHash } from 'common/store/state-machine/utils';

export const selectStateMachineItemState = <T extends string>(descriptor: StateMachineEntityDescriptorT | null) => {
    return (state: SharableStateT): StateMachineItemStateT<T> => {
        if (!descriptor) {
            // @ts-expect-error T is generic
            return initialStateMachineItemState;
        }

        const hash = getStateMachineEntityHash(descriptor);

        // @ts-expect-error T is generic
        return state.stateMachine[hash] || initialStateMachineItemState;
    };
};
