import { OrdersStateT } from './types';
import { SharableStateT } from '../models';

export const selectOrdersPages = (state: SharableStateT): OrdersStateT['pages'] => state.orders.pages;

export const selectOrdersQuery = (state: SharableStateT): OrdersStateT['query'] => state.orders.query;

export const selectOrdersTotal = (state: SharableStateT): OrdersStateT['total'] => state.orders.total;

export const selectOrdersByIds = (state: SharableStateT): OrdersStateT['byId'] => state.orders.byId;
