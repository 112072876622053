import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './Identifier.scss';

const cx = classNames.bind(styles);

type PropsT = {
    id: React.ReactNode;
};

const Identifier: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    return <span className={cx('identifier')}>{id}</span>;
});

export default Identifier;
