import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ModalContent.scss';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import { KeyEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    testSelector?: string;
};

const ModalContent: React.FC<PropsT> = (props) => {
    const { children, onClose, testSelector } = props;

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === KeyEnum.escape) {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className={cx('modal')}>
            <OutsideClickHandler onOutsideClick={onClose}>
                <div className={cx('inner')} data-test-selector={`${testSelector}_modal`}>
                    {children}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default ModalContent;
