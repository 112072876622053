import { QueryFiltersT } from '../query-models';
import { FieldsEnum, FormValuesT } from './constants';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.dictTrailerId]: null,

        [FieldsEnum.address]: null,

        [FieldsEnum.validFromDates]: null,
        [FieldsEnum.validTillDates]: null,

        [FieldsEnum.createdDates]: null,

        [FieldsEnum.createdById]: null,
        [FieldsEnum.createdByName]: null,
    };

    return values;
};

export default getInitialValues;
