import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { VehicleScheduleStateT } from './types';
import { initialVehicleState } from './reducer';
import { VehicleScheduleT } from 'common/store/vehicle-schedules/models';

const selectVehicleState = (state: SharableStateT, vehicleId: VehicleIdT): VehicleScheduleStateT => {
    return state.vehicleSchedules.byId[vehicleId] || initialVehicleState;
};

export const selectVehicleSchedule =
    (vehicleId: VehicleIdT) =>
    (state: SharableStateT): VehicleScheduleT => {
        const vehicleState = selectVehicleState(state, vehicleId);

        return vehicleState.schedule;
    };

export const selectFetchScheduleRequest =
    (vehicleId: VehicleIdT) =>
    (state: SharableStateT): RequestStatusT => {
        const vehicleState = selectVehicleState(state, vehicleId);

        return vehicleState.fetchRequestStatus;
    };

export const selectSetUnavailableVehicleRequestStatus = (state: SharableStateT): RequestStatusT => {
    return state.vehicleSchedules.setUnavailableRequest;
};
