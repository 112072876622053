import * as React from 'react';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';

type PropsT = {
    type?: DictAdditionalServiceT['enum'];
};

const AsyncAdditionalServiceFormatter: React.FC<PropsT> = React.memo((props) => {
    const { type } = props;

    return <AdditionalServiceLabel enumType={type} />;
});

export default AsyncAdditionalServiceFormatter;
