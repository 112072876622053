import { SharableStateT } from '../models';
import { AlertsStateT } from 'common/store/alerts/types';

const NEW_ALERTS_LIMIT = 6;

export const selectNewAlertsIds = (state: SharableStateT): AlertsStateT['ids'] => {
    return state.alerts.ids.slice(0, NEW_ALERTS_LIMIT);
};

export const selectAlertsById = (state: SharableStateT): AlertsStateT['byId'] => {
    return state.alerts.byId;
};
