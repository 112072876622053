import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Pagination.scss';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import RightArrowIcon from 'common/icons/RightArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import Input from 'common/components/Input/Input';

export type PropsT = {
    current: number | null | undefined;
    count: number | null | undefined;
    goToPage: (pageNumber: PageNumberT) => void;
};

const cx = classNames.bind(styles);

const ENTER_KEY = 'Enter';

const colorsMap = {
    disabled: StyleGuideColorsEnum.light,
    active: StyleGuideColorsEnum.charcoal,
};

const Pagination: React.FC<PropsT> = React.memo((props) => {
    const { current, count, goToPage } = props;
    const { t } = useTranslation();

    const getInputText = (current: PropsT['current'], count: PropsT['count']): string => {
        return t('common:pagination', {
            currentPage: (current || 0) + 1,
            pages: count,
        });
    };

    const [inputValue, setInputValue] = React.useState(getInputText(current, count));

    React.useEffect(() => {
        setInputValue(getInputText(current, count));
    }, [current, count]);

    const isShowPagination = !!(count && count > 1);

    if (isNil(current) || isNil(count) || !isShowPagination) {
        return null;
    }

    const isDisabledPrevPage = current === 0;
    const isDisabledNextPage = current === count - 1;

    const goToPrevPage = () => {
        if (isDisabledPrevPage) {
            return;
        }
        goToPage(current - 1);
    };

    const goToNextPage = () => {
        if (isDisabledNextPage) {
            return;
        }

        goToPage(current + 1);
    };

    const tryChangePageByInputValue = () => {
        const separator = t('common:pagination', {
            currentPage: '',
            pages: '',
        }).trim();

        const [rawPage, rawCount] = inputValue.split(separator);

        const parsedPage = +rawPage;
        const parsedCount = +rawCount;
        const isValidPage = !Number.isNaN(parsedPage) && parsedPage > 0 && parsedPage <= count;
        const isSamePage = current === parsedPage - 1;
        const isSameCount = parsedCount === count;

        if (isValidPage && !isSamePage) {
            goToPage(parsedPage - 1);
        }

        if (!isValidPage || (!isSameCount && isSamePage)) {
            setInputValue(getInputText(current, count));
        }
    };

    const handleBlur = (): void => {
        tryChangePageByInputValue();
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === ENTER_KEY) {
            tryChangePageByInputValue();
        }
    };

    const testSelector = 'pagination';

    return (
        <div className={cx('pagination')} data-test-selector={testSelector}>
            <TransparentTrigger
                withoutBorderRadius
                isDisabled={isDisabledPrevPage}
                onClick={isDisabledPrevPage ? noop : goToPrevPage}
                testSelector={`${testSelector}_prev-page`}
                leftIcon={<LeftArrowIcon fillColor={isDisabledPrevPage ? colorsMap.disabled : colorsMap.active} />}
                reflectionTheme={ReflectionThemeEnum.light}
            />
            <span className={cx('input-wrap')}>
                <span className={cx('width-machine')}>{inputValue}</span>
                <Input
                    className={cx('input')}
                    name="page"
                    testSelectorPrefix={testSelector}
                    value={inputValue}
                    onChange={setInputValue}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                />
            </span>
            <TransparentTrigger
                withoutBorderRadius
                isDisabled={isDisabledNextPage}
                onClick={isDisabledNextPage ? noop : goToNextPage}
                testSelector={`${testSelector}_next-page`}
                leftIcon={<RightArrowIcon fillColor={isDisabledNextPage ? colorsMap.disabled : colorsMap.active} />}
                reflectionTheme={ReflectionThemeEnum.light}
            />
        </div>
    );
});

export default Pagination;
