import { FetchError } from './fetch-error';

export enum ApiRequestErrorTypeEnum {
    timeoutError = 'timeoutError',
    networkError = 'networkError',
    badRequest = 'badRequest',
}

export class ApiRequestError<TResponseData = any, TRequestConfig = any> extends FetchError<
    TResponseData,
    TRequestConfig
> {
    type: ApiRequestErrorTypeEnum;

    constructor(fetchError: FetchError<TResponseData, TRequestConfig>, type: ApiRequestErrorTypeEnum) {
        super(fetchError);

        this.type = type;

        // fix for instanceof
        // https://github.com/microsoft/TypeScript/issues/13965
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, ApiRequestError.prototype);
    }
}

export const checkIsApiRequestError = (error: Error | null | undefined): error is ApiRequestError => {
    return error instanceof ApiRequestError;
};

export const checkIsUnauthorizedError = (error: Error | null | undefined): error is ApiRequestError => {
    return error instanceof ApiRequestError && error.httpStatusCode === 401;
};

export const checkIsForbiddenError = (error: Error | null | undefined): error is ApiRequestError => {
    return error instanceof ApiRequestError && error.httpStatusCode === 403;
};

export const checkIsBadRequestError = (error: Error | null | undefined): boolean => {
    if (!checkIsApiRequestError(error)) {
        return false;
    }

    return error.type === ApiRequestErrorTypeEnum.badRequest;
};

export const checkIsNetworkError = (error: Error | null | undefined): boolean => {
    if (!checkIsApiRequestError(error)) {
        return false;
    }

    return error.type === ApiRequestErrorTypeEnum.networkError;
};
