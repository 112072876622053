import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const LeftArrowIcon: React.FC<PropsT> = (props) => {
    const { fillColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={fillColor} d="M16 9.25H6.872l4.193-4.192L10 4l-6 6 6 6 1.057-1.057-4.185-4.193H16z" />
            </g>
        </svg>
    );
};

LeftArrowIcon.displayName = 'LeftArrowIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default LeftArrowIcon;
