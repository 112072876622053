import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ContinueSignUpForm.scss';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { AUTO_COMPLETE_OFF_FIX } from 'common/constants';
import { useTranslation } from 'react-i18next';

import { FieldsEnum, FormValuesT } from './constants';
import asyncValidate from './async-validations';
import validateForm from './validate-form';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import { RequestStatusT } from 'common/utils/request-status';
import useAsyncFormErrorMessage from 'common/utils/hooks/useAsyncFormErrorMessage';
import { useSelector } from 'react-redux';
import { checkRegisteredUser } from 'common/store/auth/selectors';
import { selectCurrentUser } from 'common/store/user/selectors';
import { authApi } from 'common/utils/api/auth/factory';

const cx = classNames.bind(styles);

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.firstName]: '',
    [FieldsEnum.lastName]: '',
    [FieldsEnum.phone]: '+',
    [FieldsEnum.password]: '',
    [FieldsEnum.acceptTerms]: true,
};

type PropsT = {
    onSubmit: (formValues: FormValuesT) => void;
    requestStatus: RequestStatusT;
};

const ContinueSignUpForm: React.FC<PropsT> = React.memo((props) => {
    const { onSubmit, requestStatus } = props;
    const { t } = useTranslation();

    const isRegisteredUser = useSelector(checkRegisteredUser);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, isRegisteredUser);
    }, [t, isRegisteredUser]);

    const user = useSelector(selectCurrentUser);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.firstName]: user?.name || '',
            [FieldsEnum.lastName]: user?.surname || '',
            [FieldsEnum.phone]: user?.phone || '',
        };

        const errors = validateForm(t, values, isRegisteredUser);

        return [values, errors];
    }, [user]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onSubmit(values);

            formikHelpers.setTouched({});
        },
    });

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(requestStatus);
    }, [requestStatus?.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const passwordAsyncError = useAsyncFormErrorMessage(asyncFormErrors[FieldsEnum.password]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('fields')}>
                <FormikField
                    name={FieldsEnum.firstName}
                    error={formik.errors[FieldsEnum.firstName]}
                    meta={formik.getFieldMeta(FieldsEnum.firstName)}
                    label={t('common:sign-up.fields.firstName.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    resetAsyncError={resetAsyncFormErrors}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.firstName}
                            value={formik.values[FieldsEnum.firstName]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.lastName}
                    error={formik.errors[FieldsEnum.lastName]}
                    meta={formik.getFieldMeta(FieldsEnum.lastName)}
                    label={t('common:sign-up.fields.lastName.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    resetAsyncError={resetAsyncFormErrors}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.lastName}
                            value={formik.values[FieldsEnum.lastName]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.phone}
                    error={formik.errors[FieldsEnum.phone]}
                    meta={formik.getFieldMeta(FieldsEnum.phone)}
                    label={t('common:sign-up.fields.phone.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    resetAsyncError={resetAsyncFormErrors}
                >
                    {(props) => (
                        <PhoneNumberInput
                            name={FieldsEnum.phone}
                            value={formik.values[FieldsEnum.phone]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            autoComplete={AUTO_COMPLETE_OFF_FIX}
                        />
                    )}
                </FormikField>
                {!isRegisteredUser && (
                    <FormikField
                        name={FieldsEnum.password}
                        error={formik.errors[FieldsEnum.password]}
                        meta={formik.getFieldMeta(FieldsEnum.password)}
                        label={t('common:sign-up.fields.password.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        resetAsyncError={resetAsyncFormErrors}
                        asyncError={passwordAsyncError}
                    >
                        {(props) => (
                            <Input
                                type="password"
                                name={FieldsEnum.password}
                                value={formik.values[FieldsEnum.password]}
                                placeholder={t('common:sign-up.fields.password.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                autoComplete={AUTO_COMPLETE_OFF_FIX}
                            />
                        )}
                    </FormikField>
                )}
                <FormikField
                    className={cx('checkbox')}
                    name={FieldsEnum.acceptTerms}
                    error={formik.errors[FieldsEnum.acceptTerms]}
                    meta={formik.getFieldMeta(FieldsEnum.acceptTerms)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    isCheckbox
                >
                    {(props) => (
                        <Checkbox
                            checked={formik.values[FieldsEnum.acceptTerms]}
                            onChange={props.onChange}
                            label={
                                <span className={cx('terms')}>
                                    {t('common:sign-up.user-form.terms.read')}{' '}
                                    <Link
                                        isExternal
                                        theme={LinkThemeEnum.boldCharcoal}
                                        to={t('common:sign-up.user-form.terms.link')}
                                        target="blank"
                                    >
                                        {t('common:sign-up.user-form.terms.link-text')}
                                    </Link>
                                </span>
                            }
                        />
                    )}
                </FormikField>
            </div>
            <Button
                isLoading={requestStatus.loading}
                theme={ButtonThemeEnum.primary}
                type="submit"
                className={cx('submit')}
            >
                {t('common:sign-up.user-form.submit')}
            </Button>
            {!isRegisteredUser && (
                <div className={cx('footer-links')}>
                    {t('common:sign-up.user-form.footer.have-account')}
                    <span className={cx('footer-links__link')}>
                        <Link theme={LinkThemeEnum.boldAzul} to={authApi.createSignInUrl()}>
                            {t('common:sign-up.user-form.footer.sign-in')}
                        </Link>
                    </span>
                </div>
            )}
        </form>
    );
});

export default ContinueSignUpForm;
