import { FieldsEnum, FormValuesT } from './constants';
import { PartnerContractDetailsT } from 'common/store/carrier-contract-details/models';
import moment from 'moment/moment';
import { simpleNumberFormatter } from 'common/utils/form-formatters';

const DEFAULT_VALUES: FormValuesT = {
    [FieldsEnum.expiration]: null,
    [FieldsEnum.number]: '',
    [FieldsEnum.name]: '',
    [FieldsEnum.truckRate]: '',
    [FieldsEnum.tiltRate]: '',
    [FieldsEnum.boxRate]: '',
    [FieldsEnum.boxExpectedMileage]: '',
    [FieldsEnum.tiltExpectedMileage]: '',
    [FieldsEnum.truckExpectedMileage]: '',
    [FieldsEnum.deadheadExpectedMileage]: '',
};

const getInitialValues = (contractDetails: PartnerContractDetailsT | null): FormValuesT => {
    if (!contractDetails) {
        return { ...DEFAULT_VALUES };
    }

    return {
        [FieldsEnum.expiration]: contractDetails.validTill ? moment(contractDetails.validTill).toDate() : null,
        [FieldsEnum.number]: contractDetails.number || '',
        [FieldsEnum.name]: contractDetails.name || '',
        [FieldsEnum.truckRate]: simpleNumberFormatter(contractDetails.truckRateKm),
        [FieldsEnum.tiltRate]: simpleNumberFormatter(contractDetails.tiltRateKm),
        [FieldsEnum.boxRate]: simpleNumberFormatter(contractDetails.boxRateKm),
        [FieldsEnum.boxExpectedMileage]: simpleNumberFormatter(contractDetails.boxExpectedKm) || '',
        [FieldsEnum.tiltExpectedMileage]: simpleNumberFormatter(contractDetails.tiltExpectedKm) || '',
        [FieldsEnum.truckExpectedMileage]: simpleNumberFormatter(contractDetails.truckExpectedKm) || '',
        [FieldsEnum.deadheadExpectedMileage]: simpleNumberFormatter(contractDetails.deadheadExpectedKm) || '',
    };
};
export default getInitialValues;
