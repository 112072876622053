import * as React from 'react';

import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './NotificationsDebugForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sendTestNotification } from 'common/store/notifications/actions';
import { selectCurrentUser } from 'common/store/user/selectors';
import { UserT } from 'common/store/user/models';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const NotificationsDebugForm: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);

    const getNotification = (currentUser: UserT | null): string => {
        return JSON.stringify(
            {
                initiatorId: null,
                initiatorType: 'COMPANY',
                receiverId: currentUser?.id,
                receiverType: 'USER',
                action: 'LOAD_STATUS_CHANGED',
                body: 'string',
                timestamp: moment().format(),
                level: 'WEB_APP',
                subjectId: currentUser?.id,
                subjectNumber: 'string',
                subjectType: 'ORDER',
                bestBefore: moment().format(),
            },
            null,
            4,
        );
    };

    const [text, setText] = React.useState<string>(getNotification(currentUser));

    React.useEffect(() => {
        setText(getNotification(currentUser));
    }, [currentUser]);

    const featuresContext = React.useContext(FeaturesContext);
    if (!featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.debugNotificationsForm)) {
        return null;
    }

    return (
        <div className={className} style={{ marginBottom: '20px' }}>
            <button
                onClick={() => {
                    dispatch(sendTestNotification(text));
                }}
            >
                send test notification
            </button>
            <br />
            <br />
            <div>
                <textarea
                    style={{
                        width: '100%',
                        height: '400px',
                    }}
                    value={text}
                    onChange={(event) => {
                        setText(event.target.value);
                    }}
                />
            </div>
        </div>
    );
});

export default NotificationsDebugForm;
