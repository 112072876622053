import React from 'react';

import classNames from 'classnames/bind';
import styles from './ZoneMark.scss';
import { RangeSelectorZoneThemeEnum } from 'common/components/RangeSelector/constants';

const cx = classNames.bind(styles);

export type PropsT = {
    hasLeftBorder?: boolean;
    left?: number | undefined;
    right?: number | undefined;
    isActive?: boolean;
    theme: RangeSelectorZoneThemeEnum | null;
    testSelector?: string;
};

const ZoneMark: React.FC<PropsT> = React.memo((props) => {
    const { hasLeftBorder, left, right, isActive, children } = props;

    const theme = props.theme || RangeSelectorZoneThemeEnum.light;

    return (
        <div
            className={cx('zone-mark', {
                'zone-mark--isActive': isActive,
                'zone-mark--hasLeftBorder': hasLeftBorder,
                [`zone-mark--theme-${theme}`]: !!theme,
            })}
            style={{
                left: left !== undefined ? `${left}%` : undefined,
                right: right !== undefined ? `${right}%` : undefined,
            }}
            data-test-selector="zone-mark"
        >
            {children}
        </div>
    );
});

export default ZoneMark;
