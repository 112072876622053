import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const AddUserIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    fillOpacity={0.3}
                    d="M12.25 13c2.025 0 4.35.967 4.5 1.5h-9c.173-.54 2.482-1.5 4.5-1.5zm0-7.5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0112.25 5.5z"
                />
                <path
                    fill={strokeColor}
                    d="M12.25 10a3 3 0 100-6 3 3 0 100 6zm0-4.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0 6c-2.002 0-6 1.005-6 3V16h12v-1.5c0-1.995-3.998-3-6-3zm-4.5 3c.165-.54 2.482-1.5 4.5-1.5 2.025 0 4.35.967 4.5 1.5h-9zM5.5 12.25V10h2.25V8.5H5.5V6.25H4V8.5H1.75V10H4v2.25h1.5z"
                />
            </g>
        </svg>
    );
};

AddUserIcon.displayName = 'AddUserIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default AddUserIcon;
