import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../query';

const useQuery = <QueryT extends {}>(): Partial<QueryT> => {
    const location = useLocation();
    const { search } = location;

    const query = React.useMemo(() => parseQuery(search), [search]);

    return query as QueryT;
};

export default useQuery;
