import { TrailersDictT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const TRAILERS_DICT_REQUEST = 'common/trailers-dict/TRAILERS_DICT_REQUEST';
export const TRAILERS_DICT_REQUEST_BEGIN = 'common/trailers-dict/TRAILERS_DICT_REQUEST_BEGIN';
export const TRAILERS_DICT_REQUEST_SUCCESS = 'common/trailers-dict/TRAILERS_DICT_REQUEST_SUCCESS';
export const TRAILERS_DICT_REQUEST_ERROR = 'common/trailers-dict/TRAILERS_DICT_REQUEST_ERROR';

export type FetchTrailersDictActionT = {
    type: typeof TRAILERS_DICT_REQUEST;
};

export type FetchTrailersDictBeginActionT = {
    type: typeof TRAILERS_DICT_REQUEST_BEGIN;
};

export type FetchTrailersDictSuccessActionT = {
    type: typeof TRAILERS_DICT_REQUEST_SUCCESS;
    trailersDict: TrailersDictT;
};

export type FetchTrailersDictErrorActionT = {
    type: typeof TRAILERS_DICT_REQUEST_ERROR;
    error: Error;
};

export type TrailersDictActionT =
    | FetchTrailersDictActionT
    | FetchTrailersDictBeginActionT
    | FetchTrailersDictSuccessActionT
    | FetchTrailersDictErrorActionT;

export type TrailersDictStateT = {
    trailersDict: TrailersDictT | null;
    trailersDictRequest: RequestStatusT;
};
