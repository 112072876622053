export enum FieldsEnum {
    taxId = 'taxId',
    vatNumber = 'vatNumber',
    iban = 'iban',
    bic = 'bic',
}

export type FormValuesT = {
    [FieldsEnum.taxId]: string;
    [FieldsEnum.vatNumber]: string;
    [FieldsEnum.iban]: string;
    [FieldsEnum.bic]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
