import * as React from 'react';
import isNumber from 'lodash/isNumber';

type PropsT = {
    lowestTemperature?: number | null | undefined;
    highestTemperature?: number | null | undefined;
};

const TemperatureModeFormatter: React.FC<PropsT> = React.memo((props) => {
    const { lowestTemperature, highestTemperature } = props;

    const label =
        isNumber(lowestTemperature) && isNumber(highestTemperature)
            ? `${lowestTemperature}...${highestTemperature} °C`
            : null;

    return <span>{label}</span>;
});

export default TemperatureModeFormatter;
