import { LangEnum } from 'common/locales/i18n';
import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';

export enum FieldsEnum {
    firstName = 'firstName',
    lastName = 'lastName',
    phoneNumber = 'phoneNumber',
    currentPassword = 'currentPassword',
    newPassword = 'newPassword',
    language = 'language',
    timezone = 'timezone',
}

export type FormValuesT = {
    [FieldsEnum.firstName]: string;
    [FieldsEnum.lastName]: string;
    [FieldsEnum.phoneNumber]: string;
    [FieldsEnum.currentPassword]: string;
    [FieldsEnum.newPassword]: string;
    [FieldsEnum.language]: LangEnum | null;
    [FieldsEnum.timezone]: number | null;
};

export type FormErrorsT = Partial<Record<keyof FormValuesT, string>>;

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.currentPassword]:
        | AuthErrorTypeEnum.wrongPassword
        | AuthErrorTypeEnum.failUpdatePassword
        | AuthErrorTypeEnum.failReauthenticateWithCredential;
}>;
