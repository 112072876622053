import { RequestStatusT } from 'common/utils/request-status';
import { SignUpParamsT } from 'common/store/auth/models';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import { AuthUserClaimsT, AuthUserT, PasswordT } from 'common/utils/api/auth/base-auth-service';

export const SIGN_IN_REQUEST = 'common/auth/SIGN_IN_REQUEST';
export const SIGN_IN_REQUEST_BEGIN = 'common/auth/SIGN_IN_REQUEST_BEGIN';
export const SIGN_IN_REQUEST_SUCCESS = 'common/auth/SIGN_IN_REQUEST_SUCCESS';
export const SIGN_IN_REQUEST_ERROR = 'common/auth/SIGN_IN_REQUEST_ERROR';

export const RESET_PASSWORD_REQUEST = 'common/auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_BEGIN = 'common/auth/RESET_PASSWORD_REQUEST_BEGIN';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'common/auth/RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_ERROR = 'common/auth/RESET_PASSWORD_REQUEST_ERROR';

export const UPDATE_PASSWORD_REQUEST = 'common/auth/UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_REQUEST_BEGIN = 'common/auth/UPDATE_PASSWORD_REQUEST_BEGIN';
export const UPDATE_PASSWORD_REQUEST_SUCCESS = 'common/auth/UPDATE_PASSWORD_REQUEST_SUCCESS';
export const UPDATE_PASSWORD_REQUEST_ERROR = 'common/auth/UPDATE_PASSWORD_REQUEST_ERROR';

export const CONTINUE_SIGN_UP_REQUEST = 'common/auth/CONTINUE_SIGN_UP_REQUEST';
export const SIGN_UP_REQUEST = 'common/auth/SIGN_UP_REQUEST';
export const SIGN_UP_REQUEST_BEGIN = 'common/auth/SIGN_UP_REQUEST_BEGIN';
export const SIGN_UP_REQUEST_SUCCESS = 'common/auth/SIGN_UP_REQUEST_SUCCESS';
export const SIGN_UP_REQUEST_ERROR = 'common/auth/SIGN_UP_REQUEST_ERROR';

export const SIGN_OUT_REQUEST = 'common/auth/SIGN_OUT_REQUEST';
export const SIGN_OUT_REQUEST_BEGIN = 'common/auth/SIGN_OUT_REQUEST_BEGIN';
export const SIGN_OUT_REQUEST_SUCCESS = 'common/auth/SIGN_OUT_REQUEST_SUCCESS';
export const SIGN_OUT_REQUEST_ERROR = 'common/auth/SIGN_OUT_REQUEST_ERROR';

export const EMAIL_VERIFICATION_REQUEST = 'common/auth/EMAIL_VERIFICATION_REQUEST';
export const EMAIL_VERIFICATION_REQUEST_BEGIN = 'common/auth/EMAIL_VERIFICATION_REQUEST_BEGIN';
export const EMAIL_VERIFICATION_REQUEST_SUCCESS = 'common/auth/EMAIL_VERIFICATION_REQUEST_SUCCESS';
export const EMAIL_VERIFICATION_REQUEST_ERROR = 'common/auth/EMAIL_VERIFICATION_REQUEST_ERROR';

export const SET_USER = 'common/auth/SET_USER';
export const RESET_USER = 'common/auth/RESET_USER';

export const DESTROY_SESSION = 'common/auth/DESTROY_SESSION';

export type AuthStateT = {
    user: AuthUserT | null;
    signInRequest: RequestStatusT;
    resetPasswordRequest: RequestStatusT;
    updatePasswordRequest: RequestStatusT;
    signUpRequest: RequestStatusT;
    signOutRequest: RequestStatusT;
    emailVerificationRequest: RequestStatusT;
    userClaims: AuthUserClaimsT | null;
    profileType: AuthProfileTypeEnum;
};

export type SignInActionT = {
    type: typeof SIGN_IN_REQUEST;
    email: string;
    password: string;
    returnUrl: string | null;
};

export type SignInBeginActionT = {
    type: typeof SIGN_IN_REQUEST_BEGIN;
};

export type SignInSuccessActionT = {
    type: typeof SIGN_IN_REQUEST_SUCCESS;
    user: AuthUserT;
    userClaims: AuthUserClaimsT | null;
};

export type SignInErrorActionT = {
    type: typeof SIGN_IN_REQUEST_ERROR;
    error: Error;
};

export type ResetPasswordActionT = {
    type: typeof RESET_PASSWORD_REQUEST;
    email: string;
};

export type ResetPasswordBeginActionT = {
    type: typeof RESET_PASSWORD_REQUEST_BEGIN;
};

export type ResetPasswordSuccessActionT = {
    type: typeof RESET_PASSWORD_REQUEST_SUCCESS;
};

export type ResetPasswordErrorActionT = {
    type: typeof RESET_PASSWORD_REQUEST_ERROR;
    error: Error;
};

export type UpdatePasswordActionT = {
    type: typeof UPDATE_PASSWORD_REQUEST;
    oobCode: string;
    password: PasswordT;
};

export type UpdatePasswordBeginActionT = {
    type: typeof UPDATE_PASSWORD_REQUEST_BEGIN;
};

export type UpdatePasswordSuccessActionT = {
    type: typeof UPDATE_PASSWORD_REQUEST_SUCCESS;
};

export type UpdatePasswordErrorActionT = {
    type: typeof UPDATE_PASSWORD_REQUEST_ERROR;
    error: Error;
};

export type ContinueSignUpActionT = {
    type: typeof CONTINUE_SIGN_UP_REQUEST;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    oobCode: string | null;
    email: string | null;
    returnUrl: string | null;
};

export type SignUpActionT = {
    type: typeof SIGN_UP_REQUEST;
    params: SignUpParamsT;
};

export type SignUpBeginActionT = {
    type: typeof SIGN_UP_REQUEST_BEGIN;
};

export type SignUpSuccessActionT = {
    type: typeof SIGN_UP_REQUEST_SUCCESS;
};

export type SignUpErrorActionT = {
    type: typeof SIGN_UP_REQUEST_ERROR;
    error: Error;
};

export type SetUserActionT = {
    type: typeof SET_USER;
    user: AuthUserT | null;
    userClaims: AuthUserClaimsT | null;
};

export type ResetUserActionT = {
    type: typeof RESET_USER;
};

export type SignOutActionT = {
    type: typeof SIGN_OUT_REQUEST;
};

export type SignOutBeginActionT = {
    type: typeof SIGN_OUT_REQUEST_BEGIN;
};

export type SignOutSuccessActionT = {
    type: typeof SIGN_OUT_REQUEST_SUCCESS;
};

export type SignOutErrorActionT = {
    type: typeof SIGN_OUT_REQUEST_ERROR;
    error: Error;
};

export type EmailVerificationActionT = {
    code: string;
    type: typeof EMAIL_VERIFICATION_REQUEST;
};

export type EmailVerificationBeginActionT = {
    type: typeof EMAIL_VERIFICATION_REQUEST_BEGIN;
};

export type EmailVerificationSuccessActionT = {
    type: typeof EMAIL_VERIFICATION_REQUEST_SUCCESS;
};

export type EmailVerificationErrorActionT = {
    type: typeof EMAIL_VERIFICATION_REQUEST_ERROR;
    error: Error;
};

export type DestroySessionActionT = {
    type: typeof DESTROY_SESSION;
};

export type AuthActionT =
    | SignInActionT
    | SignInBeginActionT
    | SignInSuccessActionT
    | SignInErrorActionT
    | SetUserActionT
    | ResetUserActionT
    | ResetPasswordActionT
    | ResetPasswordBeginActionT
    | ResetPasswordSuccessActionT
    | ResetPasswordErrorActionT
    | UpdatePasswordActionT
    | UpdatePasswordBeginActionT
    | UpdatePasswordSuccessActionT
    | UpdatePasswordErrorActionT
    | SignUpActionT
    | SignUpBeginActionT
    | SignUpSuccessActionT
    | SignUpErrorActionT
    | SignOutActionT
    | SignOutBeginActionT
    | SignOutSuccessActionT
    | SignOutErrorActionT
    | DestroySessionActionT
    | EmailVerificationActionT
    | EmailVerificationBeginActionT
    | EmailVerificationSuccessActionT
    | EmailVerificationErrorActionT;
