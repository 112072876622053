import {
    FETCH_RFQS_STATS_REQUEST,
    FETCH_RFQS_STATS_REQUEST_BEGIN,
    FETCH_RFQS_STATS_REQUEST_ERROR,
    FETCH_RFQS_STATS_REQUEST_SUCCESS,
    FetchRFQsStatsActionT,
    FetchRFQsStatsBeginActionT,
    FetchRFQsStatsErrorActionT,
    FetchRFQsStatsSuccessActionT,
} from './types';
import { RFQsStatsT } from './models';

export const fetchRFQsStats = (options?: FetchOptionsT): FetchRFQsStatsActionT => ({
    type: FETCH_RFQS_STATS_REQUEST,
    options,
});

export const fetchRFQsStatsBegin = (): FetchRFQsStatsBeginActionT => ({
    type: FETCH_RFQS_STATS_REQUEST_BEGIN,
});

export const fetchRFQsStatsSuccess = (statistics: RFQsStatsT | null): FetchRFQsStatsSuccessActionT => ({
    type: FETCH_RFQS_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchRFQsStatsError = (error: Error): FetchRFQsStatsErrorActionT => ({
    type: FETCH_RFQS_STATS_REQUEST_ERROR,
    error,
});
