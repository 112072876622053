import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_INVOICES_PAGE_REQUEST, FetchInvoicesPageActionT } from './types';
import { fetchInvoicesPageBegin, fetchInvoicesPageError, fetchInvoicesPageSuccess, resetInvoices } from './actions';
import { selectInvoicesPages, selectInvoicesQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { invoicesPaginationChannel } from './channels';
import { PageResponseT } from 'common/utils/pagination/models';
import { DepreactedPagingStateT, ShipperInvoiceT } from 'shipper/store/invoices/models';

function* fetchInvoicesSaga(action: FetchInvoicesPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query: rawQuery, options } = action;

    const query: typeof rawQuery = {
        ...rawQuery,
    };

    const prevQuery: ReturnType<typeof selectInvoicesQuery> = yield select(selectInvoicesQuery);
    const pages: ReturnType<typeof selectInvoicesPages> = yield select(selectInvoicesPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);

    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetInvoices({ savingPageNumber: pageNumber }));
    }

    yield put(fetchInvoicesPageBegin(query, pageNumber));
    const [error, response]: ReturnApiT<typeof shipperTranziitApi.fetchShipperInvoicesPage> =
        yield shipperTranziitApi.fetchShipperInvoicesPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchInvoicesPageError(query, pageNumber, error));
        return;
    }

    const items = response?.data || [];

    const preparedResponse: PageResponseT<ShipperInvoiceT> = {
        content: items.map((item): ShipperInvoiceT => {
            return {
                id: String(Math.random() * 10000),
                ...item,
            };
        }),
        totalPages: 1,
        totalElements: items?.length || 0,
    };

    const pagingTokens: DepreactedPagingStateT = {
        hasMore: response?.hasMore || false,
        startingAfter: response?.startingAfter || null,
        endingBefore: response?.endingBefore || null,
    };

    yield put(fetchInvoicesPageSuccess(query, pageNumber, preparedResponse, pagingTokens));

    checkShouldEmitChangePage(pageNumber, preparedResponse, invoicesPaginationChannel);
}

function* invoicesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_INVOICES_PAGE_REQUEST, fetchInvoicesSaga);
}

export { invoicesSaga };
