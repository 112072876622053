import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';

const asyncValidate = (requestError: RequestStatusT): AsyncFormErrorsT => {
    const asyncErrors: AsyncFormErrorsT = {};

    if (!checkIsAuthApiError(requestError.error)) {
        return asyncErrors;
    }

    if (
        requestError.error?.type === AuthErrorTypeEnum.userNotFound ||
        requestError.error?.type === AuthErrorTypeEnum.wrongRole ||
        requestError.error?.type === AuthErrorTypeEnum.userDisabled
    ) {
        asyncErrors[FieldsEnum.email] = requestError.error?.type;
    }

    if (requestError.error?.type === AuthErrorTypeEnum.wrongPassword) {
        asyncErrors[FieldsEnum.password] = requestError.error?.type;
    }

    return asyncErrors;
};

export default asyncValidate;
