import isEmpty from 'lodash/isEmpty';

export const checkFilters = (primaryFilters: Array<TODO>, secondaryFilters: Array<TODO>) => {
    const hasPrimaryQueryFilters = !!primaryFilters.filter((item) => !isEmpty(item))?.length;
    const hasSecondaryQueryFilters = !!secondaryFilters.filter((item) => !isEmpty(item))?.length;

    return {
        hasPrimaryQueryFilters,
        hasSecondaryQueryFilters,
    };
};
