import {
    FETCH_COMPANY_REQUEST,
    FETCH_COMPANY_REQUEST_BEGIN,
    FETCH_COMPANY_REQUEST_ERROR,
    FETCH_COMPANY_REQUEST_SUCCESS,
    FetchCompanyActionT,
    FetchCompanyBeginActionT,
    FetchCompanyErrorActionT,
    FetchCompanySuccessActionT,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_REQUEST_BEGIN,
    UPDATE_COMPANY_REQUEST_ERROR,
    UPDATE_COMPANY_REQUEST_SUCCESS,
    UpdateCompanyActionT,
    UpdateCompanyBeginActionT,
    UpdateCompanyErrorActionT,
    UpdateCompanySuccessActionT,
} from './types';
import { CompanyChangesT, CompanyT } from './models';

export const fetchCompany = (options?: FetchOptionsT): FetchCompanyActionT => ({
    type: FETCH_COMPANY_REQUEST,
    options,
});

export const fetchCompanyBegin = (): FetchCompanyBeginActionT => ({
    type: FETCH_COMPANY_REQUEST_BEGIN,
});

export const fetchCompanySuccess = (currentCompany: CompanyT | null): FetchCompanySuccessActionT => ({
    type: FETCH_COMPANY_REQUEST_SUCCESS,
    currentCompany,
});

export const fetchCompanyError = (error: Error): FetchCompanyErrorActionT => ({
    type: FETCH_COMPANY_REQUEST_ERROR,
    error,
});

export const updateCompany = (company: CompanyT, companyChanges: CompanyChangesT): UpdateCompanyActionT => ({
    type: UPDATE_COMPANY_REQUEST,
    companyChanges,
    company,
});

export const updateCompanyBegin = (): UpdateCompanyBeginActionT => ({
    type: UPDATE_COMPANY_REQUEST_BEGIN,
});

export const updateCompanySuccess = (currentCompany: CompanyT | null): UpdateCompanySuccessActionT => ({
    type: UPDATE_COMPANY_REQUEST_SUCCESS,
    currentCompany,
});

export const updateCompanyError = (error: Error): UpdateCompanyErrorActionT => ({
    type: UPDATE_COMPANY_REQUEST_ERROR,
    error,
});
