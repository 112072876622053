import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PendingUserCell.scss';
import { ApiUserStatusT, UserStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    userStatus?: ApiUserStatusT;
};

const cx = classNames.bind(styles);

const PendingUserCell: React.FC<PropsT> = React.memo((props) => {
    const { children, userStatus } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('content')}>
            {userStatus === UserStatusEnum.pending ? t('common:team-members.pending') : children}
        </div>
    );
});

export default PendingUserCell;
