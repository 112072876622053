import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const SearchIcon: React.FC<PropsT> = (props) => {
    const { fillColor, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M.9.9h16.2v16.2H.9z" />
                <path
                    fill={fillColor}
                    d="M11.363 10.35h-.534l-.189-.182a4.387 4.387 0 10-.472.472l.182.19v.533l3.375 3.368 1.006-1.006-3.368-3.375zm-4.05 0a3.034 3.034 0 01-3.038-3.037 3.034 3.034 0 013.037-3.038 3.034 3.034 0 013.038 3.037 3.034 3.034 0 01-3.037 3.038z"
                />
            </g>
        </svg>
    );
};

SearchIcon.displayName = 'SearchIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default SearchIcon;

export const SearchIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                fillColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                fillColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent,
            };
        }

        if (isDisabled) {
            return {
                fillColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.gray,
        };
    },
};
