import React from 'react';

export type MenuVisibilityT = {
    isVisible: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    handleToggle: () => void;
};

const useMenuVisibility = (): MenuVisibilityT => {
    const [value, setValue] = React.useState(false);

    const handleOpen = () => {
        setValue(true);
    };

    const handleClose = () => {
        setValue(false);
    };

    const handleToggle = () => {
        setValue(!value);
    };

    return {
        isVisible: value,
        handleOpen,
        handleClose,
        handleToggle,
    };
};

export default useMenuVisibility;
