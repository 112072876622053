import {
    COUNTRIES_DICT_REQUEST,
    COUNTRIES_DICT_REQUEST_BEGIN,
    COUNTRIES_DICT_REQUEST_ERROR,
    COUNTRIES_DICT_REQUEST_SUCCESS,
    FetchCountriesDictActionT,
    FetchCountriesDictBeginActionT,
    FetchCountriesDictErrorActionT,
    FetchCountriesDictSuccessActionT,
} from './types';
import { CountriesListT } from './models';

export const fetchCountriesDict = (): FetchCountriesDictActionT => ({
    type: COUNTRIES_DICT_REQUEST,
});

export const fetchCountriesDictBegin = (): FetchCountriesDictBeginActionT => ({
    type: COUNTRIES_DICT_REQUEST_BEGIN,
});

export const fetchCountriesDictSuccess = (countriesList: CountriesListT): FetchCountriesDictSuccessActionT => ({
    type: COUNTRIES_DICT_REQUEST_SUCCESS,
    countriesList,
});

export const fetchCountriesDictError = (error: Error): FetchCountriesDictErrorActionT => ({
    type: COUNTRIES_DICT_REQUEST_ERROR,
    error,
});
