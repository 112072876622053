import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './EvidenceOfTaxDetailsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import values from 'lodash/values';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import moment from 'moment';
import prepareApproveData from './prepare-approve-data';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFetchEvidenceOfTaxDetails,
    selectFetchEvidenceOfTaxDetailsRequest,
    selectUpdateCompanyDocumentsRequest,
    selectUpdateEvidenceOfTaxDetailsRequest,
} from 'common/store/documents/selectors';
import {
    approveEvidenceOfTaxDocument,
    fetchEvidenceOfTaxDetails,
    updateEvidenceOfTaxDetails,
} from 'common/store/documents/actions';
import { logWarning } from 'common/utils/logger';
import Input from 'common/components/Input/Input';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import { DocumentVersionT, EvidenceOfTaxDetailsChangesT } from 'common/store/documents/models';
import ApproveDocumentsConfirmation, {
    BaseConfirmApproveDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/ApproveDocumentsConfirmation/ApproveDocumentsConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { DictDocumentT } from 'common/store/documents-dict/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { PartnerTypeEnum } from 'common/utils/api/models';

const TOMORROW_DATE = moment().add(1, 'days').format('YYYY-MM-DD');

const cx = classNames.bind(styles);

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type PropsT = {
    isReview: boolean;
    dictDocument: DictDocumentT | null;
    actualVersion: DocumentVersionT | null;
    setNeedCloseConfirmation: (needCloseConfirmation: boolean) => void;
};

type ConfirmApproveDocumentModalDataT = BaseConfirmApproveDocumentModalDataT<{
    data: EvidenceOfTaxDetailsChangesT;
    partnerType: PartnerTypeEnum;
    dictDocumentId: DictDocumentIdT;
}>;

const EvidenceOfTaxDetailsForm: React.FC<PropsT> = React.memo((props) => {
    const { isReview, actualVersion, dictDocument, setNeedCloseConfirmation } = props;

    const { partnerId, partnerType } = usePartnerContext();

    const dispatch = useDispatch();

    const confirmApproveDocumentModalDialog = useModalDialog<ConfirmApproveDocumentModalDataT>();

    const updateDocumentRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));
    const updateEvidenceOfTaxDetailsRequest = useSelector(selectUpdateEvidenceOfTaxDetailsRequest(partnerId));

    const isLoading = updateDocumentRequest.loading || updateEvidenceOfTaxDetailsRequest.loading;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    React.useEffect(() => {
        dispatch(fetchEvidenceOfTaxDetails(partnerId));
    }, [partnerId]);

    const evidenceOfTaxDetails = useSelector(selectFetchEvidenceOfTaxDetails(partnerId));

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(evidenceOfTaxDetails);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [evidenceOfTaxDetails]);

    const handleConfirmApproveDocument = (data: ConfirmApproveDocumentModalDataT) => {
        if (!actualVersion?.id) {
            logWarning('failed handleConfirmApproveDocument, empty actualVersion?.id');
            return;
        }

        dispatch(
            approveEvidenceOfTaxDocument(
                partnerId,
                partnerType,
                actualVersion.id,
                data.data.validTill || null,
                data.data,
            ),
        );
    };

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const approveData = prepareApproveData(values);

            if (!actualVersion?.id) {
                logWarning('failed to save evidence details, empty documentId');
                return;
            }

            if (!dictDocument) {
                logWarning('failed to save evidence details, empty dictDocument');
                return;
            }

            if (!approveData) {
                logWarning('failed to save evidence details, empty approveData');
                return;
            }

            if (isReview) {
                confirmApproveDocumentModalDialog.setData({
                    partnerType,
                    dictDocumentId: dictDocument?.id,
                    data: approveData,
                });
            } else {
                if (!approveData.validTill) {
                    logWarning('failed to save document, empty approveData.expiration');
                    return;
                }

                dispatch(updateEvidenceOfTaxDetails(partnerId, actualVersion.id, approveData));
            }

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        return () => {
            setNeedCloseConfirmation(false);
        };
    }, []);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const fetchEvidenceOfTaxDetailsRequest = useSelector(selectFetchEvidenceOfTaxDetailsRequest(partnerId));
    if (fetchEvidenceOfTaxDetailsRequest.loading && !evidenceOfTaxDetails) {
        return <SideBarLoader isRelative />;
    }

    return (
        <form onSubmit={formik.handleSubmit} className={cx('wrap')}>
            <FormikField
                className={cx('field')}
                name={FieldsEnum.taxId}
                error={formik.errors[FieldsEnum.taxId]}
                meta={formik.getFieldMeta(FieldsEnum.taxId)}
                label={t('common:document.evidence-of-tax-details-form.tax-id.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.taxId}
                        value={formik.values[FieldsEnum.taxId]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field')}
                name={FieldsEnum.vatNumber}
                error={formik.errors[FieldsEnum.vatNumber]}
                meta={formik.getFieldMeta(FieldsEnum.vatNumber)}
                label={t('common:document.evidence-of-tax-details-form.vat-number.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.vatNumber}
                        value={formik.values[FieldsEnum.vatNumber]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field')}
                name={FieldsEnum.iban}
                error={formik.errors[FieldsEnum.iban]}
                meta={formik.getFieldMeta(FieldsEnum.iban)}
                label={t('common:document.evidence-of-tax-details-form.iban.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.iban}
                        value={formik.values[FieldsEnum.iban]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field')}
                name={FieldsEnum.bic}
                error={formik.errors[FieldsEnum.bic]}
                meta={formik.getFieldMeta(FieldsEnum.bic)}
                label={t('common:document.evidence-of-tax-details-form.bic.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.bic}
                        value={formik.values[FieldsEnum.bic]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <HiddenSubmitButtonForKeyboardEnter />
            <ApproveDocumentsConfirmation
                name={dictDocument?.name}
                data={confirmApproveDocumentModalDialog.data}
                onCancel={confirmApproveDocumentModalDialog.onCancel}
                onClose={confirmApproveDocumentModalDialog.onClose}
                onConfirm={handleConfirmApproveDocument}
                isLoading={isLoading}
            />
        </form>
    );
});

export default EvidenceOfTaxDetailsForm;
