import * as React from 'react';
import { ApiCarrierContractStatusT, CarrierContractStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiCarrierContractStatusT | null | undefined;
};

export const CARRIER_CONTRACT_STATUS_T_MAP: Record<ApiCarrierContractStatusT, string> = {
    [CarrierContractStatusEnum.waitingForApprove]: 'common:document-status.WAITING_FOR_APPROVE',
    [CarrierContractStatusEnum.approved]: 'common:document-status.APPROVED',
    [CarrierContractStatusEnum.rejected]: 'common:document-status.REJECTED',
    [CarrierContractStatusEnum.revoked]: 'common:document-status.REVOKED',
    [CarrierContractStatusEnum.expired]: 'common:document-status.EXPIRED',
};

const CarrierContractStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return t('common:document-status.not-upload');
    }

    return t(CARRIER_CONTRACT_STATUS_T_MAP[status]);
});

export default CarrierContractStatus;
