import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';

type PropsT = {
    size: number;
    opacity?: number;
    fillColor: StyleGuideColorsEnum;
    className?: string;
};

const ControlLoaderIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, opacity, ...restProps } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...restProps}>
            <path
                fill={fillColor}
                fillOpacity={opacity || 0.3}
                fillRule="evenodd"
                d="M10 3a7 7 0 110 14 1.4 1.4 0 010-2.8A4.2 4.2 0 105.8 10 1.4 1.4 0 013 10a7 7 0 017-7z"
            >
                <animateTransform
                    attributeName="transform"
                    dur="1s"
                    from="0 10 10"
                    repeatCount="indefinite"
                    to="360 10 10"
                    type="rotate"
                />
            </path>
        </svg>
    );
};

ControlLoaderIcon.displayName = 'ControlLoaderIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    opacity: 0.5,
};

export const ControlLoaderIconProps = {
    getFetchDataProps: () => {
        return {
            fillColor: StyleGuideColorsEnum.brandAccent,
            size: DEFAULT_ICON_SIZE,
        };
    },
};

export { storyProps };
export default ControlLoaderIcon;
