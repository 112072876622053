import {
    COUNTRIES_DICT_REQUEST_BEGIN,
    COUNTRIES_DICT_REQUEST_ERROR,
    COUNTRIES_DICT_REQUEST_SUCCESS,
    CountriesDictActionT,
    CountriesDictStateT,
} from './types';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import requestStatus from 'common/utils/request-status';
import { CountryLanguageT } from 'common/store/countries-dict/models';

const initialState: CountriesDictStateT = {
    byLangCode: {},
    allLangCodes: [],
    list: [],
    allCodes: [],
    byCode: {},
    request: requestStatus.initial,
};

export default (state = initialState, action: CountriesDictActionT): CountriesDictStateT => {
    switch (action.type) {
        case COUNTRIES_DICT_REQUEST_BEGIN: {
            return {
                ...state,
                request: requestStatus.loading,
            };
        }

        case COUNTRIES_DICT_REQUEST_SUCCESS: {
            const { countriesList } = action;

            const countryLanguages: CountryLanguageT[] = countriesList.map((country) => {
                return {
                    code: country.langCode,
                    name: country.language,
                };
            });
            const byLangCode = keyBy(countryLanguages, 'code');
            const allLangCodes = countryLanguages.map((country) => country.code);
            const uniqAllLangCodes = uniq(allLangCodes);

            const allCodes = countriesList.map((country) => country.code);
            const byCode = keyBy(countriesList, 'code');

            return {
                ...state,
                allCodes,
                byCode,
                list: countriesList,
                allLangCodes: uniqAllLangCodes,
                byLangCode,
                request: requestStatus.ok,
            };
        }

        case COUNTRIES_DICT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
