import React from 'react';
import PhoneNumberDropdownInput, {
    PropsT as PhoneNumberDropdownInputPropsT,
} from './PhoneNumberDropdownInput/PhoneNumberDropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { selectCountriesDict } from 'common/store/countries-dict/selectors';

type PropsT = Omit<PhoneNumberDropdownInputPropsT, 'allCountries'>;

const PhoneNumberInput: React.FC<PropsT> = React.memo((props) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const allCountries = useSelector(selectCountriesDict);

    return <PhoneNumberDropdownInput {...props} allCountries={allCountries} />;
});

export default PhoneNumberInput;
