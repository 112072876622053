import * as React from 'react';
import { ApiShipperContractStatusT, ShipperContractStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiShipperContractStatusT | null | undefined;
};

export const SHIPPER_CONTRACT_STATUS_T_MAP: Record<ApiShipperContractStatusT, string> = {
    [ShipperContractStatusEnum.revoked]: 'common:shipper-contract-status.REVOKED',
    [ShipperContractStatusEnum.expired]: 'common:shipper-contract-status.EXPIRED',
    [ShipperContractStatusEnum.active]: 'common:shipper-contract-status.ACTIVE',
};

const ShipperContractStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(SHIPPER_CONTRACT_STATUS_T_MAP[status]);
});

export default ShipperContractStatus;
