import * as React from 'react';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, AssetTypeEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectGPSIsChecking, selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import { checkGPSStatusRequest, reset } from 'common/store/gps-tracking-status/actions';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';

type PropsT = {
    className?: string;
    plateNumber: string;
    assetType: AssetTypeEnum;
    companyId: CompanyIdT;
};

const GPSTrackingStatusAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, plateNumber, assetType, companyId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(checkGPSStatusRequest({ companyId, assetType, plateNumber }));

        return () => {
            dispatch(reset());
        };
    }, []);

    React.useEffect(() => {
        dispatch(checkGPSStatusRequest({ companyId, assetType, plateNumber }));
    }, [companyId, assetType, plateNumber]);

    const isChecking = useSelector(selectGPSIsChecking);
    const gpsStatus = useSelector(selectGPSStatus);

    const infoIconNode = <InfoIcon fillColor={StyleGuideColorsEnum.white} />;
    const loaderIconNode = <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;

    let theme: AlertThemeEnum | null = null;
    let text = '';

    if (gpsStatus === GPStatusEnum.unable) {
        theme = AlertThemeEnum.orange;
        text = t('common:trucks-page.add-truck.gps-tracking-status.dedicated-unable');
    }

    if (gpsStatus === GPStatusEnum.enabled) {
        theme = AlertThemeEnum.brandAccent;
        text = t('common:trucks-page.add-truck.gps-tracking-status.dedicated-enabled');
    }

    if (!gpsStatus) {
        theme = AlertThemeEnum.gray;
        text = plateNumber
            ? t('common:trucks-page.add-truck.gps-tracking-status.loading')
            : t('common:trucks-page.add-truck.gps-tracking-status.empty');
    }

    return (
        <Alert
            className={className}
            icon={isChecking ? loaderIconNode : infoIconNode}
            size={AlertSizeEnum.small}
            theme={theme || AlertThemeEnum.gray}
        >
            {text}
        </Alert>
    );
});

export default GPSTrackingStatusAlert;
