import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ErrorDetails.scss';

import { useTranslation } from 'react-i18next';
import { formatDate } from 'common/utils/time';

import { ErrorActionT } from 'common/store/errors/models';
import { checkIsFetchError } from 'common/utils/api/errors/fetch-error';

const cx = classNames.bind(styles);

type PropsT = {
    errorLog: ErrorActionT;
};

const ErrorDetails: React.FC<PropsT> = React.memo((props) => {
    const { errorLog } = props;
    const { t } = useTranslation();

    return (
        <div className={cx('content')}>
            <div className={cx('time')}>{formatDate('HH:mm:ss - D MMMM YYYY', errorLog.timestamp)}</div>
            <div>
                <b>{t('common:error-modal.details.message')}:</b> {errorLog.error?.message}
            </div>
            {checkIsFetchError(errorLog.error) && (
                <>
                    <div className={cx('method')}>
                        <b>{t('common:error-modal.details.method')}</b>: [{errorLog.error?.requestConfig.method}]{' '}
                        {errorLog.error?.requestConfig.path}
                    </div>
                    <div>
                        <b>{t('common:error-modal.details.request')}</b>:{' '}
                        {JSON.stringify(errorLog.error?.requestConfig || '', null, 4)}
                    </div>
                    <div>
                        <b>{t('common:error-modal.details.http-status-code')}</b>: {errorLog.error?.httpStatusCode}
                    </div>
                    <div className={cx('response')}>
                        <b>{t('common:error-modal.details.response')}</b>:{' '}
                        {JSON.stringify(errorLog.error?.response || '', null, 4)}
                    </div>
                </>
            )}
        </div>
    );
});

export default ErrorDetails;
