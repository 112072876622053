import { UnitTypeEnum } from 'common/constants';
import { ApiLocationAddressT } from 'mobile-app/src/utils/api/carrier-tranziit/models';
import { FormikTouched } from 'formik';

export enum ShipmentFieldsEnum {
    fakeId = 'fakeId',

    pickUpPointId = 'pickUpPointId',
    dropOffPointId = 'dropOffPointId',

    cargoUnitsCount = 'cargoUnitsCount',
    cargoUnitType = 'cargoUnitType',
    cargoWeight = 'cargoWeight',
    cargoTemperatureRange = 'cargoTemperatureRange',
    cargoDescription = 'cargoDescription',

    poNumber = 'poNumber',
    pickUpReferenceNumber = 'pickUpReferenceNumber',
    dropOffReferenceNumber = 'dropOffReferenceNumber',
}

export enum PointContactsFieldsEnum {
    companyName = 'companyName',
    fullName = 'fullName',
    email = 'email',
    phoneNumber = 'phoneNumber',
    comment = 'comment',
}

export enum FieldsEnum {
    shipments = 'shipments',
    contacts = 'contacts',
}

export type TemperatureRangeT = [number, number];

export type ShipmentFormValuesT = {
    fakeId: string | null;
    pickUpPointId: RoutePointIdT | null;
    dropOffPointId: RoutePointIdT | null;
    cargoUnitsCount: string;
    cargoUnitType: UnitTypeEnum;
    cargoWeight: string;
    cargoTemperatureRange: TemperatureRangeT;
    cargoDescription: string;
    poNumber: string;
    pickUpReferenceNumber: string;
    dropOffReferenceNumber: string;
};

export type PointContactsFormValuesT = {
    companyName: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    comment: string;
};

export type FormValuesT = {
    shipments: Array<ShipmentFormValuesT>;
    contacts: Array<PointContactsFormValuesT>;
};

export type FormErrorsT = Partial<{
    shipments: Array<
        Partial<{
            fakeId: FormErrorMessageT;
            pickUpPointId: FormErrorMessageT;
            dropOffPointId: FormErrorMessageT;
            cargoUnitsCount: FormErrorMessageT;
            cargoUnitType: FormErrorMessageT;
            cargoWeight: FormErrorMessageT;
            cargoTemperatureRange: FormErrorMessageT;
            cargoDescription: FormErrorMessageT;
            poNumber: FormErrorMessageT;
            pickUpReferenceNumber: FormErrorMessageT;
            dropOffReferenceNumber: FormErrorMessageT;
        }>
    >;
    contacts: Array<
        Partial<{
            companyName: FormErrorMessageT;
            fullName: FormErrorMessageT;
            email: FormErrorMessageT;
            phoneNumber: FormErrorMessageT;
            comment: FormErrorMessageT;
        }>
    >;
}>;

export const CARGO_DETAILS_FORM_NAME = 'details-order-form/v1.2';

export type RoutePointT = {
    id: RoutePointIdT;
    index?: number;
    driveThru?: boolean;
    address: ApiLocationAddressT | null;
};

export type FormTouchedT = OverwriteT<
    FormikTouched<FormValuesT>,
    {
        shipments?: Array<FormikTouched<ShipmentFormValuesT>>;
        contacts?: Array<FormikTouched<PointContactsFormValuesT>>;
    }
>;
