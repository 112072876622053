import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RadioGroup.scss';
import cs from 'classnames';
import Radio from '../Radio/Radio';

const cx = classNames.bind(styles);

export type RadioGroupOptionT<ValueT> = {
    className?: string;
    value: ValueT;
    isDisabled?: boolean;
    label: React.ReactNode;
    rightIcon?: React.ReactNode;
};

export type PropsT<ValueT> = {
    className?: string;
    selectedValue: ValueT;
    onChange: (value: ValueT, event: React.MouseEvent<HTMLDivElement>) => void;
    testSelector?: string;
    options: RadioGroupOptionT<ValueT>[];
};

const RadioGroup = <ValueT,>(props: PropsT<ValueT>): React.ReactElement => {
    const { className, selectedValue, onChange, options, testSelector } = props;

    const fullTestSelector = `${testSelector}_radio`;

    return (
        <div className={cs(cx('group'), className)} data-test-selector={fullTestSelector}>
            {options.map((option, optionIndex) => (
                <Radio<ValueT>
                    key={optionIndex}
                    className={cs(cx('option'), option.className)}
                    value={option.value}
                    selectedValue={selectedValue}
                    onChange={onChange}
                    isDisabled={option.isDisabled}
                    label={option.label}
                    rightIcon={option.rightIcon}
                    testSelector={testSelector}
                />
            ))}
        </div>
    );
};

export default React.memo(RadioGroup) as typeof RadioGroup;
