import { DictDocumentByIdT, DictDocumentByTypeT, DictDocumentT } from './models';
import { PartnerTypeT } from 'common/utils/api/models';
import { RequestStatusT } from 'common/utils/request-status';

export const DOCUMENTS_DICT_REQUEST = 'common/documents-dict/DOCUMENTS_DICT_REQUEST';
export const DOCUMENTS_DICT_REQUEST_BEGIN = 'common/documents-dict/DOCUMENTS_DICT_REQUEST_BEGIN';
export const DOCUMENTS_DICT_REQUEST_SUCCESS = 'common/documents-dict/DOCUMENTS_DICT_REQUEST_SUCCESS';
export const DOCUMENTS_DICT_REQUEST_ERROR = 'common/documents-dict/DOCUMENTS_DICT_REQUEST_ERROR';

export type FetchDocumentsDictActionT = {
    type: typeof DOCUMENTS_DICT_REQUEST;
    partnerType: PartnerTypeT | null;
};

export type FetchDocumentsDictBeginActionT = {
    type: typeof DOCUMENTS_DICT_REQUEST_BEGIN;
    partnerType: PartnerTypeT;
};

export type FetchDocumentsDictSuccessActionT = {
    type: typeof DOCUMENTS_DICT_REQUEST_SUCCESS;
    documentsList: DictDocumentT[];
    partnerType: PartnerTypeT;
};

export type FetchDocumentsDictErrorActionT = {
    type: typeof DOCUMENTS_DICT_REQUEST_ERROR;
    partnerType: PartnerTypeT;
    error: Error;
};

export type DocumentsDictActionT =
    | FetchDocumentsDictActionT
    | FetchDocumentsDictBeginActionT
    | FetchDocumentsDictSuccessActionT
    | FetchDocumentsDictErrorActionT;

export type PartnerTypeDocumentsStateT = {
    byId: DictDocumentByIdT;
    byType: DictDocumentByTypeT;
    ids: DictDocumentIdT[];
    fetchRequest: RequestStatusT;
};

export type DocumentsDictStateT = Partial<Record<PartnerTypeT, PartnerTypeDocumentsStateT>>;
