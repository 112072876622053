import requestStatus from 'common/utils/request-status';
import {
    ShipperSuggestActionT,
    ShipperSuggestStateT,
    FIND_SHIPPERS_REQUEST_BEGIN,
    FIND_SHIPPERS_REQUEST_ERROR,
    FIND_SHIPPERS_REQUEST_SUCCESS,
    RESET,
} from './types';
import { PartnerT } from './models';
import { isNonNil } from 'common/utils';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialShipperSuggestState: ShipperSuggestStateT = {
    query: null,
    requestStatus: requestStatus.initial,
    ids: [],
    byId: {},
};

export default (
    state = initialShipperSuggestState,
    action: ShipperSuggestActionT | DestroySessionActionT,
): ShipperSuggestStateT => {
    switch (action.type) {
        case FIND_SHIPPERS_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                query,
                requestStatus: requestStatus.loading,
            };
        }

        case FIND_SHIPPERS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                requestStatus: requestStatus.setError(error),
            };
        }

        case FIND_SHIPPERS_REQUEST_SUCCESS: {
            const { partners } = action;

            const byId = partners.reduce<Record<string, PartnerT>>((acc, partner) => {
                if (!partner?.id) {
                    return acc;
                }

                return {
                    ...acc,
                    [partner.id]: {
                        ...partner,
                    },
                };
            }, {});

            const ids = partners.map((partner) => partner.id).filter(isNonNil);

            return {
                ...state,
                requestStatus: requestStatus.ok,
                ids,
                byId: {
                    ...state.byId,
                    ...byId,
                },
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialShipperSuggestState,
            };
        }

        default:
            return state;
    }
};
