import { FieldsEnum, FormValuesT } from './constants';
import { ApiRevokePartnerContractInfoT, RevokeContractActionEnum } from 'common/utils/api/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';

const prepareRevokeInfo = (formValues: FormValuesT): ApiRevokePartnerContractInfoT | null => {
    const revokeInfo: ApiRevokePartnerContractInfoT = {
        action: formValues[FieldsEnum.action],
    };

    switch (formValues[FieldsEnum.action]) {
        case RevokeContractActionEnum.setNewContract: {
            revokeInfo.newContractId = formValues[FieldsEnum.nextContractId] || undefined;
            break;
        }
        case RevokeContractActionEnum.setNewRate: {
            revokeInfo.boxRateKm = parseSimpleNumber(formValues[FieldsEnum.boxesRatePerKm]);
            revokeInfo.tiltRateKm = parseSimpleNumber(formValues[FieldsEnum.tiltsRatePerKm]);
            revokeInfo.truckRateKm = parseSimpleNumber(formValues[FieldsEnum.trucksRatePerKm]);
            break;
        }
        case RevokeContractActionEnum.keepOldRate:
        default: {
            // nothing
            break;
        }
    }

    return revokeInfo;
};

export { prepareRevokeInfo };
