import { RequestStatusT } from 'common/utils/request-status';
import { CompanyChangesT, CompanyT } from './models';

export const FETCH_COMPANY_REQUEST = 'common/company/FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_REQUEST_BEGIN = 'common/company/FETCH_COMPANY_REQUEST_BEGIN';
export const FETCH_COMPANY_REQUEST_ERROR = 'common/company/FETCH_COMPANY_REQUEST_ERROR';
export const FETCH_COMPANY_REQUEST_SUCCESS = 'common/company/FETCH_COMPANY_REQUEST_SUCCESS';

export const UPDATE_COMPANY_REQUEST = 'common/company/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_REQUEST_BEGIN = 'common/company/UPDATE_COMPANY_REQUEST_BEGIN';
export const UPDATE_COMPANY_REQUEST_ERROR = 'common/company/UPDATE_COMPANY_REQUEST_ERROR';
export const UPDATE_COMPANY_REQUEST_SUCCESS = 'common/company/UPDATE_COMPANY_REQUEST_SUCCESS';

export type CompanyStateT = {
    currentCompanyRequest: RequestStatusT;
    updateCompanyRequest: RequestStatusT;
    currentCompany: CompanyT | null;
};

export type FetchCompanyActionT = {
    type: typeof FETCH_COMPANY_REQUEST;
    options?: FetchOptionsT;
};

export type FetchCompanyBeginActionT = {
    type: typeof FETCH_COMPANY_REQUEST_BEGIN;
};

export type FetchCompanySuccessActionT = {
    type: typeof FETCH_COMPANY_REQUEST_SUCCESS;
    currentCompany: CompanyT | null;
};

export type FetchCompanyErrorActionT = {
    type: typeof FETCH_COMPANY_REQUEST_ERROR;
    error: Error;
};

export type UpdateCompanyActionT = {
    type: typeof UPDATE_COMPANY_REQUEST;
    companyChanges: CompanyChangesT;
    company: CompanyT;
};

export type UpdateCompanyBeginActionT = {
    type: typeof UPDATE_COMPANY_REQUEST_BEGIN;
};

export type UpdateCompanySuccessActionT = {
    type: typeof UPDATE_COMPANY_REQUEST_SUCCESS;
    currentCompany: CompanyT | null;
};

export type UpdateCompanyErrorActionT = {
    type: typeof UPDATE_COMPANY_REQUEST_ERROR;
    error: Error;
};

export type CompanyActionT =
    | FetchCompanyActionT
    | FetchCompanyBeginActionT
    | FetchCompanySuccessActionT
    | FetchCompanyErrorActionT
    | UpdateCompanyActionT
    | UpdateCompanyBeginActionT
    | UpdateCompanySuccessActionT
    | UpdateCompanyErrorActionT;
