import * as React from 'react';
import ProfileMembers from 'common/layouts/BaseProfileLayout/ProfileMembers/ProfileMembers';
import classNames from 'classnames/bind';
import styles from './TeamPage.scss';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';

import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'common/layouts/SideBars/SideBars';

const cx = classNames.bind(styles);

type PropsT = {};

const TeamPage: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t('common:page-titles.team')} />
            <ScrollableContent>
                <ContentMargins>
                    <TopBar>
                        <TopBarContent title={t('common:team-page.title')} rightNode={<NotificationsBarTrigger />} />
                    </TopBar>
                    <ProfileMembers />
                </ContentMargins>
                <SideBars />
            </ScrollableContent>
        </>
    );
});

export default TeamPage;
