import {
    ApiReservePreviewQueryT,
    ReservePreviewQueryChangesT,
    ReservePreviewQueryT,
    ReserveQueryChangesT,
    ReserveT,
} from '../models';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import keyBy from 'lodash/keyBy';

const setReversePreviewDefaultQuery = (
    queryChanges: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ReservePreviewQueryT | null => {
    if (!queryChanges) {
        return null;
    }

    const newQueryChanges: ReservePreviewQueryT = { ...queryChanges };

    reserve?.points?.forEach((point, index) => {
        if (!newQueryChanges.timeWindows) {
            newQueryChanges.timeWindows = [];
        }

        if (!newQueryChanges.timeWindows[index]) {
            newQueryChanges.timeWindows[index] = {};
        }

        if (point.defaultTimeWindow?.start && !newQueryChanges.timeWindows[index].timeWindowFrom) {
            newQueryChanges.timeWindows[index].timeWindowFrom = point.defaultTimeWindow?.start;
        }

        if (point.defaultTimeWindow?.end && !newQueryChanges.timeWindows[index].timeWindowTo) {
            newQueryChanges.timeWindows[index].timeWindowTo = point.defaultTimeWindow?.end;
        }
    });

    return newQueryChanges;
};

const getPreviewReverseQueryChanges = (
    changes: ReserveQueryChangesT,
    reserve: ReserveT | null,
): ReservePreviewQueryChangesT => {
    const queryChanges: ReservePreviewQueryChangesT = {};

    const laneId = changes?.laneId;
    if (!isUndefined(laneId)) {
        queryChanges.laneId = laneId;
    }

    const routeChanges = changes?.route || [];
    routeChanges.forEach((routePointChanges, index) => {
        if (!queryChanges?.timeWindows) {
            queryChanges.timeWindows = [];
        }

        if (!queryChanges.timeWindows?.[index]) {
            queryChanges.timeWindows[index] = {};
        }

        const timeWindowFrom = routePointChanges?.timeWindowFrom;
        if (!isUndefined(timeWindowFrom)) {
            queryChanges.timeWindows[index].timeWindowFrom = timeWindowFrom;
        }

        const timeWindowTo = routePointChanges?.timeWindowTo;
        if (!isUndefined(timeWindowTo)) {
            queryChanges.timeWindows[index].timeWindowTo = timeWindowTo;
        }

        const isBrokerTimeWindow = routePointChanges?.isBrokerTimeWindow;
        if (isBoolean(isBrokerTimeWindow)) {
            queryChanges.timeWindows[index].isBrokerTimeWindow = isBrokerTimeWindow;
        }

        const id = reserve?.points?.[index]?.id;
        if (!isUndefined(id)) {
            queryChanges.timeWindows[index].id = id;
        }
    });

    const reserveId = reserve?.reserveId || null;
    if (reserveId) {
        queryChanges.reserveId = reserveId;
    }

    return queryChanges;
};

const mergePreviewReverseQuery = (
    prevQuery: ReservePreviewQueryT | null,
    changes: ReservePreviewQueryChangesT,
): ReservePreviewQueryT | null => {
    const query: ReservePreviewQueryT = {
        timeWindows: [],
        reserveId: null,
        laneId: null,
        ...prevQuery,
        ...changes,
    };

    return query;
};

const getApiPreviewReverseQuery = (
    query: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ApiReservePreviewQueryT | null => {
    if (!query) {
        return null;
    }

    const { reserveId } = query;
    if (!reserveId) {
        return null;
    }

    const points = reserve?.points || [];
    const pointsById = keyBy(points, (point) => point.id);

    const timeWindows = points
        .map((point, index) => {
            const timeWindow = query.timeWindows[index] || null;

            return {
                brokerWindow: !!timeWindow?.isBrokerTimeWindow,
                from: timeWindow?.timeWindowFrom || null,
                to: timeWindow?.timeWindowTo || null,
                id: point.id,
            };
        })
        .filter((timeWindow) => {
            const point = pointsById[timeWindow?.id as string];

            return !point?.driveThru;
        });

    const checkIsValidTimeWindow = (
        timeWindow: (typeof timeWindows)[number],
    ): timeWindow is NonNullable<ApiReservePreviewQueryT['timeWindows']>[number] => {
        return !!timeWindow?.from && !!timeWindow?.to && !!timeWindow?.id;
    };

    const isValidTimeWindows = timeWindows?.every(checkIsValidTimeWindow) && !!timeWindows?.length;
    if (!isValidTimeWindows) {
        return null;
    }

    return {
        timeWindows,
        reserveId,
    };
};

export {
    getPreviewReverseQueryChanges,
    mergePreviewReverseQuery,
    getApiPreviewReverseQuery,
    setReversePreviewDefaultQuery,
};
