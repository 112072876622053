import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { PriceOfferT } from 'common/store/order-creation/models';
import getDatesHash from './get-dates-hash';
import sortOffers from 'common/store/order-creation/utils/sort-offers';

const groupOffersByDatesHash = (priceOffers: Array<PriceOfferT>): Record<string, Array<PriceOfferIdT>> => {
    const groupOffers = groupBy(priceOffers, (offer) => getDatesHash(offer.pickUpDate, offer.dropOffDate));

    const sortedGroupOffers = mapValues(groupOffers, (offers) => {
        return sortOffers(offers).map((offer) => offer.id);
    });

    return sortedGroupOffers;
};

export default groupOffersByDatesHash;
