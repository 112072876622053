import requestStatus from 'common/utils/request-status';

import {
    CarrierContractsActionT,
    CarrierContractsStateT,
    CarrierContractStateT,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
    FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN,
    FETCH_CARRIER_CONTRACTS_REQUEST_ERROR,
    FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS,
    UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN,
    UPDATE_CARRIER_CONTRACT_REQUEST_ERROR,
    UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS,
    UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCarrierContractState: CarrierContractStateT = {
    contracts: [],
    fetchContractsRequest: requestStatus.initial,
    updateContractRequest: requestStatus.initial,
    uploadContractRequest: requestStatus.initial,
    downloadContractRequest: requestStatus.initial,
    updatingContractId: null,
};

const initialState: CarrierContractsStateT = {};

export default (
    state = initialState,
    action: CarrierContractsActionT | DestroySessionActionT,
): CarrierContractsStateT => {
    switch (action.type) {
        case FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    fetchContractsRequest: requestStatus.loading,
                },
            };
        }
        case FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS: {
            const { partnerId, partnerContracts } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    contracts: partnerContracts,
                    fetchContractsRequest: requestStatus.ok,
                },
            };
        }
        case FETCH_CARRIER_CONTRACTS_REQUEST_ERROR: {
            const { error, partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    fetchContractsRequest: requestStatus.setError(error),
                },
            };
        }
        case UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN: {
            const { partnerId, updatingContractId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    updatingContractId,
                    updateContractRequest: requestStatus.loading,
                },
            };
        }
        case UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    updateContractRequest: requestStatus.ok,
                },
            };
        }
        case UPDATE_CARRIER_CONTRACT_REQUEST_ERROR: {
            const { error, partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    updateContractRequest: requestStatus.setError(error),
                },
            };
        }

        case UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    uploadContractRequest: requestStatus.loading,
                },
            };
        }
        case UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    uploadContractRequest: requestStatus.ok,
                },
            };
        }
        case UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR: {
            const { error, partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    uploadContractRequest: requestStatus.setError(error),
                },
            };
        }

        case DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    downloadContractRequest: requestStatus.loading,
                },
            };
        }
        case DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS: {
            const { partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    downloadContractRequest: requestStatus.ok,
                },
            };
        }
        case DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR: {
            const { error, partnerId } = action;

            const carrierContractState = state[partnerId] || initialCarrierContractState;

            return {
                ...state,
                [partnerId]: {
                    ...carrierContractState,
                    downloadContractRequest: requestStatus.setError(error),
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
