import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Textarea.scss';
import useTestSelector from 'common/utils/hooks/useTestSelector';

const cx = classNames.bind(styles);

type ValueT = string;

export type PropsT = {
    className?: string;
    name: string;
    value: ValueT;
    placeholder?: string;
    onChange: (value: ValueT) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    autoFocus?: boolean;
    isDisabled?: boolean;
    isFocused?: boolean;
    isTransparent?: boolean;
    isHighlighted?: boolean;
    hasChanges?: boolean;
    autoComplete?: string;
    testSelectorPrefix?: string;
};

const Textarea: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        name,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        onKeyUp,
        hasError,
        hasWarning,
        hasSuccess,
        autoFocus,
        isDisabled,
        isHighlighted,
        isFocused: isForceFocused,
        isTransparent,
        className,
        hasChanges,
        autoComplete,
        testSelectorPrefix,
    } = props;

    const [isFocused, setFocused] = React.useState<boolean>(false);
    const [isHovered, setHovered] = React.useState<boolean>(false);

    const handleChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
        const sourceValue = event.currentTarget.value || '';
        onChange(sourceValue);
    };

    const handleBlur = (): void => {
        setFocused(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleFocus = (): void => {
        setFocused(true);

        if (onFocus) {
            onFocus();
        }
    };

    const handleMouseMove = (): void => {
        if (!isHovered) {
            setHovered(true);
        }
    };

    const handleMouseLeave = (): void => {
        setHovered(false);
    };

    const isEmpty = !value;

    const testSelector = useTestSelector(testSelectorPrefix || name, 'textarea');

    const textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
        autoFocus: !!autoFocus,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onKeyUp,
        name,
        value: value || '',
        placeholder,
        disabled: isDisabled,
        autoComplete: autoComplete || 'off',
        // @ts-ignore
        'data-test-selector': testSelector,
    };

    return (
        <textarea
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            {...textareaProps}
            className={cs(
                cx('textarea', {
                    'textarea--hasWarning': hasWarning,
                    'textarea--hasSuccess': hasSuccess,
                    'textarea--hasChanges': hasChanges,
                    'textarea--isHighlighted': isHighlighted,
                    'textarea--hasError': hasError,
                    'textarea--isDisabled': isDisabled,
                    'textarea--isHovered': isHovered,
                    'textarea--isTransparent': isTransparent,
                    'textarea--isFocused': isFocused || isForceFocused,
                }),
                cx('inner-textarea', {
                    'inner-textarea--isEmpty': isEmpty,
                    'inner-textarea--isDisabled': isDisabled,
                }),
                className,
            )}
        />
    );
});

export default Textarea;
