import { useState } from 'react';
import { OrderCreationPercentContextT } from '../contexts/order-creation-percent-context';

export const useOrderCreationPercentContextValue = (): OrderCreationPercentContextT => {
    const [percent, setPercent] = useState<number>(0);

    return {
        percent,
        setPercent,
    };
};
