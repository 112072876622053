export enum RateSourceEnum {
    current = 'current',
    new = 'new',
}

export enum FieldsEnum {
    source = 'source',
    rate = 'rate',
}

export type FormValuesT = {
    [FieldsEnum.source]: RateSourceEnum;
    [FieldsEnum.rate]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
