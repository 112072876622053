import {
    CompanyDocumentsStateT,
    DocumentsActionT,
    DocumentsStateT,
    DOWNLOAD_DOCUMENT_REQUEST_BEGIN,
    DOWNLOAD_DOCUMENT_REQUEST_ERROR,
    DOWNLOAD_DOCUMENT_REQUEST_SUCCESS,
    FETCH_DOCUMENTS_REQUEST_BEGIN,
    FETCH_DOCUMENTS_REQUEST_ERROR,
    FETCH_DOCUMENTS_REQUEST_SUCCESS,
    RESET,
    UPLOAD_DOCUMENT_REQUEST_BEGIN,
    UPLOAD_DOCUMENT_REQUEST_ERROR,
    UPLOAD_DOCUMENT_REQUEST_SUCCESS,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    UPDATE_DOCUMENT_REQUEST_BEGIN,
    UPDATE_DOCUMENT_REQUEST_ERROR,
    UPDATE_DOCUMENT_REQUEST_SUCCESS,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
} from './types';
import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyDocumentsState: CompanyDocumentsStateT = {
    idsByType: null,
    byId: null,
    ids: null,
    uploadRequest: requestStatus.initial,
    uploadingDictDocumentId: null,
    updateRequest: requestStatus.initial,
    updatingDocumentId: null,
    fetchDocumentsRequest: requestStatus.initial,
    downloadDocumentRequest: requestStatus.initial,
    evidenceOfTaxDetails: null,
    fetchEvidenceOfTaxDetailsRequest: requestStatus.initial,
    updateEvidenceOfTaxDetailsRequest: requestStatus.initial,
};

const initialState: DocumentsStateT = {};

export default (state = initialState, action: DocumentsActionT | DestroySessionActionT): DocumentsStateT => {
    switch (action.type) {
        case FETCH_DOCUMENTS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    fetchDocumentsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_DOCUMENTS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    fetchDocumentsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_DOCUMENTS_REQUEST_SUCCESS: {
            const { companyId, documentIdsByType, documentsList } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            const ids = documentsList.map((document) => document.id);
            const byId = keyBy(documentsList, 'id');

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    idsByType: documentIdsByType,
                    byId,
                    ids,
                    fetchDocumentsRequest: requestStatus.ok,
                },
            };
        }

        case DOWNLOAD_DOCUMENT_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    downloadDocumentRequest: requestStatus.loading,
                },
            };
        }

        case DOWNLOAD_DOCUMENT_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    downloadDocumentRequest: requestStatus.setError(error),
                },
            };
        }

        case DOWNLOAD_DOCUMENT_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    downloadDocumentRequest: requestStatus.ok,
                },
            };
        }

        case UPLOAD_DOCUMENT_REQUEST_BEGIN: {
            const { companyId, dictDocumentId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    uploadingDictDocumentId: dictDocumentId,
                    uploadRequest: requestStatus.loading,
                },
            };
        }

        case UPLOAD_DOCUMENT_REQUEST_ERROR: {
            const { error } = action;

            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    uploadingDictDocumentId: null,
                    uploadRequest: requestStatus.setError(error),
                },
            };
        }

        case UPLOAD_DOCUMENT_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    uploadingDictDocumentId: null,
                    uploadRequest: requestStatus.ok,
                },
            };
        }

        case UPDATE_DOCUMENT_REQUEST_BEGIN: {
            const { companyId, documentId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateRequest: requestStatus.loading,
                    updatingDocumentId: documentId,
                },
            };
        }

        case UPDATE_DOCUMENT_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateRequest: requestStatus.setError(error),
                },
            };
        }

        case UPDATE_DOCUMENT_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateRequest: requestStatus.ok,
                },
            };
        }

        case UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateEvidenceOfTaxDetailsRequest: requestStatus.loading,
                },
            };
        }

        case UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateEvidenceOfTaxDetailsRequest: requestStatus.setError(error),
                },
            };
        }

        case UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    updateEvidenceOfTaxDetailsRequest: requestStatus.ok,
                },
            };
        }

        case FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    fetchEvidenceOfTaxDetailsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    fetchEvidenceOfTaxDetailsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS: {
            const { companyId, evidenceOfTaxDetails } = action;

            const companyDocumentsState = state[companyId] || initialCompanyDocumentsState;

            return {
                ...state,
                [companyId]: {
                    ...companyDocumentsState,
                    evidenceOfTaxDetails,
                    fetchEvidenceOfTaxDetailsRequest: requestStatus.ok,
                },
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
