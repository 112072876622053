import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './InvoicesTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import { ShipperInvoiceT } from 'shipper/store/invoices/models';
import IdCell from 'common/components/Table/cell-renderers/IdCell/IdCell';
import ActionsCell from './cell-renderers/ActionsCell/ActionsCell';
import OrderCell from './cell-renderers/OrderCell/OrderCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import { LinkToT } from 'common/components/Link/Link';
import InvoiceStatusPill from 'common/components/status-pill/InvoiceStatusPill/InvoiceStatusPill';

const cx = classNames.bind(styles);

const DUE_DATE_FORMAT = 'DD MMM YYYY';
const CREATED_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

type PropsT = {
    invoices: Array<ShipperInvoiceT>;
    className: string;
    isLoading: boolean;
    getOrderDetailsLink: (orderId: OrderIdT) => LinkToT;
};

const InvoicesTable: React.FC<PropsT> = React.memo((props) => {
    const { invoices, className, getOrderDetailsLink, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ShipperInvoiceT, void>> = [
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.number')}</span>,
            headerClassName: cx('table__header', 'table__header--number'),
            render: (invoice: ShipperInvoiceT) => <IdCell id={invoice?.invoiceNumber} />,
            className: cx('table__column', 'table__column--number'),
            testSelector: 'number',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.order')}</span>,
            headerClassName: cx('table__header', 'table__header--order'),
            render: (invoice: ShipperInvoiceT) => (
                <OrderCell invoice={invoice} getOrderDetailsLink={getOrderDetailsLink} />
            ),
            className: cx('table__column', 'table__column--order'),
            testSelector: 'order',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.amount')}</span>,
            headerClassName: cx('table__header'),
            render: (invoice: ShipperInvoiceT) => <CostCell cost={invoice?.amount} />,
            className: cx('table__column', 'table__column--amount'),
            testSelector: 'amount',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.created')}</span>,
            headerClassName: cx('table__header'),
            render: (invoice: ShipperInvoiceT) => (
                <DateCell date={invoice?.createDate} dateFormat={CREATED_DATE_FORMAT} isBigFont isBold />
            ),
            className: cx('table__column', 'table__column--created'),
            testSelector: 'created',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.due')}</span>,
            headerClassName: cx('table__header'),
            render: (invoice: ShipperInvoiceT) => (
                <DateCell date={invoice?.dueDate} dateFormat={DUE_DATE_FORMAT} isBigFont isBold />
            ),
            className: cx('table__column', 'table__column--due'),
            testSelector: 'due',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (invoice: ShipperInvoiceT) => <InvoiceStatusPill isSymmetrical status={invoice?.status} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
        {
            renderHeader: () => <span>{t('invoices.list.table.columns.actions')}</span>,
            headerClassName: cx('table__header', 'table__header--actions'),
            render: (invoice: ShipperInvoiceT) => <ActionsCell invoice={invoice} />,
            className: cx('table__column', 'table__column--actions'),
            testSelector: 'actions',
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, invoice: ShipperInvoiceT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ShipperInvoiceT> tableName="invoices" isLoading={isLoading} rows={invoices}>
                {(rows, isUsedPrevRows) => (
                    <Table<ShipperInvoiceT, void>
                        testSelector="invoices"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default InvoicesTable;
