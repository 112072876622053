import {
    RFQDetailsActionT,
    RFQDetailsByIdStateT,
    RFQDetailsStateT,
    FETCH_RFQ_DETAILS_REQUEST_BEGIN,
    FETCH_RFQ_DETAILS_REQUEST_ERROR,
    FETCH_RFQ_DETAILS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import isEqual from 'lodash/isEqual';

const initialState: RFQDetailsByIdStateT = {};

export const initialRFQDetailsState: RFQDetailsStateT = {
    details: null,
    defaultOffer: null,
    requestStatus: requestStatus.initial,
};

export default (state = initialState, action: RFQDetailsActionT | DestroySessionActionT): RFQDetailsByIdStateT => {
    switch (action.type) {
        case FETCH_RFQ_DETAILS_REQUEST_BEGIN: {
            const { id } = action;

            const rfqDetailsState = state[id] || initialRFQDetailsState;

            return {
                ...state,
                [id]: {
                    ...rfqDetailsState,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_RFQ_DETAILS_REQUEST_ERROR: {
            const { error, id } = action;

            const rfqDetailsState = state[id] || initialRFQDetailsState;

            return {
                ...state,
                [id]: {
                    ...rfqDetailsState,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_RFQ_DETAILS_REQUEST_SUCCESS: {
            const { details, defaultOffer, id } = action;

            const rfqDetailsState = state[id] || initialRFQDetailsState;

            return {
                ...state,
                [id]: {
                    ...rfqDetailsState,
                    requestStatus: requestStatus.ok,
                    details: isEqual(details, rfqDetailsState.details) ? rfqDetailsState.details : details,
                    defaultOffer,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
