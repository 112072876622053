import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneDetailsPage.scss';
import BaseShipperContractLaneDetailsLayout from 'common/layouts/BaseShipperContractLaneDetailsLayout/BaseShipperContractLaneDetailsLayout';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useParams } from 'react-router-dom';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { urlFactory } from 'common/utils/urls';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectShipperContractLaneDetailsState } from 'common/store/shipper-contract-lane-details/selectors';
import { selectPermissions } from 'common/store/auth/selectors';

const cx = classNames.bind(styles);

type PropsT = {};

const ShipperContractLaneDetailsPage: React.FC<PropsT> = React.memo(() => {
    const params = useParams<{ contractId: string; laneId: string }>();
    const { laneId, contractId } = params;

    const permissions = useSelector(selectPermissions);

    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const { data } = useSelector(selectShipperContractLaneDetailsState(laneId));

    return (
        <>
            <PageTitle
                title={t('page-titles.shipper-lane-details', {
                    laneName: data?.tztLaneId || '',
                })}
            />
            <BaseShipperContractLaneDetailsLayout
                laneId={laneId}
                contractId={contractId}
                partnerId={partnerId}
                onClose={() => {
                    goBackIfHasRePath(() => {
                        history.push(
                            urlFactory.shipperContractLanes({
                                shipperContractId: contractId,
                            }),
                        );
                    });
                }}
                isReadOnly={!permissions.canEditShipperContracts}
            />
        </>
    );
});

export default ShipperContractLaneDetailsPage;
