import React from 'react';
import classNames from 'classnames/bind';

import styles from './ContactSidebarHeader.scss';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import { CompanyContactDetailsT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import { UserStatusEnum } from 'common/utils/api/models';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    contactDetails: CompanyContactDetailsT | null;
};

const colorsMap: Record<UserStatusEnum, StyleGuideColorsEnum> = {
    [UserStatusEnum.archived]: StyleGuideColorsEnum.tomatoRed,
    [UserStatusEnum.active]: StyleGuideColorsEnum.mediumGreen,
    [UserStatusEnum.pending]: StyleGuideColorsEnum.orange,
};

const ContactSidebarHeader: React.FC<PropsT> = (props) => {
    const { contactDetails } = props;

    const { t } = useTranslation();

    const fullName = `${contactDetails?.name} ${contactDetails?.surname}`;

    if (!contactDetails) {
        return null;
    }

    return (
        <div>
            <div className={cx('full-name')}>{fullName}</div>
            {contactDetails?.position && (
                <ColoredStatusLabel
                    label={t(`common:team-members.edit-contact.fields.position.labels.${contactDetails?.position}`)}
                    color={colorsMap[contactDetails?.userStatus as UserStatusEnum] || StyleGuideColorsEnum.light}
                />
            )}
        </div>
    );
};

export default ContactSidebarHeader;
