import { SharableStateT } from '../models';
import {
    initialShipperContractLaneDetailsItemState,
    ShipperContractLaneDetailsItemStateT,
    ShipperContractLaneDetailsStateT,
} from './slice';

export const selectShipperContractLaneDetailsState = (shipperContractLaneId: ShipperContractLaneIdT) => {
    return (state: SharableStateT): ShipperContractLaneDetailsItemStateT => {
        return (
            state.shipperContractLaneDetails.detailsById[shipperContractLaneId] ||
            initialShipperContractLaneDetailsItemState
        );
    };
};

export const selectUpdateShipperContractLaneRequest = (
    state: SharableStateT,
): ShipperContractLaneDetailsStateT['updateRequest'] => {
    return state.shipperContractLaneDetails.updateRequest;
};
