import React from 'react';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './PhoneNumberDropdownInput.scss';
import PhoneNumberDropdownTrigger from '../PhoneNumberDropdownTrigger/PhoneNumberDropdownTrigger';
import PhoneNumberDropdownOption from '../PhoneNumberDropdownOption/PhoneNumberDropdownOption';
import { CountriesListT } from 'common/store/countries-dict/models';
import {
    clearPhoneNumber,
    getCountryCode,
    getCountryPhoneNumberStart,
    parsePhoneNumber,
} from 'common/utils/phone-number';

const cx = classNames.bind(styles);

export enum OverlayPositionEnum {
    top = 'top',
    bottom = 'bottom',
}

export type PropsT = {
    name: string;
    value: string;
    onChange: (value: string) => void;

    autoFocus?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    isDisabled?: boolean;
    autoComplete?: string;

    testSelector?: string;

    hasWarning?: boolean;
    hasError?: boolean;
    hasChanges?: boolean;

    overlayPosition?: OverlayPositionEnum;
    overlayClassName?: string;

    allCountries: CountriesListT | null;

    className?: string;
};

const convertCountryCode = (dictCountryCode: string) => dictCountryCode.toUpperCase();

const PhoneNumberDropdownInput = (props: PropsT): React.ReactElement => {
    const {
        name,
        allCountries,
        onFocus,
        onBlur,
        isDisabled,
        hasWarning,
        hasError,
        overlayPosition,
        className,
        overlayClassName,
        hasChanges,
        testSelector,
        value,
        onChange,
    } = props;

    const [isFocused, setFocused] = React.useState(false);
    const [isHovered, setHovered] = React.useState<boolean>(false);

    const handleMouseMove = (): void => {
        if (!isHovered) {
            setHovered(true);
        }
    };

    const handleMouseLeave = (): void => {
        setHovered(false);
    };

    const handleFocus = () => {
        if (!isFocused) {
            setFocused(true);
        }

        if (onFocus) {
            onFocus();
        }
    };

    const handleBlur = () => {
        setFocused(false);

        if (onBlur) {
            onBlur();
        }
    };

    const [inputValue, setInputValue] = React.useState<string>((): string => {
        return clearPhoneNumber(value);
    });
    const [countryCode, setCountryCode] = React.useState<string>();
    const [isOpenCountryList, setOpenCountryList] = React.useState(false);

    const toggleOpenCountryList = (): void => {
        if (isDisabled) {
            return;
        }
        setOpenCountryList(!isOpenCountryList);
    };

    const handleChangePhoneNumber = (phoneNumber: string): void => {
        const clearedPhoneNumber = clearPhoneNumber(phoneNumber);
        setInputValue(clearedPhoneNumber);

        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
        if (value !== parsedPhoneNumber) {
            onChange(parsedPhoneNumber);
        }
    };

    React.useEffect(() => {
        if (!value) {
            setCountryCode('');
            setInputValue('');
            return;
        }

        const clearedPhoneNumber = clearPhoneNumber(value);
        setInputValue(clearedPhoneNumber);

        const parsedCountryCode = getCountryCode(value);
        setCountryCode(parsedCountryCode || '');
    }, [value]);

    const fullTestSelector = `${testSelector}_phone-number-input`;

    const handleOuterEvent = () => {
        setOpenCountryList(false);
    };

    return (
        <div className={cs(cx('dropdown'), className)}>
            <OutsideClickHandler onOutsideClick={handleOuterEvent} isDisabled={!isOpenCountryList}>
                <div className={cx('trigger-wrap')}>
                    <div
                        className={cx('trigger', {
                            'trigger--isFocus': isFocused || isOpenCountryList,
                            'trigger--isDisabled': isDisabled,
                            'trigger--hasChanges': hasChanges,
                            'trigger--hasWarning': hasWarning,
                            'trigger--hasError': hasError,
                            'trigger--isHovered': isHovered,
                        })}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <PhoneNumberDropdownTrigger
                            name={name}
                            countryCode={countryCode}
                            onClickCountryCode={toggleOpenCountryList}
                            phoneNumber={inputValue}
                            onChangePhoneNumber={handleChangePhoneNumber}
                            testSelector={fullTestSelector}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            isDisabled={isDisabled}
                        />
                    </div>
                    {isOpenCountryList && (
                        <div
                            className={cs(
                                cx('overlay', {
                                    [`overlay--${overlayPosition || OverlayPositionEnum.bottom}`]: true,
                                }),
                                overlayClassName,
                            )}
                        >
                            {(allCountries || []).map((country, index) => {
                                return (
                                    <PhoneNumberDropdownOption
                                        key={country.code}
                                        index={index}
                                        testSelector={fullTestSelector}
                                        countryCode={convertCountryCode(country.code)}
                                        label={country.userLangDisplayName}
                                        isSelected={convertCountryCode(country.code) === countryCode}
                                        onSelect={(selectedCountryCode) => {
                                            if (selectedCountryCode !== countryCode) {
                                                const countryPhoneNumberStart =
                                                    getCountryPhoneNumberStart(selectedCountryCode);

                                                setInputValue(countryPhoneNumberStart);
                                                setCountryCode(selectedCountryCode);
                                                onChange(countryPhoneNumberStart);
                                            }

                                            setOpenCountryList(false);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default PhoneNumberDropdownInput;
