import { SharableStateT } from 'common/store/models';
import { OrderDetailsStateT } from './types';
import { RequestStatusT } from 'common/utils/request-status';
import { initialOrderState } from './reducer';

export const selectOrder =
    (id: OrderIdT) =>
    (state: SharableStateT): OrderDetailsStateT['order'] =>
        state.orderDetails[id]?.order || initialOrderState.order;

export const selectOrderRequestStatus =
    (id: OrderIdT) =>
    (state: SharableStateT): RequestStatusT =>
        state.orderDetails[id]?.orderRequestStatus || initialOrderState.orderRequestStatus;
