import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SelectedOfferHeader.scss';
import SideBarLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-layout-context';
import Money from 'common/components/Money/Money';
import { CurrencyEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    amount: number;
    currency: CurrencyEnum;
    onClickAmount: () => void;
};

const SelectedOfferHeader: React.FC<PropsT> = React.memo((props) => {
    const { onClickAmount, amount, currency } = props;
    const context = React.useContext(SideBarLayoutContext);

    const scrollTopValue = context?.scrollTop?.value || 0;
    if (scrollTopValue < 500) {
        return null;
    }

    return (
        <div className={cx('content')}>
            <span className={cx('total')} onClick={onClickAmount}>
                <Money amount={amount} currency={currency} />
            </span>
        </div>
    );
});

export default SelectedOfferHeader;
