import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { TrailerStatusEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    dictTrailerTypeId = 'dict-trailer-type-id',
    plateNumber = 'plate-number',
    status = 'status',
    link = 'link',
    GPSTracker = 'GPS-tracker',
    dedication = 'dedication',
    createDates = 'create-dates',
    createdByUserId = 'create-dated-by-user-id',
    createdByUserName = 'create-dated-by-user-name',
    lastModifyDates = 'last-modify-dates',
    lastModifyByUserId = 'last-modify-by-user-id',
    lastModifyByUserName = 'last-modify-by-user-name',
}

export type FormValuesT = {
    [FieldsEnum.dictTrailerTypeId]: TrailerDictIdT | null;
    [FieldsEnum.plateNumber]: string;
    [FieldsEnum.status]: TrailerStatusEnum | null;
    [FieldsEnum.link]: LinkValuesEnum | null;
    [FieldsEnum.GPSTracker]: GPSTrackedValuesEnum | null;
    [FieldsEnum.dedication]: DedicationValuesEnum | null;
    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.createdByUserId]: UserIdT | null;
    [FieldsEnum.createdByUserName]: string | null;
    [FieldsEnum.lastModifyDates]: DateRangeT | null;
    [FieldsEnum.lastModifyByUserId]: UserIdT | null;
    [FieldsEnum.lastModifyByUserName]: string | null;
};
