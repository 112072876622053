import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
};

const FeeIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1.97h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M6.85 5.57a2.25 2.25 0 10.002 4.503A2.25 2.25 0 006.85 5.57zm0 3.15a.903.903 0 01-.9-.9c0-.494.405-.9.9-.9s.9.406.9.9c0 .496-.405.9-.9.9zm6.3 3.15a2.25 2.25 0 10.002 4.503 2.25 2.25 0 00-.002-4.502zm0 3.15a.903.903 0 01-.9-.9c0-.494.405-.9.9-.9s.9.406.9.9c0 .496-.405.9-.9.9zm-7.596 1.35l-.954-.953 9.846-9.846.954.954-9.846 9.846z"
                />
            </g>
        </svg>
    );
};

FeeIcon.displayName = 'FeeIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.slate,
};

export { storyProps };
export default FeeIcon;
