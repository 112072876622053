import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CommonDocumentParamsDetailsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import values from 'lodash/values';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import moment from 'moment';
import prepareApproveData from './prepare-approve-data';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpdateCompanyDocumentsRequest } from 'common/store/documents/selectors';
import { approveDocument, updateDocument } from 'common/store/documents/actions';
import { logWarning } from 'common/utils/logger';
import { DocumentDetailsChangesT, DocumentVersionT } from 'common/store/documents/models';
import { DictDocumentT } from 'common/store/documents-dict/models';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ApproveDocumentsConfirmation, {
    BaseConfirmApproveDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/ApproveDocumentsConfirmation/ApproveDocumentsConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { documentsWithoutExpirySet } from 'common/constants';
import { PartnerTypeEnum } from 'common/utils/api/models';

const TOMORROW_DATE = moment().add(1, 'days').format('YYYY-MM-DD');

const cx = classNames.bind(styles);

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type PropsT = {
    isReview: boolean;
    dictDocument: DictDocumentT | null;
    actualVersion: DocumentVersionT | null;
    setNeedCloseConfirmation: (needCloseConfirmation: boolean) => void;
};

type ConfirmApproveDocumentModalDataT = BaseConfirmApproveDocumentModalDataT<{
    data: DocumentDetailsChangesT;
    partnerType: PartnerTypeEnum;
    dictDocumentId: DictDocumentIdT;
}>;

const CommonDocumentParamsDetailsForm: React.FC<PropsT> = React.memo((props) => {
    const { isReview, actualVersion, dictDocument, setNeedCloseConfirmation } = props;

    const isShowExpirationDate =
        !!actualVersion?.documentType && !documentsWithoutExpirySet.has(actualVersion?.documentType);

    const { partnerId, partnerType } = usePartnerContext();

    const dispatch = useDispatch();

    const confirmApproveDocumentModalDialog = useModalDialog<ConfirmApproveDocumentModalDataT>();

    const updateRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));

    const isLoading = updateRequest.loading;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { isShowExpirationDate });
    }, [t, isShowExpirationDate]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(actualVersion, { isShowExpirationDate });
        const errors = validateForm(t, values, { isShowExpirationDate });

        return [values, errors];
    }, [actualVersion, isShowExpirationDate]);

    const handleConfirmApproveDocument = (data: ConfirmApproveDocumentModalDataT) => {
        if (!actualVersion?.id) {
            logWarning('failed handleConfirmApproveDocument, empty actualVersion?.id');
            return;
        }

        dispatch(approveDocument(actualVersion.id, data.data.validTill, partnerId, partnerType));
    };

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!actualVersion?.id) {
                logWarning('failed to save document details, empty documentId');
                return;
            }

            const approveData = prepareApproveData(values, { isShowExpirationDate });
            if (!approveData) {
                logWarning('failed to save document details, empty approveData');
                return;
            }

            if (!dictDocument) {
                logWarning('failed to save document details, empty dictDocument');
                return;
            }

            if (isReview) {
                confirmApproveDocumentModalDialog.setData({
                    partnerType,
                    dictDocumentId: dictDocument.id,
                    data: approveData,
                });
            } else {
                if (!approveData.validTill) {
                    logWarning('failed to save details, empty approveData.validTill');
                    return;
                }

                dispatch(updateDocument(actualVersion.id, partnerId, approveData));
            }

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        return () => {
            setNeedCloseConfirmation(false);
        };
    }, []);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {isShowExpirationDate && (
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.expiration}
                        error={formik.errors[FieldsEnum.expiration]}
                        meta={formik.getFieldMeta(FieldsEnum.expiration)}
                        label={t('common:document.details-form.expiration.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                {...datePicketRangePresets.documentValidTill}
                                hasYearMonthForm
                                value={formik.values[FieldsEnum.expiration]}
                                placeholder={t('common:document.details-form.expiration.placeholder')}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                )}
                <HiddenSubmitButtonForKeyboardEnter />
            </form>
            <ApproveDocumentsConfirmation
                name={dictDocument?.name}
                data={confirmApproveDocumentModalDialog.data}
                onCancel={confirmApproveDocumentModalDialog.onCancel}
                onClose={confirmApproveDocumentModalDialog.onClose}
                onConfirm={handleConfirmApproveDocument}
                isLoading={isLoading}
            />
        </>
    );
});

export default CommonDocumentParamsDetailsForm;
