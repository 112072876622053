import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './DocumentMessage.scss';

type PropsT = {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
};

const cx = classNames.bind(styles);

const DocumentMessage: React.FC<PropsT> = React.memo((props) => {
    const { icon, title, description } = props;

    return (
        <div className={cx('inner')}>
            <div className={cx('icon')}>{icon}</div>
            <div className={cx('title')}>{title}</div>
            <div className={cx('description')}>{description}</div>
        </div>
    );
});

export default DocumentMessage;
