import { AsYouType, isPossiblePhoneNumber, CountryCode, getPhoneCode } from 'libphonenumber-js';
import { PHONE_NUMBER_PREFIX } from 'common/constants';

const NOT_NUMBER_REG_EXP = /\D/g;

export const clearPhoneNumber = (rawPhoneNumber: string): string => {
    const asYouType = new AsYouType();

    if (!rawPhoneNumber) {
        return '';
    }

    const formattedPhoneNumber = asYouType.input(rawPhoneNumber);

    const clearedPhoneNumber = formattedPhoneNumber.startsWith(PHONE_NUMBER_PREFIX)
        ? formattedPhoneNumber.replace(PHONE_NUMBER_PREFIX, '')
        : formattedPhoneNumber;

    return clearedPhoneNumber;
};

export const parsePhoneNumber = (rawPhoneNumber: string): string => {
    const phoneNumber = rawPhoneNumber.replace(NOT_NUMBER_REG_EXP, '');
    return `${PHONE_NUMBER_PREFIX}${phoneNumber}`;
};

export const getCountryCode = (phoneNumber: string): CountryCode | undefined => {
    const asYouType = new AsYouType();
    asYouType.input(parsePhoneNumber(phoneNumber));
    return asYouType.country;
};

export const getCountryPhoneNumberStart = (selectedCountryCode: string): string => {
    const phoneCode = getPhoneCode(selectedCountryCode as CountryCode);

    return `${PHONE_NUMBER_PREFIX}${phoneCode}`;
};

export const checkIsPossiblePhoneNumber = (phoneNumber: string): boolean => {
    return isPossiblePhoneNumber(phoneNumber);
};

export const checkIsEmptyPhoneNumber = (phoneNumber: string): boolean => {
    const parsedPhoneNumber = phoneNumber.trim();
    return !parsedPhoneNumber || parsedPhoneNumber === PHONE_NUMBER_PREFIX;
};
