import React from 'react';
import classNames from 'classnames/bind';

import styles from './ChangeRoleUserForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { RolesEnum } from 'common/utils/api/models';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { logWarning } from 'common/utils/logger';
import UserRoleDropdown, {
    getAvailableRolesByPartnerType,
} from 'common/components/dropdowns/UserRoleDropdown/UserRoleDropdown';
import { CommonChangeUserRoleFormPropsT } from 'common/layouts/Members/dialogs/ChangeUserRoleModal/models';
import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = CommonChangeUserRoleFormPropsT;

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.role]: RolesEnum.driver,
};

const TEST_SELECTOR = 'change-role-user-form';

const ChangeRoleUserForm: React.FC<PropsT> = (props) => {
    const { contact, onChangeUserRole, onCancel, updateMemberRequest } = props;

    const { partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const availableRoles = React.useMemo((): Array<RolesEnum> => {
        return getAvailableRolesByPartnerType(partnerType);
    }, [partnerType]);

    const firstRole = contact.roles[0]?.role as RolesEnum;

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        if (!firstRole) {
            logWarning('empty contact.roles!');
        }

        values[FieldsEnum.role] = firstRole;

        const errors = validateForm(t, values);

        return [values, errors];
    }, [firstRole]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const role = values[FieldsEnum.role];
            if (!role) {
                logWarning('empty role');
                return;
            }

            if (!contact?.userId) {
                logWarning('empty contact?.userId');
                return;
            }

            onChangeUserRole({ userId: contact.userId, role });

            formikHelpers.setTouched({});
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    name={FieldsEnum.role}
                    error={formik.errors[FieldsEnum.role]}
                    meta={formik.getFieldMeta(FieldsEnum.role)}
                    label={t('common:team-members.dialogs.change-user-role.fields.role.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <UserRoleDropdown
                            value={formik.values[FieldsEnum.role]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            availableRoles={availableRoles}
                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                        />
                    )}
                </FormikField>
            </div>
            <ModalDialogActions
                actions={[
                    {
                        children: t('common:error-modal.actions.cancel'),
                        theme: ButtonThemeEnum.secondary,
                        testSelector: `${TEST_SELECTOR}_cancel`,
                        onClick: onCancel,
                        isDisabled: updateMemberRequest.loading,
                    },
                    {
                        children: t('common:error-modal.actions.change'),
                        theme: ButtonThemeEnum.danger,
                        testSelector: `${TEST_SELECTOR}_change`,
                        type: 'submit',
                        isDisabled: firstRole === formik.values[FieldsEnum.role],
                        isLoading: updateMemberRequest.loading,
                    },
                ]}
            />
        </form>
    );
};

export default ChangeRoleUserForm;
