import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const AccountIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    d="M10 8.575a1.575 1.575 0 1 0 0-3.15 1.575 1.575 0 0 0 0 3.15zm0 3.6c-2.228 0-4.575 1.095-4.575 1.575v.825h9.15v-.825c0-.48-2.348-1.575-4.575-1.575z"
                    fillOpacity=".3"
                    fill={fillColor}
                />
                <path
                    d="M10 10.75c-2.003 0-6 1.005-6 3V16h12v-2.25c0-1.995-3.998-3-6-3zm4.575 3.825h-9.15v-.825c0-.48 2.348-1.575 4.575-1.575 2.227 0 4.575 1.095 4.575 1.575v.825zM10 10a3 3 0 1 0 0-6 3 3 0 1 0 0 6zm0-4.575a1.575 1.575 0 1 1 0 3.15 1.575 1.575 0 0 1 0-3.15z"
                    fill={strokeColor}
                />
            </g>
        </svg>
    );
};

AccountIcon.displayName = 'AccountIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default AccountIcon;
