import * as React from 'react';

import styles from './ButtonTimer.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { formatTimer, MS_IN_SEC } from 'common/utils/time';
import isNumber from 'lodash/isNumber';

type PropsT = {
    showTime: number;
    expireTime: number | null;
    tooltipMessage: React.ReactNode;
    onExpire: () => void;
};

const cx = classNames.bind(styles);

const getLimitedLost = (expireTime: number) => {
    const newLost = expireTime - Date.now();
    return Math.max(newLost, 0);
};

const DELAY_MS = MS_IN_SEC;

const ButtonTimer: React.FC<PropsT> = React.memo((props) => {
    const { expireTime, showTime, tooltipMessage, onExpire } = props;

    const [lost, setLost] = React.useState<number | null>(null);
    React.useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        let timeoutId: NodeJS.Timeout | null = null;

        if (expireTime) {
            intervalId = setInterval(() => {
                if (!expireTime) {
                    setLost(null);
                    return;
                }

                const limitedLost = getLimitedLost(expireTime);
                setLost(limitedLost);
            }, MS_IN_SEC);

            const limitedLost = getLimitedLost(expireTime);
            timeoutId = setTimeout(() => {
                onExpire();
            }, limitedLost + DELAY_MS);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [expireTime]);

    const isShow = isNumber(lost) ? lost < showTime : false;
    if (!isShow) {
        return null;
    }

    if (!lost) {
        return null;
    }

    let now = isNumber(lost) ? formatTimer(lost) : null;
    if (now === '00:00') {
        now = '00:01';
    }

    return (
        <Tooltip
            position={TooltipPositionEnum.centerLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={<TooltipContent theme={TooltipContentThemeEnum.black}>{tooltipMessage}</TooltipContent>}
        >
            {(isShow, childrenClassName) => (
                <div className={cs(cx('timer'), childrenClassName)}>
                    <div className={cx('text')}>{now}</div>
                </div>
            )}
        </Tooltip>
    );
});

export default ButtonTimer;
