import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
    className?: string;
};

const PlusIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, size, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M10.75 6.25h-1.5v3h-3v1.5h3v3h1.5v-3h3v-1.5h-3v-3zM10 2.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM10 16c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6z"
                />
            </g>
        </svg>
    );
};

PlusIcon.displayName = 'PlusIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PlusIcon;
