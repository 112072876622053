import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import {
    checkBirthday,
    checkNotEmpty,
    checkDate,
    checkPhoneNumber,
    checkNotEmptyString,
    checkIsPositiveNumberValue,
} from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.firstName, FieldsEnum.lastName, FieldsEnum.ratePerKm, FieldsEnum.language] as const;
const BIRTHDAY_FIELDS = [FieldsEnum.birthday] as const;
const DATE_FIELDS = [FieldsEnum.birthday] as const;
const PHONE_FIELDS = [FieldsEnum.desktopPhone] as const;
const MEDIA_FIELDS = [
    FieldsEnum.skype,
    FieldsEnum.whatsapp,
    FieldsEnum.viber,
    FieldsEnum.telegram,
    FieldsEnum.zoom,
    FieldsEnum.msTeams,
    FieldsEnum.webEx,
] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkBirthday(t, BIRTHDAY_FIELDS, values),
        ...checkDate(t, DATE_FIELDS, values),
        ...checkPhoneNumber(t, PHONE_FIELDS, values),
        ...checkNotEmptyString(t, MEDIA_FIELDS, values),
        ...checkIsPositiveNumberValue(t, [FieldsEnum.ratePerKm], values),
    };

    return errors;
};

export default validate;
