import { AssetTypeEnum } from 'common/constants';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import React from 'react';
import useDebouncedEffect from 'common/utils/hooks/useDebouncedEffect';
import { checkIsDefaultCompanyId } from 'common/utils';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

type PlateNumberUniqCheckRequestT = {
    plateNumber: string | null;
    companyId: CompanyIdT;
    assetType: AssetTypeEnum;
};

type PlateNumberUniqCheckT = {
    requestStatus: RequestStatusT;
    duplicateId: TrailerIdT | null;
};

const usePlateNumberUniqCheck = (request: PlateNumberUniqCheckRequestT): PlateNumberUniqCheckT => {
    const [duplicateId, setDuplicateId] = React.useState<TrailerIdT | null>(null);
    const [status, setStatus] = React.useState<RequestStatusT>(requestStatus.initial);
    const queryRef = React.useRef<PlateNumberUniqCheckRequestT | null>(null);

    useDebouncedEffect(
        () => {
            const { plateNumber, companyId, assetType } = request;

            if (!plateNumber) {
                setDuplicateId(null);
                setStatus(requestStatus.ok);
                return;
            }

            queryRef.current = request;

            setStatus(requestStatus.loading);

            let promise: Promise<PlateNumberUniqCheckT['duplicateId']> | null = null;

            if (assetType === AssetTypeEnum.trailer) {
                if (checkIsDefaultCompanyId(companyId)) {
                    promise = carrierTranziitApi
                        .fetchTrailersPage({
                            plateNumber,
                            page: 0,
                        })
                        .then(([error, data]): PlateNumberUniqCheckT['duplicateId'] => {
                            if (error) {
                                throw error;
                            }

                            const duplcate = (data?.content || []).find(
                                (trailer) => trailer.plateNumber === plateNumber,
                            );
                            return duplcate?.id || null;
                        });
                } else {
                    promise = brokerTranziitApi
                        .fetchTrailersPage(
                            {
                                plateNumber,
                                page: 0,
                            },
                            companyId,
                        )
                        .then(([error, data]): PlateNumberUniqCheckT['duplicateId'] => {
                            if (error) {
                                throw error;
                            }

                            const duplcate = (data?.content || []).find(
                                (trailer) => trailer.plateNumber === plateNumber,
                            );
                            return duplcate?.id || null;
                        });
                }
            }
            if (assetType === AssetTypeEnum.truck) {
                if (checkIsDefaultCompanyId(companyId)) {
                    promise = carrierTranziitApi
                        .fetchTrucksPage({
                            plateNumber,
                            page: 0,
                        })
                        .then(([error, data]): PlateNumberUniqCheckT['duplicateId'] => {
                            if (error) {
                                throw error;
                            }

                            const duplcate = (data?.content || []).find((truck) => truck.plateNumber === plateNumber);
                            return duplcate?.id || null;
                        });
                } else {
                    promise = brokerTranziitApi
                        .fetchTrucksPage(
                            {
                                plateNumber,
                                page: 0,
                            },
                            companyId,
                        )
                        .then(([error, data]): PlateNumberUniqCheckT['duplicateId'] => {
                            if (error) {
                                throw error;
                            }

                            const duplcate = (data?.content || []).find((truck) => truck.plateNumber === plateNumber);
                            return duplcate?.id || null;
                        });
                }
            }

            if (!promise) {
                throw new Error('empty promise!');
            }

            promise
                .then((duplicateId) => {
                    const isSameQuery = checkIsSameQuery(queryRef.current, request);
                    if (!isSameQuery) {
                        return;
                    }

                    setDuplicateId(duplicateId);
                    setStatus(requestStatus.ok);
                })
                .catch((error) => {
                    const isSameQuery = checkIsSameQuery(queryRef.current, request);
                    if (!isSameQuery) {
                        return;
                    }

                    setDuplicateId(null);
                    setStatus(requestStatus.setError(error));
                });
        },
        300,
        [request.companyId, request.plateNumber],
    );

    return {
        requestStatus: status,
        duplicateId,
    };
};

export { usePlateNumberUniqCheck };
