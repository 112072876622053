import * as React from 'react';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';

type PropsT = {
    className?: string;
    actionText?: string;
    message: string;
    onClickAction?: () => void;
};

const VerificationAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, actionText, message, onClickAction } = props;

    const [isClosed, setIsClosed] = React.useState<boolean>(false);
    if (isClosed) {
        return null;
    }

    return (
        <Alert
            className={className}
            icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
            onClose={() => {
                setIsClosed(true);
            }}
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.tomatoRed}
            actionText={actionText}
            onClickAction={onClickAction}
            isAlignItemsCenter
        >
            {message}
        </Alert>
    );
});

export default VerificationAlert;
