import * as React from 'react';
import { ApiTruckStatusT, TruckStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import TruckStatus from 'common/components/status/TruckStatus/TruckStatus';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiTruckStatusT | null | undefined;
    isSymmetrical?: boolean;
};

export const PILL_THEME_BY_TRUCK_STATUS: Record<ApiTruckStatusT, PillLabelThemeEnum> = {
    [TruckStatusEnum.free]: PillLabelThemeEnum.brandDark,
    [TruckStatusEnum.inTransit]: PillLabelThemeEnum.light,
    [TruckStatusEnum.unavailable]: PillLabelThemeEnum.tomatoRed,
    [TruckStatusEnum.archived]: PillLabelThemeEnum.tomatoRed,
};

const TruckStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap {...restProps} theme={PILL_THEME_BY_TRUCK_STATUS[status]}>
            <TruckStatus status={status} />
        </PillLabel>
    );
});

export default TruckStatusPill;
