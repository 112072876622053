import * as React from 'react';
import { OrderT } from 'common/store/orders/models';
import { formatTimeInterval } from 'common/utils/time';
import LocationCellLayout from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import { DirectionEnum } from 'common/constants';

type PropsT = {
    order: OrderT;
    type: DirectionEnum;
};

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { order, type } = props;

    let time = null;
    let address = null;

    if (type === DirectionEnum.destination) {
        address = order.destination;
        time = formatTimeInterval(order.dropOffDateFrom, order.dropOffDateTo);
    }
    if (type === DirectionEnum.origin) {
        address = order.origin;
        time = formatTimeInterval(order.pickupDateFrom, order.pickupDateTo);
    }

    return <LocationCellLayout address={address} time={time} />;
});

export default LocationCell;
