import * as React from 'react';
import { ApiShipperContractLaneStatusT, ShipperContractLaneStatusEnum } from 'common/utils/api/models';
import ColoredStatusLabel, {
    colorCodingColoredStatusTheme,
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import { StyleGuideColorsEnum } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectProfileType } from 'common/store/auth/selectors';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import ShipperContractLaneStatus from 'common/components/status/ShipperContractLaneStatus/ShipperContractLaneStatus';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiShipperContractLaneStatusT | null | undefined;
};

type ContractLaneStatusColorMapT = Record<ApiShipperContractLaneStatusT, StyleGuideColorsEnum>;

export const statusColorMap: Record<AuthProfileTypeEnum, ContractLaneStatusColorMapT> = {
    [AuthProfileTypeEnum.shipper]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingColoredStatusTheme.success,
    },
    [AuthProfileTypeEnum.carrier]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingColoredStatusTheme.success,
    },
    [AuthProfileTypeEnum.broker]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingColoredStatusTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingColoredStatusTheme.success,
    },
};

const ShipperContractLaneColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    const profileTypeEnum = useSelector(selectProfileType);

    let color;

    // not upload
    if (!status) {
        color = colorCodingColoredStatusTheme.normal;
    } else {
        color = statusColorMap[profileTypeEnum]?.[status];
    }

    return <ColoredStatusLabel {...restProps} label={<ShipperContractLaneStatus status={status} />} color={color} />;
});

export default ShipperContractLaneColoredStatus;
