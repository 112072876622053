import { FieldsEnum, FormValuesT } from './TrailerDetailsForm/constants';
import { ApiTrailerAddRequestT, ApiTrailerUpdateRequestT, CountryCodeUnionT } from 'common/utils/api/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { FieldsEnum as DetailsFieldsEnum } from 'common/layouts/CommonEditableTruckDetailsLayout/EditTruckSidebarContent/TruckDetailsForm/constants';

const prepareApiCreateTrailer = (detailsFormValues: FormValuesT): ApiTrailerAddRequestT => {
    const dictTrailerId = detailsFormValues[FieldsEnum.trailerDictId];

    let pricing: Partial<ApiTrailerAddRequestT> = {};
    if (detailsFormValues[DetailsFieldsEnum.contract] === null) {
        pricing = {
            ratePerKm: parseSimpleNumber(detailsFormValues[FieldsEnum.ratePerKm]),
        };
    } else {
        pricing = {
            contractId: detailsFormValues[DetailsFieldsEnum.contract] || undefined,
        };
    }

    return {
        dictTrailerId: dictTrailerId ? parseInt(dictTrailerId, 10) : undefined,
        plateNumber: detailsFormValues[FieldsEnum.plateNumber] || undefined,
        countryOfRegistration: (detailsFormValues[FieldsEnum.countryCode] as CountryCodeUnionT) || undefined,
        ...pricing,
    };
};

const prepareApiModifyTrailer = (trailerId: TrailerIdT, detailsFormValues: FormValuesT): ApiTrailerUpdateRequestT => {
    let pricing: Partial<ApiTrailerUpdateRequestT> = {};
    if (detailsFormValues[DetailsFieldsEnum.contract] === null) {
        pricing = {
            ratePerKm: parseSimpleNumber(detailsFormValues[FieldsEnum.ratePerKm]),
        };
    } else {
        pricing = {
            contractId: detailsFormValues[DetailsFieldsEnum.contract] || undefined,
        };
    }

    return {
        trailerId,
        countryOfRegistration: (detailsFormValues[FieldsEnum.countryCode] as CountryCodeUnionT) || undefined,
        ...pricing,
    };
};

export { prepareApiCreateTrailer, prepareApiModifyTrailer };
