import React from 'react';

export type ScrollTopContextT = {
    value: number;
    setValue: (value: number) => void;
};

const useScrollTop = (): ScrollTopContextT => {
    const [value, setValue] = React.useState<number>(0);

    return {
        value,
        setValue,
    };
};

export default useScrollTop;
