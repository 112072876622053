import {
    FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN,
    FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR,
    FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS,
    SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN,
    SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR,
    SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS,
    VehicleSchedulesActionT,
    VehicleSchedulesStateT,
    VehicleScheduleStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: VehicleSchedulesStateT = {
    byId: {},
    setUnavailableRequest: requestStatus.initial,
};

export const initialVehicleState: VehicleScheduleStateT = {
    schedule: [],
    fetchRequestStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: VehicleSchedulesActionT | DestroySessionActionT,
): VehicleSchedulesStateT => {
    switch (action.type) {
        case FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN: {
            const { vehicleId } = action;

            const vehicleState = state.byId[vehicleId] || initialVehicleState;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [vehicleId]: {
                        ...vehicleState,
                        fetchRequestStatus: requestStatus.loading,
                    },
                },
            };
        }

        case FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS: {
            const { schedule, vehicleId } = action;

            const vehicleState = state.byId[vehicleId] || initialVehicleState;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [vehicleId]: {
                        ...vehicleState,
                        schedule,
                        fetchRequestStatus: requestStatus.ok,
                    },
                },
            };
        }

        case FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR: {
            const { vehicleId, error } = action;

            const vehicleState = state.byId[vehicleId] || initialVehicleState;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [vehicleId]: {
                        ...vehicleState,
                        fetchRequestStatus: requestStatus.setError(error),
                    },
                },
            };
        }

        case SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN: {
            return {
                ...state,
                setUnavailableRequest: requestStatus.loading,
            };
        }

        case SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                setUnavailableRequest: requestStatus.setError(error),
            };
        }

        case SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS: {
            return {
                ...state,
                setUnavailableRequest: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
