import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PositionsCell.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { CompanyContactT } from 'common/store/members/models';
import { ContactPositionsEnum, RolesEnum } from 'common/utils/api/models';
import { USER_POSITIONS_T_MAP, USER_ROLE_T_MAP } from 'common/utils/t';

type PropsT = {
    contact: CompanyContactT;
};

const cx = classNames.bind(styles);

const UNKNOWN_LABEL_COLOR = PillLabelThemeEnum.light;

const getPillLabelByRole = (role: RolesEnum): PillLabelThemeEnum => {
    if (role === RolesEnum.companyAdmin) {
        return PillLabelThemeEnum.brandDark;
    }

    if (USER_ROLE_T_MAP[role]) {
        return PillLabelThemeEnum.charcoal;
    }

    return UNKNOWN_LABEL_COLOR;
};

const PositionsCell: React.FC<PropsT> = React.memo((props) => {
    const { contact } = props;
    const { userId, main: isMain, position, roles } = contact;

    const { t } = useTranslation();

    const hasUser = !!userId;

    const positionT = USER_POSITIONS_T_MAP[position as ContactPositionsEnum];

    return (
        <div className={cx('positions')}>
            {!hasUser && (
                <PillLabel className={cx('pill')} theme={PillLabelThemeEnum.grey}>
                    {t('common:team-members.contact')}
                </PillLabel>
            )}
            {isMain && (
                <PillLabel className={cx('pill')} theme={PillLabelThemeEnum.orange}>
                    {t('common:team-members.main')}
                </PillLabel>
            )}
            {position && (
                <PillLabel className={cx('pill')} theme={positionT ? PillLabelThemeEnum.slate : UNKNOWN_LABEL_COLOR}>
                    {positionT ? t(positionT) : position}
                </PillLabel>
            )}
            {(roles || []).map((role) => {
                const roleT = USER_ROLE_T_MAP[role.role as RolesEnum];

                return (
                    <PillLabel key={role.id} className={cx('pill')} theme={getPillLabelByRole(role.role as RolesEnum)}>
                        {roleT ? t(roleT) : role.role}
                    </PillLabel>
                );
            })}
        </div>
    );
});

export default PositionsCell;
