import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import moment, { Moment } from 'moment';
import isNumber from 'lodash/isNumber';

import { selectCurrentUser } from 'common/store/user/selectors';
import { signOut } from 'common/store/auth/actions';
import { selectSignOutRequestStatus } from 'common/store/auth/selectors';

import classNames from 'classnames/bind';
import styles from './AccountPreview.scss';
import { useTranslation } from 'react-i18next';
import SignOutIcon from './icons/SignOutIcon';
import { MS_IN_MIN } from 'common/utils/time';
import Avatar, { AvatarSizeEnum } from 'common/components/Avatar/Avatar';
import { getTimeZoneLabel } from 'common/utils/timezones';

const cx = classNames.bind(styles);

type PropsT = {};

const AccountPreview: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const signOutRequest = useSelector(selectSignOutRequestStatus);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    const [date, setDate] = React.useState<Moment>(moment());
    setInterval(() => {
        setDate(moment());
    }, MS_IN_MIN);

    if (isNumber(user?.timezone)) {
        date.utcOffset((user?.timezone || 0) / 60);
    }

    const timezoneLabel = getTimeZoneLabel(t, user?.timezone);

    return (
        <div className={cx('inner')}>
            <Avatar className={cx('avatar')} hash={user?.email || ''} size={AvatarSizeEnum.extraLarge} />
            <div className={cx('full-name')} data-test-selector="user-full-name">
                {user?.fullName}
            </div>
            <div className={cx('email')} data-test-selector="user-email">
                {user?.email}
            </div>
            <div className={cx('time')} data-test-selector="user-local-time">
                {t('common:account-page.time')}: {date.format('hh:mm A')} {timezoneLabel ? `(${timezoneLabel})` : ''}
            </div>
            <Button
                className={cx('sign-out')}
                theme={ButtonThemeEnum.secondary}
                isLoading={signOutRequest.loading}
                onClick={handleSignOut}
                testSelector="sign-out"
                leftIcon={<SignOutIcon />}
            >
                {t('common:account-page.sign-out-button')}
            </Button>
        </div>
    );
};

export default AccountPreview;
