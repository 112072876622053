import { useEffect, useRef } from 'react';

function usePrevious<ValueT>(value: ValueT) {
    const ref = useRef<ValueT | undefined | null>(value);

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default usePrevious;
