import { ViewEventChannel } from 'common/utils/view-event-channel';

export const driversRefreshChannel = new ViewEventChannel<{}>();

export const driversPaginationChannel = new ViewEventChannel<{
    pageNumber: number;
}>();

export const driverDetailsRefreshChannel = new ViewEventChannel<{
    driverId: DriverIdT;
    partnerId: PartnerIdT;
}>();
