import * as React from 'react';
import { ApiRFQStatusT, RFQStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiRFQStatusT | null | undefined;
};

export const RFQ_STATUS_T_MAP: Record<ApiRFQStatusT, string> = {
    [RFQStatusEnum.open]: 'common:rfq-status.OPEN',
    [RFQStatusEnum.expireSoon]: 'common:rfq-status.EXPIRE_SOON',
    [RFQStatusEnum.expired]: 'common:rfq-status.EXPIRED',
    [RFQStatusEnum.completed]: 'common:rfq-status.COMPLETED',
    [RFQStatusEnum.closed]: 'common:rfq-status.CLOSED',
};

const RFQStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(RFQ_STATUS_T_MAP[status]);
});

export default RFQStatus;
