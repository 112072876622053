import { FieldsEnum, FormValuesT } from './constants';
import { getDateFromDate } from 'common/utils/time';
import { parseSimpleNumber, parseStringFormatter } from 'common/utils/input-parsers';
import { UpdateShipperContractLaneChangesT } from 'common/store/shipper-contract-lane-details/models';

const prepareApRequest = (values: FormValuesT): UpdateShipperContractLaneChangesT | null => {
    const validFrom = values[FieldsEnum.validFrom];
    const validTill = values[FieldsEnum.validTill];
    const originRadius = parseSimpleNumber(values[FieldsEnum.pickUpLocationRadius]);
    const destinationRadius = parseSimpleNumber(values[FieldsEnum.dropOffLocationRadius]);
    const maximumLayover = parseSimpleNumber(values[FieldsEnum.layoverLimit]);

    if (!validFrom || !validTill) {
        return null;
    }

    return {
        externalLaneId: parseStringFormatter(values[FieldsEnum.externalLaneId]),
        originRadius: originRadius || 0,
        destinationRadius: destinationRadius || 0,
        validFrom: getDateFromDate(validFrom),
        validTill: getDateFromDate(validTill),
        maximumLayover: maximumLayover || 0,
    };
};

export { prepareApRequest };
