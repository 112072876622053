import React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipperContractDetails.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FilePickerInput from 'design-system/components/FilePickerInput/FilePickerInput';
import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';
import FieldValue, { EMPTY_VALUE_PLACEHOLDER } from 'common/components/FieldValue/FieldValue';
import { DownloadDataT } from '../EditShipperContractDetailsForm/models';
import { RemoteFile } from 'design-system/components/FilePickerInput/models';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import CalendarIcon from 'common/icons/CalendarIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import isNumber from 'lodash/isNumber';
import PdfFileIcon from 'common/icons/PdfFileIcon';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    shipperContractId: ShipperContractIdT;
    shipperContractDetails: ShipperContractDetailsT | null;
    onDownloadShipperContract: (data: DownloadDataT) => void;
};

const VALID_TILL_FORMAT = 'DD.MM.YYYY';

const ShipperContractDetails: React.FC<PropsT> = (props) => {
    const { partnerId, shipperContractId, shipperContractDetails, onDownloadShipperContract } = props;

    const { t } = useTranslation();

    return (
        <div>
            <FieldGroup>
                <FieldValue
                    className={cx('field', 'field--name')}
                    label={t('common:shipper-contract-details.editing-form.fields.name.label')}
                    value={shipperContractDetails?.name || ''}
                />
                <FieldValue
                    className={cx('field', 'field--number')}
                    label={t('common:shipper-contract-details.editing-form.fields.number.label')}
                    tooltipNode={
                        <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={150}>
                            {t('common:shipper-contract-details.editing-form.fields.number.tooltip')}
                        </TooltipContent>
                    }
                    tooltipPosition={TooltipPositionEnum.centerLeft}
                    value={shipperContractDetails?.number || ''}
                    placeholder={EMPTY_VALUE_PLACEHOLDER}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={
                        <CalendarIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    className={cx('field', 'field--activate-date')}
                    label={t('common:shipper-contract-details.editing-form.fields.activate-date.label')}
                    value={
                        shipperContractDetails?.validFrom
                            ? moment(shipperContractDetails.validFrom).format(VALID_TILL_FORMAT)
                            : null
                    }
                />
                <FieldValue
                    icon={
                        <CalendarIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    className={cx('field', 'field--valid-till')}
                    label={t('common:shipper-contract-details.editing-form.fields.valid-till-date.label')}
                    value={
                        shipperContractDetails?.validTill
                            ? moment(shipperContractDetails.validTill).format(VALID_TILL_FORMAT)
                            : null
                    }
                />
                <FieldValue
                    className={cx('field', 'field--orders-left')}
                    label={t('common:shipper-contract-details.editing-form.fields.orders-left.label')}
                    value={
                        isNumber(shipperContractDetails?.ordersLeft) &&
                        isNumber(shipperContractDetails?.maxNumberOfOrders)
                            ? `${shipperContractDetails?.ordersLeft}/${shipperContractDetails?.maxNumberOfOrders}`
                            : null
                    }
                    placeholder={EMPTY_VALUE_PLACEHOLDER}
                />
            </FieldGroup>
            <div className={cx('field', 'field--file')}>
                <FilePickerInput
                    accept="application/pdf"
                    name="name"
                    onChange={() => {
                        // nothing
                    }}
                    value={
                        new RemoteFile<DownloadDataT>({
                            name: shipperContractDetails?.fileName || '',
                            downloadData: {
                                partnerId,
                                shipperContractId,
                            },
                        })
                    }
                    onDownload={onDownloadShipperContract}
                    renderLeftIcon={({ isEmpty }) => (
                        <PdfFileIcon
                            fillColor={isEmpty ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.brandDark}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default ShipperContractDetails;
