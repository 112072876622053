import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TransparentButton.scss';
import cs from 'classnames';

import noop from 'lodash/noop';

const cx = classNames.bind(styles);

export type ButtonPropsT = {
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
    testSelector?: string;
};

const TransparentButton: React.FC<ButtonPropsT> = (props) => {
    const { className, type, onClick, isDisabled, children, testSelector } = props;

    const isClickable = !!onClick && !isDisabled;

    return (
        <button
            className={cs(
                cx('button', {
                    'button--isClickable': isClickable,
                }),
                className,
            )}
            type={type || 'button'}
            disabled={!isClickable}
            data-test-selector={`${testSelector}_button`}
            onClick={isClickable ? onClick : noop}
        >
            <span className={cx('inner')}>{children}</span>
        </button>
    );
};

export default TransparentButton;
