import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';

export enum FieldsEnum {
    firstName = 'firstName',
    lastName = 'lastName',
    phone = 'phone',
    password = 'password',
    acceptTerms = 'acceptTerms',
}

export type FormValuesT = {
    [FieldsEnum.firstName]: string;
    [FieldsEnum.lastName]: string;
    [FieldsEnum.phone]: string;
    [FieldsEnum.password]: string;
    [FieldsEnum.acceptTerms]: boolean;
};

export type FormErrorsT = Partial<Record<keyof FormValuesT, string>>;

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.password]: AuthErrorTypeEnum;
}>;
