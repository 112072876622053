import { RequestStatusT } from 'common/utils/request-status';
import { ContractDetailsChangesT, PartnerContractT } from './models';
import { ApiRevokePartnerContractInfoT } from 'common/utils/api/models';

export const UPLOAD_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/UPLOAD_CARRIER_CONTRACT_REQUEST';
export const UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN = 'common/carrier-contracts/UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN';
export const UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR = 'common/carrier-contracts/UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR';
export const UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS =
    'common/carrier-contracts/UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS';

export const DOWNLOAD_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/DOWNLOAD_CARRIER_CONTRACT_REQUEST';
export const DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN =
    'common/carrier-contracts/DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN';
export const DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR =
    'common/carrier-contracts/DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR';
export const DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS =
    'common/carrier-contracts/DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS';

export const FETCH_CARRIER_CONTRACTS_REQUEST = 'common/carrier-contracts/FETCH_CARRIER_CONTRACTS_REQUEST';
export const FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN = 'common/carrier-contracts/FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN';
export const FETCH_CARRIER_CONTRACTS_REQUEST_ERROR = 'common/carrier-contracts/FETCH_CARRIER_CONTRACTS_REQUEST_ERROR';
export const FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS =
    'common/carrier-contracts/FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS';

export const APPROVE_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/APPROVE_CARRIER_CONTRACT_REQUEST';
export const REJECT_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/REJECT_CARRIER_CONTRACT_REQUEST';
export const REVOKE_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/REVOKE_CARRIER_CONTRACT_REQUEST';
export const UPDATE_CARRIER_CONTRACT_REQUEST = 'common/carrier-contracts/UPDATE_CARRIER_CONTRACT_REQUEST';
export const UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN = 'common/carrier-contracts/UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN';
export const UPDATE_CARRIER_CONTRACT_REQUEST_ERROR = 'common/carrier-contracts/UPDATE_CARRIER_CONTRACT_REQUEST_ERROR';
export const UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS =
    'common/carrier-contracts/UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS';

export type CarrierContractStateT = {
    contracts: Array<PartnerContractT>;
    fetchContractsRequest: RequestStatusT;
    updateContractRequest: RequestStatusT;
    uploadContractRequest: RequestStatusT;
    downloadContractRequest: RequestStatusT;
    updatingContractId: CarrierContractIdT | null;
};

export type CarrierContractsStateT = Record<PartnerIdT, CarrierContractStateT>;

export type FetchCarrierContractsActionT = {
    type: typeof FETCH_CARRIER_CONTRACTS_REQUEST;
    partnerId: PartnerIdT;
    options?: FetchOptionsT;
};

export type FetchCarrierContractsBeginActionT = {
    type: typeof FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN;
    partnerId: PartnerIdT;
};

export type FetchCarrierContractsSuccessActionT = {
    type: typeof FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS;
    partnerId: PartnerIdT;
    partnerContracts: Array<PartnerContractT>;
};

export type FetchCarrierContractsErrorActionT = {
    type: typeof FETCH_CARRIER_CONTRACTS_REQUEST_ERROR;
    error: Error;
    partnerId: PartnerIdT;
};

export type RevokeCarrierContractActionT = {
    type: typeof REVOKE_CARRIER_CONTRACT_REQUEST;
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    revokeInfo: ApiRevokePartnerContractInfoT;
};

export type ApproveCarrierContractActionT = {
    type: typeof APPROVE_CARRIER_CONTRACT_REQUEST;
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    contractChanges: ContractDetailsChangesT;
};

export type RejectCarrierContractActionT = {
    type: typeof REJECT_CARRIER_CONTRACT_REQUEST;
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    reason: string;
};

export type UpdateCarrierContractActionT = {
    type: typeof UPDATE_CARRIER_CONTRACT_REQUEST;
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    contractChanges: ContractDetailsChangesT;
};

export type UpdateCarrierContractBeginActionT = {
    type: typeof UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN;
    partnerId: PartnerIdT;
    updatingContractId: CarrierContractIdT | null;
};

export type UpdateCarrierContractSuccessActionT = {
    type: typeof UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS;
    partnerId: PartnerIdT;
};

export type UpdateCarrierContractErrorActionT = {
    type: typeof UPDATE_CARRIER_CONTRACT_REQUEST_ERROR;
    error: Error;
    partnerId: PartnerIdT;
};

export type UploadCarrierContractActionT = {
    type: typeof UPLOAD_CARRIER_CONTRACT_REQUEST;
    partnerId: PartnerIdT;
    file: File;
};

export type UploadCarrierContractBeginActionT = {
    type: typeof UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN;
    partnerId: PartnerIdT;
};

export type UploadCarrierContractSuccessActionT = {
    type: typeof UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS;
    partnerId: PartnerIdT;
};

export type UploadCarrierContractErrorActionT = {
    type: typeof UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR;
    error: Error;
    partnerId: PartnerIdT;
};

export type DownloadCarrierContractActionT = {
    type: typeof DOWNLOAD_CARRIER_CONTRACT_REQUEST;
    contractId: CarrierContractIdT;
    partnerId: PartnerIdT;
};

export type DownloadCarrierContractBeginActionT = {
    type: typeof DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN;
    partnerId: PartnerIdT;
};

export type DownloadCarrierContractSuccessActionT = {
    type: typeof DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS;
    partnerId: PartnerIdT;
};

export type DownloadCarrierContractErrorActionT = {
    type: typeof DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR;
    error: Error;
    partnerId: PartnerIdT;
};

export type CarrierContractsActionT =
    | FetchCarrierContractsActionT
    | FetchCarrierContractsBeginActionT
    | FetchCarrierContractsSuccessActionT
    | FetchCarrierContractsErrorActionT
    | RevokeCarrierContractActionT
    | ApproveCarrierContractActionT
    | RejectCarrierContractActionT
    | UploadCarrierContractActionT
    | UploadCarrierContractBeginActionT
    | UploadCarrierContractSuccessActionT
    | UploadCarrierContractErrorActionT
    | DownloadCarrierContractActionT
    | DownloadCarrierContractBeginActionT
    | DownloadCarrierContractSuccessActionT
    | DownloadCarrierContractErrorActionT
    | UpdateCarrierContractActionT
    | UpdateCarrierContractBeginActionT
    | UpdateCarrierContractSuccessActionT
    | UpdateCarrierContractErrorActionT;
