import { RequestStatusT } from 'common/utils/request-status';
import { initialCountryLegalFormsState } from 'common/store/legal-forms-dict/reducer';

import { SharableStateT } from '../models';
import { CountryLegalFormsDictStateT } from './types';
import { CountryCodeT } from 'common/store/countries-dict/models';

const selectCountryLegalFormState = (
    countryCode: CountryCodeT | null,
    state: SharableStateT,
): CountryLegalFormsDictStateT => {
    return state.legalFormsDict[countryCode as CountryCodeT] || initialCountryLegalFormsState;
};

export const selectLegalFormsDictById =
    (countryCode: CountryCodeT | null) =>
    (state: SharableStateT): CountryLegalFormsDictStateT['byId'] => {
        return selectCountryLegalFormState(countryCode, state).byId;
    };

export const selectLegalFormsDictAllIds =
    (countryCode: CountryCodeT | null) =>
    (state: SharableStateT): CountryLegalFormsDictStateT['allIds'] => {
        return selectCountryLegalFormState(countryCode, state).allIds;
    };

export const selectLegalFormsDictRequest =
    (countryCode: CountryCodeT | null) =>
    (state: SharableStateT): RequestStatusT => {
        return selectCountryLegalFormState(countryCode, state).request;
    };
