import { FieldsEnum, FormValuesT } from './constants';
import moment from 'moment';
import { EvidenceOfTaxDetailsChangesT } from 'common/store/documents/models';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { FAKE_NO_LIMIT_VALID_TILL_YEAR } from 'common/constants';

const VALID_TILL_FORMAT = 'YYYY-MM-DD';

const prepareApproveData = (values: FormValuesT): EvidenceOfTaxDetailsChangesT | null => {
    const approveData: EvidenceOfTaxDetailsChangesT = {
        taxId: simpleStringFormatter(values[FieldsEnum.taxId]),
        iban: simpleStringFormatter(values[FieldsEnum.iban]),
        bic: simpleStringFormatter(values[FieldsEnum.bic]),
        validTill: moment().add(FAKE_NO_LIMIT_VALID_TILL_YEAR, 'year').format(VALID_TILL_FORMAT),
    };

    if (!approveData.taxId || !approveData.iban || !approveData.bic) {
        return null;
    }

    const rawVatNumber = simpleStringFormatter(values[FieldsEnum.vatNumber]);
    if (rawVatNumber) {
        approveData.vat = rawVatNumber;
    }

    return approveData;
};

export default prepareApproveData;
