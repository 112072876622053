import * as React from 'react';

import { useDispatch } from 'react-redux';

import { fetchCompany } from 'common/store/company/actions';
import { useCallback } from 'react';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { companyRefreshChannel } from 'common/store/company/channels';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

type PropsT = {};

const Company: React.FC<PropsT> = React.memo((props) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCompany());
    }, []);

    const refreshHandler = useCallback(() => {
        dispatch(fetchCompany({ isForceUpdate: true }));
    }, []);
    useChannelSubscribe(companyRefreshChannel, refreshHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchCompany({ isForceUpdate: false }));
    }, []);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    return null;
});

export default Company;
