import * as React from 'react';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';

function useContainerScroll() {
    const [scrollTop, setScrollTop] = React.useState<number>(0);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const onContainerScroll = useMemo(() => {
        return debounce(
            () => {
                setScrollTop(containerRef?.current?.scrollTop || 0);
            },
            100,
            { maxWait: 100 },
        );
    }, [containerRef?.current, setScrollTop]);

    return {
        containerRef,
        onContainerScroll,
        scrollTop,
    };
}

export default useContainerScroll;
