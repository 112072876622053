import { v4 as uuidv4 } from 'uuid';
import { AnyNotificationT, NotificationActionEnum } from 'common/store/notifications/models';

type CallbackT = (notification: AnyNotificationT) => void;
type TopicT = Record<string, CallbackT | null>;
const topics: Record<string, TopicT> = {};

// for debug
window.topics = topics;

type UnsubscribeT = () => void;

export const subscribe = (actions: NotificationActionEnum[], callback: CallbackT): UnsubscribeT => {
    const unsubscribeCallbacks = actions.map((action) => {
        const id = uuidv4();

        if (!topics[action]) {
            topics[action] = {};
        }

        topics[action][id] = callback;

        return (): void => {
            topics[action][id] = null;
            delete topics[action][id];
        };
    });

    return () => {
        unsubscribeCallbacks.forEach((unsubscribe) => unsubscribe());
    };
};

export function publish(notifications: AnyNotificationT[]) {
    notifications.forEach((notification) => {
        const { action } = notification;

        if (!topics[action]) {
            return;
        }

        Object.values(topics[action]).forEach((callback) => {
            if (callback) {
                callback(notification);
            }
        });
    });
}
