import {
    AssetTrackActionT,
    AssetTrackItemStateT,
    AssetTrackStateT,
    FETCH_TRACK_REQUEST_BEGIN,
    FETCH_TRACK_REQUEST_ERROR,
    FETCH_TRACK_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialAssetTrackState: AssetTrackItemStateT = {
    points: [],
    requestStatus: requestStatus.initial,
};

const initialState: AssetTrackStateT = {};

export default (state = initialState, action: AssetTrackActionT | DestroySessionActionT): AssetTrackStateT => {
    switch (action.type) {
        case FETCH_TRACK_REQUEST_BEGIN: {
            const { hash } = action;

            return {
                ...state,
                [hash]: {
                    ...initialAssetTrackState,
                    ...state[hash],
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_TRACK_REQUEST_ERROR: {
            const { hash, error } = action;

            return {
                ...state,
                [hash]: {
                    ...initialAssetTrackState,
                    ...state[hash],
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_TRACK_REQUEST_SUCCESS: {
            const { hash, points } = action;

            return {
                ...state,
                [hash]: {
                    ...initialAssetTrackState,
                    ...state[hash],
                    requestStatus: requestStatus.ok,
                    points: points || [],
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
