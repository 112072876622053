import { ApiRFQT } from 'common/utils/api/models';
import { RFQT } from '../models';
import { getDateFromISO, MS_IN_SEC } from 'common/utils/time';
import isNumber from 'lodash/isNumber';

export default (apiRFQ: ApiRFQT): RFQT => {
    return {
        ...apiRFQ,
        expireTime: isNumber(apiRFQ.secondsToExpire) ? apiRFQ.secondsToExpire * MS_IN_SEC + Date.now() : null,
        points: apiRFQ.points.map((point) => {
            return {
                ...point,
                fromDate: getDateFromISO(point.from),
                toDate: getDateFromISO(point.to),
            };
        }),
    };
};
