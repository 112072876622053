export const isZeroNumber = (value: string | null | undefined): boolean => {
    const preparedValue = (value || '').trim();

    return preparedValue === '0' || preparedValue === '0.0' || preparedValue === '0,0';
};

export const isEmpty = (
    value: object | string | number | null | undefined | Array<object | string | number>,
): boolean => {
    if (value === undefined || value === null) {
        return true;
    }

    if (typeof value === 'string') {
        return !(value || '').trim();
    }

    if (Array.isArray(value)) {
        return !value?.length;
    }

    return false;
};

export const isLessMinLength = (value: string | null | undefined, minLength: number): boolean => {
    if (typeof value === 'string' && value.trim().length < minLength) {
        return true;
    }

    return false;
};

export const isMoreMaxLength = (value: string | null | undefined, maxLength: number): boolean => {
    if (typeof value === 'string' && value.trim().length > maxLength) {
        return true;
    }

    return false;
};
