import { OrdersStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_ORDERS_STATS_REQUEST = 'common/orders-stats/FETCH_ORDERS_STATS_REQUEST';
export const FETCH_ORDERS_STATS_REQUEST_BEGIN = 'common/orders-stats/FETCH_ORDERS_STATS_REQUEST_BEGIN';
export const FETCH_ORDERS_STATS_REQUEST_ERROR = 'common/orders-stats/FETCH_ORDERS_STATS_REQUEST_ERROR';
export const FETCH_ORDERS_STATS_REQUEST_SUCCESS = 'common/orders-stats/FETCH_ORDERS_STATS_REQUEST_SUCCESS';

export type OrdersStatsStateT = {
    stats: OrdersStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchOrdersStatsActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST;
};

export type FetchOrdersStatsBeginActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_BEGIN;
};

export type FetchOrdersStatsSuccessActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_SUCCESS;
    statistics: OrdersStatsT | null;
};

export type FetchOrdersStatsErrorActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_ERROR;
    error: Error;
};

export type OrdersStatsActionT =
    | FetchOrdersStatsActionT
    | FetchOrdersStatsBeginActionT
    | FetchOrdersStatsSuccessActionT
    | FetchOrdersStatsErrorActionT;
