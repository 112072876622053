import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';

const asyncValidate = (saveRequestStatus: RequestStatusT): AsyncFormErrorsT => {
    const asyncErrors: AsyncFormErrorsT = {};

    if (!checkIsAuthApiError(saveRequestStatus.error)) {
        return asyncErrors;
    }

    if (
        saveRequestStatus.error?.type === AuthErrorTypeEnum.wrongPassword ||
        saveRequestStatus.error?.type === AuthErrorTypeEnum.failUpdatePassword ||
        saveRequestStatus.error?.type === AuthErrorTypeEnum.failReauthenticateWithCredential
    ) {
        asyncErrors[FieldsEnum.currentPassword] = saveRequestStatus.error.type;
    }

    return asyncErrors;
};

export default asyncValidate;
