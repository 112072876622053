import { CompanyStateT } from './types';
import { SharableStateT } from '../models';
import { createSelector } from 'reselect';
import { VerificationProblemsT } from 'common/store/company/models';
import { checkVerificationProblems } from 'common/store/company/utils/check-verification-problems';

export const selectCurrentCompanyRequestStatus = (state: SharableStateT): CompanyStateT['currentCompanyRequest'] =>
    state.company.currentCompanyRequest;

export const selectCurrentCompany = (state: SharableStateT): CompanyStateT['currentCompany'] | null =>
    state.company.currentCompany || null;

export const selectUpdateCompanyRequestStatus = (state: SharableStateT): CompanyStateT['updateCompanyRequest'] =>
    state.company.updateCompanyRequest;

export const selectCompanyVerificationProblems = createSelector(
    selectCurrentCompany,
    (currentCompany): VerificationProblemsT => {
        return checkVerificationProblems(currentCompany);
    },
);
