import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
    fillColor: StyleGuideColorsEnum;
};

const OptionsIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, fillColor, ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    fill={strokeColor}
                    d="M15 5h3.333v9.167H15V5zM5.833 15.833h8.334v-12.5H5.833v12.5zM7.5 5h5v9.167h-5V5zM1.667 5H5v9.167H1.667V5z"
                />
                <path fill={fillColor} d="M7.5 5h5v9.167h-5z" />
            </g>
        </svg>
    );
};

OptionsIcon.displayName = 'OptionsIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.slate,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default OptionsIcon;
