import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const PadIcon: React.FC<PropsT> = (props) => {
    const { fillColor, fillOpacity, strokeColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 18 18" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path
                    fillOpacity={fillOpacity || 1}
                    fill={fillColor}
                    d="M3.75 3.75v10.5h10.5V3.75H3.75zm6.75 9H5.25v-1.5h5.25v1.5zm2.25-3h-7.5v-1.5h7.5v1.5zm0-3h-7.5v-1.5h7.5v1.5z"
                />
                <path
                    fill={strokeColor}
                    d="M5.25 11.25h5.25v1.5H5.25v-1.5zm0-3h7.5v1.5h-7.5v-1.5zm0-3h7.5v1.5h-7.5v-1.5zm9-3h-3.135A2.258 2.258 0 009 .75c-.975 0-1.8.63-2.115 1.5H3.75c-.105 0-.203.007-.3.03a1.509 1.509 0 00-1.08.893 1.47 1.47 0 00-.12.577v10.5c0 .203.045.405.12.585a1.5 1.5 0 001.08.892c.097.015.195.023.3.023h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5zM9 2.062c.307 0 .563.256.563.563A.567.567 0 019 3.188a.568.568 0 01-.563-.563c0-.308.256-.563.563-.563zm5.25 12.188H3.75V3.75h10.5v10.5z"
                />
            </g>
        </svg>
    );
};

PadIcon.displayName = 'PadIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PadIcon;
