import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
};

const UrgentIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={fillColor} d="M6.25 2.5v8.25H8.5v6.75l5.25-9h-3l3-6z" />
            </g>
        </svg>
    );
};

UrgentIcon.displayName = 'UrgentIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.squash,
};

export { storyProps };
export default UrgentIcon;

export const UrgentIconProps = {
    getCreateOrder: (): PropsT => {
        return {
            size: DEFAULT_ICON_SIZE,
            fillColor: StyleGuideColorsEnum.orange,
        };
    },
};
