import {
    CompanyDriversStateT,
    DELETE_DRIVER_REQUEST_BEGIN,
    DELETE_DRIVER_REQUEST_ERROR,
    DELETE_DRIVER_REQUEST_SUCCESS,
    DriversActionT,
    DriversStateT,
    FETCH_DRIVER_DETAILS_REQUEST_BEGIN,
    FETCH_DRIVER_DETAILS_REQUEST_ERROR,
    FETCH_DRIVER_DETAILS_REQUEST_SUCCESS,
    FETCH_DRIVERS_PAGE_REQUEST_BEGIN,
    FETCH_DRIVERS_PAGE_REQUEST_ERROR,
    FETCH_DRIVERS_PAGE_REQUEST_SUCCESS,
    INVITE_DRIVER_REQUEST_BEGIN,
    INVITE_DRIVER_REQUEST_ERROR,
    INVITE_DRIVER_REQUEST_SUCCESS,
    RESET_DRIVERS,
    UPDATE_DRIVER_REQUEST_BEGIN,
    UPDATE_DRIVER_REQUEST_ERROR,
    UPDATE_DRIVER_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyDriversState: CompanyDriversStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
    inviteRequest: requestStatus.initial,
    deleteRequest: requestStatus.initial,
    updateRequest: requestStatus.initial,
    fetchDetailsRequest: requestStatus.initial,
    detailsById: {},
};

const initialState: DriversStateT = {};

export default (state = initialState, action: DriversActionT | DestroySessionActionT): DriversStateT => {
    switch (action.type) {
        case FETCH_DRIVERS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageBeginReducer(companyState, pageNumber, query),
                },
            };
        }

        case FETCH_DRIVERS_PAGE_REQUEST_SUCCESS: {
            const { query, pageResponse, pageNumber, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageSuccessReducer(companyState, pageNumber, query, pageResponse),
                },
            };
        }
        case FETCH_DRIVERS_PAGE_REQUEST_ERROR: {
            const { error, query, pageNumber, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageErrorReducer(companyState, pageNumber, query, error),
                },
            };
        }

        case INVITE_DRIVER_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    inviteRequest: requestStatus.loading,
                },
            };
        }

        case INVITE_DRIVER_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    inviteRequest: requestStatus.ok,
                },
            };
        }

        case INVITE_DRIVER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    inviteRequest: requestStatus.setError(error),
                },
            };
        }

        case DELETE_DRIVER_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.loading,
                },
            };
        }

        case DELETE_DRIVER_REQUEST_SUCCESS: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.ok,
                },
            };
        }

        case DELETE_DRIVER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteRequest: requestStatus.setError(error),
                },
            };
        }

        case UPDATE_DRIVER_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.loading,
                },
            };
        }

        case UPDATE_DRIVER_REQUEST_SUCCESS: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.ok,
                },
            };
        }

        case UPDATE_DRIVER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_DRIVER_DETAILS_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchDetailsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_DRIVER_DETAILS_REQUEST_ERROR: {
            const { companyId, error } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchDetailsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_DRIVER_DETAILS_REQUEST_SUCCESS: {
            const { companyId, driverId, details } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    detailsById: {
                        ...companyState.detailsById,
                        [driverId]: details,
                    },
                    fetchDetailsRequest: requestStatus.ok,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        case RESET_DRIVERS: {
            const { companyId, mutationListOptions } = action;
            const companyState = state[companyId] || initialCompanyDriversState;

            return {
                ...initialState,
                [companyId]: {
                    ...initialCompanyDriversState,
                    ...resetPagesCacheReducer(initialCompanyDriversState, companyState, mutationListOptions),
                },
            };

            return {
                ...state,
                [companyId]: null,
            };
        }

        default: {
            return state;
        }
    }
};
