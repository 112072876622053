import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './EmailCell.scss';

type PropsT = {
    email: string;
};

const cx = classNames.bind(styles);

const EmailCell: React.FC<PropsT> = React.memo((props) => {
    const { email } = props;

    return <div className={cx('email')}>{email}</div>;
});

export default EmailCell;
