import React from 'react';

import { CompanyContactT, RolesWithSuccessorT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import Modal from 'common/components/Modal/Modal';
import { logWarning } from 'common/utils/logger';
import { DeactivateUserDataT } from 'common/layouts/Members/dialogs/DeactivateUserModal/models';
import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import { getFirstNeedSuccessorRole } from 'common/store/members/utils';
import { USER_ROLE_T_MAP } from 'common/utils/t';
import { useSelector } from 'react-redux';
import { selectUpdateMemberRequest } from 'common/store/members/selectors';
import { rolesNeededSuccessorSet } from 'common/store/members/constants';
import DeactivateUserWithSuccessorForm from 'broker-admin/layouts/PartnerPage/PartnerMembersPage/DeactivateUserWithSuccessorForm/DeactivateUserWithSuccessorForm';
import DeactivateUserForm from 'common/layouts/Members/DeactivateUserForm/DeactivateUserForm';

export type DeactivateUserModalDataT = {
    contact: {
        userId?: CompanyContactT['userId'];
        roles: CompanyContactT['roles'];
        fullName: CompanyContactT['fullName'];
    } | null;
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

export type DeactivateUserModalPropsT = {
    data: DeactivateUserModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onDeactivateUser: (params: {
        data: DeactivateUserModalDataT | null;
        isNeedSuccessor: boolean;
        changes: DeactivateUserDataT;
    }) => void;
};

const TEST_SELECTOR = 'deactivate-user-modal';

const TITLE_MAP_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.deactivate-confirmation.title',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.deactivate-confirmation.title',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.deactivate-confirmation.partner-title',
};

const MESSAGE_MAP_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.deactivate-confirmation.description',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.deactivate-confirmation.description',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.deactivate-confirmation.partner-description',
};

const DeactivateUserModal: React.FC<DeactivateUserModalPropsT> = React.memo((props) => {
    const { data, onCancel, onClose, onDeactivateUser } = props;

    const contact = data?.contact || null;
    const partnerType = data?.partnerType || null;
    const partnerId = data?.partnerId || null;

    const { t } = useTranslation();

    const isNeedSuccessor = !!contact?.roles.some((roleData) => {
        return rolesNeededSuccessorSet.has(roleData.role as RolesWithSuccessorT);
    });

    const oldUserRoleData = getFirstNeedSuccessorRole(contact?.roles);
    const updateMemberRequest = useSelector(selectUpdateMemberRequest(partnerId));

    if (!contact || !partnerType || !partnerId) {
        return null;
    }

    const FormComponent = isNeedSuccessor ? DeactivateUserWithSuccessorForm : DeactivateUserForm;

    return (
        <Modal>
            <ModalContent onClose={onCancel}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigTrashIcon />}
                    title={t(TITLE_MAP_T[partnerType], {
                        fullName: contact?.fullName,
                    })}
                    message={t(MESSAGE_MAP_T[partnerType], {
                        fullName: contact?.fullName,
                        role: t(USER_ROLE_T_MAP[oldUserRoleData?.role as RolesEnum]),
                    })}
                >
                    <FormComponent
                        contact={contact}
                        updateMemberRequest={updateMemberRequest}
                        onDeactivateUser={(changes) => {
                            if (changes) {
                                onDeactivateUser({ data, isNeedSuccessor, changes });
                            } else {
                                logWarning('empty user id');
                            }

                            onClose();
                        }}
                        onCancel={onCancel}
                    />
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default DeactivateUserModal;
