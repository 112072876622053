import {
    FETCH_MANAGERS_REQUEST,
    FETCH_MANAGERS_REQUEST_BEGIN,
    FETCH_MANAGERS_REQUEST_ERROR,
    FETCH_MANAGERS_REQUEST_SUCCESS,
    FetchManagersActionT,
    FetchManagersBeginActionT,
    FetchManagersErrorActionT,
    FetchManagersSuccessActionT,
} from './types';

import { ManagerT } from './models';

export const fetchManagers = (): FetchManagersActionT => ({
    type: FETCH_MANAGERS_REQUEST,
});

export const fetchManagersBegin = (): FetchManagersBeginActionT => ({
    type: FETCH_MANAGERS_REQUEST_BEGIN,
});

export const fetchManagersSuccess = (managers: ManagerT[]): FetchManagersSuccessActionT => ({
    type: FETCH_MANAGERS_REQUEST_SUCCESS,
    managers,
});

export const fetchManagersError = (error: Error): FetchManagersErrorActionT => ({
    type: FETCH_MANAGERS_REQUEST_ERROR,
    error,
});
