import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './CountryFlagPlaceholderOption.scss';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

export type PropsT = {
    label: string;
};

const CountryFlagPlaceholderOption: React.FC<PropsT> = (props) => {
    const { label } = props;

    return (
        <div className={cx('option')}>
            <FlagIcon className={cx('icon')} />
            <div className={cx('label')}>{label}</div>
        </div>
    );
};

export default CountryFlagPlaceholderOption;
