import * as React from 'react';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import { useSelector } from 'react-redux';
import { AddTruckSidebarDataT } from 'common/layouts/SideBars/contents/AddTruckSidebarContent/models';
import { selectAddTruckRequestStatus } from 'common/store/trucks/selectors';
import EditTruckSidebarContent from 'common/layouts/CommonEditableTruckDetailsLayout/EditTruckSidebarContent/EditTruckSidebarContent';

type PropsT = SidebarContentPropsT<AddTruckSidebarDataT, CommonSidebarDataT>;

const AddTruckSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onGoBack, onClose, setNeedCloseConfirmation, onOpenNextSidebar } = props;

    const addTruckRequestStatus = useSelector(selectAddTruckRequestStatus(data.partnerId));
    useCloseSidebarAfterRequest(addTruckRequestStatus, onGoBack || onClose);

    if (!onClose) {
        return null;
    }

    const handleOpenTruckDetails = (truckId: TruckIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.truckDetails,
            partnerId: data.partnerId,
            truckId,
        });
    };

    const handleOpenTrailerDetails = (trailerId: TrailerIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            partnerId: data.partnerId,
            trailerId,
        });
    };

    return (
        <EditTruckSidebarContent
            partnerId={data.partnerId}
            truckId={null}
            onClose={onClose}
            onCancel={onClose}
            setNeedCloseConfirmation={setNeedCloseConfirmation}
            onOpenTrailerDetails={handleOpenTrailerDetails}
            onOpenTruckDetails={handleOpenTruckDetails}
        />
    );
};

export default AddTruckSidebarContent;
