import {
    CHANGE_RESERVE_QUERY,
    ChangeReserveQueryActionT,
    CLEAR_OFFERS_TASKS,
    ClearOffersTasksActionT,
    CORRECT_PREVIEW_RESERVE_QUERY,
    CorrectPreviewReserveQueryActionT,
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_REQUEST_BEGIN,
    CREATE_ORDER_REQUEST_ERROR,
    CREATE_ORDER_REQUEST_SUCCESS,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS,
    CREATE_RESERVE_BEGIN,
    CREATE_RESERVE_ERROR,
    CREATE_RESERVE_SUCCESS,
    CREATE_RFQ_REQUEST,
    CREATE_RFQ_REQUEST_BEGIN,
    CREATE_RFQ_REQUEST_ERROR,
    CREATE_RFQ_REQUEST_SUCCESS,
    CreateOrderActionT,
    CreateOrderBeginActionT,
    CreateOrderErrorActionT,
    CreateOrderSuccessActionT,
    CreatePriceOfferWithLaneRequestActionT,
    CreatePriceOfferWithLaneRequestBeginActionT,
    CreatePriceOfferWithLaneRequestErrorActionT,
    CreatePriceOfferWithLaneRequestSuccessActionT,
    CreateReserveBeginActionT,
    CreateReserveErrorActionT,
    CreateReserveSuccessActionT,
    CreateRFQActionT,
    CreateRFQBeginActionT,
    CreateRFQErrorActionT,
    CreateRFQSuccessActionT,
    ENSURE_CREATED_RFQ,
    EnsureCreatedRFQActionT,
    FETCH_OFFERS,
    FETCH_OFFERS_ERROR,
    FETCH_ROUTE,
    FETCH_ROUTE_ERROR,
    FETCH_ROUTE_SUCCESS,
    FETCH_TASK_OFFER,
    FETCH_TASK_OFFER_ERROR,
    FetchOffersActionT,
    FetchOffersErrorActionT,
    FetchRouteActionT,
    FetchRouteErrorActionT,
    FetchRouteSuccessActionT,
    FetchTaskOfferActionT,
    FetchTaskOfferErrorActionT,
    MARK_OFFERS_EXPIRED,
    MarkOffersExpiredActionT,
    PREVIEW_RESERVE_BEGIN,
    PREVIEW_RESERVE_ERROR,
    PREVIEW_RESERVE_SUCCESS,
    PreviewReserveBeginActionT,
    PreviewReserveErrorActionT,
    PreviewReserveSuccessActionT,
    RESET,
    RESET_CREATE_RESERVE_WITH_QUERY,
    RESET_RESERVE_AND_RESERVE_PREVIEW,
    ResetActionT,
    ResetCreateReserveWithQueryActionT,
    ResetReserveAndReservePreviewT,
    SELECT_OFFER,
    SelectOfferActionT,
    SET_ALLOW_CREATE_RFQ,
    SET_CREATE_RESERVE_QUERY,
    SET_EXPECTED_OFFERS_COUNT,
    SET_PREVIEW_RESERVE_QUERY,
    SetAllowCreateRFQT,
    SetCreateReserveQueryActionT,
    SetExpectedOffersCountT,
    SetPreviewReserveQueryActionT,
} from './types';
import {
    CreateReserveQueryT,
    PriceOfferT,
    ReservePreviewQueryT,
    ReservePreviewT,
    ReserveQueryChangesT,
    ReserveT,
    RFQT,
} from './models';
import { ApiActualOrderRequestT, ApiCreateOrderRequestT } from 'common/utils/api/models';

export const changeReserveQuery = (changes: ReserveQueryChangesT): ChangeReserveQueryActionT => ({
    type: CHANGE_RESERVE_QUERY,
    changes,
});

export const setAllowCreateRFQ = (isAllowCreateRFQ: boolean): SetAllowCreateRFQT => ({
    type: SET_ALLOW_CREATE_RFQ,
    isAllowCreateRFQ,
});

export const createRFQ = (
    actualOrderRequest: ApiActualOrderRequestT,
    reserve: ReserveT,
    shipperId: ShipperIdT | null,
): CreateRFQActionT => ({
    type: CREATE_RFQ_REQUEST,
    actualOrderRequest,
    reserve,
    shipperId,
});

export const createPriceOfferWithLaneRequest = (
    actualOrderRequest: ApiActualOrderRequestT,
    reserve: ReserveT,
    shipperId: ShipperIdT | null,
    laneId: ShipperContractLaneIdT,
): CreatePriceOfferWithLaneRequestActionT => ({
    type: CREATE_PRICE_OFFER_WITH_LANE_REQUEST,
    actualOrderRequest,
    reserve,
    shipperId,
    laneId,
});

export const createPriceOfferWithLaneRequestBegin = (): CreatePriceOfferWithLaneRequestBeginActionT => ({
    type: CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN,
});

export const createPriceOfferWithLaneRequestSuccess = (
    rfq: RFQT,
    lanePriceOffer: PriceOfferT | null,
): CreatePriceOfferWithLaneRequestSuccessActionT => ({
    type: CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS,
    rfq,
    lanePriceOffer,
});

export const createPriceOfferWithLaneRequestError = (error: Error): CreatePriceOfferWithLaneRequestErrorActionT => ({
    type: CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR,
    error,
});

export const createRFQBegin = (): CreateRFQBeginActionT => ({
    type: CREATE_RFQ_REQUEST_BEGIN,
});

export const ensureCreatedRFQ = (rfqId: RFQIdT): EnsureCreatedRFQActionT => ({
    type: ENSURE_CREATED_RFQ,
    rfqId,
});

export const createRFQSuccess = (rfq: RFQT): CreateRFQSuccessActionT => ({
    type: CREATE_RFQ_REQUEST_SUCCESS,
    rfq,
});

export const fetchRoute = (polylineId: PolylineIdT | null): FetchRouteActionT => ({
    type: FETCH_ROUTE,
    polylineId,
});

export const fetchRouteSuccess = (polylines: Array<GooglePolylineT>): FetchRouteSuccessActionT => ({
    type: FETCH_ROUTE_SUCCESS,
    polylines,
});

export const fetchRouteError = (error: Error): FetchRouteErrorActionT => ({
    type: FETCH_ROUTE_ERROR,
    error,
});

export const createRFQError = (error: Error): CreateRFQErrorActionT => ({
    type: CREATE_RFQ_REQUEST_ERROR,
    error,
});

export const createOrder = (
    rfqId: RFQIdT,
    createOrderRequest: ApiCreateOrderRequestT,
    isCreateNextOrder: boolean,
): CreateOrderActionT => ({
    type: CREATE_ORDER_REQUEST,
    rfqId,
    createOrderRequest,
    isCreateNextOrder,
});

export const createOrderBegin = (): CreateOrderBeginActionT => ({
    type: CREATE_ORDER_REQUEST_BEGIN,
});

export const createOrderSuccess = (): CreateOrderSuccessActionT => ({
    type: CREATE_ORDER_REQUEST_SUCCESS,
});

export const createOrderError = (error: Error): CreateOrderErrorActionT => ({
    type: CREATE_ORDER_REQUEST_ERROR,
    error,
});

export const selectOffer = (rfqId: RFQIdT, offer: PriceOfferT): SelectOfferActionT => ({
    type: SELECT_OFFER,
    rfqId,
    offer,
});

export const fetchOffersError = (error: Error): FetchOffersErrorActionT => ({
    type: FETCH_OFFERS_ERROR,
    error,
});

export const setExpectedOffersCount = (expectedOffersCount: number | null): SetExpectedOffersCountT => ({
    type: SET_EXPECTED_OFFERS_COUNT,
    expectedOffersCount,
});

export const fetchOffers = (priceOffers: Array<PriceOfferT>): FetchOffersActionT => ({
    type: FETCH_OFFERS,
    priceOffers,
});

export const markOffersExpired = (ids: Array<PriceOfferIdT>): MarkOffersExpiredActionT => ({
    type: MARK_OFFERS_EXPIRED,
    ids,
});

export const fetchTaskOffer = (offer: PriceOfferT, tasksCount: number): FetchTaskOfferActionT => ({
    type: FETCH_TASK_OFFER,
    offer,
    tasksCount,
});

export const fetchTaskOfferError = (error: Error): FetchTaskOfferErrorActionT => ({
    type: FETCH_TASK_OFFER_ERROR,
    error,
});

export const clearOffersTasks = (): ClearOffersTasksActionT => ({
    type: CLEAR_OFFERS_TASKS,
});

export const resetOrderCreation = (): ResetActionT => ({
    type: RESET,
});

export const setCreateReserveQuery = (query: CreateReserveQueryT | null): SetCreateReserveQueryActionT => ({
    type: SET_CREATE_RESERVE_QUERY,
    query,
});

export const createReserveBegin = (query: CreateReserveQueryT | null): CreateReserveBeginActionT => ({
    type: CREATE_RESERVE_BEGIN,
    query,
});

export const createReserveSuccess = (
    reserve: ReserveT,
    query: CreateReserveQueryT | null,
): CreateReserveSuccessActionT => ({
    type: CREATE_RESERVE_SUCCESS,
    reserve,
    query,
});

export const createReserveError = (error: Error, query: CreateReserveQueryT | null): CreateReserveErrorActionT => ({
    type: CREATE_RESERVE_ERROR,
    error,
    query,
});

export const resetCreateReserveWithQuery = (query: CreateReserveQueryT | null): ResetCreateReserveWithQueryActionT => ({
    type: RESET_CREATE_RESERVE_WITH_QUERY,
    query,
});

export const previewReserveBegin = (query: ReservePreviewQueryT | null): PreviewReserveBeginActionT => ({
    type: PREVIEW_RESERVE_BEGIN,
    query,
});

export const setPreviewReserveQuery = (query: ReservePreviewQueryT | null): SetPreviewReserveQueryActionT => ({
    type: SET_PREVIEW_RESERVE_QUERY,
    query,
});

export const correctPreviewReserveQuery = (query: ReservePreviewQueryT | null): CorrectPreviewReserveQueryActionT => ({
    type: CORRECT_PREVIEW_RESERVE_QUERY,
    query,
});

export const previewReserveSuccess = (
    preview: ReservePreviewT,
    query: ReservePreviewQueryT | null,
): PreviewReserveSuccessActionT => ({
    type: PREVIEW_RESERVE_SUCCESS,
    preview,
    query,
});

export const previewReserveError = (error: Error, query: ReservePreviewQueryT | null): PreviewReserveErrorActionT => ({
    type: PREVIEW_RESERVE_ERROR,
    error,
    query,
});

export const resetReserveAndPreviewReserve = (): ResetReserveAndReservePreviewT => ({
    type: RESET_RESERVE_AND_RESERVE_PREVIEW,
});
