import { SharableMembersStateT } from 'common/store/models';
import { RequestStatusT } from 'common/utils/request-status';
import { CompanyContactT } from './models';
import { initialCompanyMembersState } from './reducer';
import { CompanyMembersStateT, ContactDetailsByIdT } from './types';

const selectCompanyMembersState = (
    state: SharableMembersStateT,
    companyId: CompanyIdT | null,
): CompanyMembersStateT => {
    return state.members[companyId as string] || initialCompanyMembersState;
};

export const selectCompanyContacts =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): CompanyContactT[] | null => {
        const companyMembers = selectCompanyMembersState(state, companyId);

        if (!companyMembers.contactIds) {
            return null;
        }

        return companyMembers.contactIds.map((id) => companyMembers.constantById[id]);
    };

export const selectContactById =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): Record<ContactIdT, CompanyContactT> =>
        selectCompanyMembersState(state, companyId).constantById;

export const selectFetchContactsRequest =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): RequestStatusT =>
        selectCompanyMembersState(state, companyId).fetchContactsRequest;

export const selectAddMemberRequest =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): RequestStatusT =>
        selectCompanyMembersState(state, companyId).addMemberRequest;

export const selectDeleteMemberRequest =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): RequestStatusT =>
        selectCompanyMembersState(state, companyId).deleteMemberRequest;

export const selectUpdateMemberRequest =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): RequestStatusT =>
        selectCompanyMembersState(state, companyId).updateMemberRequest;

export const selectFetchContactDetailsRequest =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): RequestStatusT =>
        selectCompanyMembersState(state, companyId).fetchContactDetailsRequest;

export const selectContactDetailsById =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): ContactDetailsByIdT =>
        selectCompanyMembersState(state, companyId).contactDetailsById;

export const selectContactIdByUserId =
    (companyId: CompanyIdT | null) =>
    (state: SharableMembersStateT): Record<UserIdT, ContactIdT> =>
        selectCompanyMembersState(state, companyId).contactIdByUserId;
