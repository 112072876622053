import isNumber from 'lodash/isNumber';

export const uppercaseFormatter = (value: string): string => value.toUpperCase();

export const uppercaseFirstCharacterFormatter = (value: string): string => {
    if (!value) {
        return value;
    }

    return value[0].toUpperCase() + value.slice(1);
};

export const simpleNumberFormatter = (value: number | null | undefined): string => {
    if (!isNumber(value)) {
        return '';
    }

    return String(value);
};

export const simpleStringFormatter = (value: string | null | undefined): string => {
    return (value || '').trim();
};
