import { RequestStatusT } from 'common/utils/request-status';

import { DispatcherT } from './models';

export const FETCH_DISPATCHERS_REQUEST = 'broker-admin/dispatchers/FETCH_DISPATCHERS_REQUEST';
export const FETCH_DISPATCHERS_REQUEST_BEGIN = 'broker-admin/dispatchers/FETCH_DISPATCHERS_REQUEST_BEGIN';
export const FETCH_DISPATCHERS_REQUEST_ERROR = 'broker-admin/dispatchers/FETCH_DISPATCHERS_REQUEST_ERROR';
export const FETCH_DISPATCHERS_REQUEST_SUCCESS = 'broker-admin/dispatchers/FETCH_DISPATCHERS_REQUEST_SUCCESS';

type DispatcherIdT = string;

export type DispatchersStateT = {
    request: RequestStatusT;
    byId: Record<DispatcherIdT, DispatcherT>;
    allIds: Array<DispatcherIdT>;
};

export type FetchDispatchersActionT = {
    type: typeof FETCH_DISPATCHERS_REQUEST;
};

export type FetchDispatchersBeginActionT = {
    type: typeof FETCH_DISPATCHERS_REQUEST_BEGIN;
};

export type FetchDispatchersSuccessActionT = {
    type: typeof FETCH_DISPATCHERS_REQUEST_SUCCESS;
    dispatchers: DispatcherT[];
};

export type FetchDispatchersErrorActionT = {
    type: typeof FETCH_DISPATCHERS_REQUEST_ERROR;
    error: Error;
};

export type DispatchersActionT =
    | FetchDispatchersActionT
    | FetchDispatchersBeginActionT
    | FetchDispatchersSuccessActionT
    | FetchDispatchersErrorActionT;
