import { StateT } from '../../../shipper/store/models';
import { BestOffersT, PriceOfferT, RFQT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { OrderCreationStateT } from './types';
import { byPriceAsc } from './utils/sort-offers';
import { isNonNil } from 'common/utils';
import mapValues from 'lodash/mapValues';
import { createSelector } from 'reselect';

export const selectCreateRFQRequest = (state: StateT): RequestStatusT => state.orderCreation.createRFQRequest;
export const selectCreatePriceOfferWithLaneRequest = (state: StateT): RequestStatusT =>
    state.orderCreation.createPriceOfferWithLaneRequest;

export const selectRFQ = (state: StateT): RFQT | null => state.orderCreation.rfq;

export const selectLanePriceOffer = (state: StateT): OrderCreationStateT['lanePriceOffer'] =>
    state.orderCreation.lanePriceOffer;

export const selectPolylines = (state: StateT): Array<GooglePolylineT> | null => state.orderCreation.polylines;

export const selectPriceOffers = (state: StateT): OrderCreationStateT['priceOffers'] => state.orderCreation.priceOffers;

export const selectPriceOffersList = createSelector(selectPriceOffers, (priceOffers): Array<PriceOfferT> => {
    return (priceOffers?.list || []).map((id) => priceOffers?.byId[id]).filter(isNonNil);
});

export const selectPriceOffersByHash = createSelector(
    selectPriceOffers,
    (priceOffers): Record<string, Array<PriceOfferT>> => {
        return mapValues(priceOffers?.byHash, (ids: PriceOfferIdT[]) => {
            return ids.map((id) => priceOffers?.byId[id]).filter(isNonNil);
        });
    },
);

export const selectPriceOffersById = (state: StateT): Record<string, PriceOfferT> =>
    state.orderCreation.priceOffers?.byId || {};

export const selectCreateOrderRequest = (state: StateT): RequestStatusT => state.orderCreation.createOrderRequest;

export const selectOffersTasks = (state: StateT): OrderCreationStateT['offersTasks'] => state.orderCreation.offersTasks;

export const selectIsPartialLoadingOffers = (state: StateT): boolean => {
    const { total, count } = state.orderCreation.offersTasks;

    return total !== null && total !== count;
};

export const selectReserve = (state: StateT): OrderCreationStateT['reserve'] => {
    return state.orderCreation.reserve;
};

export const selectReserveQuery = (state: StateT): OrderCreationStateT['reserveQuery'] => {
    return state.orderCreation.reserveQuery;
};

export const selectReserveRequest = (state: StateT): OrderCreationStateT['reserveRequest'] => {
    return state.orderCreation.reserveRequest;
};

export const selectReservePreview = (state: StateT): OrderCreationStateT['previewReserve'] => {
    return state.orderCreation.previewReserve;
};

export const selectReservePreviewQuery = (state: StateT): OrderCreationStateT['previewReserveQuery'] => {
    return state.orderCreation.previewReserveQuery;
};

export const selectIsAllowCreateRFQ = (state: StateT): OrderCreationStateT['isAllowCreateRFQ'] => {
    return state.orderCreation.isAllowCreateRFQ;
};

export const selectReservePreviewRequest = (state: StateT): OrderCreationStateT['previewReserveRequest'] => {
    return state.orderCreation.previewReserveRequest;
};

export const selectBestOffers = createSelector(selectPriceOffersList, (priceOffers): BestOffersT | null => {
    if (!priceOffers) {
        return null;
    }

    const bestOffers: BestOffersT = {
        bestPrice: null,
        urgent: null,
    };

    const bestPriceOffer = priceOffers.filter((offer) => offer.isBestPrice).sort(byPriceAsc)[0];
    if (bestPriceOffer) {
        bestOffers.bestPrice = bestPriceOffer;
    }

    const bestUrgentOffer = priceOffers.filter((offer) => offer.isUrgentShipment).sort(byPriceAsc)[0];
    if (bestUrgentOffer) {
        bestOffers.urgent = bestUrgentOffer;
    }

    return bestOffers;
});
