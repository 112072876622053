import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PhoneCell.scss';

type PropsT = {
    phone: string;
};

const cx = classNames.bind(styles);

const PhoneCell: React.FC<PropsT> = React.memo((props) => {
    const { phone } = props;

    return <div className={cx('phone')}>{phone}</div>;
});

export default PhoneCell;
