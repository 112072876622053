import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import { ShipperContractLaneDetailsT, UpdateShipperContractLaneChangesT } from './models';

export type ShipperContractLaneDetailsItemStateT = {
    fetchRequest: RequestStatusT;
    data: ShipperContractLaneDetailsT | null;
};

export const initialShipperContractLaneDetailsItemState: ShipperContractLaneDetailsItemStateT = {
    fetchRequest: requestStatus.initial,
    data: null,
};

export type ShipperContractLaneDetailsStateT = {
    updateRequest: RequestStatusT;
    detailsById: Record<ShipperContractLaneIdT, ShipperContractLaneDetailsItemStateT>;
};

const initialState: ShipperContractLaneDetailsStateT = {
    updateRequest: requestStatus.initial,
    detailsById: {},
};

export const shipperContractLaneDetailsSlice = createSlice({
    name: 'shipperContractLaneDetails',
    initialState,
    reducers: {
        fetchShipperContractLaneDetails: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchShipperContractLaneDetailsBegin: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            const { laneId } = action.payload;
            const detailsItem = state.detailsById[laneId] || initialShipperContractLaneDetailsItemState;

            state.detailsById[laneId] = {
                ...detailsItem,
                fetchRequest: requestStatus.loading,
            };
        },
        fetchShipperContractLaneDetailsSuccess: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
                data: ShipperContractLaneDetailsT | null;
            }>,
        ) => {
            const { laneId, data } = action.payload;
            const detailsItem = state.detailsById[laneId] || initialShipperContractLaneDetailsItemState;

            state.detailsById[laneId] = {
                ...detailsItem,
                fetchRequest: requestStatus.ok,
                data,
            };
        },
        fetchShipperContractLaneDetailsError: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
                error: Error;
            }>,
        ) => {
            const { laneId, error } = action.payload;
            const detailsItem = state.detailsById[laneId] || initialShipperContractLaneDetailsItemState;

            state.detailsById[laneId] = {
                ...detailsItem,
                fetchRequest: requestStatus.setError(error),
            };
        },
        revokeShipperContractLane: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
            }>,
        ) => {
            // nothing
        },
        updateShipperContractLane: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                laneId: ShipperContractLaneIdT;
                detailsChanges: UpdateShipperContractLaneChangesT;
            }>,
        ) => {
            // nothing
        },
        updateShipperContractLaneDetailsBegin: (state, action: PayloadAction<{}>) => {
            state.updateRequest = requestStatus.loading;
        },
        updateShipperContractLaneDetailsSuccess: (state, action: PayloadAction<{}>) => {
            state.updateRequest = requestStatus.ok;
        },
        updateShipperContractLaneDetailsError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;
            state.updateRequest = requestStatus.setError(error);
        },
    },
});

export const {
    fetchShipperContractLaneDetails,
    fetchShipperContractLaneDetailsBegin,
    fetchShipperContractLaneDetailsSuccess,
    fetchShipperContractLaneDetailsError,
    revokeShipperContractLane,
    updateShipperContractLane,
    updateShipperContractLaneDetailsBegin,
    updateShipperContractLaneDetailsError,
    updateShipperContractLaneDetailsSuccess,
} = shipperContractLaneDetailsSlice.actions;

export default shipperContractLaneDetailsSlice.reducer;
