import { ErrorActionT } from './models';

export const CLOSE_ERROR_MODAL = 'common/errors/CLOSE_ERROR_MODAL';
export const OPEN_ERROR_MODAL = 'common/errors/OPEN_ERROR_MODAL';
export const RESET_LOGS = 'common/errors/RESET_LOGS';

export type ErrorsStateT = {
    logs: Array<ErrorActionT>;
    isShowErrorModal: boolean;
};

export type CloseErrorModalActionT = {
    type: typeof CLOSE_ERROR_MODAL;
};

export type OpenErrorModalActionT = {
    type: typeof OPEN_ERROR_MODAL;
};

export type ResetErrorLogsActionT = {
    type: typeof RESET_LOGS;
};

export type ErrorsActionT = CloseErrorModalActionT | OpenErrorModalActionT | ResetErrorLogsActionT;
