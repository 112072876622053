import { FieldsEnum as DetailsFieldsEnum, FormValuesT as DetailsFormValuesT } from './TruckDetailsForm/constants';
import { ApiTruckAddRequestT, ApiTruckUpdateRequestT, CountryCodeUnionT } from 'common/utils/api/models';
import { parseSimpleNumber, parseStringFormatter } from 'common/utils/input-parsers';

const prepareUpdateApiTruck = (detailsFormValues: DetailsFormValuesT): ApiTruckUpdateRequestT | null => {
    const contractId = detailsFormValues[DetailsFieldsEnum.contract];

    return {
        dictTruckId: detailsFormValues[DetailsFieldsEnum.truckModel] as string,
        cabinColor: parseStringFormatter(detailsFormValues[DetailsFieldsEnum.cabinColor]),
        countryOfRegistration: detailsFormValues[DetailsFieldsEnum.countryCode] as CountryCodeUnionT,
        vin: parseStringFormatter(detailsFormValues[DetailsFieldsEnum.vin]),
        contractId: contractId || undefined,
        ratePerKm: contractId === null ? parseSimpleNumber(detailsFormValues[DetailsFieldsEnum.ratePerKm]) : undefined,
    };
};

const prepareCreateApiTruck = (detailsFormValues: DetailsFormValuesT): ApiTruckAddRequestT | null => {
    const updateApiTruck = prepareUpdateApiTruck(detailsFormValues);

    return {
        ...updateApiTruck,
        plateNumber: parseStringFormatter(detailsFormValues[DetailsFieldsEnum.plateNumber]),
    };
};

export { prepareCreateApiTruck, prepareUpdateApiTruck };
