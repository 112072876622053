import * as React from 'react';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';

import classNames from 'classnames/bind';
import styles from './CreateCompanyForm.scss';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { getZipCodePlaceholder } from 'common/utils/postal-codes';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import LegalFormDropdown from 'common/components/dropdowns/LegalFormDropdown/LegalFormDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useCheckCountryCorrectLegacyForm } from 'common/components/dropdowns/LegalFormDropdown/hooks';
import { RequestStatusT } from 'common/utils/request-status';
import { ApiCountriesT, ApiRegistrationCompanyRequestDraftT } from 'common/utils/api/models';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.companyName]: '',
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.zipCode]: '',
    [FieldsEnum.city]: '',
    [FieldsEnum.street]: '',
    [FieldsEnum.legalFormId]: null,
};

type PropsT = {
    requestStatus?: RequestStatusT;
    onSubmit: (registrationCompanyRequestDraft: ApiRegistrationCompanyRequestDraftT) => void;
};

const CreateCompanyForm: React.FC<PropsT> = React.memo((props) => {
    const { onSubmit, requestStatus } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, INITIAL_VALUES);
    }, [t, INITIAL_VALUES]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues: INITIAL_VALUES,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const dictLegalFormId = values[FieldsEnum.legalFormId];
            const countryCode = values[FieldsEnum.countryCode] as ApiCountriesT;
            if (!isNumber(dictLegalFormId) || !countryCode) {
                return;
            }

            onSubmit({
                dictLegalFormId,
                companyName: values[FieldsEnum.companyName],
                countryCode,
                street: values[FieldsEnum.street],
                zipCode: values[FieldsEnum.zipCode],
                city: values[FieldsEnum.city],
            });

            formikHelpers.setTouched({});
        },
    });

    const parseRequestError = (error: Error | null | undefined): string | null => {
        if (!error) {
            return null;
        }

        // if (
        //     checkIsTranziitApiRequestError(error) &&
        //     error.subType === TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist
        // ) {
        //     return t('common:auth-errors.email-already-exist');
        // }

        return error?.message || null;
    };

    const selectedLegalFormId = formik.values[FieldsEnum.legalFormId];
    const selectedCountry = formik.values[FieldsEnum.countryCode];

    useCheckCountryCorrectLegacyForm(
        {
            selectedLegalFormId,
            selectedCountry,
        },
        () => {
            formik.setTouched({ [FieldsEnum.legalFormId]: false }, false);
            formik.setFieldValue(FieldsEnum.legalFormId, null);
        },
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('fields')}>
                <FormikField
                    name={FieldsEnum.companyName}
                    error={formik.errors[FieldsEnum.companyName]}
                    meta={formik.getFieldMeta(FieldsEnum.companyName)}
                    label={t('common:self-create-company.fields.companyName.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.companyName}
                            value={formik.values[FieldsEnum.companyName]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.countryCode}
                    error={formik.errors[FieldsEnum.countryCode]}
                    label={t('common:self-create-company.fields.countryCode.label')}
                    meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <CountryDropdown
                            value={formik.values[FieldsEnum.countryCode]}
                            onChange={(countryCode) => {
                                props.onChange(countryCode);
                            }}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    )}
                </FormikField>
                <FieldGroup spaces={1}>
                    <FormikField
                        className={cx('field--zip')}
                        name={FieldsEnum.zipCode}
                        error={formik.errors[FieldsEnum.zipCode]}
                        meta={formik.getFieldMeta(FieldsEnum.zipCode)}
                        label={t('common:self-create-company.fields.zipCode.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.zipCode}
                                value={formik.values[FieldsEnum.zipCode]}
                                placeholder={getZipCodePlaceholder(formik.values[FieldsEnum.countryCode])}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field--city')}
                        name={FieldsEnum.city}
                        error={formik.errors[FieldsEnum.city]}
                        meta={formik.getFieldMeta(FieldsEnum.city)}
                        label={t('common:self-create-company.fields.city.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.city}
                                value={formik.values[FieldsEnum.city]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FormikField
                    name={FieldsEnum.street}
                    error={formik.errors[FieldsEnum.street]}
                    meta={formik.getFieldMeta(FieldsEnum.street)}
                    label={t('common:self-create-company.fields.street.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.street}
                            value={formik.values[FieldsEnum.street]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.legalFormId}
                    error={formik.errors[FieldsEnum.legalFormId]}
                    meta={formik.getFieldMeta(FieldsEnum.legalFormId)}
                    label={t('common:self-create-company.fields.legal-form.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <LegalFormDropdown
                            countryCode={formik.values[FieldsEnum.countryCode]}
                            value={formik.values[FieldsEnum.legalFormId]}
                            onChange={props.onChange}
                            placeholder={t('common:self-create-company.fields.legal-form.placeholder')}
                            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    )}
                </FormikField>
            </div>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <Button
                isLoading={requestStatus?.loading}
                theme={ButtonThemeEnum.primary}
                type="submit"
                className={cx('submit')}
            >
                {t('common:self-create-company.actions.submit')}
            </Button>
            <div className={cx('error')}>{parseRequestError(requestStatus?.error)}</div>
        </form>
    );
});

export default CreateCompanyForm;
