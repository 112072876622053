import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './HorizontalColumnsLayout.scss';

const cx = classNames.bind(styles);

enum TabsEnum {
    left = 'left',
    right = 'right',
}

type PropsT = {
    className?: string;
    isNarrow?: boolean;
    leftColumnTitle: React.ReactNode;
    leftColumnNode: React.ReactNode;
    rightColumnTitle: React.ReactNode;
    rightColumnNode: React.ReactNode;
    overlayNode?: React.ReactNode;
    testSelector?: string;
};

const HorizontalColumnsLayout: React.FC<PropsT> = React.memo((props) => {
    const {
        isNarrow,
        leftColumnTitle,
        leftColumnNode,
        rightColumnTitle,
        rightColumnNode,
        overlayNode,
        className,
        testSelector,
    } = props;

    const [activeTab, setTab] = React.useState<TabsEnum>(TabsEnum.left);

    return (
        <div className={cs(cx('content'), className)} data-test-selector={`${testSelector}_horizontal-columns`}>
            <div className={cx('inner')}>
                <div className={cx('tabs')}>
                    <div
                        className={cx('tab-item', {
                            'tab-item--isActive': activeTab === TabsEnum.left,
                        })}
                        onClick={() => setTab(TabsEnum.left)}
                    >
                        {leftColumnTitle}
                    </div>
                    <div
                        className={cx('tab-item', {
                            'tab-item--isActive': activeTab === TabsEnum.right,
                        })}
                        onClick={() => setTab(TabsEnum.right)}
                    >
                        {rightColumnTitle}
                    </div>
                </div>
                <div className={cx('tabs__container')}>
                    <div
                        className={cx('tabs__tab', 'tabs__tab--isLeftTab', {
                            'tabs__tab--isNarrow': isNarrow,
                        })}
                    >
                        <div className={cx('tab')}>{leftColumnNode}</div>
                    </div>
                    <div
                        className={cx('tabs__tab', 'tabs__tab--isRightTab', {
                            'tabs__tab--isActiveRightTab': activeTab === TabsEnum.right,
                        })}
                    >
                        {rightColumnNode}
                    </div>
                    {overlayNode}
                </div>
            </div>
        </div>
    );
});

export default HorizontalColumnsLayout;
