import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const PinIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path
                    fill={fillColor}
                    d="M14.875 8.65c0 1.928-1.575 4.342-4.62 7.133l-.255.225-.255-.233c-3.045-2.783-4.62-5.197-4.62-7.125 0-2.88 2.115-5.025 4.875-5.025s4.875 2.138 4.875 5.025z"
                />
                <path
                    fill={strokeColor}
                    d="M10 2.5c3.15 0 6 2.415 6 6.15 0 2.49-2.002 5.438-6 8.85-3.997-3.412-6-6.36-6-8.85C4 4.915 6.85 2.5 10 2.5zm4.5 6.15C14.5 5.927 12.512 4 10 4 7.487 4 5.5 5.928 5.5 8.65c0 1.755 1.463 4.08 4.5 6.855 3.037-2.775 4.5-5.1 4.5-6.855zM10 10c-.825 0-1.5-.675-1.5-1.5S9.175 7 10 7s1.5.675 1.5 1.5S10.825 10 10 10z"
                />
                <path d="M1 1h18v18H1z" />
            </g>
        </svg>
    );
};

PinIcon.displayName = 'PinIcon';

export const PinIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.white,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PinIcon;
