import * as React from 'react';

type PropsT = {
    className?: string;
};

const SignOutIcon: React.FC<PropsT> = (props) => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill="#3E3F42" d="M16 9.25H6.872l4.193-4.192L10 4l-6 6 6 6 1.057-1.057-4.185-4.193H16z" />
            </g>
        </svg>
    );
};

export default SignOutIcon;
