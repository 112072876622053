import {
    LEGAL_FORMS_DICT_REQUEST,
    LEGAL_FORMS_DICT_REQUEST_BEGIN,
    LEGAL_FORMS_DICT_REQUEST_ERROR,
    LEGAL_FORMS_DICT_REQUEST_SUCCESS,
    FetchLegalFormsDictActionT,
    FetchLegalFormsDictBeginActionT,
    FetchLegalFormsDictErrorActionT,
    FetchLegalFormsDictSuccessActionT,
} from './types';
import { LegalFormsListT } from './models';
import { CountryCodeT } from 'common/store/countries-dict/models';

export const fetchLegalFormsDict = (countryCode: CountryCodeT): FetchLegalFormsDictActionT => ({
    type: LEGAL_FORMS_DICT_REQUEST,
    countryCode,
});

export const fetchLegalFormsDictBegin = (countryCode: CountryCodeT): FetchLegalFormsDictBeginActionT => ({
    type: LEGAL_FORMS_DICT_REQUEST_BEGIN,
    countryCode,
});

export const fetchLegalFormsDictSuccess = (
    countryCode: CountryCodeT,
    legalFormsList: LegalFormsListT,
): FetchLegalFormsDictSuccessActionT => ({
    type: LEGAL_FORMS_DICT_REQUEST_SUCCESS,
    countryCode,
    legalFormsList,
});

export const fetchLegalFormsDictError = (countryCode: CountryCodeT, error: Error): FetchLegalFormsDictErrorActionT => ({
    type: LEGAL_FORMS_DICT_REQUEST_ERROR,
    countryCode,
    error,
});
