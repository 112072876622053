import {
    CLOSE_ERROR_MODAL,
    OPEN_ERROR_MODAL,
    RESET_LOGS,
    CloseErrorModalActionT,
    OpenErrorModalActionT,
    ResetErrorLogsActionT,
} from './types';

export const closeErrorModal = (): CloseErrorModalActionT => ({
    type: CLOSE_ERROR_MODAL,
});

export const openErrorModal = (): OpenErrorModalActionT => ({
    type: OPEN_ERROR_MODAL,
});

export const resetErrorLogs = (): ResetErrorLogsActionT => ({
    type: RESET_LOGS,
});
