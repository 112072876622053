import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';

export const selectCreateCompanyRequest = (state: SharableStateT): RequestStatusT => {
    return state.createCompany.requestStatus;
};

export const selectCreatedCompanyIsLocked = (state: SharableStateT): boolean | null => {
    return state.createCompany.isLocked;
};
