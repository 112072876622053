import moment from 'moment';
import { FieldsEnum, FormValuesT } from './constants';
import { ApiContactFormalEnum, CountryCodeT } from 'common/utils/api/models';
import { PHONE_NUMBER_PREFIX, SocialMediaEnum } from 'common/constants';
import { CompanyDriverDetailsT } from 'common/store/drivers/models';
import isNumber from 'lodash/isNumber';

const getInitialValues = (driverDetails?: CompanyDriverDetailsT | null): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.id]: driverDetails?.id || null,
        [FieldsEnum.formal]: driverDetails?.contact?.formal as ApiContactFormalEnum,
        [FieldsEnum.firstName]: driverDetails?.contact?.name || '',
        [FieldsEnum.lastName]: driverDetails?.contact?.surname || '',

        [FieldsEnum.desktopPhone]: driverDetails?.contact?.deskPhone || PHONE_NUMBER_PREFIX,

        [FieldsEnum.address]: driverDetails?.homeAddress || '',

        [FieldsEnum.birthday]: null,
        [FieldsEnum.countryCode]: (driverDetails?.contact?.residence as CountryCodeT) || null,
        [FieldsEnum.language]: driverDetails?.contact?.language || null,

        [FieldsEnum.ratePerKm]: isNumber(driverDetails?.rate) ? String(driverDetails?.rate) : '',
    };

    driverDetails?.contact?.medias?.forEach((media) => {
        const { login, type } = media;

        values[type as SocialMediaEnum] = login;
    });

    if (driverDetails?.contact?.birthday) {
        values[FieldsEnum.birthday] = moment(driverDetails.contact.birthday, 'YYYY-MM-DD').toDate();
    }

    return values;
};

export default getInitialValues;
