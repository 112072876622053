import React from 'react';
import classNames from 'classnames/bind';

import styles from './SideBarParanja.scss';
import ContentRootContext from 'common/layouts/LeftMenuLayout/contexts/content-root-context';
import { createPortal } from 'react-dom';

const cx = classNames.bind(styles);

type PropsT = {};

const SideBarParanja: React.FC<PropsT> = React.memo((props) => {
    const contentRootNode = React.useContext(ContentRootContext);
    if (!contentRootNode) {
        return null;
    }

    return createPortal(<div className={cx('paranja')} />, contentRootNode);
});

export default SideBarParanja;
