import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const validate = (
    t: TFunction,
    values: FormValuesT,
    { isShowExpirationDate }: { isShowExpirationDate: boolean },
): FormErrorsT => {
    let errors: FormErrorsT = {};

    if (isShowExpirationDate) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.expiration], values),
        };
    }

    return errors;
};

export default validate;
