import { FetchTransportOrdersPageQueryT, TransportOrderT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_ORDERS_PAGE_REQUEST = 'common/transport-orders/FETCH_ORDERS_PAGE_REQUEST';
export const FETCH_ORDERS_PAGE_REQUEST_BEGIN = 'common/transport-orders/FETCH_ORDERS_PAGE_REQUEST_BEGIN';
export const FETCH_ORDERS_PAGE_REQUEST_ERROR = 'common/transport-orders/FETCH_ORDERS_PAGE_REQUEST_ERROR';
export const FETCH_ORDERS_PAGE_REQUEST_SUCCESS = 'common/transport-orders/FETCH_ORDERS_PAGE_REQUEST_SUCCESS';

export const RESET_ORDERS = 'common/transport-orders/RESET_ORDERS';

export type TransportOrdersStateT = Record<PartnerIdT, CompanyTransportOrdersStateT>;

export type CompanyTransportOrdersStateT = PaginationStateT<TransportOrderT, FetchTransportOrdersPageQueryT>;

export type FetchTransportOrdersPageActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchTransportOrdersPageQueryT;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchTransportOrdersPageBeginActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchTransportOrdersPageQueryT;
    companyId: CompanyIdT;
};

export type FetchTransportOrdersPageSuccessActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_SUCCESS;
    query: FetchTransportOrdersPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<TransportOrderT> | null;
    companyId: CompanyIdT;
};

export type FetchTransportOrdersPageErrorActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_ERROR;
    query: FetchTransportOrdersPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
    companyId: CompanyIdT;
};

export type ResetTransportOrdersActionT = {
    type: typeof RESET_ORDERS;
    companyId: CompanyIdT;
    mutationListOptions: MutationListOptionsT;
};

export type TransportOrdersActionT =
    | FetchTransportOrdersPageActionT
    | FetchTransportOrdersPageBeginActionT
    | FetchTransportOrdersPageSuccessActionT
    | FetchTransportOrdersPageErrorActionT
    | ResetTransportOrdersActionT;
