import { AnyAlert, AnyAlertPayloadT } from 'common/store/alerts/models';
import React from 'react';
import { AlertThemeEnum } from 'common/components/Alert/Alert';
import { TFunction } from 'i18next';
import { RolesEnum } from 'common/utils/api/models';

export type AlertToastViewT = {
    id: AlertIdT;
    theme: AlertThemeEnum;
    icon: React.ReactNode | null;
    text: React.ReactNode | null;
};

export type AlertToastRendererT<A = AnyAlert<AnyAlertPayloadT>> = (t: TFunction, alert: A) => AlertToastViewT | null;

export enum CommonAlertTypeEnum {
    networkError = 'network-error',

    userRestored = 'user-restored',
    inviteCanceled = 'invite-canceled',
    contactAdded = 'contact-added',
    emailInvited = 'email-invited',
    userRoleChanged = 'user-role-changed',
    userDeactivated = 'user-deactivated',
    contactDeleted = 'contact-deleted',
    mainContactChanged = 'main-contact-changed',
    contactEdited = 'contact-edited',
    userEdited = 'user-edited',

    contractUploaded = 'contract-uploaded',

    documentUploaded = 'document-uploaded',
    documentApproved = 'document-approved',
    documentRejected = 'document-rejected',
    documentRevoked = 'document-revoked',

    trailerAdded = 'trailer-added',
    trailerSuccessfullyActivated = 'trailer-successfully-activated',
    trailersSuccessfullyActivated = 'trailers-successfully-activated',
    trailersFailedActivate = 'trailers-failed-activate',
    trailerSuccessfullyArchived = 'trailer-successfully-archived',
    trailersSuccessfullyArchived = 'trailers-successfully-archived',
    trailersFailedArchived = 'trailers-failed-archived',
    trailerSuccessfullyEdited = 'trailer-successfully-edited',
    trailerSuccessfullySetUnavailable = 'trailer-successfully-set-unavailable',
    trailerNotEditable = 'trailer-not-editable',

    truckAdded = 'truck-added',
    truckSuccessfullyActivated = 'truck-successfully-activated',
    trucksSuccessfullyActivated = 'trucks-successfully-activated',
    trucksFailedActivate = 'trucks-failed-activate',
    truckSuccessfullyArchived = 'truck-successfully-archived',
    trucksSuccessfullyArchived = 'trucks-successfully-archived',
    trucksFailedArchived = 'trucks-failed-archived',
    truckSuccessfullyEdited = 'truck-successfully-edited',
    truckSuccessfullySetUnavailable = 'truck-successfully-set-unavailable',
    truckNotEditable = 'truck-not-editable',

    paperAdded = 'paper-added',
    paperDeleted = 'paper-deleted',

    driverAssigned = 'driver-assigned',
    driverUnassigned = 'driver-unassigned',

    rfqSuccessfulClosed = 'rfq-successful-closed',

    spotAssetsAssigned = 'spot-assets-assigned',
    spotAssetsUnassigned = 'spot-assets-unassigned',

    carrierSpotLanesUpdateSuccess = 'carrier-spot-lanes-update-success',
    carrierSpotLanesDeleteSuccess = 'carrier-spot-lanes-delete-success',

    shipperContractUploaded = 'shipper-contract-uploaded',
    shipperContractRevoked = 'shipper-contract-revoked',
    shipperContractUpdated = 'shipper-contract-updated',

    shipperContractLaneAdded = 'shipper-contract-lane-added',
    shipperContractLaneRevoked = 'shipper-contract-lane-revoked',
    shipperContractLaneUpdated = 'shipper-contract-lane-updated',
}

type CommonAnyAlertPayloadT =
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.userRestored,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.inviteCanceled,
          {
              email: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.contactAdded,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.emailInvited,
          {
              email: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.userRoleChanged,
          {
              fullName: string;
              newRole: RolesEnum;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.userDeactivated,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.contactDeleted,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.mainContactChanged,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.contactEdited,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.userEdited,
          {
              fullName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailerAdded,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailerSuccessfullyActivated,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailersSuccessfullyActivated,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailerSuccessfullyEdited,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailerSuccessfullySetUnavailable,
          {
              plateNumber: string;
              fromDate: string;
              toDate: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailerSuccessfullyArchived,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailersSuccessfullyArchived,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailersFailedActivate,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trailersFailedArchived,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<CommonAlertTypeEnum.trailerNotEditable, {}>
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.truckAdded,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.truckSuccessfullyActivated,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trucksSuccessfullyActivated,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.truckSuccessfullyEdited,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.truckSuccessfullySetUnavailable,
          {
              plateNumber: string;
              fromDate: string;
              toDate: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.truckSuccessfullyArchived,
          {
              plateNumber: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trucksSuccessfullyArchived,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trucksFailedActivate,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.trucksFailedArchived,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<CommonAlertTypeEnum.truckNotEditable, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.paperAdded, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.paperDeleted, {}>
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.driverAssigned,
          {
              driversCount: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.driverUnassigned,
          {
              driversCount: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.documentUploaded,
          {
              dictDocumentName: string;
          }
      >
    | AnyAlertPayloadT<CommonAlertTypeEnum.contractUploaded, {}>
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.documentApproved,
          {
              dictDocumentName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.documentRejected,
          {
              dictDocumentName: string;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.documentRevoked,
          {
              dictDocumentName: string;
          }
      >
    | AnyAlertPayloadT<CommonAlertTypeEnum.spotAssetsAssigned, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.spotAssetsUnassigned, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.rfqSuccessfulClosed, {}>
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.carrierSpotLanesDeleteSuccess,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<
          CommonAlertTypeEnum.carrierSpotLanesUpdateSuccess,
          {
              count: number;
          }
      >
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractUploaded, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractUpdated, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractRevoked, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractLaneAdded, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractLaneUpdated, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.shipperContractLaneRevoked, {}>
    | AnyAlertPayloadT<CommonAlertTypeEnum.networkError, {}>;

export class CommonAnyAlert extends AnyAlert<CommonAnyAlertPayloadT> {}
