import { DictDocumentT } from 'common/store/documents-dict/models';
import { DocumentIdsByTypeT, DocumentT } from 'common/store/documents/models';
import { DocumentRowT } from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/BaseDocumentsTable';
import forEach from 'lodash/forEach';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectDocumentsDict } from 'common/store/documents-dict/selectors';
import {
    selectCompanyDocumentIdsByType,
    selectCompanyDocumentsById,
    selectFetchCompanyDocumentsRequest,
} from 'common/store/documents/selectors';
import { DocumentRequiredEnum, PartnerTypeEnum } from 'common/utils/api/models';
import { useDocumentInfo } from './use-document-info';
import { isNonNil } from 'common/utils';

const useDocumentRows = (
    dictDocuments: Array<DictDocumentT>,
    documentIdsByType: DocumentIdsByTypeT | null,
    documentsById: Record<DocumentIdT, DocumentT> | null,
): Array<DocumentRowT> => {
    return dictDocuments
        .map((dictDocument) => {
            return useDocumentInfo(dictDocument, documentIdsByType, documentsById);
        })
        .filter(isNonNil);
};

const useDocumentsRows = (partnerId: PartnerIdT, partnerType: PartnerTypeEnum) => {
    const documentsDict = useSelector(selectDocumentsDict(partnerType));

    const documentIdsByType = useSelector(selectCompanyDocumentIdsByType(partnerId));
    const documentsById = useSelector(selectCompanyDocumentsById(partnerId)) || {};
    const fetchDocumentsRequest = useSelector(selectFetchCompanyDocumentsRequest(partnerId)) || {};

    return React.useMemo(() => {
        if (!fetchDocumentsRequest.ok && !fetchDocumentsRequest.error) {
            return {
                requiredDocumentRows: [],
                optionalDocumentRows: [],
            };
        }

        const requiredDictDocuments: Array<DictDocumentT> = [];
        const optionalDictDocuments: Array<DictDocumentT> = [];

        forEach(documentsDict, (item) => {
            if (item?.required === DocumentRequiredEnum.must) {
                requiredDictDocuments.push(item);
            }

            if (item?.required === DocumentRequiredEnum.optional) {
                optionalDictDocuments.push(item);
            }
        });

        const requiredDocumentRows = useDocumentRows(requiredDictDocuments, documentIdsByType, documentsById);
        const optionalDocumentRows = useDocumentRows(optionalDictDocuments, documentIdsByType, documentsById);

        return {
            requiredDocumentRows,
            optionalDocumentRows,
        };
    }, [documentsDict, documentIdsByType, documentsById, fetchDocumentsRequest]);
};

export { useDocumentsRows };
