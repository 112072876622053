import * as React from 'react';
import { Dispatch } from 'react';

import classNames from 'classnames/bind';
import styles from './NotificationToastsManager.scss';
import NotificationToastContent from '../NotificationToastContent/NotificationToastContent';
import { connect } from 'react-redux';
import { selectNewNotifications } from 'common/store/notifications/selectors';
import { fetchUnreadNotifications, markAsOld, markAsRead } from 'common/store/notifications/actions';
import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import { getUnknownNotificationToast, renderCommonNotificationToast } from './utils';
import { AnyNotificationT, NotificationToastViewT } from 'common/store/notifications/models';
import { logWarning } from 'common/utils/logger';
import { NotificationLinkFactoryT } from 'common/components/notifications/NotificationsBarContent/models';
import history from 'common/utils/history';
import Toast from 'common/components/toasts/Toast/Toast';

const cx = classNames.bind(styles);

type StatePropsT = {
    newNotifications: ReturnType<typeof selectNewNotifications>;
};

type DispatchPropsT = {
    dispatch: Dispatch<any>;
};

type OwnPropsT = {
    className?: string;
    renderNotificationToast: NotificationToastRendererT;
    getNotificationLink: NotificationLinkFactoryT;
};

type PropsT = StatePropsT & DispatchPropsT & OwnPropsT;

class NotificationToastsManager extends React.PureComponent<PropsT> {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchUnreadNotifications());
    }

    handleMarkAsOld = (notification: AnyNotificationT) => {
        const { dispatch } = this.props;

        const { id } = notification;
        if (!id) {
            logWarning('empty notification id');
            return;
        }

        dispatch(markAsOld([id]));
    };

    handleMarkAsRead = (notification: AnyNotificationT) => {
        const { dispatch } = this.props;

        const { id, readAlready } = notification;
        if (!id) {
            logWarning('empty notification id');
            return;
        }

        if (readAlready) {
            return;
        }

        dispatch(markAsRead([id]));
    };

    render() {
        const { className, newNotifications, renderNotificationToast, getNotificationLink } = this.props;

        return (
            <div
                className={className}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                {newNotifications.map((notification, notificationIndex): React.ReactNode => {
                    if (!notification.isNew) {
                        return null;
                    }

                    const notificationToastView: NotificationToastViewT =
                        renderCommonNotificationToast(notification) ||
                        renderNotificationToast(notification) ||
                        getUnknownNotificationToast(notification);

                    const key = notification.id || notificationIndex;

                    return (
                        <Toast
                            key={key}
                            className={cx('notification')}
                            onClose={() => {
                                this.handleMarkAsOld(notification);
                            }}
                        >
                            {(onClose) => (
                                <NotificationToastContent
                                    key={key}
                                    {...notificationToastView}
                                    onClick={() => {
                                        this.handleMarkAsRead(notification);

                                        const link: ReturnType<NotificationLinkFactoryT> =
                                            getNotificationLink(notification) || null;

                                        if (link) {
                                            history.push(link);
                                        }
                                    }}
                                    onClose={onClose}
                                />
                            )}
                        </Toast>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps =
    () =>
    (state: any): StatePropsT => ({
        newNotifications: selectNewNotifications(state),
    });

export default connect<StatePropsT, DispatchPropsT, OwnPropsT>(mapStateToProps)(NotificationToastsManager);
