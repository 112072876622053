import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './Money.scss';

import { CURRENCY_SETTINGS, CurrencyEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    amount: number | null | undefined;
    currency: CurrencyEnum;
    isRound?: boolean;
    className?: string;
};

const DEFAULT_SEPARATOR = '.';

export const formatDecimal = (sourceDecimalString: string, thousandSeparator: string): string => {
    const parts =
        sourceDecimalString
            .split('')
            .reverse()
            .join('')
            .match(/.{1,3}/g) || [];

    return parts
        .join(thousandSeparator || ' ')
        .split('')
        .reverse()
        .join('');
};

export const formatMoney = ({
    currency,
    amount,
    isRound,
}: {
    amount: number;
    currency: CurrencyEnum;
    isRound?: boolean;
}): string => {
    const settings = CURRENCY_SETTINGS[currency];

    const roundedAmount = amount.toFixed(isRound ? 0 : settings.minimumSignificantDigits);
    const [decimal, fraction] = roundedAmount.split(DEFAULT_SEPARATOR);

    const formattedDecimal = formatDecimal(decimal, settings.thousandSeparator);

    return [
        settings.isFirstSymbol ? settings.symbol : '',
        formattedDecimal,
        isRound ? '' : settings.decimalSeparator,
        fraction,
        !settings.isFirstSymbol ? ` ${settings.symbol}` : '',
    ].join('');
};

const Money: React.FC<PropsT> = React.memo((props) => {
    const { amount, currency, isRound, className } = props;

    if (amount === null || amount === undefined) {
        return null;
    }

    const formattedMoney = formatMoney({
        amount,
        currency,
        isRound,
    });

    return <span className={cs(className, cx('money'))}>{formattedMoney}</span>;
});

export default Money;
