import * as React from 'react';
import {
    selectTrailersDict,
    selectTrailersDictById,
    selectTrailersDictRequest,
} from 'common/store/trailers-dict/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';

const useTrailersDict = () => {
    const dispatch = useDispatch();

    const trailersDictRequest = useSelector(selectTrailersDictRequest);
    const trailersDict = useSelector(selectTrailersDict);
    const trailersDictById = useSelector(selectTrailersDictById);

    React.useEffect(() => {
        if (!trailersDict) {
            dispatch(fetchTrailersDict());
        }
    }, []);

    return {
        trailersDict,
        trailersDictById,
        trailersDictRequest,
    };
};

export { useTrailersDict };
