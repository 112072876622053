import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
};

const EuroSymbolIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M11.8 14.5c-1.467 0-2.754-.711-3.582-1.8H11.8l.9-1.8H7.39a4.921 4.921 0 01-.09-.9c0-.306.036-.612.09-.9h4.41l.9-1.8H8.218c.828-1.089 2.115-1.8 3.582-1.8a4.52 4.52 0 013.186 1.314l1.269-1.269A6.278 6.278 0 0011.8 3.7c-2.511 0-4.671 1.476-5.688 3.6H3.7l-.9 1.8h2.772a5.97 5.97 0 00-.072.9c0 .306.027.603.072.9H3.7l-.9 1.8h3.312a6.299 6.299 0 0010.143 1.755l-1.269-1.269A4.52 4.52 0 0111.8 14.5z"
                />
            </g>
        </svg>
    );
};

EuroSymbolIcon.displayName = 'EuroSymbolIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default EuroSymbolIcon;
