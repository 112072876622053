import React from 'react';
import CommonSideBars from 'common/layouts/SideBars/SideBars';
import { getSidebarCloseConfirmation, renderSidebarContent } from './utils';

type PropsT = {
    paranjaShowLevel?: number;
};

const SideBars: React.FC<PropsT> = React.memo((props) => {
    return (
        <CommonSideBars
            {...props}
            renderSidebarContent={renderSidebarContent}
            getSidebarCloseConfirmation={getSidebarCloseConfirmation}
        />
    );
});

export default SideBars;
