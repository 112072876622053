import * as React from 'react';
import { useState } from 'react';

import AuthLayout from 'common/layouts/AuthLayout/AuthLayout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from './SelfCreateCompanyPage.scss';
import PageTitle from 'common/components/PageTitle/PageTitle';
import CreateCompanyForm from 'common/layouts/SelfCreateCompanyPage/CreateCompanyForm/CreateCompanyForm';
import { ApiRegistrationCompanyRequestDraftT, PartnerTypeEnum } from 'common/utils/api/models';
import { createCompanyRequest } from 'common/store/create-company/slice';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { selectCreateCompanyRequest, selectCreatedCompanyIsLocked } from 'common/store/create-company/selectors';
import { QueryKeysEnum } from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import AuthLayoutMessage, { AuthMessageIconsEnum } from 'common/layouts/AuthLayout/AuthLayoutMessage/AuthLayoutMessage';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import { selectCountriesDictRequest } from 'common/store/countries-dict/selectors';
import history from 'common/utils/history';
import { parseAuthReturnUrl } from 'common/utils/auth-return-url';
import { selectUser } from 'common/store/auth/selectors';

const cx = classNames.bind(styles);

type QueryT = {
    [QueryKeysEnum.returnUrl]: string;
};

type PropsT = {};

const SelfCreateCompanyPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { partnerType } = usePartnerContext();

    const query = useQuery<QueryT>();

    const returnUrl = query[QueryKeysEnum.returnUrl] || null;

    const [isShowLockedMessage, setIsShowLockedMessage] = useState(false);

    const user = useSelector(selectUser);

    const countriesDictRequest = useSelector(selectCountriesDictRequest);
    const requestStatus = useSelector(selectCreateCompanyRequest);
    const isLocked = useSelector(selectCreatedCompanyIsLocked);

    useOnlyChangesEffect(() => {
        if (isLocked) {
            setIsShowLockedMessage(isLocked);
        }
    }, [isLocked]);

    useOnlyChangesEffect(() => {
        if (countriesDictRequest.error) {
            history.push(parseAuthReturnUrl(returnUrl));
        }
    }, [countriesDictRequest.error]);

    useOnlyChangesEffect(() => {
        if (user) {
            history.push(parseAuthReturnUrl(returnUrl));
        }
    }, [user]);

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const handleSubmit = (registrationCompanyRequestDraft: ApiRegistrationCompanyRequestDraftT): void => {
        dispatch(createCompanyRequest({ registrationCompanyRequestDraft, returnUrl }));
    };

    if (isShowLockedMessage) {
        return (
            <AuthLayout testSelector="created-company-is-locked">
                <AuthLayoutMessage
                    iconType={AuthMessageIconsEnum.success}
                    title={t('common:sign-up.success-message.title')}
                    description={t('common:sign-up.success-message.description')}
                    testSelector="success"
                />
            </AuthLayout>
        );
    }

    const isBroker = partnerType === PartnerTypeEnum.broker;
    if (isBroker) {
        return (
            <AuthLayout testSelector="continue-sign-up-page">
                <AuthLayoutMessage
                    iconType={AuthMessageIconsEnum.warning}
                    title={t('common:self-create-company.messages.blocked-for-broker')}
                    testSelector="broker-create-company-forbidden"
                />
            </AuthLayout>
        );
    }

    return (
        <>
            <AuthLayout title={t('common:continue-sign-up.title')} testSelector="continue-sign-up-page">
                <PageTitle title={t('common:page-titles.continue-sign-up')} />
                <CreateCompanyForm onSubmit={handleSubmit} requestStatus={requestStatus} />
            </AuthLayout>
        </>
    );
});

export default SelfCreateCompanyPage;
