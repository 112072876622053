import { RFQsStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_RFQS_STATS_REQUEST = 'common/rfqs-stats/FETCH_RFQS_STATS_REQUEST';
export const FETCH_RFQS_STATS_REQUEST_BEGIN = 'common/rfqs-stats/FETCH_RFQS_STATS_REQUEST_BEGIN';
export const FETCH_RFQS_STATS_REQUEST_ERROR = 'common/rfqs-stats/FETCH_RFQS_STATS_REQUEST_ERROR';
export const FETCH_RFQS_STATS_REQUEST_SUCCESS = 'common/rfqs-stats/FETCH_RFQS_STATS_REQUEST_SUCCESS';

export type RFQsStatsStateT = {
    stats: RFQsStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchRFQsStatsActionT = {
    type: typeof FETCH_RFQS_STATS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchRFQsStatsBeginActionT = {
    type: typeof FETCH_RFQS_STATS_REQUEST_BEGIN;
};

export type FetchRFQsStatsSuccessActionT = {
    type: typeof FETCH_RFQS_STATS_REQUEST_SUCCESS;
    statistics: RFQsStatsT | null;
};

export type FetchRFQsStatsErrorActionT = {
    type: typeof FETCH_RFQS_STATS_REQUEST_ERROR;
    error: Error;
};

export type RFQsStatsActionT =
    | FetchRFQsStatsActionT
    | FetchRFQsStatsBeginActionT
    | FetchRFQsStatsSuccessActionT
    | FetchRFQsStatsErrorActionT;
