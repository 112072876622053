import { put, select } from 'redux-saga/effects';
import { fetchTrailersDictBegin, fetchTrailersDictError, fetchTrailersDictSuccess } from './actions';
import { FetchTrailersDictActionT, TRAILERS_DICT_REQUEST } from './types';
import { selectTrailersDict } from './selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { semaphoreSaga } from 'common/utils/semaphore-saga';

function* fetchTrailersDictSaga(action: FetchTrailersDictActionT): WrapGeneratorT<void> {
    const currentTrailersDict: ReturnType<typeof selectTrailersDict> = yield select(selectTrailersDict);
    if (currentTrailersDict) {
        return;
    }

    yield put(fetchTrailersDictBegin());

    const [error, trailersDict]: ReturnApiT<typeof commonTranziitApi.getTrailersDict> =
        yield commonTranziitApi.getTrailersDict();
    if (error) {
        yield put(fetchTrailersDictError(error));
    } else {
        yield put(fetchTrailersDictSuccess(trailersDict || {}));
    }
}

function* trailersSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(TRAILERS_DICT_REQUEST, fetchTrailersDictSaga);
}

export default trailersSaga;
