import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const LinkIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M15.25 15.25H4.75V4.75H10v-1.5H4.75a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V10h-1.5v5.25zm-3.75-12v1.5h2.693L6.82 12.123l1.058 1.057 7.372-7.372V8.5h1.5V3.25H11.5z"
                />
            </g>
        </svg>
    );
};

LinkIcon.displayName = 'LinkIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default LinkIcon;
