export enum QueryFiltersKeysEnum {
    plateNumber = 'plateNumber',

    dictTrailerTypeId = 'dictTrailerTypeId',
    status = 'status',

    link = 'link',
    GPSTracker = 'gps-tracker',
    dedication = 'dedication',

    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',
    createdByUserId = 'createdByUserId',
    createdByUserName = 'createdByUserName',

    lastModifyDateFrom = 'lastModifyDateFrom',
    lastModifyDateTo = 'lastModifyDateTo',
    lastModifyByUserId = 'lastModifyByUserId',
    lastModifyByUserName = 'lastModifyByUserName',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dictTrailerTypeId]: QueryParam.StringT;

    [QueryFiltersKeysEnum.plateNumber]: QueryParam.StringT;
    [QueryFiltersKeysEnum.status]: QueryParam.StringT;

    [QueryFiltersKeysEnum.link]: QueryParam.StringT;
    [QueryFiltersKeysEnum.GPSTracker]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dedication]: QueryParam.StringT;

    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByUserId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByUserName]: QueryParam.StringT;

    [QueryFiltersKeysEnum.lastModifyDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyByUserId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyByUserName]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    trailersPage = 'trailers-page',
    trailersSort = 'trailers-sort',
    trailersFilters = 'trailers-filters',
    trailersTab = 'trailers-tab',
}
