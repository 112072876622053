import { RequestStatusT } from 'common/utils/request-status';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';

type DialogT = {
    isShow: boolean;
    onClose: () => void;
};

const useCloseModalDialogAfterRequest = (requestStatus: RequestStatusT, dialogs: Array<DialogT>) => {
    useOnlyChangesEffect(() => {
        const isShow = dialogs.some((dialog) => dialog.isShow);
        if (!isShow) {
            return;
        }

        if (requestStatus.ok) {
            dialogs.forEach((dialog) => {
                dialog.onClose();
            });
        }
    }, [requestStatus]);
};

export default useCloseModalDialogAfterRequest;
