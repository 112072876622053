import React from 'react';

type LibrariesT = {
    geometry: GeometryLibraryT | null;
};

export type GoogleMapsContextT = {
    map: MapT | null;
    maps: MapsT | null;
    libraries: LibrariesT;
    set: (maps: MapsT, map: MapT, libraryNames: Array<keyof LibrariesT>) => void;
};

const useGoogleMaps = (): GoogleMapsContextT => {
    const [map, setMap] = React.useState<MapT | null>(null);
    const [maps, setMaps] = React.useState<MapsT | null>(null);
    const [libraries, setLibraries] = React.useState<LibrariesT>({
        geometry: null,
    });

    return {
        map,
        maps,
        libraries,
        set: async <T extends keyof LibrariesT>(maps: MapsT, map: MapT, libraryNames: Array<T>): Promise<void> => {
            setMaps(maps);
            setMap(map);

            for (const libraryName of libraryNames) {
                // @ts-expect-error wrong types
                const library: LibrariesT[T] = await maps?.importLibrary(libraryName);

                setLibraries((libraries) => {
                    return {
                        ...libraries,
                        [libraryName]: library,
                    };
                });
            }
        },
    };
};

export default useGoogleMaps;
