import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CountryCodeT } from 'common/store/countries-dict/models';
import { changeReserveQuery } from 'common/store/order-creation/actions';

import ExcludeCountriesMapWidget from 'common/components/ExcludeCountriesMapWidget/ExcludeCountriesMapWidget';
import { SyncShipmentDetailsFormValuesContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-form-values';
import { FieldsEnum } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/constants';

type PropsT = {
    className?: string;
};

const ExcludeCountries: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const { t } = useTranslation();

    const syncShipmentDetailsFormValuesContext = React.useContext(SyncShipmentDetailsFormValuesContext);
    const excludedCountriesFormValue =
        syncShipmentDetailsFormValuesContext?.formValues?.[FieldsEnum.excludedCountries] || [];

    const dispatch = useDispatch();

    const handleSetExcludedCountries = (excludedCountries: Array<CountryCodeT>): void => {
        if (syncShipmentDetailsFormValuesContext?.setFieldValueCallback) {
            syncShipmentDetailsFormValuesContext.setFieldValueCallback(FieldsEnum.excludedCountries, excludedCountries);
        }

        dispatch(
            changeReserveQuery({
                excludedCountries,
            }),
        );
    };

    return (
        <ExcludeCountriesMapWidget
            className={className}
            excludedCountries={excludedCountriesFormValue}
            setExcludedCountries={handleSetExcludedCountries}
            triggerLabel={t('common:new-order-form.excluded-countries.trigger')}
            inputPlaceholder={t('common:new-order-form.excluded-countries.search-input-placeholder')}
        />
    );
});

export default ExcludeCountries;
