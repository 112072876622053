import { useMemo } from 'react';
import find from 'lodash/find';
import { useSelector } from 'react-redux';
import { selectReserve } from 'common/store/order-creation/selectors';
import { ApiReserveT } from 'common/store/order-creation/models';

export const useSelectedLane = (
    selectedLaneId: ShipperContractLaneIdT | null,
): ApiReserveT['contractLanes'][number] | null => {
    const reserve = useSelector(selectReserve);

    const selectedLane = useMemo(() => {
        if (!selectedLaneId) {
            return null;
        }

        return (
            find(reserve?.contractLanes, (lane) => {
                return lane.id === selectedLaneId;
            }) || null
        );
    }, [reserve, selectedLaneId]);

    return selectedLane;
};
