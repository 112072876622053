import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FieldNotes.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    error?: React.ReactNode;
    warning?: React.ReactNode;
    success?: React.ReactNode;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
};

const FieldNotes: React.FC<PropsT> = React.memo((props) => {
    const { className, error, warning, success, hasError, hasWarning, hasSuccess } = props;

    const noteNodes: Array<React.ReactNode> = [];

    if (hasError) {
        noteNodes.push(
            <div key="error" className={cs(cx('note', 'note--error'), className)} data-error-message={error}>
                {error}
            </div>,
        );
    }

    if (hasWarning) {
        noteNodes.push(
            <div key="warning" className={cs(cx('note', 'note--warning'), className)}>
                {warning}
            </div>,
        );
    }

    if (hasSuccess) {
        noteNodes.push(
            <div key="success" className={cs(cx('note', 'note--success'), className)}>
                {success}
            </div>,
        );
    }

    const emptyNoteNode = <div key="empty" className={cs(cx('note'), className)} />;

    return <>{noteNodes.length ? noteNodes : emptyNoteNode}</>;
});

export default FieldNotes;
