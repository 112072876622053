import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './EditContactDetailsSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, MEDIA_FIELDS_NAME } from './constants';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { ContactPositionsEnum } from 'common/utils/api/models';
import { uppercaseFirstCharacterFormatter } from 'common/utils/form-formatters';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import prepareApiContact from './prepare-api-contact';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import LanguageSelector from 'common/components/LanguageSelector/LanguageSelector';
import { CompanyContactDetailsT } from 'common/store/members/models';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { AUTO_COMPLETE_OFF_FIX, SocialMediaEnum, StyleGuideColorsEnum } from 'common/constants';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import FieldValue from 'common/components/FieldValue/FieldValue';
import { ICON_BY_MEDIA } from 'common/layouts/Members/media-icons';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import moment from 'moment';
import UserFormalDropdown from 'common/components/dropdowns/UserFormalDropdown/UserFormalDropdown';
import { logWarning } from 'common/utils/logger';
import PositionOption from 'common/layouts/Members/PositionOption/PositionOption';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAddMemberRequest,
    selectContactDetailsById,
    selectFetchContactDetailsRequest,
    selectUpdateMemberRequest,
} from 'common/store/members/selectors';
import AddOtherMessengerDropdown from './AddOtherMessengerDropdown/AddOtherMessengerDropdown';
import { addContact, fetchContactDetails, updateContact } from 'common/store/members/actions';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { EditContactDetailsSidebarDataT } from './models';
import noop from 'lodash/noop';
import ContactSidebarHeader from 'common/layouts/Members/ContactSidebarHeader/ContactSidebarHeader';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';

const TODAY = moment().format();

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<EditContactDetailsSidebarDataT, CommonSidebarDataT>;

const EditContactDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, setNeedCloseConfirmation } = props;

    const { partnerId } = data;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const contactDetailsRequestStatus = useSelector(selectFetchContactDetailsRequest(partnerId));
    const isLoading = contactDetailsRequestStatus.loading;

    const contactDetailsById = useSelector(selectContactDetailsById(partnerId));

    const isNewContact = 'isNewContact' in data && data.isNewContact;

    const contactDetails: CompanyContactDetailsT | null = useMemo(() => {
        if ('contactId' in data) {
            return contactDetailsById[data.contactId] || null;
        }

        if (isNewContact) {
            return null;
        }

        return null;
    }, [data, contactDetailsById, isNewContact]);

    const addMemberRequestStatus = useSelector(selectAddMemberRequest(partnerId));
    const updateMemberRequestStatus = useSelector(selectUpdateMemberRequest(partnerId));

    const updateRequestStatus = useMemo(() => {
        if ('contactId' in data) {
            return updateMemberRequestStatus;
        }

        if (isNewContact) {
            return addMemberRequestStatus;
        }

        return null;
    }, [data, updateMemberRequestStatus, addMemberRequestStatus, isNewContact]);

    useCloseSidebarAfterRequest(updateRequestStatus, onGoBack || onClose);

    React.useEffect(() => {
        if ('contactId' in data) {
            dispatch(fetchContactDetails(data.contactId, partnerId));
        }
    }, []);

    const hasUser = !!contactDetails?.userId;
    const isAllowEditEmail = isNewContact || !hasUser;

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(contactDetails);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [contactDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const apiContact = prepareApiContact(values);

            if (isNewContact) {
                dispatch(addContact(apiContact, partnerId));
            } else if ('contactId' in data) {
                dispatch(updateContact(data.contactId, apiContact, partnerId));
            } else {
                logWarning('failed submit edit contact form, empty contact id or not new contact');
            }

            formikHelpers.setTouched({});
        },
    });

    const handleSelectPosition = (value: ContactPositionsEnum): void => {
        formik.setFieldValue(FieldsEnum.position, value);
    };

    const handleSelectLanguage = (langCode: string | undefined | null): void => {
        if (!langCode) {
            return;
        }

        formik.setFieldValue(FieldsEnum.language, langCode);
    };

    const [lastAddedMediaField, setLastAddedMediaField] = React.useState<SocialMediaEnum | null>(null);

    const renderRadioButton = (position: ContactPositionsEnum) => {
        return (
            <PositionOption
                className={cx('radio')}
                value={position}
                selectedValue={formik.values[FieldsEnum.position]}
                onChange={handleSelectPosition}
            />
        );
    };

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    useEffect(() => {
        setNeedCloseConfirmation(hasAnyFieldValueChanges);
    }, [hasAnyFieldValueChanges]);

    const isDisabledSubmit = (!isNewContact && !hasAnyFieldValueChanges) || updateRequestStatus?.loading;

    const hasChangeHighlight = !isNewContact && formik.values[FieldsEnum.id] === contactDetails?.id;

    const isShowNotSaveStatus = !isNewContact && hasAnyFieldValueChanges && hasChangeHighlight;

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            {isLoading && <LoaderOverlay />}
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    onGoBack={onGoBack ? () => onGoBack({ needCloseConfirmation: false }) : null}
                    title={
                        isNewContact ? (
                            t('common:team-members.edit-contact.title')
                        ) : (
                            <ContactSidebarHeader contactDetails={contactDetails} />
                        )
                    }
                    rightNode={
                        isShowNotSaveStatus && (
                            <ColoredStatusLabel
                                label={t('common:team-members.edit-contact.status.not-saved')}
                                color={StyleGuideColorsEnum.orange}
                            />
                        )
                    }
                    onClose={onClose ? () => onClose({ needCloseConfirmation: false }) : null}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('common:team-members.edit-contact.sections.name')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--formal')}
                                name={FieldsEnum.formal}
                                error={formik.errors[FieldsEnum.formal]}
                                meta={formik.getFieldMeta(FieldsEnum.formal)}
                                label={t('common:team-members.edit-contact.fields.formal.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <UserFormalDropdown
                                        value={formik.values[FieldsEnum.formal]}
                                        onChange={props.onChange}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        placeholder={t('common:team-members.edit-contact.fields.formal.placeholder')}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--first-name')}
                                name={FieldsEnum.firstName}
                                error={formik.errors[FieldsEnum.firstName]}
                                meta={formik.getFieldMeta(FieldsEnum.firstName)}
                                label={t('common:team-members.edit-contact.fields.first-name.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.firstName}
                                        value={formik.values[FieldsEnum.firstName]}
                                        formatter={uppercaseFirstCharacterFormatter}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--last-name')}
                                name={FieldsEnum.lastName}
                                error={formik.errors[FieldsEnum.lastName]}
                                meta={formik.getFieldMeta(FieldsEnum.lastName)}
                                label={t('common:team-members.edit-contact.fields.last-name.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.lastName}
                                        value={formik.values[FieldsEnum.lastName]}
                                        formatter={uppercaseFirstCharacterFormatter}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>
                        {t('common:team-members.edit-contact.sections.position')}
                    </div>
                    <div className={cx('section__block')}>
                        <div className={cx('fields-group')}>
                            <div className={cx('fields-group__field', 'fields-group__field--two')}>
                                {renderRadioButton(ContactPositionsEnum.operator)}
                                {renderRadioButton(ContactPositionsEnum.sales)}
                                {renderRadioButton(ContactPositionsEnum.it)}
                            </div>
                            <div className={cx('fields-group__field', 'fields-group__field--two')}>
                                {renderRadioButton(ContactPositionsEnum.management)}
                                {renderRadioButton(ContactPositionsEnum.owner)}
                                <FormikField
                                    name={FieldsEnum.isDefault}
                                    error={formik.errors[FieldsEnum.isDefault]}
                                    meta={formik.getFieldMeta(FieldsEnum.isDefault)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    withoutLabel
                                >
                                    {(props) => (
                                        <Checkbox
                                            label={t('common:team-members.edit-contact.fields.default.label')}
                                            checked={!!formik.values[FieldsEnum.isDefault]}
                                            onChange={() => {
                                                props.onChange(!formik.values[FieldsEnum.isDefault]);
                                            }}
                                        />
                                    )}
                                </FormikField>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>
                        {t('common:team-members.edit-contact.sections.contacts')}
                    </div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            {isAllowEditEmail ? (
                                <FormikField
                                    className={cx('field--email')}
                                    name={FieldsEnum.email}
                                    error={formik.errors[FieldsEnum.email]}
                                    meta={formik.getFieldMeta(FieldsEnum.email)}
                                    label={t('common:team-members.edit-contact.fields.email.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.email}
                                            value={formik.values[FieldsEnum.email]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={hasChangeHighlight && props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            ) : (
                                <FieldValue
                                    className={cx('field--email')}
                                    label={t('common:team-members.edit-contact.fields.email.label')}
                                    value={formik.values[FieldsEnum.email]}
                                />
                            )}
                            <FormikField
                                className={cx('field--mobile-phone')}
                                name={FieldsEnum.mobilePhone}
                                error={formik.errors[FieldsEnum.mobilePhone]}
                                meta={formik.getFieldMeta(FieldsEnum.mobilePhone)}
                                label={t('common:team-members.edit-contact.fields.mobile-phone.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <PhoneNumberInput
                                        name={FieldsEnum.mobilePhone}
                                        value={formik.values[FieldsEnum.mobilePhone]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--desktop-phone')}
                                name={FieldsEnum.desktopPhone}
                                error={formik.errors[FieldsEnum.desktopPhone]}
                                meta={formik.getFieldMeta(FieldsEnum.desktopPhone)}
                                label={t('common:team-members.edit-contact.fields.desktop-phone.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <PhoneNumberInput
                                        name={FieldsEnum.desktopPhone}
                                        value={formik.values[FieldsEnum.desktopPhone]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--fax-number')}
                                name={FieldsEnum.faxNumber}
                                error={formik.errors[FieldsEnum.faxNumber]}
                                meta={formik.getFieldMeta(FieldsEnum.faxNumber)}
                                label={t('common:team-members.edit-contact.fields.fax-number.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.faxNumber}
                                        value={formik.values[FieldsEnum.faxNumber]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            name={FieldsEnum.webpageLink}
                            error={formik.errors[FieldsEnum.webpageLink]}
                            meta={formik.getFieldMeta(FieldsEnum.webpageLink)}
                            label={t('common:team-members.edit-contact.fields.webpage-link.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.webpageLink}
                                    value={formik.values[FieldsEnum.webpageLink]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={hasChangeHighlight && props.hasChanges}
                                />
                            )}
                        </FormikField>
                        {MEDIA_FIELDS_NAME.map((media) => {
                            const mediaValue = formik.values[media];

                            if (typeof mediaValue !== 'string') {
                                return null;
                            }

                            return (
                                <FormikField
                                    key={media}
                                    name={media}
                                    error={formik.errors[media]}
                                    meta={formik.getFieldMeta(media)}
                                    label={t(`common:team-members.edit-contact.fields.${media}.label`)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <div className={cx('media')}>
                                            <Input
                                                name={media}
                                                className={cx('media__input')}
                                                value={mediaValue}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                renderLeftIcon={() => ICON_BY_MEDIA[media]}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                hasChanges={hasChangeHighlight && props.hasChanges}
                                                autoFocus={lastAddedMediaField === media}
                                            />
                                            <TransparentTrigger
                                                className={cx('media__icon')}
                                                onClick={() => {
                                                    formik.setFieldValue(media, undefined);
                                                }}
                                                leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                                reflectionTheme={ReflectionThemeEnum.light}
                                            />
                                        </div>
                                    )}
                                </FormikField>
                            );
                        })}
                        <AddOtherMessengerDropdown
                            formValues={formik.values}
                            onCreateField={(socialMedia) => {
                                setLastAddedMediaField(socialMedia);
                                formik.setFieldValue(socialMedia, '');
                            }}
                        />
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>
                        {t('common:team-members.edit-contact.sections.personals')}
                    </div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--birthday')}
                                name={FieldsEnum.birthday}
                                error={formik.errors[FieldsEnum.birthday]}
                                meta={formik.getFieldMeta(FieldsEnum.birthday)}
                                label={t('common:team-members.edit-contact.fields.birthday.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DatePicker
                                        {...datePicketRangePresets.birthday}
                                        hasYearMonthForm
                                        name={FieldsEnum.birthday}
                                        value={formik.values[FieldsEnum.birthday]}
                                        initialValue={initialValues[FieldsEnum.birthday]}
                                        placeholder={t('common:team-members.edit-contact.fields.birthday.placeholder')}
                                        onChange={props.onChange}
                                        overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        autoComplete={AUTO_COMPLETE_OFF_FIX}
                                        hasClearControl
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--country')}
                                name={FieldsEnum.countryCode}
                                error={formik.errors[FieldsEnum.countryCode]}
                                label={t('common:team-members.edit-contact.fields.residence.label')}
                                meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <CountryDropdown
                                        value={formik.values[FieldsEnum.countryCode]}
                                        onChange={(countryCode) => {
                                            props.onChange(countryCode);
                                        }}
                                        overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--language')}
                                name={FieldsEnum.language}
                                error={formik.errors[FieldsEnum.language]}
                                label={t('common:team-members.edit-contact.fields.language.label')}
                                meta={formik.getFieldMeta(FieldsEnum.language)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <LanguageSelector
                                        value={formik.values[FieldsEnum.language]}
                                        onChange={handleSelectLanguage}
                                        overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={hasChangeHighlight && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FieldGroupEmptyItem className={cx('field--language')} />
                        </FieldGroup>
                    </div>
                </div>
            </SideBarLayout>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout hasPaddings>
                <div className={cx('actions')}>
                    {!isNewContact && (
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            onClick={onGoBack ? () => onGoBack({ needCloseConfirmation: false }) : noop}
                            className={cx('actions__action', 'actions__action--isDiscard')}
                        >
                            {t('common:team-members.edit-contact.discard-changes')}
                        </Button>
                    )}
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isDisabled={isDisabledSubmit}
                        isLoading={updateRequestStatus?.loading}
                        className={cx('actions__action')}
                        type="submit"
                    >
                        {isNewContact
                            ? t('common:team-members.edit-contact.add-contact')
                            : t('common:team-members.edit-contact.update-contact')}
                    </Button>
                </div>
            </FooterSideBarLayout>
        </form>
    );
};

export default EditContactDetailsSidebarContent;
