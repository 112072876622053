import * as React from 'react';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiShipmentWarningT, ShipmentWarningEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    warning: ApiShipmentWarningT | null | undefined;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_WARNING: Record<ApiShipmentWarningT, PillLabelThemeEnum> = {
    [ShipmentWarningEnum.noShow]: PillLabelThemeEnum.tomatoRed,
    [ShipmentWarningEnum.missed]: PillLabelThemeEnum.tomatoRed,
};

export const WARNING_T_MAP: Record<ApiShipmentWarningT, string> = {
    [ShipmentWarningEnum.noShow]: 'common:shipment-warnings.no-show',
    [ShipmentWarningEnum.missed]: 'common:shipment-warnings.missed',
};

const ShipmentWarningPill: React.FC<PropsT> = React.memo((props) => {
    const { warning, isSymmetrical } = props;

    const { t } = useTranslation();

    if (!warning) {
        return null;
    }

    return (
        <PillLabel isNoWrap theme={PILL_THEME_BY_WARNING[warning]} isSymmetrical={isSymmetrical}>
            {t(WARNING_T_MAP[warning])}
        </PillLabel>
    );
});

export default ShipmentWarningPill;
