import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './Card.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ExpandIcon from 'common/icons/ExpandIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { OnKeyPressCallbackT, useKeyboardShortcut } from 'common/utils/hooks/useKeyboardShortcut';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    iconNode?: React.ReactNode;
    titleNode: React.ReactNode;
    titleClassName?: string;
    afterTitleNode?: React.ReactNode;
    titleTooltipNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    initIsOpen?: boolean;
    headerClassName?: string;
    contentClassName?: string;
    hasHeaderBottomBorder?: boolean;
    footerNode?: React.ReactNode;
    keyboardShortcut?: string;
};

const Card: React.FC<PropsT> = React.memo((props) => {
    const {
        iconNode,
        titleNode,
        titleClassName,
        afterTitleNode,
        titleTooltipNode,
        rightNode,
        initIsOpen,
        headerClassName,
        className,
        contentClassName,
        children,
        hasHeaderBottomBorder,
        footerNode,
        keyboardShortcut,
    } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = React.useState<boolean>(!!initIsOpen);

    const handleKeyPress: OnKeyPressCallbackT = React.useCallback(
        (key) => {
            if (key === keyboardShortcut) {
                setIsOpen((isOpen) => !isOpen);
            }
        },
        [keyboardShortcut],
    );
    useKeyboardShortcut(handleKeyPress);

    const toggle = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cs(cx('header'), headerClassName)} onClick={toggle}>
                <div className={cx('left-node')}>
                    {iconNode || null}
                    {titleNode && <div className={titleClassName || cx('title')}>{titleNode}</div>}
                    {titleTooltipNode && (
                        <Tooltip
                            className={cx('tooltip')}
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                    {titleTooltipNode}
                                </TooltipContent>
                            }
                        >
                            {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                        </Tooltip>
                    )}
                    {afterTitleNode}
                </div>
                <div className={cx('right-node')}>
                    {rightNode}
                    <Tooltip
                        position={TooltipPositionEnum.topCenter}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={
                            <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                {t(isOpen ? 'common:tooltips.collapse' : 'common:tooltips.expand')}
                            </TooltipContent>
                        }
                    >
                        {() => (
                            <TransparentTrigger
                                onClick={() => {
                                    // header onClick
                                }}
                                leftIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.brandAccent} isInvert={isOpen} />}
                                reflectionTheme={ReflectionThemeEnum.light}
                            />
                        )}
                    </Tooltip>
                </div>
            </div>
            {isOpen && (
                <div
                    className={cs(
                        cx('content', {
                            'content--has-top-border': hasHeaderBottomBorder,
                        }),
                        contentClassName,
                    )}
                >
                    {children}
                </div>
            )}
            {footerNode && (
                <div
                    className={cs(
                        cx('content', {
                            'content--has-top-border': hasHeaderBottomBorder,
                        }),
                        contentClassName,
                    )}
                >
                    {footerNode}
                </div>
            )}
        </div>
    );
});

export default Card;
