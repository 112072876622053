import * as React from 'react';

import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './ProgressBar.scss';
import { useSelector } from 'react-redux';
import { selectOffersTasks } from 'common/store/order-creation/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const ProgressBar: React.FC<PropsT> = (props) => {
    const { className } = props;

    const offersTasks = useSelector(selectOffersTasks);

    const isShow = offersTasks?.total !== null && offersTasks?.count !== offersTasks?.total;
    const percent = ((offersTasks?.count || 0) / (offersTasks?.total || 0)) * 100;
    const limitedPercent = Math.min(percent, 100);

    return (
        <div className={cs(cx('wrap'), className)}>
            {isShow && (
                <>
                    <div className={cx('line', 'line--isBackground')} />
                    <div style={{ width: `${limitedPercent}%` }} className={cx('line', 'line--isProgress')} />
                </>
            )}
        </div>
    );
};

export default ProgressBar;
