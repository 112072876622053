import { SharableStateT } from 'common/store/models';
import { ErrorsStateT } from './types';
import { ErrorActionT } from 'common/store/errors/models';

export const selectErrorLogs = (state: SharableStateT): ErrorsStateT['logs'] => state.errors.logs;

export const selectNewErrorLogs = (state: SharableStateT): ErrorActionT[] => {
    return state.errors.logs.filter((log) => log.isNew);
};

export const selectIsShowErrorModal = (state: SharableStateT): ErrorsStateT['isShowErrorModal'] =>
    state.errors.isShowErrorModal;
