import { ApiScheduleAddressT, SetUnavailableVehicleQueryT } from 'common/utils/api/models';
import moment from 'moment';

import { FieldsEnum, FormValuesT } from './constants';
import { AssetTypeEnum } from 'common/constants';
import { prepareLocation } from 'common/utils/prepare-location';
import isNumber from 'lodash/isNumber';

export const prepareQuery = (formValues: FormValuesT, assetType: AssetTypeEnum): SetUnavailableVehicleQueryT | null => {
    const dateRange = formValues[FieldsEnum.dateRange];

    if (!dateRange?.from) {
        return null;
    }

    const query: SetUnavailableVehicleQueryT = {};

    const apiLocation = prepareLocation(formValues[FieldsEnum.dropoffLocation]);

    const dateFrom = moment(dateRange.from).startOf('day').format();
    const dateTo = moment(dateRange.to || dateRange.from)
        .endOf('day')
        .format();

    const latitude = apiLocation?.latitude || null;
    const longitude = apiLocation?.longitude || null;
    if (!isNumber(latitude) || !isNumber(longitude)) {
        return null;
    }

    const scheduleAddress: ApiScheduleAddressT = {
        ...apiLocation,
        latitude,
        longitude,
    };

    if (assetType === AssetTypeEnum.truck) {
        query.truckDateFrom = dateFrom;
        query.truckDateTo = dateTo;
        query.truckOrigin = scheduleAddress || undefined;
        query.truckDestination = scheduleAddress || undefined;
    }

    if (assetType === AssetTypeEnum.trailer) {
        query.trailerDateFrom = dateFrom;
        query.trailerDateTo = dateTo;
        query.trailerOrigin = scheduleAddress || undefined;
        query.trailerDestination = scheduleAddress || undefined;
    }

    return query;
};
