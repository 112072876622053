import { put, select } from 'redux-saga/effects';
import { fetchTrucksDictBegin, fetchTrucksDictError, fetchTrucksDictSuccess } from './actions';
import { FetchTrucksDictActionT, TRUCKS_DICT_REQUEST } from './types';
import { selectTrucksDict, selectTrucksDictRequest } from './selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import checkNeedRequest from 'common/utils/check-need-request';
import { semaphoreSaga } from 'common/utils/semaphore-saga';

function* fetchTrucksDictSaga(action: FetchTrucksDictActionT): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectTrucksDictRequest> = yield select(selectTrucksDictRequest);
    if (!checkNeedRequest(requestStatus)) {
        return;
    }

    const currentTrucksDict: ReturnType<typeof selectTrucksDict> = yield select(selectTrucksDict);
    if (currentTrucksDict) {
        return;
    }

    yield put(fetchTrucksDictBegin());

    const [error, trucksDict]: ReturnApiT<typeof commonTranziitApi.getTrucksDict> =
        yield commonTranziitApi.getTrucksDict();
    if (error) {
        yield put(fetchTrucksDictError(error));
    }

    yield put(fetchTrucksDictSuccess(trucksDict || []));
}

function* trucksSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(TRUCKS_DICT_REQUEST, fetchTrucksDictSaga);
}

export default trucksSaga;
