import { RequestStatusT } from 'common/utils/request-status';
import { AnyNotificationT, FetchNotificationsPageQueryT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const SEND_TEST_NOTIFICATION = 'common/notifications/SEND_TEST_NOTIFICATION';

export const RECEIVED_NOTIFICATIONS = 'common/notifications/RECEIVED_NOTIFICATIONS';

export const SUBSCRIBE_TO_NOTIFICATIONS = 'common/notifications/SUBSCRIBE_TO_NOTIFICATIONS';
export const SUBSCRIBE_TO_NOTIFICATIONS_ERROR = 'common/notifications/SUBSCRIBE_TO_NOTIFICATIONS_ERROR';

export const FETCH_UNREAD_NOTIFICATIONS_REQUEST = 'common/notifications/FETCH_UNREAD_NOTIFICATIONS_REQUEST';
export const FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN = 'common/notifications/FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN';
export const FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS =
    'common/notifications/FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS';
export const FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR = 'common/notifications/FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR';

export const FETCH_NOTIFICATIONS_PAGE_REQUEST = 'common/notifications/FETCH_NOTIFICATIONS_PAGE_REQUEST';
export const FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN = 'common/notifications/FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN';
export const FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR = 'common/notifications/FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR';
export const FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS = 'common/notifications/FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS';

export const MARK_AS_OLD = 'common/notifications/MARK_AS_OLD';

export const MARK_AS_READ_REQUEST = 'common/notifications/MARK_AS_READ_REQUEST';
export const MARK_AS_READ_REQUEST_BEGIN = 'common/notifications/MARK_AS_READ_REQUEST_BEGIN';
export const MARK_AS_READ_REQUEST_SUCCESS = 'common/notifications/MARK_AS_READ_REQUEST_SUCCESS';
export const MARK_AS_READ_REQUEST_ERROR = 'common/notifications/MARK_AS_READ_REQUEST_ERROR';

export const RESET_NOTIFICATION_PAGES = 'common/notifications/RESET_NOTIFICATION_PAGES';

export type ReceivedNotificationsActionT = {
    type: typeof RECEIVED_NOTIFICATIONS;
    notifications: AnyNotificationT[];
};

export type SendTestNotificationActionT = {
    type: typeof SEND_TEST_NOTIFICATION;
    text: string;
};

export type SubscribeToNotificationsActionT = {
    type: typeof SUBSCRIBE_TO_NOTIFICATIONS;
};

export type SubscribeToNotificationsErrorActionT = {
    type: typeof SUBSCRIBE_TO_NOTIFICATIONS_ERROR;
    error: Error;
};

export type FetchUnreadNotificationsActionT = {
    type: typeof FETCH_UNREAD_NOTIFICATIONS_REQUEST;
};

export type FetchUnreadNotificationsBeginActionT = {
    type: typeof FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN;
};

export type FetchUnreadNotificationsSuccessActionT = {
    type: typeof FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS;
    notifications: AnyNotificationT[] | null;
};

export type FetchUnreadNotificationsErrorActionT = {
    type: typeof FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR;
    error: Error;
};

export type FetchNotificationsPageActionT = {
    type: typeof FETCH_NOTIFICATIONS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchNotificationsPageQueryT;
    options?: FetchOptionsT;
};

export type FetchNotificationsPageBeginActionT = {
    type: typeof FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchNotificationsPageQueryT;
};

export type FetchNotificationsPageSuccessActionT = {
    type: typeof FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS;
    query: FetchNotificationsPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<AnyNotificationT> | null;
};

export type FetchNotificationsErrorActionT = {
    type: typeof FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR;
    query: FetchNotificationsPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type MarkAsOldActionT = {
    type: typeof MARK_AS_OLD;
    ids: NotificationIdT[];
};

export type MarkAsReadActionT = {
    type: typeof MARK_AS_READ_REQUEST;
    ids: NotificationIdT[];
    isAll?: boolean;
};

export type MarkAsReadBeginActionT = {
    type: typeof MARK_AS_READ_REQUEST_BEGIN;
};

export type MarkAsReadSuccessActionT = {
    type: typeof MARK_AS_READ_REQUEST_SUCCESS;
    ids: NotificationIdT[];
};

export type MarkAsReadErrorActionT = {
    type: typeof MARK_AS_READ_REQUEST_ERROR;
    error: Error;
};

export type ResetNotificationPagesT = {
    type: typeof RESET_NOTIFICATION_PAGES;
};

export type NotificationsActionT =
    | ReceivedNotificationsActionT
    | SendTestNotificationActionT
    | SubscribeToNotificationsActionT
    | SubscribeToNotificationsErrorActionT
    | FetchUnreadNotificationsBeginActionT
    | FetchUnreadNotificationsSuccessActionT
    | FetchUnreadNotificationsErrorActionT
    | FetchUnreadNotificationsActionT
    | FetchNotificationsPageActionT
    | FetchNotificationsPageBeginActionT
    | FetchNotificationsPageSuccessActionT
    | FetchNotificationsErrorActionT
    | MarkAsReadActionT
    | MarkAsReadBeginActionT
    | MarkAsReadSuccessActionT
    | MarkAsReadErrorActionT
    | MarkAsOldActionT
    | ResetNotificationPagesT;

export type NotificationsStateT = PaginationStateT<AnyNotificationT, FetchNotificationsPageQueryT> & {
    unreadNotifications: {
        ids: AnyNotificationT['id'][];
        byId: Record<NonNullable<AnyNotificationT['id']>, AnyNotificationT>;
        fetchRequest: RequestStatusT;
    };
    newNotificationIds: NotificationIdT[];
    markAsReadRequest: RequestStatusT;
    currentPageNumber: number;
};
