import { PageSortT } from 'common/utils/query';

const SEPARATOR = ',';

export const formatPageQuerySort = <T extends string>(pageSort: PageSortT<T> | null): QuerySortT => {
    if (!pageSort) {
        return '';
    }

    return [pageSort.value, pageSort.direction].join(SEPARATOR);
};
