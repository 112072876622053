import React from 'react';

import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DatePickerOverlay.scss';

const cx = classNames.bind(styles);

export enum DatePickerOverlayPositionEnum {
    bottomLeft = 'bottom-left',
    bottomRight = 'bottom-right',
    topLeft = 'top-left',
    topRight = 'top-right',
}

type PropsT = {
    position?: DatePickerOverlayPositionEnum;
    className?: string;
};

/* eslint-disable react/destructuring-assignment */
const DatePickerOverlay: React.FC<PropsT> = (props) => {
    const { className, children } = props;

    const position = props.position || DatePickerOverlayPositionEnum.bottomLeft;

    return (
        <div
            className={cs(
                cx('overlay', {
                    [`overlay--position-${position}`]: true,
                }),
                className,
            )}
            onClick={(event) => {
                // fix formik submit, button in datepicker has not "button" type attribute
                // https://github.com/formium/formik/issues/1610#issuecomment-529572094
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            {children}
        </div>
    );
};

export default DatePickerOverlay;
