import {
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_REQUEST_BEGIN,
    FETCH_ORDER_REQUEST_ERROR,
    FETCH_ORDER_REQUEST_SUCCESS,
    FetchOrderRequestActionT,
    FetchOrderRequestBeginActionT,
    FetchOrderRequestErrorActionT,
    FetchOrderRequestSuccessActionT,
} from './types';
import { OrderDetailsT } from './models';

export const fetchOrder = (id: OrderIdT, options?: FetchOptionsT): FetchOrderRequestActionT => ({
    type: FETCH_ORDER_REQUEST,
    id,
    options,
});

export const fetchOrderBegin = (id: OrderIdT): FetchOrderRequestBeginActionT => ({
    type: FETCH_ORDER_REQUEST_BEGIN,
    id,
});

export const fetchOrderSuccess = (id: OrderIdT, order: OrderDetailsT | null): FetchOrderRequestSuccessActionT => ({
    type: FETCH_ORDER_REQUEST_SUCCESS,
    order,
    id,
});

export const fetchOrderError = (id: OrderIdT, error: Error): FetchOrderRequestErrorActionT => ({
    type: FETCH_ORDER_REQUEST_ERROR,
    error,
    id,
});
