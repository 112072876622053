import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './PositionOption.scss';
import { ContactPositionsEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import Radio from 'common/components/Radio/Radio';

type PropsT = {
    onChange?: (value: ContactPositionsEnum) => void;
    value: ContactPositionsEnum;
    selectedValue: ContactPositionsEnum;
    className?: string;
    isDisabled?: boolean;
};

const cx = classNames.bind(styles);

const PositionOption: React.FC<PropsT> = React.memo((props) => {
    const { value, selectedValue, onChange, isDisabled, className } = props;

    const { t } = useTranslation();

    return (
        <Radio<ContactPositionsEnum>
            className={cs(cx('radio'), className)}
            label={
                <span className={cx('radio__label')}>
                    {t(`common:team-members.edit-contact.fields.position.labels.${value}`)}
                </span>
            }
            isDisabled={isDisabled}
            selectedValue={selectedValue}
            value={value}
            onChange={onChange}
        />
    );
});

export default PositionOption;
