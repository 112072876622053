import * as React from 'react';
import { ApiShipperContractLaneStatusT, ShipperContractLaneStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiShipperContractLaneStatusT | null | undefined;
};

export const SHIPPER_CONTRACT_LANE_STATUS_T_MAP: Record<ApiShipperContractLaneStatusT, string> = {
    [ShipperContractLaneStatusEnum.revoked]: 'common:shipper-contract-lane-status.REVOKED',
    [ShipperContractLaneStatusEnum.expired]: 'common:shipper-contract-lane-status.EXPIRED',
    [ShipperContractLaneStatusEnum.completed]: 'common:shipper-contract-lane-status.COMPLETED',
    [ShipperContractLaneStatusEnum.active]: 'common:shipper-contract-lane-status.ACTIVE',
};

const ShipperContractLaneStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(SHIPPER_CONTRACT_LANE_STATUS_T_MAP[status]);
});

export default ShipperContractLaneStatus;
