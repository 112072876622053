import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const NewIcon: React.FC<PropsT> = (props) => {
    const { fillColor, fillOpacity, strokeColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    fillOpacity={fillOpacity || 1}
                    d="M10.008 14.5c-1.11 0-2.063-.607-2.588-1.5H4.75v2.25h10.5V13h-2.662a2.985 2.985 0 01-2.58 1.5z"
                />
                <path
                    fill={strokeColor}
                    d="M13 7.75h-1.912V5.5H8.913v2.25H7l3 3 3-3zm2.25-4.5H4.742c-.832 0-1.492.675-1.492 1.5v10.5c0 .825.66 1.5 1.492 1.5H15.25c.825 0 1.5-.675 1.5-1.5V4.75c0-.825-.675-1.5-1.5-1.5zm0 12H4.75V13h2.67a2.988 2.988 0 002.588 1.5c1.11 0 2.062-.607 2.587-1.5h2.655v2.25zm0-3.75h-3.742c0 .825-.675 1.5-1.5 1.5s-1.5-.675-1.5-1.5H4.75l-.008-6.75H15.25v6.75z"
                />
            </g>
        </svg>
    );
};

NewIcon.displayName = 'NewIcon';

const storyProps: PropsT = {
    fillOpacity: 1,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getNewIconProps = (preset: 'default' | 'menu-item' | 'menu-item-selected'): PropsT => {
    switch (preset) {
        case 'menu-item-selected': {
            return {
                fillOpacity: 0.3,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'menu-item':
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
export { storyProps };
export default NewIcon;
