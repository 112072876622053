import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './HeaderSideBarLayout.scss';
import SideBarLayoutContext from '../contexts/side-bar-layout-context';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    hasBottomBorder?: boolean;
    hasBottomShadow?: boolean;
};

const HeaderSideBarLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, hasBottomBorder, hasBottomShadow, className } = props;

    const ref = React.useRef<HTMLDivElement | null>(null);

    const context = React.useContext(SideBarLayoutContext);

    React.useEffect(() => {
        const measureContainer = () => {
            if (!ref.current) {
                return;
            }

            context.headerHeight?.setValue(ref.current?.clientHeight);
        };

        window.addEventListener('resize', measureContainer);

        measureContainer();

        return () => {
            window.removeEventListener('resize', measureContainer);

            context.headerHeight?.setValue(0);
        };
    }, [children, hasBottomBorder]);

    return (
        <div
            ref={ref}
            className={cs(
                cx('wrap', {
                    'wrap--hasBottomBorder': hasBottomBorder,
                    'wrap--hasBottomShadow': hasBottomShadow,
                }),
                className,
            )}
            style={{ marginRight: `${context.scrollBarWidth?.value || 0}px` }}
        >
            {children}
        </div>
    );
});

export default HeaderSideBarLayout;
