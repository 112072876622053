import { UserStateT } from './types';
import { SharableStateT } from '../models';
import { UserStatusEnum } from 'common/utils/api/models';
import { SEC_IN_MIN } from 'common/utils/time';
import isNumber from 'lodash/isNumber';

export const selectCurrentUserRequestStatus = (state: SharableStateT): UserStateT['currentUserRequest'] =>
    state.user.currentUserRequest;

export const selectCurrentUser = (state: SharableStateT): UserStateT['currentUser'] | null =>
    state.user.currentUser || null;

export const selectCurrentUserUTCOffset = (state: SharableStateT): UTCOffsetT | null => {
    const timezone = state.user.currentUser?.timezone;

    return isNumber(timezone) ? timezone / SEC_IN_MIN : null;
};

export const selectUserSignedUp = (state: SharableStateT): boolean => !!state.user.currentUser;

export const selectIsPendingUser = (state: SharableStateT): boolean => {
    return state.user.currentUser?.status === UserStatusEnum.pending;
};

export const selectPatchUserRequestStatus = (state: SharableStateT): UserStateT['patchUserRequest'] =>
    state.user.patchUserRequest;
