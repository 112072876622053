import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type NotUsedRoutePointValidationResultT = {};

type PropsT = {
    validationResult: NotUsedRoutePointValidationResultT | null;
};

const NotUsedRoutePointValidationAlertContent: React.FC<PropsT> = React.memo((props) => {
    const { validationResult } = props;

    const { t } = useTranslation();

    if (!validationResult) {
        return null;
    }

    return <span>{t('common:new-order-details.validate-messages.not-used-pickup-delivery-point')}</span>;
});

export default NotUsedRoutePointValidationAlertContent;
