import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    fillOpacity?: number;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const InvoiceIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, fillOpacity, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fillOpacity={fillOpacity || 1}
                    fill={fillColor}
                    d="M4.75 15.318h10.5V4.682H4.75v10.636zM5.5 6.25h9v1.5h-9v-1.5zm0 3h9v1.5h-9v-1.5zm0 3h9v1.5h-9v-1.5z"
                />
                <path
                    fill={strokeColor}
                    d="M15.625 3.625L14.5 2.5l-1.125 1.125L12.25 2.5l-1.125 1.125L10 2.5 8.875 3.625 7.75 2.5 6.625 3.625 5.5 2.5 4.375 3.625 3.25 2.5v15l1.125-1.125L5.5 17.5l1.125-1.125L7.75 17.5l1.125-1.125L10 17.5l1.125-1.125L12.25 17.5l1.125-1.125L14.5 17.5l1.125-1.125L16.75 17.5v-15l-1.125 1.125zm-.375 11.693H4.75V4.682h10.5v10.636zM5.5 12.25h9v1.5h-9v-1.5zm0-3h9v1.5h-9v-1.5zm0-3h9v1.5h-9v-1.5z"
                />
            </g>
        </svg>
    );
};

InvoiceIcon.displayName = 'InvoiceIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
    fillOpacity: 1,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default InvoiceIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getInvoiceIconProps = (preset: 'default' | 'selected'): PropsT => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent,
                fillOpacity: 0.3,
                strokeColor: StyleGuideColorsEnum.brandDark,
                size: DEFAULT_ICON_SIZE,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
                size: DEFAULT_ICON_SIZE,
            };
        }
    }
};
