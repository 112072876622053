import { RolesEnum } from 'common/utils/api/models';
import { TranziitApiRequestErrorSubTypeEnum } from 'common/utils/api/tranziit/errors/tranziit-api-errors';

export enum FieldsEnum {
    email = 'email',
    role = 'role',
}

export type FormValuesT = {
    [FieldsEnum.email]: string;
    [FieldsEnum.role]: RolesEnum | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.email]: TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist;
}>;
