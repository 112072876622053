import * as React from 'react';
import { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './NotificationsBarContent.scss';
import { useTranslation } from 'react-i18next';
import NotificationLayout from 'common/components/notifications/NotificationsBarContent/NotificationLayout/NotificationLayout';
import { AnyNotificationT, NotificationViewT } from 'common/store/notifications/models';
import { NotificationLinkFactoryT, NotificationRendererT } from './models';
import {
    fetchNotificationsPage,
    fetchUnreadNotifications,
    markAsRead,
    resetNotificationPages,
} from 'common/store/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentPageNumber,
    selectMarkAsReadRequest,
    selectNotificationsById,
    selectNotificationsPages,
    selectNotificationsTotal,
} from 'common/store/notifications/selectors';
import { StyleGuideColorsEnum } from 'common/constants';
import { useIsScrollable } from './useIsScrollable';
import InfiniteScroll from 'react-infinite-scroll-component';
import flatten from 'lodash/flatten';
import { isNonNil } from 'common/utils';
import SideBarMessage, {
    SideBarMessageEnum,
} from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarMessage/SideBarMessage';
import { renderCommonNotification, renderUnknownNotification } from './utils';
import history from 'common/utils/history';
import { logWarning } from 'common/utils/logger';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';
import SideBarLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-layout-context';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';

const cx = classNames.bind(styles);

export type PropsT = {
    renderNotification: NotificationRendererT | null;
    getNotificationLink: NotificationLinkFactoryT | null;
};

type NotificationDataT = [AnyNotificationT, NotificationViewT];

const NotificationsBarContent: React.FC<PropsT> = React.memo((props) => {
    const { renderNotification, getNotificationLink } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchNotificationsPage(0, {}, { isForceUpdate: true }));
        dispatch(fetchUnreadNotifications());

        return () => {
            dispatch(resetNotificationPages());
        };
    }, []);

    const pages = useSelector(selectNotificationsPages);
    const markAsReadRequest = useSelector(selectMarkAsReadRequest);

    const notificationsById = useSelector(selectNotificationsById);

    const currentPageNumber = useSelector(selectCurrentPageNumber);
    const currentPage = pages[currentPageNumber];

    const total = useSelector(selectNotificationsTotal);
    const hasNextPage = total?.pageCount ? total.pageCount - 1 > currentPageNumber : false;

    const isLoading =
        (!currentPage?.requestStatus?.ok && !currentPage?.requestStatus?.error) || markAsReadRequest.loading;

    const fetchNextPage = async () => {
        if (!hasNextPage) {
            return;
        }

        dispatch(fetchNotificationsPage(currentPageNumber + 1, {}, { isForceUpdate: true }));
    };

    const [isScrollable, ref, node] = useIsScrollable([pages]);

    useEffect(() => {
        if (!node || isLoading) return;

        if (!isScrollable) {
            fetchNextPage();
        }
    }, [isLoading, isScrollable, node, currentPage]);

    const pageNotificationIds = pages.map((page, pageIndex) => {
        return page.ids;
    });

    const allNotificationsIds = flatten(pageNotificationIds).filter(isNonNil);

    const isEmpty = total?.pageCount === 0 && total?.elementCount === 0;

    const context = React.useContext(SideBarLayoutContext);

    React.useEffect(() => {
        const scrollBarWidth = getScrollbarWidth(node);

        if (context.scrollBarWidth) {
            context.scrollBarWidth.setValue(scrollBarWidth);
        }
    }, [node, allNotificationsIds]);

    const notificationData: Array<NotificationDataT> = React.useMemo(() => {
        return allNotificationsIds
            .map((id) => {
                const notification = notificationsById[id];
                if (!notification) {
                    return null;
                }

                const notificationView: NotificationViewT =
                    renderCommonNotification(notification) ||
                    renderNotification?.(notification) ||
                    renderUnknownNotification(notification);

                const data: NotificationDataT = [notification, notificationView];

                return data;
            })
            .filter(isNonNil);
    }, [allNotificationsIds, notificationsById]);

    return (
        <div id="scrollableDiv" className={cx('inner')} ref={ref}>
            {isEmpty && (
                <div className={cx('message-wrap')}>
                    <SideBarMessage
                        iconType={SideBarMessageEnum.empty}
                        title={t('common:notification-side-bar.empty-message.title')}
                    />
                </div>
            )}
            <InfiniteScroll
                dataLength={allNotificationsIds.length}
                next={() => {
                    fetchNextPage();
                }}
                className={cx('notifications')}
                hasMore={hasNextPage}
                loader={null}
                scrollableTarget="scrollableDiv"
            >
                {notificationData.map(([notification, notificationView]) => {
                    return (
                        <NotificationLayout
                            key={notification.id}
                            {...notificationView}
                            emotion={notification.readAlready ? null : notificationView.emotion}
                            source={notification}
                            onClick={() => {
                                const link: ReturnType<NotificationLinkFactoryT> =
                                    getNotificationLink?.(notification) || null;
                                if (link) {
                                    history.push(link);
                                }

                                if (notification.readAlready) {
                                    return;
                                }

                                if (notification.id) {
                                    dispatch(markAsRead([notification.id]));
                                } else {
                                    logWarning('empty notification id');
                                }
                            }}
                        />
                    );
                })}
            </InfiniteScroll>
            {isLoading && (
                <div className={cx('loader')}>
                    <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} />
                </div>
            )}
            {!hasNextPage && currentPageNumber > 0 && currentPage?.requestStatus?.ok && (
                <SideBarMessage
                    className={cx('message')}
                    iconType={SideBarMessageEnum.done}
                    title={t('common:notification-side-bar.done-message.title')}
                    description={t('common:notification-side-bar.done-message.description')}
                />
            )}
        </div>
    );
});

export default NotificationsBarContent;
