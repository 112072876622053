import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {};

const OriginIcon: React.FC<PropsT> = () => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <circle cx={10} cy={10} r={10} fill={StyleGuideColorsEnum.dodgerBlue} />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M7.775 15l.574-1.86h3.288l.581 1.86h2.577l-3.677-9.953H8.862L5.212 15h2.563zm3.288-3.712h-2.14L9.99 7.836l1.073 3.452z"
                />
            </g>
        </svg>
    );
};

OriginIcon.displayName = 'OriginIcon';

const storyProps: PropsT = {};

export { storyProps };
export default OriginIcon;
