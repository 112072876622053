export function logWarning(message?: string, ...data: any): void {
    console.warn(`WARNING ${message}`, ...data);
}

export function logDebug(message?: string, ...data: any): void {
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    console.warn(`DEBUG ${message}`, ...data);
}
