import {
    ACTIVATE_DRIVER,
    ActivateDriverActionT,
    ARCHIVE_DRIVER,
    ArchiveDriverActionT,
    CANCEL_DRIVER_INVITE,
    CancelDriverInviteActionT,
    DELETE_DRIVER_REQUEST,
    DELETE_DRIVER_REQUEST_BEGIN,
    DELETE_DRIVER_REQUEST_ERROR,
    DELETE_DRIVER_REQUEST_SUCCESS,
    DeleteDriverActionT,
    DeleteDriverBeginActionT,
    DeleteDriverErrorActionT,
    DeleteDriverSuccessActionT,
    FETCH_DRIVER_DETAILS_REQUEST,
    FETCH_DRIVER_DETAILS_REQUEST_BEGIN,
    FETCH_DRIVER_DETAILS_REQUEST_ERROR,
    FETCH_DRIVER_DETAILS_REQUEST_SUCCESS,
    FETCH_DRIVERS_PAGE_REQUEST,
    FETCH_DRIVERS_PAGE_REQUEST_BEGIN,
    FETCH_DRIVERS_PAGE_REQUEST_ERROR,
    FETCH_DRIVERS_PAGE_REQUEST_SUCCESS,
    FetchDriverDetailsActionT,
    FetchDriverDetailsBeginActionT,
    FetchDriverDetailsErrorActionT,
    FetchDriverDetailsSuccessActionT,
    FetchDriversPageActionT,
    FetchDriversPageBeginActionT,
    FetchDriversPageErrorActionT,
    FetchDriversPageSuccessActionT,
    INVITE_DRIVER_REQUEST,
    INVITE_DRIVER_REQUEST_BEGIN,
    INVITE_DRIVER_REQUEST_ERROR,
    INVITE_DRIVER_REQUEST_SUCCESS,
    InviteDriverActionT,
    InviteDriverBeginActionT,
    InviteDriverErrorActionT,
    InviteDriverSuccessActionT,
    RESET_DRIVERS,
    ResetDriversActionT,
    SET_DRIVER_STATUS,
    SetDriverStatusActionT,
    UPDATE_DRIVER_REQUEST,
    UPDATE_DRIVER_REQUEST_BEGIN,
    UPDATE_DRIVER_REQUEST_ERROR,
    UPDATE_DRIVER_REQUEST_SUCCESS,
    UpdateDriverActionT,
    UpdateDriverBeginActionT,
    UpdateDriverErrorActionT,
    UpdateDriverSuccessActionT,
} from './types';
import { CompanyDriverDetailsT, CompanyDriverT, FetchCompanyDriversPageQueryT } from './models';

import { ApiInviteDriverRequestT, ApiUpdateDriverRequestT, DriverStatusEnum } from 'common/utils/api/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchDriversPage = (
    pageNumber: PageNumberT,
    query: FetchCompanyDriversPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchDriversPageActionT => {
    return {
        type: FETCH_DRIVERS_PAGE_REQUEST,
        query,
        pageNumber,
        companyId,
        options,
    };
};

export const fetchDriversPageBegin = (
    query: FetchCompanyDriversPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchDriversPageBeginActionT => ({
    type: FETCH_DRIVERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchDriversPageSuccess = (
    query: FetchCompanyDriversPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<CompanyDriverT> | null,
    companyId: CompanyIdT,
): FetchDriversPageSuccessActionT => ({
    type: FETCH_DRIVERS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
    companyId,
});

export const fetchDriversPageError = (
    query: FetchCompanyDriversPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchDriversPageErrorActionT => ({
    type: FETCH_DRIVERS_PAGE_REQUEST_ERROR,
    query,
    pageNumber,
    error,
    companyId,
});

export const resetDrivers = (
    companyId: CompanyIdT,
    mutationListOptions: MutationListOptionsT,
): ResetDriversActionT => ({
    type: RESET_DRIVERS,
    companyId,
    mutationListOptions,
});

export const inviteDriver = (newDriver: ApiInviteDriverRequestT, companyId: CompanyIdT): InviteDriverActionT => ({
    type: INVITE_DRIVER_REQUEST,
    newDriver,
    companyId,
});

export const inviteDriverBegin = (companyId: CompanyIdT): InviteDriverBeginActionT => ({
    type: INVITE_DRIVER_REQUEST_BEGIN,
    companyId,
});

export const inviteDriverSuccess = (companyId: CompanyIdT): InviteDriverSuccessActionT => ({
    type: INVITE_DRIVER_REQUEST_SUCCESS,
    companyId,
});

export const inviteDriverError = (error: Error, companyId: CompanyIdT): InviteDriverErrorActionT => ({
    type: INVITE_DRIVER_REQUEST_ERROR,
    error,
    companyId,
});

export const deleteDriver = (driverId: DriverIdT, companyId: CompanyIdT): DeleteDriverActionT => ({
    type: DELETE_DRIVER_REQUEST,
    driverId,
    companyId,
});

export const deleteDriverBegin = (companyId: CompanyIdT): DeleteDriverBeginActionT => ({
    type: DELETE_DRIVER_REQUEST_BEGIN,
    companyId,
});

export const deleteDriverSuccess = (companyId: CompanyIdT): DeleteDriverSuccessActionT => ({
    type: DELETE_DRIVER_REQUEST_SUCCESS,
    companyId,
});

export const deleteDriverError = (error: Error, companyId: CompanyIdT): DeleteDriverErrorActionT => ({
    type: DELETE_DRIVER_REQUEST_ERROR,
    error,
    companyId,
});

export const updateDriver = (
    driverId: DriverIdT,
    driverChanges: ApiUpdateDriverRequestT,
    companyId: CompanyIdT,
): UpdateDriverActionT => ({
    type: UPDATE_DRIVER_REQUEST,
    driverId,
    driverChanges,
    companyId,
});

export const setDriverStatus = (
    driverId: DriverIdT,
    status: DriverStatusEnum,
    companyId: CompanyIdT,
): SetDriverStatusActionT => ({
    type: SET_DRIVER_STATUS,
    driverId,
    status,
    companyId,
});

export const activateDriver = (driverId: DriverIdT, companyId: CompanyIdT): ActivateDriverActionT => ({
    type: ACTIVATE_DRIVER,
    driverId,
    companyId,
});

export const cancelDriverInvite = (driverUserId: UserIdT, companyId: CompanyIdT): CancelDriverInviteActionT => ({
    type: CANCEL_DRIVER_INVITE,
    driverUserId,
    companyId,
});

export const archiveDriver = (driverId: DriverIdT, companyId: CompanyIdT): ArchiveDriverActionT => ({
    type: ARCHIVE_DRIVER,
    driverId,
    companyId,
});

export const updateDriverBegin = (companyId: CompanyIdT): UpdateDriverBeginActionT => ({
    type: UPDATE_DRIVER_REQUEST_BEGIN,
    companyId,
});

export const updateDriverSuccess = (companyId: CompanyIdT): UpdateDriverSuccessActionT => ({
    type: UPDATE_DRIVER_REQUEST_SUCCESS,
    companyId,
});

export const updateDriverError = (error: Error, companyId: CompanyIdT): UpdateDriverErrorActionT => ({
    type: UPDATE_DRIVER_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchDriverDetails = (
    driverId: DriverIdT,
    companyId: CompanyIdT,
    fetchOptions: FetchOptionsT,
): FetchDriverDetailsActionT => ({
    type: FETCH_DRIVER_DETAILS_REQUEST,
    driverId,
    companyId,
    fetchOptions,
});

export const fetchDriverDetailsBegin = (companyId: CompanyIdT): FetchDriverDetailsBeginActionT => ({
    type: FETCH_DRIVER_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchDriverDetailsSuccess = (
    driverId: DriverIdT,
    details: CompanyDriverDetailsT | null,
    companyId: CompanyIdT,
): FetchDriverDetailsSuccessActionT => ({
    type: FETCH_DRIVER_DETAILS_REQUEST_SUCCESS,
    details,
    driverId,
    companyId,
});

export const fetchDriverDetailsError = (error: Error, companyId: CompanyIdT): FetchDriverDetailsErrorActionT => ({
    type: FETCH_DRIVER_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});
