import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './UploadFooterContent.scss';
import { DictDocumentT } from 'common/store/documents-dict/models';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useSelector } from 'react-redux';
import { selectUploadCompanyDocumentsRequest } from 'common/store/documents/selectors';
import { useTranslation } from 'react-i18next';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onUploadDocument: (dictDocumentId: DictDocumentIdT) => void;
    dictDocument: DictDocumentT | null;
};

const UploadFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { dictDocument, onUploadDocument } = props;

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();
    const uploadRequest = useSelector(selectUploadCompanyDocumentsRequest(partnerId));

    const handleUploadDocument = () => {
        if (!dictDocument) {
            logWarning('failed to upload document, empty dictDocument');
            return;
        }

        onUploadDocument(dictDocument.id);
    };

    return (
        <div className={cx('content')}>
            <Button
                theme={ButtonThemeEnum.primary}
                className={cx('action')}
                onClick={handleUploadDocument}
                isLoading={uploadRequest.loading}
            >
                {t('common:documents.table.actions.upload')}
            </Button>
        </div>
    );
});

export default UploadFooterContent;
