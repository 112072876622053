import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    className?: string;
};

const CalendarIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor, className } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            className={className}
            viewBox="0 0 20 20"
        >
            <defs>
                <path
                    id="4oz2u0ytpa"
                    d="M6.667 1.667v1.666h6.666V1.667H15v1.666h.833c.917 0 1.667.75 1.667 1.667v11.667c0 .916-.75 1.666-1.667 1.666H4.167c-.925 0-1.667-.75-1.667-1.666L2.508 5a1.66 1.66 0 011.659-1.667H5V1.667h1.667zM15.833 7.5H4.167v9.167h11.666V7.5zM7.5 9.167v1.666H5.833V9.167H7.5zm3.333 0v1.666H9.167V9.167h1.666zm3.334 0v1.666H12.5V9.167h1.667zM15.833 5H4.167v1.667h11.666V5z"
                />
                <path id="axmp3dnelb" d="M4.167 6.667h11.666V5H4.167z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill={strokeColor} xlinkHref="#4oz2u0ytpa" />
                <use fill={fillColor} xlinkHref="#axmp3dnelb" />
            </g>
        </svg>
    );
};

CalendarIcon.displayName = 'CalendarIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default CalendarIcon;

export const CalendarIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.white,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};
