import * as React from 'react';

import Members from 'common/layouts/Members/Members';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';

type PropsT = {};

const ProfileMembers: React.FC<PropsT> = React.memo((props) => {
    const permissions = useSelector(selectPermissions);
    if (!permissions.canViewTeamMembers) {
        return null;
    }

    return <Members />;
});

export default ProfileMembers;
