import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './PointContactsPicker.scss';
import { FieldsEnum, FormValuesT, PointContactsFieldsEnum } from '../constants';
import { useTranslation } from 'react-i18next';
import Input from 'common/components/Input/Input';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    contactIndex: number;
};

const PointContactsPicker: React.FC<PropsT> = React.memo((props) => {
    const { contactIndex, className } = props;

    const { t } = useTranslation();

    const companyNameFieldName =
        `${FieldsEnum.contacts}.${contactIndex}.${PointContactsFieldsEnum.companyName}` as const;
    const fullNameFieldName = `${FieldsEnum.contacts}.${contactIndex}.${PointContactsFieldsEnum.fullName}` as const;
    const emailFieldName = `${FieldsEnum.contacts}.${contactIndex}.${PointContactsFieldsEnum.email}` as const;
    const phoneNumberFieldName =
        `${FieldsEnum.contacts}.${contactIndex}.${PointContactsFieldsEnum.phoneNumber}` as const;
    const commentFieldName = `${FieldsEnum.contacts}.${contactIndex}.${PointContactsFieldsEnum.comment}` as const;

    return (
        <div className={className}>
            <FieldGroup spaces={2}>
                <FormikConnectContextField<FormValuesT, typeof companyNameFieldName>
                    name={companyNameFieldName}
                    label={t('common:new-order-details.fields.company-name.label')}
                    className={cx('field', 'field--company-name')}
                >
                    {(props, extendsProps) => (
                        <Input
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-details.fields.company-name.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
                <FormikConnectContextField<FormValuesT, typeof fullNameFieldName>
                    name={fullNameFieldName}
                    label={t('common:new-order-details.fields.full-name.label')}
                    className={cx('field', 'field--full-name')}
                >
                    {(props, extendsProps) => (
                        <Input
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-details.fields.full-name.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
            </FieldGroup>
            <FieldGroup spaces={2}>
                <FormikConnectContextField<FormValuesT, typeof phoneNumberFieldName>
                    name={phoneNumberFieldName}
                    label={t('common:new-order-details.fields.phone-number.label')}
                    className={cx('field', 'field--phone-number')}
                >
                    {(props, extendsProps) => (
                        <PhoneNumberInput
                            name={extendsProps.name}
                            value={extendsProps.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
                <FormikConnectContextField<FormValuesT, typeof emailFieldName>
                    name={emailFieldName}
                    label={t('common:new-order-details.fields.email.label')}
                    className={cx('field', 'field--email')}
                >
                    {(props, extendsProps) => (
                        <Input
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-details.fields.email.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
            </FieldGroup>
            <FormikConnectContextField<FormValuesT, typeof commentFieldName>
                name={commentFieldName}
                label={t('common:new-order-details.fields.comment.label')}
                className={cx('field', 'field--comment')}
            >
                {(props, extendsProps) => (
                    <Input
                        name={extendsProps.name}
                        value={extendsProps.value}
                        placeholder={t('common:new-order-details.fields.comment.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikConnectContextField>
        </div>
    );
});

export default PointContactsPicker;
