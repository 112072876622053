import React from 'react';
import classNames from 'classnames/bind';

import styles from './DropdownSearchMultipleInput.scss';
import SearchIcon from 'common/icons/SearchIcon';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { StyleGuideColorsEnum } from 'common/constants';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import DropdownBaseButtonTrigger from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import cs from 'classnames';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

export type PropsT<OptionT, ValueT> = {
    className?: string;
    selectedValues: Array<ValueT>;
    placeholder?: string;
    inputPlaceholder?: string;
    isInline?: boolean;
    isDisabled?: boolean;
    hasWarning?: boolean;
    hasError?: boolean;
    options: Array<OptionT>;
    onSelect: (values: Array<ValueT>) => void;
    overlayPosition: DropdownOverlayPositionEnum;
    triggerClassName?: string;
    overlayClassName?: string;
    renderTrigger: (options: Array<OptionT>, placeholder: string | undefined) => React.ReactNode;
    renderOption: (option: OptionT) => React.ReactNode;
    getOptionValue: (option: OptionT) => ValueT;
    getOptionQueryHash: (option: OptionT) => string;
    onFocus?: () => void;
    onBlur?: () => void;
    testSelector?: string;
};

const prepareQuery = (query: string): string => query.replace(/\s/g, '').toLowerCase();

const DropdownSearchMultipleInput = <OptionT, ValueT>(props: PropsT<OptionT, ValueT>) => {
    const {
        className,
        selectedValues,
        placeholder,
        inputPlaceholder,
        onSelect,
        options,
        isInline,
        renderOption,
        getOptionValue,
        getOptionQueryHash,
        isDisabled,
        overlayPosition,
        triggerClassName,
        overlayClassName,
        renderTrigger,
        onFocus,
        onBlur,
        testSelector,
    } = props;

    const [query, setQuery] = React.useState<string>('');
    const [isOpen, toggleOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);

        if (onFocus) {
            onFocus();
        }
    };

    const handleClose = (): void => {
        toggleOpen(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleOuterEvent = (): void => {
        handleClose();
    };

    const optionByValue = new Map<ValueT, OptionT>();
    options.forEach((option) => {
        const value = getOptionValue(option);

        optionByValue.set(value, option);
    });

    const selectedOptions: Array<OptionT> = selectedValues.map((value) => optionByValue.get(value)).filter(isNonNil);

    const selectedValuesSet = new Set<ValueT>(selectedValues);

    const inputProps = {
        onChange: (event: TODO) => setQuery(event.target.value),
        value: query || '',
        type: 'text',
        className: cx('search-input', {
            'search-input--isEmpty': !query,
            'search-input--isFocus': isOpen,
        }),
        placeholder: inputPlaceholder,
        disabled: isDisabled,
    };

    const preparedQuery = prepareQuery(query);

    const handleChange = (value: ValueT): void => {
        if (isDisabled) {
            return;
        }

        const newSelectedValuesSet = new Set(selectedValuesSet);
        const isAlreadySelected = newSelectedValuesSet.has(value);
        if (isAlreadySelected) {
            newSelectedValuesSet.delete(value);
        } else {
            newSelectedValuesSet.add(value);
        }
        onSelect([...newSelectedValuesSet]);
    };

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <DropdownBaseButtonTrigger
                    isEmpty={!selectedOptions.length}
                    isPressed={isOpen}
                    isDisabled={isDisabled}
                    className={triggerClassName}
                    testSelector={testSelector}
                    onClick={handleOpen}
                >
                    {renderTrigger(selectedOptions, placeholder)}
                </DropdownBaseButtonTrigger>
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <>
                    <div className={cx('search-input-wrap')}>
                        <input ref={(node) => node?.focus()} {...inputProps} />
                        <div className={cx('icon')}>
                            <SearchIcon fillColor={StyleGuideColorsEnum.gray} />
                        </div>
                    </div>
                    <div className={cx('options')}>
                        {options.map((option, index): React.ReactElement | null => {
                            const value = getOptionValue(option);

                            const optionQueryHash = getOptionQueryHash(option);
                            const preparedOptionQueryHash = prepareQuery(optionQueryHash);

                            if (preparedQuery && !preparedOptionQueryHash.includes(preparedQuery)) {
                                return null;
                            }

                            const isSelected = selectedValuesSet.has(value);

                            return (
                                <div key={index} className={cx('option')}>
                                    <Checkbox
                                        checked={isSelected}
                                        className={cx('option__checkbox')}
                                        label={null}
                                        onChange={() => {
                                            handleChange(value);
                                        }}
                                    />
                                    <div
                                        className={cx('option__label')}
                                        onClick={() => {
                                            handleChange(value);
                                        }}
                                    >
                                        {renderOption(option)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            }
        />
    );
};

export default DropdownSearchMultipleInput;
