import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './NavigationTabs.scss';
import { LinkProps, matchPath, useLocation } from 'react-router-dom';
import TabLabel, { TabLabelSizeEnum } from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';

const cx = classNames.bind(styles);

type LinkToObjT = LinkProps<HistoryStateT>['to'];
type PathT = string;

export type NavigationTabConfigT = {
    to: PathT | LinkToObjT;
    pathname: PathT;
    render: () => React.ReactElement;
    testSelector: string;
    badge?: React.ReactNode;
};

export type NavigationTabsPropsT = {
    onSelectTab: (to: PathT | LinkToObjT) => void;
    tabsConfig: Array<NavigationTabConfigT | null>;
    className?: string;
};

const NavigationTabs: React.FC<NavigationTabsPropsT> = (props) => {
    const { tabsConfig, className, onSelectTab } = props;

    const { pathname } = useLocation();

    return (
        <div className={cs(cx('tabs'), className)}>
            {tabsConfig.map((tabConfig, tabIndex) => {
                if (!tabConfig) {
                    return null;
                }

                const isActive = !!matchPath(pathname, { path: tabConfig.pathname, strict: false });

                return (
                    <TabLabel
                        key={tabIndex}
                        isActive={isActive}
                        isCompact
                        size={TabLabelSizeEnum.small}
                        theme={TabsThemesEnum.light}
                        testSelector={tabConfig.testSelector}
                        badge={tabConfig.badge}
                        onClick={() => {
                            if (isActive) {
                                return;
                            }

                            onSelectTab(tabConfig.to);
                        }}
                    >
                        {tabConfig.render()}
                    </TabLabel>
                );
            })}
        </div>
    );
};

export default NavigationTabs;
