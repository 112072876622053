import * as React from 'react';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import { formatMoney } from 'common/components/Money/Money';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './UnitTypeCount.scss';

const cx = classNames.bind(styles);

type BasePropsT = {
    count: number | null | undefined;
    className?: string;
};

type PropsT =
    | (BasePropsT & {
          type: UnitTypeEnum | null | undefined;
      })
    | (BasePropsT & {
          types: Array<UnitTypeEnum>;
      });

type CurrencyUnitTypesT = UnitTypeEnum.euroAbbreviation;
const CURRENCY_TYPE_MAP: Record<CurrencyUnitTypesT, CurrencyEnum> = {
    [UnitTypeEnum.euroAbbreviation]: CurrencyEnum.EUR,
};

type SimpleUnitTypesT = UnitTypeEnum.percentsAbbreviation;
const SIMPLE_UNIT_MAP: Record<SimpleUnitTypesT, string> = {
    [UnitTypeEnum.percentsAbbreviation]: '%',
};

type OtherUnitTypesT = Exclude<UnitTypeEnum, CurrencyUnitTypesT | SimpleUnitTypesT>;
const UNIT_TYPE_T_MAP: Record<OtherUnitTypesT, string> = {
    [UnitTypeEnum.eur1Pallets]: 'common:unit-count.eur-1-pallets',
    [UnitTypeEnum.eur2Pallets]: 'common:unit-count.eur-2-pallets',
    [UnitTypeEnum.other]: 'common:unit-count.other',
    [UnitTypeEnum.kilogramsAbbreviation]: 'common:unit-count.kilograms-abbreviation',
    [UnitTypeEnum.metersAbbreviation]: 'common:unit-count.meters-abbreviation',
    [UnitTypeEnum.kilometersAbbreviation]: 'common:unit-count.kilometers-abbreviation',
    [UnitTypeEnum.litersAbbreviation]: 'common:unit-count.liters-abbreviation',
    [UnitTypeEnum.oneHundredKilometersAbbreviation]: 'common:unit-count.100-kilometers-abbreviation',
    [UnitTypeEnum.days]: 'common:unit-count.days',
    [UnitTypeEnum.hoursAbbreviation]: 'common:unit-count.hours-abbreviation',
    [UnitTypeEnum.minutesAbbreviation]: 'common:unit-count.minutes-abbreviation',
    [UnitTypeEnum.milliSecondsAbbreviation]: 'common:unit-count.milli-seconds-abbreviation',
    [UnitTypeEnum.secondsAbbreviation]: 'common:unit-count.seconds-abbreviation',
    [UnitTypeEnum.shipments]: 'common:unit-count.shipments',
    [UnitTypeEnum.kilometersPerHourAbbreviation]: 'common:unit-count.kilometers-per-hour-abbreviation',
};

const UnitTypeCount: React.FC<PropsT> = React.memo((props) => {
    const { count, className } = props;

    const { t } = useTranslation();

    if (isNil(count)) {
        return null;
    }

    if ('types' in props) {
        const { types: subTypes } = props;

        const result = subTypes.reduce((result, subType) => {
            const base = result + (result.length ? '/' : '');
            if (CURRENCY_TYPE_MAP[subType as CurrencyUnitTypesT]) {
                return (
                    base +
                    formatMoney({
                        currency: CURRENCY_TYPE_MAP[subType as CurrencyUnitTypesT],
                        amount: count,
                        isRound: false,
                    })
                );
            }

            if (SIMPLE_UNIT_MAP[subType as SimpleUnitTypesT]) {
                return base + SIMPLE_UNIT_MAP[subType as SimpleUnitTypesT];
            }

            return base + t(UNIT_TYPE_T_MAP[subType as OtherUnitTypesT], { postProcess: 'interval', count });
        }, '');

        return <span className={cs(className, cx('count'))}>{result}</span>;
    }

    const { type } = props;
    if (isNil(type)) {
        return null;
    }

    if (CURRENCY_TYPE_MAP[type as CurrencyUnitTypesT]) {
        const formattedType = formatMoney({
            currency: CURRENCY_TYPE_MAP[type as CurrencyUnitTypesT],
            amount: count,
            isRound: false,
        });

        return <span className={cs(className, cx('count'))}>{formattedType}</span>;
    }

    if (SIMPLE_UNIT_MAP[type as SimpleUnitTypesT]) {
        return (
            <span className={cs(className, cx('count'))}>
                {count}
                {SIMPLE_UNIT_MAP[type as SimpleUnitTypesT]}
            </span>
        );
    }

    return (
        <span className={cs(className, cx('count'))}>
            {count} {t(UNIT_TYPE_T_MAP[type as OtherUnitTypesT], { postProcess: 'interval', count })}
        </span>
    );
});

export default UnitTypeCount;
