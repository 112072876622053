import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import moment from 'moment';
import { ApiLocationT } from './api/models';
import { clearDateTimezone } from 'common/utils/time';

export const formatDateInterval = (
    from: string | null | undefined,
    to: string | null | undefined,
    format = 'YYYY-MM-DD HH:mm',
): string => {
    return [from, to]
        .filter(Boolean)
        .map((time) => moment(clearDateTimezone(time)).format(format))
        .join(' - ');
};

export const formatLocation = (location: ApiLocationT | null | undefined, separator = ' '): string => {
    if (!location) {
        return '';
    }

    return [location.street1, location.street2, location.zipCode, location.city, location.country]
        .filter(Boolean)
        .join(separator);
};

export const formatThousandNumber = (value: number | null | undefined): string => {
    if (isNil(value)) {
        return '';
    }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

export const formatTemperature = (value: number | string | null | undefined): string => {
    if (isNil(value)) {
        return '';
    }

    if (isString(value)) {
        return value;
    }

    let sign = '';
    if (value > 0) {
        sign = '+';
    }
    if (value < 0) {
        sign = '-';
    }

    return `${sign}${Math.abs(value)}`;
};

const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};
