import * as React from 'react';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './ExpireDateCell.scss';
import { CarrierContractStatusEnum } from 'common/utils/api/models';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { ContractRowT } from '../../models';
import {
    selectUpdateCarrierContractRequest,
    selectUpdatingCarrierContractId,
} from 'common/store/carrier-contracts/selectors';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    row: ContractRowT;
    className?: string;
    onSelectRow?: (row: ContractRowT) => void;
};

const ExpireDateCell: React.FC<PropsT> = React.memo((props) => {
    const { row, onSelectRow } = props;

    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const permissions = useSelector(selectPermissions);

    const updateContractRequest = useSelector(selectUpdateCarrierContractRequest(partnerId));
    const updatingContractId = useSelector(selectUpdatingCarrierContractId(partnerId));

    if (row?.id === updatingContractId && updateContractRequest.loading) {
        return <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} size={DEFAULT_ICON_SIZE} />;
    }

    if (permissions.canReviewCarrierContracts && row?.status === CarrierContractStatusEnum.waitingForApprove) {
        return (
            <ClickInterceptorLabel>
                <Button
                    className={cx('action')}
                    theme={ButtonThemeEnum.secondary}
                    onClick={() => {
                        if (onSelectRow) {
                            onSelectRow(row);
                        }
                    }}
                >
                    {t('common:carrier-contracts.table.actions.review')}
                </Button>
            </ClickInterceptorLabel>
        );
    }

    return <DateCell className={cx('date')} date={row?.validTill} />;
});

export default ExpireDateCell;
