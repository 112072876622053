import { RequestStatusT } from 'common/utils/request-status';

import { ManagerT } from './models';

export const FETCH_MANAGERS_REQUEST = 'broker-admin/managers/FETCH_MANAGERS_REQUEST';
export const FETCH_MANAGERS_REQUEST_BEGIN = 'broker-admin/managers/FETCH_MANAGERS_REQUEST_BEGIN';
export const FETCH_MANAGERS_REQUEST_ERROR = 'broker-admin/managers/FETCH_MANAGERS_REQUEST_ERROR';
export const FETCH_MANAGERS_REQUEST_SUCCESS = 'broker-admin/managers/FETCH_MANAGERS_REQUEST_SUCCESS';

type ManagerIdT = string;

export type ManagersStateT = {
    request: RequestStatusT;
    byId: Record<ManagerIdT, ManagerT>;
    allIds: Array<ManagerIdT>;
};

export type FetchManagersActionT = {
    type: typeof FETCH_MANAGERS_REQUEST;
};

export type FetchManagersBeginActionT = {
    type: typeof FETCH_MANAGERS_REQUEST_BEGIN;
};

export type FetchManagersSuccessActionT = {
    type: typeof FETCH_MANAGERS_REQUEST_SUCCESS;
    managers: ManagerT[];
};

export type FetchManagersErrorActionT = {
    type: typeof FETCH_MANAGERS_REQUEST_ERROR;
    error: Error;
};

export type ManagersActionT =
    | FetchManagersActionT
    | FetchManagersBeginActionT
    | FetchManagersSuccessActionT
    | FetchManagersErrorActionT;
