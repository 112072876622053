import React, { useState } from 'react';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { ContractDetailsSidebarDataT } from './models';
import { ApiRevokePartnerContractInfoT, CarrierContractStatusEnum } from 'common/utils/api/models';
import { useDispatch, useSelector } from 'react-redux';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { selectPermissions } from 'common/store/auth/selectors';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import isEmpty from 'lodash/isEmpty';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import ReviewFooterContent from './footers/ReviewFooterContent/ReviewFooterContent';
import SaveFooterContent from './footers/SaveFooterContent/SaveFooterContent';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import DocumentSummary from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentDetailsLayout/DocumentSummary/DocumentSummary';
import DocumentEventsSummary from '../DocumentDetailsSidebarContent/DocumentDetailsLayout/DocumentEventsSummary/DocumentEventsSummary';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import {
    selectUpdateCarrierContractRequest,
    selectUploadCarrierContractRequest,
} from 'common/store/carrier-contracts/selectors';
import { selectCarrierContractStateById } from 'common/store/carrier-contract-details/selectors';
import ContractDetailsForm from 'common/layouts/SideBars/contents/ContractDetailsSidebarContent/ContractDetailsForm/ContractDetailsForm';
import ContractDetails from './ContractDetails/ContractDetails';
import {
    downloadCarrierContract,
    rejectCarrierContract,
    revokeCarrierContract,
} from 'common/store/carrier-contracts/actions';
import { fetchCarrierContractDetails } from 'common/store/carrier-contract-details/actions';
import classNames from 'classnames/bind';
import styles from './ContractDetailsSidebarContent.scss';
import { useContractsActionOptions } from 'common/layouts/BaseCarrierContractsLayout/hooks/use-contracts-action-options';
import RevokeContractConfirmation, {
    ConfirmRevokeContractModalDataT,
} from 'common/layouts/BaseCarrierContractsLayout/dialogs/RevokeContractConfirmation/RevokeContractConfirmation';
import ContractName from 'common/components/ContractName/ContractName';
import { useTranslation } from 'react-i18next';
import RejectContractConfirmation, {
    ConfirmRejectContractModalDataT,
} from 'common/layouts/BaseCarrierContractsLayout/dialogs/RejectContractConfirmation/RejectContractConfirmation';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { carrierContractDetailsRefreshChannel } from 'common/store/carrier-contract-details/channels';
import { logWarning } from 'common/utils/logger';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import CarrierContractStatusPill from 'common/components/status-pill/CarrierContractStatusPill/CarrierContractStatusPill';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<ContractDetailsSidebarDataT, CommonSidebarDataT>;

const ContractDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, onOpenNextSidebar, setNeedCloseConfirmation } = props;

    const { initIsEditing, contractId } = data;

    const { t } = useTranslation();

    const { partnerId, partnerType } = usePartnerContext();

    const [isEditing, setIsEditing] = useState(initIsEditing);

    const remoteFormActions = useRemoteFormActions();

    const uploadRequest = useSelector(selectUploadCarrierContractRequest(partnerId));
    const updateRequest = useSelector(selectUpdateCarrierContractRequest(partnerId));

    const { data: contractDetails, fetchRequest: fetchContractDetailsRequest } = useSelector(
        selectCarrierContractStateById(contractId),
    );

    const isRequestLoading = fetchContractDetailsRequest.loading;
    const isShowLineLoader = isRequestLoading && !isEmpty(contractDetails);
    const isShowOverlayLoader = isRequestLoading && !isShowLineLoader;

    const confirmRevokeContractModalDialog = useModalDialog<ConfirmRevokeContractModalDataT>();
    const confirmRejectContractModalDialog = useModalDialog<ConfirmRejectContractModalDataT>();

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (contractId) {
            dispatch(fetchCarrierContractDetails(partnerId, contractId));
        }
    }, [contractId, partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (contractId) {
            dispatch(fetchCarrierContractDetails(partnerId, contractId));
        }
    }, [contractId, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(
        ({ contractId }: InferChannelEventT<typeof carrierContractDetailsRefreshChannel>) => {
            if (data.contractId === contractId) {
                dispatch(fetchCarrierContractDetails(partnerId, contractId, { isForceUpdate: true }));
            }
        },
        [contractId, partnerId, data],
    );
    useChannelSubscribe(carrierContractDetailsRefreshChannel, refreshPageHandler);

    const permissions = useSelector(selectPermissions);

    const handleDownloadContract = (contractId: CarrierContractIdT) => {
        dispatch(downloadCarrierContract(contractId, partnerId));
    };

    const handleEditContractInfo = () => {
        setIsEditing(true);
    };

    const handleRevokeContract = () => {
        if (!contractDetails) {
            logWarning(`failed to revoke contract, failed contractDetails`);
            return;
        }

        confirmRevokeContractModalDialog.setData({
            id: contractDetails.id,
            name: contractDetails.name,
            truckRateKm: contractDetails.truckRateKm,
            tiltRateKm: contractDetails.tiltRateKm,
            boxRateKm: contractDetails.boxRateKm,
        });
    };

    const handleRejectContract = () => {
        if (!contractDetails) {
            logWarning(`failed to reject contract, failed contractDetails`);
            return;
        }

        confirmRejectContractModalDialog.setData({
            contractId: contractDetails.id,
            contractName: contractDetails.name || '',
        });
    };

    const headerDropdownOptions = useContractsActionOptions(
        contractDetails,
        {
            ...permissions,
            canEditCarrierContracts: isEditing ? false : permissions.canEditCarrierContracts,
        },
        {
            onDownloadContract: handleDownloadContract,
            onEditContractDetails: handleEditContractInfo,
            onRevokeContract: handleRevokeContract,
        },
    );

    const handleConfirmRevokeContract = (contractId: CarrierContractIdT, revokeInfo: ApiRevokePartnerContractInfoT) => {
        dispatch(revokeCarrierContract({ partnerId, contractId, revokeInfo }));
    };

    const handleConfirmRejectContract = (data: ConfirmRejectContractModalDataT, reason: string) => {
        dispatch(rejectCarrierContract({ contractId: data.contractId, reason, partnerId }));
    };

    useCloseModalDialogAfterRequest(updateRequest, [
        confirmRevokeContractModalDialog,
        confirmRejectContractModalDialog,
    ]);

    useCloseModalDialogAfterRequest(updateRequest, [confirmRevokeContractModalDialog]);

    useOnlyChangesEffect(() => {
        if (updateRequest.ok) {
            setIsEditing(false);
        }
    }, [updateRequest]);

    useOnlyChangesEffect(() => {
        if (updateRequest.ok) {
            setIsEditing(false);
        }
    }, [updateRequest]);

    const handleOpenUserDetails = (userId: UserIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const shouldReview = contractDetails?.status === CarrierContractStatusEnum.waitingForApprove;

    const isShowSkeleton =
        isEmpty(contractDetails) && !fetchContractDetailsRequest.ok && !fetchContractDetailsRequest.error;
    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    onGoBack={onGoBack}
                    title={
                        contractDetails?.status ? (
                            <CarrierContractStatusPill isSymmetrical status={contractDetails?.status} />
                        ) : null
                    }
                    onClose={onClose}
                    dropdownOptions={headerDropdownOptions}
                />
                <HeaderSideBarLoader isShow={isShowLineLoader} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                {isShowSkeleton ? (
                    <SideBarLoader />
                ) : (
                    <>
                        <DocumentSummary
                            className={cx('document-summary')}
                            title={<ContractName name={contractDetails?.name} />}
                            tooltipContentNode={t('common:carrier-contracts.actions.download')}
                            id={contractDetails?.id || null}
                            onDownloadFile={handleDownloadContract}
                            canDownload={permissions.canDownloadCarrierContracts}
                        />
                        <DocumentEventsSummary
                            className={cx('document-events')}
                            version={contractDetails}
                            onOpenUserDetails={handleOpenUserDetails}
                        />
                        {isEditing ? (
                            <ContractDetailsForm
                                isReview={shouldReview}
                                contractDetails={contractDetails}
                                setNeedCloseConfirmation={setNeedCloseConfirmation}
                            />
                        ) : (
                            <ContractDetails contract={contractDetails} />
                        )}
                    </>
                )}
            </SideBarLayout>
            {isShowOverlayLoader && <SideBarLoader />}
            {uploadRequest.loading && <SideBarLoader />}
            {!!contractDetails && isEditing && shouldReview && (
                <FooterSideBarLayout>
                    <ReviewFooterContent onReject={handleRejectContract} />
                </FooterSideBarLayout>
            )}
            {!!contractDetails && isEditing && !shouldReview && (
                <FooterSideBarLayout>
                    <SaveFooterContent />
                </FooterSideBarLayout>
            )}
            <RejectContractConfirmation
                data={confirmRejectContractModalDialog.data}
                onCancel={confirmRejectContractModalDialog.onCancel}
                onClose={confirmRejectContractModalDialog.onClose}
                onConfirm={handleConfirmRejectContract}
                requestStatus={updateRequest}
            />
            <RevokeContractConfirmation
                data={confirmRevokeContractModalDialog.data}
                onCancel={confirmRevokeContractModalDialog.onCancel}
                onClose={confirmRevokeContractModalDialog.onClose}
                onConfirm={handleConfirmRevokeContract}
                requestStatus={updateRequest}
            />
        </RemoteFormActionsContext.Provider>
    );
};

export default ContractDetailsSidebarContent;
