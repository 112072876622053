import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const RouteIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                fill={fillColor}
                fillRule="nonzero"
                d="M15 10c1.659 0 3 1.252 3 2.8 0 2.1-3 5.2-3 5.2s-3-3.1-3-5.2c0-1.548 1.341-2.8 3-2.8zm-.875-7c1.447 0 2.625 1.178 2.625 2.625S15.572 8.25 14.125 8.25H5c-.825 0-1.5.675-1.5 1.5v3c0 .825.675 1.5 1.5 1.5h5.5v1.5H5c-1.658 0-3-1.342-3-3v-3c0-1.658 1.342-3 3-3h9.125c.623 0 1.125-.503 1.125-1.125S14.748 4.5 14.125 4.5H3.5a.752.752 0 01-.75-.75c0-.413.337-.75.75-.75zM15 12a1 1 0 100 2 1 1 0 000-2z"
            />
        </svg>
    );
};

RouteIcon.displayName = 'RouteIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default RouteIcon;
