import { StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const InfoIcon: React.FC<PropsT> = (props) => {
    const { className, fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M8.38 14.006c-.213 0-.385-.201-.385-.448s.172-.448.385-.448L9 13v-3l-.62-.116c-.213 0-.385-.201-.385-.448s.172-.448.385-.448h2.514c.077 0 .102-.032.113-.058-.003.015-.007.029-.007.07v4l.619.11c.213 0 .386.201.386.448s-.173.448-.386.448H8.38zM9 6h.98c.007 0 .013-.004.02-.004.007 0 .013.004.02.004H11v.94c.001.022.012.04.012.061 0 .022-.011.04-.012.061V8h-.967c-.012.001-.022.007-.033.007-.012 0-.022-.006-.033-.007H9v-.939c-.002-.021-.013-.038-.013-.06 0-.021.011-.039.013-.06V6zm-7 4a8 8 0 1016 0 8 8 0 00-16 0z"
            />
        </svg>
    );
};

InfoIcon.displayName = 'InfoIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default InfoIcon;
