import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PriceMatrixWithMap.scss';
import { useSelector } from 'react-redux';
import { selectRFQ } from 'common/store/order-creation/selectors';
import TabsLabel from 'common/components/TabsLabel/TabsLabel';
import TabLabel from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import PriceMatrix from 'common/layouts/NewOrderPage/PriceMatrix/PriceMatrix';
import RouteMap from 'common/layouts/NewOrderPage/RouteMap/RouteMap';
import { useTranslation } from 'react-i18next';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type PropsT = {};

const PriceMatrixWithMap: React.FC<PropsT> = React.memo((props) => {
    const rfq = useSelector(selectRFQ);

    const { t } = useTranslation();

    const isImportedOrder = rfq?.isImported;

    const availableTabs = React.useMemo(
        () =>
            [
                isImportedOrder
                    ? null
                    : {
                          label: t('common:new-order-offers.tabs.price-matrix'),
                          node: <PriceMatrix />,
                      },
                {
                    label: t('common:new-order-offers.tabs.map'),
                    node: <RouteMap />,
                },
            ].filter(isNonNil),
        [isImportedOrder],
    );

    const [activeIndexTab, setActiveIndexTab] = React.useState<number>(0);

    const hasManyTabs = availableTabs?.length > 1;

    return (
        <div className={cx('tabs-wrap')}>
            {hasManyTabs && (
                <TabsLabel>
                    {availableTabs.map((tab, tabIndex) => (
                        <TabLabel
                            key={tabIndex}
                            theme={TabsThemesEnum.dark}
                            onClick={() => {
                                setActiveIndexTab(tabIndex);
                            }}
                            isActive={tabIndex === activeIndexTab}
                        >
                            {tab.label}
                        </TabLabel>
                    ))}
                </TabsLabel>
            )}
            <div className={cx('tab')}>{availableTabs[activeIndexTab].node}</div>
        </div>
    );
});

export default PriceMatrixWithMap;
