import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchShipperContractsPage,
    fetchShipperContractsPageBegin,
    fetchShipperContractsPageError,
    fetchShipperContractsPageSuccess,
    resetShipperContracts,
} from './slice';
import { FetchShipperContractsActionT } from './types';
import { CompanyTypeEnum } from 'common/constants';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import { shipperContractsPaginationChannel } from 'common/store/shipper-contracts/channels';
import { selectShipperContractsPages, selectShipperContractsQuery } from 'common/store/shipper-contracts/selectors';

function getFetchCarrierContractsSaga(companyType: CompanyTypeEnum) {
    return function* (action: FetchShipperContractsActionT): WrapGeneratorT<void> {
        const { pageNumber, query: rawQuery, partnerId } = action.payload;

        const query: typeof rawQuery = {
            ...rawQuery,
        };

        const prevQuery: ReReturnT<typeof selectShipperContractsQuery> = yield select(
            selectShipperContractsQuery(partnerId),
        );
        const pages: ReReturnT<typeof selectShipperContractsPages> = yield select(
            selectShipperContractsPages(partnerId),
        );
        const isSameQuery = checkIsSameQuery(query, prevQuery);
        const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
        if (isSameQuery && !isNeedRequest) {
            return;
        }
        if (!isSameQuery) {
            yield put(resetShipperContracts({ partnerId, mutationListOptions: { savingPageNumber: pageNumber } }));
        }

        yield put(fetchShipperContractsPageBegin({ query, pageNumber, partnerId }));

        let result: ReturnApiT<typeof brokerTranziitApi.fetchPartnerShipperContracts>;
        if (checkIsDefaultCompanyId(partnerId)) {
            result = yield shipperTranziitApi.fetchPartnerShipperContracts({
                ...query,
                page: pageNumber,
            });
        } else {
            result = yield brokerTranziitApi.fetchPartnerShipperContracts(partnerId, {
                ...query,
                page: pageNumber,
            });
        }

        const [error, response] = result;

        if (error) {
            yield put(fetchShipperContractsPageError({ query, pageNumber, error, partnerId }));
            return;
        }

        yield put(fetchShipperContractsPageSuccess({ query, pageNumber, response, partnerId }));

        checkShouldEmitChangePage(pageNumber, response, shipperContractsPaginationChannel);
    };
}

function* shipperContractsSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchShipperContractsPage.type, getFetchCarrierContractsSaga(companyType));
}

export { shipperContractsSaga };
