import * as React from 'react';

import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './QuickActions.scss';

import Button from 'common/components/Button/Button';
import { useSelector } from 'react-redux';
import { selectBestOffers } from 'common/store/order-creation/selectors';
import { PriceOfferT } from 'common/store/order-creation/models';
import history from 'common/utils/history';
import { CurrencyEnum, DEFAULT_ICON_SIZE, QueryKeysEnum, StyleGuideColorsEnum } from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';
import Money from 'common/components/Money/Money';
import { useTranslation } from 'react-i18next';
import { formatQuery } from 'common/utils/query';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import ThumbUpIcon from 'common/icons/ThumbUpIcon';
import UrgentIcon from 'common/icons/UrgentIcon';
import { getDateFromISO } from 'common/utils/time';

const cx = classNames.bind(styles);

type QueryT = {};

type PropsT = {
    className?: string;
    isPartialLoadingOffers?: boolean;
};

type QuickActionConfigT = {
    offer: PriceOfferT | null;
    testSelector: string;
    icon: React.ReactNode;
    title: React.ReactNode;
};

const PRICE_SEPARATOR = ' - ';

const QuickActions: React.FC<PropsT> = React.memo((props) => {
    const { className, isPartialLoadingOffers } = props;

    const { t } = useTranslation();

    const query = useQuery<QueryT>();

    const bestOffers = useSelector(selectBestOffers);

    const isLoading = isPartialLoadingOffers || !bestOffers;

    const selectOffer = (priceOffer: PriceOfferT | null): void => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.orderCreationPickUpDate]: getDateFromISO(priceOffer?.pickupDockingHoursFrom),
                [QueryKeysEnum.orderCreationDropOffDate]: getDateFromISO(priceOffer?.dropOffDockingHoursFrom),
                [QueryKeysEnum.orderCreationPriceOfferId]: priceOffer?.id,
            }),
        });
    };

    const loaderIconNode = (
        <ControlLoaderIcon opacity={1} size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.light} />
    );

    const actionsConfig: Array<QuickActionConfigT> = [
        {
            offer: bestOffers?.bestPrice || null,
            testSelector: 'quick-actions_best-price',
            icon: (
                <ThumbUpIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.brandDark}
                    strokeColor={StyleGuideColorsEnum.brandDark}
                />
            ),
            title: (
                <span>
                    {t('common:new-order-offers.quick-actions.best-price')}
                    {bestOffers?.bestPrice && (
                        <>
                            {PRICE_SEPARATOR}
                            <Money isRound amount={bestOffers.bestPrice.price} currency={CurrencyEnum.EUR} />
                        </>
                    )}
                </span>
            ),
        },
        {
            offer: bestOffers?.urgent || null,
            testSelector: 'quick-actions_urgent',
            icon: <UrgentIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.squash} />,
            title: (
                <span>
                    {t('common:new-order-offers.quick-actions.urgent')}
                    {bestOffers?.urgent && (
                        <>
                            {PRICE_SEPARATOR}
                            <Money isRound amount={bestOffers.urgent.price} currency={CurrencyEnum.EUR} />
                        </>
                    )}
                </span>
            ),
        },
    ];

    const renderIcon = (actionConfig: QuickActionConfigT): React.ReactNode => {
        if (isLoading) {
            return loaderIconNode;
        }

        if (actionConfig.offer) {
            return actionConfig.icon;
        }

        return null;
    };

    const renderTitle = (actionConfig: QuickActionConfigT): React.ReactNode => {
        if (!isLoading && !actionConfig.offer) {
            return null;
        }

        return actionConfig.title;
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            {actionsConfig.map((actionConfig, index) => {
                if (!isLoading && !actionConfig.offer) {
                    return <div className={cx('action')} key={index} />;
                }

                return (
                    <div className={cx('action')} key={index}>
                        <Button
                            className={cx('button')}
                            type="button"
                            isNarrow
                            isDisabled={actionConfig.offer?.isExpired}
                            onClick={() => {
                                if (actionConfig.offer) {
                                    selectOffer(actionConfig.offer);
                                }
                            }}
                            testSelector={actionConfig.testSelector}
                            leftIcon={renderIcon(actionConfig)}
                        >
                            {renderTitle(actionConfig)}
                        </Button>
                    </div>
                );
            })}
        </div>
    );
});

export default QuickActions;
