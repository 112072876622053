import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const FuelIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={fillColor} d="M5.5 15.25H10V10H5.5z" />
                <path
                    fill={strokeColor}
                    d="M10 3.25H5.5c-.825 0-1.5.675-1.5 1.5v12h7.5v-5.625h1.125v3.75a1.876 1.876 0 003.75 0V7.75c0-.518-.21-.99-.547-1.327l.007-.008-2.79-2.79-.795.795 1.582 1.583a1.867 1.867 0 00-1.207 1.747 1.876 1.876 0 002.625 1.717v5.408c0 .412-.338.75-.75.75s-.75-.338-.75-.75V11.5c0-.825-.675-1.5-1.5-1.5h-.75V4.75c0-.825-.675-1.5-1.5-1.5zm0 7.875v4.125H5.5V10H10v1.125zM10 8.5H5.5V4.75H10V8.5zm4.5 0c-.412 0-.75-.338-.75-.75s.338-.75.75-.75.75.338.75.75-.338.75-.75.75z"
                />
            </g>
        </svg>
    );
};

FuelIcon.displayName = 'FuelIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default FuelIcon;
