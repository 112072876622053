import {
    FETCH_RFQ_DETAILS_REQUEST,
    FETCH_RFQ_DETAILS_REQUEST_BEGIN,
    FETCH_RFQ_DETAILS_REQUEST_ERROR,
    FETCH_RFQ_DETAILS_REQUEST_SUCCESS,
    FetchRFQDetailsRequestActionT,
    FetchRFQDetailsRequestBeginActionT,
    FetchRFQDetailsRequestErrorActionT,
    FetchRFQDetailsRequestSuccessActionT,
} from './types';
import { RFQDetailsT, RFQPriceOfferT } from './models';

export const fetchRFQDetails = (id: TransportOrderIdT, options?: FetchOptionsT): FetchRFQDetailsRequestActionT => ({
    type: FETCH_RFQ_DETAILS_REQUEST,
    id,
    options,
});

export const fetchRFQDetailsBegin = (id: TransportOrderIdT): FetchRFQDetailsRequestBeginActionT => ({
    type: FETCH_RFQ_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchRFQDetailsSuccess = (
    id: TransportOrderIdT,
    details: RFQDetailsT | null,
    defaultOffer: RFQPriceOfferT | null,
): FetchRFQDetailsRequestSuccessActionT => ({
    type: FETCH_RFQ_DETAILS_REQUEST_SUCCESS,
    details,
    defaultOffer,
    id,
});

export const fetchRFQDetailsError = (id: TransportOrderIdT, error: Error): FetchRFQDetailsRequestErrorActionT => ({
    type: FETCH_RFQ_DETAILS_REQUEST_ERROR,
    error,
    id,
});
