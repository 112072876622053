import { DictAdditionalServiceT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { ApiDictAdditionalServiceT } from 'common/utils/api/models';

export const FETCH_ADDITIONAL_SERVICES = 'common/additional-services-dict/FETCH_ADDITIONAL_SERVICES';
export const FETCH_ADDITIONAL_SERVICES_BEGIN = 'common/additional-services-dict/FETCH_ADDITIONAL_SERVICES_BEGIN';
export const FETCH_ADDITIONAL_SERVICES_SUCCESS = 'common/additional-services-dict/FETCH_ADDITIONAL_SERVICES_SUCCESS';
export const FETCH_ADDITIONAL_SERVICES_ERROR = 'common/additional-services-dict/FETCH_ADDITIONAL_SERVICES_ERROR';

export type FetchAdditionalServicesActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES;
};

export type FetchAdditionalServicesBeginActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_BEGIN;
};

export type FetchAdditionalServicesSuccessActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_SUCCESS;
    additionalServices: Record<ApiDictAdditionalServiceT['type'], ApiDictAdditionalServiceT[]> | null;
};

export type FetchAdditionalServicesErrorActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_ERROR;
    error: Error;
};

export type AdditionalServicesDictActionT =
    | FetchAdditionalServicesActionT
    | FetchAdditionalServicesBeginActionT
    | FetchAdditionalServicesSuccessActionT
    | FetchAdditionalServicesErrorActionT;

export type AdditionalServicesDictStateT = {
    byType: Record<ApiDictAdditionalServiceT['type'], ApiDictAdditionalServiceT[]> | null;
    byEnum: Record<DictAdditionalServiceT['enum'], DictAdditionalServiceT> | null;
    byId: Record<DictAdditionalServiceT['id'], DictAdditionalServiceT> | null;
    fetchRequest: RequestStatusT;
};
