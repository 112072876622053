import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor?: StyleGuideColorsEnum;
    size: number;
    className?: string;
};

const DoneIcon: React.FC<PropsT> = (props) => {
    const { fillColor, size, ...restProps } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 40 40" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M2 2h36v36H2z" />
                <path fill={fillColor} d="M15.5 26.3L9.2 20l-2.1 2.1 8.4 8.4 18-18-2.1-2.1z" />
            </g>
        </svg>
    );
};

DoneIcon.displayName = 'DoneIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default DoneIcon;
