import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const RightArrowIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M19 1H1v18h18z" />
                <path fill={fillColor} d="M4 9.25h9.128L8.935 5.058 10 4l6 6-6 6-1.057-1.057 4.185-4.193H4z" />
            </g>
        </svg>
    );
};

RightArrowIcon.displayName = 'RightArrowIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default RightArrowIcon;
