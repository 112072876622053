import * as React from 'react';
import { checkValuesChanges } from 'common/utils/form-helpers';

type FormValueT = object | number | string | boolean | null | undefined | Array<number> | Array<string>;
type FormValuesT = Record<string, FormValueT>;

export const createUseWatchAnyFieldValueChanges =
    <T extends FormValuesT>(fields: Array<keyof T>) =>
    (initValues: T, values: T): boolean => {
        const hasChanges = React.useMemo(() => {
            return checkValuesChanges(fields, initValues, values);
        }, [fields, initValues, values]);

        return hasChanges;
    };
