import * as React from 'react';

import { OrderT } from 'common/store/orders/models';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';

type PropsT = {
    order: OrderT;
};

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return <OrderStatusPill isSymmetrical status={order?.status} />;
});

export default StatusCell;
