import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import styles from './SideBarLayout.scss';
import ContentRootContext from 'common/layouts/LeftMenuLayout/contexts/content-root-context';

const cx = classNames.bind(styles);

export enum SideBarPositionEnum {
    left = 'left',
    right = 'right',
}

export enum SideBarSizeEnum {
    regular = 'regular',
    narrow = 'narrow',
}

type PropsT = {
    size?: SideBarSizeEnum;
    position?: SideBarPositionEnum;
};

const SideBarLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, size = SideBarSizeEnum.regular } = props;

    const position = props.position || SideBarPositionEnum.left;

    const contentRootNode = React.useContext(ContentRootContext);
    if (!contentRootNode) {
        return null;
    }

    return createPortal(
        <div
            className={cx('side-bar', {
                'side-bar--isShow': true,
                [`side-bar--size-${size}`]: true,
                [`side-bar--position-${position}`]: true,
            })}
        >
            <div className={cx('content')}>{children}</div>
        </div>,
        contentRootNode,
    );
});

export default SideBarLayout;
