import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TabLabel.scss';
import { TabsThemesEnum } from '../constants';

const cx = classNames.bind(styles);

export enum TabLabelSizeEnum {
    normal = 'normal',
    small = 'small',
}

export type PropsT<ValueT> = {
    className?: string;
    onClick: () => void;
    isActive: boolean;
    isCompact?: boolean;
    testSelector?: string;
    theme: TabsThemesEnum;
    children: React.ReactNode | string;
    size?: TabLabelSizeEnum;
    badge?: React.ReactNode;
};

const TabLabel = <ValueT,>(props: PropsT<ValueT>): React.ReactElement => {
    const { className, children, onClick, isActive, isCompact, theme, testSelector, size, badge } = props;

    let content = children;

    if (badge) {
        content = (
            <div className={cx('inner')}>
                <div className={cx('inner__badge')}>{badge}</div>
                <div className={cx('inner__content')}>{children}</div>
            </div>
        );
    }

    return (
        <div
            className={cs(
                cx('tab', {
                    'tab--isActive': isActive,
                    'tab--isCompact': isCompact,
                    [`tab--size-${size || TabLabelSizeEnum.normal}`]: true,
                    [`tab--theme-${theme}`]: true,
                }),
                className,
            )}
            onClick={onClick}
            data-test-selector={`${testSelector}_tab`}
        >
            {content}
        </div>
    );
};

export default TabLabel;
