import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './DropdownControlOptionLabel.scss';

const cx = classNames.bind(styles);

type PropsT = {
    label: React.ReactNode;
    icon?: React.ReactNode;
    withoutPaddings?: boolean;
    description?: React.ReactNode;
    isSelected?: boolean;
    isDisabled?: boolean;
};

const DropdownControlOptionLabel: React.FC<PropsT> = (props) => {
    const { label, icon, description, isSelected, isDisabled, withoutPaddings } = props;

    return (
        <div
            className={cx('option', {
                'option--isSelected': isSelected,
                'option--withoutPaddings': withoutPaddings,
            })}
        >
            {icon && <div className={cx('option__icon')}>{icon}</div>}
            <div
                className={cx('option__label', {
                    'option__label--isDisabled': !!isDisabled,
                })}
            >
                {label}
            </div>
            {!!description && (
                <>
                    <div className={cx('option__spacer')} />
                    <div className={cx('option__description')}>{description}</div>
                </>
            )}
        </div>
    );
};

export default DropdownControlOptionLabel;
