import * as React from 'react';
import GoogleMapContext from 'common/contexts/google-map-context';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM } from 'common/store/constants';
import GoogleMapReact from 'google-map-react';

type PropsT = {};

const GoogleMapsLoaderWithoutMaps: React.FC<PropsT> = React.memo(() => {
    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    return (
        <div style={{ height: '1px', width: '1px', visibility: 'hidden' }}>
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                onGoogleApiLoaded={apiIsLoaded}
            />
        </div>
    );
});

export { GoogleMapsLoaderWithoutMaps };
