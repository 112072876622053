import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    // eslint-disable-next-line react/no-unused-prop-types
    key?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
    isWrong?: boolean;
    className?: string;
};

const DestinationPinIcon: React.FC<PropsT> = (props) => {
    const { isWrong, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="45" viewBox="0 0 40 45" className={className}>
            <defs>
                <filter
                    id="r6d1i0clwa"
                    width="390%"
                    height="357.8%"
                    x="-145%"
                    y="-128.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="15" />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#r6d1i0clwa)"
                            transform="translate(-1160 -851) translate(569) translate(591 851)"
                        >
                            <path
                                fill={isWrong ? StyleGuideColorsEnum.tomatoRed : StyleGuideColorsEnum.blazeOrange}
                                d="M25 0c8.284 0 15 6.716 15 15v10c0 8.284-6.716 15-15 15l-5 5-5-5C6.716 40 0 33.284 0 25V15C0 6.716 6.716 0 15 0h10z"
                            />
                            <path
                                fill="#FFF"
                                fillRule="nonzero"
                                d="M20.859 30c2.358-.018 4.138-.52 5.34-1.504C27.398 27.51 28 26.039 28 24.08c0-1.08-.27-1.997-.811-2.748-.541-.75-1.317-1.263-2.327-1.538.895-.348 1.573-.888 2.034-1.62.461-.734.692-1.617.692-2.652 0-1.795-.65-3.164-1.948-4.107-1.3-.943-3.194-1.415-5.685-1.415H13v20h7.859zm-.758-11.662h-2.433v-4.615h2.287c1.046 0 1.804.185 2.274.556.47.37.705.977.705 1.82 0 .733-.218 1.287-.652 1.662-.434.375-1.161.568-2.18.577zm.625 7.953h-3.058v-4.794h3.27c1.605.01 2.408.847 2.408 2.514 0 .723-.226 1.284-.678 1.683-.453.398-1.1.597-1.942.597z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

DestinationPinIcon.displayName = 'DestinationPinIcon';

const storyProps: PropsT = {};

export { storyProps };
export default DestinationPinIcon;
