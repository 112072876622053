import { VehicleIdT, VehicleScheduleT } from './models';

import { RequestStatusT } from 'common/utils/request-status';
import { SetUnavailableVehicleQueryT } from 'common/utils/api/models';

export const FETCH_VEHICLE_SCHEDULE_REQUEST = 'common/vehicle-schedules/FETCH_VEHICLE_SCHEDULE_REQUEST';
export const FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN = 'common/vehicle-schedules/FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN';
export const FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS = 'common/vehicle-schedules/FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS';
export const FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR = 'common/vehicle-schedules/FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR';
export const SET_UNAVAILABLE_VEHICLE_REQUEST = 'common/vehicle-schedules/SET_UNAVAILABLE_VEHICLE_REQUEST';
export const SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN = 'common/vehicle-schedules/SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN';
export const SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR = 'common/vehicle-schedules/SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR';
export const SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS =
    'common/vehicle-schedules/SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS';

export type SetUnavailableVehicleRequestActionT = {
    type: typeof SET_UNAVAILABLE_VEHICLE_REQUEST;
    query: SetUnavailableVehicleQueryT;
    companyId: CompanyIdT;
};

export type SetUnavailableVehicleRequestBeginActionT = {
    type: typeof SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN;
};

export type SetUnavailableVehicleRequestErrorActionT = {
    type: typeof SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR;
    error: Error;
};

export type SetUnavailableVehicleRequestSuccessActionT = {
    type: typeof SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS;
};

export type FetchVehicleScheduleActionT = {
    type: typeof FETCH_VEHICLE_SCHEDULE_REQUEST;
    vehicleId: VehicleIdT;
    options?: FetchOptionsT;
};

export type FetchVehicleScheduleBeginActionT = {
    type: typeof FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN;
    vehicleId: VehicleIdT;
};

export type FetchVehicleScheduleSuccessActionT = {
    type: typeof FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS;
    vehicleId: VehicleIdT;
    schedule: VehicleScheduleT;
};

export type FetchVehicleScheduleErrorActionT = {
    type: typeof FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR;
    error: Error;
    vehicleId: VehicleIdT;
};

export type VehicleSchedulesActionT =
    | SetUnavailableVehicleRequestActionT
    | SetUnavailableVehicleRequestBeginActionT
    | SetUnavailableVehicleRequestErrorActionT
    | SetUnavailableVehicleRequestSuccessActionT
    | FetchVehicleScheduleActionT
    | FetchVehicleScheduleBeginActionT
    | FetchVehicleScheduleSuccessActionT
    | FetchVehicleScheduleErrorActionT;

export type VehicleScheduleStateT = {
    schedule: VehicleScheduleT;
    fetchRequestStatus: RequestStatusT;
};

export type VehicleSchedulesStateT = {
    setUnavailableRequest: RequestStatusT;
    byId: Record<VehicleIdT, VehicleScheduleStateT>;
};
