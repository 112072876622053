import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TableLoader.scss';
import { StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const TableLoader: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    return (
        <div className={className} data-test-selector="table-loader">
            <div className={cx('skeleton__box', 'skeleton__box--table-head')} />
            <div className={cx('skeleton__box', 'skeleton__box--table-row')} />
            <div className={cx('skeleton__box', 'skeleton__box--table-row')} />
            <div className={cx('skeleton__box', 'skeleton__box--table-row')} />
            <div className={cx('loader')}>
                <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} />
            </div>
        </div>
    );
});

export default TableLoader;
