import { FieldsEnum, FormValuesT, RateSourceEnum } from './constants';
import { AssetDataT } from 'common/layouts/dialogs/ActivateAssetDialogModal/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';

export const prepareRate = (assets: Array<AssetDataT>, values: FormValuesT): Array<number | null> => {
    return assets.map(() => {
        if (values[FieldsEnum.source] === RateSourceEnum.current) {
            return null;
        }

        return parseSimpleNumber(values[FieldsEnum.rate]) || null;
    });
};
