import { ApiTruckDictTypeT, CountryCodeT } from 'common/utils/api/models';

export enum FieldsEnum {
    truckModel = 'truckModel',
    cabinColor = 'cabinColor',
    countryCode = 'countryCode',
    plateNumber = 'plateNumber',
    vin = 'vin',
    contract = 'contract',
    ratePerKm = 'ratePerKm',
}

export type FormValuesT = {
    [FieldsEnum.truckModel]: ApiTruckDictTypeT['id'] | null;
    [FieldsEnum.cabinColor]: string;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.plateNumber]: string;
    [FieldsEnum.vin]: string;
    [FieldsEnum.contract]: CarrierContractIdT | null;
    [FieldsEnum.ratePerKm]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
