import React from 'react';

import classNames from 'classnames/bind';
import styles from './LanguageSelector.scss';
import { useTranslation } from 'react-i18next';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { selectCountriesAllLangCodes, selectCountriesByLangCode } from 'common/store/countries-dict/selectors';
import { byAlphabetASC } from 'common/utils/sort';

const cx = classNames.bind(styles);

type OptionT = string;
type ValueT = string | null | undefined;

type PropsT = {
    onChange: (langCode: ValueT) => void;
    value?: ValueT;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
};

const LanguageSelector: React.FC<PropsT> = (props) => {
    const { onChange, value, overlayPosition, className, hasError, hasWarning, hasChanges, onBlur, onFocus } = props;

    const { t } = useTranslation();

    const allLangCodes = useSelector(selectCountriesAllLangCodes);
    const byLangCode = useSelector(selectCountriesByLangCode);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const renderOption = (langCode: OptionT | null | undefined): React.ReactElement | null => {
        if (!langCode) {
            return null;
        }

        const countryLanguage = byLangCode[langCode];

        return <span>{countryLanguage?.name || '-'}</span>;
    };

    const sortedAllLangCodes = React.useMemo(() => {
        return [...allLangCodes].sort((codeA, codeB) => {
            const langA = byLangCode[codeA];
            const langB = byLangCode[codeB];

            return byAlphabetASC(langA.name, langB.name);
        });
    }, [allLangCodes, byLangCode]);

    const getOptionValue = (option: OptionT): ValueT => option;

    const handleOptionClick = (langCode: ValueT) => {
        onChange(langCode);
    };

    return (
        <DropdownInput
            className={className}
            selectedValue={value}
            placeholder={t('common:language-selector.placeholder')}
            options={sortedAllLangCodes}
            onSelect={handleOptionClick}
            hasError={hasError}
            hasWarning={hasWarning}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
};

export default LanguageSelector;
