import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

function useEqualPreviousValue<ValueT>(value: ValueT): ValueT {
    const [savedValue, setSavedValue] = useState<ValueT>(value);

    // Store current value in ref
    useEffect(() => {
        if (!isEqual(savedValue, value)) {
            setSavedValue(value);
        }
    }, [value]); // Only re-run if value changes

    return savedValue;
}

export { useEqualPreviousValue };
