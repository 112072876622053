import * as React from 'react';

type PropsT = {};

const FilterIcon: React.FC<PropsT> = (props) => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill="#3E3F42" d="M8.5 14.5h3V13h-3v1.5zm-5.25-9V7h13.5V5.5H3.25zm2.25 5.25h9v-1.5h-9v1.5z" />
            </g>
        </svg>
    );
};

FilterIcon.displayName = 'FilterIcon';

const storyProps: PropsT = {};

export { storyProps };
export default FilterIcon;
