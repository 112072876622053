import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TrailerType.scss';
import { ApiTrailerTypeT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = {
    trailerType: ApiTrailerTypeT | undefined;
    className?: string;
};

const TrailerType: React.FC<PropsT> = (props) => {
    const { trailerType, className } = props;

    return (
        <div
            className={cs(
                cx('icon', {
                    [`icon--${trailerType}`]: true,
                }),
                className,
            )}
        />
    );
};

export default TrailerType;
