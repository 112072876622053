import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DocumentTypeCell.scss';

type PropsT = {
    name: string;
    isEmpty: boolean;
};

const cx = classNames.bind(styles);

const DocumentTypeCell: React.FC<PropsT> = React.memo((props) => {
    const { name, isEmpty } = props;

    return (
        <div
            className={cx('type', {
                'type--is-empty': isEmpty,
            })}
        >
            {name}
        </div>
    );
});

export default DocumentTypeCell;
