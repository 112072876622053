import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const PaperIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={fillColor} d="M5 3v14h10V7l-4-4z" />
                <path
                    fill={strokeColor}
                    d="M7 13h6v1.5H7V13zm0-3h6v1.5H7V10zm4.5-7.5h-6C4.675 2.5 4 3.175 4 4v12c0 .825.668 1.5 1.492 1.5H14.5c.825 0 1.5-.675 1.5-1.5V7l-4.5-4.5zm3 13.5h-9V4h5.25v3.75h3.75V16z"
                />
            </g>
        </svg>
    );
};

PaperIcon.displayName = 'PaperIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PaperIcon;
