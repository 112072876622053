import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { RevokeShipperContractConfirmationDataT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { StyleGuideColorsEnum } from 'common/constants';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { FieldsEnum, FormValuesT } from './constants';
import Textarea from 'common/components/Textarea/Textarea';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import { useFormik } from 'formik';
import { prepareData } from './prepare-reject-data';
import { logWarning } from 'common/utils/logger';
import classNames from 'classnames/bind';
import styles from './RevokeShipperContractConfirmation.scss';

const cx = classNames.bind(styles);

type PropsT = {
    data: RevokeShipperContractConfirmationDataT | null;
    requestStatus: RequestStatusT;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (reason: string, data: RevokeShipperContractConfirmationDataT | null) => void;
};

const TEST_SELECTOR = 'revoke-shipper-contract-confirmation';

const RevokeShipperContractConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, requestStatus, onClose, onCancel, onConfirm } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const preparedData = prepareData(values);

            if (!data) {
                logWarning('failed to reject document, empty data');
                return;
            }

            onConfirm(preparedData.reason, data);

            formikHelpers.setTouched({});
        },
    });

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <form onSubmit={formik.handleSubmit}>
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.keep'),
                                theme: ButtonThemeEnum.secondary,
                                testSelector: `${TEST_SELECTOR}_cancel`,
                                isDisabled: requestStatus.loading,
                                onClick: onCancel,
                            },
                            {
                                children: t('common:error-modal.actions.revoke'),
                                theme: ButtonThemeEnum.danger,
                                isDisabled: requestStatus.loading,
                                isLoading: requestStatus.loading,
                                testSelector: `${TEST_SELECTOR}_ok`,
                                type: 'submit',
                            },
                        ]}
                        icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                        title={t('common:shipper-contract-details.revoke-shipper-contract-confirmation.title', {
                            name: data.shipperContractName,
                        })}
                        message={t('common:shipper-contract-details.revoke-shipper-contract-confirmation.description')}
                        body={
                            <>
                                <FormikField
                                    name={FieldsEnum.reason}
                                    error={formik.errors[FieldsEnum.reason]}
                                    meta={formik.getFieldMeta(FieldsEnum.reason)}
                                    label={t(
                                        'common:shipper-contract-details.revoke-shipper-contract-confirmation.fields.reason.label',
                                    )}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Textarea
                                            className={cx('textarea')}
                                            name={FieldsEnum.reason}
                                            value={formik.values[FieldsEnum.reason]}
                                            placeholder={t(
                                                'common:shipper-contract-details.revoke-shipper-contract-confirmation.fields.reason.placeholder',
                                            )}
                                            onChange={props.onChange}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                        />
                                    )}
                                </FormikField>
                            </>
                        }
                    />
                </form>
            </ModalContent>
        </Modal>
    );
});

export default RevokeShipperContractConfirmation;
