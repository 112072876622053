import {
    FetchTrucksDictActionT,
    FetchTrucksDictBeginActionT,
    FetchTrucksDictErrorActionT,
    FetchTrucksDictSuccessActionT,
    TRUCKS_DICT_REQUEST,
    TRUCKS_DICT_REQUEST_BEGIN,
    TRUCKS_DICT_REQUEST_ERROR,
    TRUCKS_DICT_REQUEST_SUCCESS,
} from './types';

import { ApiTrucksDictT } from 'common/utils/api/models';

export const fetchTrucksDict = (): FetchTrucksDictActionT => ({
    type: TRUCKS_DICT_REQUEST,
});

export const fetchTrucksDictBegin = (): FetchTrucksDictBeginActionT => ({
    type: TRUCKS_DICT_REQUEST_BEGIN,
});

export const fetchTrucksDictSuccess = (trucksDict: ApiTrucksDictT): FetchTrucksDictSuccessActionT => ({
    type: TRUCKS_DICT_REQUEST_SUCCESS,
    trucksDict,
});

export const fetchTrucksDictError = (error: Error): FetchTrucksDictErrorActionT => ({
    type: TRUCKS_DICT_REQUEST_ERROR,
    error,
});
