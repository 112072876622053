import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ExcludedCountry.scss';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import { CountryCodeT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    countryCode: CountryCodeT;
    countryLabel?: string;
};

const ExcludedCountry: React.FC<PropsT> = React.memo((props) => {
    const { countryCode, className, countryLabel } = props;

    return (
        <div className={className}>
            <Tooltip
                tooltipNode={
                    countryLabel ? (
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {countryLabel}
                        </TooltipContent>
                    ) : null
                }
                position={TooltipPositionEnum.topCenter}
                theme={TooltipThemeEnum.black}
            >
                {(isShow, childrenClassName) => (
                    <div
                        className={cs(
                            cx('flag-wrap', {
                                'flag-wrap--is-show': isShow,
                            }),
                            childrenClassName,
                        )}
                    >
                        <FlagIcon countryCode={countryCode} className={cx('flag')} />
                    </div>
                )}
            </Tooltip>
        </div>
    );
});

export default ExcludedCountry;
