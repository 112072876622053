import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LocationCell.scss';

type PropsT = {
    address: React.ReactNode;
    time: React.ReactNode;
    isSmallTimeFont?: boolean;
};

const cx = classNames.bind(styles);

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { address, time, isSmallTimeFont } = props;

    return (
        <div>
            <div className={cx('address')}>{address}</div>
            <div className={cx('time', { 'time--isSmallFont': isSmallTimeFont })}>{time}</div>
        </div>
    );
});

export default LocationCell;
