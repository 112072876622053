import { put, select } from 'redux-saga/effects';
import { fetchCountriesDictBegin, fetchCountriesDictError, fetchCountriesDictSuccess } from './actions';
import { COUNTRIES_DICT_REQUEST, FetchCountriesDictActionT } from './types';
import { selectCountriesDictRequest } from './selectors';
import { byAlphabetASC, getCountryCompByName } from 'common/utils/sort';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { semaphoreSaga } from 'common/utils/semaphore-saga';

function* fetchCountriesDictSaga(action: FetchCountriesDictActionT): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectCountriesDictRequest> = yield select(selectCountriesDictRequest);
    if (requestStatus.ok || requestStatus.loading) {
        return;
    }

    yield put(fetchCountriesDictBegin());

    const [error, countriesDict]: ReturnApiT<typeof commonTranziitApi.getCountries> =
        yield commonTranziitApi.getCountries();

    const sortedCountriesDict = (countriesDict || []).sort(getCountryCompByName(byAlphabetASC));
    if (error) {
        yield put(fetchCountriesDictError(error));
    } else {
        yield put(fetchCountriesDictSuccess(sortedCountriesDict));
    }
}

function* countriesSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(COUNTRIES_DICT_REQUEST, fetchCountriesDictSaga);
}

export default countriesSaga;
