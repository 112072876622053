import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { ApiGroupedNotificationCountsT } from 'common/utils/api/models';

export const selectGroupedNotificationCounts = (state: SharableStateT): ApiGroupedNotificationCountsT | null => {
    return state.groupedNotificationCounts.counts;
};

export const selectGroupedNotificationCountsRequest = (state: SharableStateT): RequestStatusT => {
    return state.groupedNotificationCounts.fetchRequest;
};
