import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './SideBarLoader.scss';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isRelative?: boolean;
    message?: React.ReactNode;
};

const SideBarLoader: React.FC<PropsT> = React.memo((props) => {
    const { className, isRelative, message } = props;

    return (
        <div
            className={cx('loader', {
                'loader--isRelative': isRelative,
                'loader--isAbsolute': !isRelative,
            })}
        >
            <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} />
            {message && <div className={cx('message')}>{message}</div>}
        </div>
    );
});

export default SideBarLoader;
