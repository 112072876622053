import { TFunction } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import { TimeZonesEnum } from 'common/constants';

export const getTimeZoneLabel = (t: TFunction, timezone: number | undefined): string => {
    if (!isNumber(timezone)) {
        return '';
    }

    switch (timezone) {
        case TimeZonesEnum['UTC-0']: {
            return t('common:time-zones.0');
        }
        case TimeZonesEnum['UTC+1']: {
            return t('common:time-zones.1');
        }
        case TimeZonesEnum['UTC+2']: {
            return t('common:time-zones.2');
        }
        case TimeZonesEnum['UTC+3']: {
            return t('common:time-zones.3');
        }
        case TimeZonesEnum['UTC+4']: {
            return t('common:time-zones.4');
        }
        default: {
            return '';
        }
    }
};
