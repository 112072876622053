import * as React from 'react';
import { TFunction } from 'i18next';
import { ContactDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContactDetailsSidebarContent/models';
import { EditContactDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditContactDetailsSidebarContent/models';
import { DocumentDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/models';
import { ContractDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContractDetailsSidebarContent/models';
import { DriverFiltersSidebarDataT } from 'common/layouts/SideBars/contents/DriverFiltersSidebarContent/models';
import { DriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/models';
import { EditDriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditDriverSidebarContent/models';
import { TrailerFiltersSidebarDataT } from 'common/layouts/SideBars/contents/TrailerFiltersSidebarContent/models';
import { AddTrailerSidebarDataT } from 'common/layouts/SideBars/contents/AddTrailerSidebarContent/models';
import { TrailerDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditableTrailerDetailsSidebarContent/models';
import { TruckFiltersSidebarDataT } from 'common/layouts/SideBars/contents/TruckFiltersSidebarContent/models';
import { AddTruckSidebarDataT } from 'common/layouts/SideBars/contents/AddTruckSidebarContent/models';
import { TruckDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditableTruckDetailsSidebarContent/models';

export enum CommonSidebarsTypeEnum {
    contact = 'contact',
    editContact = 'edit-contact',
    document = 'document',
    carrierContract = 'carrierContract',
    driverFilters = 'driver-filters',
    driverDetails = 'driver-details',
    editDriverDetails = 'edit-driver-details',
    trailerFilters = 'trailer-filters',
    trailerDetails = 'trailer-details',
    addTrailer = 'add-trailer',
    truckFilters = 'truck-filters',
    truckDetails = 'truck-details',
    addTruck = 'add-truck',
}

export type CommonSidebarMetaT = Partial<{
    // NB: try to use unique names for all data sidebars!
    isForceShowBackAction: boolean;
    isNarrowSidebar: boolean;
}>;

export type SidebarDataWithMetaT<T> = T & CommonSidebarMetaT;

export type AnySidebarDataT = {
    type: string;
};

export type CommonSidebarDataT =
    | ContactDetailsSidebarDataT
    | EditContactDetailsSidebarDataT
    | DocumentDetailsSidebarDataT
    | DriverFiltersSidebarDataT
    | DriverDetailsSidebarDataT
    | EditDriverDetailsSidebarDataT
    | TrailerFiltersSidebarDataT
    | AddTrailerSidebarDataT
    | TrailerDetailsSidebarDataT
    | TruckFiltersSidebarDataT
    | AddTruckSidebarDataT
    | TruckDetailsSidebarDataT
    | ContractDetailsSidebarDataT;

export enum SideBarPositionEnum {
    left = 'left',
    right = 'right',
}

export type SidebarContentPropsT<T, V> = CommonSidebarContentPropsT<V> & {
    data: T;
    setNeedCloseConfirmation: (needCloseConfirmation: boolean) => void;
};

export type CloseCallbackOptionsT = { needCloseConfirmation: boolean };

export type CommonSidebarContentPropsT<T> = {
    onOpenNextSidebar: (data: T) => void;
    onClose?: ((options?: CloseCallbackOptionsT) => void) | null;
    onGoBack?: ((options?: CloseCallbackOptionsT) => void) | null;
    position: SideBarPositionEnum;
};

export enum SidebarQueryKeysEnum {
    leftSidebar = 'left-sidebar',
    rightSidebar = 'right-sidebar',
}

export type SidebarCloseConfirmationT = {
    icon?: React.ReactNode;
    title: React.ReactNode;
    message: React.ReactNode;
    confirmAction: React.ReactNode;
    cancelAction: React.ReactNode;
};

export type RenderSidebarContentT<T> = (props: SidebarContentPropsT<T, T>) => React.ReactNode;

export type GetSidebarCloseConfirmationT<T> = (t: TFunction, data: T) => SidebarCloseConfirmationT | null;
