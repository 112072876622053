import { formatPageQuerySort } from 'common/utils/page-sort';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { FetchShipperContractLanesPageQueryT } from 'common/store/shipper-contract-lanes/models';
import isString from 'lodash/isString';
import { PageSortT } from 'common/utils/query';
import { ShipperContractLanesSortEnum } from 'common/utils/api/models';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<ShipperContractLanesSortEnum> | null,
): FetchShipperContractLanesPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

    return {
        textSearch: queryFilters[QueryFiltersKeysEnum.text] || undefined,
        status: queryFilters[QueryFiltersKeysEnum.status] || undefined,

        address: queryFilters[QueryFiltersKeysEnum.address] || undefined,

        dictTrailerId: isString(dictTrailerId) ? +dictTrailerId : undefined,

        createdById: queryFilters[QueryFiltersKeysEnum.createdById] || undefined,

        validFromFrom: queryFilters[QueryFiltersKeysEnum.validFromStart] || undefined,
        validFromTo: queryFilters[QueryFiltersKeysEnum.validFromFinish] || undefined,

        validTillFrom: queryFilters[QueryFiltersKeysEnum.validTillStart] || undefined,
        validTillTo: queryFilters[QueryFiltersKeysEnum.validTillFinish] || undefined,

        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateStart] || undefined,
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateFinish] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
