import { EmissionStandartEnum } from 'common/constants';

export enum QueryFiltersKeysEnum {
    model = 'model',
    plateNumber = 'plateNumber',
    emissionClass = 'emissionClass',
    status = 'status',

    link = 'link',
    GPSTracker = 'gps-tracker',
    dedication = 'dedication',

    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',
    lastModifyDateFrom = 'lastModifyDateFrom',
    lastModifyDateTo = 'lastModifyDateTo',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.plateNumber]: QueryParam.StringT;
    [QueryFiltersKeysEnum.model]: QueryParam.StringT;
    [QueryFiltersKeysEnum.emissionClass]: EmissionStandartEnum;
    [QueryFiltersKeysEnum.status]: QueryParam.StringT;

    [QueryFiltersKeysEnum.link]: QueryParam.StringT;
    [QueryFiltersKeysEnum.GPSTracker]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dedication]: QueryParam.StringT;

    [QueryFiltersKeysEnum.lastModifyDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.lastModifyDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    trucksPage = 'trucks-page',
    trucksSort = 'trucks-sort',
    trucksFilters = 'trucks-filters',
    trucksTab = 'trucks-tab',
}
