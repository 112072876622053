import { SharableStateT } from 'common/store/models';
import { RequestStatusT } from 'common/utils/request-status';
import { initialCompanyDriversState } from './reducer';
import { CompanyDriversStateT, DriverDetailsByIdT } from './types';

const EMPTY_QUERY = {};

const selectCompanyDriversState = (state: SharableStateT, companyId: CompanyIdT): CompanyDriversStateT => {
    return state.drivers[companyId] || initialCompanyDriversState;
};

export const selectDriversPages =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyDriversStateT['pages'] =>
        selectCompanyDriversState(state, companyId).pages;

export const selectDriversById =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyDriversStateT['byId'] =>
        selectCompanyDriversState(state, companyId).byId;

export const selectDriversQuery =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyDriversStateT['query'] =>
        selectCompanyDriversState(state, companyId).query || EMPTY_QUERY;

export const selectDriversTotal =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyDriversStateT['total'] =>
        selectCompanyDriversState(state, companyId).total;

export const selectInviteDriverRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): RequestStatusT =>
        selectCompanyDriversState(state, companyId).inviteRequest;

export const selectDeleteDriverRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): RequestStatusT =>
        selectCompanyDriversState(state, companyId).deleteRequest;

export const selectUpdateDriverRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): RequestStatusT =>
        selectCompanyDriversState(state, companyId).updateRequest;

export const selectFetchDriverDetailsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): RequestStatusT =>
        selectCompanyDriversState(state, companyId).fetchDetailsRequest;

export const selectDriverDetailsById =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): DriverDetailsByIdT =>
        selectCompanyDriversState(state, companyId).detailsById;
