import * as React from 'react';
import { formatDate } from 'common/utils/time';

type PropsT = {
    date: Date | number | string | null | undefined;
    format?: string;
};

const DateFormatter: React.FC<PropsT> = React.memo((props) => {
    const { date, format } = props;

    if (!date) {
        return null;
    }

    const label = formatDate(format || 'DD MMM YYYY', date);
    return <span>{label}</span>;
});

export default DateFormatter;
