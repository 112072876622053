import {
    ShipmentStatusEnum,
    RFQStatusEnum,
    OrderStatusEnum,
    TourStatusEnum,
    TransportOrderStatusEnum,
} from 'common/utils/api/models';
import { NotificationEmotionEnum, NotificationIconEnum } from 'common/store/notifications/models';

export const EMOTION_LOAD_STATUS_MAP: Record<ShipmentStatusEnum, NotificationEmotionEnum> = {
    [ShipmentStatusEnum.awaitingLoading]: NotificationEmotionEnum.neutral,
    [ShipmentStatusEnum.loading]: NotificationEmotionEnum.neutral,
    [ShipmentStatusEnum.inTransit]: NotificationEmotionEnum.neutral,
    [ShipmentStatusEnum.unloading]: NotificationEmotionEnum.neutral,
    [ShipmentStatusEnum.delivered]: NotificationEmotionEnum.positive,
    [ShipmentStatusEnum.canceled]: NotificationEmotionEnum.neutral,
};

export const EMOTION_ORDER_STATUS_MAP: Record<OrderStatusEnum, NotificationEmotionEnum> = {
    [OrderStatusEnum.placed]: NotificationEmotionEnum.neutral,
    [OrderStatusEnum.inProgress]: NotificationEmotionEnum.neutral,
    [OrderStatusEnum.canceled]: NotificationEmotionEnum.neutral,
    [OrderStatusEnum.delivered]: NotificationEmotionEnum.neutral,
    [OrderStatusEnum.done]: NotificationEmotionEnum.positive,
};

export const EMOTION_TOUR_STATUS_MAP: Record<TourStatusEnum, NotificationEmotionEnum> = {
    [TourStatusEnum.carrierUnassigned]: NotificationEmotionEnum.negative,
    [TourStatusEnum.assetsUnassigned]: NotificationEmotionEnum.negative,
    [TourStatusEnum.assigned]: NotificationEmotionEnum.neutral,
    [TourStatusEnum.inTransit]: NotificationEmotionEnum.neutral,
    [TourStatusEnum.finished]: NotificationEmotionEnum.positive,
    [TourStatusEnum.canceled]: NotificationEmotionEnum.neutral,
    [TourStatusEnum.driverUnassigned]: NotificationEmotionEnum.negative,
};

export const EMOTION_TRANSPORTATION_STATUS_MAP: Record<TransportOrderStatusEnum, NotificationEmotionEnum> = {
    [TransportOrderStatusEnum.assetsUnassigned]: NotificationEmotionEnum.negative,
    [TransportOrderStatusEnum.driverUnassigned]: NotificationEmotionEnum.negative,
    [TransportOrderStatusEnum.assigned]: NotificationEmotionEnum.neutral,
    [TransportOrderStatusEnum.inTransit]: NotificationEmotionEnum.neutral,
    [TransportOrderStatusEnum.done]: NotificationEmotionEnum.positive,
    [TransportOrderStatusEnum.canceled]: NotificationEmotionEnum.neutral,
    [TransportOrderStatusEnum.declined]: NotificationEmotionEnum.neutral,
    [TransportOrderStatusEnum.unknown]: NotificationEmotionEnum.neutral,
};

export const EMOTION_ORDER_DRAFT_STATUS_MAP: Record<RFQStatusEnum, NotificationEmotionEnum> = {
    [RFQStatusEnum.open]: NotificationEmotionEnum.neutral,
    [RFQStatusEnum.expireSoon]: NotificationEmotionEnum.attention,
    [RFQStatusEnum.expired]: NotificationEmotionEnum.negative,
    [RFQStatusEnum.completed]: NotificationEmotionEnum.positive,
    [RFQStatusEnum.closed]: NotificationEmotionEnum.neutral,
};

export const ALERT_ICON_LOAD_STATUS_MAP: Record<ShipmentStatusEnum, NotificationIconEnum | null> = {
    [ShipmentStatusEnum.awaitingLoading]: null,
    [ShipmentStatusEnum.loading]: null,
    [ShipmentStatusEnum.inTransit]: null,
    [ShipmentStatusEnum.unloading]: null,
    [ShipmentStatusEnum.delivered]: NotificationIconEnum.success,
    [ShipmentStatusEnum.canceled]: null,
};

export const ALERT_ICON_ORDER_STATUS_MAP: Record<OrderStatusEnum, NotificationIconEnum | null> = {
    [OrderStatusEnum.placed]: null,
    [OrderStatusEnum.inProgress]: null,
    [OrderStatusEnum.canceled]: null,
    [OrderStatusEnum.done]: NotificationIconEnum.success,
    [OrderStatusEnum.delivered]: null,
};

export const ALERT_ICON_TOUR_STATUS_MAP: Record<TourStatusEnum, NotificationIconEnum | null> = {
    [TourStatusEnum.carrierUnassigned]: NotificationIconEnum.fail,
    [TourStatusEnum.assetsUnassigned]: NotificationIconEnum.fail,
    [TourStatusEnum.assigned]: null,
    [TourStatusEnum.inTransit]: null,
    [TourStatusEnum.finished]: NotificationIconEnum.success,
    [TourStatusEnum.canceled]: null,
    [TourStatusEnum.driverUnassigned]: NotificationIconEnum.fail,
};

export const ALERT_ICON_TRANSPORTATION_STATUS_MAP: Record<TransportOrderStatusEnum, NotificationIconEnum | null> = {
    [TransportOrderStatusEnum.driverUnassigned]: NotificationIconEnum.fail,
    [TransportOrderStatusEnum.assetsUnassigned]: NotificationIconEnum.fail,
    [TransportOrderStatusEnum.assigned]: null,
    [TransportOrderStatusEnum.inTransit]: null,
    [TransportOrderStatusEnum.done]: NotificationIconEnum.success,
    [TransportOrderStatusEnum.canceled]: null,
    [TransportOrderStatusEnum.declined]: null,
    [TransportOrderStatusEnum.unknown]: null,
};

export const ALERT_ICON_ORDER_DRAFT_STATUS_MAP: Record<RFQStatusEnum, NotificationIconEnum | null> = {
    [RFQStatusEnum.open]: null,
    [RFQStatusEnum.expireSoon]: NotificationIconEnum.attention,
    [RFQStatusEnum.expired]: NotificationIconEnum.fail,
    [RFQStatusEnum.completed]: NotificationIconEnum.success,
    [RFQStatusEnum.closed]: null,
};
