import { FieldsEnum, FormValuesT } from './constants';
import moment from 'moment';
import { DocumentVersionT } from 'common/store/documents/models';

const getInitialValues = (
    actualVersion: DocumentVersionT | null,
    { isShowExpirationDate }: { isShowExpirationDate: boolean },
): FormValuesT => {
    return {
        [FieldsEnum.expiration]:
            isShowExpirationDate && actualVersion?.validTill ? moment(actualVersion.validTill).toDate() : null,
    };
};

export default getInitialValues;
