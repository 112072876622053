import React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './ShipperContractLaneDetails.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import FieldValue, { EMPTY_VALUE_PLACEHOLDER } from 'common/components/FieldValue/FieldValue';
import EmissionIcon from 'common/icons/EmissionIcon';
import { ShipperContractLaneDetailsT } from 'common/store/shipper-contract-lane-details/models';
import CalendarIcon from 'common/icons/CalendarIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';
import OriginIcon from 'common/icons/OriginIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import DestinationIcon from 'common/icons/DestinationIcon';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    data: ShipperContractLaneDetailsT | null;
};

const DATE_FORMAT = 'DD.MM.YYYY';

const ShipperContractLaneDetails: React.FC<PropsT> = (props) => {
    const { className, data } = props;

    const { t } = useTranslation();

    const trailersDictById = useSelector(selectTrailersDictById) || {};
    const trailerDictModel = data?.dictTrailerId ? trailersDictById[data?.dictTrailerId] : null;

    return (
        <div className={cs(cx('wrap'), className)}>
            <FieldGroup>
                <FieldValue
                    className={cx('field', 'field--trailer-type')}
                    label={t(`common:shipper-lane-details.fields.trailer-type.label`)}
                    icon={
                        <TrailerIcon
                            size={20}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    value={
                        trailerDictModel
                            ? t('common:trailer-subtype-template', {
                                  length: trailerDictModel.length,
                                  model: trailerDictModel.model,
                                  eur1Pallets: trailerDictModel.eur1Pallets,
                                  eur2Pallets: trailerDictModel.eur2Pallets,
                              })
                            : ''
                    }
                />
                <FieldValue
                    icon={<EmissionIcon strokeColor={StyleGuideColorsEnum.brandDark} />}
                    className={cx('field', 'field--emission-class')}
                    label={t('common:shipper-lane-details.fields.emission-type.label')}
                    value={<EmissionClassLabel isCompact emissionClass={data?.emissionClass} />}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={<OriginIcon />}
                    className={cx('field', 'field--location')}
                    label={t('common:shipper-lane-details.fields.origin.label')}
                    value={data?.originFullAddress}
                />
                <FieldValue
                    className={cx('field', 'field--radius')}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    value={<UnitTypeCount count={data?.originRadius} type={UnitTypeEnum.kilometersAbbreviation} />}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={<DestinationIcon />}
                    className={cx('field', 'field--location')}
                    label={t('common:shipper-lane-details.fields.destination.label')}
                    value={data?.destinationFullAddress}
                />
                <FieldValue
                    className={cx('field', 'field--radius')}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    value={<UnitTypeCount count={data?.destinationRadius} type={UnitTypeEnum.kilometersAbbreviation} />}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={
                        <CalendarIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.white}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    className={cx('field', 'field--date')}
                    label={t('common:shipper-lane-details.fields.valid-from.label')}
                    value={data?.validFrom ? moment(data.validFrom).format(DATE_FORMAT) : null}
                />
                <FieldValue
                    icon={
                        <CalendarIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.white}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    className={cx('field', 'field--date')}
                    label={t('common:shipper-lane-details.fields.valid-till.label')}
                    value={data?.validTill ? moment(data.validTill).format(DATE_FORMAT) : null}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    className={cx('field', 'field--limit')}
                    label={t('common:shipper-lane-details.fields.layover-limit.label')}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={300}>
                            {t('common:shipper-lane-details.fields.layover-limit.tooltip')}
                        </TooltipContent>
                    }
                    value={<UnitTypeCount count={data?.maximumLayover} type={UnitTypeEnum.hoursAbbreviation} />}
                />
                <FieldValue
                    className={cx('field', 'field--limit')}
                    label={t('common:shipper-lane-details.fields.orders-left.label')}
                    value={
                        isNumber(data?.ordersLeft) && isNumber(data?.maxNumberOfOrders)
                            ? `${data?.ordersLeft}/${data?.maxNumberOfOrders}`
                            : null
                    }
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    className={cx('field', 'field--external-lane-id')}
                    label={t('common:shipper-lane-details.fields.external-lane-id.label')}
                    value={data?.externalLaneId}
                    placeholder={EMPTY_VALUE_PLACEHOLDER}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:shipper-lane-details.fields.external-lane-id.tooltip')}
                        </TooltipContent>
                    }
                />
                <FieldGroupEmptyItem className={cx('field', 'field--limit')} />
            </FieldGroup>
        </div>
    );
};

export default ShipperContractLaneDetails;
