import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { SEC_IN_HOUR, SEC_IN_MIN } from 'common/utils/time';
import { M_IN_KM } from 'common/utils/distance';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatThousandNumber } from 'common/utils/formatters';
import classNames from 'classnames/bind';
import styles from './MapFooterEstimate.scss';
import MapFooter from 'common/components/maps/MapFooter/MapFooter';
import cs from 'classnames';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    distanceM: number | undefined;
    travelTimeSec: number | undefined;
    isLoading: boolean;
};

const formatDistance = (t: TFunction, distanceM: number | undefined): React.ReactNode => {
    if (distanceM === undefined) {
        return null;
    }

    if (distanceM <= M_IN_KM) {
        return t('common:short-meters', {
            value: formatThousandNumber(Math.round(distanceM)),
        });
    }

    return t('common:short-kilometers', {
        value: formatThousandNumber(Math.round(distanceM / M_IN_KM)),
    });
};

const formatDurationSec = (t: TFunction, durationSec: number | undefined): React.ReactNode => {
    if (durationSec === undefined) {
        return null;
    }

    if (durationSec < SEC_IN_HOUR) {
        const roundedDuration = Math.round(durationSec / SEC_IN_MIN);
        return `${roundedDuration} ${t('common:minutes', {
            postProcess: 'interval',
            count: roundedDuration,
        })}`;
    }

    const roundedDuration = Math.round(durationSec / SEC_IN_HOUR);
    return `${roundedDuration} ${t('common:hours', {
        postProcess: 'interval',
        count: Math.round(durationSec / SEC_IN_HOUR),
    })}`;
};

const MapFooterEstimate: React.FC<PropsT> = React.memo((props) => {
    const { className, isLoading, distanceM, travelTimeSec } = props;

    const { t } = useTranslation();

    const hasMetrics = isNumber(distanceM) && isNumber(travelTimeSec);
    if (!hasMetrics && !isLoading) {
        return null;
    }

    return (
        <MapFooter>
            <div className={cs(cx('estimate'), className)}>
                {isLoading && (
                    <div className={cx('loader')}>
                        <ControlLoaderIcon
                            className={cx('loader-icon')}
                            fillColor={StyleGuideColorsEnum.charcoal}
                            size={DEFAULT_ICON_SIZE}
                        />
                        <span className={cx('loader-text')}>{t('common:maps.loading')}</span>
                    </div>
                )}
                {hasMetrics && !isLoading && (
                    <span>
                        {formatDistance(t, distanceM)}
                        {' / ~'}
                        {formatDurationSec(t, travelTimeSec)}
                    </span>
                )}
            </div>
        </MapFooter>
    );
});

export default MapFooterEstimate;
