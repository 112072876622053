import {
    ADD_MEMBER_REQUEST_BEGIN,
    ADD_MEMBER_REQUEST_ERROR,
    ADD_MEMBER_REQUEST_SUCCESS,
    CompanyMembersStateT,
    DELETE_MEMBER_REQUEST_BEGIN,
    DELETE_MEMBER_REQUEST_ERROR,
    DELETE_MEMBER_REQUEST_SUCCESS,
    FETCH_CONTACT_DETAILS_REQUEST_BEGIN,
    FETCH_CONTACT_DETAILS_REQUEST_ERROR,
    FETCH_CONTACT_DETAILS_REQUEST_SUCCESS,
    FETCH_CONTACTS_REQUEST_BEGIN,
    FETCH_CONTACTS_REQUEST_ERROR,
    FETCH_CONTACTS_REQUEST_SUCCESS,
    MembersActionT,
    MembersStateT,
    UPDATE_MEMBER_REQUEST_BEGIN,
    UPDATE_MEMBER_REQUEST_ERROR,
    UPDATE_MEMBER_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';
import { isNonNil } from 'common/utils';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyMembersState: CompanyMembersStateT = {
    contactIds: null,
    constantById: {},
    fetchContactsRequest: requestStatus.initial,
    addMemberRequest: requestStatus.initial,
    deleteMemberRequest: requestStatus.initial,
    updateMemberRequest: requestStatus.initial,
    fetchContactDetailsRequest: requestStatus.initial,
    contactDetailsById: {},
    contactIdByUserId: {},
};

const initialState: MembersStateT = {};

export default (state = initialState, action: MembersActionT | DestroySessionActionT): MembersStateT => {
    switch (action.type) {
        case FETCH_CONTACTS_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchContactsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_CONTACTS_REQUEST_SUCCESS: {
            const { companyId, companyContacts } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            const ids = companyContacts.map((contact) => contact?.id).filter(isNonNil);
            const byId = keyBy(companyContacts, 'id');

            const contactIdByUserId = ids.reduce((acc, id) => {
                const contact = byId[id];

                const userId = contact?.userId;
                if (userId) {
                    return {
                        ...acc,
                        [userId]: id,
                    };
                }

                return acc;
            }, {});

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    contactIds: ids,
                    constantById: {
                        ...companyState.constantById,
                        ...byId,
                    },
                    fetchContactsRequest: requestStatus.ok,
                    contactIdByUserId: {
                        ...companyState.contactIdByUserId,
                        ...contactIdByUserId,
                    },
                },
            };
        }

        case FETCH_CONTACTS_REQUEST_ERROR: {
            const { companyId, error } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchContactsRequest: requestStatus.setError(error),
                },
            };
        }

        case ADD_MEMBER_REQUEST_BEGIN: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addMemberRequest: requestStatus.loading,
                },
            };
        }

        case ADD_MEMBER_REQUEST_SUCCESS: {
            const { companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addMemberRequest: requestStatus.ok,
                },
            };
        }

        case ADD_MEMBER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    addMemberRequest: requestStatus.setError(error),
                },
            };
        }

        case DELETE_MEMBER_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteMemberRequest: requestStatus.loading,
                },
            };
        }

        case DELETE_MEMBER_REQUEST_SUCCESS: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteMemberRequest: requestStatus.ok,
                },
            };
        }

        case DELETE_MEMBER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    deleteMemberRequest: requestStatus.setError(error),
                },
            };
        }

        case UPDATE_MEMBER_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateMemberRequest: requestStatus.loading,
                },
            };
        }

        case UPDATE_MEMBER_REQUEST_SUCCESS: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateMemberRequest: requestStatus.ok,
                },
            };
        }

        case UPDATE_MEMBER_REQUEST_ERROR: {
            const { error, companyId } = action;

            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    updateMemberRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_CONTACT_DETAILS_REQUEST_BEGIN: {
            const { companyId } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchContactDetailsRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_CONTACT_DETAILS_REQUEST_ERROR: {
            const { companyId, error } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    fetchContactDetailsRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_CONTACT_DETAILS_REQUEST_SUCCESS: {
            const { companyId, contactId, details } = action;
            const companyState = state[companyId] || initialCompanyMembersState;

            const userId = details?.userId;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    contactDetailsById: {
                        ...companyState.contactDetailsById,
                        [contactId]: details,
                    },
                    contactIdByUserId: {
                        ...companyState.contactIdByUserId,
                        ...(userId ? { [userId]: contactId } : {}),
                    },
                    fetchContactDetailsRequest: requestStatus.ok,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
