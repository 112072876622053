import * as React from 'react';
import { useCallback } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractPage.scss';
import BaseShipperContractDetailsLayout from 'common/layouts/BaseShipperContractDetailsLayout/BaseShipperContractDetailsLayout';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useShipperContractTabsConfig } from './hooks/useShipperContractTabsConfig';
import NavigationTabs, { NavigationTabsPropsT } from 'common/components/NavigationTabs/NavigationTabs';
import history from 'common/utils/history';
import isString from 'lodash/isString';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShipperContractDetails } from 'common/store/shipper-contract-details/slice';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { carrierContractDetailsRefreshChannel } from 'common/store/carrier-contract-details/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import ShipperContractHeader from './ShipperContractHeader/ShipperContractHeader';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import useContainerScroll from 'common/utils/hooks/useContainerScroll';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import { useTranslation } from 'react-i18next';
import BaseShipperContractLanesLayout from 'common/layouts/BaseShipperContractLanesLayout/BaseShipperContractLanesLayout';
import { urlFactory } from 'common/utils/urls';
import { ShipperContractRoutesEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {};

const ShipperContractPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const params = useParams<{ contractId: ShipperContractIdT }>();

    const { containerRef, onContainerScroll, scrollTop } = useContainerScroll();

    const scrollBarWidth = React.useMemo(() => getScrollbarWidth(containerRef?.current), [containerRef?.current]);

    const navigationTabsConfig = useShipperContractTabsConfig(params);

    const contractDetailsState = useSelector(selectShipperContractDetailsState(params.contractId));

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        history.push({
            ...(isString(to) ? { pathname: to } : to),
        });
    };

    const { contractId } = params;

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshHandler = useCallback(
        (event: InferChannelEventT<typeof carrierContractDetailsRefreshChannel>) => {
            if (event.contractId !== contractId) {
                return;
            }

            dispatch(
                fetchShipperContractDetails({
                    partnerId,
                    shipperContractId: event.contractId,
                }),
            );
        },
        [partnerId, contractId],
    );
    useChannelSubscribe(carrierContractDetailsRefreshChannel, refreshHandler);

    const goToLaneDetails = (laneId: ShipperContractLaneIdT) => {
        history.push(
            urlFactory.shipperContractLaneDetails({
                shipperContractId: contractId,
                shipperContractLaneId: laneId,
            }),
        );
    };

    const defaultRedirectPath = urlFactory.shipperContractDetails({
        shipperContractId: contractId,
    });

    return (
        <>
            <PageTitle
                title={t('page-titles.shipper-contract-details', {
                    contractName: contractDetailsState?.data?.name,
                })}
            />
            <ScrollableContent
                scrollContainerRef={containerRef}
                onContainerScroll={onContainerScroll}
                stickyHeaderNode={
                    <TopBar isSticky isWhite={scrollTop > 10} scrollBarWidth={scrollBarWidth}>
                        <TopBarContent title={<ShipperContractHeader />} rightNode={<NotificationsBarTrigger />} />
                    </TopBar>
                }
            >
                <ContentMargins>
                    <NavigationTabs
                        className={cx('tabs')}
                        tabsConfig={navigationTabsConfig}
                        onSelectTab={handleSelectTab}
                    />
                    <Switch>
                        <Route path={ShipperContractRoutesEnum.shipperContractDetails}>
                            <BaseShipperContractDetailsLayout
                                key={`details-${contractId}-${partnerId}`}
                                className={cx('details')}
                                partnerId={partnerId}
                                partnerType={partnerType}
                                contractId={params.contractId}
                                isAllowEditing={false}
                            />
                        </Route>
                        <Route path={ShipperContractRoutesEnum.shipperContractLanes}>
                            <BaseShipperContractLanesLayout
                                key={`lanes-${contractId}-${partnerId}`}
                                partnerId={partnerId}
                                contractId={contractId}
                                onOpenShipperContractLaneDetails={goToLaneDetails}
                            />
                        </Route>
                        <Route>
                            <Redirect to={defaultRedirectPath} />
                        </Route>
                    </Switch>
                </ContentMargins>
            </ScrollableContent>
        </>
    );
});

export default ShipperContractPage;
