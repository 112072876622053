import React from 'react';

export type OnKeyPressCallbackT = (key: string, isPressedMetaKey: boolean) => void;

export const useKeyboardShortcut = (onKeyPress: OnKeyPressCallbackT) => {
    const handleKeyPress = React.useCallback(
        (event: any) => {
            try {
                const isPressedMetaKey = (event as KeyboardEvent).altKey || (event as KeyboardEvent).metaKey;

                onKeyPress((event as KeyboardEvent).key, isPressedMetaKey);
            } catch (error) {
                console.error(error);
            }
        },
        [onKeyPress],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
};
