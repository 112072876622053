import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {};

const DestinationIcon: React.FC<PropsT> = () => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <circle cx={10} cy={10} r={10} fill={StyleGuideColorsEnum.blazeOrange} />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M10.277 15c1.212-.01 2.127-.259 2.744-.749.618-.49.927-1.222.927-2.197 0-.538-.14-.994-.417-1.367-.278-.374-.677-.63-1.197-.766.46-.173.81-.442 1.046-.807.237-.364.356-.804.356-1.32 0-.892-.334-1.574-1.002-2.043-.667-.47-1.641-.704-2.922-.704H6.237V15h4.04zm-.39-5.804h-1.25V6.9h1.175c.538 0 .927.093 1.169.277.241.185.362.487.362.906 0 .365-.111.64-.335.827-.223.187-.597.283-1.12.287zm.321 3.958H8.636V10.77h1.682c.825.004 1.237.421 1.237 1.25 0 .36-.116.64-.348.838-.233.198-.566.297-.999.297z"
                />
            </g>
        </svg>
    );
};

DestinationIcon.displayName = 'DestinationIcon';

const storyProps: PropsT = {};

export { storyProps };
export default DestinationIcon;
