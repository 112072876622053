import * as React from 'react';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import LoadStatus from 'common/components/status/LoadStatus/LoadStatus';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiShipmentStatusT | null | undefined;
};

export const LOAD_STATUS_COLOR_MAP: Record<ApiShipmentStatusT, StyleGuideColorsEnum> = {
    [ShipmentStatusEnum.awaitingLoading]: StyleGuideColorsEnum.light,
    [ShipmentStatusEnum.loading]: StyleGuideColorsEnum.light,
    [ShipmentStatusEnum.inTransit]: StyleGuideColorsEnum.light,
    [ShipmentStatusEnum.unloading]: StyleGuideColorsEnum.light,
    [ShipmentStatusEnum.delivered]: StyleGuideColorsEnum.mediumGreen,
    [ShipmentStatusEnum.canceled]: StyleGuideColorsEnum.light,
};

const ShipmentColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    const color = LOAD_STATUS_COLOR_MAP[status];

    return <ColoredStatusLabel {...restProps} label={<LoadStatus status={status} />} color={color} />;
});

export default ShipmentColoredStatus;
