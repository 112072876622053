import { PriceOfferT } from '../models';

export const byPriceAsc = (priceA: PriceOfferT, priceB: PriceOfferT): number => {
    return (priceA.price || 0) - (priceB.price || 0);
};

const sortOffers = (priceOffers: Array<PriceOfferT>): Array<PriceOfferT> => {
    return priceOffers.sort(byPriceAsc);
};

export default sortOffers;
