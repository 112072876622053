import i18n from 'i18next';

export type ConfigT = {
    mapsApiKey: string;
    language: string;
};

export default {
    mapsApiKey: window.MAPS_API_KEY,
    language: i18n.language,
    region: i18n.language,
};
