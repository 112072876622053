import { RequestStatusT } from 'common/utils/request-status';

import { FindShippersQueryT, PartnerT, ShipperByIdT } from './models';

export const FIND_SHIPPERS_REQUEST_BY_QUERY = 'common/shipper-suggest/FIND_SHIPPERS_REQUEST_BY_QUERY';
export const FIND_SHIPPERS_REQUEST_BY_ID = 'common/shipper-suggest/FIND_SHIPPERS_REQUEST_BY_ID';
export const FIND_SHIPPERS_REQUEST_BEGIN = 'common/shipper-suggest/FIND_SHIPPERS_REQUEST_BEGIN';
export const FIND_SHIPPERS_REQUEST_ERROR = 'common/shipper-suggest/FIND_SHIPPERS_REQUEST_ERROR';
export const FIND_SHIPPERS_REQUEST_SUCCESS = 'common/shipper-suggest/FIND_SHIPPERS_REQUEST_SUCCESS';

export const RESET = 'common/shipper-suggest/RESET';

export type ShipperSuggestStateT = {
    query: FindShippersQueryT | null;
    requestStatus: RequestStatusT;
    ids: Array<PartnerT['id']>;
    byId: ShipperByIdT;
};

export type FindShippersByQueryActionT = {
    type: typeof FIND_SHIPPERS_REQUEST_BY_QUERY;
    query: FindShippersQueryT;
};

export type FindShippersByIdActionT = {
    type: typeof FIND_SHIPPERS_REQUEST_BY_ID;
    partnerId: PartnerIdT;
    query: FindShippersQueryT;
};

export type FindShippersBeginActionT = {
    type: typeof FIND_SHIPPERS_REQUEST_BEGIN;
    query: FindShippersQueryT;
};

export type FindShippersSuccessActionT = {
    type: typeof FIND_SHIPPERS_REQUEST_SUCCESS;
    partners: PartnerT[];
};

export type FindShippersErrorActionT = {
    type: typeof FIND_SHIPPERS_REQUEST_ERROR;
    error: Error;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type ShipperSuggestActionT =
    | FindShippersByQueryActionT
    | FindShippersBeginActionT
    | FindShippersSuccessActionT
    | FindShippersErrorActionT
    | ResetActionT;
