import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const BellIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    fillOpacity="0.3"
                    d="M10 5.875c-1.867 0-3 1.515-3 3.375v4.5h6v-4.5c0-1.86-1.133-3.375-3-3.375z"
                />
                <path
                    fill={strokeColor}
                    d="M10 17.5c.825 0 1.5-.675 1.5-1.5h-3c0 .825.675 1.5 1.5 1.5zm4.5-4.5V9.25c0-2.303-1.223-4.23-3.375-4.74V4c0-.623-.502-1.125-1.125-1.125S8.875 3.377 8.875 4v.51C6.73 5.02 5.5 6.94 5.5 9.25V13L4 14.5v.75h12v-.75L14.5 13zm-1.5.75H7v-4.5c0-1.86 1.133-3.375 3-3.375s3 1.515 3 3.375v4.5z"
                />
            </g>
        </svg>
    );
};

BellIcon.displayName = 'BellIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default BellIcon;
