import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_RFQ_DETAILS_REQUEST, FetchRFQDetailsRequestActionT } from './types';
import { fetchRFQDetailsBegin, fetchRFQDetailsError, fetchRFQDetailsSuccess } from './actions';
import { selectRFQDetailsRequestStatus } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { CompanyTypeEnum } from 'common/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';

const getFetchRFQDetailsSaga = (companyType: CompanyTypeEnum) => {
    return function* fetchRFQDetailsSaga(action: FetchRFQDetailsRequestActionT): WrapGeneratorT<void> {
        const { id, options } = action;

        const requestStatus: ReReturnT<typeof selectRFQDetailsRequestStatus> = yield select(
            selectRFQDetailsRequestStatus(id),
        );
        if (!checkNeedRequest(requestStatus, options)) {
            return;
        }

        yield put(fetchRFQDetailsBegin(id));

        let detailsResponse: ReturnApiT<
            typeof brokerTranziitApi.fetchRFQDetails | typeof shipperTranziitApi.fetchRFQDetails
        >;
        if (companyType === CompanyTypeEnum.broker) {
            detailsResponse = yield brokerTranziitApi.fetchRFQDetails(id);
        } else {
            detailsResponse = yield shipperTranziitApi.fetchRFQDetails(id);
        }
        const [rfqDetailsError, rfqDetailsData] = detailsResponse;
        if (rfqDetailsError) {
            yield put(fetchRFQDetailsError(id, rfqDetailsError));
            return;
        }

        let defaultOfferResponse: ReturnApiT<
            typeof brokerTranziitApi.getRFQDefaultOffer | typeof shipperTranziitApi.getRFQDefaultOffer
        >;
        if (companyType === CompanyTypeEnum.broker) {
            defaultOfferResponse = yield brokerTranziitApi.getRFQDefaultOffer(id);
        } else {
            defaultOfferResponse = yield shipperTranziitApi.getRFQDefaultOffer(id);
        }
        const [defaultOfferError, defaultOfferData] = defaultOfferResponse;
        if (defaultOfferError) {
            yield put(fetchRFQDetailsError(id, defaultOfferError));
            return;
        }

        yield put(fetchRFQDetailsSuccess(id, rfqDetailsData, defaultOfferData));
    };
};

function* rfqDetailsSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(FETCH_RFQ_DETAILS_REQUEST, getFetchRFQDetailsSaga(companyType));
}

export default rfqDetailsSaga;
