import React from 'react';

import classNames from 'classnames/bind';
import styles from './CountryDropdown.scss';
import { useTranslation } from 'react-i18next';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import {
    selectCountriesAllCodes,
    selectCountriesByCode,
    selectCountriesDictRequest,
} from 'common/store/countries-dict/selectors';
import { CountryCodeT } from 'common/store/countries-dict/models';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import CountryFlagPlaceholderOption from 'common/components/dropdowns/options/CountryFlagPlaceholderOption/CountryFlagPlaceholderOption';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';

const cx = classNames.bind(styles);

type ValueT = CountryCodeT | null;
type OptionT = CountryCodeT;

type PropsT = {
    onChange: (countryCode: ValueT) => void;
    value?: ValueT;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    onReset?: () => void;
    placeholder?: string;
    inputPlaceholder?: string;
    testSelector?: string;
};

const CountryDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        onChange,
        value,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        hasChanges,
        onBlur,
        onFocus,
        onReset,
        testSelector,
        placeholder,
        inputPlaceholder,
    } = props;

    const { t } = useTranslation();

    const [query, setQuery] = React.useState<string>('');

    const fetchRequest = useSelector(selectCountriesDictRequest);
    const allCodes = useSelector(selectCountriesAllCodes);
    const byCode = useSelector(selectCountriesByCode);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const renderOption = (countryCode: CountryCodeT | null | undefined, placeholder?: string): React.ReactElement => {
        if (!countryCode && placeholder) {
            return <CountryFlagPlaceholderOption label={placeholder} />;
        }

        const country = countryCode ? byCode[countryCode] : null;

        return (
            <span>
                <FlagIcon className={cx('flag')} countryCode={country?.code} />
                {country?.userLangDisplayName}
            </span>
        );
    };

    const getOptionValue = (option: CountryCodeT): CountryCodeT => option;

    const handleOptionClick = (countryCode: CountryCodeT | null) => {
        onChange(countryCode);
    };

    const filteredCodes = React.useMemo(() => {
        const clearedQuery = query.toLowerCase().replace(/ /g, '').trim();
        if (!clearedQuery) {
            return allCodes;
        }

        return allCodes.filter((countryCode) => {
            const country = countryCode ? byCode[countryCode] : null;

            return [country?.userLangDisplayName, country?.language, country?.langCode, country?.code].some((text) =>
                text?.toLowerCase().replace(/ /g, '').includes(clearedQuery),
            );
        });
    }, [allCodes, query, byCode]);

    return (
        <DropdownSearchInput<OptionT, ValueT | null>
            className={className}
            selectedValue={value || null}
            placeholder={placeholder || t('common:country-selector.placeholder')}
            inputPlaceholder={inputPlaceholder || t('common:country-selector.input-placeholder')}
            options={filteredCodes}
            onSelect={handleOptionClick}
            hasError={hasError}
            hasWarning={hasWarning}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            testSelector={`${testSelector}_country`}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={onReset}
            onChangeQuery={setQuery}
        />
    );
});

export default CountryDropdown;
