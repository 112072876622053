import * as React from 'react';
import ControlsCell from 'common/components/Table/cell-renderers/ControlsCell/ControlsCell';
import type { DocumentRowT } from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/BaseDocumentsTable';
import { useDocumentsActionOptions } from 'common/layouts/BaseDocumentsLayout/hooks/use-documents-action-options';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import { DocumentActionsT } from '../../../models';

type PropsT = {
    row: DocumentRowT;
    className?: string;
} & DocumentActionsT;

const ActionsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, row, onDownloadDocument, onUploadDocument, onRevokeDocument, onEditDocumentInfo } = props;

    const permissions = useSelector(selectPermissions);

    const actualVersion = row?.actualVersion || null;
    const dictDocument = row?.dictDocument || null;

    const options = useDocumentsActionOptions(actualVersion, dictDocument, permissions, {
        onDownloadDocument,
        onUploadDocument,
        onRevokeDocument,
        onEditDocumentInfo,
    });

    return <ControlsCell className={className} options={options} />;
});

export default ActionsCell;
