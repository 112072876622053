import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerTypeCell.scss';
import { OrderT } from 'common/store/orders/models';
import parseTrailerModel from 'common/utils/parse-trailer-model';
import { useTranslation } from 'react-i18next';

type PropsT = {
    order: OrderT;
};

const cx = classNames.bind(styles);

const TrailerTypeCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    const { t } = useTranslation();

    const { trailerModel, trailerPlateNumber } = order;
    const [trailer] = parseTrailerModel(trailerModel);

    const defaultLabel = t('common:trailers.not-assigned');

    return (
        <div>
            {trailer && <div className={cx('type')}>{trailer}</div>}
            {trailerPlateNumber ? (
                <div className={cx('plate-number')}>{trailerPlateNumber}</div>
            ) : (
                <div className={cx('not-assigned')}>{defaultLabel}</div>
            )}
        </div>
    );
});

export default TrailerTypeCell;
