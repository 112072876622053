import { ApiShipperContractLaneStatusT } from 'common/utils/api/models';

export enum QueryFiltersKeysEnum {
    text = 'text',
    status = 'status',

    dictTrailerId = 'dictTrailerId',

    address = 'address',

    validFromStart = 'validFromStart',
    validFromFinish = 'validFromFinish',

    validTillStart = 'validTillStart',
    validTillFinish = 'validTillFinish',

    createdDateStart = 'createdDateStart',
    createdDateFinish = 'createdDateFinish',

    createdById = 'createdById',
    createdByName = 'createdByName',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.text]: QueryParam.StringT;
    [QueryFiltersKeysEnum.status]: ApiShipperContractLaneStatusT;

    [QueryFiltersKeysEnum.dictTrailerId]: QueryParam.StringT;

    [QueryFiltersKeysEnum.address]: QueryParam.StringT;

    [QueryFiltersKeysEnum.validFromStart]: QueryParam.StringT;
    [QueryFiltersKeysEnum.validFromFinish]: QueryParam.StringT;

    [QueryFiltersKeysEnum.validTillStart]: QueryParam.StringT;
    [QueryFiltersKeysEnum.validTillFinish]: QueryParam.StringT;

    [QueryFiltersKeysEnum.createdDateStart]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFinish]: QueryParam.StringT;

    [QueryFiltersKeysEnum.createdById]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByName]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    shipperContractLanesPage = 'shipper-contract-lanes-page',
    shipperContractLanesSort = 'shipper-contracts-sort',
    shipperContractLanesFilters = 'shipper-contracts-filters',
}
