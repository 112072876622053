import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './LaneDropdownInputOptionLabel.scss';
import { ApiContractLaneT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import isNumber from 'lodash/isNumber';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';

const cx = classNames.bind(styles);

type PropsT = {
    lane: ApiContractLaneT | null | undefined;
};

const LaneDropdownInputOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const { lane } = props;

    const { t } = useTranslation();

    if (!lane) {
        return null;
    }

    return (
        <div className={cx('option')}>
            <div className={cx('label')}>
                {t('common:new-order-form.lane-option-template', {
                    contractName: lane.contractName || '-',
                    tztLaneId: lane.tztLaneId || '-',
                    externalLaneId: lane.externalLaneId || '-',
                })}
            </div>
            <div className={cx('description')}>
                {!!lane.teamDrive && (
                    <div>
                        <TeamDriveIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                        />
                    </div>
                )}
                {!!lane.emissionClass && (
                    <PillLabel isSymmetrical theme={PillLabelThemeEnum.grey}>
                        <EmissionClassLabel emissionClass={lane.emissionClass} />
                    </PillLabel>
                )}
                {isNumber(lane.maxNumberOfOrders) && isNumber(lane.ordersLeft) && (
                    <PillLabel isSymmetrical isRounded theme={PillLabelThemeEnum.charcoal}>
                        {`${lane.ordersLeft}/${lane.maxNumberOfOrders}`}
                    </PillLabel>
                )}
                <div className={cx('spacer')} />
                {isNumber(lane.totalPrice) && (
                    <div className={cx('price')}>
                        <Money amount={lane.totalPrice} currency={CurrencyEnum.EUR} />
                    </div>
                )}
            </div>
        </div>
    );
});

export default LaneDropdownInputOptionLabel;
