import * as React from 'react';
import { ApiTourStatusT, TourStatusEnum } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import TourStatus from 'common/components/status/TourStatus/TourStatus';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiTourStatusT | null | undefined;
};

export const TOUR_STATUS_COLOR_MAP: Record<ApiTourStatusT, StyleGuideColorsEnum> = {
    [TourStatusEnum.assetsUnassigned]: StyleGuideColorsEnum.tomatoRed,
    [TourStatusEnum.assigned]: StyleGuideColorsEnum.light,
    [TourStatusEnum.inTransit]: StyleGuideColorsEnum.light,
    [TourStatusEnum.finished]: StyleGuideColorsEnum.brandDark,
    [TourStatusEnum.canceled]: StyleGuideColorsEnum.light,
    [TourStatusEnum.driverUnassigned]: StyleGuideColorsEnum.tomatoRed,
    [TourStatusEnum.carrierUnassigned]: StyleGuideColorsEnum.tomatoRed,
};

const TourColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    const color = TOUR_STATUS_COLOR_MAP[status];

    return <ColoredStatusLabel {...restProps} label={<TourStatus status={status} />} color={color} />;
});

export default TourColoredStatus;
