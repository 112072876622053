import React, { useCallback, useEffect, useMemo } from 'react';
import {
    UseAsyncOptionsParamsT,
    useAsyncOptionsRequest,
    UseAsyncOptionsResultT,
} from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import { prepareSearchQuery } from 'common/utils/search';
import { isNonNil } from 'common/utils';

export type AdditionalOptionFilterT<T extends {}> = (option: T) => boolean;

export type UseAsyncFilterOptionsParamsT<T extends {}> = UseAsyncOptionsParamsT<T> & {
    getOptionText: (option: T) => string;
    additionalOptionFilter?: AdditionalOptionFilterT<T>;
};

export type UseAsyncFilterOptionsResultT<T extends {}> = UseAsyncOptionsResultT<T> & {
    query: string;
    onRefresh: () => void;
};

export const useAsyncFilterOptions = <T extends {}>({
    initialOption,
    requestFactory,
    getOptionText,
    additionalOptionFilter,
}: UseAsyncFilterOptionsParamsT<T>): UseAsyncFilterOptionsResultT<T> => {
    const [query, setQuery] = React.useState<string>('');

    const asyncOptionsRequest = useAsyncOptionsRequest<T>({
        initialOption,
        requestFactory,
        allowEmptyQuery: true,
    });

    const { options, onChangeQuery, ...restAsyncOptionsRequest } = asyncOptionsRequest;

    const handleRefresh = useCallback(() => {
        onChangeQuery('');
    }, [onChangeQuery]);

    const handleChangeQuery = useCallback(
        (query?: string | null | undefined) => {
            setQuery(query || '');
        },
        [setQuery],
    );

    useEffect(() => {
        onChangeQuery('');
    }, []);

    const defaultOptionFilter = useMemo(() => {
        const preparedQuery = prepareSearchQuery(query);
        if (!preparedQuery) {
            return () => true;
        }

        return (option: T) => {
            const text = getOptionText(option);
            const preparedText = prepareSearchQuery(text);

            return preparedText.includes(preparedQuery);
        };
    }, [query]);

    const optionFilters: Array<typeof defaultOptionFilter> = useMemo(() => {
        return [defaultOptionFilter, additionalOptionFilter].filter(isNonNil);
    }, [defaultOptionFilter, additionalOptionFilter]);

    const filteredOptions = useMemo(() => {
        return options.filter((option) => {
            return optionFilters.every((optionFilter) => optionFilter(option));
        });
    }, [options, optionFilters]);

    return {
        ...restAsyncOptionsRequest,
        options: filteredOptions,
        query,
        onChangeQuery: handleChangeQuery,
        onRefresh: handleRefresh,
    };
};
