import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './ParanjaSideBarLayout.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const ParanjaSideBarLayout: React.FC<PropsT> = React.memo((props) => {
    return <div className={cx('paranja')} />;
});

export default ParanjaSideBarLayout;
