import { GPSTrackingStatusStateT } from './types';
import { SharableStateT } from '../models';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';

export const selectGPSCheckQuery = (state: SharableStateT): GPSTrackingStatusStateT['query'] =>
    state.gpsTrackingStatus.query;

export const selectGPSCheckStatus = (state: SharableStateT): GPSTrackingStatusStateT['requestStatus'] =>
    state.gpsTrackingStatus.requestStatus;

export const selectGPSCheckData = (state: SharableStateT): GPSTrackingStatusStateT['data'] =>
    state.gpsTrackingStatus.data;

export const selectGPSIsChecking = (state: SharableStateT): boolean => {
    const { requestStatus } = state.gpsTrackingStatus;

    return requestStatus.loading;
};

export const selectGPSStatus = (state: SharableStateT): GPStatusEnum | null => {
    const { requestStatus, data } = state.gpsTrackingStatus;

    if (requestStatus.ok && data?.hasGps) {
        return GPStatusEnum.enabled;
    }

    if (requestStatus.error || (data && !data.hasGps)) {
        return GPStatusEnum.unable;
    }

    return null;
};
