import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiNotificationSubjectTypeT, SubjectTypeEnum } from 'common/utils/api/models';
import styles from './NotificationSubjectType.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type PropsT = {
    subjectType: ApiNotificationSubjectTypeT | null | undefined;
    isCapitalized?: boolean;
    isUppercase?: boolean;
};

export const SUBJECT_TYPE_T_MAP: Record<ApiNotificationSubjectTypeT, string | null> = {
    [SubjectTypeEnum.order]: 'common:notifications.subject-type.ORDER',
    [SubjectTypeEnum.tour]: 'common:notifications.subject-type.TOUR',
    [SubjectTypeEnum.shipment]: 'common:notifications.subject-type.SHIPMENT',
    [SubjectTypeEnum.transportationOrder]: 'common:notifications.subject-type.TRANSPORTATION_ORDER',
    [SubjectTypeEnum.dispatch]: 'common:notifications.subject-type.DISPATCH',
    [SubjectTypeEnum.rfq]: 'common:notifications.subject-type.RFQ',
    [SubjectTypeEnum.document]: null,
    [SubjectTypeEnum.carrierContract]: null,
    [SubjectTypeEnum.shipperContract]: null,
    [SubjectTypeEnum.shipperContractLane]: null,
};

const NotificationSubjectType: React.FC<PropsT> = React.memo((props) => {
    const { subjectType, isCapitalized, isUppercase } = props;

    const { t } = useTranslation();

    if (!subjectType) {
        return null;
    }

    const tDocumentSubject = SUBJECT_TYPE_T_MAP[subjectType];
    const label = tDocumentSubject ? t(tDocumentSubject) : null;

    return (
        <span
            className={cx('text', {
                'text--is-capitalized': isCapitalized,
                'text--is-uppercase': isUppercase,
            })}
        >
            {label}
        </span>
    );
});

export default NotificationSubjectType;
