import * as React from 'react';
import { useCallback, useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './ProfileDocuments.scss';
import {
    ApiCarrierContractStatusT,
    ApiShipperContractStatusT,
    DocumentStatusEnum,
    PartnerTypeEnum,
} from 'common/utils/api/models';
import VerificationAlert from 'common/components/VerificationAlert/VerificationAlert';
import { useTranslation } from 'react-i18next';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ProfileDocumentsRoutesEnum } from 'common/constants';
import BaseDocumentsLayout from 'common/layouts/BaseDocumentsLayout/BaseDocumentsLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentsDict } from 'common/store/documents-dict/actions';
import { fetchDocuments } from 'common/store/documents/actions';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { selectDocumentsDictRequest } from 'common/store/documents-dict/selectors';
import { selectFetchCompanyDocumentsRequest } from 'common/store/documents/selectors';
import { selectCurrentCompany } from 'common/store/company/selectors';
import { useDocumentsRows } from 'common/layouts/BaseDocumentsLayout/hooks/use-documents-rows';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import history from 'common/utils/history';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import BaseCarrierContractsLayout from 'common/layouts/BaseCarrierContractsLayout/BaseCarrierContractsLayout';
import BaseShipperContractsLayout from 'common/layouts/BaseShipperContractsLayout/BaseShipperContractsLayout';
import { urlFactory } from 'common/utils/urls';
import isString from 'lodash/isString';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { documentsRefreshChannel } from 'common/store/documents/channels';

const cx = classNames.bind(styles);

type PropsT = {
    hasVerificationProblem: boolean;
};

const ProfileDocuments: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType } = usePartnerContext();

    const { hasVerificationProblem } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId));
    }, [partnerType, partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId));
    }, [partnerType, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshHandler = useCallback(() => {
        dispatch(fetchDocuments(partnerId, { isForceUpdate: true }));
    }, []);
    useChannelSubscribe(documentsRefreshChannel, refreshHandler);

    const fetchDocumentsDictRequest = useSelector(selectDocumentsDictRequest(partnerType));
    const fetchRequest = useSelector(selectFetchCompanyDocumentsRequest(partnerId));

    const currentCompany = useSelector(selectCurrentCompany);

    const isLoading = fetchRequest.loading || fetchDocumentsDictRequest.loading;

    const { requiredDocumentRows, optionalDocumentRows } = useDocumentsRows(partnerId, partnerType);

    const requiredDocumentStatusesAttentionSet = useMemo(() => {
        return new Set<DocumentStatusEnum | null>([
            null, // not uploaded
            DocumentStatusEnum.rejected,
            DocumentStatusEnum.revoked,
            DocumentStatusEnum.expired,
        ]);
    }, []);

    const optionalDocumentStatusesAttentionSet = useMemo(() => {
        return new Set<DocumentStatusEnum>([]);
    }, []);

    const carrierContactStatusesAttentionSet = useMemo(() => {
        return new Set<ApiCarrierContractStatusT | null>([]);
    }, []);

    const shipperContactStatusesAttentionSet = useMemo(() => {
        return new Set<ApiShipperContractStatusT | null>([]);
    }, []);

    const badgeRequiredDocumentsCount = React.useMemo(() => {
        return requiredDocumentRows?.filter((row) => {
            return !row?.actualVersion || requiredDocumentStatusesAttentionSet.has(row.actualVersion.status);
        }).length;
    }, [requiredDocumentRows, requiredDocumentStatusesAttentionSet]);

    const tabsConfig = React.useMemo((): Array<NavigationTabConfigT | null> => {
        const tabs: Array<NavigationTabConfigT> = [
            {
                pathname: ProfileDocumentsRoutesEnum.requiredDocuments,
                to: ProfileDocumentsRoutesEnum.requiredDocuments,
                badge: badgeRequiredDocumentsCount ? (
                    <NotificationLabel count={badgeRequiredDocumentsCount} theme={NotificationLabelThemeEnum.orange} />
                ) : null,
                render: () => t('common:profile-page.documents-tabs.required'),
                testSelector: 'required-documents',
            },
            {
                pathname: ProfileDocumentsRoutesEnum.optionalDocuments,
                to: ProfileDocumentsRoutesEnum.optionalDocuments,
                badge: null,
                render: () => t('common:profile-page.documents-tabs.optional'),
                testSelector: 'optional-documents',
            },
        ];

        if (partnerType === PartnerTypeEnum.carrier) {
            tabs.push({
                pathname: ProfileDocumentsRoutesEnum.carrierContracts,
                to: ProfileDocumentsRoutesEnum.carrierContracts,
                badge: null,
                render: () => t('common:profile-page.documents-tabs.contracts'),
                testSelector: 'carrier-contracts',
            });
        }

        if (partnerType === PartnerTypeEnum.shipper) {
            tabs.push({
                pathname: ProfileDocumentsRoutesEnum.shipperContracts,
                to: ProfileDocumentsRoutesEnum.shipperContracts,
                badge: null,
                render: () => t('common:profile-page.documents-tabs.contracts'),
                testSelector: 'shipper-contracts',
            });
        }

        return tabs;
    }, [partnerType, badgeRequiredDocumentsCount, t]);

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        history.push({
            ...(isString(to) ? { pathname: to } : to),
        });
    };

    const isAllDocumentsVerified = !!currentCompany?.documentsVerified;

    const navigationTabs = <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />;

    const handleOpenShipperContractDetails = (shipperContractId: ShipperContractIdT) => {
        history.push(
            urlFactory.shipperContractLanes({
                shipperContractId,
            }),
        );
    };

    return (
        <div className={cx('wrap')}>
            {hasVerificationProblem && (
                <VerificationAlert
                    className={cx('alert')}
                    message={t('inline-alerts.verification-company-problem.message')}
                />
            )}
            <div className={cx('document-table-wrap')}>
                <Switch>
                    <Route path={ProfileDocumentsRoutesEnum.requiredDocuments}>
                        <BaseDocumentsLayout
                            navigationTabs={navigationTabs}
                            tableName="required-documents"
                            testSelector="required-documents"
                            isAllDocumentsVerified={isAllDocumentsVerified}
                            isShowReviewUser={false}
                            isLoading={isLoading}
                            rows={requiredDocumentRows}
                            documentStatusesAttentionSet={requiredDocumentStatusesAttentionSet}
                        />
                    </Route>
                    <Route path={ProfileDocumentsRoutesEnum.optionalDocuments}>
                        <BaseDocumentsLayout
                            navigationTabs={navigationTabs}
                            tableName="optional-documents"
                            testSelector="optional-documents"
                            isAllDocumentsVerified={isAllDocumentsVerified}
                            isShowReviewUser={false}
                            isLoading={isLoading}
                            rows={optionalDocumentRows}
                            documentStatusesAttentionSet={optionalDocumentStatusesAttentionSet}
                        />
                    </Route>
                    <Route path={ProfileDocumentsRoutesEnum.carrierContracts}>
                        <BaseCarrierContractsLayout
                            navigationTabs={navigationTabs}
                            tableName="carrier-contracts"
                            isLoading={isLoading}
                            testSelector="carrier-contracts"
                            contactStatusesAttentionSet={carrierContactStatusesAttentionSet}
                        />
                    </Route>
                    <Route path={ProfileDocumentsRoutesEnum.shipperContracts}>
                        <BaseShipperContractsLayout
                            navigationTabs={navigationTabs}
                            contactStatusesAttentionSet={shipperContactStatusesAttentionSet}
                            onOpenShipperContractDetails={handleOpenShipperContractDetails}
                        />
                    </Route>
                    <Route>
                        <Redirect to={ProfileDocumentsRoutesEnum.requiredDocuments} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
});

export default ProfileDocuments;
