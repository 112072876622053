import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './NotificationToastContent.scss';
import CloseIcon from 'common/icons/CloseIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DoneIcon from 'common/icons/DoneIcon';
import LinkIcon from 'common/icons/LinkIcon';
import { NotificationToastViewT, NotificationIconEnum } from 'common/store/notifications/models';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import NewIcon from 'common/icons/NewIcon';
import HourglassIcon from 'common/icons/HourglassIcon';

const cx = classNames.bind(styles);

export type PropsT = {
    onClick: () => void;
    onClose: () => void;
    testSelector?: string;
    className?: string;
} & NotificationToastViewT;

const NotificationToastContent: React.FC<PropsT> = React.memo((props) => {
    const { icon, text, testSelector, className, onClose, onClick } = props;

    const fullTestSelector = `${testSelector}_notification`;

    return (
        <div
            className={cx('content', {
                'content--has-icon': !!icon,
            })}
            data-test-selector={fullTestSelector}
        >
            {icon && (
                <div className={cx('content__left-icon')}>
                    {icon === NotificationIconEnum.success && (
                        <DoneIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                    )}
                    {icon === NotificationIconEnum.attention && (
                        <StopWarningIcon opacity={0.7} fillColor={StyleGuideColorsEnum.orange} />
                    )}
                    {icon === NotificationIconEnum.fail && (
                        <StopWarningIcon opacity={0.7} fillColor={StyleGuideColorsEnum.tomatoRed} />
                    )}
                    {icon === NotificationIconEnum.new && (
                        <NewIcon
                            fillColor={StyleGuideColorsEnum.transparent}
                            strokeColor={StyleGuideColorsEnum.white}
                        />
                    )}
                    {icon === NotificationIconEnum.expired && (
                        <HourglassIcon
                            fillColor={StyleGuideColorsEnum.tomatoRed}
                            strokeColor={StyleGuideColorsEnum.white}
                        />
                    )}
                </div>
            )}
            <div className={cx('content__content')}>
                <div className={cx('text')} data-test-selector={`${fullTestSelector}-text`}>
                    {text}
                </div>
            </div>
            {onClick && (
                <div className={cx('content__link-icon')}>
                    <TransparentTrigger
                        testSelector={`${fullTestSelector}-link`}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            onClick();
                        }}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                        spaces="xs"
                        leftIcon={<LinkIcon fillColor={StyleGuideColorsEnum.light} />}
                    />
                </div>
            )}
            <div className={cx('content__close-icon')}>
                <TransparentTrigger
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        onClose();
                    }}
                    reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    spaces="xs"
                    testSelector={`${fullTestSelector}-close`}
                    leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.light} opacity={0.3} />}
                />
            </div>
        </div>
    );
});

export default NotificationToastContent;
