import * as React from 'react';
import { useTranslation } from 'react-i18next';

type PropsT = {};

const LoaderTitle: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    return <div>{t('common:loading')}</div>;
});

export default LoaderTitle;
