import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './HeaderSideBarLoader.scss';
import SideBarLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-layout-context';
import LineLoader from 'common/components/LineLoader/LineLoader';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isShow: boolean;
};

const HeaderSideBarLoader: React.FC<PropsT> = React.memo((props) => {
    const { className, isShow } = props;

    const context = React.useContext(SideBarLayoutContext);

    const headerHeightValue = context.headerHeight?.value || 0;

    return (
        <LineLoader
            isShow={isShow}
            className={cs(cx('line-loader'), className)}
            style={{
                top: `${headerHeightValue}px`,
            }}
        />
    );
});

export default HeaderSideBarLoader;
