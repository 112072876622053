import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { getZipCodeExample, hasZipCodeValidation, isValid } from 'common/utils/postal-codes';
import { checkMaxLength, checkMinLength, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.companyName,
    FieldsEnum.zipCode,
    FieldsEnum.city,
    FieldsEnum.street1,
    FieldsEnum.legalFormId,
] as const;

const validateForm = (t: TFunction, values: FormValuesT): Partial<Record<FieldsEnum, string>> => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),

        ...checkMinLength(t, [FieldsEnum.companyName], values, 2),
        ...checkMaxLength(t, [FieldsEnum.companyName], values, 255),
    };

    const countryCode = values[FieldsEnum.countryCode];
    const zipCode = values[FieldsEnum.zipCode];
    if (hasZipCodeValidation(countryCode) && !isValid(countryCode, zipCode)) {
        errors[FieldsEnum.zipCode] = t('common:form-errors.zip-code', {
            example: getZipCodeExample(countryCode),
        });
    }

    return errors;
};

export default validateForm;
