import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    width?: number;
    height?: number;
};

const DownArrowIcon: React.FC<PropsT> = (props) => {
    const { fillColor, width = 20, height = 20 } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <defs>
                <path id="t93mq67o4a" d="M13 12H7l3-3z" />
            </defs>
            <use fill={fillColor} fillRule="evenodd" transform="matrix(1 0 0 -1 0 21)" xlinkHref="#t93mq67o4a" />
        </svg>
    );
};

DownArrowIcon.displayName = 'DownArrowIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default DownArrowIcon;
