import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './DateCell.scss';

import isNil from 'lodash/isNil';
import { formatDate } from 'common/utils/time';

type PropsT = {
    date: string | null | undefined;
    className?: string;
    dateFormat?: string;
    isBold?: boolean;
    isBigFont?: boolean;
};

const cx = classNames.bind(styles);

const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

const DateCell: React.FC<PropsT> = React.memo((props) => {
    const { date, isBold, dateFormat, isBigFont, className } = props;

    if (isNil(date)) {
        return null;
    }

    return (
        <div className={cs(cx('date', { 'date--isBold': isBold, 'date--isBigFont': isBigFont }), className)}>
            {formatDate(dateFormat || DEFAULT_DATE_FORMAT, date)}
        </div>
    );
});

export default DateCell;
