import app from 'firebase/app';
import 'firebase/auth';

import i18n from 'i18next';
import { authRoutesEnum, QueryKeysEnum } from '../../../constants';
import { AuthApiError, AuthErrorTypeEnum } from './errors/auth-api-error';
import {
    AuthAccountUrlsT,
    AuthStateChangedCallbackT,
    AuthUnsubscribeCallbackT,
    AuthUserClaimsT,
    AuthUserT,
    BaseAuthService,
} from './base-auth-service';
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import { formatQuery } from 'common/utils/query';
import { createSelfExternalUrl } from 'common/utils/history';

type FirebaseConfigT = {
    apiKey?: string;
    authDomain?: string;
    databaseURL?: string;
    projectId?: string;
    storageBucket?: string;
    messagingSenderId?: string;
    appId?: string;
};

enum AuthApiTypeEnum {
    userDisabled = 'auth/user-disabled',
    userNotFound = 'auth/user-not-found',
    wrongPassword = 'auth/wrong-password',
    networkRequestFailed = 'auth/network-request-failed',
    expiredActionCode = 'auth/expired-action-code',
    invalidActionCode = 'auth/invalid-action-code',
    weakPassword = 'auth/weak-password',
}

interface AuthStateChangedCallbackI {
    (user: firebase.User | null): void;
}

export class FirebaseAuthService extends BaseAuthService {
    private auth: firebase.auth.Auth;

    constructor(config: FirebaseConfigT) {
        super();

        app.initializeApp(config);

        this.auth = app.auth();

        if (window.FIREBASE_TENANT_ID) {
            this.auth.tenantId = window.FIREBASE_TENANT_ID;
        }
    }

    checkAllowedAnonymouslyUserCreation = (): boolean => {
        return true;
    };

    private syncLanguage = (): void => {
        this.auth.languageCode = i18n.language;
    };

    onAuthStateChanged = (resolver: AuthStateChangedCallbackT): AuthUnsubscribeCallbackT => {
        return this.auth.onAuthStateChanged(resolver);
    };

    private parseCommonErrors(error: firebase.auth.Error | null): AuthApiError | null {
        const errorMapper: Record<string, AuthErrorTypeEnum> = {
            [AuthApiTypeEnum.networkRequestFailed]: AuthErrorTypeEnum.networkRequestFailed,
            [AuthApiTypeEnum.expiredActionCode]: AuthErrorTypeEnum.expiredActionCode,
            [AuthApiTypeEnum.invalidActionCode]: AuthErrorTypeEnum.invalidActionCode,
            [AuthApiTypeEnum.weakPassword]: AuthErrorTypeEnum.weakPassword,
            [AuthApiTypeEnum.wrongPassword]: AuthErrorTypeEnum.wrongPassword,
            [AuthApiTypeEnum.userDisabled]: AuthErrorTypeEnum.userDisabled,
            [AuthApiTypeEnum.userNotFound]: AuthErrorTypeEnum.userNotFound,
        };

        const authErrorType = error?.code ? errorMapper[error.code] : null;
        if (error?.code && authErrorType) {
            return new AuthApiError(authErrorType, error.message);
        }

        return null;
    }

    destroySession = () => {
        // nothing
    };

    async getAuthToken(): Promise<string | null> {
        const { auth } = this;

        if (!auth.currentUser) {
            return null;
        }

        const idToken = auth.currentUser.getIdToken();

        return idToken;
    }

    async getAuthHeaders(): Promise<Record<string, string> | null> {
        const token = await this.getAuthToken();

        return {
            Authorization: `Bearer ${token}`,
        };
    }

    getAuthUserClaims = async (): Promise<[AuthApiError | null, AuthUserClaimsT | null]> => {
        const { auth } = this;

        if (!auth.currentUser) {
            return [null, null];
        }

        const response = await auth.currentUser.getIdTokenResult();

        const authUserClaims: AuthUserClaimsT = {
            roles: response.claims.roles,
        };

        return [null, authUserClaims];
    };

    createAnonymouslyUser = async (): Promise<[AuthApiError | null, AuthUserT | null]> => {
        const { auth } = this;

        try {
            const userCredential = await auth.signInAnonymously();

            return [null, userCredential.user];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failAnonymouslySignIn, error.message);

            return [authError, null];
        }
    };

    signInWithEmailAndPassword = async (
        email: string,
        password: string,
    ): Promise<[AuthApiError | null, firebase.User | null]> => {
        const { auth } = this;

        try {
            const userCredential = await auth.signInWithEmailAndPassword(email, password);

            return [null, userCredential.user];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failSignIn, error.message);
            return [authError, null];
        }
    };

    doSignOut = async (): Promise<[AuthApiError | null, null]> => {
        try {
            await this.auth.signOut();
            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failSignOut, error.message);
            return [authError, null];
        }
    };

    sendVerificationEmail = async (user: firebase.User | null): Promise<[AuthApiError | null, null]> => {
        const ACTION_CODE_SETTINGS: ActionCodeSettings = {
            url: createSelfExternalUrl(authRoutesEnum.signIn),
        };

        if (!user) {
            const authError = new AuthApiError(AuthErrorTypeEnum.userIsNotAuthorized, 'empty currentUserCredential');

            return [authError, null];
        }

        try {
            await user.sendEmailVerification(ACTION_CODE_SETTINGS);

            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failSendEmailVerification, error.message);

            return [authError, null];
        }
    };

    sendPasswordResetEmail = async (email: string): Promise<[AuthApiError | null, null]> => {
        this.syncLanguage();

        const { auth } = this;
        try {
            await auth.sendPasswordResetEmail(email, {
                url: window.location.origin,
            });

            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            if (error.code === AuthApiTypeEnum.userNotFound) {
                const authError = new AuthApiError(AuthErrorTypeEnum.userNotFound, error.message);
                return [authError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failSendPasswordResetEmail, error.message);
            return [authError, null];
        }
    };

    updatePassword = async (code: string, password: string): Promise<[AuthApiError | null, null]> => {
        const { auth } = this;

        try {
            await auth.confirmPasswordReset(code, password);

            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failUpdatePassword, error.message);

            return [authError, null];
        }
    };

    signUp = async (email: string, password: string): Promise<[AuthApiError | null, firebase.User | null]> => {
        const { auth } = this;

        try {
            const credential = app.auth.EmailAuthProvider.credential(email, password);
            const usercred = await auth.currentUser?.linkWithCredential(credential);

            return [null, usercred?.user || null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failUpdatePassword, error.message);

            return [authError, null];
        }
    };

    verificationEmail = async (code: string): Promise<[AuthApiError | null, null]> => {
        const { auth } = this;

        try {
            await auth.applyActionCode(code);
            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failEmailVerification, error.message);

            return [authError, null];
        }
    };

    reauthenticateWithCredential = async (
        email: string,
        password: string,
    ): Promise<[AuthApiError | null, AuthUserT | null]> => {
        const { auth } = this;
        const { currentUser } = auth || {};

        try {
            const credential = app.auth.EmailAuthProvider.credential(email, password);
            const userCredential = await currentUser?.reauthenticateWithCredential(credential);

            return [null, userCredential?.user || null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failReauthenticateWithCredential, error.message);

            return [authError, null];
        }
    };

    changePassword = async (newPassword: string): Promise<[AuthApiError | null, null]> => {
        const { auth } = this;
        const { currentUser } = auth || {};

        try {
            await currentUser?.updatePassword(newPassword);
            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failChangePassword, error.message);

            return [authError, null];
        }
    };

    updateProfile = async (displayName: string, photoURL?: string): Promise<[AuthApiError | null, null]> => {
        const { auth } = this;
        const { currentUser } = auth || {};

        try {
            await currentUser?.updateProfile({
                displayName,
                photoURL,
            });
            return [null, null];
        } catch (error) {
            const parsedCommonError = this.parseCommonErrors(error);
            if (parsedCommonError) {
                return [parsedCommonError, null];
            }

            const authError = new AuthApiError(AuthErrorTypeEnum.failUpdateProfile, error.message);

            return [authError, null];
        }
    };

    createSignInUrlAfterChangePassword = (): {
        pathname: string;
        search: string;
    } => {
        return {
            pathname: authRoutesEnum.signIn,
            search: formatQuery({
                [QueryKeysEnum.successChangePassword]: true,
            }),
        };
    };

    checkIsContinueSignInRedirect = () => {
        return false;
    };

    createSignInUrl = (
        returnUrl?: string,
    ): {
        pathname: string;
        search: string;
    } => {
        return {
            pathname: authRoutesEnum.signIn,
            search: formatQuery({
                [QueryKeysEnum.returnUrl]: returnUrl,
            }),
        };
    };

    createSignUpUrl = (): string => {
        return authRoutesEnum.signUp;
    };

    createForgotPasswordUrl = (): string => {
        return authRoutesEnum.forgotPassword;
    };

    createChangePasswordUrl = (): string => {
        return authRoutesEnum.changePassword;
    };

    createAccountUrl = (): string => {
        throw new Error('not implement in firebase');
    };

    forceRefreshToken = async (): Promise<[AuthApiError | null, boolean | null]> => {
        throw new Error('not implement in firebase');
    };

    getAccountUrls = async (): Promise<[AuthApiError | null, AuthAccountUrlsT | null]> => {
        return [null, null];
    };
}

export const tryParseFirebaseConfig = (): FirebaseConfigT => {
    try {
        return JSON.parse(window.STRINGIFIED_FIREBASE_CONFIG);
    } catch (error) {
        console.error('Parse firebase config error!!');
        console.error(error);

        return {};
    }
};
