import { RequestStatusT } from 'common/utils/request-status';

export const CLOSE_RFQ = 'common/rfqs-changes/CLOSE_RFQ';
export const CHANGE_RFQ_REQUEST_BEGIN = 'common/rfqs-changes/CHANGE_RFQ_REQUEST_BEGIN';
export const CHANGE_RFQ_REQUEST_ERROR = 'common/rfqs-changes/CHANGE_RFQ_REQUEST_ERROR';
export const CHANGE_RFQ_REQUEST_SUCCESS = 'common/rfqs-changes/CHANGE_RFQ_REQUEST_SUCCESS';

export type RFQsChangesStateT = {
    id: RFQIdT | null;
    request: RequestStatusT;
};

export type CloseRFQActionT = {
    type: typeof CLOSE_RFQ;
    id: RFQIdT;
};

export type ChangeRFQBeginActionT = {
    type: typeof CHANGE_RFQ_REQUEST_BEGIN;
    id: RFQIdT;
};

export type ChangeRFQSuccessActionT = {
    type: typeof CHANGE_RFQ_REQUEST_SUCCESS;
    id: RFQIdT;
};

export type ChangeRFQErrorActionT = {
    type: typeof CHANGE_RFQ_REQUEST_ERROR;
    id: RFQIdT;
    error: Error;
};

export type RFQsChangesActionT =
    | CloseRFQActionT
    | ChangeRFQBeginActionT
    | ChangeRFQSuccessActionT
    | ChangeRFQErrorActionT;
