import { CompanyContactDetailsT, RolesWithSuccessorT } from 'common/store/members/models';
import { rolesNeededSuccessorSet } from './constants';
import { ApiCompanyContactDetailsT, ApiCompanyContactRoleT } from 'common/utils/api/models';

export const getFirstNeedSuccessorRole = (
    roles: Array<ApiCompanyContactRoleT> | null | undefined,
): ApiCompanyContactRoleT | null => {
    return (
        (roles || []).find((role) => {
            return role.role && rolesNeededSuccessorSet.has(role.role as RolesWithSuccessorT);
        }) || null
    );
};

export const prepareCompanyContactDetails = (
    contactDetails: ApiCompanyContactDetailsT | null,
): CompanyContactDetailsT | null => {
    if (!contactDetails) {
        return null;
    }

    return {
        ...contactDetails,
        fullName: [contactDetails.name, contactDetails.surname].filter(Boolean).join(' '),
    };
};
