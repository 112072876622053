import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TableMessage.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import BigEmptyIcon from 'common/icons/BigEmptyIcon';
import BigSearchIcon from 'common/icons/BigSearchIcon';
import BigLaneIcon from 'common/icons/BigLaneIcon';

const cx = classNames.bind(styles);

export enum TableMessageIconsEnum {
    notFound = 'notFound',
    empty = 'empty',
    warning = 'warning',
    lane = 'lane',
}

type PropsT = {
    className?: string;
    iconType: TableMessageIconsEnum;
    title: string;
    description?: string | null;
    testSelector?: string;
} & (
    | {
          isShowAction: true;
          onActionClick: () => void;
          actionTitle: string;
          actionTheme?: ButtonThemeEnum;
      }
    | {
          isShowAction: false;
      }
);

const TableMessage: React.FC<PropsT> = React.memo((props) => {
    const { className, iconType, title, description, isShowAction, testSelector } = props;

    const fullTestSelector = `${testSelector}_table-message`;

    return (
        <div className={cs(cx('wrap'), className)} data-test-selector={fullTestSelector}>
            <div className={cx('container')}>
                <div className={cx('icon')}>
                    {iconType === TableMessageIconsEnum.warning && <BigWarningIcon />}
                    {iconType === TableMessageIconsEnum.empty && <BigEmptyIcon />}
                    {iconType === TableMessageIconsEnum.notFound && <BigSearchIcon />}
                    {iconType === TableMessageIconsEnum.lane && <BigLaneIcon />}
                </div>
                <div className={cx('title')}>{title}</div>
                <div className={cx('description')}>{description}</div>
                {props.isShowAction && (
                    <div className={cx('action')}>
                        <Button
                            theme={props.actionTheme || ButtonThemeEnum.primary}
                            type="button"
                            className={cx('button')}
                            testSelector={fullTestSelector}
                            onClick={props.onActionClick}
                        >
                            {props.actionTitle}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
});

export default TableMessage;
