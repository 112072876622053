import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ControlsCell.scss';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import MoreIcon from 'common/icons/MoreIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { CompanyContactT } from 'common/store/members/models';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import AddUserIcon from 'common/icons/AddUserIcon';
import { useTranslation } from 'react-i18next';
import { RoleT, UserStatusEnum } from 'common/utils/api/models';
import { StyleGuideColorsEnum } from 'common/constants';
import UpdateUserIcon from 'common/icons/UpdateUserIcon';
import RestoreIcon from 'common/icons/RestoreIcon';
import DeleteUserIcon from 'common/icons/DeleteUserIcon';
import CloseIcon from 'common/icons/CloseIcon';
import { logWarning } from 'common/utils/logger';
import ContactBadgeIcon from 'common/icons/ContactBadgeIcon';

type PropsT = {
    className?: string;
    contact: CompanyContactT;
    onDeleteUser: (contact: CompanyContactT) => void;
    onDeleteContact: (contact: CompanyContactT) => void;
    onChangeUserRole: (contact: CompanyContactT) => void;
    onMakeMainContact: (contact: CompanyContactT) => void;
    onCancelInvite: (contact: CompanyContactT) => void;
    onRestoreUser: (contact: CompanyContactT) => void;
    onInviteUser: (email: string) => void;
    onResendInviteUser: (email: string, role: RoleT) => void;
    isLastRow: boolean;
    canInviteUsers: boolean;
};

const cx = classNames.bind(styles);

enum ActionsEnum {
    changeRole = 'change-role',
    deactivateUser = 'deactivate-user',
    inviteUser = 'invite-user',
    resendInviteUser = 'resend-invite-user',
    deleteContact = 'delete-contact',
    cancelInvite = 'cancel-invite',
    restoreUser = 'restore-user',
    makeMainContact = 'make-main-contact',
}

type PermissionsT = {
    canInviteUsers: boolean;
};

const getActions = (contact: CompanyContactT, permissions: PermissionsT): Array<ActionsEnum | null> => {
    if (contact.userStatus === UserStatusEnum.pending) {
        const actions: Array<ActionsEnum | null> = [ActionsEnum.cancelInvite];

        if (permissions.canInviteUsers) {
            actions.push(ActionsEnum.resendInviteUser);
        }

        return actions;
    }

    if (contact.userStatus === UserStatusEnum.archived) {
        return [ActionsEnum.restoreUser];
    }

    if (contact.userStatus === UserStatusEnum.active) {
        const actions = [ActionsEnum.changeRole, null, ActionsEnum.deactivateUser];

        if (!contact.main) {
            return [ActionsEnum.makeMainContact, ...actions];
        }

        return actions;
    }

    if (!contact.userId) {
        const actions: Array<ActionsEnum | null> = [ActionsEnum.deleteContact];

        if (permissions.canInviteUsers) {
            actions.unshift(ActionsEnum.inviteUser, null);
        }

        if (!contact.main) {
            return [ActionsEnum.makeMainContact, ...actions];
        }

        return actions;
    }

    return [];
};

const ControlsCell: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        onDeleteUser,
        onDeleteContact,
        onChangeUserRole,
        onMakeMainContact,
        onInviteUser,
        onResendInviteUser,
        contact,
        onCancelInvite,
        onRestoreUser,
        isLastRow,
        canInviteUsers,
    } = props;

    const { t } = useTranslation();

    const actionsMap: Record<ActionsEnum, DropdownControlOptionT> = {
        [ActionsEnum.makeMainContact]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <ContactBadgeIcon
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.slate}
                        />
                    }
                    label={t('common:team-members.actions.make-main-contact')}
                />
            ),
            onSelect: () => {
                onMakeMainContact(contact);
            },
        },
        [ActionsEnum.changeRole]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <UpdateUserIcon
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.slate}
                        />
                    }
                    label={t('common:team-members.actions.change-user-role')}
                />
            ),
            onSelect: () => {
                onChangeUserRole(contact);
            },
        },
        [ActionsEnum.inviteUser]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <AddUserIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                    }
                    label={t('common:team-members.actions.invite')}
                />
            ),
            onSelect: () => {
                onInviteUser(contact.email || '');
            },
        },
        [ActionsEnum.resendInviteUser]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <AddUserIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                    }
                    label={t('common:team-members.actions.resend-invite')}
                />
            ),
            onSelect: () => {
                const firstRole = contact.roles[0]?.role;
                if (!firstRole || !contact.email) {
                    logWarning('empty role or email');
                    return;
                }

                onResendInviteUser(contact.email, firstRole);
            },
        },
        [ActionsEnum.deactivateUser]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <DeleteUserIcon
                            fillColor={StyleGuideColorsEnum.white}
                            strokeColor={StyleGuideColorsEnum.tomatoRed}
                        />
                    }
                    label={t('common:team-members.actions.deactivate-user')}
                />
            ),
            onSelect: () => {
                onDeleteUser(contact);
            },
        },
        [ActionsEnum.deleteContact]: {
            label: (
                <DropdownControlOptionLabel
                    icon={
                        <DeleteUserIcon
                            fillColor={StyleGuideColorsEnum.white}
                            strokeColor={StyleGuideColorsEnum.tomatoRed}
                        />
                    }
                    label={t('common:team-members.actions.delete-contact')}
                />
            ),
            onSelect: () => {
                onDeleteContact(contact);
            },
        },
        [ActionsEnum.cancelInvite]: {
            label: (
                <DropdownControlOptionLabel
                    icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                    label={t('common:team-members.actions.cancel-invite')}
                />
            ),
            onSelect: () => {
                onCancelInvite(contact);
            },
        },
        [ActionsEnum.restoreUser]: {
            label: (
                <DropdownControlOptionLabel
                    icon={<RestoreIcon strokeColor={StyleGuideColorsEnum.slate} />}
                    label={t('common:team-members.actions.restore-user')}
                />
            ),
            onSelect: () => {
                onRestoreUser(contact);
            },
        },
    };

    const actions = getActions(contact, { canInviteUsers });

    const options = actions.map((action) => {
        if (action === null) {
            return SpecialOptionEnum.separator;
        }

        return actionsMap[action];
    });

    return (
        <div className={cx('wrap')}>
            <DropdownControl
                isInline
                className={className}
                options={options}
                renderTrigger={(isActive, onClick) => (
                    <TransparentTrigger
                        isPressed={isActive}
                        onClick={onClick}
                        leftIcon={<MoreIcon />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                )}
                overlayPosition={
                    isLastRow ? DropdownOverlayPositionEnum.topRight : DropdownOverlayPositionEnum.bottomRight
                }
            />
        </div>
    );
});

export default ControlsCell;
