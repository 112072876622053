import { ApiLocationT, CountryCodeT } from 'common/utils/api/models';

const prepareLocation = (location: LocationT | null): ApiLocationT | null => {
    if (!location) {
        return null;
    }

    return {
        country: (location.addressComponents?.countryCode || '') as CountryCodeT,
        street1: location.addressComponents?.street1 || '',
        street2: location.addressComponents?.street2 || '',
        zipCode: location.addressComponents?.zipCode || '',
        city: location.addressComponents?.city || '',
        longitude: location.point?.lng,
        latitude: location.point?.lat,
    };
};

export { prepareLocation };
