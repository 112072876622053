import * as React from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { QueryKeysEnum } from 'common/constants';
import { urlFactory } from 'common/utils/urls';
import { checkRegisteredUser } from 'common/store/auth/selectors';
import useQuery from 'common/utils/hooks/useQuery';

type PropsT = {};

type QueryT = {
    [QueryKeysEnum.returnUrl]: string;
};

const RedirectSignedUser: React.FC<PropsT> = React.memo(() => {
    const query = useQuery<QueryT>();

    const isRegisteredUser = useSelector(checkRegisteredUser);
    if (isRegisteredUser) {
        const returnUrl = query[QueryKeysEnum.returnUrl];

        return <Redirect to={urlFactory.toReturnUrl(returnUrl)} />;
    }

    return null;
});

export default RedirectSignedUser;
