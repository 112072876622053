import React from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { ApiContactFormalEnum, ApiContactFormalT } from 'common/utils/api/models';

import styles from './UserFormalDropdown.scss';
import { useTranslation } from 'react-i18next';

type FormalOptionT = {
    label: string;
    value: ApiContactFormalT;
};

const cx = classNames.bind(styles);

type ValueT = ApiContactFormalT | null;

type PropsT = {
    placeholder?: string;
    value: ValueT;
    isDisabled?: boolean;
    onChange: (value: ValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
};

export const FORMAL_T_MAP: Record<ApiContactFormalT, string> = {
    [ApiContactFormalEnum.mr]: 'common:formal.mr',
    [ApiContactFormalEnum.mrs]: 'common:formal.ms',
    [ApiContactFormalEnum.dr]: 'common:formal.dr',
    [ApiContactFormalEnum.mag]: 'common:formal.mag',
    [ApiContactFormalEnum.prof]: 'common:formal.prof',
};

const AVAILABLE_FORMALS = [
    ApiContactFormalEnum.mr,
    ApiContactFormalEnum.mrs,
    ApiContactFormalEnum.dr,
    ApiContactFormalEnum.mag,
    ApiContactFormalEnum.prof,
];

const UserFormalDropdown: React.FC<PropsT> = React.memo((props) => {
    const { value, placeholder, onChange, isDisabled, hasWarning, hasError, hasChanges, onBlur, onFocus } = props;

    const { t } = useTranslation();

    const options: Array<FormalOptionT> = AVAILABLE_FORMALS.map((formal) => ({
        label: t(FORMAL_T_MAP[formal]),
        value: formal,
    }));

    const renderOption = (option: FormalOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return <span>{option?.label}</span>;
    };

    const handleSelect = (value: ValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: FormalOptionT) => option?.value;

    return (
        <DropdownInput<FormalOptionT, ValueT>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
});

export default UserFormalDropdown;
