import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { FieldsEnum, FormValuesT } from './constants';
import { getDateFromDate } from 'common/utils/time';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.dictTrailerId]: values[FieldsEnum.dictTrailerId] || undefined,

        [QueryFiltersKeysEnum.address]: undefined,

        [QueryFiltersKeysEnum.createdById]: values[FieldsEnum.createdById] || undefined,
        [QueryFiltersKeysEnum.createdByName]: values[FieldsEnum.createdByName] || undefined,

        [QueryFiltersKeysEnum.validFromStart]: undefined,
        [QueryFiltersKeysEnum.validFromFinish]: undefined,

        [QueryFiltersKeysEnum.validTillStart]: undefined,
        [QueryFiltersKeysEnum.validTillFinish]: undefined,

        [QueryFiltersKeysEnum.createdDateStart]: undefined,
        [QueryFiltersKeysEnum.createdDateFinish]: undefined,
    };

    const address = simpleStringFormatter(values[FieldsEnum.address]);
    if (address) {
        queryFilters[QueryFiltersKeysEnum.address] = address;
    }

    const validFromDates = values[FieldsEnum.validFromDates];
    if (validFromDates?.from) {
        queryFilters[QueryFiltersKeysEnum.validFromStart] = getDateFromDate(validFromDates.from);
    }

    if (validFromDates?.to) {
        queryFilters[QueryFiltersKeysEnum.validFromFinish] = getDateFromDate(validFromDates.to);
    }

    const validTillDates = values[FieldsEnum.validTillDates];
    if (validTillDates?.from) {
        queryFilters[QueryFiltersKeysEnum.validTillStart] = getDateFromDate(validTillDates.from);
    }

    if (validTillDates?.to) {
        queryFilters[QueryFiltersKeysEnum.validTillFinish] = getDateFromDate(validTillDates.to);
    }

    const createDates = values[FieldsEnum.createdDates];
    if (createDates?.from) {
        queryFilters[QueryFiltersKeysEnum.createdDateStart] = getDateFromDate(createDates.from);
    }

    if (createDates?.to) {
        queryFilters[QueryFiltersKeysEnum.createdDateFinish] = getDateFromDate(createDates.to);
    }

    return queryFilters;
};

export default getQueryFilters;
