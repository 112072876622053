import * as React from 'react';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    isPaid: boolean;
    isSymmetrical?: boolean;
};

const InvoiceBinaryStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { className, isPaid, isSymmetrical } = props;

    const { t } = useTranslation();

    let label = t('common:invoice-binary-status.not-paid');
    let theme = PillLabelThemeEnum.orange;

    if (isPaid) {
        label = t('common:invoice-binary-status.paid');
        theme = PillLabelThemeEnum.brandAccent;
    }

    return (
        <PillLabel isNoWrap className={className} theme={theme} isSymmetrical={isSymmetrical}>
            {label}
        </PillLabel>
    );
});

export default InvoiceBinaryStatusPill;
