import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './BaseShipperContractLaneDetailsLayout.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ShipperContractLaneDetailsMap, {
    ShipperContractLaneAddressT,
} from './ShipperContractLaneDetailsMap/ShipperContractLaneDetailsMap';
import { selectShipperContractLaneDetailsState } from 'common/store/shipper-contract-lane-details/selectors';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import CommonEditableShipperContractLaneDetailsLayout from 'common/layouts/CommonEditableShipperContractLaneDetailsLayout/CommonEditableShipperContractLaneDetailsLayout';
import GoogleMapContext from 'common/contexts/google-map-context';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    partnerId: PartnerIdT;
    contractId: ShipperContractIdT;
    laneId: ShipperContractLaneIdT;
    onOpenUserDetails?: (userId: UserIdT) => void;
    isReadOnly: boolean;
};

const BaseShipperContractLaneDetailsLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, contractId, laneId, onClose, isReadOnly, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const { data } = useSelector(selectShipperContractLaneDetailsState(laneId));

    const [originRadiusKm, setOriginRadiusKm] = useState<number>(0);

    useEffect(() => {
        setOriginRadiusKm(data?.originRadius || 0);
    }, [data?.originRadius]);

    const originAddress = useMemo((): ShipperContractLaneAddressT | null => {
        return {
            point:
                data?.originPoint?.latitude && data?.originPoint?.longitude
                    ? {
                          lat: data?.originPoint?.latitude,
                          lng: data?.originPoint?.longitude,
                      }
                    : null,
            radiusKm: originRadiusKm,
        };
    }, [data, originRadiusKm]);

    const [destinationRadiusKm, setDestinationRadiusKm] = useState<number>(0);

    useEffect(() => {
        setDestinationRadiusKm(data?.destinationRadius || 0);
    }, [data?.destinationRadius]);

    const destinationAddress = useMemo((): ShipperContractLaneAddressT | null => {
        return {
            point:
                data?.destinationPoint?.latitude && data?.destinationPoint?.longitude
                    ? {
                          lat: data?.destinationPoint?.latitude,
                          lng: data?.destinationPoint?.longitude,
                      }
                    : null,
            radiusKm: destinationRadiusKm,
        };
    }, [data, destinationRadiusKm]);

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <HorizontalColumnsLayout
                leftColumnTitle={t('common:shipper-lane-details.tabs.form')}
                leftColumnNode={
                    <SideBarLayoutContextWrap>
                        <CommonEditableShipperContractLaneDetailsLayout
                            partnerId={partnerId}
                            shipperContractId={contractId}
                            shipperContractLaneId={laneId}
                            onClose={onClose}
                            onOpenUserDetails={onOpenUserDetails}
                            onChangeOriginRadiusKm={setOriginRadiusKm}
                            onChangeDestinationRadiusKm={setDestinationRadiusKm}
                            isReadOnly={isReadOnly}
                        />
                    </SideBarLayoutContextWrap>
                }
                rightColumnTitle={t('common:shipper-lane-details.tabs.map')}
                rightColumnNode={
                    <ShipperContractLaneDetailsMap
                        originAddress={originAddress}
                        destinationAddress={destinationAddress}
                    />
                }
            />
        </GoogleMapContext.Provider>
    );
});

export default BaseShipperContractLaneDetailsLayout;
