import React, { useMemo } from 'react';
import styles from './DocumentEventsSummary.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import { DocumentVersionT } from 'common/store/documents/models';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    version: Pick<
        DocumentVersionT,
        | 'createdByBroker'
        | 'createdById'
        | 'createdByName'
        | 'createdDate'
        | 'lastEditByBroker'
        | 'lastEditById'
        | 'lastEditByName'
        | 'lastEditDate'
        | 'reviewedById'
        | 'reviewedByName'
        | 'reviewedDate'
        | 'revokedById'
        | 'revokedByName'
        | 'revokedDate'
    > | null;
    onOpenUserDetails: (userId: UserIdT) => void;
};

const DocumentEventsSummary: React.FC<PropsT> = (props) => {
    const { className, version, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const uploadEventDetails: Array<InfoTableRowT> = useMemo(
        () => [
            {
                icon: null,
                name: t('common:document.details.uploaded-date'),
                rightNode: <DateFormatter date={version?.createdDate} />,
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-date',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:document.details.uploaded-by'),
                rightNode: (
                    <UserLinkFormatter
                        fullName={version?.createdByName}
                        userId={version?.createdById}
                        byBroker={version?.createdByBroker}
                        onOpenUserDetails={onOpenUserDetails}
                    />
                ),
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-user',
            },
        ],
        [version],
    );

    const reviewEventDetails: Array<InfoTableRowT> = useMemo(() => {
        if (!version?.reviewedDate) {
            return [];
        }

        return [
            {
                icon: null,
                name: t('common:document.details.reviewed-date'),
                rightNode: <DateFormatter date={version?.reviewedDate} />,
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'review-date',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:document.details.reviewed-by'),
                rightNode: (
                    <UserLinkFormatter
                        fullName={version?.reviewedByName}
                        userId={version?.reviewedById}
                        byBroker
                        onOpenUserDetails={onOpenUserDetails}
                    />
                ),
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'review-user',
            },
        ];
    }, [version]);

    const revokeEventDetails: Array<InfoTableRowT> = useMemo(() => {
        if (!version?.revokedDate) {
            return [];
        }
        return [
            {
                icon: null,
                name: t('common:document.details.revoke-date'),
                rightNode: <DateFormatter date={version?.revokedDate} />,
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'revoke-date',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:document.details.revoke-by'),
                rightNode: (
                    <UserLinkFormatter
                        fullName={version?.revokedByName}
                        userId={version?.revokedById}
                        byBroker
                        onOpenUserDetails={onOpenUserDetails}
                    />
                ),
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'revoke-user',
            },
        ];
    }, [version]);

    const editEventDetails: Array<InfoTableRowT> = useMemo(
        () => [
            {
                icon: null,
                name: t('common:document.details.edit-date'),
                rightNode: <DateFormatter date={version?.lastEditDate} />,
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'edit-date',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:document.details.edit-by'),
                rightNode: (
                    <UserLinkFormatter
                        fullName={version?.lastEditByName}
                        userId={version?.lastEditById}
                        byBroker={version?.lastEditByBroker}
                        onOpenUserDetails={onOpenUserDetails}
                    />
                ),
                isBoldRightNode: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'edit-user',
            },
        ],
        [version],
    );

    if (!version) {
        return null;
    }

    return (
        <div className={cs(cx('events'), className)}>
            <InfoTable
                className={cx('table', 'table--upload-details')}
                rows={uploadEventDetails}
                testSelector="upload-event"
            />
            <InfoTable className={cx('table')} rows={reviewEventDetails} testSelector="review-event" />
            <InfoTable className={cx('table')} rows={revokeEventDetails} testSelector="revoke-event" />
            <InfoTable className={cx('table')} rows={editEventDetails} testSelector="edit-event" />
        </div>
    );
};

export default DocumentEventsSummary;
