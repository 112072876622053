import React from 'react';

import classNames from 'classnames/bind';
import styles from './TimeWindowZoneText.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { RangeSelectorRangeT } from 'common/components/RangeSelector/models';
import { useTranslation } from 'react-i18next';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

export type PropsT = {
    currentRanges: RangeSelectorRangeT[];
};

const TimeWindowZoneText: React.FC<PropsT> = React.memo((props) => {
    const { currentRanges } = props;

    const { t } = useTranslation();

    return (
        <Tooltip
            position={TooltipPositionEnum.topLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black}>
                    <div className={cx('content')}>
                        <div className={cx('content__left-icon')}>
                            <StopWarningIcon opacity={0.7} fillColor={StyleGuideColorsEnum.orange} />
                        </div>
                        <div className={cx('content__content')}>
                            {currentRanges?.map((range, rangeIndex) => {
                                return (
                                    <div key={rangeIndex} className={cx('text')}>
                                        {range.label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </TooltipContent>
            }
        >
            {() => (
                <span className={cx('zone-title')} data-test-selector="zone-text">
                    {t('common:new-order-form.surcharge')}
                </span>
            )}
        </Tooltip>
    );
});

export default TimeWindowZoneText;
