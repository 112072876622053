import * as emailValidator from 'email-validator';
import { isEmpty, isLessMinLength, isMoreMaxLength, isZeroNumber } from './validators';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import { checkIsEmptyPhoneNumber, checkIsPossiblePhoneNumber } from 'common/utils/phone-number';

type FormErrorsT<KeyT extends string> = Partial<Record<KeyT, string>>;

export const checkNotZero = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, string | null | undefined>>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        if (isZeroNumber(value)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkNotEmpty = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, object | number | string | null | undefined>>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        if (isEmpty(value)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkFullRange = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, DateRangeT | null | undefined>>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        if (value && (!value?.from || !value?.to)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkDateIsAfterDay = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, Date | null | undefined>>,
    limit: string | number | Date | null | undefined,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    if (!limit) {
        return errors;
    }

    fields.forEach((field) => {
        const value = values[field];
        if (!value) {
            return;
        }

        const valueDate = moment(value);
        if (valueDate.isValid() && !valueDate.isSameOrAfter(limit, 'day')) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkDateIsBeforeDay = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, Date | null | undefined>>,
    limit: string | number | Date | null | undefined,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    if (!limit) {
        return errors;
    }

    fields.forEach((field) => {
        const value = values[field];
        if (!value) {
            return;
        }

        const valueDate = moment(value);
        if (valueDate.isValid() && !valueDate.isSameOrBefore(limit, 'day')) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkRangeNotIncludeDisabledDates = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, DateRangeT | null | undefined>>,
    disabledDates: string[],
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        const isDisableValue =
            value?.from &&
            value?.to &&
            disabledDates.some((disabledDate) => {
                return moment(disabledDate).isBetween(value.from, value.to);
            });

        if (value && isDisableValue) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkNotEmptyString = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, string | null | undefined>>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        if (typeof value === 'string' && !value) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkMinLength = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    minLength: number,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];
        if (isLessMinLength(value, minLength)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkMaxLength = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    maxLength: number,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];
        if (isMoreMaxLength(value, maxLength)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkMinValue = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    minValue: number,
    getErrorLabel: (params: { value: number }) => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const parsedValue = parseSimpleNumber(values[field]);

        if (isNumber(parsedValue) && parsedValue < minValue) {
            errors[field] = getErrorLabel({
                value: minValue,
            });
        }
    });

    return errors;
};

export const checkStrictMinValue = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    minValue: number,
    getErrorLabel: (params: { value: number }) => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const parsedValue = parseSimpleNumber(values[field]);

        if (isNumber(parsedValue) && parsedValue <= minValue) {
            errors[field] = getErrorLabel({
                value: minValue,
            });
        }
    });

    return errors;
};

export const checkMaxValue = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    maxValue: number,
    getErrorLabel: (params: { value: number }) => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const parsedValue = parseSimpleNumber(values[field]);

        if (isNumber(parsedValue) && parsedValue > maxValue) {
            errors[field] = getErrorLabel({
                value: maxValue,
            });
        }
    });

    return errors;
};

export const checkStrictMaxValue = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    maxValue: number,
    getErrorLabel: (params: { value: number }) => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const parsedValue = parseSimpleNumber(values[field]);

        if (isNumber(parsedValue) && parsedValue >= maxValue) {
            errors[field] = getErrorLabel({
                value: maxValue,
            });
        }
    });

    return errors;
};

export const checkEmail = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Partial<Record<KeyT, object | number | string | null | undefined>>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        if (value && typeof value === 'string' && !emailValidator.validate(value)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkDate = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, Date | null | undefined>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        const isValidDate = moment(value).isValid();
        if (value && !isValidDate) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkBirthday = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, Date | null | undefined>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];

        const isValidBirthday = !!value && moment(value).valueOf() < Date.now();
        if (!isValidBirthday) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkPhoneNumber = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const value = values[field];
        const parsedValue = typeof value === 'string' ? value.trim() : '';

        if (checkIsEmptyPhoneNumber(parsedValue)) {
            return;
        }

        if (!checkIsPossiblePhoneNumber(parsedValue)) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};

export const checkRoundValue = <KeyT extends string>(
    fields: readonly KeyT[],
    values: Record<KeyT, string | null | undefined>,
    getErrorLabel: () => string,
): FormErrorsT<KeyT> => {
    const errors: FormErrorsT<KeyT> = {};

    fields.forEach((field) => {
        const parsedValue = parseSimpleNumber(values[field]);

        if (isNumber(parsedValue) && parsedValue % 1 !== 0) {
            errors[field] = getErrorLabel();
        }
    });

    return errors;
};
