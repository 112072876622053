import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ShipperContractLaneDetailsPage from './ShipperContractLaneDetailsPage/ShipperContractLaneDetailsPage';
import ShipperContractPage from 'shipper/layouts/ProfilePage/ShipperContractsPage/ShipperContractPage/ShipperContractPage';
import { ShipperContractsRoutesEnum } from 'common/constants';

type PropsT = {};

const ShipperContractsPage: React.FC<PropsT> = React.memo(() => {
    return (
        <>
            <Switch>
                <Route path={ShipperContractsRoutesEnum.shipperContractLaneDetails}>
                    <ShipperContractLaneDetailsPage />
                </Route>
                <Route path={ShipperContractsRoutesEnum.shipperContract}>
                    <ShipperContractPage />
                </Route>
            </Switch>
        </>
    );
});

export default ShipperContractsPage;
