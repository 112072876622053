import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './FlagCell.scss';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import DoneIcon from 'common/icons/DoneIcon';

type PropsT = {
    className?: string;
    isShowFlag: boolean | null | undefined;
    fillColor?: StyleGuideColorsEnum;
};

const cx = classNames.bind(styles);

const FlagCell: React.FC<PropsT> = React.memo((props) => {
    const { isShowFlag, fillColor, className } = props;

    return (
        <div className={cs(cx('flag'), className)}>
            {isShowFlag ? (
                <DoneIcon fillColor={fillColor || StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
            ) : null}
        </div>
    );
});

export default FlagCell;
