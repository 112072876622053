import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import {
    ShipperContractDetailsT,
    UpdateShipperContractChangesT,
    UploadShipperContractDetailsT,
} from 'common/store/shipper-contract-details/models';

export type ShipperContractDetailsItemStateT = {
    fetchRequest: RequestStatusT;
    data: ShipperContractDetailsT | null;
};

export const initialShipperContractDetailsItemState: ShipperContractDetailsItemStateT = {
    fetchRequest: requestStatus.initial,
    data: null,
};

export type ShipperContractDetailsStateT = {
    updateRequest: RequestStatusT;
    detailsById: Record<ShipperContractIdT, ShipperContractDetailsItemStateT>;
    downloadRequest: RequestStatusT;
};

const initialState: ShipperContractDetailsStateT = {
    updateRequest: requestStatus.initial,
    detailsById: {},
    downloadRequest: requestStatus.initial,
};

export const shipperContractDetailsSlice = createSlice({
    name: 'shipperContractDetails',
    initialState,
    reducers: {
        fetchShipperContractDetails: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchShipperContractDetailsBegin: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            const { shipperContractId } = action.payload;
            const detailsItem = state.detailsById[shipperContractId] || initialShipperContractDetailsItemState;

            state.detailsById[shipperContractId] = {
                ...detailsItem,
                fetchRequest: requestStatus.loading,
            };
        },
        fetchShipperContractDetailsSuccess: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                data: ShipperContractDetailsT | null;
            }>,
        ) => {
            const { shipperContractId, data } = action.payload;
            const detailsItem = state.detailsById[shipperContractId] || initialShipperContractDetailsItemState;

            state.detailsById[shipperContractId] = {
                ...detailsItem,
                fetchRequest: requestStatus.ok,
                data,
            };
        },
        fetchShipperContractDetailsError: (
            state,
            action: PayloadAction<{
                shipperContractId: ShipperContractIdT;
                error: Error;
            }>,
        ) => {
            const { shipperContractId, error } = action.payload;
            const detailsItem = state.detailsById[shipperContractId] || initialShipperContractDetailsItemState;

            state.detailsById[shipperContractId] = {
                ...detailsItem,
                fetchRequest: requestStatus.setError(error),
            };
        },
        uploadShipperContract: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                file: File;
                details: UploadShipperContractDetailsT;
            }>,
        ) => {
            // nothing
        },
        revokeShipperContract: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                reason: string;
            }>,
        ) => {
            // nothing
        },
        updateShipperContract: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                detailsChanges: UpdateShipperContractChangesT;
                file: File | null;
            }>,
        ) => {
            // nothing
        },
        updateShipperContractDetailsBegin: (state, action: PayloadAction<{}>) => {
            state.updateRequest = requestStatus.loading;
        },
        updateShipperContractDetailsSuccess: (state, action: PayloadAction<{}>) => {
            state.updateRequest = requestStatus.ok;
        },
        updateShipperContractDetailsError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;
            state.updateRequest = requestStatus.setError(error);
        },
        downloadShipperContract: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                shipperContractId: ShipperContractIdT;
                fileName: string | null;
            }>,
        ) => {
            // nothing
        },
        downloadShipperContractDetailsBegin: (state, action: PayloadAction<{}>) => {
            state.downloadRequest = requestStatus.loading;
        },
        downloadShipperContractDetailsSuccess: (state, action: PayloadAction<{}>) => {
            state.downloadRequest = requestStatus.ok;
        },
        downloadShipperContractDetailsError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;

            state.downloadRequest = requestStatus.setError(error);
        },
    },
});

export const {
    fetchShipperContractDetails,
    fetchShipperContractDetailsBegin,
    fetchShipperContractDetailsSuccess,
    fetchShipperContractDetailsError,
    revokeShipperContract,
    uploadShipperContract,
    updateShipperContract,
    updateShipperContractDetailsBegin,
    updateShipperContractDetailsError,
    updateShipperContractDetailsSuccess,
    downloadShipperContract,
    downloadShipperContractDetailsBegin,
    downloadShipperContractDetailsSuccess,
    downloadShipperContractDetailsError,
} = shipperContractDetailsSlice.actions;

export default shipperContractDetailsSlice.reducer;
