import {
    EMAIL_VERIFICATION_REQUEST_ERROR,
    RESET_PASSWORD_REQUEST_ERROR,
    SIGN_IN_REQUEST_ERROR,
    SIGN_UP_REQUEST_ERROR,
    UPDATE_PASSWORD_REQUEST_ERROR,
} from 'common/store/auth/types';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';
import { AnyAction } from 'redux';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import {
    ApiRequestErrorTypeEnum,
    checkIsApiRequestError,
    checkIsForbiddenError,
    checkIsUnauthorizedError,
} from 'common/utils/api/errors/api-request-error';
import { CHECK_GPS_STATUS_REQUEST_ERROR } from 'common/store/gps-tracking-status/types';

export const checkError = (actionType: string): boolean => {
    return /(_ERROR|Error)$/.test(actionType);
};

const IGNORE_ACTION_TYPES_SET = new Set([
    SIGN_IN_REQUEST_ERROR,
    RESET_PASSWORD_REQUEST_ERROR,
    SIGN_UP_REQUEST_ERROR,
    EMAIL_VERIFICATION_REQUEST_ERROR,
    UPDATE_PASSWORD_REQUEST_ERROR,
    CHECK_GPS_STATUS_REQUEST_ERROR,
]);

const IGNORE_API_ERRORS_TYPES_SET = new Set([
    AuthErrorTypeEnum.failReauthenticateWithCredential,
    AuthErrorTypeEnum.failUpdatePassword,
    AuthErrorTypeEnum.wrongPassword,
    AuthErrorTypeEnum.wrongRole,
]);

const IGNORE_API_ERRORS_SUB_TYPES_SET = new Set([
    TranziitApiRequestErrorSubTypeEnum.notFoundCompany,
    TranziitApiRequestErrorSubTypeEnum.notFoundUser,
    TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist,
    TranziitApiRequestErrorSubTypeEnum.notAuthorizedUser,
    TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist,
    TranziitApiRequestErrorSubTypeEnum.expiredContextPreviewReserve,
    TranziitApiRequestErrorSubTypeEnum.assignmentSelfCostPrediction,
    TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_CapacityExcess,
    TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_Overweight,
    TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteNotFound,
    TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable,
]);

export const extractErrorFromAction = (action: AnyAction): Error | null => {
    return action?.error || action?.payload?.error || null;
};

export const checkIgnoreError = (action: AnyAction): boolean => {
    const actionError = extractErrorFromAction(action);

    if (
        (checkIsAuthApiError(actionError) && actionError?.type === AuthErrorTypeEnum.networkRequestFailed) ||
        (checkIsApiRequestError(actionError) && actionError?.type === ApiRequestErrorTypeEnum.networkError)
    ) {
        return true;
    }

    if (checkIsApiRequestError(actionError) && actionError?.type === ApiRequestErrorTypeEnum.timeoutError) {
        return false;
    }

    if (
        checkIsForbiddenError(actionError) ||
        (checkIsAuthApiError(actionError) && actionError?.type === AuthErrorTypeEnum.wrongRole)
    ) {
        return true;
    }

    return (
        IGNORE_ACTION_TYPES_SET.has(action.type) ||
        checkIsUnauthorizedError(actionError) ||
        (checkIsAuthApiError(actionError) && IGNORE_API_ERRORS_TYPES_SET.has(actionError?.type)) ||
        (checkIsTranziitApiRequestError(actionError) && IGNORE_API_ERRORS_SUB_TYPES_SET.has(actionError?.subType))
    );
};
