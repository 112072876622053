import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ContactError.scss';
import ModalDialog, { ModalDialogPropsT } from 'common/components/Modal/ModalDialog/ModalDialog';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    testSelector?: string;
    className?: string;
};

const ContactError: React.FC<PropsT> = React.memo((props) => {
    const { testSelector, className } = props;

    const { t } = useTranslation();

    const modalDialogProps: ModalDialogPropsT = {
        width: 340,
        testSelector: `${testSelector}_contact-error`,
        actions: [],
        icon: <BigWarningIcon />,
        title: t('common:common-errors.something-went-wrong.title'),
        message: t('common:common-errors.something-went-wrong.description'),
        className: cx('content'),
    };

    return (
        <div className={className}>
            <ModalDialog {...modalDialogProps} />
        </div>
    );
});

export default ContactError;
