import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { CountriesListT } from './models';
import { CountriesDictStateT } from './types';
import { createSelector } from 'reselect';
import { isNonNil } from 'common/utils';

export const selectCountriesState = (state: SharableStateT): CountriesDictStateT => {
    return state.countriesDict;
};

export const selectCountriesDict = createSelector(selectCountriesState, (countriesState): CountriesListT | null => {
    const { allCodes, byCode } = countriesState;

    return allCodes.map((code) => byCode[code]).filter(isNonNil);
});

export const selectCountriesDictRequest = (state: SharableStateT): RequestStatusT => state.countriesDict.request;

export const selectCountriesAllCodes = (state: SharableStateT): CountriesDictStateT['allCodes'] => {
    return state.countriesDict.allCodes;
};

export const selectCountriesByCode = (state: SharableStateT): CountriesDictStateT['byCode'] =>
    state.countriesDict.byCode;

export const selectCountriesAllLangCodes = (state: SharableStateT): CountriesDictStateT['allLangCodes'] => {
    return state.countriesDict.allLangCodes;
};

export const selectCountriesByLangCode = (state: SharableStateT): CountriesDictStateT['byLangCode'] => {
    return state.countriesDict.byLangCode;
};
