import * as React from 'react';

import cs from 'classnames';

import classNames from 'classnames/bind';
import styles from './PriceDetailsHeader.scss';

const cx = classNames.bind(styles);

type PropsT = {
    title: React.ReactNode;
    rightNode?: React.ReactNode;
    className?: string;
};

const PriceDetailsHeader: React.FC<PropsT> = React.memo((props) => {
    const { title, rightNode, className } = props;

    return (
        <div className={cs(cx('row'), className)}>
            <div
                className={cx('row__column', {
                    'row__column--title': true,
                })}
            >
                <span className={cx('title')}>{title}</span>
            </div>
            {rightNode && (
                <div
                    className={cx('row__column', {
                        'row__column--right-node': true,
                    })}
                >
                    {rightNode}
                </div>
            )}
        </div>
    );
});

export default PriceDetailsHeader;
