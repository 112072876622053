import React, { useCallback } from 'react';
import RangeSelector, { RangeSelectorMarkT, RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import { getTimeRelativeStartDay, MS_IN_DAY, MS_IN_HOUR } from 'common/utils/time';

type PropsT = {
    className?: string;
    isDisabled?: boolean;
    isLocked?: boolean;
    isLockedLeftRangeControl?: boolean;
    isLockedRightRangeControl?: boolean;
    values: TimeWindowT | null;
    onChange: (values: TimeWindowT) => void;
    range: TimeWindowT;
    step?: number;
    minRangeWidth?: number;
    maxRangeWidth?: number;
    availableValues: TimeWindowT;
    ranges: RangeSelectorRangeT[];
    marks?: Array<RangeSelectorMarkT>;
    hasStartStep?: boolean;
    hasEndStep?: boolean;
    renderZoneTextNode?: (currentRanges: RangeSelectorRangeT[] | null) => React.ReactNode;
};

const TimeWindowPicker: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        values,
        isDisabled,
        isLocked,
        isLockedLeftRangeControl,
        isLockedRightRangeControl,
        range,
        minRangeWidth,
        maxRangeWidth,
        availableValues,
        ranges,
        onChange,
        hasStartStep,
        hasEndStep,
        renderZoneTextNode,
        marks,
    } = props;

    const handleSelect = useCallback(
        (selectedValue: TimeWindowT) => {
            if (isLocked) {
                return;
            }

            onChange(selectedValue);
        },
        [isLocked, onChange],
    );

    const step = props.step || MS_IN_HOUR / 2;

    const shownStepCount = MS_IN_DAY / (step * 24);
    const shownLabelCount = shownStepCount * 3;

    const labelsConfig = React.useMemo(() => {
        return {
            shownStepCount,
            shownLabelCount,
            hasStartStep,
            hasEndStep,
        };
    }, [shownStepCount, shownLabelCount, hasStartStep, hasEndStep]);

    return (
        <RangeSelector
            className={className}
            isDisabled={isDisabled}
            valuesRange={range}
            availableValues={availableValues || range}
            ranges={ranges || []}
            values={values}
            valueStep={step}
            isLocked={isLocked}
            isLockedLeftRangeControl={isLockedLeftRangeControl}
            isLockedRightRangeControl={isLockedRightRangeControl}
            minValuesRangeWidth={minRangeWidth}
            maxValuesRangeWidth={maxRangeWidth}
            labelsConfig={labelsConfig}
            marks={marks}
            renderZoneTextNode={renderZoneTextNode}
            renderLabel={(value: number) => {
                const relativeTime = getTimeRelativeStartDay(value);
                if (value && relativeTime === '00:00') {
                    return '23:59';
                }

                return relativeTime;
            }}
            onSelect={handleSelect}
        />
    );
});

export default TimeWindowPicker;
