import { FetchError } from './errors/fetch-error';
import { ApiRequestError, ApiRequestErrorTypeEnum } from './errors/api-request-error';
import { stringifyQuery } from '../stringify-query';
import { FetchConfigT } from './do-fetch-models';

const tryParseJson = <D>(text: string): D | string => {
    try {
        return JSON.parse(text);
    } catch (error) {
        console.warn(`Couldn't parse JSON: "${text}". Error: "${error.message}"`);
        return text;
    }
};

const parseResponseContentType = (responseContentType: string | null): FetchConfigT['expectedResponseContentType'] => {
    if (responseContentType?.includes('json')) {
        return 'json';
    }

    if (responseContentType?.includes('pdf') || responseContentType?.includes('image')) {
        return 'blob';
    }

    return 'unknown';
};

const doFetch = async <D, E>(config: FetchConfigT): Promise<[ApiRequestError | null, D | null]> => {
    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), config.timeout);

        const options: RequestInit = {
            method: config.method,
            headers: config.headers,
            signal: controller.signal,
        };

        if (config.data instanceof FormData) {
            // browser automatically add this header with boundary
            // @ts-ignore
            delete options.headers['Content-Type'];
        }

        if (config.data) {
            if (config.data instanceof FormData) {
                options.body = config.data;
            } else if (config.isRawData) {
                options.body = config.data;
            } else {
                options.body = JSON.stringify(config.data);
            }
        }

        let url = `${config.basepath}${config.path}`;
        if (config.query) {
            url = `${url}${stringifyQuery(config.query)}`;
        }

        const response = await fetch(url, options);
        clearTimeout(timeoutId);

        if (response.status < 200 || response.status >= 400) {
            const rawResponseText = await response.text();

            const fetchError = new FetchError({
                message: `${response.status} status code`,
                httpStatusCode: response.status,
                requestConfig: config,
                response: tryParseJson<D>(rawResponseText),
            });

            const apiError = new ApiRequestError(fetchError, ApiRequestErrorTypeEnum.badRequest);
            return [apiError, null];
        }

        const rawResponseContentType = response.headers.get('content-type');
        const responseContentType = parseResponseContentType(rawResponseContentType);
        if (
            config.expectedResponseContentType !== responseContentType &&
            config.expectedResponseContentType !== 'unknown'
        ) {
            const fetchError = new FetchError({
                message: `Wrong response type: ${config.expectedResponseContentType} !== ${responseContentType}`,
                httpStatusCode: response.status,
                requestConfig: config,
                response: '',
            });

            const apiError = new ApiRequestError(fetchError, ApiRequestErrorTypeEnum.badRequest);
            return [apiError, null];
        }

        switch (config.expectedResponseContentType) {
            case 'blob': {
                const rawResponseBlob = await response.blob();
                // @ts-ignore
                return [null, rawResponseBlob];
                break;
            }
            case 'json': {
                const rawResponseText = await response.text();
                const parsedResponse = tryParseJson<D>(rawResponseText);
                // @ts-ignore
                return [null, parsedResponse];
            }
            case 'unknown':
            default: {
                const rawResponseText = await response.text();
                // @ts-ignore
                return [null, rawResponseText];
                break;
            }
        }
    } catch (error) {
        const errorMessage = error.message || 'Something went wrong';

        if (errorMessage.toLowerCase().includes('aborted')) {
            const fetchError = new FetchError({
                message: `Timeout error: ${errorMessage}`,
                httpStatusCode: 0,
                requestConfig: config,
                response: {},
            });
            const apiError = new ApiRequestError(fetchError, ApiRequestErrorTypeEnum.timeoutError);
            return [apiError, null];
        }

        if (errorMessage.toLowerCase().includes('network') || errorMessage.toLowerCase().includes('failed to fetch')) {
            const fetchError = new FetchError({
                message: `Network error: ${errorMessage}`,
                httpStatusCode: 0,
                requestConfig: config,
                response: {},
            });
            const apiError = new ApiRequestError(fetchError, ApiRequestErrorTypeEnum.networkError);
            return [apiError, null];
        }

        const fetchError = new FetchError({
            message: errorMessage,
            httpStatusCode: 0,
            requestConfig: config,
            response: {},
        });
        const apiError = new ApiRequestError(fetchError, ApiRequestErrorTypeEnum.badRequest);
        return [apiError, null];
    }
};

export default doFetch;
