import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Radio.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export type PropsT<ValueT> = {
    className?: string;
    value: ValueT;
    selectedValue: ValueT;
    onChange?: (value: ValueT, event: React.MouseEvent<HTMLDivElement>) => void;
    isDisabled?: boolean;
    label?: React.ReactNode;
    rightIcon?: React.ReactNode;
    testSelector?: string;
};

const Radio = <ValueT,>(props: PropsT<ValueT>): React.ReactElement => {
    const { className, value, selectedValue, onChange, isDisabled, label, rightIcon, testSelector } = props;

    const [isHover, setHover] = React.useState<boolean>(false);

    const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDisabled) {
            return;
        }

        if (onChange) {
            onChange(value, event);
        }
    };

    const handleMouseMove = () => {
        if (isHover) {
            return;
        }

        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const isSelected = value === selectedValue;

    const fullTestSelector = `${testSelector}_radio`;

    return (
        <div className={cs(cx('wrap'), className)}>
            <div
                className={cx('radio', {
                    'radio--isHover': isHover,
                    'radio--isClickable': !isDisabled,
                    'radio--isSelected': isSelected,
                    'radio--isDisabled': isDisabled,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleChange}
                data-test-selector={fullTestSelector}
            />
            <div
                className={cx('label', {
                    'label--isClickable': !isDisabled,
                    'label--isDisabled': isDisabled,
                    'label--hasRightIcon': !!rightIcon,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleChange}
                data-test-selector={`${fullTestSelector}_label`}
            >
                {label}
            </div>
            {rightIcon}
        </div>
    );
};

export default React.memo(Radio) as typeof Radio;
