import * as React from 'react';
import { RFQT } from 'common/store/rfqs/models';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';

type PropsT = {
    rfq: RFQT;
    onOpenUser: (userId: UserIdT) => void;
};

const CreatedCell: React.FC<PropsT> = React.memo((props) => {
    const { rfq, onOpenUser } = props;

    return (
        <EventCell
            userFullName={rfq.createdByName}
            userId={rfq.createdById}
            date={rfq.createdDate}
            byBroker={rfq.createdByBroker}
            openUserDetails={onOpenUser}
        />
    );
});

export default CreatedCell;
