import React, { useCallback, useMemo } from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import keyBy from 'lodash/keyBy';
import { AsyncRequestFactoryT } from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import isNil from 'lodash/isNil';
import UserDropdownOptionLabel from 'common/components/dropdowns/options/UserDropdownOptionLabel/UserDropdownOptionLabel';
import { useAsyncFilterOptions } from 'design-system/components/dropdowns/DropdownSearchInput/hooks/use-async-filter-options';

type OptionT = {
    surname?: string;
    name?: string;
    id?: UserIdT;
};

type ValueT = OptionT['id'] | null;

export type PropsT = {
    className?: string;
    initialValue: ValueT;
    initialLabel: string | null;
    onSelect: (value: ValueT, label?: string) => void;
    value: ValueT;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
    hasClearControl?: boolean;
};

const getOptionText = (option: OptionT): string => {
    return [option.name, option.surname].map((item) => item || '').join(' ');
};

const BrokerAdminUserSuggest: React.FC<PropsT> = (props) => {
    const {
        className,
        initialValue,
        initialLabel,
        value,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        onBlur,
        onFocus,
        hasChanges,
        hasClearControl,
        placeholder,
    } = props;

    const initialOption = useMemo((): OptionT | null => {
        if (!initialValue) {
            return null;
        }

        return {
            surname: '',
            name: initialLabel || 'Unknown user',
            id: initialValue,
        };
    }, [initialLabel, initialValue]);

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback(() => {
        return brokerTranziitApi.fetchBrokerAdminUsers();
    }, []);

    const asyncFilterOptionsRequest = useAsyncFilterOptions<OptionT>({
        initialOption,
        requestFactory,
        getOptionText,
    });

    const optionByUserId = React.useMemo(() => {
        return keyBy(asyncFilterOptionsRequest.options, 'id');
    }, [asyncFilterOptionsRequest.options]);

    const renderOption = (option?: OptionT, placeholder?: string): React.ReactNode => {
        if (isNil(option)) {
            return <UserDropdownOptionLabel label={placeholder || ''} isPlaceholder />;
        }

        return <UserDropdownOptionLabel label={getOptionText(option)} />;
    };

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }

        asyncFilterOptionsRequest.onRefresh();
    }, [onFocus, asyncFilterOptionsRequest.onRefresh]);

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={asyncFilterOptionsRequest.options}
            isDisabled={isDisabled}
            onSelect={(value) => {
                const option = value ? optionByUserId[value] : null;
                const label = option ? getOptionText(option) : '';

                onSelect(value, label);
            }}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncFilterOptionsRequest.onChangeQuery}
            renderRightIcon={() =>
                asyncFilterOptionsRequest.requestStatus.loading ? (
                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                ) : null
            }
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={(option) => option?.id}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={handleFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={
                hasClearControl
                    ? () => {
                          onSelect(null);
                      }
                    : undefined
            }
        />
    );
};

export default BrokerAdminUserSuggest;
