import {
    FETCH_ORDERS_STATS_REQUEST,
    FETCH_ORDERS_STATS_REQUEST_BEGIN,
    FETCH_ORDERS_STATS_REQUEST_ERROR,
    FETCH_ORDERS_STATS_REQUEST_SUCCESS,
    FetchOrdersStatsActionT,
    FetchOrdersStatsBeginActionT,
    FetchOrdersStatsErrorActionT,
    FetchOrdersStatsSuccessActionT,
} from './types';
import { OrdersStatsT } from './models';

export const fetchOrdersStats = (): FetchOrdersStatsActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST,
});

export const fetchOrdersStatsBegin = (): FetchOrdersStatsBeginActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_BEGIN,
});

export const fetchOrdersStatsSuccess = (statistics: OrdersStatsT | null): FetchOrdersStatsSuccessActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchOrdersStatsError = (error: Error): FetchOrdersStatsErrorActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_ERROR,
    error,
});
