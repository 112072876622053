import { put, select } from 'redux-saga/effects';
import { fetchDocumentsDictBegin, fetchDocumentsDictError, fetchDocumentsDictSuccess } from './actions';
import { DOCUMENTS_DICT_REQUEST, FetchDocumentsDictActionT } from './types';
import { selectDocumentsDict, selectDocumentsDictRequest } from './selectors';
import isEmpty from 'lodash/isEmpty';
import prepareDictDocument from './utils/prepare-dict-document';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { semaphoreSaga } from 'common/utils/semaphore-saga';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { selectProfileType } from 'common/store/auth/selectors';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';

function* fetchDocumentsDictSaga(action: FetchDocumentsDictActionT): WrapGeneratorT<void> {
    const { partnerType } = action;
    if (!partnerType) {
        return;
    }

    const profileType: ReturnType<typeof selectProfileType> = yield select(selectProfileType);

    const currentDocumentsDict: ReReturnT<typeof selectDocumentsDict> = yield select(selectDocumentsDict(partnerType));
    if (!isEmpty(currentDocumentsDict)) {
        return;
    }

    const documentsDictRequest: ReReturnT<typeof selectDocumentsDictRequest> = yield select(
        selectDocumentsDictRequest(partnerType),
    );
    if (documentsDictRequest.loading) {
        return;
    }

    yield put(fetchDocumentsDictBegin(partnerType));

    let documentDictResult: ReturnApiT<
        typeof brokerTranziitApi.fetchDocumentsDict | typeof commonTranziitApi.fetchDocumentsDict
    >;

    if (profileType === AuthProfileTypeEnum.broker) {
        documentDictResult = yield brokerTranziitApi.fetchDocumentsDict(partnerType);
    } else {
        documentDictResult = yield commonTranziitApi.fetchDocumentsDict();
    }

    const [error, documentsDict] = documentDictResult;

    const preparedDocumentsDict = (documentsDict || []).map(prepareDictDocument);

    if (error) {
        yield put(fetchDocumentsDictError(partnerType, error));
    } else {
        yield put(fetchDocumentsDictSuccess(partnerType, preparedDocumentsDict));
    }
}

function* documentDictSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(DOCUMENTS_DICT_REQUEST, fetchDocumentsDictSaga);
}

export default documentDictSaga;
