import { CompanyRFQsStateT } from './types';
import { SharableStateT } from '../models';
import { initialCompanyRFQsState } from './reducer';

const selectCompanyState = (state: SharableStateT, companyId: CompanyIdT | null): CompanyRFQsStateT => {
    return state.rfqs[companyId as CompanyIdT] || initialCompanyRFQsState;
};

export const selectRFQsPages = (companyId: CompanyIdT | null) => {
    return (state: SharableStateT): CompanyRFQsStateT['pages'] => {
        return selectCompanyState(state, companyId).pages;
    };
};

export const selectRFQsQuery = (companyId: CompanyIdT | null) => {
    return (state: SharableStateT): CompanyRFQsStateT['query'] => selectCompanyState(state, companyId).query;
};

export const selectRFQsTotal = (companyId: CompanyIdT | null) => {
    return (state: SharableStateT): CompanyRFQsStateT['total'] => selectCompanyState(state, companyId).total;
};

export const selectRFQsByIds = (companyId: CompanyIdT | null) => {
    return (state: SharableStateT): CompanyRFQsStateT['byId'] => selectCompanyState(state, companyId).byId;
};
