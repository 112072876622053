import {
    AdditionalServicesDictActionT,
    AdditionalServicesDictStateT,
    FETCH_ADDITIONAL_SERVICES_BEGIN,
    FETCH_ADDITIONAL_SERVICES_ERROR,
    FETCH_ADDITIONAL_SERVICES_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import { ApiDictAdditionalServiceT } from 'common/utils/api/models';

const initialState: AdditionalServicesDictStateT = {
    fetchRequest: requestStatus.initial,
    byType: null,
    byId: null,
    byEnum: null,
};

export default (state = initialState, action: AdditionalServicesDictActionT): AdditionalServicesDictStateT => {
    switch (action.type) {
        case FETCH_ADDITIONAL_SERVICES_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_ADDITIONAL_SERVICES_SUCCESS: {
            const { additionalServices } = action;

            const list = flatten(values(additionalServices));

            const byEnum = keyBy(list, 'enum') as Record<ApiDictAdditionalServiceT['enum'], ApiDictAdditionalServiceT>;

            return {
                ...state,
                byType: additionalServices,
                byId: keyBy(list, 'id'),
                byEnum,
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_ADDITIONAL_SERVICES_ERROR: {
            const { error } = action;
            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
