import {
    TRAILERS_DICT_REQUEST_BEGIN,
    TRAILERS_DICT_REQUEST_ERROR,
    TRAILERS_DICT_REQUEST_SUCCESS,
    TrailersDictActionT,
    TrailersDictStateT,
} from './types';
import requestStatus from 'common/utils/request-status';

const initialState: TrailersDictStateT = {
    trailersDict: null,
    trailersDictRequest: requestStatus.initial,
};

export default (state = initialState, action: TrailersDictActionT): TrailersDictStateT => {
    switch (action.type) {
        case TRAILERS_DICT_REQUEST_BEGIN: {
            return {
                ...state,
                trailersDictRequest: requestStatus.loading,
            };
        }

        case TRAILERS_DICT_REQUEST_SUCCESS: {
            const { trailersDict } = action;

            return {
                ...state,
                trailersDict,
                trailersDictRequest: requestStatus.ok,
            };
        }

        case TRAILERS_DICT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                trailersDictRequest: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
