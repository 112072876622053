import * as React from 'react';

import noop from 'lodash/noop';
import TransparentTrigger, { ReflectionThemeEnum } from '../TransparentTrigger/TransparentTrigger';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';

export type PropsT = {
    className?: string;
    onClick: () => void;
    isDisabled: boolean;
};

const BackButton: React.FC<PropsT> = (props) => {
    const { className, onClick, isDisabled } = props;

    return (
        <TransparentTrigger
            className={className}
            isDisabled={isDisabled}
            onClick={isDisabled ? noop : onClick}
            reflectionTheme={ReflectionThemeEnum.light}
            leftIcon={
                <LeftArrowIcon fillColor={isDisabled ? StyleGuideColorsEnum.light : StyleGuideColorsEnum.charcoal} />
            }
        />
    );
};

export default BackButton;
