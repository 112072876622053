import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import styles from './TrailerTypeSwitcher.scss';
import { TrailerTypeEnum } from '../../constants';
import Radio from '../Radio/Radio';

const cx = classNames.bind(styles);

type OptionT = {
    title: string;
    icon: 'tilt' | 'box' | 'reef';
    testSelector: string;
};

type TrailerTypePropsT = {
    type: TrailerTypeEnum;
    option: OptionT;
    selectedType: TrailerTypeEnum | null;
    onChange: (type: TrailerTypeEnum) => void;
};

const TrailerType: React.FC<TrailerTypePropsT> = (props) => {
    const { type, option, selectedType, onChange } = props;
    const [isHover, setHover] = React.useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const handleClick = () => {
        onChange(type);
    };

    const isSelected = selectedType === type;

    return (
        <div
            className={cx('option')}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <div
                className={cx('option__image', `option__image--${option.icon}`, {
                    'option__image--isActive': isHover || isSelected,
                })}
            />
            <div className={cx('option__title')}>
                <Radio<TrailerTypeEnum | null>
                    testSelector={option.testSelector}
                    className={cx('radio')}
                    selectedValue={selectedType}
                    value={type}
                    label={option.title}
                />
            </div>
        </div>
    );
};

type PropsT = {
    name: string;
    value: TrailerTypeEnum | null;
    availableTypes: Array<TrailerTypeEnum>;
    setFieldValue: (name: string, value: TrailerTypeEnum) => void;
};

const TrailerTypeSwitcher: React.FC<PropsT> = (props) => {
    const { name, value, setFieldValue, availableTypes } = props;

    const testSelector = 'trailer-type-switcher';

    const { t } = useTranslation();

    const optionByType: Record<string, OptionT> = {
        [TrailerTypeEnum.tilt]: {
            title: t('common:trailer-type-switcher.tilt'),
            icon: 'tilt',
            testSelector: `${testSelector}_tilt`,
        },
        [TrailerTypeEnum.box]: {
            title: t('common:trailer-type-switcher.box'),
            icon: 'box',
            testSelector: `${testSelector}_box`,
        },
        [TrailerTypeEnum.reef]: {
            title: t('common:trailer-type-switcher.reef'),
            icon: 'reef',
            testSelector: `${testSelector}_reef`,
        },
    };

    const handleChange = (trailerType: TrailerTypeEnum) => {
        setFieldValue(name, trailerType);
    };

    return (
        <div className={cx('select')} data-test-selector={testSelector}>
            {availableTypes.map((type) => (
                <TrailerType
                    key={type}
                    type={type}
                    selectedType={value}
                    option={optionByType[type]}
                    onChange={handleChange}
                />
            ))}
        </div>
    );
};

export default TrailerTypeSwitcher;
