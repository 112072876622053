import {
    FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN,
    FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR,
    FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
    MARK_AS_OLD,
    MARK_AS_READ_REQUEST,
    NotificationsActionT,
    NotificationsStateT,
    RECEIVED_NOTIFICATIONS,
    RESET_NOTIFICATION_PAGES,
} from './types';
import requestStatus from 'common/utils/request-status';
import { isNonNil } from 'common/utils';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';
import { AnyNotificationT } from 'common/store/notifications/models';
import { pageBeginReducer, pageErrorReducer, pageSuccessReducer } from 'common/utils/pagination/reducers';
import uniq from 'lodash/uniq';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: NotificationsStateT = {
    unreadNotifications: {
        ids: [],
        byId: {},
        fetchRequest: requestStatus.initial,
    },

    newNotificationIds: [],

    currentPageNumber: 0,
    pages: [],
    query: null,
    total: null,
    byId: {},

    markAsReadRequest: requestStatus.initial,
};

const mergeNotificationsById = (
    oldNotificationsById: Record<NotificationIdT, AnyNotificationT>,
    newNotificationsById: Record<NotificationIdT, AnyNotificationT>,
) => {
    return {
        ...oldNotificationsById,
        ...mapValues(newNotificationsById, (notification) => {
            if (!notification.id) {
                return notification;
            }

            const oldNotification = oldNotificationsById[notification.id];

            return {
                ...notification,
                isNew: oldNotification?.isNew || notification.isNew,
            };
        }),
    };
};

export default (state = initialState, action: NotificationsActionT | DestroySessionActionT): NotificationsStateT => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query } = action;

            const newPages = [...state.pages];
            newPages.splice(pageNumber, 1, {
                ...state.pages[pageNumber],
                requestStatus: requestStatus.loading,
            });

            return {
                ...state,
                ...pageBeginReducer(state, pageNumber, query),
                currentPageNumber: Math.max(state.currentPageNumber, pageNumber),
            };
        }

        case FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse } = action;

            return {
                ...state,
                ...pageSuccessReducer(state, pageNumber, query, pageResponse),
            };
        }

        case FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR: {
            const { query, error, pageNumber } = action;

            return {
                ...state,
                ...pageErrorReducer(state, pageNumber, query, error),
            };
        }

        case FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN: {
            return {
                ...state,
                unreadNotifications: {
                    ...state.unreadNotifications,
                    fetchRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS: {
            const { notifications } = action;

            const byId = notifications ? keyBy(notifications, 'id') : state.unreadNotifications.byId;

            return {
                ...state,
                unreadNotifications: {
                    ...state.unreadNotifications,
                    ids: (notifications || []).map((notification) => notification.id),
                    byId: mergeNotificationsById(state.unreadNotifications.byId, byId),
                    fetchRequest: requestStatus.ok,
                },
            };
        }

        case FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                unreadNotifications: {
                    ...state.unreadNotifications,
                    fetchRequest: requestStatus.setError(error),
                },
            };
        }

        case MARK_AS_OLD: {
            const { ids } = action;

            const newById = { ...state.byId };

            ids.forEach((id) => {
                newById[id] = {
                    ...newById[id],
                    isNew: false,
                };
            });

            return {
                ...state,
                byId: newById,
            };
        }

        case MARK_AS_READ_REQUEST: {
            const { ids, isAll } = action;

            const newById = { ...state.byId };

            ids.forEach((id) => {
                newById[id] = {
                    ...newById[id],
                    readAlready: true,
                    isNew: false,
                };
            });

            if (isAll) {
                const pageNotificationIds = state.pages.map((page) => page.ids);
                const allNotificationsIds = flatten(pageNotificationIds).filter(isNonNil);

                allNotificationsIds.forEach((id) => {
                    newById[id] = {
                        ...newById[id],
                        readAlready: true,
                        isNew: false,
                    };
                });
            }

            return {
                ...state,
                byId: newById,
            };
        }

        case RECEIVED_NOTIFICATIONS: {
            const { notifications } = action;

            const ids = notifications.map((notification) => notification.id).filter(isNonNil);

            const notificationsById = keyBy(notifications, 'id');

            const unreadNotifications = notifications.filter((notification) => notification.readAlready);
            const unreadNotificationsIds = unreadNotifications.map((notification) => notification.id);
            const unreadNotificationsById = keyBy(unreadNotifications, 'id');

            return {
                ...state,
                unreadNotifications: {
                    ...state.unreadNotifications,
                    ids: uniq([...state.unreadNotifications.ids, ...unreadNotificationsIds]),
                    byId: mergeNotificationsById(state.unreadNotifications.byId, unreadNotificationsById),
                },
                newNotificationIds: [...state.newNotificationIds, ...ids],
                byId: mergeNotificationsById(state.byId, notificationsById),
            };
        }

        case RESET_NOTIFICATION_PAGES: {
            return {
                ...state,
                pages: [],
                query: null,
                total: null,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
