import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { TrailersDictT } from './models';
import { TrailerTypeEnum } from '../../constants';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { createSelector } from 'reselect';

export const selectTrailersDict = (state: SharableStateT): TrailersDictT | null => state.trailersDict.trailersDict;

export const selectTrailersDictRequest = (state: SharableStateT): RequestStatusT =>
    state.trailersDict.trailersDictRequest;

export const selectAllTrailersTypes = createSelector(selectTrailersDict, (trailersDict): Array<TrailerTypeEnum> => {
    return Object.keys(trailersDict || {}) as Array<TrailerTypeEnum>;
});

export const selectTrailersDictList = createSelector(selectTrailersDict, (trailersDict): Array<ApiTrailerDictTypeT> => {
    return flatten(Object.values(trailersDict || {}));
});

export const selectTrailerDictTypeIds = createSelector(
    selectTrailersDictList,
    (trailersDictList): Array<TrailerDictIdT> => {
        return trailersDictList.map((trailerType) => String(trailerType?.id));
    },
);

export const selectTrailersDictById = createSelector(
    selectTrailersDictList,
    (trailersDictList): Record<string, ApiTrailerDictTypeT> => {
        return keyBy(trailersDictList, 'id');
    },
);
