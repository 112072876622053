export class RemoteFile<T> {
    name: string;

    downloadData: T;

    constructor({ name, downloadData }: { name: string; downloadData: T }) {
        this.name = name;
        this.downloadData = downloadData;
    }
}
