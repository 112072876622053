import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const SquareInfoIcon: React.FC<PropsT> = (props) => {
    const { fillColor, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M11 5.007v7a1 1 0 01-2 0v-7a1 1 0 012 0zm0 10a1 1 0 11-2 0 1 1 0 012 0zM18 17a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v14zm0-17H2C.895 0 0 .899 0 2.003v16.004C0 19.112.895 20 2 20h16c1.105 0 2-.892 2-1.997V2.007C20 .902 19 0 18 0z"
            />
        </svg>
    );
};

SquareInfoIcon.displayName = 'SquareInfoIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default SquareInfoIcon;
