import { ApiLocationT, ApiMultiPointPriceOfferT, ApiRFQT } from 'common/utils/api/models';
import { CountryCodeT } from '../countries-dict/models';

export type ReserveQueryChangesT = Partial<{
    route: Array<
        Partial<{
            address: ApiLocationT | null;
            driveThru: boolean;
            isBrokerTimeWindow: boolean;
            timeWindowFrom: string | null;
            timeWindowTo: string | null;
        }>
    >;
    trailerDictId: TrailerDictIdT | null;
    excludedCountries: CountryCodeT[];
    shipperId: ShipperIdT | null;
    laneId: ShipperContractLaneIdT | null;
}>;

export enum OrderCreationStepEnum {
    shipmentDetails = 'shipment-details',
    price = 'price',
    cargoDetails = 'cargo-details',
}

export type RFQT = OverwriteT<
    ApiRFQT,
    {
        expireTime: number | null;
        points: Array<
            ApiRFQT['points'][number] & {
                fromDate: string | null;
                toDate: string | null;
            }
        >;
    }
>;

export type ApiSocketPriceOfferT = ApiMultiPointPriceOfferT;

export type PriceOfferT = {
    id: string;
    dropOffDockingHoursFrom: string;
    dropOffDockingHoursTo: string;
    pickupDockingHoursFrom: string;
    pickupDockingHoursTo: string;
    dropOffDate: string | null;
    pickUpDate: string | null;
    isUrgentShipment: boolean;
    isBestPrice?: boolean;
    isTeamDrive: boolean;
    isGreenTruck: boolean;
    hasLayover: boolean;
    layoverSeconds: number;
    distanceKm: number;
    co2: number;
    travelSeconds: number;
    price?: number;
    expiration: string | null;
    expirationMs: number | null;
    isExpired: boolean;
    brokerInfo: ApiMultiPointPriceOfferT['brokerInfo'];
    priceComponents: Partial<{
        toll: number | null;
        fuel: number | null;
        lineHaul: number | null;
        additionalServices: number | null;
        tranziitServiceFee: number | null;
        layover: number | null;
        urgentRate: number | null;
        totalRate: number | null;
        costOfOperation: number | null;
        layoverRate: number | null;
        driverCostPerHour: number | null;
        teamDriveHours: number | null;
        urgentOverprice: number | null;
        green: number | null;
        teamDriveCost: number | null;
        tollByRoadType: ApiMultiPointPriceOfferT['tollByRoadType'] | null;
    }>;
};

export type ReserveTimeWindowT = {
    start: ISODateTimeT;
    end: ISODateTimeT;
};

export type ReserveOpenTimeWindowT = {
    start: ISODateTimeT;
    end: ISODateTimeT | null;
};

export type BestOffersT = {
    bestPrice: PriceOfferT | null;
    urgent: PriceOfferT | null;
};

export type ApiCreateReserveQueryT = Api2.Definitions.ReserveOrderRequest & { shipperId: ShipperIdT | null };
export type CreateReserveQueryT = {
    shipperId: ShipperIdT | null;
    addresses: Array<{
        address: ApiLocationT | null;
        driveThru: boolean;
    }>;
    trailerDictId: number | null;
    prohibitedCountries: CountryCodeT[];
    routeStart?: string; // date-time
};
export type CreateReserveQueryChangesT = Partial<CreateReserveQueryT>;

export type ApiReserveT = Api2.Definitions.ReservedOrderDto;

export type ReserveT = Omit<ApiReserveT, 'points'> & {
    expireTime: number;
    points: Array<
        Omit<ApiReserveT['points'][number], 'defaultFrom' | 'defaultTo'> & {
            defaultTimeWindow: ReserveTimeWindowT | null;
        }
    >;
};

export type ApiReservePreviewQueryT = Api2.Definitions.RfqRequest & {
    reserveId: ReserveIdT;
};

export type ReservePreviewQueryT = {
    timeWindows: Array<
        Partial<{
            id: string;
            isBrokerTimeWindow: boolean;
            timeWindowFrom: string | null;
            timeWindowTo: string | null;
        }>
    >;
    reserveId: ReserveIdT | null;
    laneId: ShipperContractLaneIdT | null;
};
export type ReservePreviewQueryChangesT = Partial<ReservePreviewQueryT>;

export type ApiReservePreviewT = Api2.Definitions.PriceOfferPreviewDto;

export type ReservePreviewT = {
    isTeamDrive: boolean;
    layoverHours?: number;
    secondsToExpire: number; // int64
    travelSeconds?: number;
    timeWindows: Array<{
        id: string; // uuid
        correctedTimeWindow: ReserveTimeWindowT | null;
        correctedBrokerWindow: boolean | null;
        urgentTimeWindow: ReserveTimeWindowT | null;
        regularTimeWindow: ReserveOpenTimeWindowT | null;
        layoverTimeWindow: ReserveOpenTimeWindowT | null;
    }>;
};

export type ReserveQueryModifyEventT = {
    correctedPoints?: Array<{
        defaultTimeWindow?: ReserveT['points'][number]['defaultTimeWindow'] | null;
        replacedCoordinate?: ReserveT['points'][number]['replacedCoordinate'] | null;
    }>;

    correctedTimeWindows?: Array<ReserveTimeWindowT | null>;
    correctedBrokerWindow?: Array<boolean | null>;
};

export type ReserveLockEventT = {
    isLocked: boolean;
};
