import React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import { ApiShipperContractLaneStatusT, ShipperContractLaneStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import { SHIPPER_CONTRACT_LANE_STATUS_T_MAP } from 'common/components/status/ShipperContractLaneStatus/ShipperContractLaneStatus';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { statusColorMap } from 'common/components/status-colored/ShipperContractLaneColoredStatus/ShipperContractLaneColoredStatus';
import { useSelector } from 'react-redux';
import { selectProfileType } from 'common/store/auth/selectors';
import CircleIcon from 'common/icons/CircleIcon';

type ValueT = ApiShipperContractLaneStatusT | null;

type PropsT = {
    className?: string;
    value: ValueT;
    onChange: (value: ValueT) => void;
    testSelector?: string;
};

const ShipperContractLaneStatusDropdownControl: React.FC<PropsT> = React.memo((props) => {
    const { className, value, onChange, testSelector } = props;

    const { t } = useTranslation();

    const profileTypeEnum = useSelector(selectProfileType);

    const options = React.useMemo((): DropdownControlOptionT[] => {
        return [
            null,
            ShipperContractLaneStatusEnum.active,
            ShipperContractLaneStatusEnum.expired,
            ShipperContractLaneStatusEnum.completed,
            ShipperContractLaneStatusEnum.revoked,
        ].map((status: ApiShipperContractLaneStatusT | null): DropdownControlOptionT => {
            const isSelected = value === status;
            if (status === null) {
                return {
                    label: (
                        <DropdownControlOptionLabel
                            isSelected={isSelected}
                            label={t('common:shipper-contract-lane-status.all')}
                        />
                    ),
                    onSelect: () => {
                        onChange(null);
                    },
                };
            }

            return {
                label: (
                    <DropdownControlOptionLabel
                        icon={<CircleIcon borderColor={statusColorMap[profileTypeEnum]?.[status]} />}
                        isSelected={isSelected}
                        label={t(SHIPPER_CONTRACT_LANE_STATUS_T_MAP[status])}
                    />
                ),
                onSelect: () => {
                    onChange(status);
                },
            };
        });
    }, [t, value]);

    return (
        <DropdownControl
            className={className}
            options={options}
            testSelector={`${testSelector}_more-statuses`}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    rightIcon={<ArrowsIcon />}
                    label={
                        value
                            ? t(SHIPPER_CONTRACT_LANE_STATUS_T_MAP[value])
                            : t('common:shipper-contract-lane-status.all')
                    }
                    reflectionTheme={ReflectionThemeEnum.light}
                    testSelector={`${testSelector}-trigger`}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default ShipperContractLaneStatusDropdownControl;
