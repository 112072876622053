import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchShipperContractLaneDetails,
    fetchShipperContractLaneDetailsBegin,
    fetchShipperContractLaneDetailsError,
    fetchShipperContractLaneDetailsSuccess,
    revokeShipperContractLane,
    updateShipperContractLane,
    updateShipperContractLaneDetailsBegin,
    updateShipperContractLaneDetailsError,
    updateShipperContractLaneDetailsSuccess,
} from './slice';
import {
    FetchShipperContractLaneDetailsActionT,
    RevokeShipperContractLaneDetailsActionT,
    UpdateShipperContractLaneActionT,
} from './types';
import { CompanyTypeEnum } from 'common/constants';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { selectShipperContractLaneDetailsState } from './selectors';
import { shipperContractLanesRefreshChannel } from 'common/store/shipper-contract-lanes/channels';
import { shipperContractLaneDetailsRefreshChannel } from './channels';

function getFetchShipperContractLaneDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: FetchShipperContractLaneDetailsActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, laneId, options } = action.payload;

        const { fetchRequest }: ReReturnT<typeof selectShipperContractLaneDetailsState> = yield select(
            selectShipperContractLaneDetailsState(shipperContractId),
        );
        if (!checkNeedRequest(fetchRequest, options)) {
            return;
        }

        yield put(fetchShipperContractLaneDetailsBegin({ shipperContractId, laneId }));

        let result: ReturnApiT<
            | typeof shipperTranziitApi.fetchShipperContractLaneDetails
            | typeof brokerTranziitApi.fetchShipperContractLaneDetails
        >;
        if (companyType === CompanyTypeEnum.shipper) {
            result = yield shipperTranziitApi.fetchShipperContractLaneDetails(shipperContractId, laneId);
        } else {
            result = yield brokerTranziitApi.fetchShipperContractLaneDetails(partnerId, shipperContractId, laneId);
        }

        const [error, data] = result;

        if (error) {
            yield put(fetchShipperContractLaneDetailsError({ shipperContractId, laneId, error }));
        } else {
            yield put(fetchShipperContractLaneDetailsSuccess({ shipperContractId, laneId, data }));
        }
    };
}

function getUpdateShipperContractLaneDetailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: UpdateShipperContractLaneActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, laneId, detailsChanges } = action.payload;

        yield put(updateShipperContractLaneDetailsBegin({}));

        const result: ReturnApiT<typeof brokerTranziitApi.updateShipperContractLane> =
            yield brokerTranziitApi.updateShipperContractLane(partnerId, shipperContractId, laneId, detailsChanges);
        const [error] = result;

        if (error) {
            yield put(updateShipperContractLaneDetailsError({ error }));
        } else {
            yield put(updateShipperContractLaneDetailsSuccess({}));

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.shipperContractLaneUpdated,
                        data: {},
                    }),
                ),
            );
        }

        shipperContractLanesRefreshChannel.emit({});
        shipperContractLaneDetailsRefreshChannel.emit({ shipperContractId, laneId });
    };
}

function getRevokeShipperContractLaneDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: RevokeShipperContractLaneDetailsActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, laneId } = action.payload;

        yield put(updateShipperContractLaneDetailsBegin({}));

        const result: ReturnApiT<typeof brokerTranziitApi.revokeShipperContractLane> =
            yield brokerTranziitApi.revokeShipperContractLane(partnerId, shipperContractId, laneId);
        const [error] = result;

        if (error) {
            yield put(updateShipperContractLaneDetailsError({ error }));
        } else {
            yield put(updateShipperContractLaneDetailsSuccess({}));

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.shipperContractLaneRevoked,
                        data: {},
                    }),
                ),
            );
        }

        shipperContractLanesRefreshChannel.emit({});
        shipperContractLaneDetailsRefreshChannel.emit({ shipperContractId, laneId });
    };
}

function* shipperContractLaneDetailsSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchShipperContractLaneDetails.type, getFetchShipperContractLaneDeailsSaga(companyType));
    yield takeEvery(revokeShipperContractLane.type, getRevokeShipperContractLaneDeailsSaga(companyType));
    yield takeEvery(updateShipperContractLane.type, getUpdateShipperContractLaneDetailsSaga(companyType));
}

export { shipperContractLaneDetailsSaga };
