import isArray from 'lodash/isArray';

// eslint-disable-next-line import/no-unresolved
import { FormikValues } from 'formik/dist/types';
import get from 'lodash/get';

const MAX_PERCENT = 100;
const MIN_PERCENT = 0;

const getterFormFillPercent = (progressSteps: Array<string | string[]>) => (values: FormikValues) => {
    const percentStep = (MAX_PERCENT - MIN_PERCENT) / progressSteps.length;

    const percent = progressSteps.reduce<number>((progress, progressStep) => {
        let isFill: boolean;
        if (isArray(progressStep)) {
            isFill = progressStep.some((field) => !!get(values, field));
        } else {
            isFill = get(values, progressStep);
        }

        return progress + (isFill ? percentStep : 0);
    }, 0);

    return percent;
};

export default getterFormFillPercent;
