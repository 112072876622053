import { FieldsEnum, FormErrorsT, ShipmentFieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import { checkIsTranziitApiErrorResponse } from 'common/utils/api/tranziit/errors/error-response';
import set from 'lodash/set';
import { TFunction } from 'i18next';

const asyncValidate = (t: TFunction, requestError: RequestStatusT): FormErrorsT => {
    const { error } = requestError;

    const asyncErrors: FormErrorsT = {};

    if (!checkIsTranziitApiRequestError(error)) {
        return asyncErrors;
    }

    if (
        checkIsTranziitApiErrorResponse(
            error,
            TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_CapacityExcess,
        )
    ) {
        set(
            asyncErrors,
            `${FieldsEnum.shipments}.${error?.response?.shipmentIndex}.${ShipmentFieldsEnum.cargoUnitsCount}`,
            t('common:new-order-form.async-errors.capacity-excess'),
        );
    }

    if (
        checkIsTranziitApiErrorResponse(
            error,
            TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_Overweight,
        )
    ) {
        set(
            asyncErrors,
            `${FieldsEnum.shipments}.${error?.response?.shipmentIndex}.${ShipmentFieldsEnum.cargoWeight}`,
            t('common:new-order-form.async-errors.overweight'),
        );
    }

    return asyncErrors;
};

export default asyncValidate;
