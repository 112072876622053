import { CURRENCY_SETTINGS, CurrencyEnum } from 'common/constants';

const formatMoney = (value: number | null | undefined, currency: CurrencyEnum): number => {
    const { minimumSignificantDigits } = CURRENCY_SETTINGS[currency];

    return +(value || 0).toFixed(minimumSignificantDigits);
};

export const moneyOperations = {
    plus: (a: number | null | undefined, b: number | null | undefined, currency: CurrencyEnum): number => {
        return formatMoney((a || 0) + (b || 0), currency);
    },
    plusWithFormat: (a: number | null | undefined, b: number | null | undefined, currency: CurrencyEnum): number => {
        return formatMoney(formatMoney(a, currency) + formatMoney(b, currency), currency);
    },
    multiply: (a: number | null | undefined, b: number | null | undefined, currency: CurrencyEnum): number => {
        return formatMoney((a || 0) * (b || 0), currency);
    },
};
