import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';

export type ActivateDriverConfirmationDataT = {
    companyId: CompanyIdT;
    driverId: DriverIdT;
    driverName: string | undefined;
    driverSurname: string | undefined;
};

type PropsT = {
    data: ActivateDriverConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: ActivateDriverConfirmationDataT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'activate-driver';

const ActivateDriverConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, requestStatus, onConfirm } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const fullName = [data.driverName, data.driverSurname].filter(Boolean).join(' ');

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.restore'),
                            theme: ButtonThemeEnum.primary,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                    title={t('common:drivers-page.activate-confirmation.title', {
                        fullName,
                    })}
                    message={t('common:drivers-page.activate-confirmation.description', {
                        fullName,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default ActivateDriverConfirmation;
