import { TFunction } from 'i18next';
import { FormErrorsT, FormValuesT } from './constants';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {};

    return errors;
};

export default validate;
