import React from 'react';

import { CompanyContactT, RolesWithSuccessorT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import { ChangeUserRoleDataT } from 'common/layouts/Members/dialogs/ChangeUserRoleModal/models';
import { USER_ROLE_T_MAP } from 'common/utils/t';
import { getFirstNeedSuccessorRole } from 'common/store/members/utils';
import ChangeRoleUserWithSuccessorForm from 'broker-admin/layouts/PartnerPage/PartnerMembersPage/ChangeRoleUserWithSuccessorForm/ChangeRoleUserWithSuccessorForm';
import ChangeRoleUserForm from 'common/layouts/Members/ChangeRoleUserForm/ChangeRoleUserForm';
import { rolesNeededSuccessorSet } from 'common/store/members/constants';
import { useSelector } from 'react-redux';
import { selectUpdateMemberRequest } from 'common/store/members/selectors';

export type ChangeUserRoleModalDataT = {
    contact: {
        roles: CompanyContactT['roles'];
        fullName: CompanyContactT['fullName'];
        userId: CompanyContactT['userId'];
    } | null;
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

export type ChangeUserRoleModalPropsT = {
    data: ChangeUserRoleModalDataT | null;
    onClose: () => void;
    onChangeUserRole: (params: {
        data: ChangeUserRoleModalDataT | null;
        isNeedSuccessor: boolean;
        changes: ChangeUserRoleDataT;
    }) => void;
};

const TEST_SELECTOR = 'change-user-role';

const MESSAGE_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.change-user-role.description',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.change-user-role.description',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.change-user-role.partner-description',
};

const ChangeUserRoleModal: React.FC<ChangeUserRoleModalPropsT> = React.memo((props) => {
    const { data, onClose, onChangeUserRole } = props;

    const contact = data?.contact || null;
    const partnerType = data?.partnerType || null;
    const partnerId = data?.partnerId || null;

    const updateMemberRequest = useSelector(selectUpdateMemberRequest(partnerId));

    const isNeedSuccessor = !!contact?.roles.some((roleData) => {
        return rolesNeededSuccessorSet.has(roleData.role as RolesWithSuccessorT);
    });

    const oldUserRoleData = getFirstNeedSuccessorRole(contact?.roles);

    const { t } = useTranslation();

    const FormComponent = isNeedSuccessor ? ChangeRoleUserWithSuccessorForm : ChangeRoleUserForm;

    if (!contact || !partnerType || !partnerId) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigWarningIcon />}
                    title={t('common:team-members.dialogs.change-user-role.title', {
                        fullName: contact?.fullName,
                    })}
                    message={t(MESSAGE_T[partnerType], {
                        fullName: contact?.fullName,
                        role: t(USER_ROLE_T_MAP[oldUserRoleData?.role as RolesEnum]),
                    })}
                >
                    <FormComponent
                        contact={contact}
                        onChangeUserRole={(changes) => {
                            onChangeUserRole({ data, isNeedSuccessor, changes });
                        }}
                        onCancel={onClose}
                        updateMemberRequest={updateMemberRequest}
                    />
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default ChangeUserRoleModal;
