import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { FieldsEnum, FormValuesT } from '../constants';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import isEmpty from 'lodash/isEmpty';

type PropsT = {
    className?: string;
    fieldNamesPrefix: FieldsEnum.shipments | FieldsEnum.contacts;
    index: number;
};

const ErrorIndicatorLabel: React.FC<PropsT> = React.memo((props) => {
    const { className, fieldNamesPrefix, index } = props;

    const { t } = useTranslation();

    const formik = useFormikContext<FormValuesT>();

    const errors = formik.errors?.[fieldNamesPrefix]?.[index];
    const hasErrors = !isEmpty(errors);

    return (
        <PillLabel
            className={className}
            theme={hasErrors ? PillLabelThemeEnum.orange : PillLabelThemeEnum.brandAccent}
            isSymmetrical
        >
            {t(
                hasErrors
                    ? 'common:new-order-details.error-indicator.unfilled'
                    : 'common:new-order-details.error-indicator.filled',
            )}
        </PillLabel>
    );
});

export default ErrorIndicatorLabel;
