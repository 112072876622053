import React from 'react';
import classNames from 'classnames/bind';

import styles from './PhoneNumberDropdownOption.scss';
import DoneIcon from 'common/icons/DoneIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

export type PropsT = {
    index: number;
    countryCode: string;
    label: string;
    onSelect: (countryCode: string) => void;
    isDisabled?: boolean;
    isSelected?: boolean;
    testSelector?: string;
};

const PhoneNumberDropdownOption = React.memo((props: PropsT): React.ReactElement => {
    const { countryCode, label, onSelect, isDisabled, isSelected, testSelector } = props;

    return (
        <div
            className={cx('option', {
                'option--isSelected': isSelected,
                'option--isSelectable': !isDisabled,
            })}
            onClick={(): void => {
                if (isDisabled) {
                    return;
                }

                onSelect(countryCode);
            }}
            data-test-selector={`${testSelector}_option_${countryCode}`}
        >
            <FlagIcon className={cx('option__flag')} countryCode={countryCode} />
            <div className={cx('option__label')}>{label}</div>
            {isSelected && (
                <div className={cx('option__icon')} data-test-selector={`${testSelector}_selected`}>
                    <DoneIcon
                        fillColor={StyleGuideColorsEnum.charcoal}
                        size={DEFAULT_ICON_SIZE}
                        className={cx('icon')}
                    />
                </div>
            )}
        </div>
    );
});

export default PhoneNumberDropdownOption;
