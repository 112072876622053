import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { useSelector } from 'react-redux';
import { selectAddMemberRequest, selectCompanyContacts } from 'common/store/members/selectors';
import BigPlusUsers from 'common/icons/BigPlusUsers';
import InviteUserForm from 'common/layouts/Members/InviteUserForm/InviteUserForm';
import { ApiAddCompanyUserRequestT, PartnerTypeEnum } from 'common/utils/api/models';

export type InviteUserModalDataT = {
    email: string;
    partnerId: CompanyIdT;
    partnerType: PartnerTypeEnum;
};

export type InviteUserModalPropsT = {
    data: InviteUserModalDataT | null;
    onClose: () => void;
    onCancel: () => void;
    onInviteUser: (newUser: ApiAddCompanyUserRequestT) => void;
};

const InviteUserModal: React.FC<InviteUserModalPropsT> = React.memo((props) => {
    const { data, onClose, onInviteUser } = props;

    const { t } = useTranslation();

    const partnerId = data?.partnerId || null;

    const contacts = useSelector(selectCompanyContacts(partnerId));
    const addMemberRequestStatus = useSelector(selectAddMemberRequest(partnerId));

    if (!partnerId || !contacts || !data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    icon={<BigPlusUsers />}
                    title={t('common:team-members.invite-form.title')}
                >
                    <InviteUserForm
                        contacts={contacts}
                        initialEmail={data.email}
                        onInviteUser={onInviteUser}
                        addMemberRequestStatus={addMemberRequestStatus}
                    />
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default InviteUserModal;
