import { CompanyT, VerificationProblemsT } from 'common/store/company/models';
import { PartnerTypeEnum } from 'common/utils/api/models';

export const checkVerificationProblems = (company: CompanyT | null): VerificationProblemsT => {
    if (!company) {
        return {
            any: false,
            anyBasicInformation: false,
            anyDocument: false,
        };
    }

    const hasAnyBasicInformationProblem =
        (company.type === PartnerTypeEnum.shipper && !company.billingAddressVerified) || !company.addressVerified;
    const hasAnyDocumentProblem = !company.documentsVerified;

    return {
        any: hasAnyBasicInformationProblem || hasAnyDocumentProblem,
        anyDocument: hasAnyDocumentProblem,
        anyBasicInformation: hasAnyBasicInformationProblem,
    };
};
