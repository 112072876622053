import * as React from 'react';

const useOnceShow = (isAllowShow: boolean) => {
    const [isClosed, setClosed] = React.useState<boolean>(false);
    const [isShow, setShow] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isAllowShow && !isClosed) {
            setShow(true);
        }
    }, [isAllowShow]);

    const close = React.useCallback(() => {
        setClosed(true);
        setShow(false);
    }, [setClosed, setShow]);

    return {
        isShow,
        close,
    };
};

export default useOnceShow;
