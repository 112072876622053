import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './RevokedShipperContractLaneCard.scss';
import { Trans } from 'react-i18next';
import Identifier from 'common/components/notification-cards/entities/Identifier/Identifier';
import CityName from 'common/components/notification-cards/entities/CityName/CityName';

const cx = classNames.bind(styles);

type PropsT = {
    id: string;
    cityFrom: string;
    cityTo: string;
};

const RevokedShipperContractLaneCard: React.FC<PropsT> = React.memo((props) => {
    const { id, cityFrom, cityTo } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="common:notifications.shipper-contract-lane-revoked"
                components={{
                    id: <Identifier id={id} />,
                    cityFrom: <CityName cityName={cityFrom} />,
                    cityTo: <CityName cityName={cityTo} />,
                }}
            />
        </div>
    );
});

export default RevokedShipperContractLaneCard;
