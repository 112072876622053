import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './RouteMap.scss';
import { useSelector } from 'react-redux';
import { selectPolylines, selectPriceOffersById, selectRFQ } from 'common/store/order-creation/selectors';
import MultiPointOrderRouteMap, {
    MultiPointOrderRouteMapPropsT,
} from 'common/components/MultiPointOrderRouteMap/MultiPointOrderRouteMap';
import MapFooterEstimate from 'common/layouts/NewOrderPage/MapFooterEstimate/MapFooterEstimate';
import { M_IN_KM } from 'common/utils/distance';
import { QueryKeysEnum } from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';

const cx = classNames.bind(styles);

type QueryT = {
    [QueryKeysEnum.orderCreationPriceOfferId]: string;
};

type PropsT = {};

const RouteMap: React.FC<PropsT> = React.memo((props) => {
    const polylines = useSelector(selectPolylines);

    const query = useQuery<QueryT>();

    const priceOffersById = useSelector(selectPriceOffersById);
    const selectedOfferId = query[QueryKeysEnum.orderCreationPriceOfferId];
    const selectedOffer = priceOffersById[selectedOfferId || ''] || null;

    const rfq = useSelector(selectRFQ);

    const mapPoints = useMemo((): MultiPointOrderRouteMapPropsT['points'] => {
        if (rfq) {
            return rfq.points.map((point, index) => {
                return {
                    longitude: point.address?.longitude,
                    latitude: point.address?.latitude,
                    driveThru: point.driveThru,
                    index,
                };
            });
        }

        return null;
    }, [rfq]);

    const isLoading = !polylines?.length || !rfq;

    return (
        <div className={cx('map')}>
            <MultiPointOrderRouteMap isLoading={isLoading} points={mapPoints} route={polylines}>
                <MapFooterEstimate
                    isLoading={isLoading}
                    distanceM={selectedOffer?.distanceKm ? selectedOffer.distanceKm * M_IN_KM : undefined}
                    travelTimeSec={selectedOffer?.travelSeconds}
                />
            </MultiPointOrderRouteMap>
        </div>
    );
});

export default RouteMap;
