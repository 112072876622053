import { CLOSE_ERROR_MODAL, ErrorsStateT, OPEN_ERROR_MODAL, RESET_LOGS } from './types';
import { checkError, checkIgnoreError } from './utils';
import { AnyAction } from 'redux';
import { ErrorActionT } from 'common/store/errors/models';

const initialState: ErrorsStateT = {
    logs: [],
    isShowErrorModal: false,
};

const MAX_LOGS_COUNT = 10;

export default (state = initialState, action: AnyAction): ErrorsStateT => {
    if (action.type === OPEN_ERROR_MODAL) {
        return {
            ...state,
            isShowErrorModal: true,
        };
    }

    if (action.type === RESET_LOGS) {
        return {
            ...state,
            logs: [],
            isShowErrorModal: false,
        };
    }

    if (action.type === CLOSE_ERROR_MODAL) {
        return {
            ...state,
            logs: state.logs.map((log) => {
                return {
                    ...log,
                    isNew: false,
                };
            }),
            isShowErrorModal: false,
        };
    }

    const isError = checkError(action.type);
    const isIgnoreError = checkIgnoreError(action);
    if (isError && !isIgnoreError) {
        const log: ErrorActionT = {
            type: action.type,
            error: action.error || action.payload.error,
            timestamp: Date.now(),
            isNew: true,
        };

        const logs = [log, ...state.logs].slice(0, MAX_LOGS_COUNT);

        return {
            ...state,
            logs,
            isShowErrorModal: true,
        };
    }

    return state;
};
