import { RECEIVED_NOTIFICATIONS, ReceivedNotificationsActionT } from 'common/store/notifications/types';
import { takeEvery } from 'redux-saga/effects';

// TODO remove
// eslint-disable-next-line require-yield
function* notificationsAffectSaga(action: ReceivedNotificationsActionT): WrapGeneratorT<void> {
    const { notifications } = action;

    for (const notification of notifications) {
        switch (notification.action) {
            default: {
                // nothing
            }
        }
    }
}

function* notificationsAffectsSaga(): WrapGeneratorT<void> {
    yield takeEvery(RECEIVED_NOTIFICATIONS, notificationsAffectSaga);
}

export default notificationsAffectsSaga;
