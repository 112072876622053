import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredShipperContractLaneCard.scss';
import { Trans } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    id: string;
};

const ExpiredShipperContractLaneCard: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="common:notifications.contract-lane-expired"
                components={{
                    id,
                }}
            />
        </div>
    );
});

export default ExpiredShipperContractLaneCard;
