import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './IdCell.scss';

type PropsT = {
    id: React.ReactNode;
};

const cx = classNames.bind(styles);

const IdCell: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    return <div className={cx('id')}>{id}</div>;
});

export default IdCell;
