import { FetchConfigT } from 'common/utils/api/do-fetch-models';

export class FetchError<TResponseData = any, TRequestConfig = any> extends Error {
    httpStatusCode: number;

    message: string;

    requestConfig: FetchConfigT<TRequestConfig>;

    response: TResponseData;

    constructor({
        message,
        httpStatusCode,
        requestConfig,
        response,
    }: {
        message: string;
        httpStatusCode: number;
        requestConfig: FetchConfigT<TRequestConfig>;
        response: TResponseData;
    }) {
        super(message);

        this.message = message;
        this.httpStatusCode = httpStatusCode;

        if (requestConfig.headers?.Authorization) {
            this.requestConfig = {
                ...requestConfig,
                headers: {
                    ...requestConfig.headers,
                    Authorization: '***',
                },
            };
        } else {
            this.requestConfig = requestConfig;
        }

        this.response = response;

        // fix for instanceof
        // https://github.com/microsoft/TypeScript/issues/13965
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, FetchError.prototype);
    }
}

export const checkIsFetchError = (error: Error | null | undefined): error is FetchError => {
    return error instanceof FetchError;
};
