import isNil from 'lodash/isNil';
import React from 'react';

export type SideBarHeaderHeightContextT = {
    value: number | null;
    setValue: (value: number) => void;
};

const DEFAULT_HEADER_HEIGHT = 70; // most popular case

const useSideBarHeaderHeight = (): SideBarHeaderHeightContextT => {
    const [value, setValue] = React.useState<number | null>(null);

    return {
        value: isNil(value) ? DEFAULT_HEADER_HEIGHT : value || 0,
        setValue,
    };
};

export default useSideBarHeaderHeight;
