import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneEditForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { FieldsEnum, FormValuesT, MAX_RADIUS_KM, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useSelector } from 'react-redux';
import OriginIcon from 'common/icons/OriginIcon';
import DestinationIcon from 'common/icons/DestinationIcon';
import validateForm from './validate-form';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import NumberInput from 'common/components/NumberInput/NumberInput';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import Input from 'common/components/Input/Input';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import moment from 'moment/moment';
import { prepareApRequest } from './prepare-api-requests';
import {
    ShipperContractLaneDetailsT,
    UpdateShipperContractLaneChangesT,
} from 'common/store/shipper-contract-lane-details/models';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import FieldValue from 'common/components/FieldValue/FieldValue';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';
import isNumber from 'lodash/isNumber';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { RequestStatusT } from 'common/utils/request-status';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { getDateFromISO, getMaxISODate, getMinISODate } from 'common/utils/time';
import { useEqualPreviousValue } from 'common/utils/hooks/useEqualPreviousValue';
import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const cx = classNames.bind(styles);

type PropsT = {
    data: ShipperContractLaneDetailsT | null;
    onCancel: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;

    onChangeOriginRadiusKm?: (radiusKm: number) => void;
    onChangeDestinationRadiusKm?: (radiusKm: number) => void;

    onSubmit: (detailsChanges: UpdateShipperContractLaneChangesT) => void;

    updateRequestStatus: RequestStatusT;

    contractDetails: ShipperContractDetailsT | null;
};

const ShipperContractLaneEditForm: React.FC<PropsT> = React.memo((props) => {
    const {
        data,
        onCancel,
        setNeedCloseConfirmation,

        onChangeOriginRadiusKm,
        onChangeDestinationRadiusKm,

        onSubmit,

        updateRequestStatus,

        contractDetails,
    } = props;

    const protectedContractDetails = useEqualPreviousValue(contractDetails);
    const { t } = useTranslation();

    const trailersDictById = useSelector(selectTrailersDictById) || {};
    const trailerDictModel = data?.dictTrailerId ? trailersDictById[data?.dictTrailerId] : null;

    const validate = React.useCallback(
        (values: FormValuesT) => {
            return validateForm(t, values);
        },
        [t],
    );

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.pickUpLocationRadius]: String(data?.originRadius || 0),
            [FieldsEnum.dropOffLocationRadius]: String(data?.destinationRadius || 0),

            [FieldsEnum.validFrom]: data?.validFrom ? new Date(data.validFrom) : null,
            [FieldsEnum.validTill]: data?.validTill ? new Date(data.validTill) : null,

            [FieldsEnum.layoverLimit]: String(data?.maximumLayover || 0),

            [FieldsEnum.externalLaneId]: data?.externalLaneId || '',
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [data]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const query = prepareApRequest(values);
            if (!query) {
                return;
            }

            onSubmit(query);

            formikHelpers.setTouched({});
        },
    });

    const handleRadiusSetValue = (field: string, value: string): void => {
        formik.setFieldValue(field, value);

        const parsedValue = parseSimpleNumber(value);
        const limitedValue = parsedValue ? Math.min(parsedValue, MAX_RADIUS_KM) : 0;

        if (field === FieldsEnum.dropOffLocationRadius && onChangeDestinationRadiusKm) {
            onChangeDestinationRadiusKm(limitedValue);
        }

        if (field === FieldsEnum.pickUpLocationRadius && onChangeOriginRadiusKm) {
            onChangeOriginRadiusKm(limitedValue);
        }
    };

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    React.useEffect(() => {
        if (hasAnyFieldValueChanges && setNeedCloseConfirmation) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    const validFrom = formik.values[FieldsEnum.validFrom];
    const minValidTillDate = useMemo((): string | undefined => {
        return (
            getMaxISODate(
                moment().add(1, 'day').format(),
                moment(validFrom).add(MIN_VALID_FROM_VALID_TILL_DAYS, 'day').format(),
                protectedContractDetails?.validFrom,
                data?.validTill,
            ) || datePicketRangePresets.anyFutureDate.minDate
        );
    }, [validFrom, protectedContractDetails]);
    const maxValidTillDate = useMemo((): string | undefined => {
        return getMinISODate(protectedContractDetails?.validTill) || datePicketRangePresets.anyFutureDate.maxDate;
    }, [protectedContractDetails]);
    const isDisabledValidTillDate = useMemo(() => {
        if (!maxValidTillDate) {
            return false;
        }

        return (
            maxValidTillDate < moment().add(1, 'day').format() ||
            (!!minValidTillDate &&
                !!maxValidTillDate &&
                getDateFromISO(minValidTillDate) > getDateFromISO(maxValidTillDate))
        );
    }, [minValidTillDate, maxValidTillDate]);

    const validTill = formik.values[FieldsEnum.validTill];
    const minValidFromDate = useMemo((): string | undefined => {
        return (
            getMaxISODate(moment().format(), protectedContractDetails?.validFrom) ||
            datePicketRangePresets.anyFutureDate.minDate
        );
    }, [validTill, protectedContractDetails]);
    const maxValidFromDate = useMemo((): string | undefined => {
        return (
            getMinISODate(
                validTill ? moment(validTill).format() : undefined,
                protectedContractDetails?.validTill
                    ? moment(protectedContractDetails.validTill)
                          .subtract(MIN_VALID_FROM_VALID_TILL_DAYS, 'days')
                          .format()
                    : undefined,
            ) || datePicketRangePresets.anyFutureDate.maxDate
        );
    }, [validTill, protectedContractDetails]);
    const isDisabledValidFromDate = useMemo(() => {
        if (!maxValidFromDate) {
            return false;
        }

        return (
            maxValidFromDate < moment().format() ||
            (!!minValidFromDate &&
                !!maxValidFromDate &&
                getDateFromISO(minValidFromDate) > getDateFromISO(maxValidFromDate))
        );
    }, [maxValidFromDate, minValidFromDate]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <FieldGroup>
                <FieldValue
                    className={cx('field', 'field--trailer-type')}
                    label={t(`common:shipper-lane-details.fields.trailer-type.label`)}
                    icon={
                        <TrailerIcon
                            size={20}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    value={
                        trailerDictModel
                            ? t('common:trailer-subtype-template', {
                                  length: trailerDictModel.length,
                                  model: trailerDictModel.model,
                                  eur1Pallets: trailerDictModel.eur1Pallets,
                                  eur2Pallets: trailerDictModel.eur2Pallets,
                              })
                            : ''
                    }
                />
                <FieldValue
                    icon={<EmissionIcon strokeColor={StyleGuideColorsEnum.brandDark} />}
                    className={cx('field', 'field--emission-class')}
                    label={t('common:shipper-lane-details.fields.emission-type.label')}
                    value={<EmissionClassLabel isCompact emissionClass={data?.emissionClass} />}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={<OriginIcon />}
                    className={cx('field', 'field--location')}
                    label={t('common:shipper-lane-details.fields.origin.label')}
                    value={data?.originFullAddress}
                />
                <FormikField
                    className={cx('field', 'field--radius')}
                    name={FieldsEnum.pickUpLocationRadius}
                    error={formik.errors[FieldsEnum.pickUpLocationRadius]}
                    meta={formik.getFieldMeta(FieldsEnum.pickUpLocationRadius)}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    setFieldValue={handleRadiusSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.pickUpLocationRadius}
                            unitType={UnitTypeEnum.kilometersAbbreviation}
                            value={formik.values[FieldsEnum.pickUpLocationRadius]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    icon={<DestinationIcon />}
                    className={cx('field', 'field--location')}
                    label={t('common:shipper-lane-details.fields.destination.label')}
                    value={data?.destinationFullAddress}
                />
                <FormikField
                    className={cx('field', 'field--radius')}
                    name={FieldsEnum.dropOffLocationRadius}
                    error={formik.errors[FieldsEnum.dropOffLocationRadius]}
                    meta={formik.getFieldMeta(FieldsEnum.dropOffLocationRadius)}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    setFieldValue={handleRadiusSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.dropOffLocationRadius}
                            unitType={UnitTypeEnum.kilometersAbbreviation}
                            value={formik.values[FieldsEnum.dropOffLocationRadius]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--date')}
                    name={FieldsEnum.validFrom}
                    error={formik.errors[FieldsEnum.validFrom]}
                    meta={formik.getFieldMeta(FieldsEnum.validFrom)}
                    label={t('common:shipper-lane-details.fields.valid-from.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.validFrom]}
                            placeholder={t('common:shipper-lane-details.fields.valid-from.placeholder')}
                            onChange={props.onChange}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasYearMonthForm
                            minDate={minValidFromDate}
                            maxDate={maxValidFromDate}
                            isDisabled={isDisabledValidFromDate}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--date')}
                    name={FieldsEnum.validTill}
                    error={formik.errors[FieldsEnum.validTill]}
                    meta={formik.getFieldMeta(FieldsEnum.validTill)}
                    label={t('common:shipper-lane-details.fields.valid-till.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.validTill]}
                            placeholder={t('common:shipper-lane-details.fields.valid-till.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasYearMonthForm
                            minDate={minValidTillDate}
                            maxDate={maxValidTillDate}
                            isDisabled={isDisabledValidTillDate}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--limit')}
                    name={FieldsEnum.layoverLimit}
                    error={formik.errors[FieldsEnum.layoverLimit]}
                    meta={formik.getFieldMeta(FieldsEnum.layoverLimit)}
                    label={t('common:shipper-lane-details.fields.layover-limit.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={300}>
                            {t('common:shipper-lane-details.fields.layover-limit.tooltip')}
                        </TooltipContent>
                    }
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.layoverLimit}
                            unitType={UnitTypeEnum.hoursAbbreviation}
                            value={formik.values[FieldsEnum.layoverLimit]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
                <FieldValue
                    className={cx('field', 'field--limit')}
                    label={t('common:shipper-lane-details.fields.orders-left.label')}
                    value={
                        isNumber(data?.ordersLeft) && isNumber(data?.maxNumberOfOrders)
                            ? `${data?.ordersLeft}/${data?.maxNumberOfOrders}`
                            : null
                    }
                />
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--external-lane-id')}
                    name={FieldsEnum.externalLaneId}
                    error={formik.errors[FieldsEnum.externalLaneId]}
                    meta={formik.getFieldMeta(FieldsEnum.externalLaneId)}
                    label={t('common:shipper-lane-details.fields.external-lane-id.label')}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:shipper-lane-details.fields.external-lane-id.tooltip')}
                        </TooltipContent>
                    }
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.externalLaneId}
                            value={formik.values[FieldsEnum.externalLaneId]}
                            placeholder={t('common:shipper-lane-details.fields.external-lane-id.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasChanges={props.hasChanges}
                        />
                    )}
                </FormikField>
                <FieldGroupEmptyItem className={cx('field', 'field--limit')} />
            </FieldGroup>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout isAbsolute hasPaddings>
                <div className={cx('actions')}>
                    <Button
                        className={cx('actions__action')}
                        theme={ButtonThemeEnum.secondary}
                        type="button"
                        testSelector="cancel"
                        onClick={onCancel}
                    >
                        {t('common:shipper-lane-details.actions.cancel')}
                    </Button>
                    <Button
                        className={cx('actions__action')}
                        theme={ButtonThemeEnum.primary}
                        type="submit"
                        isLoading={updateRequestStatus.loading}
                        testSelector="save"
                    >
                        {t('common:shipper-lane-details.actions.save')}
                    </Button>
                </div>
            </FooterSideBarLayout>
        </form>
    );
});

export default ShipperContractLaneEditForm;
