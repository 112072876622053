import React from 'react';
import classNames from 'classnames/bind';

import styles from './CommonDocumentParamsDetails.scss';
import { useTranslation } from 'react-i18next';
import FieldValue from 'common/components/FieldValue/FieldValue';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, documentsWithoutExpirySet, StyleGuideColorsEnum } from 'common/constants';
import { formatDate } from 'common/utils/time';
import { DocumentVersionT } from 'common/store/documents/models';

const cx = classNames.bind(styles);

type PropsT = {
    actualVersion: DocumentVersionT | null;
};

const CommonDocumentParamsDetails: React.FC<PropsT> = (props) => {
    const { actualVersion } = props;

    const { t } = useTranslation();

    const isShowExpirationDate =
        !!actualVersion?.documentType && !documentsWithoutExpirySet.has(actualVersion?.documentType);

    return (
        <>
            {isShowExpirationDate && (
                <FieldValue
                    icon={
                        <CalendarIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    label={t('common:documents.details.valid-till')}
                    value={formatDate('DD.MM.YYYY', actualVersion?.validTill)}
                    placeholder={t('common:fields.placeholder.empty')}
                />
            )}
        </>
    );
};

export default CommonDocumentParamsDetails;
