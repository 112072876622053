import { TFunction } from 'i18next';

import {
    FieldsEnum,
    FormErrorsT,
    FormValuesT,
    PointContactsFieldsEnum,
    RoutePointT,
    ShipmentFieldsEnum,
} from './constants';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { checkEmail, checkMinValue, checkNotEmpty, checkNotZero, checkPhoneNumber } from 'common/utils/form-validators';
import { checkIsEmptyPhoneNumber } from 'common/utils/phone-number';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { UnitTypeEnum } from 'common/constants';
import keys from 'lodash/keys';
import set from 'lodash/set';

const SHIPMENT_REQUIRED_FIELDS = [
    ShipmentFieldsEnum.cargoUnitType,
    ShipmentFieldsEnum.cargoUnitsCount,
    ShipmentFieldsEnum.cargoWeight,
    ShipmentFieldsEnum.pickUpPointId,
    ShipmentFieldsEnum.dropOffPointId,
] as const;

const SHIPMENT_NOT_ZERO_FIELDS = [ShipmentFieldsEnum.cargoUnitsCount] as const;

const POINT_CONTACTS_EMAILS_FIELDS = [PointContactsFieldsEnum.email] as const;

const POINT_CONTACTS_PHONE_FIELDS = [PointContactsFieldsEnum.phoneNumber] as const;

type ValidateMetaT = {
    routePoints: Array<RoutePointT>;
    trailerType: ApiTrailerDictTypeT | null | undefined;
};

const validate = (t: TFunction, values: FormValuesT, meta: ValidateMetaT): FormErrorsT => {
    const { routePoints, trailerType } = meta;

    const errors: FormErrorsT = {};

    const shipmentsFormValues = values[FieldsEnum.shipments];
    shipmentsFormValues.forEach((shipmentFormValues, index) => {
        const shipmentFormErrors: NonNullable<FormErrorsT[FieldsEnum.shipments]>[number] = {
            ...checkNotEmpty(t, SHIPMENT_REQUIRED_FIELDS, shipmentFormValues),
            ...checkNotZero(t, SHIPMENT_NOT_ZERO_FIELDS, shipmentFormValues),
            ...checkMinValue(
                t,
                [ShipmentFieldsEnum.cargoUnitsCount, ShipmentFieldsEnum.cargoWeight],
                shipmentFormValues,
                0,
            ),
        };

        const parsedCargoWeight = parseSimpleNumber(shipmentFormValues[ShipmentFieldsEnum.cargoWeight]);
        if (parsedCargoWeight && trailerType?.weight && parsedCargoWeight > trailerType.weight) {
            shipmentFormErrors[ShipmentFieldsEnum.cargoWeight] = t('common:new-order-form.errors.cargo-is-too-huge');
        }

        const parsedUnitsCount = parseSimpleNumber(shipmentFormValues[ShipmentFieldsEnum.cargoUnitsCount]);
        const cargoUnitType = shipmentFormValues[ShipmentFieldsEnum.cargoUnitType];

        const isMoreUnitLimits =
            (cargoUnitType === UnitTypeEnum.eur1Pallets &&
                trailerType?.eur1Pallets &&
                parsedUnitsCount &&
                parsedUnitsCount > trailerType.eur1Pallets) ||
            (cargoUnitType === UnitTypeEnum.eur2Pallets &&
                trailerType?.eur2Pallets &&
                parsedUnitsCount &&
                parsedUnitsCount > trailerType.eur2Pallets);

        if (isMoreUnitLimits) {
            shipmentFormErrors[ShipmentFieldsEnum.cargoUnitsCount] = t(
                'common:new-order-form.errors.cargo-is-too-huge',
            );
        }

        keys(shipmentFormErrors).forEach((fieldName) => {
            set(
                errors,
                `${FieldsEnum.shipments}.${index}.${fieldName}`,
                shipmentFormErrors[fieldName as ShipmentFieldsEnum],
            );
        });
    });

    const contactsFormValues = values[FieldsEnum.contacts];
    contactsFormValues.forEach((contactFormValues, index) => {
        const point = routePoints[index];
        if (point?.driveThru) {
            return;
        }

        const contactFormErrors: NonNullable<FormErrorsT[FieldsEnum.contacts]>[number] = {
            ...checkEmail(t, POINT_CONTACTS_EMAILS_FIELDS, contactFormValues),
            ...checkPhoneNumber(t, POINT_CONTACTS_PHONE_FIELDS, contactFormValues),
        };

        const unDetailedAddressError = t('common:new-order-form.errors.undetailed-address');
        const isUnDetailedAddress = !point?.address?.street1;
        if (isUnDetailedAddress && !contactFormValues[PointContactsFieldsEnum.fullName]) {
            contactFormErrors[PointContactsFieldsEnum.fullName] = unDetailedAddressError;
        }

        if (isUnDetailedAddress && checkIsEmptyPhoneNumber(contactFormValues[PointContactsFieldsEnum.phoneNumber])) {
            contactFormErrors[PointContactsFieldsEnum.phoneNumber] = unDetailedAddressError;
        }

        keys(contactFormErrors).forEach((fieldName) => {
            set(
                errors,
                `${FieldsEnum.contacts}.${index}.${fieldName}`,
                contactFormErrors[fieldName as PointContactsFieldsEnum],
            );
        });
    });

    return errors;
};

export default validate;
