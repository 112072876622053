import {
    AuthActionT,
    AuthStateT,
    EMAIL_VERIFICATION_REQUEST_BEGIN,
    RESET_PASSWORD_REQUEST_BEGIN,
    RESET_PASSWORD_REQUEST_ERROR,
    RESET_PASSWORD_REQUEST_SUCCESS,
    SET_USER,
    RESET_USER,
    SIGN_IN_REQUEST_BEGIN,
    SIGN_IN_REQUEST_ERROR,
    SIGN_IN_REQUEST_SUCCESS,
    SIGN_OUT_REQUEST_BEGIN,
    SIGN_OUT_REQUEST_ERROR,
    SIGN_OUT_REQUEST_SUCCESS,
    SIGN_UP_REQUEST_BEGIN,
    SIGN_UP_REQUEST_ERROR,
    SIGN_UP_REQUEST_SUCCESS,
    UPDATE_PASSWORD_REQUEST_BEGIN,
    UPDATE_PASSWORD_REQUEST_ERROR,
    UPDATE_PASSWORD_REQUEST_SUCCESS,
    EMAIL_VERIFICATION_REQUEST_SUCCESS,
    EMAIL_VERIFICATION_REQUEST_ERROR,
    DESTROY_SESSION,
} from './types';
import requestStatus from 'common/utils/request-status';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';

const getInitialState = (profileType: AuthProfileTypeEnum): AuthStateT => ({
    user: null,
    userClaims: null,
    signInRequest: requestStatus.initial,
    resetPasswordRequest: requestStatus.initial,
    updatePasswordRequest: requestStatus.initial,
    signUpRequest: requestStatus.initial,
    signOutRequest: requestStatus.initial,
    emailVerificationRequest: requestStatus.initial,
    profileType,
});

export default (profileType: AuthProfileTypeEnum) =>
    (state = getInitialState(profileType), action: AuthActionT): AuthStateT => {
        switch (action.type) {
            case SIGN_IN_REQUEST_BEGIN: {
                return {
                    ...state,
                    signInRequest: requestStatus.loading,
                };
            }
            case SIGN_IN_REQUEST_SUCCESS: {
                const { user, userClaims } = action;
                return {
                    ...state,
                    user,
                    userClaims,
                    signInRequest: requestStatus.ok,
                };
            }
            case SIGN_IN_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    signInRequest: requestStatus.setError(error),
                };
            }
            case SET_USER: {
                const { user, userClaims } = action;

                return {
                    ...state,
                    user,
                    userClaims,
                };
            }
            case RESET_USER: {
                return {
                    ...state,
                    user: null,
                };
            }
            case RESET_PASSWORD_REQUEST_BEGIN: {
                return {
                    ...state,
                    resetPasswordRequest: requestStatus.loading,
                };
            }
            case RESET_PASSWORD_REQUEST_SUCCESS: {
                return {
                    ...state,
                    resetPasswordRequest: requestStatus.ok,
                };
            }
            case RESET_PASSWORD_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    resetPasswordRequest: requestStatus.setError(error),
                };
            }
            case UPDATE_PASSWORD_REQUEST_BEGIN: {
                return {
                    ...state,
                    updatePasswordRequest: requestStatus.loading,
                };
            }
            case UPDATE_PASSWORD_REQUEST_SUCCESS: {
                return {
                    ...state,
                    updatePasswordRequest: requestStatus.ok,
                };
            }
            case UPDATE_PASSWORD_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    updatePasswordRequest: requestStatus.setError(error),
                };
            }
            case SIGN_UP_REQUEST_BEGIN: {
                return {
                    ...state,
                    signUpRequest: requestStatus.loading,
                };
            }
            case SIGN_UP_REQUEST_SUCCESS: {
                return {
                    ...state,
                    signUpRequest: requestStatus.ok,
                };
            }
            case SIGN_UP_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    signUpRequest: requestStatus.setError(error),
                };
            }
            case SIGN_OUT_REQUEST_BEGIN: {
                return {
                    ...state,
                    signOutRequest: requestStatus.loading,
                };
            }
            case SIGN_OUT_REQUEST_SUCCESS: {
                return {
                    ...state,
                    signOutRequest: requestStatus.ok,
                };
            }
            case SIGN_OUT_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    signOutRequest: requestStatus.setError(error),
                };
            }
            case EMAIL_VERIFICATION_REQUEST_BEGIN: {
                return {
                    ...state,
                    emailVerificationRequest: requestStatus.loading,
                };
            }
            case EMAIL_VERIFICATION_REQUEST_SUCCESS: {
                return {
                    ...state,
                    emailVerificationRequest: requestStatus.ok,
                };
            }
            case EMAIL_VERIFICATION_REQUEST_ERROR: {
                const { error } = action;

                return {
                    ...state,
                    emailVerificationRequest: requestStatus.setError(error),
                };
            }

            case DESTROY_SESSION: {
                return {
                    ...getInitialState(profileType),
                    signOutRequest: state.signOutRequest,
                    user: state.user,
                    userClaims: state.userClaims,
                };
            }

            default:
                return state;
        }
    };
