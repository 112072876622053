import {
    FETCH_VEHICLE_SCHEDULE_REQUEST,
    FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN,
    FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR,
    FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS,
    FetchVehicleScheduleActionT,
    FetchVehicleScheduleBeginActionT,
    FetchVehicleScheduleErrorActionT,
    FetchVehicleScheduleSuccessActionT,
    SetUnavailableVehicleRequestBeginActionT,
    SetUnavailableVehicleRequestActionT,
    SET_UNAVAILABLE_VEHICLE_REQUEST,
    SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN,
    SetUnavailableVehicleRequestErrorActionT,
    SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR,
    SetUnavailableVehicleRequestSuccessActionT,
    SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS,
} from './types';
import { VehicleIdT, VehicleScheduleT } from './models';
import { SetUnavailableVehicleQueryT } from 'common/utils/api/models';

export const fetchVehicleSchedule = (vehicleId: VehicleIdT, options?: FetchOptionsT): FetchVehicleScheduleActionT => ({
    type: FETCH_VEHICLE_SCHEDULE_REQUEST,
    vehicleId,
    options,
});

export const fetchVehicleScheduleBegin = (vehicleId: VehicleIdT): FetchVehicleScheduleBeginActionT => ({
    type: FETCH_VEHICLE_SCHEDULE_REQUEST_BEGIN,
    vehicleId,
});

export const fetchVehicleScheduleSuccess = (
    vehicleId: VehicleIdT,
    schedule: VehicleScheduleT,
): FetchVehicleScheduleSuccessActionT => ({
    type: FETCH_VEHICLE_SCHEDULE_REQUEST_SUCCESS,
    vehicleId,
    schedule,
});

export const fetchVehicleScheduleError = (vehicleId: VehicleIdT, error: Error): FetchVehicleScheduleErrorActionT => ({
    type: FETCH_VEHICLE_SCHEDULE_REQUEST_ERROR,
    error,
    vehicleId,
});

export const setUnavailableVehicleRequest = (
    query: SetUnavailableVehicleQueryT,
    companyId: CompanyIdT,
): SetUnavailableVehicleRequestActionT => ({
    type: SET_UNAVAILABLE_VEHICLE_REQUEST,
    query,
    companyId,
});

export const setUnavailableVehicleRequestBegin = (): SetUnavailableVehicleRequestBeginActionT => ({
    type: SET_UNAVAILABLE_VEHICLE_REQUEST_BEGIN,
});

export const setUnavailableVehicleRequestError = (error: Error): SetUnavailableVehicleRequestErrorActionT => ({
    type: SET_UNAVAILABLE_VEHICLE_REQUEST_ERROR,
    error,
});

export const setUnavailableVehicleRequestSuccess = (): SetUnavailableVehicleRequestSuccessActionT => ({
    type: SET_UNAVAILABLE_VEHICLE_REQUEST_SUCCESS,
});
