import { ApiCompanyContactMediaT, ApiCompanyContactRequestT } from 'common/utils/api/models';
import { FormValuesT, FieldsEnum, MEDIA_FIELDS_NAME } from './constants';
import moment from 'moment';
import { parsePhoneNumber } from 'common/utils/input-parsers';

const prepareApiContact = (formValues: FormValuesT): ApiCompanyContactRequestT => {
    const medias = MEDIA_FIELDS_NAME.reduce<ApiCompanyContactMediaT[]>((acc, fieldName) => {
        const value = formValues[fieldName];
        if (value) {
            acc.push({
                type: fieldName,
                login: value,
            });
        }

        return acc;
    }, []);

    return {
        formal: formValues[FieldsEnum.formal],
        name: formValues[FieldsEnum.firstName],
        surname: formValues[FieldsEnum.lastName],

        position: formValues[FieldsEnum.position],

        main: formValues[FieldsEnum.isDefault],
        deskPhone: parsePhoneNumber(formValues[FieldsEnum.desktopPhone]),
        mobilePhone: parsePhoneNumber(formValues[FieldsEnum.mobilePhone]),
        fax: formValues[FieldsEnum.faxNumber],
        email: formValues[FieldsEnum.email],

        website: formValues[FieldsEnum.webpageLink],

        birthday: moment(formValues[FieldsEnum.birthday]).format('YYYY-MM-DD'), // `${}T00:00:00.000000+00:00`,
        language: formValues[FieldsEnum.language] || undefined,
        residence: formValues[FieldsEnum.countryCode] || undefined,

        medias,
        roles: [],
    };
};

export default prepareApiContact;
