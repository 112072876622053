import { SocialMediaEnum, StyleGuideColorsEnum } from 'common/constants';
import React from 'react';
import SkypeIcon from 'common/icons/SkypeIcon';

export const ICON_BY_MEDIA: Record<SocialMediaEnum, React.ReactNode> = {
    [SocialMediaEnum.skype]: <SkypeIcon fillColor={StyleGuideColorsEnum.brandDark} />,
    [SocialMediaEnum.whatsapp]: null,
    [SocialMediaEnum.viber]: null,
    [SocialMediaEnum.telegram]: null,
    [SocialMediaEnum.zoom]: null,
    [SocialMediaEnum.msTeams]: null,
    [SocialMediaEnum.webEx]: null,
};
