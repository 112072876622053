import { eventChannel } from 'redux-saga';
import { call, take } from 'redux-saga/effects';

function createVisibilityChangeChannel() {
    return eventChannel((emitter) => {
        document.addEventListener('visibilitychange', () => {
            emitter(document.hidden);
        });

        return () => {
            // nothing
        };
    });
}

function* watchDocumentVisibilityChangeSaga(visibilityChangeSaga: any): WrapGeneratorT<void> {
    const visibilityChangeChannel = yield call(createVisibilityChangeChannel);
    while (true) {
        const isHidden = yield take(visibilityChangeChannel);
        if (isHidden) {
            yield call(visibilityChangeSaga);
        }
    }
}

export default watchDocumentVisibilityChangeSaga;
