import * as React from 'react';
import { ShipmentStatusEnum, ApiShipmentStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status?: ApiShipmentStatusT | null | undefined;
};

export const LOAD_STATUS_T_MAP: Record<ApiShipmentStatusT, string> = {
    [ShipmentStatusEnum.awaitingLoading]: 'common:load-status.AWAITING_LOADING',
    [ShipmentStatusEnum.loading]: 'common:load-status.LOADING',
    [ShipmentStatusEnum.inTransit]: 'common:load-status.IN_TRANSIT',
    [ShipmentStatusEnum.unloading]: 'common:load-status.UNLOADING',
    [ShipmentStatusEnum.delivered]: 'common:load-status.DELIVERED',
    [ShipmentStatusEnum.canceled]: 'common:load-status.CANCELED',
};

const LoadStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(LOAD_STATUS_T_MAP[status]);
});

export default LoadStatus;
