import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TabsLabel.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    isShouldShowOneTab?: boolean;
    children: Array<React.ReactNode>;
};

const TabsLabel: React.FC<PropsT> = (props) => {
    const { children, isShouldShowOneTab, className } = props;

    if (children?.length === 1 && !isShouldShowOneTab) {
        return null;
    }

    return <div className={cs(cx('tabs'), className)}>{children}</div>;
};

export default TabsLabel;
