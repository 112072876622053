import * as React from 'react';

import styles from './Alert.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import { StyleGuideColorsEnum } from 'common/constants';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentButton from '../TransparentButton/TransparentButton';

export enum AlertThemeEnum {
    brandAccent = 'brand-accent',
    tomatoRed = 'tomato-red',
    charcoal = 'charcoal',
    orange = 'orange',
    gray = 'gray',
}

export enum AlertSizeEnum {
    small = 'small',
    medium = 'medium',
}

export type PropsT = {
    className?: string;
    icon: React.ReactNode;
    onClose?: () => void;
    size: AlertSizeEnum;
    theme: AlertThemeEnum;
    actionText?: React.ReactNode;
    onClickAction?: () => void;
    isAlignItemsCenter?: boolean;
};

const cx = classNames.bind(styles);

const closeIconFillColorMap: Record<AlertThemeEnum, StyleGuideColorsEnum> = {
    [AlertThemeEnum.brandAccent]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.tomatoRed]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.charcoal]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.orange]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.gray]: StyleGuideColorsEnum.white,
};

const Alert: React.FC<PropsT> = React.memo((props) => {
    const { children, className, icon, theme, size, onClose, onClickAction, actionText, isAlignItemsCenter } = props;

    const closeIconFillColor = closeIconFillColorMap[theme];

    return (
        <div
            className={cs(
                cx('inner', {
                    [`inner--theme-${theme}`]: true,
                    [`inner__size-${size}`]: true,
                    'inner--is-align-items-center': !!isAlignItemsCenter,
                }),
                className,
            )}
        >
            <div
                className={cx('icon', {
                    [`icon__size-${size}`]: true,
                })}
            >
                {icon}
            </div>
            <div className={cx('text')}>{children}</div>
            {actionText && onClickAction ? (
                <TransparentButton onClick={onClickAction} className={cx('action')}>
                    <span className={cx('action-text')}>{actionText}</span>
                </TransparentButton>
            ) : null}
            {onClose && (
                <TransparentButton onClick={onClose} className={cx('close')}>
                    <CloseIcon fillColor={closeIconFillColor} />
                </TransparentButton>
            )}
        </div>
    );
});

export default Alert;
