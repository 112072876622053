import {
    TRUCKS_DICT_REQUEST_BEGIN,
    TRUCKS_DICT_REQUEST_ERROR,
    TRUCKS_DICT_REQUEST_SUCCESS,
    TrucksDictActionT,
    TrucksDictStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';

const initialState: TrucksDictStateT = {
    trucksDict: null,
    trucksDictRequest: requestStatus.initial,
};

export default (state = initialState, action: TrucksDictActionT): TrucksDictStateT => {
    switch (action.type) {
        case TRUCKS_DICT_REQUEST_BEGIN: {
            return {
                ...state,
                trucksDictRequest: requestStatus.loading,
            };
        }

        case TRUCKS_DICT_REQUEST_SUCCESS: {
            const { trucksDict } = action;

            const trucksById = keyBy(trucksDict, 'id');

            return {
                ...state,
                trucksDict: trucksById,
                trucksDictRequest: requestStatus.ok,
            };
        }

        case TRUCKS_DICT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                trucksDictRequest: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
