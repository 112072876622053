import React from 'react';
import { ScrollBarWidthContextT } from 'common/utils/hooks/useScrollBarWidth';
import { ScrollTopContextT } from 'common/utils/hooks/useScrollTop';
import { SideBarFooterHeightContextT } from 'common/utils/hooks/useSideBarFooterHeight';
import { SideBarHeaderHeightContextT } from 'common/utils/hooks/useSideBarHeaderHeight';

export type SideBarLayoutContextT = {
    scrollBarWidth: ScrollBarWidthContextT | null;
    scrollTop?: ScrollTopContextT | null;
    headerHeight?: SideBarHeaderHeightContextT | null;
    footerHeight?: SideBarFooterHeightContextT | null;
};

const SideBarLayoutContext = React.createContext<SideBarLayoutContextT>({
    scrollBarWidth: null,
    scrollTop: null,
    headerHeight: null,
    footerHeight: null,
});

export default SideBarLayoutContext;
