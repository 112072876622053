import React from 'react';
import cs from 'classnames';
import Burger from '@animated-burgers/burger-squeeze';

import './override.css';

type PropsT = {
    className?: string;
    isOpen: boolean;
};

const NavBurger: React.FC<PropsT> = (props) => {
    const { className, isOpen } = props;

    return <Burger className={cs(className)} isOpen={isOpen} />;
};

export default NavBurger;
