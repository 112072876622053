import BaseTranziitApi, { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';
import { OverwritePageRequestT, OverwritePageRequestWithSortT } from 'common/utils/api/models';
import { authApi } from 'common/utils/api/auth/factory';
import { MS_IN_MIN } from 'common/utils/time';

class ShipperTranziitApi extends BaseTranziitApi {
    private static instance: ShipperTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '/api',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): ShipperTranziitApi {
        if (!ShipperTranziitApi.instance) {
            ShipperTranziitApi.instance = new ShipperTranziitApi();
        }

        return ShipperTranziitApi.instance;
    }

    getRFQDefaultOffer = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdOffersDefault.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/rfqs/${rfqId}/offers/default`,
            expectedResponseContentType: 'json',
        });
    };

    fetchAssetTracksByOrder = async (
        orderId: Api2Paths.ApiV2ShipperOrders$OrderIdAssetsCoordinates.Get.Parameters.OrderId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2ShipperOrders$OrderIdAssetsCoordinates.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2ShipperOrders$OrderIdAssetsCoordinates.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/shipper/orders/${orderId}/assets-coordinates`,
            expectedResponseContentType: 'json',
        });
    };

    fetchOrdersPage = async (
        filters: OverwritePageRequestT<Api2Paths.ApiV2OrderFilter.Post.RequestBody>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2OrderFilter.Post.Responses.$200>> => {
        const { page, ...data } = filters;

        const query: OverwritePageRequestT<{}> = {
            page,
        };

        return this.doFetch<Api2Paths.ApiV2OrderFilter.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/order/filter`,
            query,
            data,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperInvoicesPage = async (
        apiFetchOrdersRequest: OverwritePageRequestWithSortT<ApiPaths.ApiV1ShipperStripeInvoices.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1ShipperStripeInvoices.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1ShipperStripeInvoices.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/shipper/stripe/invoices`,
            query: apiFetchOrdersRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchRFQsPage = async (
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV2Rfqs.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/rfqs',
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchRFQDetails = async (
        id: Api2Paths.ApiV2Rfqs$RfqId.Get.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/rfqs/${id}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchRFQsStats = async (): Promise<TranziitApiResultT<Api2Paths.ApiV2RfqsCounts.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2RfqsCounts.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/rfqs/counts',
            expectedResponseContentType: 'json',
        });
    };

    closeRFQ = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdStatusClosed.Put.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqIdStatusClosed.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdStatusClosed.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/rfqs/${rfqId}/status/closed`,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContractDetails = async (
        shipperContractId: ApiPaths.ApiV1CompanyShipperContracts$ContractId.Get.Parameters.ContractId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CompanyShipperContracts$ContractId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CompanyShipperContracts$ContractId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/shipper-contracts/${shipperContractId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContractLaneDetails = async (
        shipperContractId: Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes$LaneId.Get.Parameters.ContractId,
        laneId: Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes$LaneId.Get.Parameters.LaneId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes$LaneId.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes$LaneId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/shipper-contracts/${shipperContractId}/lanes/${laneId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchPartnerShipperContracts = async (
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV1CompanyShipperContracts.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1CompanyShipperContracts.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1CompanyShipperContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/shipper-contracts`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchShipperContract = async (
        contractId: ApiPaths.ApiV1CompanyShipperContracts$ContractIdSourceFile.Get.Parameters.ContractId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CompanyShipperContracts$ContractIdSourceFile.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CompanyShipperContracts$ContractIdSourceFile.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/shipper-contracts/${contractId}/source-file`,
            expectedResponseContentType: 'blob',
        });
    };

    fetchShipperContractLanes = async (
        contractId: Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes.Get.Parameters.ContractId,
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1CompanyShipperContracts$ContractIdLanes.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/shipper-contracts/${contractId}/lanes`,
            expectedResponseContentType: 'json',
            query,
        });
    };

    getOrderDetails = async (
        orderId: Api2Paths.ApiV2Order$OrderId.Get.Parameters.OrderId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Order$OrderId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Order$OrderId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/order/${orderId}`,
            expectedResponseContentType: 'json',
        });
    };
}

const shipperTranziitApi = ShipperTranziitApi.getInstance();

export default shipperTranziitApi;
