import * as React from 'react';

type PropsT = {
    isDisabled?: boolean;
    className?: string;
};

const BigCheckIcon: React.FC<PropsT> = (props) => {
    const { isDisabled, className } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            className={className}
        >
            <defs>
                <rect id="ysnd058vha" width="30" height="30" x="0" y="0" rx="15" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill={isDisabled ? '#9EA0A5' : '#8ABA2E'} xlinkHref="#ysnd058vha" />
                <use fill="#000" fillOpacity="0.1" xlinkHref="#ysnd058vha" />
                <path
                    fill="#FFF"
                    d="M20.314 12.707l-6.072 6.071a.999.999 0 01-1.414 0l-2.535-2.535a1 1 0 011.414-1.415l1.829 1.83 5.364-5.365a.999.999 0 111.414 1.414"
                />
            </g>
        </svg>
    );
};

BigCheckIcon.displayName = 'BigCheckIcon';

const storyProps: PropsT = {
    isDisabled: false,
};

export { storyProps };
export default BigCheckIcon;
