import {
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS,
    FetchCarrierContractDetailsActionT,
    FetchCarrierContractDetailsBeginActionT,
    FetchCarrierContractDetailsErrorActionT,
    FetchCarrierContractDetailsSuccessActionT,
} from './types';
import { PartnerContractDetailsT } from './models';

export const fetchCarrierContractDetails = (
    partnerId: PartnerIdT,
    contractId: CarrierContractIdT,
    options?: FetchOptionsT,
): FetchCarrierContractDetailsActionT => ({
    type: FETCH_CARRIER_CONTRACT_DETAILS_REQUEST,
    partnerId,
    contractId,
    options,
});

export const fetchCarrierContractDetailsBegin = (
    contractId: CarrierContractIdT,
): FetchCarrierContractDetailsBeginActionT => ({
    type: FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN,
    contractId,
});

export const fetchCarrierContractDetailsSuccess = (
    contractId: CarrierContractIdT,
    contractDetails: PartnerContractDetailsT | null,
): FetchCarrierContractDetailsSuccessActionT => ({
    type: FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS,
    contractId,
    contractDetails,
});

export const fetchCarrierContractDetailsError = (
    contractId: CarrierContractIdT,
    error: Error,
): FetchCarrierContractDetailsErrorActionT => ({
    type: FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR,
    error,
    contractId,
});
