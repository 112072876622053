import * as React from 'react';

type PropsT = {};

const AddAccountDuotineIcon: React.FC<PropsT> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path d="M12.25 13c-2.018 0-4.327.96-4.5 1.5h9c-.15-.533-2.475-1.5-4.5-1.5zm0-4.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                <path
                    fill="#FFF"
                    d="M12.25 10a3 3 0 100-6 3 3 0 100 6zm0-4.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0 6c-2.002 0-6 1.005-6 3V16h12v-1.5c0-1.995-3.998-3-6-3zm-4.5 3c.165-.54 2.482-1.5 4.5-1.5 2.025 0 4.35.967 4.5 1.5h-9zM5.5 12.25V10h2.25V8.5H5.5V6.25H4V8.5H1.75V10H4v2.25h1.5z"
                />
            </g>
        </svg>
    );
};

AddAccountDuotineIcon.displayName = 'AddAccountDuotineIcon';

const storyProps: PropsT = {};

export { storyProps };
export default AddAccountDuotineIcon;
