import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';

const asyncValidate = (addMemberRequestStatus: RequestStatusT): AsyncFormErrorsT => {
    if (
        checkIsTranziitApiRequestError(addMemberRequestStatus.error) &&
        addMemberRequestStatus.error.subType === TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist
    ) {
        return {
            [FieldsEnum.email]: addMemberRequestStatus.error?.subType,
        };
    }

    return {};
};

export default asyncValidate;
