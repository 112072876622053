import * as React from 'react';
import { FieldsEnum, FormValuesT, RoutePointFieldsEnum } from '../constants';
import { ReserveT } from 'common/store/order-creation/models';
import { useTranslation } from 'react-i18next';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import classNames from 'classnames/bind';
import styles from './DriveThroughRoutePointPicker.scss';
import DeleteWaypointTrigger from 'common/layouts/NewOrderPage/ShipmentDetailsForm/DeleteWaypointTrigger/DeleteWaypointTrigger';
import WaypointNumberIcon from 'common/layouts/NewOrderPage/ShipmentDetailsForm/WaypointNumberIcon/WaypointNumberIcon';
import { SyncShipmentDetailsFormMapStateContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-map-state';
import { OnChangeRoutePointReserveT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/models';
import { getFieldFormValue } from 'common/utils/form-values';
import { useFormikContext } from 'formik';

type PropsT = {
    className?: string;
    routePointIndex: number;
    reserve: ReserveT | null;
    onRemove: (index: number) => void;
    isAllowRemove: boolean;
    onManualSelectLocation: (location: LocationT | null, routePointIndex: number) => void;
    onChangeRoutePointReserve: OnChangeRoutePointReserveT;
    isDisabledPreviewReserve?: boolean;
    isShowSuggestLoading?: boolean;
};

const cx = classNames.bind(styles);

const DriveThroughRoutePointPicker: React.FC<PropsT> = React.memo((props) => {
    const {
        routePointIndex,
        reserve,
        onRemove,
        isShowSuggestLoading,
        isAllowRemove,
        onManualSelectLocation,
        onChangeRoutePointReserve,
    } = props;

    const { t } = useTranslation();

    const formik = useFormikContext<FormValuesT>();

    const syncShipmentDetailsFormMapStateContext = React.useContext(SyncShipmentDetailsFormMapStateContext);

    const locationFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.location}` as const;
    const fakeIdFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.fakeId}` as const;

    const fakeId = getFieldFormValue(formik.values, fakeIdFieldName);

    const hasViolation = reserve?.points?.[routePointIndex]?.violated;

    return (
        <>
            <FormikConnectContextField<FormValuesT, typeof locationFieldName>
                name={locationFieldName}
                label={t('common:new-order-form.fields.drive-through-point.label')}
                warning={hasViolation ? t('common:new-order-form.warnings.check-route-points') : null}
            >
                {(props, extendsProps) => (
                    <div className={cx('wrap')} data-fake-id={fakeId}>
                        <GeoSuggest
                            className={cx('geo-suggest')}
                            hasTriangle
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-form.fields.drive-through-point.placeholder')}
                            renderLeftIcon={({ hasValue, hasError, hasWarning, isDisabled }) => (
                                <WaypointNumberIcon
                                    routePointIndex={routePointIndex}
                                    hasValue={hasValue}
                                    hasError={hasError}
                                    hasWarning={hasWarning}
                                    isDisabled={isDisabled}
                                    isDriveThroughRoutePointPicker
                                />
                            )}
                            onChange={(value) => {
                                props.onChange(value);

                                onManualSelectLocation(value, routePointIndex);

                                onChangeRoutePointReserve(routePointIndex, {
                                    [RoutePointFieldsEnum.location]: value,
                                });
                            }}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            onFocus={() => {
                                props.onFocus();

                                if (syncShipmentDetailsFormMapStateContext?.setEditingRoutePointIndex) {
                                    syncShipmentDetailsFormMapStateContext.setEditingRoutePointIndex(routePointIndex);
                                }
                            }}
                            onBlur={() => {
                                props.onBlur();

                                if (syncShipmentDetailsFormMapStateContext?.setEditingRoutePointIndex) {
                                    syncShipmentDetailsFormMapStateContext.setEditingRoutePointIndex(null);
                                }
                            }}
                            isLoading={isShowSuggestLoading}
                            testSelector={extendsProps.name}
                            showClearControl={!!extendsProps.value}
                            isRedTriangle={
                                syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex === routePointIndex &&
                                syncShipmentDetailsFormMapStateContext?.hasEditingRoutePointError
                            }
                            triangleClassName={cx('triangle', {
                                'triangle--has-remove-control': isAllowRemove,
                            })}
                        />
                        {isAllowRemove && (
                            <DeleteWaypointTrigger
                                onClick={() => {
                                    onRemove(routePointIndex);
                                }}
                            />
                        )}
                    </div>
                )}
            </FormikConnectContextField>
        </>
    );
});

export default DriveThroughRoutePointPicker;
