import React, { useMemo } from 'react';
import styles from './DocumentVersion.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { StyleGuideColorsEnum } from 'common/constants';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import { DocumentVersionT } from 'common/store/documents/models';
import { DictDocumentT } from 'common/store/documents-dict/models';
import { DocumentRequiredEnum } from 'common/utils/api/models';
import DocumentStatusPill from 'common/components/status-pill/DocumentStatusPill/DocumentStatusPill';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    dictDocument: DictDocumentT | null;
    version: DocumentVersionT;
    onDownloadFile: (documentId: DocumentIdT) => void;
    canDownloadDocuments: boolean;
    onOpenUserDetails: (userId: UserIdT) => void;
};

const DocumentVersion: React.FC<PropsT> = (props) => {
    const { className, version, dictDocument, onOpenUserDetails, canDownloadDocuments, onDownloadFile } = props;

    const { t } = useTranslation();

    const details: Array<InfoTableRowT> = useMemo(
        () => [
            ...(version?.createdDate
                ? [
                      {
                          icon: null,
                          name: t('common:document.details.uploaded-date'),
                          rightNode: <DateFormatter date={version?.createdDate} />,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          isBoldRightNode: true,
                          testSelector: 'upload-date',
                      },
                      {
                          icon: null,
                          name: t('common:document.details.uploaded-by'),
                          rightNode: (
                              <UserLinkFormatter
                                  fullName={version?.createdByName}
                                  userId={version?.createdById}
                                  byBroker={version?.createdByBroker}
                                  onOpenUserDetails={onOpenUserDetails}
                              />
                          ),
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'upload-user',
                      },
                  ]
                : []),
            ...(version?.reviewedDate
                ? [
                      {
                          icon: null,
                          name: t('common:document.details.reviewed-date'),
                          rightNode: <DateFormatter date={version?.reviewedDate} />,
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'review-date',
                      },
                      {
                          icon: null,
                          name: t('common:document.details.reviewed-by'),
                          rightNode: (
                              <UserLinkFormatter
                                  fullName={version?.reviewedByName}
                                  userId={version?.reviewedById}
                                  byBroker
                                  onOpenUserDetails={onOpenUserDetails}
                              />
                          ),
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'review-user',
                      },
                  ]
                : []),
            ...(version?.revokedDate
                ? [
                      {
                          icon: null,
                          name: t('common:document.details.revoke-date'),
                          rightNode: <DateFormatter date={version?.revokedDate} />,
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'revoke-date',
                      },
                      {
                          icon: null,
                          name: t('common:document.details.revoke-by'),
                          rightNode: (
                              <UserLinkFormatter
                                  fullName={version?.revokedByName}
                                  userId={version?.revokedById}
                                  byBroker
                                  onOpenUserDetails={onOpenUserDetails}
                              />
                          ),
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'revoke-user',
                      },
                  ]
                : []),
            ...(version?.lastEditDate
                ? [
                      {
                          icon: null,
                          name: t('common:document.details.edit-date'),
                          rightNode: <DateFormatter date={version?.lastEditDate} />,
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'edit-date',
                      },
                      {
                          icon: null,
                          name: t('common:document.details.edit-by'),
                          rightNode: (
                              <UserLinkFormatter
                                  fullName={version?.lastEditByName}
                                  userId={version?.lastEditById}
                                  byBroker={version?.lastEditByBroker}
                                  onOpenUserDetails={onOpenUserDetails}
                              />
                          ),
                          isBoldRightNode: true,
                          emptyValue: t('common:info-table.placeholders.empty'),
                          testSelector: 'edit-user',
                      },
                  ]
                : []),
        ],
        [version],
    );

    return (
        <div className={cs(cx('version'), className)}>
            <div className={cx('header')}>
                <div className={cx('header__title')}>{t('common:document.version.title')}</div>
                <DocumentStatusPill
                    className={cx('status')}
                    isSymmetrical
                    status={version.status}
                    isRequiredDocument={dictDocument?.required === DocumentRequiredEnum.must}
                />
                {canDownloadDocuments && (
                    <Tooltip
                        position={TooltipPositionEnum.centerLeft}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={
                            <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={80}>
                                {t('common:document.actions.download')}
                            </TooltipContent>
                        }
                    >
                        {(isShow) => (
                            <TransparentTrigger
                                spaces="xs"
                                isPressed={isShow}
                                onClick={() => onDownloadFile(version.id)}
                                leftIcon={<PdfFileIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                                reflectionTheme={ReflectionThemeEnum.light}
                            />
                        )}
                    </Tooltip>
                )}
            </div>
            <InfoTable className={cx('table')} rows={details} testSelector="version-details" />
        </div>
    );
};

export default DocumentVersion;
