import {
    FETCH_SETTINGS,
    FETCH_SETTINGS_BEGIN,
    FETCH_SETTINGS_ERROR,
    FETCH_SETTINGS_SUCCESS,
    FetchSettingsActionT,
    FetchSettingsBeginActionT,
    FetchSettingsErrorActionT,
    FetchSettingsSuccessActionT,
} from './types';
import { SettingsT } from './models';

export const fetchSettings = (options?: FetchOptionsT): FetchSettingsActionT => ({
    type: FETCH_SETTINGS,
    options,
});

export const fetchSettingsBegin = (): FetchSettingsBeginActionT => ({
    type: FETCH_SETTINGS_BEGIN,
});

export const fetchSettingsSuccess = (settings: SettingsT): FetchSettingsSuccessActionT => ({
    type: FETCH_SETTINGS_SUCCESS,
    settings,
});

export const fetchSettingsError = (error: Error): FetchSettingsErrorActionT => ({
    type: FETCH_SETTINGS_ERROR,
    error,
});
