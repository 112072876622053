import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AvatarCell.scss';
import Avatar, { AvatarSizeEnum } from 'common/components/Avatar/Avatar';

type PropsT = {
    fullName: string;
};

const cx = classNames.bind(styles);

const AvatarCell: React.FC<PropsT> = React.memo((props) => {
    const { fullName } = props;

    return <Avatar size={AvatarSizeEnum.medium} hash={fullName || ''} className={cx('avatar')} />;
});

export default AvatarCell;
