import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import { DeactivateUserFormPropsT } from 'common/layouts/Members/dialogs/DeactivateUserModal/models';

const REQUIRED_FIELDS = [FieldsEnum.successorUserId] as const;

const validate = (t: TFunction, values: FormValuesT, contact: DeactivateUserFormPropsT['contact']): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    const successorUserId = values[FieldsEnum.successorUserId];
    if (successorUserId && contact?.userId === successorUserId) {
        errors[FieldsEnum.successorUserId] = t('team-members.dialogs.deactivate-user.errors.same-user');
    }

    return errors;
};

export default validate;
