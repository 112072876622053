import React from 'react';

import cs from 'classnames';

import classNames from 'classnames/bind';

import styles from './OpenedDatePicker.scss';

import Calendar, {
    CalendarDateRangeValuePropsT,
    CalendarDateValuePropsT,
} from 'design-system/components/Calendar/Calendar';
import DateInputTrigger, {
    InputDateValuePropsT,
    InputRangeValuePropsT,
} from 'design-system/components/date-pickers/internals/DateInputTrigger/DateInputTrigger';
import CalendarIcon, { CalendarIconProps } from 'common/icons/CalendarIcon';
import useTestSelector from 'common/utils/hooks/useTestSelector';

const cx = classNames.bind(styles);

type BasePropsT = {
    name?: string;
    placeholder?: string;
    isDisabled?: boolean;
    minDate?: string | null;
    maxDate?: string | null;
    hasError?: boolean;
    hasWarning?: boolean;
    hasYearMonthForm?: boolean;
    autoComplete?: string;
    testSelectorPrefix?: string;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    inputClassName?: string;
    disabledDates?: string[];
    hasClearControl?: boolean;
};

export type OpenedDatePickerPropsT =
    | (BasePropsT & CalendarDateValuePropsT)
    | (BasePropsT & CalendarDateRangeValuePropsT);

/* eslint-disable react/destructuring-assignment */
const OpenedDatePicker: React.FC<OpenedDatePickerPropsT> = (props) => {
    const {
        name,
        placeholder,
        onFocus,
        onBlur,
        isDisabled,
        minDate,
        maxDate,
        hasError,
        hasWarning,
        hasYearMonthForm,
        hasChanges,
        autoComplete,
        testSelectorPrefix,
        inputClassName,
        disabledDates,
        hasClearControl,
    } = props;

    const handleCalendarRangeChange: CalendarDateRangeValuePropsT['onChange'] = (value, selectedDate) => {
        if (props.isRange) {
            props.onChange(value, selectedDate);
        }
    };

    const handleCalendarDateChange: CalendarDateValuePropsT['onChange'] = (value, selectedDate) => {
        if (!props.isRange) {
            props.onChange(value, selectedDate);
        }
    };

    const testSelector = useTestSelector(testSelectorPrefix, 'opened-date-picker');

    let calendarValueProps: CalendarDateValuePropsT | CalendarDateRangeValuePropsT;
    if (props.isRange) {
        calendarValueProps = {
            isRange: true,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarRangeChange,
            focusInitialDate: props.value?.from,
        };
    } else {
        calendarValueProps = {
            isRange: false,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarDateChange,
            focusInitialDate: props.value,
        };
    }

    let inputDateValueProps: InputDateValuePropsT | InputRangeValuePropsT;
    if (props.isRange) {
        inputDateValueProps = {
            isRange: true,
            value: props.value,
            onChange: handleCalendarRangeChange,
        };
    } else {
        inputDateValueProps = {
            isRange: false,
            value: props.value,
            onChange: handleCalendarDateChange,
        };
    }

    return (
        <div className={cx('wrap')} data-test-selector={testSelector}>
            <Calendar
                className={cx('calendar')}
                minDate={minDate}
                maxDate={maxDate}
                hasYearMonthForm={hasYearMonthForm}
                disabledDates={disabledDates}
                {...calendarValueProps}
            />
            <DateInputTrigger
                {...inputDateValueProps}
                className={cs(cx('input'), inputClassName)}
                name={name || 'datepicker'}
                placeholder={placeholder}
                hasError={hasError}
                onFocus={onFocus}
                onBlur={onBlur}
                minDate={minDate}
                maxDate={maxDate}
                hasWarning={hasWarning}
                isDisabled={isDisabled}
                hasChanges={hasChanges}
                autoComplete={autoComplete}
                hasClearControl={hasClearControl}
                renderLeftIcon={(iconMeta) => <CalendarIcon {...CalendarIconProps.getControlProps(iconMeta)} />}
            />
        </div>
    );
};

export default OpenedDatePicker;
