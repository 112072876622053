import min from 'lodash/fp/min';
import max from 'lodash/fp/max';
import isNil from 'lodash/isNil';
import { isNonNil } from 'common/utils/index';
import isNumber from 'lodash/isNumber';

export type MinBoundingBoxT = [number, number];

export const createGeoPoint = (
    latitude: number | null | undefined,
    longitude: number | null | undefined,
): GeoPointT | null => {
    if (isNumber(latitude) && isNumber(longitude)) {
        return {
            lat: latitude,
            lng: longitude,
        };
    }

    return null;
};

const getBoundingBox = (
    points: Array<Partial<GeoPointT> | null>,
    minSize: MinBoundingBoxT = [0.02, 0.02],
): BoundingBoxT | null => {
    const lats = points.filter(isNonNil).map((point) => point.lat);
    const lngs = points.filter(isNonNil).map((point) => point.lng);

    let lat0 = min(lats);
    let lng0 = min(lngs);
    let lat1 = max(lats);
    let lng1 = max(lngs);

    if (isNil(lat0) || isNil(lng0) || isNil(lat1) || isNil(lng1)) {
        return null;
    }

    const dlat = lat1 - lat0;
    const dlng = lng1 - lng0;

    if (dlat < minSize[0]) {
        const latAdj = (minSize[0] - dlat) / 2;
        lat0 -= latAdj;
        lat1 += latAdj;
    }

    if (dlng < minSize[1]) {
        const lngAdj = (minSize[1] - dlng) / 2;
        lng0 -= lngAdj;
        lng1 += lngAdj;
    }
    return [
        [lat0, lng0],
        [lat1, lng1],
    ];
};

export default getBoundingBox;
