import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_ADDITIONAL_SERVICES } from './types';
import { fetchAdditionalServicesBegin, fetchAdditionalServicesError, fetchAdditionalServicesSuccess } from './actions';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { selectAdditionalServicesDictStatus } from 'common/store/additional-services-dict/selectors';

function* fetchAdditionalServicesSaga(): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectAdditionalServicesDictStatus> = yield select(
        selectAdditionalServicesDictStatus,
    );
    if (requestStatus.ok) {
        return;
    }

    yield put(fetchAdditionalServicesBegin());

    const [error, apiAdditionalServices]: ReturnApiT<typeof commonTranziitApi.fetchAdditionalServices> =
        yield commonTranziitApi.fetchAdditionalServices();

    if (error) {
        yield put(fetchAdditionalServicesError(error));
        return;
    }

    yield put(fetchAdditionalServicesSuccess(apiAdditionalServices || {}));
}

function* additionalServicesDictSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_ADDITIONAL_SERVICES, fetchAdditionalServicesSaga);
}

export default additionalServicesDictSaga;
