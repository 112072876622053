import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';

const asyncValidate = (requestStatus: RequestStatusT): AsyncFormErrorsT => {
    if (!checkIsAuthApiError(requestStatus.error)) {
        return {};
    }

    if (
        requestStatus.error?.type === AuthErrorTypeEnum.expiredActionCode ||
        requestStatus.error?.type === AuthErrorTypeEnum.invalidActionCode ||
        requestStatus.error?.type === AuthErrorTypeEnum.userNotFound ||
        requestStatus.error?.type === AuthErrorTypeEnum.userDisabled ||
        requestStatus.error?.type === AuthErrorTypeEnum.weakPassword ||
        requestStatus.error?.type === AuthErrorTypeEnum.failUpdatePassword ||
        requestStatus.error?.type === AuthErrorTypeEnum.failReauthenticateWithCredential
    ) {
        return {
            [FieldsEnum.password]: requestStatus.error.type,
        };
    }

    return {};
};

export default asyncValidate;
