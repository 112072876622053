import {
    CreateReserveQueryT,
    PriceOfferT,
    ReservePreviewQueryT,
    ReservePreviewT,
    ReserveQueryChangesT,
    ReserveT,
    RFQT,
} from './models';
import { RequestStatusT } from 'common/utils/request-status';

import { ApiActualOrderRequestT, ApiCreateOrderRequestT } from 'common/utils/api/models';

export const CHANGE_RESERVE_QUERY = 'common/order-creation/CHANGE_RESERVE_QUERY';
export const SET_ALLOW_CREATE_RFQ = 'common/order-creation/SET_ALLOW_CREATE_RFQ';

export const CREATE_PRICE_OFFER_WITH_LANE_REQUEST = 'common/order-creation/CREATE_PRICE_OFFER_WITH_LANE_REQUEST';
export const CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN =
    'common/order-creation/CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN';
export const CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS =
    'common/order-creation/CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS';
export const CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR =
    'common/order-creation/CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR';

export const CREATE_RFQ_REQUEST = 'common/order-creation/CREATE_RFQ_REQUEST';
export const CREATE_RFQ_REQUEST_BEGIN = 'common/order-creation/CREATE_RFQ_REQUEST_BEGIN';
export const CREATE_RFQ_REQUEST_SUCCESS = 'common/order-creation/CREATE_RFQ_REQUEST_SUCCESS';
export const CREATE_RFQ_REQUEST_ERROR = 'common/order-creation/CREATE_RFQ_REQUEST_ERROR';

export const ENSURE_CREATED_RFQ = 'common/order-creation/ENSURE_CREATED_RFQ';

export const CREATE_ORDER_REQUEST = 'common/order-creation/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_REQUEST_BEGIN = 'common/order-creation/CREATE_ORDER_REQUEST_BEGIN';
export const CREATE_ORDER_REQUEST_SUCCESS = 'common/order-creation/CREATE_ORDER_REQUEST_SUCCESS';
export const CREATE_ORDER_REQUEST_ERROR = 'common/order-creation/CREATE_ORDER_REQUEST_ERROR';

export const FETCH_ROUTE = 'common/order-creation/FETCH_ROUTE';
export const FETCH_ROUTE_ERROR = 'common/order-creation/FETCH_ROUTE_ERROR';
export const FETCH_ROUTE_SUCCESS = 'common/order-creation/FETCH_ROUTE_SUCCESS';

export const FETCH_OFFERS_ERROR = 'common/order-creation/FETCH_OFFERS_ERROR';
export const SELECT_OFFER = 'common/order-creation/SELECT_OFFER';
export const FETCH_TASK_OFFER = 'common/order-creation/FETCH_TASK_OFFER';
export const FETCH_TASK_OFFER_ERROR = 'common/order-creation/FETCH_TASK_OFFER_ERROR';
export const FETCH_OFFERS = 'common/order-creation/FETCH_OFFERS';
export const MARK_OFFERS_EXPIRED = 'common/order-creation/MARK_OFFERS_EXPIRED';
export const SET_EXPECTED_OFFERS_COUNT = 'common/order-creation/SET_EXPECTED_OFFERS_COUNT';
export const CLEAR_OFFERS_TASKS = 'common/order-creation/CLEAR_OFFERS_TASKS';
export const RESET = 'common/order-creation/RESET';

export const SET_CREATE_RESERVE_QUERY = 'common/order-creation/SET_CREATE_RESERVE_QUERY';
export const CREATE_RESERVE_BEGIN = 'common/order-creation/CREATE_RESERVE_BEGIN';
export const CREATE_RESERVE_SUCCESS = 'common/order-creation/CREATE_RESERVE_SUCCESS';
export const CREATE_RESERVE_ERROR = 'common/order-creation/CREATE_RESERVE_ERROR';
export const RESET_CREATE_RESERVE_WITH_QUERY = 'common/order-creation/RESET_CREATE_RESERVE_WITH_QUERY';

export const CORRECT_PREVIEW_RESERVE_QUERY = 'common/order-creation/CORRECT_PREVIEW_RESERVE_QUERY';
export const SET_PREVIEW_RESERVE_QUERY = 'common/order-creation/SET_PREVIEW_RESERVE_QUERY';
export const PREVIEW_RESERVE_BEGIN = 'common/order-creation/PREVIEW_RESERVE_BEGIN';
export const PREVIEW_RESERVE_SUCCESS = 'common/order-creation/PREVIEW_RESERVE_SUCCESS';
export const PREVIEW_RESERVE_ERROR = 'common/order-creation/PREVIEW_RESERVE_ERROR';

export const RESET_RESERVE_AND_RESERVE_PREVIEW = 'common/order-creation/RESET_RESERVE_AND_RESERVE_PREVIEW';

export type ChangeReserveQueryActionT = {
    type: typeof CHANGE_RESERVE_QUERY;
    changes: ReserveQueryChangesT;
};

export type SetAllowCreateRFQT = {
    type: typeof SET_ALLOW_CREATE_RFQ;
    isAllowCreateRFQ: boolean;
};

export type CreatePriceOfferWithLaneRequestActionT = {
    type: typeof CREATE_PRICE_OFFER_WITH_LANE_REQUEST;
    actualOrderRequest: ApiActualOrderRequestT;
    reserve: ReserveT;
    shipperId: ShipperIdT | null;
    laneId: ShipperContractLaneIdT;
};

export type CreatePriceOfferWithLaneRequestBeginActionT = {
    type: typeof CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN;
};

export type CreatePriceOfferWithLaneRequestSuccessActionT = {
    type: typeof CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS;
    rfq: RFQT;
    lanePriceOffer: PriceOfferT | null;
};

export type CreatePriceOfferWithLaneRequestErrorActionT = {
    type: typeof CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR;
    error: Error;
};

export type CreateRFQActionT = {
    type: typeof CREATE_RFQ_REQUEST;
    actualOrderRequest: ApiActualOrderRequestT;
    reserve: ReserveT;
    shipperId: ShipperIdT | null;
};

export type CreateRFQBeginActionT = {
    type: typeof CREATE_RFQ_REQUEST_BEGIN;
};

export type CreateRFQSuccessActionT = {
    type: typeof CREATE_RFQ_REQUEST_SUCCESS;
    rfq: RFQT;
};

export type CreateRFQErrorActionT = {
    type: typeof CREATE_RFQ_REQUEST_ERROR;
    error: Error;
};

export type FetchRouteActionT = {
    type: typeof FETCH_ROUTE;
    polylineId: PolylineIdT | null;
};

export type FetchRouteSuccessActionT = {
    type: typeof FETCH_ROUTE_SUCCESS;
    polylines: Array<GooglePolylineT>;
};

export type FetchRouteErrorActionT = {
    type: typeof FETCH_ROUTE_ERROR;
    error: Error;
};

export type CreateOrderActionT = {
    type: typeof CREATE_ORDER_REQUEST;
    rfqId: RFQIdT;
    createOrderRequest: ApiCreateOrderRequestT;
    isCreateNextOrder: boolean;
};

export type CreateOrderBeginActionT = {
    type: typeof CREATE_ORDER_REQUEST_BEGIN;
};

export type CreateOrderSuccessActionT = {
    type: typeof CREATE_ORDER_REQUEST_SUCCESS;
};

export type CreateOrderErrorActionT = {
    type: typeof CREATE_ORDER_REQUEST_ERROR;
    error: Error;
};

export type EnsureCreatedRFQActionT = {
    type: typeof ENSURE_CREATED_RFQ;
    rfqId: RFQIdT;
};

export type SelectOfferActionT = {
    type: typeof SELECT_OFFER;
    offer: PriceOfferT;
    rfqId: RFQIdT;
};

export type FetchTaskOfferActionT = {
    type: typeof FETCH_TASK_OFFER;
    offer: PriceOfferT;
    tasksCount: number;
};

export type FetchTaskOfferErrorActionT = {
    type: typeof FETCH_TASK_OFFER_ERROR;
    error: Error;
};

export type FetchOffersActionT = {
    type: typeof FETCH_OFFERS;
    priceOffers: Array<PriceOfferT>;
};

export type MarkOffersExpiredActionT = {
    type: typeof MARK_OFFERS_EXPIRED;
    ids: Array<PriceOfferIdT>;
};

export type SetExpectedOffersCountT = {
    type: typeof SET_EXPECTED_OFFERS_COUNT;
    expectedOffersCount: number | null;
};

export type ClearOffersTasksActionT = {
    type: typeof CLEAR_OFFERS_TASKS;
};

export type FetchOffersErrorActionT = {
    type: typeof FETCH_OFFERS_ERROR;
    error: Error;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type SetCreateReserveQueryActionT = {
    type: typeof SET_CREATE_RESERVE_QUERY;
    query: CreateReserveQueryT | null;
};

export type ResetCreateReserveWithQueryActionT = {
    type: typeof RESET_CREATE_RESERVE_WITH_QUERY;
    query: CreateReserveQueryT | null;
};

export type CreateReserveBeginActionT = {
    type: typeof CREATE_RESERVE_BEGIN;
    query: CreateReserveQueryT | null;
};

export type CreateReserveSuccessActionT = {
    type: typeof CREATE_RESERVE_SUCCESS;
    reserve: ReserveT;
    query: CreateReserveQueryT | null;
};

export type CreateReserveErrorActionT = {
    type: typeof CREATE_RESERVE_ERROR;
    error: Error;
    query: CreateReserveQueryT | null;
};

export type SetPreviewReserveQueryActionT = {
    type: typeof SET_PREVIEW_RESERVE_QUERY;
    query: ReservePreviewQueryT | null;
};

export type CorrectPreviewReserveQueryActionT = {
    type: typeof CORRECT_PREVIEW_RESERVE_QUERY;
    query: ReservePreviewQueryT | null;
};

export type PreviewReserveBeginActionT = {
    type: typeof PREVIEW_RESERVE_BEGIN;
    query: ReservePreviewQueryT | null;
};

export type PreviewReserveSuccessActionT = {
    type: typeof PREVIEW_RESERVE_SUCCESS;
    preview: ReservePreviewT;
    query: ReservePreviewQueryT | null;
};

export type PreviewReserveErrorActionT = {
    type: typeof PREVIEW_RESERVE_ERROR;
    error: Error;
    query: ReservePreviewQueryT | null;
};

export type ResetReserveAndReservePreviewT = {
    type: typeof RESET_RESERVE_AND_RESERVE_PREVIEW;
};

export type OrderCreationActionT =
    | SetAllowCreateRFQT
    | CreateRFQActionT
    | EnsureCreatedRFQActionT
    | CreatePriceOfferWithLaneRequestActionT
    | CreatePriceOfferWithLaneRequestBeginActionT
    | CreatePriceOfferWithLaneRequestSuccessActionT
    | CreatePriceOfferWithLaneRequestErrorActionT
    | CreateRFQBeginActionT
    | CreateRFQSuccessActionT
    | CreateRFQErrorActionT
    | CreateOrderActionT
    | CreateOrderBeginActionT
    | CreateOrderSuccessActionT
    | CreateOrderErrorActionT
    | SelectOfferActionT
    | SetExpectedOffersCountT
    | FetchOffersActionT
    | MarkOffersExpiredActionT
    | FetchTaskOfferActionT
    | FetchTaskOfferErrorActionT
    | ClearOffersTasksActionT
    | FetchOffersErrorActionT
    | FetchRouteErrorActionT
    | FetchRouteSuccessActionT
    | SetCreateReserveQueryActionT
    | ResetCreateReserveWithQueryActionT
    | CreateReserveBeginActionT
    | CreateReserveSuccessActionT
    | CreateReserveErrorActionT
    | SetPreviewReserveQueryActionT
    | CorrectPreviewReserveQueryActionT
    | PreviewReserveBeginActionT
    | PreviewReserveSuccessActionT
    | PreviewReserveErrorActionT
    | ResetReserveAndReservePreviewT
    | ResetActionT;

export type OrderCreationStateT = {
    previewReserveQuery: ReservePreviewQueryT | null;
    previewReserveRequest: RequestStatusT;
    previewReserve: ReservePreviewT | null;

    reserveQuery: CreateReserveQueryT | null;
    reserveRequest: RequestStatusT;
    reserve: ReserveT | null;

    isAllowCreateRFQ: boolean;

    polylines: Array<GooglePolylineT> | null;
    createRFQRequest: RequestStatusT;
    createPriceOfferWithLaneRequest: RequestStatusT;

    rfq: RFQT | null;
    priceOffers: {
        byHash: Record<string, Array<PriceOfferIdT>>;
        byId: Record<PriceOfferIdT, PriceOfferT>;
        list: Array<PriceOfferIdT>;
    } | null;
    offersTasks: {
        count: number;
        total: number | null;
    };

    lanePriceOffer: PriceOfferT | null;

    createOrderRequest: RequestStatusT;
};
