import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AlertToastsManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUnknownAlertToast, renderCommonAlertToast } from './utils';
import Toast from 'common/components/toasts/Toast/Toast';
import { selectAlertsById, selectNewAlertsIds } from 'common/store/alerts/selectors';
import Alert, { AlertSizeEnum } from 'common/components/Alert/Alert';
import { AlertToastRendererT } from 'common/components/toasts/AlertToastsManager/models';
import { deleteAlert } from 'common/store/alerts/actions';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    renderAlertToast: AlertToastRendererT;
};

const AlertToastsManager: React.FC<PropsT> = React.memo((props) => {
    const { renderAlertToast, className } = props;

    const dispatch = useDispatch();

    const newAlertIds = useSelector(selectNewAlertsIds);
    const alertsById = useSelector(selectAlertsById);

    const { t } = useTranslation();

    return (
        <div
            className={className}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
            }}
        >
            {newAlertIds.map((alertId, alertIndex): React.ReactNode => {
                const alert = alertsById[alertId];
                if (!alert) {
                    return null;
                }

                const alertToastView =
                    renderCommonAlertToast(t, alert) || renderAlertToast(t, alert) || getUnknownAlertToast(t, alert);

                if (!alertToastView) {
                    return null;
                }

                const key = alert.id || alertIndex;

                return (
                    <Toast
                        key={key}
                        onClose={() => {
                            dispatch(deleteAlert(alert.id));
                        }}
                    >
                        {(onClose) => (
                            <Alert
                                key={key}
                                size={AlertSizeEnum.small}
                                icon={alertToastView.icon}
                                theme={alertToastView.theme}
                                className={cx('alert')}
                                onClose={onClose}
                                isAlignItemsCenter
                            >
                                {alertToastView.text}
                            </Alert>
                        )}
                    </Toast>
                );
            })}
        </div>
    );
});

export default AlertToastsManager;
