export const getAssetsTrackHashByOrderId = (orderId?: OrderIdT): string => {
    return `${orderId || ''}`;
};

export const getAssetTrackHashByOrderId = (orderId?: OrderIdT | null, assetId?: AssetIdT | null): string => {
    return `${orderId || ''}_${assetId || ''}`;
};

export const getAssetsTrackHashByTransportOrderId = (transportOrderId?: TransportOrderIdT): string => {
    return `${transportOrderId || ''}`;
};

export const getAssetTrackHashByTransportOrderId = (
    transportOrderId?: TransportOrderIdT | null,
    assetId?: AssetIdT,
): string => {
    return `${transportOrderId || ''}_${assetId || ''}`;
};
