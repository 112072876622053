import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_USER_REQUEST_SUCCESS } from 'common/store/user/types';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { FETCH_SETTINGS, FetchSettingsActionT } from './types';
import { fetchSettingsBegin, fetchSettingsError, fetchSettingsSuccess } from './actions';
import { selectSettingsStatus } from './selectors';
import { SettingsT } from './models';
import checkNeedRequest from 'common/utils/check-need-request';

function* fetchSettingsSaga(action: FetchSettingsActionT): WrapGeneratorT<void> {
    const { options } = action;

    const requestStatus: ReturnType<typeof selectSettingsStatus> = yield select(selectSettingsStatus);
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchSettingsBegin());

    const [error, apiSettings]: ReturnApiT<typeof commonTranziitApi.fetchSettings> =
        yield commonTranziitApi.fetchSettings();

    if (error) {
        yield put(fetchSettingsError(error));
        return;
    }

    const settings = (apiSettings || []).reduce<SettingsT>((acc, apiSetting) => {
        acc[apiSetting.name] = apiSetting;

        return acc;
    }, {});

    yield put(fetchSettingsSuccess(settings));
}

function* settingsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_USER_REQUEST_SUCCESS, FETCH_SETTINGS], fetchSettingsSaga);
}

export default settingsSaga;
