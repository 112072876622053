import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { FetchShipperContractsPageQueryT, ShipperContractT } from 'common/store/shipper-contracts/models';

export type PartnerShipperContractStateT = PaginationStateT<ShipperContractT, FetchShipperContractsPageQueryT>;

export const initialPartnerShipperContractsState: PartnerShipperContractStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
};

export type ShipperContractsStateT = Record<PartnerIdT, PartnerShipperContractStateT>;

const initialState: ShipperContractsStateT = {};

export const shipperContractsSlice = createSlice({
    name: 'shipperContracts',
    initialState,
    reducers: {
        fetchShipperContractsPage: (
            state,
            action: PayloadAction<{
                pageNumber: PageNumberT;
                query: FetchShipperContractsPageQueryT;
                partnerId: PartnerIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchShipperContractsPageBegin: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractsPageQueryT;
                pageNumber: PageNumberT;
                partnerId: PartnerIdT;
            }>,
        ) => {
            const { partnerId, pageNumber, query } = action.payload;
            const partnerState = state[partnerId] || initialPartnerShipperContractsState;

            state[partnerId] = {
                ...partnerState,
                ...pageBeginReducer(partnerState, pageNumber, query),
            };
        },
        fetchShipperContractsPageSuccess: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractsPageQueryT;
                pageNumber: PageNumberT;
                response: PageResponseT<ShipperContractT> | null;
                partnerId: PartnerIdT;
            }>,
        ) => {
            const { partnerId, pageNumber, query, response } = action.payload;
            const partnerState = state[partnerId] || initialPartnerShipperContractsState;

            state[partnerId] = {
                ...partnerState,
                ...pageSuccessReducer(partnerState, pageNumber, query, response),
            };
        },
        fetchShipperContractsPageError: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractsPageQueryT;
                pageNumber: PageNumberT;
                error: Error;
                partnerId: PartnerIdT;
            }>,
        ) => {
            const { partnerId, pageNumber, query, error } = action.payload;
            const partnerState = state[partnerId] || initialPartnerShipperContractsState;

            state[partnerId] = {
                ...partnerState,
                ...pageErrorReducer(partnerState, pageNumber, query, error),
            };
        },
        resetShipperContracts: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                mutationListOptions: MutationListOptionsT;
            }>,
        ) => {
            const { partnerId, mutationListOptions } = action.payload;
            const partnerState = state[partnerId] || initialPartnerShipperContractsState;

            state[partnerId] = {
                ...partnerState,
                ...resetPagesCacheReducer(initialPartnerShipperContractsState, partnerState, mutationListOptions),
            };
        },
    },
});

export const {
    fetchShipperContractsPage,
    fetchShipperContractsPageBegin,
    fetchShipperContractsPageSuccess,
    fetchShipperContractsPageError,
    resetShipperContracts,
} = shipperContractsSlice.actions;

export default shipperContractsSlice.reducer;
