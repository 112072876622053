const checkIsHostname = (hosts: Array<string>): boolean =>
    hosts.some((hostname) => {
        // eslint-disable-next-line no-restricted-globals
        return location.hostname === hostname;
    });

export const checkIsProduction = checkIsHostname([
    'shipper.tranziit.com',
    'carrier.tranziit.com',
    'office.tranziit.com',
]);

export const checkIsStaging = checkIsHostname([
    'shipper.test.tranziit.com',
    'carrier.test.tranziit.com',
    'office.test.tranziit.com',
]);
