import React, { useState } from 'react';
import { checkActiveFeature, ServerFeatureEnum } from 'common/utils/features/server-features-config';
import {
    clearClientFeaturesConfig,
    ClientFeatureEnum,
    ClientFeaturesConfigT,
    getClientFeaturesConfig,
    saveClientFeaturesConfig,
} from 'common/utils/features/client-features-config';

export { ServerFeatureEnum, ClientFeatureEnum };

export type FeaturesContextValueT = {
    checkActiveServerFeature?: typeof checkActiveFeature;
    checkActiveClientFeature?: (feature: ClientFeatureEnum) => boolean;
    getClientFeatureValue?: <T extends ClientFeatureEnum>(feature: T) => ClientFeaturesConfigT[T] | undefined;
    setClientFeature?: <T extends ClientFeatureEnum>(feature: T, value: ClientFeaturesConfigT[T]) => void;
    clearClientFeatures?: () => void;
};

const useFeaturesContextValue = (): FeaturesContextValueT => {
    const [clientFeatures, setClientFeatures] = useState<ClientFeaturesConfigT>(getClientFeaturesConfig());

    return React.useMemo((): FeaturesContextValueT => {
        return {
            checkActiveServerFeature: checkActiveFeature,
            checkActiveClientFeature: (feature) => {
                return !!clientFeatures[feature];
            },
            getClientFeatureValue: (feature) => {
                return clientFeatures[feature];
            },
            setClientFeature: (feature, value) => {
                saveClientFeaturesConfig({
                    ...clientFeatures,
                    [feature]: value,
                });

                const newClientFeature = getClientFeaturesConfig();
                setClientFeatures(newClientFeature);
            },
            clearClientFeatures: () => {
                clearClientFeaturesConfig();

                const newClientFeature = getClientFeaturesConfig();
                setClientFeatures(newClientFeature);
            },
        };
    }, [clientFeatures, checkActiveFeature]);
};

export { useFeaturesContextValue };
