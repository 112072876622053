import { TrucksDictT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

import { ApiTrucksDictT } from 'common/utils/api/models';

export const TRUCKS_DICT_REQUEST = 'common/trucks-dict/TRUCKS_DICT_REQUEST';
export const TRUCKS_DICT_REQUEST_BEGIN = 'common/trucks-dict/TRUCKS_DICT_REQUEST_BEGIN';
export const TRUCKS_DICT_REQUEST_SUCCESS = 'common/trucks-dict/TRUCKS_DICT_REQUEST_SUCCESS';
export const TRUCKS_DICT_REQUEST_ERROR = 'common/trucks-dict/TRUCKS_DICT_REQUEST_ERROR';

export type FetchTrucksDictActionT = {
    type: typeof TRUCKS_DICT_REQUEST;
};

export type FetchTrucksDictBeginActionT = {
    type: typeof TRUCKS_DICT_REQUEST_BEGIN;
};

export type FetchTrucksDictSuccessActionT = {
    type: typeof TRUCKS_DICT_REQUEST_SUCCESS;
    trucksDict: ApiTrucksDictT;
};

export type FetchTrucksDictErrorActionT = {
    type: typeof TRUCKS_DICT_REQUEST_ERROR;
    error: Error;
};

export type TrucksDictActionT =
    | FetchTrucksDictActionT
    | FetchTrucksDictBeginActionT
    | FetchTrucksDictSuccessActionT
    | FetchTrucksDictErrorActionT;

export type TrucksDictStateT = {
    trucksDict: TrucksDictT | null;
    trucksDictRequest: RequestStatusT;
};
