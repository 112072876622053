import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, RateSourceEnum } from './constants';
import { checkNotEmpty, checkIsPositiveNumberValue } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.source] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    if (values[FieldsEnum.source] === RateSourceEnum.new) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.rate], values),
            ...checkIsPositiveNumberValue(t, [FieldsEnum.rate], values),
        };
    }

    return errors;
};

export default validate;
