import React from 'react';

import classNames from 'classnames/bind';
import styles from './DnDIconWrap.scss';
import DragIcon from 'common/icons/DragIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

export type PropsT = {
    values: number[];
};

const PERMISSIBLE_ERROR = 1.5;

const DnDIconWrap: React.FC<PropsT> = React.memo((props) => {
    const { values } = props;

    const [isShowIcon, setIsShowIcon] = React.useState<boolean>(true);

    const wrapRef = React.useRef<HTMLDivElement>(null);
    const iconRef = React.useRef<HTMLDivElement>(null);

    const checkShouldShowIcon = () => {
        if (!iconRef.current || !wrapRef.current) {
            return;
        }

        const iconNodeRect = iconRef.current.getBoundingClientRect();
        const wrapNodeRect = wrapRef.current.getBoundingClientRect();

        const isShouldShow = wrapNodeRect.width > iconNodeRect.width * PERMISSIBLE_ERROR;
        if (isShowIcon !== isShouldShow) {
            setIsShowIcon(isShouldShow);
        }
    };
    React.useEffect((): void => {
        // wait rerender
        setTimeout(() => {
            checkShouldShowIcon();
        }, 0);
    }, [iconRef.current, wrapRef.current, values]);

    return (
        <div className={cx('dnd-wrap')} ref={wrapRef}>
            <div
                className={cx('icon', {
                    'icon--is-hide': !isShowIcon,
                })}
                ref={iconRef}
            >
                <DragIcon fillColor={StyleGuideColorsEnum.white} />
            </div>
        </div>
    );
});

export default DnDIconWrap;
