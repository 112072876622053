import React from 'react';
import classNames from 'classnames/bind';
import styles from './ScrollableContent.scss';
import ContentLimiter from 'common/layouts/LeftMenuLayout/ContentLimiter/ContentLimiter';

const cx = classNames.bind(styles);

type PropsT = {
    children: React.ReactNode;

    scrollContainerRef?: React.RefObject<HTMLDivElement>;
    onContainerScroll?: (event: React.UIEvent<HTMLElement>) => void;

    stickyHeaderNode?: React.ReactNode;
};

const ScrollableContent: React.FC<PropsT> = React.memo((props) => {
    const { children, stickyHeaderNode, scrollContainerRef, onContainerScroll } = props;

    return (
        <div className={cx('wrapper')}>
            {stickyHeaderNode}
            <div
                className={cx('scroll', {
                    'scroll--hasStickyHeader': !!stickyHeaderNode,
                })}
                ref={scrollContainerRef}
                onScroll={onContainerScroll}
            >
                <ContentLimiter className={cx('content')}>{children}</ContentLimiter>
            </div>
        </div>
    );
});

export default ScrollableContent;
