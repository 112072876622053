import {
    FETCH_ORDER_REQUEST_BEGIN,
    FETCH_ORDER_REQUEST_ERROR,
    FETCH_ORDER_REQUEST_SUCCESS,
    OrderDetailsActionT,
    OrderDetailsByIdStateT,
    OrderDetailsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import isEqual from 'lodash/isEqual';

const initialState: OrderDetailsByIdStateT = {};

export const initialOrderState: OrderDetailsStateT = {
    order: null,
    orderRequestStatus: requestStatus.initial,
};

export default (state = initialState, action: OrderDetailsActionT | DestroySessionActionT): OrderDetailsByIdStateT => {
    switch (action.type) {
        case FETCH_ORDER_REQUEST_BEGIN: {
            const { id } = action;

            const orderState = state[id] || initialOrderState;

            return {
                ...state,
                [id]: {
                    ...orderState,
                    orderRequestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_ORDER_REQUEST_ERROR: {
            const { error, id } = action;

            const orderState = state[id] || initialOrderState;

            return {
                ...state,
                [id]: {
                    ...orderState,
                    orderRequestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_ORDER_REQUEST_SUCCESS: {
            const { order, id } = action;

            const orderState = state[id] || initialOrderState;

            return {
                ...state,
                [id]: {
                    ...orderState,
                    orderRequestStatus: requestStatus.ok,
                    order: isEqual(order, orderState.order) ? orderState.order : order,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
