import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AlertThemeEnum } from 'common/components/Alert/Alert';

export type SideBarFooterAlertT = {
    id: SidebarFooterAlertIdT;
    theme: AlertThemeEnum;
    content: React.ReactNode;
};

export type CloseSideBarFooterAlertCallbackT = () => void;

export type SideBarFooterAlertsContextValueT = {
    alerts: Array<SideBarFooterAlertT>;
    onCloseAlert: (id: SidebarFooterAlertIdT) => void;
    showAlert: (data: Pick<SideBarFooterAlertT, 'theme' | 'content'>) => CloseSideBarFooterAlertCallbackT;
};

const useSideBarFooterAlertsContextValue = (): SideBarFooterAlertsContextValueT => {
    const [alerts, setAlerts] = React.useState<Array<SideBarFooterAlertT>>([]);

    const onCloseAlert = useCallback(
        (id: SidebarFooterAlertIdT) => {
            setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
        },
        [setAlerts],
    );

    const showAlert = useCallback(
        ({ theme, content }: Pick<SideBarFooterAlertT, 'theme' | 'content'>): CloseSideBarFooterAlertCallbackT => {
            const alertId = uuidv4();

            setAlerts((alerts) => [
                {
                    id: alertId,
                    theme,
                    content,
                },
                ...alerts,
            ]);

            return () => {
                setAlerts((alerts) => alerts.filter((alert) => alert.id !== alertId));
            };
        },
        [setAlerts],
    );

    return {
        alerts,
        onCloseAlert,
        showAlert,
    };
};

export default useSideBarFooterAlertsContextValue;
