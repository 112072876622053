import { TFunction } from 'i18next';

import { FieldsEnum, FormValuesT, FormErrorsT } from './constants';
import { checkNotEmpty, checkIsPositiveNumberValue } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.truckModel,
    FieldsEnum.cabinColor,
    FieldsEnum.countryCode,
    FieldsEnum.plateNumber,
    FieldsEnum.vin,
] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    let errors = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    if (values[FieldsEnum.contract] === null) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.ratePerKm], values),
            ...checkIsPositiveNumberValue(t, [FieldsEnum.ratePerKm], values),
        };
    }

    return errors;
};

export default validate;
