import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { AssetTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import BigTrashIcon from 'common/icons/BigTrashIcon';

export type ArchiveAssetConfirmationDataT = {
    companyId: CompanyIdT;
    assets: Array<{
        plateNumber: string;
        id: AssetIdT;
    }>;
};

type PropsT = {
    assetType: AssetTypeEnum;
    data: ArchiveAssetConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: ArchiveAssetConfirmationDataT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'archive-assets';

const T_MAP: Record<AssetTypeEnum, { title: string; oneAssetMessage: string; manyAssetsMessage: string }> = {
    [AssetTypeEnum.truck]: {
        title: 'common:trucks-page.archive-confirmation.title',
        oneAssetMessage: 'common:trucks-page.archive-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trucks-page.archive-confirmation.many-assets-message',
    },
    [AssetTypeEnum.trailer]: {
        title: 'common:trailers-page.archive-confirmation.title',
        oneAssetMessage: 'common:trailers-page.archive-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trailers-page.archive-confirmation.many-assets-message',
    },
};

const ArchiveAssetConfirmationModal: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, requestStatus, onConfirm, assetType } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const { assets } = data;

    const tSet = T_MAP[assetType];

    let message = null;
    if (assets.length === 1) {
        message = t(tSet.oneAssetMessage, {
            plateNumber: assets?.[0]?.plateNumber || '',
        });
    } else {
        message = t(tSet.manyAssetsMessage, {
            count: assets.length,
        });
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.archive'),
                            theme: ButtonThemeEnum.danger,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t(tSet.title)}
                    message={message}
                />
            </ModalContent>
        </Modal>
    );
});

export default ArchiveAssetConfirmationModal;
