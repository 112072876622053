import { SignUpParamsT } from 'common/store/auth/models';
import { FieldsEnum as UserFieldsEnum, FormValuesT as UserFormDataT } from './UserSignUpForm/constants';
import { FieldsEnum as CompanyFieldsEnum, FormValuesT as CompanyFormDataT } from './CompanySignUpForm/constants';
import { simpleStringFormatter } from 'common/utils/form-formatters';

export const prepareSignUpParams = (
    userFormData: UserFormDataT | null,
    companyFormData: CompanyFormDataT | null,
): SignUpParamsT | null => {
    if (!userFormData || !companyFormData) {
        return null;
    }

    return {
        firstName: simpleStringFormatter(userFormData[UserFieldsEnum.firstName]),
        lastName: simpleStringFormatter(userFormData[UserFieldsEnum.lastName]),
        email: simpleStringFormatter(userFormData[UserFieldsEnum.email]),
        password: simpleStringFormatter(userFormData[UserFieldsEnum.password]),
        phone: userFormData[UserFieldsEnum.phone],
        legalFormId: companyFormData[CompanyFieldsEnum.legalFormId],

        companyName: simpleStringFormatter(companyFormData[CompanyFieldsEnum.companyName]),
        city: simpleStringFormatter(companyFormData[CompanyFieldsEnum.city]),
        countryCode: companyFormData[CompanyFieldsEnum.countryCode] || '',
        street1: simpleStringFormatter(companyFormData[CompanyFieldsEnum.street1]),
        zipCode: simpleStringFormatter(companyFormData[CompanyFieldsEnum.zipCode]),
    };
};
