import React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './EntriesTabs.scss';
import map from 'lodash/map';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { UseEntriesTabsResultT } from 'common/components/tabs/EntriesTabs/hooks';
import { OnKeyPressCallbackT, useKeyboardShortcut } from 'common/utils/hooks/useKeyboardShortcut';

const cx = classNames.bind(styles);

export enum EntryCountThemeEnum {
    normal = 'normal',
    warning = 'warning',
}

const notificationThemeMap: Record<EntryCountThemeEnum, NotificationLabelThemeEnum> = {
    [EntryCountThemeEnum.normal]: NotificationLabelThemeEnum.slate,
    [EntryCountThemeEnum.warning]: NotificationLabelThemeEnum.orange,
};

export type EntryTabT<T extends string> = {
    type: T;
    label: React.ReactNode;
    counter?: {
        value?: number | null | undefined;
        theme?: EntryCountThemeEnum;
        isShowPlusSign?: boolean;
        isShowWarning?: boolean;
    };
};

export type PropsT<T extends string> = {
    className?: string;
    tabs: Array<EntryTabT<T> | null>;
    testSelector?: React.ReactNode;
} & UseEntriesTabsResultT<T>;

const EntriesTabs = <T extends string>(props: PropsT<T>) => {
    const { activeEntriesTabType, setActiveEntriesTabType, tabs, testSelector, className } = props;

    const handleKeyPress: OnKeyPressCallbackT = React.useCallback(
        (key, isPressedMetaKey) => {
            const numberKey = parseInt(key, 10);
            const selectedTabType = tabs[numberKey - 1]?.type || null;
            if (!Number.isNaN(numberKey) && isPressedMetaKey && selectedTabType) {
                setActiveEntriesTabType(selectedTabType);
            }
        },
        [tabs, setActiveEntriesTabType],
    );
    useKeyboardShortcut(handleKeyPress);

    return (
        <div className={cs(cx('tabs'), className)} data-test-selector={testSelector}>
            {map(tabs, (tab, tabIndex) => {
                if (!tab) {
                    return null;
                }

                const isActiveTab = tab.type === activeEntriesTabType;

                return (
                    <Button
                        key={tabIndex}
                        isToggled={isActiveTab}
                        className={cx('tabs__tab', {
                            'tabs__tab--is-active': isActiveTab,
                        })}
                        onClick={() => {
                            setActiveEntriesTabType(tab.type);
                        }}
                        leftIcon={
                            tab.counter ? (
                                <NotificationLabel
                                    theme={notificationThemeMap[tab.counter.theme as EntryCountThemeEnum]}
                                    count={tab.counter.value}
                                    isShowPlusSign={tab.counter.isShowPlusSign}
                                    isShowWarning={tab.counter.isShowWarning}
                                />
                            ) : null
                        }
                        theme={ButtonThemeEnum.transparent}
                    >
                        {tab.label}
                    </Button>
                );
            })}
        </div>
    );
};

export default EntriesTabs;
