import * as React from 'react';
import SideBarLayoutContext from '../contexts/side-bar-layout-context';
import useScrollBarWidth from 'common/utils/hooks/useScrollBarWidth';
import useSideBarFooterHeight from 'common/utils/hooks/useSideBarFooterHeight';
import useSideBarHeaderHeight from 'common/utils/hooks/useSideBarHeaderHeight';
import useScrollTop from 'common/utils/hooks/useScrollTop';

type PropsT = {};

const SideBarLayoutContextWrap: React.FC<PropsT> = React.memo((props) => {
    const scrollBarWidth = useScrollBarWidth();
    const sideBarFooter = useSideBarFooterHeight();
    const sideBarHeader = useSideBarHeaderHeight();
    const scrollTop = useScrollTop();

    const value = React.useMemo(() => {
        return {
            scrollBarWidth,
            footerHeight: sideBarFooter,
            headerHeight: sideBarHeader,
            scrollTop,
        };
    }, [scrollBarWidth, sideBarFooter, sideBarHeader, scrollTop]);

    return <SideBarLayoutContext.Provider value={value}>{props.children}</SideBarLayoutContext.Provider>;
});

export default SideBarLayoutContextWrap;
