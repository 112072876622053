import React from 'react';

import classNames from 'classnames/bind';
import styles from './DispatcherDropdown.scss';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import UserDropdownOptionLabel from 'common/components/dropdowns/options/UserDropdownOptionLabel/UserDropdownOptionLabel';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { fetchDispatchers } from 'broker-admin/store/dispatchers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDispatchersAllIds,
    selectDispatchersById,
    selectFetchDispatchersRequest,
} from 'broker-admin/store/dispatchers/selectors';
import { ManagerT } from 'broker-admin/store/managers/models';
import isNil from 'lodash/isNil';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import { prepareSearchQuery } from 'common/utils/search';

const cx = classNames.bind(styles);

type ValueT = UserIdT | null;

type PropsT = {
    selectedValue: ValueT;
    initialLabel: string | null;
    placeholder: string;
    isDisabled?: boolean;
    onSelect: (value: ValueT, label: string) => void;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    onReset?: () => void;
    excludeUserIds?: Array<UserIdT>;
};

type OptionT = ValueT;

const getLabel = (user?: ManagerT) => {
    if (!user) {
        return '';
    }
    return `${user.name} ${user.surname}`;
};

const DispatcherDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        selectedValue,
        initialLabel,
        placeholder,
        isDisabled,
        onSelect,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        hasChanges,
        onBlur,
        onFocus,
        onReset,
        excludeUserIds,
    } = props;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDispatchers());
    }, []);

    const fetchRequest = useSelector(selectFetchDispatchersRequest);
    const allIds = useSelector(selectDispatchersAllIds);
    const byId = useSelector(selectDispatchersById);

    const renderOption = (id?: ValueT, placeholder?: string): React.ReactNode => {
        if (isNil(id)) {
            return <UserDropdownOptionLabel label={placeholder || ''} isPlaceholder />;
        }

        const user = byId[id];
        if (!user) {
            if (!initialLabel) {
                return null;
            }

            return <UserDropdownOptionLabel label={initialLabel} />;
        }

        return <UserDropdownOptionLabel label={getLabel(user)} />;
    };

    const getOptionValue = (option: OptionT): ValueT => option;

    const handleSelect = (value: ValueT) => {
        const user = byId[value as string];
        const label = getLabel(user);

        onSelect(value, label);
    };

    const [query, setQuery] = React.useState<string>('');

    const filteredOptions = React.useMemo(() => {
        const preparedQuery = prepareSearchQuery(query);

        const excludeUserIdsSet = new Set<UserIdT>(excludeUserIds || []);

        return allIds.filter((id: UserIdT) => {
            if (excludeUserIdsSet.has(id)) {
                return false;
            }

            const user = byId[id];

            const label = getLabel(user);
            const preparedLabel = prepareSearchQuery(label);

            return preparedLabel.includes(preparedQuery);
        });
    }, [allIds, query, excludeUserIds]);

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={selectedValue}
            placeholder={placeholder}
            options={filteredOptions}
            isDisabled={fetchRequest.loading || isDisabled}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={setQuery}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={onReset}
        />
    );
});

export default DispatcherDropdown;
