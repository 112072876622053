import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './NotificationsBarTrigger.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import BellIcon from 'common/icons/BellIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import SideBar, { SideBarPositionEnum } from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import { selectUnReadNotificationsCount } from 'common/store/notifications/selectors';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { useTranslation } from 'react-i18next';
import NotificationsBarContent from 'common/components/notifications/NotificationsBarContent/NotificationsBarContent';

import { markAsRead } from 'common/store/notifications/actions';
import useNotificationSideBarContext from 'common/utils/hooks/useNotificationSideBarContext';

const cx = classNames.bind(styles);

type PropsT = {};

const NotificationsBarTrigger: React.FC<PropsT> = React.memo((props) => {
    const { renderNotification, getNotificationLink } = useNotificationSideBarContext();

    const [isOpen, setOpen] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const unReadNotificationsCount = useSelector(selectUnReadNotificationsCount);

    const options: Array<DropdownControlOptionT | null> = [
        unReadNotificationsCount
            ? {
                  label: <DropdownControlOptionLabel label={t('common:notification-side-bar.mark-all-as-read')} />,
                  testSelector: 'mark-all-as-read',
                  isDisabled: !unReadNotificationsCount,
                  onSelect: () => {
                      dispatch(markAsRead([], true));
                  },
              }
            : null,
    ];

    const hasUnReadNotifications = !!unReadNotificationsCount;

    return (
        <>
            <Button
                isNarrow
                theme={ButtonThemeEnum.transparent}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <div className={cx('inner')}>
                    {hasUnReadNotifications && (
                        <NotificationLabel
                            className={cx('count')}
                            count={unReadNotificationsCount}
                            theme={NotificationLabelThemeEnum.orange}
                        />
                    )}
                    <BellIcon
                        strokeColor={
                            hasUnReadNotifications ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.gray
                        }
                        fillColor={
                            hasUnReadNotifications ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.light
                        }
                    />
                </div>
            </Button>
            <SideBar
                isShow={isOpen}
                isNarrow
                position={SideBarPositionEnum.right}
                onClose={() => {
                    setOpen(false);
                }}
            >
                {(close) =>
                    isOpen ? (
                        <>
                            <HeaderSideBarLayout>
                                <HeaderSideBarContent
                                    title={
                                        <div className={cx('header')}>
                                            <div>{t('common:notification-side-bar.title')}</div>
                                            {hasUnReadNotifications && (
                                                <NotificationLabel
                                                    className={cx('header-count')}
                                                    count={unReadNotificationsCount}
                                                    theme={NotificationLabelThemeEnum.orange}
                                                />
                                            )}
                                        </div>
                                    }
                                    onClose={close}
                                    rightNode={
                                        <DropdownControl
                                            overlayClassName={cx('overlay')}
                                            options={options}
                                            renderTrigger={(isActive, onClick) => (
                                                <TransparentTrigger
                                                    isPressed={isActive}
                                                    onClick={onClick}
                                                    leftIcon={<MoreIcon />}
                                                    reflectionTheme={ReflectionThemeEnum.light}
                                                    testSelector="notification-actions-trigger"
                                                />
                                            )}
                                            testSelector="notification-actions"
                                            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                        />
                                    }
                                />
                            </HeaderSideBarLayout>
                            <SideBarLayout withoutScrollbar isWide>
                                <NotificationsBarContent
                                    renderNotification={renderNotification}
                                    getNotificationLink={getNotificationLink}
                                />
                            </SideBarLayout>
                        </>
                    ) : null
                }
            </SideBar>
        </>
    );
});

export default NotificationsBarTrigger;
