import React from 'react';
import { concatTestSelectors } from 'common/utils/test-selectors';

const useTestSelector = (...testSelectors: Array<string | null | undefined>): string => {
    return React.useMemo(() => {
        return concatTestSelectors(testSelectors);
    }, testSelectors);
};

export default useTestSelector;
