import * as React from 'react';
import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

type PropsT<RowT> = {
    tableName: string;
    rows: Array<RowT>;
    isLoading: boolean;
    children: (rows: Array<RowT>, isUsedPrevRows: boolean) => React.ReactElement | null;
};

const MemoizedTable = <RowT extends {}>(props: PropsT<RowT>) => {
    const { tableName, rows, isLoading, children } = props;

    const [prevRows, setPrevRows] = useState<RowT[]>(rows);
    React.useEffect(() => {
        if (!isLoading) {
            setPrevRows(rows);
        }
    }, [isLoading]);

    React.useEffect(() => {
        setPrevRows([]);
    }, [tableName]);

    const isUsedPrevRows = isLoading && isEmpty(rows) && !isEmpty(prevRows);
    const tableRows = isUsedPrevRows ? prevRows : rows;

    return children(tableRows, isUsedPrevRows);
};

export default React.memo(MemoizedTable) as typeof MemoizedTable;
