import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './BaseShipperContractDetailsLayout.scss';
import { PartnerTypeEnum, ShipperContractStatusEnum } from 'common/utils/api/models';
import Widget from 'common/components/Widget/Widget';
import { useTranslation } from 'react-i18next';
import ShipperContractStatusPill from 'common/components/status-pill/ShipperContractStatusPill/ShipperContractStatusPill';
import RemoteFormActions from 'common/components/forms/RemoteFormActions/RemoteFormActions';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DeleteIcon from 'common/icons/DeleteIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import EditIcon from 'common/icons/EditIcon';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { RevokeShipperContractConfirmationDataT } from './dialogs/RevokeShipperContractConfirmation/models';
import RevokeShipperContractConfirmation from './dialogs/RevokeShipperContractConfirmation/RevokeShipperContractConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectShipperContractDetailsState,
    selectUpdateShipperContractRequest,
} from 'common/store/shipper-contract-details/selectors';
import {
    downloadShipperContract,
    revokeShipperContract,
    updateShipperContract,
} from 'common/store/shipper-contract-details/slice';
import { UpdateShipperContractChangesT } from 'common/store/shipper-contract-details/models';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { checkIsApiBrokerShipperContractDetails } from 'common/store/shipper-contract-details/type-guards';
import EditShipperContractDetailsForm from './EditShipperContractDetailsForm/EditShipperContractDetailsForm';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import ShipperContractDetails from './ShipperContractDetails/ShipperContractDetails';
import { DownloadDataT } from './EditShipperContractDetailsForm/models';
import cs from 'classnames';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    partnerType: PartnerTypeEnum;
    contractId: ShipperContractIdT;
    isAllowEditing: boolean;
    className?: string;
};

const HISTORY_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

const BaseShipperContractDetailsLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType, contractId, isAllowEditing, className } = props;

    const dispatch = useDispatch();

    const openLeftSidebar = useOpenLeftSidebar();

    const contractDetailsState = useSelector(selectShipperContractDetailsState(contractId));
    const updateRequestStatus = useSelector(selectUpdateShipperContractRequest);
    const contractDetails = contractDetailsState.data;
    const fetchRequestStatus = contractDetailsState.fetchRequest;

    const isShowLoaderOverlay = !(fetchRequestStatus.ok || fetchRequestStatus.error) || updateRequestStatus.loading;

    const { t } = useTranslation();

    const revokeShipperContractConfirmation = useModalDialog<RevokeShipperContractConfirmationDataT>();
    useCloseModalDialogAfterRequest(updateRequestStatus, [revokeShipperContractConfirmation]);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    useEffect(() => {
        if (updateRequestStatus.ok) {
            setIsEditing(false);
        }
    }, [updateRequestStatus.ok]);

    const options = useMemo((): Array<DropdownControlOptionT | SpecialOptionEnum | null> => {
        if (!isAllowEditing) {
            return [];
        }

        return [
            contractDetails?.status === ShipperContractStatusEnum.active
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              icon={<EditIcon />}
                              label={t('common:shipper-contract-details.actions.edit')}
                          />
                      ),
                      onSelect: () => {
                          setIsEditing(true);
                      },
                  }
                : null,
            contractDetails?.status === ShipperContractStatusEnum.active
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                              label={t('common:shipper-contract-details.actions.revoke')}
                          />
                      ),
                      onSelect: () => {
                          revokeShipperContractConfirmation.setData({
                              partnerId,
                              shipperContractId: contractId,
                              shipperContractName: contractDetails?.name || '',
                          });
                      },
                  }
                : null,
        ];
    }, [partnerId, contractId, contractDetails, isAllowEditing]);

    const handleDownloadShipperContract = (data: DownloadDataT) => {
        dispatch(
            downloadShipperContract({
                partnerId: data.partnerId,
                shipperContractId: data.shipperContractId,
                fileName: contractDetails?.fileName || null,
            }),
        );
    };

    const handleUpdateShipperContract = ({
        partnerId,
        shipperContractId,
        detailsChanges,
        file,
    }: {
        partnerId: PartnerIdT;
        shipperContractId: ShipperContractIdT;
        detailsChanges: UpdateShipperContractChangesT;
        file: File | null;
    }) => {
        dispatch(
            updateShipperContract({
                partnerId,
                shipperContractId,
                detailsChanges,
                file,
            }),
        );
    };

    const handleRevokeShipperContract = (reason: string, data: RevokeShipperContractConfirmationDataT | null) => {
        if (!data) {
            return;
        }

        dispatch(
            revokeShipperContract({
                partnerId: data.partnerId,
                shipperContractId: data.shipperContractId,
                reason,
            }),
        );
    };

    const handleOpenUserDetails = (userId: UserIdT): void => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const createDetails: Array<InfoTableRowT | null> = [
        contractDetails?.createdDate
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.date-added'),
                  value: <DateFormatter date={contractDetails?.createdDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-added',
              }
            : null,
        checkIsApiBrokerShipperContractDetails(contractDetails)
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.added-by'),
                  value: (
                      <UserLinkFormatter
                          fullName={contractDetails?.createdByName}
                          userId={contractDetails?.createdById}
                          byBroker
                          onOpenUserDetails={handleOpenUserDetails}
                      />
                  ),
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'added-by',
              }
            : null,
    ];

    const modifyDetails: Array<InfoTableRowT | null> = [
        contractDetails?.lastEditDate
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.date-modified'),
                  value: <DateFormatter date={contractDetails?.lastEditDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-modify',
              }
            : null,
        checkIsApiBrokerShipperContractDetails(contractDetails)
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.modified-by'),
                  value: (
                      <UserLinkFormatter
                          fullName={contractDetails?.lastEditByName}
                          userId={contractDetails?.lastEditById}
                          byBroker
                          onOpenUserDetails={handleOpenUserDetails}
                      />
                  ),
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'modified-by',
              }
            : null,
    ];

    const revokeDetails: Array<InfoTableRowT | null> = [
        contractDetails?.revokedDate
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.date-revoked'),
                  value: <DateFormatter date={contractDetails?.revokedDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-revoked',
              }
            : null,
        checkIsApiBrokerShipperContractDetails(contractDetails)
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.revoked-by'),
                  value: (
                      <UserLinkFormatter
                          fullName={contractDetails?.revokedByName}
                          userId={contractDetails?.revokedById}
                          byBroker
                          onOpenUserDetails={handleOpenUserDetails}
                      />
                  ),
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'revoked-by',
              }
            : null,
    ];

    const expireDetails: Array<InfoTableRowT | null> = [
        contractDetails?.validTill
            ? {
                  icon: null,
                  name: t('common:shipper-contract-details.history.date-expired'),
                  value: <DateFormatter date={contractDetails?.validTill} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-expired',
              }
            : null,
    ];

    return (
        <>
            <div className={cs(cx('widget-group'), className)}>
                <Widget
                    className={cx('widget', 'widget-group__widget')}
                    title={t('common:shipper-contract-details.widgets.basic-information')}
                    afterTitleNode={<ShipperContractStatusPill status={contractDetails?.status} isSymmetrical />}
                    rightNode={
                        <>
                            {isEditing ? (
                                <RemoteFormActions
                                    onClose={() => {
                                        setIsEditing(false);
                                    }}
                                />
                            ) : (
                                <DropdownControl
                                    options={options}
                                    renderTrigger={(isActive, onClick) => (
                                        <TransparentTrigger
                                            isPressed={isActive}
                                            onClick={onClick}
                                            leftIcon={<MoreIcon />}
                                            reflectionTheme={ReflectionThemeEnum.light}
                                        />
                                    )}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                />
                            )}
                        </>
                    }
                >
                    <div className={cx('details')}>
                        {isEditing ? (
                            <EditShipperContractDetailsForm
                                partnerId={partnerId}
                                shipperContractId={contractId}
                                shipperContractDetails={contractDetails}
                                onDownloadShipperContract={handleDownloadShipperContract}
                                onUpdateShipperContract={handleUpdateShipperContract}
                            />
                        ) : (
                            <ShipperContractDetails
                                partnerId={partnerId}
                                shipperContractId={contractId}
                                shipperContractDetails={contractDetails}
                                onDownloadShipperContract={handleDownloadShipperContract}
                            />
                        )}
                    </div>
                </Widget>
                <Widget
                    className={cx('widget', 'widget-group__widget')}
                    title={t('common:shipper-contract-details.widgets.history')}
                >
                    <div className={cx('history')}>
                        <InfoTable className={cx('history-table')} rows={createDetails} testSelector="create-details" />
                        <InfoTable className={cx('history-table')} rows={modifyDetails} testSelector="modify-details" />
                        <InfoTable className={cx('history-table')} rows={revokeDetails} testSelector="revoke-details" />
                        <InfoTable className={cx('history-table')} rows={expireDetails} testSelector="expire-details" />
                    </div>
                </Widget>
            </div>
            <RevokeShipperContractConfirmation
                data={revokeShipperContractConfirmation.data}
                requestStatus={updateRequestStatus}
                onClose={revokeShipperContractConfirmation.onClose}
                onCancel={revokeShipperContractConfirmation.onCancel}
                onConfirm={handleRevokeShipperContract}
            />
            {isShowLoaderOverlay && <LoaderOverlay />}
        </>
    );
});

export default BaseShipperContractDetailsLayout;
