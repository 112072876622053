import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_ORDERS_PAGE_REQUEST, FetchOrdersPageActionT } from './types';
import { fetchOrdersPageBegin, fetchOrdersPageError, fetchOrdersPageSuccess, resetOrders } from './actions';
import { selectOrdersPages, selectOrdersQuery } from 'common/store/orders/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { ordersPaginationChannel } from 'common/store/orders/channels';

function* fetchOrdersSaga(action: FetchOrdersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query: rawQuery, options } = action;

    const query: typeof rawQuery = {
        ...rawQuery,
    };

    const prevQuery: ReturnType<typeof selectOrdersQuery> = yield select(selectOrdersQuery);
    const pages: ReturnType<typeof selectOrdersPages> = yield select(selectOrdersPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);

    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetOrders({ savingPageNumber: pageNumber }));
    }

    yield put(fetchOrdersPageBegin(query, pageNumber));
    const [error, response]: ReturnApiT<typeof shipperTranziitApi.fetchOrdersPage> =
        yield shipperTranziitApi.fetchOrdersPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchOrdersPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchOrdersPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, ordersPaginationChannel);
}

function* ordersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_ORDERS_PAGE_REQUEST, fetchOrdersSaga);
}

export default ordersSaga;
