import * as React from 'react';
import SaveChangesNotification from 'common/components/SaveChangesNotification/SaveChangesNotification';
import { checkValuesChanges } from 'common/utils/form-helpers';

type FormValueT = object | number | string | boolean | null | undefined | Array<number> | Array<string>;
type FormValuesT = Record<string, FormValueT>;

type PropsT<T extends FormValuesT> = {
    fields: Array<keyof T>;
    defaultNotification?: React.ReactElement | null;
    className?: string;
};

const withWidgetChangesDetector =
    <T extends FormValuesT>(initialValues: T, values: T): React.FC<PropsT<T>> =>
    (props): React.ReactElement | null => {
        const { fields, defaultNotification, className } = props;

        const hasChanges = checkValuesChanges(fields, initialValues, values);
        if (hasChanges) {
            return <SaveChangesNotification className={className} hasChanges />;
        }

        if (defaultNotification) {
            return defaultNotification || null;
        }

        return null;
    };

export default withWidgetChangesDetector;
