import {
    FETCH_DISPATCHERS_REQUEST,
    FETCH_DISPATCHERS_REQUEST_BEGIN,
    FETCH_DISPATCHERS_REQUEST_ERROR,
    FETCH_DISPATCHERS_REQUEST_SUCCESS,
    FetchDispatchersActionT,
    FetchDispatchersBeginActionT,
    FetchDispatchersErrorActionT,
    FetchDispatchersSuccessActionT,
} from './types';

import { DispatcherT } from './models';

export const fetchDispatchers = (): FetchDispatchersActionT => ({
    type: FETCH_DISPATCHERS_REQUEST,
});

export const fetchDispatchersBegin = (): FetchDispatchersBeginActionT => ({
    type: FETCH_DISPATCHERS_REQUEST_BEGIN,
});

export const fetchDispatchersSuccess = (dispatchers: DispatcherT[]): FetchDispatchersSuccessActionT => ({
    type: FETCH_DISPATCHERS_REQUEST_SUCCESS,
    dispatchers,
});

export const fetchDispatchersError = (error: Error): FetchDispatchersErrorActionT => ({
    type: FETCH_DISPATCHERS_REQUEST_ERROR,
    error,
});
