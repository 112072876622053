import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './OrdersTable.scss';
import { OrderT } from 'common/store/orders/models';
import { useTranslation } from 'react-i18next';
import LocationCell from './cell-renderers/LocationCell/LocationCell';
import TrailerTypeCell from './cell-renderers/TrailerTypeCell/TrailerTypeCell';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import { OrderStatusEnum } from 'common/utils/api/models';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import IdCell from 'common/components/Table/cell-renderers/IdCell/IdCell';
import CreatedCell from 'shipper/layouts/OrdersPage/OrderListPage/OrdersTable/cell-renderers/CreatedCell/CreatedCell';
import { DirectionEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    orders: Array<OrderT>;
    className?: string;
    goToOrderDetails: (event: React.MouseEvent, orderId: string) => void;
    isLoading: boolean;
    updatedOrderIdsSet: Set<OrderIdT>;
    goToUserDetails: (userId: UserIdT) => void;
};

const OrdersTable: React.FC<PropsT> = React.memo((props) => {
    const { orders, className, goToOrderDetails, isLoading, updatedOrderIdsSet, goToUserDetails } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<OrderT, void>> = [
        {
            renderHeader: () => <span>{t('orders-table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (order: OrderT) => <IdCell id={order.number} />,
            className: cx('table__column', 'table__column--id'),
            testSelector: 'origin',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.origin')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <LocationCell order={order} type={DirectionEnum.origin} />,
            className: cx('table__column', 'table__column--origin'),
            testSelector: 'origin',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.destination')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <LocationCell order={order} type={DirectionEnum.destination} />,
            className: cx('table__column', 'table__column--destination'),
            testSelector: 'destination',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.created')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <CreatedCell order={order} onOpenUser={goToUserDetails} />,
            className: cx('table__column', 'table__column--created'),
            testSelector: 'created',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.price')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <CostCell cost={order.offerPrice || null} />,
            className: cx('table__column', 'table__column--price'),
            testSelector: 'cost',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.trailer')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <TrailerTypeCell order={order} />,
            className: cx('table__column', 'table__column--trailer'),
            testSelector: 'trailer',
        },
        {
            renderHeader: () => <span>{t('orders-table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (order: OrderT) => <StatusCell order={order} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, order: OrderT) => {
        if (order.id) {
            goToOrderDetails(event, order.id);
        }
    };

    const getRowMods = (meta: TableRowMetaT, order: OrderT) => {
        const hasUpdates = order.id ? updatedOrderIdsSet.has(order.id) : false;

        return {
            [TableRowModsEnum.hasLeftGreenBorder]: order.status === OrderStatusEnum.delivered,
            [TableRowModsEnum.isHighlighted]: hasUpdates,
            [TableRowModsEnum.hasLeftGreyBorder]: hasUpdates,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<OrderT> tableName="orders" isLoading={isLoading} rows={orders}>
                {(rows, isUsedPrevRows) => (
                    <Table<OrderT, void>
                        testSelector="orders"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default OrdersTable;
