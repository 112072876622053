import * as React from 'react';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import MoreIcon from 'common/icons/MoreIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

type PropsT = {
    className?: string;
    options: Array<DropdownControlOptionT | SpecialOptionEnum>;
};

const ControlsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, options } = props;

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default ControlsCell;
