import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './SetUnavailableFooterContent.scss';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import SetUnavailableForm from '../SetUnavailableForm/SetUnavailableForm';
import { SetUnavailableVehicleQueryT } from 'common/utils/api/models';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import { useSelector } from 'react-redux';
import { selectFetchScheduleRequest } from 'common/store/vehicle-schedules/selectors';
import { AssetTypeEnum } from 'common/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { GoogleMapsLoaderWithoutMaps } from 'common/components/maps/GoogleMapsLoaderWithoutMaps/GoogleMapsLoaderWithoutMaps';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onClose: () => void;
    isLoading: boolean;
    assetId: AssetIdT | null;
    assetType: AssetTypeEnum;
    onSetUnavailable: (assetId: AssetIdT, query: SetUnavailableVehicleQueryT) => void;
};

const SetUnavailableFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { assetId, assetType, onClose, isLoading, onSetUnavailable, className } = props;

    const requestStatus = useSelector(selectFetchScheduleRequest(assetId || ''));

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    return (
        <>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <GoogleMapsLoaderWithoutMaps />
                {requestStatus.loading && <LoaderOverlay />}
                <div className={cs(cx('content'), className)}>
                    <TransparentTrigger
                        className={cx('close-button')}
                        onClick={onClose}
                        leftIcon={<CloseIcon />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                    <SetUnavailableForm
                        isLoading={isLoading}
                        assetId={assetId}
                        assetType={assetType}
                        onSetUnavailable={onSetUnavailable}
                    />
                </div>
            </GoogleMapContext.Provider>
        </>
    );
});

export default SetUnavailableFooterContent;
