import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { StyleGuideColorsEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import CloseIcon from 'common/icons/CloseIcon';
import { CarrierContractStatusEnum } from 'common/utils/api/models';
import { PermissionsT } from 'common/store/auth/models';
import { ContractsActionsT } from '../models';
import EditIcon from 'common/icons/EditIcon';
import { logWarning } from 'common/utils/logger';
import { PartnerContractT } from 'common/store/carrier-contracts/models';

enum ContractActionsEnum {
    download = 'download',
    revoke = 'revoke',
    edit = 'edit',
}

type CallbacksT = ContractsActionsT;

export const useContractsActionOptions = (
    contract: PartnerContractT | null,
    permissions: PermissionsT,
    callbacks: CallbacksT,
): Array<DropdownControlOptionT | SpecialOptionEnum> => {
    const { onDownloadContract, onEditContractDetails, onRevokeContract } = callbacks;

    const { canDownloadCarrierContracts, canEditCarrierContracts, canRevokeCarrierContracts } = permissions;

    const { t } = useTranslation();

    const contractId = contract?.id || null;

    const optionsConfigMap: Record<
        ContractActionsEnum,
        { icon: React.ReactNode; label: React.ReactNode; onSelect: () => void }
    > = {
        [ContractActionsEnum.download]: {
            icon: <PdfFileIcon fillColor={StyleGuideColorsEnum.charcoal} />,
            label: t('common:carrier-contracts.table.actions.download'),
            onSelect: () => {
                if (!contractId) {
                    logWarning('failed to download contract, empty contractId');
                    return;
                }

                onDownloadContract(contractId);
            },
        },
        [ContractActionsEnum.revoke]: {
            icon: <CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />,
            label: t('common:carrier-contracts.table.actions.revoke'),
            onSelect: () => {
                if (!contract) {
                    logWarning('failed to revoke contract, empty contract');
                    return;
                }

                onRevokeContract({
                    id: contract.id,
                    name: contract.name,
                    truckRateKm: contract.truckRateKm,
                    tiltRateKm: contract.tiltRateKm,
                    boxRateKm: contract.boxRateKm,
                });
            },
        },
        [ContractActionsEnum.edit]: {
            icon: <EditIcon />,
            label: t('common:carrier-contracts.table.actions.edit'),
            onSelect: () => {
                if (!contractId) {
                    logWarning('failed to edit contract, empty contractId');
                    return;
                }

                onEditContractDetails(contractId);
            },
        },
    };

    const actions = React.useMemo((): Array<ContractActionsEnum | SpecialOptionEnum> => {
        const hasAnyVersion = !!contract;

        const actions: Array<ContractActionsEnum | SpecialOptionEnum> = [];

        if (hasAnyVersion && canDownloadCarrierContracts) {
            actions.push(ContractActionsEnum.download);
        }

        if (hasAnyVersion && contract?.status === CarrierContractStatusEnum.approved && canEditCarrierContracts) {
            actions.push(SpecialOptionEnum.separator);
            actions.push(ContractActionsEnum.edit);
        }

        if (hasAnyVersion && contract?.status === CarrierContractStatusEnum.approved && canRevokeCarrierContracts) {
            actions.push(SpecialOptionEnum.separator);
            actions.push(ContractActionsEnum.revoke);
        }

        return actions;
    }, [contract, canDownloadCarrierContracts, canEditCarrierContracts, canRevokeCarrierContracts]);

    const options = actions.map((action) => {
        if (action === SpecialOptionEnum.separator) {
            return SpecialOptionEnum.separator;
        }

        const optionConfig = optionsConfigMap[action];

        return {
            label: <DropdownControlOptionLabel icon={optionConfig.icon} label={optionConfig.label} />,
            onSelect: optionConfig.onSelect,
        };
    });

    return options;
};
