import { ApiUnitTypeT } from 'common/utils/api/models';
import { UnitTypeEnum } from 'common/constants';

export const getLoadUnitType = (apiUnit: ApiUnitTypeT | null | undefined): UnitTypeEnum | null => {
    switch (apiUnit) {
        case 'EURO_PALLET_1': {
            return UnitTypeEnum.eur1Pallets;
        }
        case 'EURO_PALLET_2': {
            return UnitTypeEnum.eur2Pallets;
        }
        case 'OTHER': {
            return UnitTypeEnum.other;
        }
        default: {
            return null;
        }
    }
};

export const getApiLoadUnitType = (unit: UnitTypeEnum | null | undefined): ApiUnitTypeT | null => {
    switch (unit) {
        case UnitTypeEnum.eur1Pallets: {
            return 'EURO_PALLET_1';
        }
        case UnitTypeEnum.eur2Pallets: {
            return 'EURO_PALLET_2';
        }
        case UnitTypeEnum.other: {
            return 'OTHER';
        }
        default: {
            return null;
        }
    }
};
