import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
    className?: string;
    fillColor: StyleGuideColorsEnum;
    number: number;
};

const NumberPinIcon: React.FC<PropsT> = (props) => {
    const { className, fillColor, number } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="45" viewBox="0 0 40 45" className={className}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill={fillColor}
                    d="M25 0c8.284 0 15 6.716 15 15v10c0 8.284-6.716 15-15 15l-5 5-5-5C6.716 40 0 33.284 0 25V15C0 6.716 6.716 0 15 0h10z"
                />
                <text fill="#FFF" fontFamily="Roboto-Black, Roboto" fontSize="28" fontWeight="800">
                    <tspan x="11.893" y="30">
                        {number}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

NumberPinIcon.displayName = 'NumberPinIcon';

const storyProps: PropsT = {
    number: 1,
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default NumberPinIcon;
