import * as React from 'react';
import { useRef } from 'react';
import isNumber from 'lodash/isNumber';
import { StyleGuideColorsEnum } from 'common/constants';

export enum MapAreaThemeEnum {
    assignmentFindArea = 'assignment-find-area',
    originArea = 'origin-area',
    destinationArea = 'destination-area',
}

const COLOR_MAP: Record<MapAreaThemeEnum, StyleGuideColorsEnum> = {
    [MapAreaThemeEnum.assignmentFindArea]: StyleGuideColorsEnum.brandAccent,
    [MapAreaThemeEnum.originArea]: StyleGuideColorsEnum.dodgerBlue,
    [MapAreaThemeEnum.destinationArea]: StyleGuideColorsEnum.blazeOrange,
};

type PropsT = {
    map: MapT | null | undefined;
    maps: TODO | null | undefined;
    theme: MapAreaThemeEnum;
    centerLat: number | null | undefined;
    centerLon: number | null | undefined;
    radiusM: number | null | undefined;
};

const MapArea: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, theme, radiusM, centerLat, centerLon } = props;

    const findAreaRef = useRef<google.maps.Circle | null>(null);
    React.useEffect(() => {
        if (findAreaRef.current) {
            findAreaRef.current.setMap(null);
        }

        if (!maps || !map || !isNumber(centerLat) || !isNumber(centerLon) || !isNumber(radiusM)) {
            return;
        }

        const color = COLOR_MAP[theme];

        findAreaRef.current = new maps.Circle({
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: color,
            fillOpacity: 0.05,
            map,
            center: {
                lat: centerLat,
                lng: centerLon,
            },
            radius: radiusM,
        });
        if (findAreaRef.current) {
            findAreaRef.current.setMap(map);
        }
    }, [map, maps, centerLat, centerLon, radiusM, theme]);

    React.useEffect(() => {
        return () => {
            if (findAreaRef.current) {
                findAreaRef.current.setMap(null);
            }
        };
    }, []);

    return null;
});

export default MapArea;
