import { ApiGoogleAddressComponentT } from './api/geo-api-models';
import isNil from 'lodash/isNil';

enum GoogleAddressComponentsTypeEnum {
    country = 'country',
    route = 'route',
    streetNumber = 'street_number',
    postalCode = 'postal_code',
    locality = 'locality',
    political = 'political',
    postalTown = 'postal_town',
    administrativeAreaLevel1 = 'administrative_area_level_1',
    administrativeAreaLevel2 = 'administrative_area_level_2',
    administrativeAreaLevel3 = 'administrative_area_level_3',
    administrativeAreaLevel4 = 'administrative_area_level_4',
    administrativeAreaLevel5 = 'administrative_area_level_5',
}

export const prepareAddressComponents = (
    addressComponents: google.maps.GeocoderAddressComponent[],
): AddressComponentsT => {
    const componentsMap = addressComponents.reduce<Record<string, ApiGoogleAddressComponentT>>((acc, component) => {
        component.types.forEach((type) => {
            // eslint-disable-next-line no-param-reassign
            acc[type] = component;
        });

        return acc;
    }, {});

    /* eslint-disable camelcase */
    return {
        countryCode:
            componentsMap[GoogleAddressComponentsTypeEnum.country]?.short_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.political]?.short_name ||
            null,
        street1:
            [
                componentsMap[GoogleAddressComponentsTypeEnum.route]?.long_name,
                componentsMap[GoogleAddressComponentsTypeEnum.streetNumber]?.long_name,
            ]
                .filter(Boolean)
                .join(' ') || '',
        street2: '',
        zipCode: componentsMap[GoogleAddressComponentsTypeEnum.postalCode]?.long_name || null,
        city:
            componentsMap[GoogleAddressComponentsTypeEnum.locality]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.postalTown]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.administrativeAreaLevel1]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.administrativeAreaLevel2]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.administrativeAreaLevel3]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.administrativeAreaLevel4]?.long_name ||
            componentsMap[GoogleAddressComponentsTypeEnum.administrativeAreaLevel5]?.long_name ||
            null,
    };
    /* eslint-enable camelcase */
};

export const formatAddressComponents = (
    addressComponents: AddressComponentsT | null | undefined,
    separator = ' ',
): string => {
    if (!addressComponents) {
        return '';
    }

    return [
        addressComponents.street1,
        addressComponents.street2,
        addressComponents.zipCode,
        addressComponents.city,
        addressComponents.countryCode,
    ]
        .filter(Boolean)
        .join(separator);
};

export const getGeoCodingLocation = (result: google.maps.places.PlaceResult): LocationT | null => {
    if (isNil(result.utc_offset_minutes)) {
        return null;
    }

    if (!result.geometry) {
        return null;
    }

    if (!result.address_components) {
        return null;
    }

    return {
        point: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
        },
        address: result.formatted_address || null,
        utcOffsetMinutes: result.utc_offset_minutes,
        addressComponents: prepareAddressComponents(result.address_components),
    };
};
