import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    className?: string;
};

const SnowflakeIcon: React.FC<PropsT> = (props) => {
    const { fillColor, ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    fill={fillColor}
                    d="M18.333 9.167h-3.475l2.7-2.7-1.175-1.184L12.5 9.167h-1.667V7.5l3.884-3.883-1.184-1.175-2.7 2.7V1.667H9.167v3.475l-2.7-2.7-1.184 1.175L9.167 7.5v1.667H7.5L3.617 5.283 2.442 6.467l2.7 2.7H1.667v1.666h3.475l-2.7 2.7 1.175 1.184L7.5 10.833h1.667V12.5l-3.884 3.883 1.184 1.175 2.7-2.7v3.475h1.666v-3.475l2.7 2.7 1.184-1.175-3.884-3.883v-1.667H12.5l3.883 3.884 1.175-1.184-2.7-2.7h3.475z"
                />
            </g>
        </svg>
    );
};

SnowflakeIcon.displayName = 'SnowflakeIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default SnowflakeIcon;
