import isEmpty from 'lodash/isEmpty';
import { isNonNil } from 'common/utils';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';

export const checkQueryFiltersKeys = <T extends Record<string, any>>(
    sourceQueryFilters: T,
    primaryQueryKeys: Array<keyof T>,
    secondaryQueryKeys: Array<keyof T>,
) => {
    const clearedQueryFilters = pickBy(sourceQueryFilters, isNonNil);
    const hasAnyFilters = isEmpty(clearedQueryFilters);

    const primaryQueryFilters = pick(clearedQueryFilters, primaryQueryKeys || []);
    const hasPrimaryQueryFilters = !isEmpty(primaryQueryFilters);

    const secondaryQueryFilters = pick(clearedQueryFilters, secondaryQueryKeys || []);
    const hasSecondaryQueryFilters = !isEmpty(secondaryQueryFilters);

    return {
        hasAnyFilters,
        hasPrimaryQueryFilters,
        hasSecondaryQueryFilters,
    };
};
