import {
    APPROVE_DOCUMENT_REQUEST,
    APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST,
    ApproveDocumentActionT,
    ApproveEvidenceOfTaxDocumentActionT,
    DOWNLOAD_DOCUMENT_REQUEST,
    DOWNLOAD_DOCUMENT_REQUEST_BEGIN,
    DOWNLOAD_DOCUMENT_REQUEST_ERROR,
    DOWNLOAD_DOCUMENT_REQUEST_SUCCESS,
    DownloadDocumentActionT,
    DownloadDocumentBeginActionT,
    DownloadDocumentErrorActionT,
    DownloadDocumentSuccessActionT,
    FETCH_DOCUMENTS_REQUEST,
    FETCH_DOCUMENTS_REQUEST_BEGIN,
    FETCH_DOCUMENTS_REQUEST_ERROR,
    FETCH_DOCUMENTS_REQUEST_SUCCESS,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    FetchDocumentsActionT,
    FetchDocumentsBeginActionT,
    FetchDocumentsErrorActionT,
    FetchDocumentsSuccessActionT,
    FetchEvidenceOfTaxDetailsActionT,
    FetchEvidenceOfTaxDetailsBeginActionT,
    FetchEvidenceOfTaxDetailsErrorActionT,
    FetchEvidenceOfTaxDetailsSuccessActionT,
    REJECT_DOCUMENT_REQUEST,
    RejectDocumentActionT,
    RESET,
    ResetDocumentActionT,
    REVOKE_DOCUMENT_REQUEST,
    RevokeDocumentActionT,
    UPDATE_DOCUMENT_REQUEST,
    UPDATE_DOCUMENT_REQUEST_BEGIN,
    UPDATE_DOCUMENT_REQUEST_ERROR,
    UPDATE_DOCUMENT_REQUEST_SUCCESS,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    UpdateDocumentActionT,
    UpdateDocumentBeginActionT,
    UpdateDocumentErrorActionT,
    UpdateDocumentSuccessActionT,
    UpdateEvidenceOfTaxDetailsActionT,
    UpdateEvidenceOfTaxDetailsBeginActionT,
    UpdateEvidenceOfTaxDetailsErrorActionT,
    UpdateEvidenceOfTaxDetailsSuccessActionT,
    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_REQUEST_BEGIN,
    UPLOAD_DOCUMENT_REQUEST_ERROR,
    UPLOAD_DOCUMENT_REQUEST_SUCCESS,
    UploadDocumentActionT,
    UploadDocumentBeginActionT,
    UploadDocumentErrorActionT,
    UploadDocumentSuccessActionT,
} from './types';
import {
    DocumentDetailsChangesT,
    DocumentIdsByTypeT,
    DocumentT,
    EvidenceOfTaxDetailsChangesT,
    EvidenceOfTaxDetailsT,
} from './models';
import { PartnerTypeEnum } from 'common/utils/api/models';

export const fetchDocuments = (companyId: CompanyIdT, options?: FetchOptionsT): FetchDocumentsActionT => ({
    type: FETCH_DOCUMENTS_REQUEST,
    companyId,
    options,
});

export const fetchDocumentsBegin = (companyId: CompanyIdT): FetchDocumentsBeginActionT => ({
    type: FETCH_DOCUMENTS_REQUEST_BEGIN,
    companyId,
});

export const fetchDocumentsSuccess = (
    documentIdsByType: DocumentIdsByTypeT,
    documentsList: DocumentT[],
    companyId: CompanyIdT,
): FetchDocumentsSuccessActionT => ({
    type: FETCH_DOCUMENTS_REQUEST_SUCCESS,
    documentIdsByType,
    documentsList,
    companyId,
});

export const fetchDocumentsError = (error: Error, companyId: CompanyIdT): FetchDocumentsErrorActionT => ({
    type: FETCH_DOCUMENTS_REQUEST_ERROR,
    error,
    companyId,
});

export const uploadDocument = (
    dictDocumentId: DictDocumentIdT,
    file: File,
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
): UploadDocumentActionT => ({
    type: UPLOAD_DOCUMENT_REQUEST,
    dictDocumentId,
    file,
    partnerType,
    companyId,
});

export const uploadDocumentBegin = (
    companyId: CompanyIdT,
    dictDocumentId: DictDocumentIdT,
): UploadDocumentBeginActionT => ({
    type: UPLOAD_DOCUMENT_REQUEST_BEGIN,
    companyId,
    dictDocumentId,
});

export const uploadDocumentSuccess = (companyId: CompanyIdT): UploadDocumentSuccessActionT => ({
    type: UPLOAD_DOCUMENT_REQUEST_SUCCESS,
    companyId,
});

export const uploadDocumentError = (error: Error, companyId: CompanyIdT): UploadDocumentErrorActionT => ({
    type: UPLOAD_DOCUMENT_REQUEST_ERROR,
    error,
    companyId,
});

export const downloadDocument = (documentId: DocumentIdT, companyId: CompanyIdT): DownloadDocumentActionT => ({
    type: DOWNLOAD_DOCUMENT_REQUEST,
    documentId,
    companyId,
});

export const downloadDocumentBegin = (companyId: CompanyIdT): DownloadDocumentBeginActionT => ({
    type: DOWNLOAD_DOCUMENT_REQUEST_BEGIN,
    companyId,
});

export const downloadDocumentSuccess = (companyId: CompanyIdT): DownloadDocumentSuccessActionT => ({
    type: DOWNLOAD_DOCUMENT_REQUEST_SUCCESS,
    companyId,
});

export const downloadDocumentError = (error: Error, companyId: CompanyIdT): DownloadDocumentErrorActionT => ({
    type: DOWNLOAD_DOCUMENT_REQUEST_ERROR,
    error,
    companyId,
});

export const approveDocument = (
    documentId: DocumentIdT,
    validTill: string | null,
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
): ApproveDocumentActionT => ({
    type: APPROVE_DOCUMENT_REQUEST,
    documentId,
    companyId,
    validTill,
    partnerType,
});

export const approveEvidenceOfTaxDocument = (
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
    documentId: DocumentIdT,
    validTill: string | null,
    detailsChanges: EvidenceOfTaxDetailsChangesT,
): ApproveEvidenceOfTaxDocumentActionT => ({
    type: APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST,
    documentId,
    companyId,
    validTill,
    partnerType,
    detailsChanges,
});

export const rejectDocument = (
    documentId: DocumentIdT,
    reason: string,
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
): RejectDocumentActionT => ({
    type: REJECT_DOCUMENT_REQUEST,
    documentId,
    reason,
    companyId,
    partnerType,
});

export const revokeDocument = (
    documentId: DocumentIdT,
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
): RevokeDocumentActionT => ({
    type: REVOKE_DOCUMENT_REQUEST,
    documentId,
    companyId,
    partnerType,
});

export const updateEvidenceOfTaxDetails = (
    companyId: CompanyIdT,
    documentId: DocumentIdT,
    detailsChanges: EvidenceOfTaxDetailsChangesT,
): UpdateEvidenceOfTaxDetailsActionT => ({
    type: UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    companyId,
    documentId,
    detailsChanges,
});

export const updateEvidenceOfTaxDetailsBegin = (companyId: CompanyIdT): UpdateEvidenceOfTaxDetailsBeginActionT => ({
    type: UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const updateEvidenceOfTaxDetailsSuccess = (companyId: CompanyIdT): UpdateEvidenceOfTaxDetailsSuccessActionT => ({
    type: UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    companyId,
});

export const updateEvidenceOfTaxDetailsError = (
    error: Error,
    companyId: CompanyIdT,
): UpdateEvidenceOfTaxDetailsErrorActionT => ({
    type: UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

export const updateDocument = (
    documentId: DocumentIdT,
    companyId: CompanyIdT,
    detailChanges: DocumentDetailsChangesT,
): UpdateDocumentActionT => ({
    type: UPDATE_DOCUMENT_REQUEST,
    documentId,
    companyId,
    detailChanges,
});

export const updateDocumentBegin = (companyId: CompanyIdT, documentId: DocumentIdT): UpdateDocumentBeginActionT => ({
    type: UPDATE_DOCUMENT_REQUEST_BEGIN,
    companyId,
    documentId,
});

export const updateDocumentSuccess = (companyId: CompanyIdT): UpdateDocumentSuccessActionT => ({
    type: UPDATE_DOCUMENT_REQUEST_SUCCESS,
    companyId,
});

export const updateDocumentError = (error: Error, companyId: CompanyIdT): UpdateDocumentErrorActionT => ({
    type: UPDATE_DOCUMENT_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchEvidenceOfTaxDetails = (companyId: CompanyIdT): FetchEvidenceOfTaxDetailsActionT => ({
    type: FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    companyId,
});

export const fetchEvidenceOfTaxDetailsBegin = (companyId: CompanyIdT): FetchEvidenceOfTaxDetailsBeginActionT => ({
    type: FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchEvidenceOfTaxDetailsSuccess = (
    companyId: CompanyIdT,
    evidenceOfTaxDetails: EvidenceOfTaxDetailsT,
): FetchEvidenceOfTaxDetailsSuccessActionT => ({
    type: FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS,
    companyId,
    evidenceOfTaxDetails,
});

export const fetchEvidenceOfTaxDetailsError = (
    companyId: CompanyIdT,
    error: Error,
): FetchEvidenceOfTaxDetailsErrorActionT => ({
    type: FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

export const resetDocument = (companyId: CompanyIdT): ResetDocumentActionT => ({
    type: RESET,
    companyId,
});
