import * as React from 'react';
import { RFQT } from 'common/store/rfqs/models';
import RFQStatusPill from 'common/components/status-pill/RFQStatusPill/RFQStatusPill';

type PropsT = {
    rfq: RFQT;
};

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { rfq } = props;

    return <RFQStatusPill isSymmetrical status={rfq?.status} />;
});

export default StatusCell;
