import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import { getFirstNeedSuccessorRole } from 'common/store/members/utils';
import { CommonChangeUserRoleFormPropsT } from 'common/layouts/Members/dialogs/ChangeUserRoleModal/models';

const REQUIRED_FIELDS = [FieldsEnum.role, FieldsEnum.successorUserId] as const;

const validate = (
    t: TFunction,
    values: FormValuesT,
    contact: CommonChangeUserRoleFormPropsT['contact'],
): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    const successorUserId = values[FieldsEnum.successorUserId];
    if (successorUserId && contact?.userId === successorUserId) {
        errors[FieldsEnum.successorUserId] = t('team-members.dialogs.change-user-role.errors.same-user');
    }

    const oldUserRoleData = getFirstNeedSuccessorRole(contact?.roles);
    const role = values[FieldsEnum.role];
    if (role && oldUserRoleData?.role === role) {
        errors[FieldsEnum.role] = t('team-members.dialogs.change-user-role.errors.same-role');
    }

    return errors;
};

export default validate;
