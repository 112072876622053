import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersT } from 'common/layouts/TrucksPageLayout/query-models';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.model]: null,
        [FieldsEnum.plateNumber]: '',
        [FieldsEnum.emissionClass]: null,
        [FieldsEnum.status]: null,
        [FieldsEnum.link]: null,
        [FieldsEnum.GPSTracker]: null,
        [FieldsEnum.dedication]: null,
        [FieldsEnum.createDates]: null,
        [FieldsEnum.lastModifyDates]: null,
    };

    return values;
};

export default getInitialValues;
