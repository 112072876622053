import * as React from 'react';

import { TFunction } from 'i18next';
import { CompanyContactDetailsT } from 'common/store/members/models';
import { PermissionsT } from 'common/store/auth/models';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { HeaderSidebarActionT } from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import EditIcon from 'common/icons/EditIcon';
import UpdateUserIcon from 'common/icons/UpdateUserIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import DeleteUserIcon from 'common/icons/DeleteUserIcon';
import DeleteIcon from 'common/icons/DeleteIcon';
import { UserStatusEnum } from 'common/utils/api/models';
import RestoreIcon from 'common/icons/RestoreIcon';

enum ActionsEnum {
    edit = 'edit',
    changeRole = 'change-role',
    deactivateUser = 'deactivate-user',
    deleteContact = 'delete-contact',
    restoreUser = 'restore-user',
}

const getActions = (
    permissions: PermissionsT,
    contactDetails: CompanyContactDetailsT | null,
): Array<ActionsEnum | null> => {
    if (!contactDetails || !permissions.canEditTeamMembers) {
        return [];
    }

    if (contactDetails.userStatus === UserStatusEnum.archived) {
        return [ActionsEnum.restoreUser];
    }

    return [
        ActionsEnum.edit,
        ...(contactDetails?.userId
            ? [ActionsEnum.changeRole, null, ActionsEnum.deactivateUser]
            : [null, ActionsEnum.deleteContact]),
    ];
};

type ActionCallbacksT = {
    onEditContact: (contactDetails: CompanyContactDetailsT) => void;
    onChangeUserRole: (contactDetails: CompanyContactDetailsT) => void;
    onDeactivateUser: (contactDetails: CompanyContactDetailsT) => void;
    onRestoreUser: (contactDetails: CompanyContactDetailsT) => void;
    onDeleteContact: (contactDetails: CompanyContactDetailsT) => void;
};

const useActionOptions = (
    t: TFunction,
    permissions: PermissionsT,
    contactDetails: CompanyContactDetailsT | null,
    callbacks: ActionCallbacksT,
): Array<HeaderSidebarActionT | SpecialOptionEnum> => {
    if (!contactDetails) {
        return [];
    }

    const actions = getActions(permissions, contactDetails);

    const actionsMap: Record<ActionsEnum, HeaderSidebarActionT> = {
        [ActionsEnum.edit]: {
            label: <DropdownControlOptionLabel label={t('common:team-members.actions.edit')} icon={<EditIcon />} />,
            onSelect: () => callbacks.onEditContact(contactDetails),
        },
        [ActionsEnum.changeRole]: {
            label: (
                <DropdownControlOptionLabel
                    label={t('common:team-members.actions.change-user-role')}
                    icon={
                        <UpdateUserIcon
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.slate}
                        />
                    }
                />
            ),
            onSelect: () => callbacks.onChangeUserRole(contactDetails),
        },
        [ActionsEnum.deactivateUser]: {
            label: (
                <DropdownControlOptionLabel
                    label={t('common:team-members.actions.deactivate-user')}
                    icon={
                        <DeleteUserIcon
                            fillColor={StyleGuideColorsEnum.white}
                            strokeColor={StyleGuideColorsEnum.tomatoRed}
                        />
                    }
                />
            ),
            onSelect: () => callbacks.onDeactivateUser(contactDetails),
        },
        [ActionsEnum.restoreUser]: {
            label: (
                <DropdownControlOptionLabel
                    icon={<RestoreIcon strokeColor={StyleGuideColorsEnum.slate} />}
                    label={t('common:team-members.actions.restore-user')}
                />
            ),
            onSelect: () => callbacks.onRestoreUser(contactDetails),
        },
        [ActionsEnum.deleteContact]: {
            label: (
                <DropdownControlOptionLabel
                    label={t('common:team-members.actions.delete-contact')}
                    icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                />
            ),
            onSelect: () => callbacks.onDeleteContact(contactDetails),
        },
    };

    return actions.map((action) => {
        if (action === null) {
            return SpecialOptionEnum.separator;
        }

        return actionsMap[action];
    });
};

export { useActionOptions };
