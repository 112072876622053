import { ALL_DAY_RANGE, TrailerTypeEnum } from 'common/constants';
import { ReserveTimeWindowT } from 'common/store/order-creation/models';
import { TimeWindowPickerSettingsT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/models';
import { CountryCodeT } from 'common/utils/api/models';
import { FormikTouched } from 'formik';

export enum FieldsEnum {
    shipperId = 'shipperId',
    shipperName = 'shipperName',

    trailerType = 'trailerType',
    trailerDictId = 'trailerDictId',

    laneId = 'laneId',

    route = 'route',

    excludedCountries = 'excludedCountries',
}

export enum RoutePointTypeEnum {
    pickupOrDelivery = 'pickup-or-delivery',
    driveThrough = 'drive-through',
}

export enum RoutePointFieldsEnum {
    fakeId = 'fakeId',
    id = 'id',
    type = 'type',
    location = 'location',
    timeWindow = 'timeWindow',
    isBrokerTimeWindow = 'isBrokerTimeWindow',
}

export const SHIPMENT_DETAIL_FORM_NAME = 'new-order-form/v1.4';

export type FormTouchedT = OverwriteT<
    FormikTouched<FormValuesT>,
    {
        route?: Array<FormikTouched<RoutePointFormValuesT>>;
    }
>;

export type RoutePointFormValuesT = {
    fakeId: string;
    id: RoutePointIdT | null; // null if not created reserve
    type: RoutePointTypeEnum;
    location: LocationT | null;
    timeWindow: ReserveTimeWindowT | null;
    isBrokerTimeWindow: boolean;
};

export type FormValuesT = {
    [FieldsEnum.shipperId]: string | null;
    [FieldsEnum.shipperName]: string | null;

    [FieldsEnum.trailerType]: TrailerTypeEnum | null;
    [FieldsEnum.trailerDictId]: TrailerDictIdT | null;

    [FieldsEnum.laneId]: ShipperContractLaneIdT | null;

    [FieldsEnum.excludedCountries]: Array<CountryCodeT>;

    route: Array<RoutePointFormValuesT>;
};

export type RoutePointErrorsT = Partial<{
    fakeId: FormErrorMessageT;
    id: FormErrorMessageT;
    type: FormErrorMessageT;
    location: FormErrorMessageT;
    timeWindow: FormErrorMessageT;
    isBrokerTimeWindow: FormErrorMessageT;
}>;

export type FormErrorsT = Partial<{
    [FieldsEnum.shipperId]: FormErrorMessageT;
    [FieldsEnum.shipperName]: FormErrorMessageT;
    [FieldsEnum.trailerType]: FormErrorMessageT;
    [FieldsEnum.trailerDictId]: FormErrorMessageT;
    [FieldsEnum.laneId]: FormErrorMessageT;
    [FieldsEnum.excludedCountries]: FormErrorMessageT;
    [FieldsEnum.route]: Array<RoutePointErrorsT>;
}>;

export enum RangeIdSetEnum {
    urgentPickUp = 'urgent-pick-up',
    regularPickUp = 'regular-pick-up',
    teamDriveDropOff = 'team-drive-drop-off',
    regularDropOff = 'regular-drop-off',
    layoverDropOff = 'layover-drop-off',
    broker = 'broker',
}

export const DEFAULT_TRAILER_TYPE = TrailerTypeEnum.box;

export const DEFAULT_BOX_TRAILER_ID = '5';
export const DEFAULT_TILT_TRAILER_ID = '1';

export const DEFAULT_TIME_WINDOW_PICKER_SETTINGS: TimeWindowPickerSettingsT = {
    availableValues: ALL_DAY_RANGE,
    brokerTimeWindowRanges: [],
    brokerTimeWindowHoursCount: 0,
    timeWindowRanges: [],
};
