import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../AuthLayout/AuthLayout';

import styles from './ConfirmEmailPage.scss';

import { QueryKeysEnum } from '../../constants';
import { selectEmailVerificationRequestStatus } from 'common/store/auth/selectors';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import history from 'common/utils/history';
import { emailVerification } from 'common/store/auth/actions';
import useQuery from 'common/utils/hooks/useQuery';
import { logWarning } from 'common/utils/logger';
import RedirectSignedUser from 'common/components/RedirectSignedUser/RedirectSignedUser';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { authApi } from 'common/utils/api/auth/factory';

const cx = classNames.bind(styles);

type QueryT = {
    [QueryKeysEnum.oobCode]: string;
    continueUrl: string;
    [QueryKeysEnum.returnUrl]: string;
};

type PropsT = {};

const ConfirmEmailPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const requestStatus = useSelector(selectEmailVerificationRequestStatus);
    const query = useQuery<QueryT>();

    React.useEffect(() => {
        const oobCode = query[QueryKeysEnum.oobCode];
        if (!oobCode) {
            logWarning('empty oobCode');
            return;
        }

        dispatch(emailVerification(oobCode));
    }, [query, t]);

    if (requestStatus.error) {
        return (
            <AuthLayout title={t('common:email-verification.error.title')} testSelector="confirm-email-page">
                <div className={cx('content')}>{t('common:email-verification.error.text')}</div>
                <br />
                <br />
                <div className={cx('error')}>{requestStatus.error?.message}</div>
            </AuthLayout>
        );
    }

    if (requestStatus.ok) {
        return (
            <AuthLayout title={t('common:email-verification.success.title')} testSelector="confirm-email-page">
                <div className={cx('content')}>
                    <div>{t('common:email-verification.success.text')}</div>
                    <br />
                    <Button
                        theme={ButtonThemeEnum.primary}
                        onClick={() => {
                            const continueLocation = query.continueUrl
                                ? {
                                      pathname: query.continueUrl,
                                  }
                                : authApi.createSignInUrl();

                            history.push(continueLocation);
                        }}
                    >
                        {t('common:email-verification.success.action')}
                    </Button>
                </div>
            </AuthLayout>
        );
    }

    return (
        <>
            <RedirectSignedUser />
            <AuthLayout testSelector="confirm-email-page">
                <PageTitle title={t('common:page-titles.confirm-email')} />
                <div className={cx('content')}>{t('common:email-verification.loading')}</div>
            </AuthLayout>
        </>
    );
});

export default ConfirmEmailPage;
