import Decimal from 'decimal.js-light';

export const safeArithmeticOperations = {
    plus: (a: number, b: number): number => {
        const bigA = new Decimal(a);

        return bigA.plus(b).toNumber();
    },
    minus: (a: number, b: number): number => {
        const bigA = new Decimal(a);

        return bigA.minus(b).toNumber();
    },
};
