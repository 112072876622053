import { useSelector } from 'react-redux';
import { selectLegalFormsDictById } from 'common/store/legal-forms-dict/selectors';
import * as React from 'react';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import { CountryCodeT } from 'common/utils/api/models';

type SelectedValuesT = {
    selectedLegalFormId: LegalFormIdT | null;
    selectedCountry: CountryCodeT | null;
};

type ResetCallbackT = () => void;

const useCheckCountryCorrectLegacyForm = (values: SelectedValuesT, resetCallback: ResetCallbackT) => {
    const { selectedCountry, selectedLegalFormId } = values;

    const legacyFormById = useSelector(selectLegalFormsDictById(selectedCountry));
    React.useEffect(() => {
        if (!isNumber(selectedLegalFormId)) {
            return;
        }

        if (isEmpty(legacyFormById)) {
            return;
        }

        const hasLegacyForm = legacyFormById[selectedLegalFormId];
        if (!hasLegacyForm) {
            resetCallback();
        }
    }, [legacyFormById]);
};

export { useCheckCountryCorrectLegacyForm };
