import * as React from 'react';
import { ApiEmissionClassT, EmissionClassEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    isCompact?: boolean;
    emissionClass: ApiEmissionClassT | null | undefined;
};

export const EMISSION_CLASS_MAP: Record<ApiEmissionClassT, string> = {
    [EmissionClassEnum.euro5]: 'V',
    [EmissionClassEnum.euro6]: 'VI',
};

export const EMISSION_CLASS_I18N_MAP: Record<ApiEmissionClassT, string> = {
    [EmissionClassEnum.euro5]: 'common:emission-classes.EURO_V',
    [EmissionClassEnum.euro6]: 'common:emission-classes.EURO_VI',
};

const EmissionClassLabel: React.FC<PropsT> = React.memo((props) => {
    const { emissionClass, isCompact } = props;

    const { t } = useTranslation();

    if (!emissionClass) {
        return null;
    }

    if (isCompact) {
        return <span>{EMISSION_CLASS_MAP[emissionClass]}</span>;
    }

    return <span>{t(EMISSION_CLASS_I18N_MAP[emissionClass])}</span>;
});

export default EmissionClassLabel;
