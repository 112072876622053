import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
    className?: string;
};

const CopyIcon: React.FC<PropsT> = (props) => {
    const { className, strokeColor } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={strokeColor} d="M7 6.25h8.25v10.5H7z" />
                <path
                    d="M13 1.75H4c-.825 0-1.5.675-1.5 1.5v10.5H4V3.25h9v-1.5zm2.25 3H7c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h8.25c.825 0 1.5-.675 1.5-1.5V6.25c0-.825-.675-1.5-1.5-1.5zm0 12H7V6.25h8.25v10.5z"
                    fill="#6B6C6F"
                />
            </g>
        </svg>
    );
};

CopyIcon.displayName = 'CopyIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default CopyIcon;
