import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import { checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';

const asyncValidate = (requestStatus: RequestStatusT): AsyncFormErrorsT => {
    const asyncErrors: AsyncFormErrorsT = {};

    if (checkIsAuthApiError(requestStatus.error)) {
        asyncErrors[FieldsEnum.password] = requestStatus.error.type;
    }

    return asyncErrors;
};

export default asyncValidate;
