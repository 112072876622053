import React from 'react';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import {
    ShipperContractLaneDetailsT,
    UpdateShipperContractLaneChangesT,
} from 'common/store/shipper-contract-lane-details/models';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import ShipperContractLaneEditForm from './ShipperContractLaneEditForm/ShipperContractLaneEditForm';
import ShipperContractLaneOtherDetails from '../ShipperContractLaneOtherDetails/ShipperContractLaneOtherDetails';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';
import classNames from 'classnames/bind';
import styles from './EditShipperContractLaneSidebarContent.scss';
import { useSelector } from 'react-redux';
import { selectUpdateShipperContractLaneRequest } from 'common/store/shipper-contract-lane-details/selectors';
import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    data: ShipperContractLaneDetailsT | null;
    onGoBack?: () => void;
    onClose?: () => void;
    onCancel: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;

    onOpenUserDetails?: (userId: UserIdT) => void;

    onChangeOriginRadiusKm?: (radiusKm: number) => void;
    onChangeDestinationRadiusKm?: (radiusKm: number) => void;

    onEditShipperContractLane: (detailsChanges: UpdateShipperContractLaneChangesT) => void;

    contractDetails: ShipperContractDetailsT | null;
};

const EditShipperContractLaneSidebarContent: React.FC<PropsT> = (props) => {
    const {
        data,
        onGoBack,
        onClose,
        onCancel,
        setNeedCloseConfirmation,

        onOpenUserDetails,

        onChangeOriginRadiusKm,
        onChangeDestinationRadiusKm,

        onEditShipperContractLane,

        contractDetails,
    } = props;

    const updateRequestStatus = useSelector(selectUpdateShipperContractLaneRequest);

    return (
        <>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={data?.tztLaneId || ''}
                    afterTitleNode={
                        <>
                            <ShipperContractLaneStatusPill className={cx('status')} status={data?.status} />
                            {data?.teamDrive && <TeamDrivePill />}
                        </>
                    }
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                    onGoBack={onGoBack}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <ShipperContractLaneEditForm
                    data={data}
                    onCancel={onCancel}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onChangeOriginRadiusKm={onChangeOriginRadiusKm}
                    onChangeDestinationRadiusKm={onChangeDestinationRadiusKm}
                    onSubmit={onEditShipperContractLane}
                    updateRequestStatus={updateRequestStatus}
                    contractDetails={contractDetails}
                />
                <ShipperContractLaneOtherDetails data={data} onOpenUserDetails={onOpenUserDetails} />
            </SideBarLayout>
        </>
    );
};

export default EditShipperContractLaneSidebarContent;
