import * as React from 'react';
import { OrderStatusEnum, ApiOrderStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiOrderStatusT | null | undefined;
};

export const ORDER_STATUS_T_MAP: Record<ApiOrderStatusT, string> = {
    [OrderStatusEnum.placed]: 'common:order-status.PLACED',
    [OrderStatusEnum.inProgress]: 'common:order-status.IN_PROGRESS',
    [OrderStatusEnum.delivered]: 'common:order-status.DELIVERED',
    [OrderStatusEnum.canceled]: 'common:order-status.CANCELED',
    [OrderStatusEnum.done]: 'common:order-status.DONE',
};

const OrderStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(ORDER_STATUS_T_MAP[status]);
});

export default OrderStatus;
