import * as React from 'react';
import { ApiShipperContractStatusT } from 'common/utils/api/models';
import ShipperContractLaneOrderCounterPill from 'common/components/pills/ShipperContractLaneOrderCounterPill/ShipperContractLaneOrderCounterPill';

type PropsT = {
    ordersLeft: number | null | undefined;
    maxNumberOfOrders: number | null | undefined;
    status: ApiShipperContractStatusT | null | undefined;
};

const OrdersCountCell: React.FC<PropsT> = React.memo((props) => {
    const { ordersLeft, maxNumberOfOrders, status } = props;

    return (
        <ShipperContractLaneOrderCounterPill
            ordersLeft={ordersLeft}
            maxNumberOfOrders={maxNumberOfOrders}
            status={status}
            isSymmetrical
        />
    );
});

export default OrdersCountCell;
