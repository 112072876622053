import {
    FETCH_RFQS_PAGE_REQUEST,
    FETCH_RFQS_PAGE_REQUEST_BEGIN,
    FETCH_RFQS_PAGE_REQUEST_ERROR,
    FETCH_RFQS_PAGE_REQUEST_SUCCESS,
    FetchRFQsPageActionT,
    FetchRFQsPageBeginActionT,
    FetchRFQsPageErrorActionT,
    FetchRFQsPageSuccessActionT,
    RESET_RFQS,
    ResetRFQsActionT,
} from './types';
import { FetchRFQsPageQueryT, RFQT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchRFQsPage = (
    pageNumber: PageNumberT,
    query: FetchRFQsPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchRFQsPageActionT => ({
    type: FETCH_RFQS_PAGE_REQUEST,
    query,
    pageNumber,
    companyId,
    options,
});

export const fetchRFQsPageBegin = (
    query: FetchRFQsPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchRFQsPageBeginActionT => ({
    type: FETCH_RFQS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchRFQsPageSuccess = (
    query: FetchRFQsPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<RFQT> | null,
    companyId: CompanyIdT,
): FetchRFQsPageSuccessActionT => ({
    type: FETCH_RFQS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
    companyId,
});

export const fetchRFQsPageError = (
    query: FetchRFQsPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchRFQsPageErrorActionT => ({
    type: FETCH_RFQS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
    companyId,
});

export const resetRFQs = (companyId: CompanyIdT, mutationListOptions: MutationListOptionsT): ResetRFQsActionT => ({
    type: RESET_RFQS,
    companyId,
    mutationListOptions,
});
