import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RejectDocumentConfirmation.scss';
import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { RequestStatusT } from 'common/utils/request-status';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import { PartnerTypeEnum } from 'common/utils/api/models';
import Textarea from 'common/components/Textarea/Textarea';
import { useFormik } from 'formik';
import { logWarning } from 'common/utils/logger';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { prepareData } from './prepare-reject-data';

export type ConfirmRejectDocumentModalDataT = {
    id: DocumentIdT;
    partnerType: PartnerTypeEnum;
    dictDocumentId: DictDocumentIdT;
};

type PropsT = {
    data: ConfirmRejectDocumentModalDataT | null;
    onConfirm: (id: ConfirmRejectDocumentModalDataT['id'], reason: string) => void;
    requestStatus: RequestStatusT;
    onClose: () => void;
    onCancel: () => void;
    name: string | undefined;
};

const cx = classNames.bind(styles);

const TEST_SELECTOR = 'reject-document-confirmation';

const RejectDocumentConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { name, data, onConfirm, requestStatus, onClose } = props;
    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const preparedData = prepareData(values);

            if (!data) {
                logWarning('failed to reject document, empty data');
                return;
            }

            onConfirm(data.id, preparedData.reason);

            formikHelpers.setTouched({});
        },
    });

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <form onSubmit={formik.handleSubmit}>
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.cancel'),
                                theme: ButtonThemeEnum.secondary,
                                testSelector: `${TEST_SELECTOR}_cancel`,
                                isDisabled: requestStatus.loading,
                                onClick: onClose,
                            },
                            {
                                children: t('common:error-modal.actions.reject'),
                                theme: ButtonThemeEnum.danger,
                                testSelector: `${TEST_SELECTOR}_ok`,
                                isLoading: requestStatus.loading,
                                isDisabled: requestStatus.loading,
                                type: 'submit',
                            },
                        ]}
                        icon={<BigTrashIcon />}
                        title={t('common:documents.reject-confirmation.title')}
                        message={t('common:documents.reject-confirmation.description', {
                            document: name,
                        })}
                        body={
                            <>
                                <FormikField
                                    name={FieldsEnum.reason}
                                    error={formik.errors[FieldsEnum.reason]}
                                    meta={formik.getFieldMeta(FieldsEnum.reason)}
                                    label={t('common:documents.reject-confirmation.fields.reason.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Textarea
                                            className={cx('textarea')}
                                            name={FieldsEnum.reason}
                                            value={formik.values[FieldsEnum.reason]}
                                            placeholder={t(
                                                'common:documents.reject-confirmation.fields.reason.placeholder',
                                            )}
                                            onChange={props.onChange}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                        />
                                    )}
                                </FormikField>
                            </>
                        }
                    />
                </form>
            </ModalContent>
        </Modal>
    );
});

export default RejectDocumentConfirmation;
