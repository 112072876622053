import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './SideBarMessage.scss';

const cx = classNames.bind(styles);

export enum SideBarMessageEnum {
    done = 'done',
    empty = 'empty',
}

export type PropsT = {
    title: React.ReactNode;
    description?: string;
    iconType: SideBarMessageEnum;
    className?: string;
};

const SideBarMessage: React.FC<PropsT> = React.memo((props) => {
    const { className, title, description, iconType } = props;

    return (
        <div className={cs(cx('message'), className)}>
            <div
                className={cx('icon', {
                    [`icon--${iconType}`]: true,
                })}
            />
            <div className={cx('title')}>{title}</div>
            {!!description && <div className={cx('description')}>{description}</div>}
        </div>
    );
});

export default SideBarMessage;
