import * as React from 'react';
import { DocumentStatusEnum, ApiDocumentStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiDocumentStatusT | null | undefined;
};

export const DOCUMENT_STATUS_T_MAP: Record<ApiDocumentStatusT, string> = {
    [DocumentStatusEnum.waitingForApprove]: 'common:document-status.WAITING_FOR_APPROVE',
    [DocumentStatusEnum.approved]: 'common:document-status.APPROVED',
    [DocumentStatusEnum.rejected]: 'common:document-status.REJECTED',
    [DocumentStatusEnum.revoked]: 'common:document-status.REVOKED',
    [DocumentStatusEnum.expired]: 'common:document-status.EXPIRED',
    [DocumentStatusEnum.archived]: 'common:document-status.ARCHIVED',
};

const DocumentStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return t('common:document-status.not-upload');
    }

    return t(DOCUMENT_STATUS_T_MAP[status]);
});

export default DocumentStatus;
