import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigEmptyIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M15 20h170v90H15z" />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.05"
                    d="M176 32a7 7 0 110 14h-40a7 7 0 110 14h22a7 7 0 110 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 110 14H62a7 7 0 110-14H23a7 7 0 110-14h40a7 7 0 100-14H38a7 7 0 110-14h40a7 7 0 110-14h98zm0 28a7 7 0 110 14 7 7 0 010-14z"
                />
                <path
                    fill="#FFF"
                    d="M87.119 80.307A11.964 11.964 0 0087 82c0 6.627 5.373 12 12 12s12-5.373 12-12c0-.575-.04-1.14-.119-1.693H140V107a3 3 0 01-3 3H61a3 3 0 01-3-3V80.307h29.119z"
                />
                <path
                    fill="#FFF"
                    d="M112 80c0 7.18-5.82 13-13 13s-13-5.82-13-13c0-.233.006-.464.018-.693H58l9.56-28.268A3.001 3.001 0 0170.402 49h57.196a3 3 0 012.842 2.039L140 79.307h-28.018c.012.23.018.46.018.693z"
                />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.3"
                    d="M110.098 80.955C110.098 86.502 105.129 92 99 92c-6.129 0-11.098-5.498-11.098-11.045 0-.18.006-1.359.016-1.536H67l8.161-19.844C75.513 58.634 76.49 58 77.587 58h42.826c1.098 0 2.074.634 2.426 1.575L131 79.42h-20.918c.01.177.016 1.356.016 1.536z"
                />
                <path
                    stroke="#8ABA2E"
                    strokeWidth="2.5"
                    d="M127.598 50.25a1.75 1.75 0 011.657 1.189h0l9.495 28.073V106c0 .483-.196.92-.513 1.237a1.742 1.742 0 01-1.237.513h0-76c-.483 0-.92-.196-1.237-.513A1.742 1.742 0 0159.25 106h0V79.512l9.495-28.073a1.745 1.745 0 011.657-1.189h0z"
                />
                <path
                    stroke="#8ABA2E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M64.574 79H67h-2.426zM72 79h12.745c1.876 0 1.876 1.319 1.876 2 0 6.627 5.496 12 12.276 12 6.78 0 12.276-5.373 12.276-12 0-.681 0-2 1.877-2H138"
                />
                <path
                    stroke="#8ABA2E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity="0.3"
                    strokeWidth="2.5"
                    d="M98.1 22v16.756M72 26.303l11.1 12.453m41-12.453L113 38.756"
                />
            </g>
        </svg>
    );
};

BigEmptyIcon.displayName = 'BigEmptyIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigEmptyIcon;
