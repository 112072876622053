import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './QuickFilters.scss';

import isEmpty from 'lodash/isEmpty';
import { TabsThemesEnum } from '../../TabsLabel/constants';
import TabLabel, { TabLabelSizeEnum } from '../../TabsLabel/TabLabel/TabLabel';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import NotificationLabel, {
    NotificationLabelThemeEnum,
    NotificationPropsT,
} from '../../notifications/NotificationLabel/NotificationLabel';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import Button, { ButtonThemeEnum } from '../../Button/Button';

const cx = classNames.bind(styles);

export type QuickFiltersOptionT<ValueT> = {
    id: ValueT | undefined;
    showPriority: number;
    hidePriority: number;
    label: string;
    notificationProps?: NotificationPropsT | null;
    testSelector: string;
};

export type PropsT<ValueT> = {
    className?: string;
    selectedId: ValueT | undefined;
    onSelect: (id: ValueT | undefined) => void;
    options: Array<QuickFiltersOptionT<ValueT>>;
    revertSeparatorIndexes?: number[];
    moreStatusesLabel: string;
    testSelector?: string;
};

const sortByShowPriorityAsc = <ValueT extends string>(
    optionA: QuickFiltersOptionT<ValueT>,
    optionB: QuickFiltersOptionT<ValueT>,
): number => {
    return optionA.showPriority - optionB.showPriority;
};

type OptionWidthByIdT = Record<string, number>;

const getFullWidth = <ValueT extends string>(
    options: Array<QuickFiltersOptionT<ValueT>>,
    optionWidthById: OptionWidthByIdT,
): number => {
    return options.reduce((summaryWidth, option) => {
        return summaryWidth + (optionWidthById[option.id as string] || 0);
    }, 0);
};

const reduceTriggerNotificationProps = <ValueT extends string>(
    options: Array<QuickFiltersOptionT<ValueT>>,
): null | NotificationPropsT => {
    const notificationGroups = groupBy(options, 'notificationProps.theme');

    const reduceNotificationGroup = (options: Array<QuickFiltersOptionT<ValueT>>) => {
        return options.reduce((summary, option) => {
            return summary + (option?.notificationProps?.count || 0);
        }, 0);
    };

    if (notificationGroups[NotificationLabelThemeEnum.red]) {
        return {
            count: reduceNotificationGroup(notificationGroups[NotificationLabelThemeEnum.red]),
            theme: NotificationLabelThemeEnum.red,
            isShowPlusSign: true,
        };
    }

    if (notificationGroups[NotificationLabelThemeEnum.orange]) {
        return {
            count: reduceNotificationGroup(notificationGroups[NotificationLabelThemeEnum.orange]),
            theme: NotificationLabelThemeEnum.orange,
            isShowPlusSign: true,
        };
    }

    if (notificationGroups[NotificationLabelThemeEnum.green]) {
        return {
            count: reduceNotificationGroup(notificationGroups[NotificationLabelThemeEnum.green]),
            theme: NotificationLabelThemeEnum.green,
        };
    }

    return null;
};

const PROXIMITY = 10;

/**
 *  @deprecated use QuickFiltersButtonGroups
 * */
const QuickFilters = <ValueT extends string>(props: PropsT<ValueT>): React.ReactElement | null => {
    const { selectedId, onSelect, options, className, moreStatusesLabel, testSelector, revertSeparatorIndexes } = props;

    const [optionWidthById, setOptionWidthById] = React.useState<OptionWidthByIdT>({});

    const [containerWidth, setContainerWidth] = React.useState<number | null>(null);

    const [containerNode, setContainerNode] = React.useState<HTMLDivElement | null>(null);
    const containerRef = React.useCallback((containerNode) => {
        if (containerNode !== null) {
            setContainerNode(containerNode);
            setContainerWidth(containerNode.offsetWidth);
        }
    }, []);

    React.useEffect(() => {
        if (!containerNode || !containerNode.firstChild) {
            return;
        }

        // @ts-ignore Property 'children' does not exist on type 'ChildNode'.
        const { children } = containerNode.firstChild;
        if (!children) {
            return;
        }

        const widthById: Record<string, number> = {};

        for (let index = 0; index < children.length; index += 1) {
            const child = children[index];
            const id = child?.getAttribute('data-id') || 'undefined';
            widthById[id] = (child?.offsetWidth || 0) + 0.5; // fix subpixel problem
        }

        const newOptionWidthById = {
            ...optionWidthById,
            ...widthById,
        };

        if (!isEqual(newOptionWidthById, optionWidthById)) {
            setOptionWidthById(newOptionWidthById);
        }
    }, [containerNode, options, moreStatusesLabel]);

    React.useLayoutEffect(() => {
        const measureContainer = () => {
            if (!containerNode) {
                return;
            }

            setContainerWidth(containerNode.offsetWidth);
        };

        window.addEventListener('resize', measureContainer);

        return () => {
            window.removeEventListener('resize', measureContainer);
        };
    }, [containerNode]);

    const moreRef = React.createRef<HTMLDivElement>();
    const [moreWidth, setMoreWidth] = React.useState<number | null>(null);
    React.useEffect(() => {
        setMoreWidth(moreRef.current?.offsetWidth || 0);
    }, [moreRef.current]);

    const sortedOptionsByHidePriority = options.sort((optionA, optionB) => {
        return optionA.hidePriority - optionB.hidePriority;
    });

    const isMeasuring = isEmpty(optionWidthById) || containerWidth === null;

    let optionsWidthLimit = isMeasuring || containerWidth === null ? Infinity : containerWidth - PROXIMITY;

    const optionsForShow = [...sortedOptionsByHidePriority];
    const optionsForHide = [];

    let optionsWidth = getFullWidth(optionsForShow, optionWidthById);

    if (optionsWidth > optionsWidthLimit && moreWidth !== null) {
        optionsWidthLimit -= moreWidth;
    }

    while (optionsWidth > optionsWidthLimit) {
        const option = optionsForShow.pop();
        if (option) {
            optionsForHide.push(option);
            const optionWidth = optionWidthById[option?.id as string];
            optionsWidth -= optionWidth;
        }
    }

    const sortedOptionsForShow = isMeasuring ? options : optionsForShow.sort(sortByShowPriorityAsc);

    const optionsForMeasure: Array<QuickFiltersOptionT<ValueT>> = [
        {
            // @ts-ignore
            id: '',
            showPriority: 0,
            hidePriority: 0,
            label: '',
        },
    ];

    const rawDropdownOptions = isMeasuring ? optionsForMeasure : optionsForHide.sort(sortByShowPriorityAsc);

    const dropdownOptions = rawDropdownOptions.map((option, optionIndex) => ({
        label: (
            <DropdownControlOptionLabel
                key={optionIndex}
                isSelected={selectedId === option.id}
                label={
                    <>
                        {option.notificationProps && (
                            <NotificationLabel className={cx('notification')} {...option.notificationProps} />
                        )}
                        {option.label}
                    </>
                }
            />
        ),
        onSelect: () => {
            onSelect(option.id);
        },
    }));

    const triggerNotificationProps = reduceTriggerNotificationProps(rawDropdownOptions);

    React.useEffect(() => {
        setMoreWidth(moreRef.current?.offsetWidth || 0);
    }, [`${triggerNotificationProps?.count}-${triggerNotificationProps?.theme}`, moreRef.current]);

    const dropdownOptionsWithSeparators = React.useMemo(() => {
        const { length } = dropdownOptions;
        const options: Array<DropdownControlOptionT | SpecialOptionEnum> = [...dropdownOptions];

        (revertSeparatorIndexes || []).forEach((separatorIndex) => {
            if (separatorIndex >= length) {
                return;
            }

            const originalIndex = length - separatorIndex;
            const originalOptions = dropdownOptions[originalIndex];
            const index = options.findIndex((option) => option === originalOptions);

            options.splice(index, 0, SpecialOptionEnum.separator);
        });

        return options;
    }, [dropdownOptions, revertSeparatorIndexes]);

    return (
        <div className={cs(cx('wrap'), className)} ref={containerRef}>
            <div className={cx('container', { 'container--isMeasuring': isMeasuring })}>
                {sortedOptionsForShow.map((option, optionIndex) => (
                    <div className={cx('option')} key={optionIndex} data-id={String(option.id)}>
                        <TabLabel
                            isCompact
                            size={TabLabelSizeEnum.small}
                            theme={TabsThemesEnum.light}
                            testSelector={`${testSelector}_${option.testSelector}`}
                            isActive={selectedId === option.id}
                            onClick={() => {
                                onSelect(option.id);
                            }}
                            badge={
                                option.notificationProps?.count ? (
                                    <NotificationLabel {...option.notificationProps} />
                                ) : null
                            }
                        >
                            {option.label}
                        </TabLabel>
                    </div>
                ))}
                <div className={cx('toggle')} ref={moreRef} data-id="more-statuses">
                    <DropdownControl
                        options={dropdownOptionsWithSeparators}
                        testSelector={`${testSelector}_more-statuses`}
                        renderTrigger={(isActive, onClick) => (
                            <Button
                                onClick={onClick}
                                isPressed={isActive}
                                testSelector={`${testSelector}_more-statuses`}
                                leftIcon={
                                    triggerNotificationProps ? (
                                        <NotificationLabel {...triggerNotificationProps} />
                                    ) : null
                                }
                                rightIcon={<ArrowsIcon />}
                                theme={ButtonThemeEnum.transparent}
                            >
                                {moreStatusesLabel}
                            </Button>
                        )}
                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                    />
                </div>
            </div>
        </div>
    );
};

/**
 *  @deprecated use QuickFiltersButtonGroups
 * */
export default React.memo(QuickFilters) as typeof QuickFilters;
