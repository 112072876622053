import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Switcher.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export enum SwitcherThemeEnum {
    primary = 'primary',
    danger = 'danger',
}

export type PropsT = {
    className?: string;
    checked: boolean;
    onToggle?: (value: boolean, event: React.MouseEvent<HTMLDivElement>) => void;
    isDisabled?: boolean;
    label?: React.ReactNode;
    rightIcon?: React.ReactNode;
    theme?: SwitcherThemeEnum;
};

const Switcher: React.FC<PropsT> = React.memo((props) => {
    const { className, checked, theme, onToggle, isDisabled, label, rightIcon } = props;

    const [isHover, setHover] = React.useState<boolean>(false);

    const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDisabled) {
            return;
        }

        if (onToggle) {
            onToggle(!checked, event);
        }
    };

    const handleMouseMove = () => {
        if (isHover) {
            return;
        }

        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            <div
                className={cx('switcher', {
                    [`switcher--theme-${theme || SwitcherThemeEnum.primary}`]: true,
                    'switcher--isHover': isHover,
                    'switcher--isClickable': !isDisabled,
                    'switcher--isChecked': checked,
                    'switcher--isDisabled': isDisabled,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleChange}
            />
            <div
                className={cx('label', {
                    'label--isClickable': !isDisabled,
                    'label--isDisabled': isDisabled,
                    'label--hasRightIcon': !!rightIcon,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleChange}
            >
                {label}
            </div>
            {rightIcon}
        </div>
    );
});

export default Switcher;
