import { FormikHelpers, FormikState } from 'formik';
import { FieldsEnum, FormValuesT } from '../constants';
import { useSelector } from 'react-redux';
import { selectReserve, selectReserveRequest } from 'common/store/order-creation/selectors';
import { useEffect } from 'react';
import find from 'lodash/find';

type FormikT = FormikState<FormValuesT> & FormikHelpers<FormValuesT>;

export const useOnlyAvailableLane = (formik: FormikT) => {
    const reserve = useSelector(selectReserve);
    const reserveRequest = useSelector(selectReserveRequest);

    const selectedLaneId = formik.values[FieldsEnum.laneId];

    useEffect(() => {
        if (!reserveRequest.ok) {
            return;
        }

        const isAvailableValue = !!find(reserve?.contractLanes, (lane) => {
            return lane.id === selectedLaneId;
        });

        if (!isAvailableValue) {
            formik.setFieldValue(FieldsEnum.laneId, null);
        }
    }, [reserveRequest, reserve]);
};
