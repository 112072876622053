import { put, takeEvery } from 'redux-saga/effects';
import { changeRFQBegin, changeRFQError, changeRFQSuccess } from './actions';
import { CLOSE_RFQ, CloseRFQActionT } from './types';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { rfqsRefreshChannel } from 'common/store/rfqs/channels';

function* closeRFQSaga(action: CloseRFQActionT): WrapGeneratorT<void> {
    const { id } = action;

    yield put(changeRFQBegin(id));

    const [error]: ReturnApiT<typeof shipperTranziitApi.closeRFQ> = yield shipperTranziitApi.closeRFQ(id);
    if (error) {
        yield put(changeRFQError(id, error));
        return;
    }

    yield put(changeRFQSuccess(id));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.rfqSuccessfulClosed,
                data: {},
            }),
        ),
    );

    rfqsRefreshChannel.emit({});
}

function* rfqsChangesSaga(): WrapGeneratorT<void> {
    yield takeEvery([CLOSE_RFQ], closeRFQSaga);
}

export default rfqsChangesSaga;
