import * as React from 'react';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import { useTranslation } from 'react-i18next';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import PageTitle from 'common/components/PageTitle/PageTitle';
import RFQListPageLayout from 'common/layouts/RFQListPageLayout/RFQListPageLayout';

import { generatePath } from 'react-router';
import { RFQRoutesEnum } from 'common/constants';
import history from 'common/utils/history';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import { useOpenLeftSidebar } from 'shipper/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';

type PropsT = {};

const RFQsListPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const { historyPush } = useHistoryPush();

    const { t } = useTranslation();

    const handleOpenRFQDetails = (event: React.MouseEvent, rfqId: RFQIdT | null) => {
        const pathname = generatePath(RFQRoutesEnum.details, {
            rfqId: rfqId || '-',
        });

        historyPush(event, pathname + history.location.search);
    };

    const openLeftSidebar = useOpenLeftSidebar();

    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId || !partnerType || !partnerId) {
            logWarning('failed to open contact details in rfq, empty userId or partnerType or partnerId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.rfqs')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('rfqs-table.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
            </ContentMargins>
            <RFQListPageLayout onOpenRFQDetails={handleOpenRFQDetails} onOpenUserDetails={handleOpenUserDetails} />
            <SideBars />
        </ScrollableContent>
    );
});

export default RFQsListPage;
