import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './MultiPointOrderRouteMap.scss';

import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import { TrackPointT } from 'common/store/asset-track/models';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import MapLoader from '../maps/MapLoader/MapLoader';
import NumberPinIcon from 'common/icons/NumberPinIcon';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

type MapPointT = {
    index?: number;
    driveThru?: boolean;
    latitude?: number; // double
    longitude?: number; // double
};

export type MultiPointOrderRouteMapPropsT = {
    withRouteTrack?: boolean;
    points: Array<MapPointT | null> | null;
    loadStatus?: ApiShipmentStatusT;
    route: Array<GooglePolylineT> | null;
    routeRequest?: RequestStatusT;
    truckTrack?: Array<TrackPointT>;
    truckTrackRequest?: RequestStatusT;
    truckRoute?: Array<GooglePolylineT> | null;
    truckRouteRequest?: RequestStatusT;
    trailerTrack?: Array<TrackPointT>;
    trailerTrackRequest?: RequestStatusT;
    trailerRoute?: Array<GooglePolylineT> | null;
    trailerRouteRequest?: RequestStatusT;
    isLoading?: boolean;
};

const visitedOriginLoadStatusSet = new Set([
    ShipmentStatusEnum.inTransit,
    ShipmentStatusEnum.unloading,
    ShipmentStatusEnum.delivered,
]);

const MultiPointOrderRouteMap: React.FC<MultiPointOrderRouteMapPropsT> = React.memo((props) => {
    const {
        points,
        loadStatus,
        route,
        routeRequest,
        withRouteTrack,
        truckTrack,
        truckTrackRequest,
        truckRoute,
        truckRouteRequest,
        trailerTrack,
        trailerTrackRequest,
        trailerRoute,
        trailerRouteRequest,
        children,
    } = props;

    const googleMapContext = React.useContext(GoogleMapContext);

    const allMapPoints = React.useMemo((): Array<BoundPointT> => {
        if (!points) {
            return [];
        }

        return points.reduce<Array<BoundPointT>>((acc, point) => {
            if (!point) {
                return acc;
            }

            acc.push([point.latitude, point.longitude]);

            return acc;
        }, []);
    }, [points]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const isRouteRequestLoading =
        (routeRequest && !(routeRequest?.error || routeRequest?.ok)) ||
        (truckRouteRequest && !(truckRouteRequest?.error || truckRouteRequest?.ok)) ||
        (trailerRouteRequest && !(trailerRouteRequest?.error || trailerRouteRequest?.ok));

    const isLoading =
        props.isLoading || isRouteRequestLoading || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {points?.map((point, index) => {
                    if (!point) {
                        return null;
                    }

                    let fillColor = StyleGuideColorsEnum.charcoal;
                    if (point.driveThru) {
                        fillColor = StyleGuideColorsEnum.gray;
                    }

                    // TODO change logic
                    const isVisited = visitedOriginLoadStatusSet.has(loadStatus as ShipmentStatusEnum);
                    if (isVisited) {
                        fillColor = StyleGuideColorsEnum.brandDark;
                    }

                    return (
                        <NumberPinIcon
                            number={(point.index || 0) + 1}
                            key={`route-point-${point.index}-${index}`}
                            className={cx('route-point-pin')}
                            fillColor={fillColor}
                            lng={point.longitude}
                            lat={point.latitude}
                        />
                    );
                })}
                {lastTruckTrackPoint && (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={lastTruckTrackPoint?.lat}
                        lng={lastTruckTrackPoint?.lng}
                        timestamp={lastTruckTrackPoint?.timestamp}
                    />
                )}
                {lastTrailerTrackPoint && (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={lastTrailerTrackPoint?.lat}
                        lng={lastTrailerTrackPoint?.lng}
                        timestamp={lastTrailerTrackPoint?.timestamp}
                    />
                )}
            </GoogleMapReact>
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={route}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={truckRoute}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={trailerRoute}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={allMapPoints}
            />
            {children}
        </>
    );
});

export default MultiPointOrderRouteMap;
