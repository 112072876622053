import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './CountyFlagLabel.scss';
import { CountryT } from 'common/store/countries-dict/models';
import { useTranslation } from 'react-i18next';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

export type PropsT = {
    country?: CountryT;
    countries?: Array<CountryT | null>;
    isThin?: boolean;
};

const CountyFlagLabel: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();

    const { country, countries, isThin } = props;

    if (!country && !countries?.length) {
        return null;
    }

    let icon: React.ReactElement | null = null;
    let label: string | null = null;
    if (country) {
        icon = <FlagIcon countryCode={country.code} className={cx('flag-icon')} />;
        label = country.userLangDisplayName || null;
    }

    if (countries?.length === 1) {
        icon = <FlagIcon countryCode={countries[0]?.code} className={cx('flag-icon')} />;
        label = countries[0]?.userLangDisplayName || null;
    }

    if (countries?.length && countries?.length > 1) {
        icon = <div className={cx('count')}>{countries.length}</div>;

        label = `${countries.length} ${t('common:countries', {
            postProcess: 'interval',
            count: countries.length,
        })}`;
    }

    return (
        <div className={cx('wrap')}>
            <span className={cx('icon')}>{icon}</span>
            <span
                className={cx('label', {
                    'label--isThin': isThin,
                })}
            >
                {label}
            </span>
        </div>
    );
};

export default React.memo(CountyFlagLabel) as typeof CountyFlagLabel;
