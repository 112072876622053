import { RolesEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    role = 'role',
}

export type FormValuesT = {
    [FieldsEnum.role]: RolesEnum;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
