import { InferChannelEventT } from 'common/utils/view-event-channel';
import { reserveLockChannel } from 'common/store/order-creation/channels';
import { useCallback, useState } from 'react';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';

export const useReserveChannelLock = (): boolean => {
    const [isLocked, setIsLocked] = useState(false);

    const reserveLockChannelHandler = useCallback((event: InferChannelEventT<typeof reserveLockChannel>) => {
        setIsLocked(event.isLocked);
    }, []);
    useChannelSubscribe(reserveLockChannel, reserveLockChannelHandler);

    return isLocked;
};
