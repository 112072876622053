import { put, select, takeEvery } from 'redux-saga/effects';
import { FetchRoutePlainGeometryActionT, FetchRoutesPlainGeometryActionT } from './types';
import { selectRoutingGeometryState } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { CompanyTypeEnum } from 'common/constants';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import {
    fetchRouteGeometryBegin,
    fetchRouteGeometryError,
    fetchRouteGeometrySuccess,
    fetchRoutePlainGeometry,
    fetchRoutesPlainGeometry,
} from './slice';
import { ROUTING_GEOMETRY_TTL } from 'common/store/routing-geometry/constants';

function getFetchRoutesGeometrySaga(companyType: CompanyTypeEnum) {
    return function* fetchRoutesGeometrySaga(action: FetchRoutesPlainGeometryActionT): WrapGeneratorT<void> {
        const { polylineIds, options } = action.payload;

        for (const polylineId of polylineIds) {
            if (polylineId) {
                yield put(
                    fetchRoutePlainGeometry({
                        polylineId,
                        options,
                    }),
                );
            }
        }
    };
}

function getFetchRouteGeometrySaga(companyType: CompanyTypeEnum) {
    return function* fetchRouteGeometrySaga(action: FetchRoutePlainGeometryActionT): WrapGeneratorT<void> {
        const { polylineId, options } = action.payload;

        const { requestStatus }: ReReturnT<typeof selectRoutingGeometryState> = yield select(
            selectRoutingGeometryState(polylineId),
        );
        if (!checkNeedRequest(requestStatus, options, ROUTING_GEOMETRY_TTL)) {
            return;
        }

        yield put(fetchRouteGeometryBegin({ polylineId }));

        const [error, data]: ReturnApiT<typeof commonTranziitApi.fetchRouteGeometryGoogle> =
            yield commonTranziitApi.fetchRouteGeometryGoogle(polylineId);

        if (error) {
            yield put(fetchRouteGeometryError({ polylineId, error }));
        } else {
            const polylines = data || [];

            yield put(fetchRouteGeometrySuccess({ polylineId, data: polylines }));
        }
    };
}

function* routingGeometrySaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchRoutesPlainGeometry.type, getFetchRoutesGeometrySaga(companyType));
    yield takeEvery(fetchRoutePlainGeometry.type, getFetchRouteGeometrySaga(companyType));
}

export { routingGeometrySaga };
