import { FieldsEnum, FormValuesT } from './constants';
import { EvidenceOfTaxDetailsT } from 'common/store/documents/models';

const DEFAULT_VALUES: FormValuesT = {
    [FieldsEnum.taxId]: '',
    [FieldsEnum.vatNumber]: '',
    [FieldsEnum.iban]: '',
    [FieldsEnum.bic]: '',
};

const getInitialValues = (evidenceOfTaxDetails: EvidenceOfTaxDetailsT | null): FormValuesT => {
    if (!evidenceOfTaxDetails) {
        return { ...DEFAULT_VALUES };
    }

    return {
        [FieldsEnum.taxId]: evidenceOfTaxDetails.taxId || '',
        [FieldsEnum.vatNumber]: evidenceOfTaxDetails.vat || '',
        [FieldsEnum.iban]: evidenceOfTaxDetails.iban || '',
        [FieldsEnum.bic]: evidenceOfTaxDetails.bic || '',
    };
};

export default getInitialValues;
