import { BaseAuthService } from './base-auth-service';
import { FirebaseAuthService, tryParseFirebaseConfig } from './firebase-auth-service';
import { KeycloakAuthService, tryParseKeycloakConfig, tryParseInitRedirectMode } from './keycloak-auth-service';

class AuthApiFactory {
    public static createAuthAPI = (): BaseAuthService => {
        // TODO add condition
        if (false) {
            const firebaseConfig = tryParseFirebaseConfig();
            return new FirebaseAuthService(firebaseConfig);
        }

        const keycloakConfig = tryParseKeycloakConfig();
        const initRedirectMode = tryParseInitRedirectMode();

        return new KeycloakAuthService(keycloakConfig, initRedirectMode);
    };
}

// singleton
const authApi = AuthApiFactory.createAuthAPI();

export { authApi };
