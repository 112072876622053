import { SettingsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_SETTINGS = 'common/settings/FETCH_SETTINGS';
export const FETCH_SETTINGS_BEGIN = 'common/settings/FETCH_SETTINGS_BEGIN';
export const FETCH_SETTINGS_SUCCESS = 'common/settings/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'common/settings/FETCH_SETTINGS_ERROR';

export type FetchSettingsActionT = {
    type: typeof FETCH_SETTINGS;
    options?: FetchOptionsT;
};

export type FetchSettingsBeginActionT = {
    type: typeof FETCH_SETTINGS_BEGIN;
};

export type FetchSettingsSuccessActionT = {
    type: typeof FETCH_SETTINGS_SUCCESS;
    settings: SettingsT;
};

export type FetchSettingsErrorActionT = {
    type: typeof FETCH_SETTINGS_ERROR;
    error: Error;
};

export type SettingsActionT =
    | FetchSettingsActionT
    | FetchSettingsBeginActionT
    | FetchSettingsSuccessActionT
    | FetchSettingsErrorActionT;

export type SettingsStateT = {
    fetchRequest: RequestStatusT;
    data: SettingsT;
};
