import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    width?: number;
    height?: number;
};

const DragIcon: React.FC<PropsT> = (props) => {
    const { fillColor, width = DEFAULT_ICON_SIZE, height = DEFAULT_ICON_SIZE } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <path fill={fillColor} fillRule="evenodd" d="m3 10 6 5V5l-6 5zm14 0-6 5V5l6 5z" />
        </svg>
    );
};

DragIcon.displayName = 'DragIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };

export default DragIcon;
