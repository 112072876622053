import { RFQDetailsStateT } from './types';
import { RequestStatusT } from 'common/utils/request-status';
import { initialRFQDetailsState } from './reducer';
import { SharableStateT } from 'common/store/models';

export const selectRFQDetails =
    (id: RFQIdT) =>
    (state: SharableStateT): RFQDetailsStateT['details'] =>
        state.rfqDetails[id]?.details || initialRFQDetailsState.details;

export const selectRFQDefaultPriceOffer =
    (id: RFQIdT) =>
    (state: SharableStateT): RFQDetailsStateT['defaultOffer'] =>
        state.rfqDetails[id]?.defaultOffer || initialRFQDetailsState.defaultOffer;

export const selectRFQDetailsRequestStatus =
    (id: RFQIdT) =>
    (state: SharableStateT): RequestStatusT =>
        state.rfqDetails[id]?.requestStatus || initialRFQDetailsState.requestStatus;
