import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchShipperContractDetails,
    fetchShipperContractDetailsBegin,
    fetchShipperContractDetailsError,
    fetchShipperContractDetailsSuccess,
    updateShipperContractDetailsBegin,
    updateShipperContractDetailsError,
    updateShipperContractDetailsSuccess,
    uploadShipperContract,
    revokeShipperContract,
    downloadShipperContract,
    downloadShipperContractDetailsBegin,
    downloadShipperContractDetailsSuccess,
    downloadShipperContractDetailsError,
    updateShipperContract,
} from './slice';
import {
    DownloadShipperContractActionT,
    FetchShipperContractDetailsActionT,
    RevokeShipperContractDetailsActionT,
    UpdateShipperContractActionT,
    UploadShipperContractDetailsActionT,
} from './types';
import { CompanyTypeEnum } from 'common/constants';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import { shipperContractsRefreshChannel } from 'common/store/shipper-contracts/channels';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import downloadFile from 'common/utils/download-file';
import { carrierContractDetailsRefreshChannel } from 'common/store/carrier-contract-details/channels';

function getFetchShipperContractDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: FetchShipperContractDetailsActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, options } = action.payload;

        const { fetchRequest }: ReReturnT<typeof selectShipperContractDetailsState> = yield select(
            selectShipperContractDetailsState(shipperContractId),
        );
        if (!checkNeedRequest(fetchRequest, options)) {
            return;
        }

        yield put(fetchShipperContractDetailsBegin({ shipperContractId }));

        let result: ReturnApiT<
            typeof shipperTranziitApi.fetchShipperContractDetails | typeof brokerTranziitApi.fetchShipperContractDetails
        >;
        if (companyType === CompanyTypeEnum.shipper) {
            result = yield shipperTranziitApi.fetchShipperContractDetails(shipperContractId);
        } else {
            result = yield brokerTranziitApi.fetchShipperContractDetails(partnerId, shipperContractId);
        }

        const [error, data] = result;

        if (error) {
            yield put(fetchShipperContractDetailsError({ shipperContractId, error }));
        } else {
            yield put(fetchShipperContractDetailsSuccess({ shipperContractId, data }));
        }
    };
}

function getUploadShipperContractDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: UploadShipperContractDetailsActionT): WrapGeneratorT<void> {
        const { partnerId, file, details } = action.payload;

        yield put(updateShipperContractDetailsBegin({}));

        const result: ReturnApiT<typeof brokerTranziitApi.uploadShipperContract> =
            yield brokerTranziitApi.uploadShipperContract(partnerId, details, file);
        const [error] = result;

        if (error) {
            yield put(updateShipperContractDetailsError({ error }));
        } else {
            yield put(updateShipperContractDetailsSuccess({}));

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.shipperContractUploaded,
                        data: {},
                    }),
                ),
            );
        }

        shipperContractsRefreshChannel.emit({});
    };
}

function getUpdateShipperContractDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: UpdateShipperContractActionT): WrapGeneratorT<void> {
        const { partnerId, file, shipperContractId, detailsChanges } = action.payload;

        yield put(updateShipperContractDetailsBegin({}));

        const result: ReturnApiT<typeof brokerTranziitApi.updateShipperContract> =
            yield brokerTranziitApi.updateShipperContract(partnerId, shipperContractId, detailsChanges, file);
        const [error] = result;

        if (error) {
            yield put(updateShipperContractDetailsError({ error }));
        } else {
            yield put(updateShipperContractDetailsSuccess({}));

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.shipperContractUpdated,
                        data: {},
                    }),
                ),
            );
        }

        shipperContractsRefreshChannel.emit({});
        carrierContractDetailsRefreshChannel.emit({ contractId: shipperContractId });
    };
}

function getRevokeShipperContractDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: RevokeShipperContractDetailsActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, reason } = action.payload;

        yield put(updateShipperContractDetailsBegin({}));

        const result: ReturnApiT<typeof brokerTranziitApi.revokeShipperContract> =
            yield brokerTranziitApi.revokeShipperContract(partnerId, shipperContractId, reason);
        const [error] = result;

        if (error) {
            yield put(updateShipperContractDetailsError({ error }));
        } else {
            yield put(updateShipperContractDetailsSuccess({}));

            yield put(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.shipperContractRevoked,
                        data: {},
                    }),
                ),
            );
        }

        shipperContractsRefreshChannel.emit({});
        carrierContractDetailsRefreshChannel.emit({ contractId: shipperContractId });
    };
}

function getDownloadShipperContractDeailsSaga(companyType: CompanyTypeEnum) {
    return function* (action: DownloadShipperContractActionT): WrapGeneratorT<void> {
        const { partnerId, shipperContractId, fileName } = action.payload;

        yield put(downloadShipperContractDetailsBegin({}));

        let response: ReturnApiT<
            typeof shipperTranziitApi.fetchShipperContract | typeof brokerTranziitApi.fetchShipperContract
        >;
        if (companyType === CompanyTypeEnum.broker) {
            response = yield brokerTranziitApi.fetchShipperContract(partnerId, shipperContractId);
        } else {
            response = yield shipperTranziitApi.fetchShipperContract(shipperContractId);
        }
        const [error, result] = response;

        if (error) {
            yield put(downloadShipperContractDetailsError({ error }));
            return;
        }

        if (result) {
            downloadFile({
                name: fileName || `${shipperContractId}.pdf`,
                data: result,
                type: 'application/pdf',
            });
        }

        yield put(downloadShipperContractDetailsSuccess({}));
    };
}

function* shipperContractDetailsSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchShipperContractDetails.type, getFetchShipperContractDeailsSaga(companyType));
    yield takeEvery(uploadShipperContract.type, getUploadShipperContractDeailsSaga(companyType));
    yield takeEvery(revokeShipperContract.type, getRevokeShipperContractDeailsSaga(companyType));
    yield takeEvery(downloadShipperContract.type, getDownloadShipperContractDeailsSaga(companyType));
    yield takeEvery(updateShipperContract.type, getUpdateShipperContractDeailsSaga(companyType));
}

export { shipperContractDetailsSaga };
