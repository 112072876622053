import React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const WeightIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M17.976 16.091L16.52 7.747c-.137-.787-.847-1.362-1.68-1.362h-2.187c.256-.428.403-.925.403-1.454C13.056 3.315 11.686 2 10 2 8.315 2 6.943 3.315 6.943 4.931c0 .529.147 1.026.404 1.454H5.158c-.831 0-1.542.576-1.679 1.363l-1.456 8.345c-.082.473.057.96.38 1.326a1.74 1.74 0 001.3.581h12.595c.501 0 .976-.215 1.3-.58.323-.368.462-.855.378-1.329zM10 3.708c.703 0 1.276.549 1.276 1.223 0 .675-.573 1.224-1.276 1.224-.704 0-1.276-.55-1.276-1.224 0-.675.572-1.223 1.276-1.223z"
            />
        </svg>
    );
};

WeightIcon.displayName = 'WeightIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default WeightIcon;

export const WeightIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                fillColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                fillColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent,
            };
        }

        if (isDisabled) {
            return {
                fillColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.gray,
        };
    },
};
