import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './OverlaysManager.scss';
import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import { NotificationLinkFactoryT } from 'common/components/notifications/NotificationsBarContent/models';
import NotificationToastsManager from 'common/components/toasts/NotificationToastsManager/NotificationToastsManager';
import NotificationsDebugForm from 'common/components/OverlaysManager/NotificationsDebugForm/NotificationsDebugForm';
import AlertToastsManager from '../toasts/AlertToastsManager/AlertToastsManager';
import { AlertToastRendererT } from 'common/components/toasts/AlertToastsManager/models';
import AlertsDebugForm from './AlertsDebugForm/AlertsDebugForm';
import DevLocalizationLanguageSwitcher from './DevLocalizationLanguageSwitcher/DevLocalizationLanguageSwitcher';

const cx = classNames.bind(styles);

export type PropsT = {
    renderAlertToast: AlertToastRendererT;
    renderNotificationToast: NotificationToastRendererT;
    getNotificationLink: NotificationLinkFactoryT;
};

const OverlaysManager: React.FC<PropsT> = React.memo((props) => {
    const { renderAlertToast, renderNotificationToast, getNotificationLink } = props;

    return (
        <>
            <NotificationToastsManager
                renderNotificationToast={renderNotificationToast}
                getNotificationLink={getNotificationLink}
                className={cx('notification-toasts-manger')}
            />
            <DevLocalizationLanguageSwitcher className={cx('dev-localization-language-switcher')} />
            <NotificationsDebugForm className={cx('debug-notifications-form')} />
            <AlertToastsManager className={cx('alerts-manager')} renderAlertToast={renderAlertToast} />
            <AlertsDebugForm className={cx('debug-alerts-form')} />
        </>
    );
});

export default OverlaysManager;
