import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FieldGroup.scss';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type FieldGroupEmptyItemPropsT = {
    className?: string;
};

export const FieldGroupEmptyItem: React.FC<FieldGroupEmptyItemPropsT> = (props) => {
    const { className } = props;

    return <div className={cs(cx('field-group__empty-item'), className)} />;
};

export type PropsT = {
    className?: string;
    spaces?: number;
    isFullWidth?: boolean;
};

const FieldGroup: React.FC<PropsT> = (props) => {
    const { isFullWidth, children, spaces, className } = props;

    return (
        <div
            className={cs(
                cx('field-group', {
                    [`field-group--spaces-${isNumber(spaces) ? spaces : 3}`]: true,
                    'field-group--is-full-width': isFullWidth,
                }),
                className,
            )}
        >
            {children}
        </div>
    );
};

export default React.memo(FieldGroup) as typeof FieldGroup;
