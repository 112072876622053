import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumnModsEnum, TableColumnT, TableRowMetaT } from 'common/components/DocumentsTable/DocumentsTable';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import classNames from 'classnames/bind';
import styles from './BaseShipperContractLanesTable.scss';
import { ShipperContractLanesTableRowT } from './models';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import OrdersCountCell from './cell-renderers/OrdersCountCell/OrdersCountCell';
import FlagCell from 'common/components/Table/cell-renderers/FlagCell/FlagCell';
import EmissionClassCell from 'common/components/Table/cell-renderers/EmissionClassCell/EmissionClassCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import LocationCell from './cell-renderers/LocationCell/LocationCell';
import EmissionIcon from 'common/icons/EmissionIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';
import Table from 'common/components/Table/Table';
import IdCell from './cell-renderers/IdCell/IdCell';
import { CountryCodeT } from 'common/utils/api/models';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';

type PropsT = {
    className?: string;
    tableName: string;
    rows: Array<ShipperContractLanesTableRowT>;
    onSelectRow?: (event: React.MouseEvent, row: ShipperContractLanesTableRowT) => void;
    isLoading: boolean;
    testSelector?: string;
};

const cx = classNames.bind(styles);

const VALID_DATE_FORMAT = 'DD MMM YYYY';

const BaseShipperContractLanesTable: React.FC<PropsT> = React.memo((props) => {
    const { tableName, rows, isLoading, testSelector, className, onSelectRow } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ShipperContractLanesTableRowT, void>> = [
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (row: ShipperContractLanesTableRowT) => <IdCell id={row.tztLaneId} />,
            className: cx('table__column', 'table__column--id'),
            testSelector: 'id',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.pickup')}</span>,
            headerClassName: cx('table__header', 'table__header--pickup'),
            render: (row: ShipperContractLanesTableRowT) => (
                <LocationCell
                    address={
                        <LocationLabel
                            location={{
                                zipCode: row.origin?.zipCode,
                                city: row.origin?.city,
                                country: row.origin?.countryCode as CountryCodeT,
                            }}
                            format="zip_city_country"
                        />
                    }
                />
            ),
            className: cx('table__column', 'table__column--pickup'),
            testSelector: 'origin',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.dropoff')}</span>,
            headerClassName: cx('table__header', 'table__header--destination'),
            render: (row: ShipperContractLanesTableRowT) => (
                <LocationCell
                    address={
                        <LocationLabel
                            location={{
                                zipCode: row.destination?.zipCode,
                                city: row.destination?.city,
                                country: row.destination?.countryCode as CountryCodeT,
                            }}
                            format="zip_city_country"
                        />
                    }
                />
            ),
            className: cx('table__column', 'table__column--destination'),
            testSelector: 'destination',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.orders-counter')}</span>,
            headerClassName: cx('table__header', 'table__header--orders-counter'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: ShipperContractLanesTableRowT) => (
                <OrdersCountCell
                    status={row.status}
                    ordersLeft={row.ordersLeft}
                    maxNumberOfOrders={row.maxNumberOfOrders}
                />
            ),
            className: cx('table__column', 'table__column--orders-counter'),
            testSelector: 'orders-counter',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.price')}</span>,
            headerClassName: cx('table__header', 'table__header--total-price'),
            render: (row: ShipperContractLanesTableRowT) => {
                return <CostCell cost={row.totalPrice} />;
            },
            className: cx('table__column', 'table__column--total-price'),
            testSelector: 'total-price',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.valid-till')}</span>,
            headerClassName: cx('table__header', 'table__header--valid-till'),
            render: (row: ShipperContractLanesTableRowT) => {
                return <DateCell date={row.validTill} dateFormat={VALID_DATE_FORMAT} isBigFont isBold />;
            },
            className: cx('table__column', 'table__column--valid-till'),
            testSelector: 'valid-till',
        },
        {
            renderHeader: () => <EmissionIcon strokeColor={StyleGuideColorsEnum.charcoal} />,
            headerClassName: cx('table__header', 'table__header--emission-class'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: ShipperContractLanesTableRowT) => {
                return <EmissionClassCell emissionClass={row?.emissionClass} />;
            },
            className: cx('table__column', 'table__column--emission-class'),
            testSelector: 'emission-class',
        },
        {
            renderHeader: () => (
                <TeamDriveIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.brandAccent}
                    strokeColor={StyleGuideColorsEnum.brandDark}
                />
            ),
            headerClassName: cx('table__header', 'table__header--team-drive'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: ShipperContractLanesTableRowT) => {
                return <FlagCell isShowFlag={!!row?.teamDrive} />;
            },
            className: cx('table__column', 'table__column--team-drive'),
            testSelector: 'team-drive',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contract-lanes.table.columns.status')}</span>,
            headerClassName: cx('table__header', 'table__header--status'),
            render: (row: ShipperContractLanesTableRowT) => {
                return <ShipperContractLaneStatusPill status={row.status} />;
            },
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, row: ShipperContractLanesTableRowT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ShipperContractLanesTableRowT> tableName={tableName} isLoading={isLoading} rows={rows}>
                {(rows, isUsedPrevRows) => (
                    <Table<ShipperContractLanesTableRowT, void>
                        testSelector={testSelector}
                        className={className}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        getRowMods={getRowMods}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default BaseShipperContractLanesTable;
