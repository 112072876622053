import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BaseShipperContractsLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApiShipperContractStatusT, ShipperContractsSortEnum } from 'common/utils/api/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import TableError from 'common/components/Table/TableError/TableError';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import Pagination from 'common/components/Table/Pagination/Pagination';
import BaseShipperContractsTable from './BaseShipperContractsTable/BaseShipperContractsTable';
import { useQueryParams } from 'use-query-params';
import {
    selectShipperContractsByIds,
    selectShipperContractsPages,
    selectShipperContractsTotal,
} from 'common/store/shipper-contracts/selectors';
import { fetchShipperContractsPage } from 'common/store/shipper-contracts/slice';
import {
    shipperContractsPaginationChannel,
    shipperContractsRefreshChannel,
} from 'common/store/shipper-contracts/channels';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { ShipperContractsTableRowT } from 'common/layouts/BaseShipperContractsLayout/BaseShipperContractsTable/models';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import noop from 'lodash/noop';
import { isNonNil } from 'common/utils';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkQueryFiltersKeys } from 'common/components/Table/utils/check-query-filters-keys';

const cx = classNames.bind(styles);

type PropsT = {
    contactStatusesAttentionSet?: Set<ApiShipperContractStatusT | null>;
    onUploadShipperContract?: () => void;

    navigationTabs: React.ReactNode;

    onOpenShipperContractDetails: (shipperContractId: ShipperContractIdT) => void;
};

const BaseShipperContractsLayout: React.FC<PropsT> = React.memo((props) => {
    const { navigationTabs, contactStatusesAttentionSet, onUploadShipperContract, onOpenShipperContractDetails } =
        props;

    const { partnerId, partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const openLeftSidebar = useOpenLeftSidebar();

    const total = useSelector(selectShipperContractsTotal(partnerId));
    const pages = useSelector(selectShipperContractsPages(partnerId));
    const byId = useSelector(selectShipperContractsByIds(partnerId));

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.shipperContractsPage]: createPageNumberParam(),
        [QueryKeysEnum.shipperContractsSort]: createSortParams<ShipperContractsSortEnum>({
            value: ShipperContractsSortEnum.addedDate,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.shipperContractsFilters]: createJsonParams<QueryFiltersT>({}),
    });

    const selectedSort = query[QueryKeysEnum.shipperContractsSort];
    const pageNumber = query[QueryKeysEnum.shipperContractsPage];
    const queryFilters = query[QueryKeysEnum.shipperContractsFilters];

    const page = pages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchShipperContractsPage({ pageNumber, query, partnerId, options: { isForceUpdate: false } }));
    }, [pageNumber, selectedSort, queryFilters, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchShipperContractsPage({ pageNumber, query, partnerId, options: { isForceUpdate: true } }));
    }, [pageNumber, selectedSort, queryFilters, partnerId]);
    useChannelSubscribe(shipperContractsRefreshChannel, refreshPageHandler);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.shipperContractsPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof shipperContractsPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(shipperContractsPaginationChannel, setPageHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchShipperContractsPage({ pageNumber, query, partnerId }));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSort, queryFilters, partnerId]);

    const shipperContractsTableRows = React.useMemo((): Array<ShipperContractsTableRowT> => {
        return (ids || []).map((id) => byId[id]).filter(isNonNil);
    }, [ids, byId]);

    const sortOptions: Array<SortDropdownOptionT<ShipperContractsSortEnum>> = React.useMemo(
        () => [
            {
                label: t('common:shipper-contracts.table.sorts.added-date'),
                value: ShipperContractsSortEnum.addedDate,
            },
            {
                label: t('common:shipper-contracts.table.sorts.valid-till-date'),
                value: ShipperContractsSortEnum.validTillDate,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<ShipperContractsSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.shipperContractsPage]: 0,
            [QueryKeysEnum.shipperContractsSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.shipperContractsPage]: 0,
            [QueryKeysEnum.shipperContractsFilters]: selectedQueryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.shipperContractsFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.shipperContractsPage]: 0,
            [QueryKeysEnum.shipperContractsFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.text]: searchText || undefined,
        });
    };

    const handleOpenShipperContractDetails = (row: ShipperContractsTableRowT) => {
        onOpenShipperContractDetails(row.id);
    };

    const handleOpenUserDetails = (userId: UserIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const renderEmptyState = () => {
        if (!checkNeedRenderEmptyState(shipperContractsTableRows, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasAnyFilters } = checkQueryFiltersKeys(queryFilters, [], []);
        if (hasAnyFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:shipper-contracts.table.messages.not-found.title')}
                    description={t('common:shipper-contracts.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('common:shipper-contracts.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="not-found"
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:shipper-contracts.table.messages.empty.title')}
                description={t('common:shipper-contracts.table.messages.empty.description')}
                isShowAction={!!onUploadShipperContract}
                actionTitle={t('common:shipper-contracts.table.messages.empty.action')}
                actionTheme={ButtonThemeEnum.primary}
                onActionClick={onUploadShipperContract || noop}
                testSelector="empty"
            />
        );
    };

    return (
        <>
            <ListPageLayout>
                <ListPageHeaderLayout
                    leftToolsNode={
                        <>
                            <div>{navigationTabs}</div>
                            <SortDropdown
                                className={cx('sort-trigger')}
                                overlayPosition={SortDropdownOverlayPositionEnum.left}
                                selectedValue={selectedSort}
                                options={sortOptions}
                                onSelect={handleSelectSort}
                            />
                            <SearchControl
                                placeholder={t('common:shipper-contracts.table.search.placeholder')}
                                searchText={queryFilters[QueryFiltersKeysEnum.text]}
                                onChangeSearchText={handleSearch}
                            />
                        </>
                    }
                    filterTagsNode={
                        <SelectedFilters
                            isCompact
                            queryFilters={queryFilters}
                            setQueryFilters={handleSetQueryFilters}
                        />
                    }
                    rightToolsNode={
                        onUploadShipperContract ? (
                            <Button
                                theme={ButtonThemeEnum.primary}
                                className={cx('action', 'action--start')}
                                onClick={onUploadShipperContract}
                            >
                                {t('common:shipper-contracts.actions.upload')}
                            </Button>
                        ) : null
                    }
                />
                {renderEmptyState()}
                <BaseShipperContractsTable
                    tableName="shipper-contracts"
                    rows={shipperContractsTableRows}
                    onSelectRow={handleOpenShipperContractDetails}
                    onOpenUserDetails={handleOpenUserDetails}
                    isLoading={requestStatus?.loading}
                    contactStatusesAttentionSet={contactStatusesAttentionSet}
                />
            </ListPageLayout>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
        </>
    );
});

export default BaseShipperContractsLayout;
