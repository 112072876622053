import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import { ALL_AUTH_PROFILE_TYPES, AuthProfileTypeEnum } from 'common/store/auth/constants';

const getPermissions = <RolesT, PermissionKeysT extends string>(
    userRolesSet: Set<RolesT>,
    config: Record<PermissionKeysT, Array<RolesT | RolesT[]>>,
    profileType: AuthProfileTypeEnum,
    profileTypeRestrictConfig: Partial<Record<PermissionKeysT, AuthProfileTypeEnum[]>>,
): Record<PermissionKeysT, boolean> => {
    return mapValues(config, (necessaryRoles, key) => {
        const profileTypeRestriction: AuthProfileTypeEnum[] =
            profileTypeRestrictConfig[key as PermissionKeysT] || ALL_AUTH_PROFILE_TYPES;

        return (
            necessaryRoles.some((rule): boolean => {
                if (isArray(rule)) {
                    return rule.every((role): boolean => {
                        return userRolesSet.has(role);
                    });
                }

                const role = rule;
                return userRolesSet.has(role);
            }) && profileTypeRestriction.includes(profileType)
        );
    });
};

export { getPermissions };
