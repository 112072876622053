import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkBirthday, checkDate, checkEmail, checkNotEmpty, checkPhoneNumber } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.firstName, FieldsEnum.lastName, FieldsEnum.position, FieldsEnum.language] as const;
const BIRTHDAY_FIELDS = [FieldsEnum.birthday] as const;
const DATE_FIELDS = [FieldsEnum.birthday] as const;
const EMAIL_FIELDS = [FieldsEnum.email] as const;
const PHONE_FIELDS = [FieldsEnum.desktopPhone, FieldsEnum.mobilePhone] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkBirthday(t, BIRTHDAY_FIELDS, values),
        ...checkDate(t, DATE_FIELDS, values),
        ...checkEmail(t, EMAIL_FIELDS, values),
        ...checkPhoneNumber(t, PHONE_FIELDS, values),
    };

    return errors;
};

export default validate;
