import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ProfileBasicInformation.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { selectCurrentCompany, selectUpdateCompanyRequestStatus } from 'common/store/company/selectors';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import { fetchCompany, updateCompany } from 'common/store/company/actions';
import validateForm from './validate-form';
import { FieldsEnum, FormValuesT } from './constants';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { getZipCodePlaceholder } from 'common/utils/postal-codes';
import Widget from 'common/components/Widget/Widget';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import LegalFormDropdown from 'common/components/dropdowns/LegalFormDropdown/LegalFormDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import ContactDropdown, { contactFilters } from 'common/components/dropdowns/ContactDropdown/ContactDropdown';
import { CountryCodeT, PartnerTypeEnum } from 'common/utils/api/models';
import { CompanyChangesT } from 'common/store/company/models';
import ChangeVerifiedAddressConfirmation, {
    ConfirmChangeAddressConfirmationDataT,
} from 'common/layouts/BaseProfileLayout/ProfileBasicInformation/ChangeVerifiedAddressConfirmation/ChangeVerifiedAddressConfirmation';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { StyleGuideColorsEnum } from 'common/constants';
import { useCheckCountryCorrectLegacyForm } from 'common/components/dropdowns/LegalFormDropdown/hooks';
import VerificationAlert from 'common/components/VerificationAlert/VerificationAlert';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CopyIcon from 'common/icons/CopyIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import IconTrigger from 'design-system/components/IconTrigger/IconTrigger';
import { checkValuesChanges } from 'common/utils/form-helpers';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import CreatePartnerInfo from 'common/layouts/BaseProfileLayout/ProfileBasicInformation/CreatePartnerInfo/CreatePartnerInfo';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { companyRefreshChannel } from 'common/store/company/channels';
import { useCallback } from 'react';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';

const cx = classNames.bind(styles);

type VerificationStatusPropsT = {
    isVerified: boolean;
};

const VerificationStatus: React.FC<VerificationStatusPropsT> = (props) => {
    const { isVerified } = props;
    const { t } = useTranslation();

    return (
        <ColoredStatusLabel
            label={isVerified ? t('common:status.verified') : t('common:status.not-verified')}
            color={isVerified ? StyleGuideColorsEnum.mediumGreen : StyleGuideColorsEnum.orange}
        />
    );
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type PropsT = {
    hasVerificationProblem: boolean;
};

const ProfileBasicInformation: React.FC<PropsT> = React.memo((props) => {
    const { hasVerificationProblem } = props;

    const { partnerId, partnerType } = usePartnerContext();

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const company = useSelector(selectCurrentCompany);
    const updateCompanyRequestStatus = useSelector(selectUpdateCompanyRequestStatus);

    React.useEffect(() => {
        dispatch(fetchCompany());
    }, []);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchCompany({ isForceUpdate: false }));
    }, []);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshHandler = useCallback(() => {
        dispatch(fetchCompany({ isForceUpdate: true }));
    }, []);
    useChannelSubscribe(companyRefreshChannel, refreshHandler);

    const confirmChangeAddressConfirmationData = useModalDialog<ConfirmChangeAddressConfirmationDataT>();
    useCloseModalDialogAfterRequest(updateCompanyRequestStatus, [confirmChangeAddressConfirmationData]);

    const shouldShowBillingInfo = company?.type === PartnerTypeEnum.shipper;

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { shouldShowBillingInfo });
    }, [t, shouldShowBillingInfo]);

    const initialValues = React.useMemo((): FormValuesT => {
        return {
            [FieldsEnum.number]: company?.number || null,

            [FieldsEnum.companyName]: company?.name || '',
            [FieldsEnum.legalFormId]: company?.legalFormId || null,
            [FieldsEnum.mainContactId]: company?.mainContactId || null,

            [FieldsEnum.legalAddressCountryCode]: company?.legalAddress?.country || null,
            [FieldsEnum.legalAddressZipCode]: company?.legalAddress?.zipCode || '',
            [FieldsEnum.legalAddressCity]: company?.legalAddress?.city || '',
            [FieldsEnum.legalAddressStreet1]: company?.legalAddress?.street1 || '',
            [FieldsEnum.legalAddressStreet2]: company?.legalAddress?.street2 || '',

            [FieldsEnum.postalAddressCountryCode]: company?.postalAddress?.country || null,
            [FieldsEnum.postalAddressZipCode]: company?.postalAddress?.zipCode || '',
            [FieldsEnum.postalAddressCity]: company?.postalAddress?.city || '',
            [FieldsEnum.postalAddressStreet1]: company?.postalAddress?.street1 || '',
            [FieldsEnum.postalAddressStreet2]: company?.postalAddress?.street2 || '',

            [FieldsEnum.billingAddressCountryCode]: company?.billingAddress?.country || null,
            [FieldsEnum.billingAddressZipCode]: company?.billingAddress?.zipCode || '',
            [FieldsEnum.billingAddressCity]: company?.billingAddress?.city || '',
            [FieldsEnum.billingAddressStreet1]: company?.billingAddress?.street1 || '',
            [FieldsEnum.billingAddressStreet2]: company?.billingAddress?.street2 || '',
            [FieldsEnum.billingEmail]: company?.billingEmail || '',
        };
    }, [company]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, initialValues, { shouldShowBillingInfo });
    }, [t, initialValues, shouldShowBillingInfo]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!company || updateCompanyRequestStatus.loading) {
                return;
            }

            const postalAddressFields = [
                FieldsEnum.postalAddressCountryCode,
                FieldsEnum.postalAddressZipCode,
                FieldsEnum.postalAddressCity,
                FieldsEnum.postalAddressStreet1,
                FieldsEnum.postalAddressStreet2,
            ];
            const hasPostalAddressChanges = checkValuesChanges(postalAddressFields, initialValues, values);

            const legalAddressFields = [
                FieldsEnum.legalAddressCountryCode,
                FieldsEnum.legalAddressZipCode,
                FieldsEnum.legalAddressCity,
                FieldsEnum.legalAddressStreet1,
                FieldsEnum.legalAddressStreet2,
            ];
            const hasLegalAddressChanges = checkValuesChanges(legalAddressFields, initialValues, values);

            const billingAddressFields = [
                FieldsEnum.billingAddressCountryCode,
                FieldsEnum.billingAddressZipCode,
                FieldsEnum.billingAddressCity,
                FieldsEnum.billingAddressStreet1,
                FieldsEnum.billingAddressStreet2,
            ];
            const hasBillingAddressChanges = checkValuesChanges(billingAddressFields, initialValues, values);

            const billingEmailFields = [FieldsEnum.billingEmail];
            const hasBillingEmailChanges = checkValuesChanges(billingEmailFields, initialValues, values);

            const companyChanges: CompanyChangesT = {
                legalFormId: values[FieldsEnum.legalFormId] || undefined,
                name: values[FieldsEnum.companyName],
                mainContactId: values[FieldsEnum.mainContactId] || undefined,
                legalAddress: hasLegalAddressChanges
                    ? {
                          country: values[FieldsEnum.legalAddressCountryCode] as CountryCodeT,
                          zipCode: values[FieldsEnum.legalAddressZipCode],
                          city: values[FieldsEnum.legalAddressCity],
                          street1: values[FieldsEnum.legalAddressStreet1],
                          street2: values[FieldsEnum.legalAddressStreet2],
                      }
                    : undefined,
                postalAddress: hasPostalAddressChanges
                    ? {
                          country: values[FieldsEnum.postalAddressCountryCode] as CountryCodeT,
                          zipCode: values[FieldsEnum.postalAddressZipCode],
                          city: values[FieldsEnum.postalAddressCity],
                          street1: values[FieldsEnum.postalAddressStreet1],
                          street2: values[FieldsEnum.postalAddressStreet2],
                      }
                    : undefined,
                billingAddress: hasBillingAddressChanges
                    ? {
                          country: values[FieldsEnum.billingAddressCountryCode] as CountryCodeT,
                          zipCode: values[FieldsEnum.billingAddressZipCode],
                          city: values[FieldsEnum.billingAddressCity],
                          street1: values[FieldsEnum.billingAddressStreet1],
                          street2: values[FieldsEnum.billingAddressStreet2],
                      }
                    : undefined,
                billingEmail: hasBillingEmailChanges ? values[FieldsEnum.billingEmail] : undefined,
            };

            const hasVerifiedLegalAddressChanges = hasLegalAddressChanges && company?.addressVerified;
            const hasVerifiedBillingAddressChanges =
                (hasBillingAddressChanges || hasBillingEmailChanges) && company?.billingAddressVerified;
            if (hasVerifiedLegalAddressChanges || hasVerifiedBillingAddressChanges) {
                confirmChangeAddressConfirmationData.setData({
                    companyChanges,
                    hasVerifiedBillingAddressChanges,
                    hasVerifiedLegalAddressChanges,
                });
            } else {
                dispatch(updateCompany(company, companyChanges));
            }
        },
    });

    React.useEffect(() => {
        if (updateCompanyRequestStatus.ok) {
            formik.setTouched({});
        }
    }, [updateCompanyRequestStatus.ok]);

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const isShowLoader = !company || updateCompanyRequestStatus.loading;

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading: updateCompanyRequestStatus.loading,
            });
        }
    }, [updateCompanyRequestStatus.loading]);

    const selectedAddressLegalCountry = formik.values[FieldsEnum.legalAddressCountryCode];
    const selectedLegalFormId = formik.values[FieldsEnum.legalFormId];
    useCheckCountryCorrectLegacyForm(
        {
            selectedLegalFormId,
            selectedCountry: selectedAddressLegalCountry,
        },
        () => {
            formik.setTouched({ [FieldsEnum.legalFormId]: false }, false);
            formik.setFieldValue(FieldsEnum.legalFormId, null);
        },
    );

    const handleCopyPostalAddress = () => {
        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.postalAddressCountryCode]: values[FieldsEnum.legalAddressCountryCode],
                [FieldsEnum.postalAddressZipCode]: values[FieldsEnum.legalAddressZipCode],
                [FieldsEnum.postalAddressCity]: values[FieldsEnum.legalAddressCity],
                [FieldsEnum.postalAddressStreet1]: values[FieldsEnum.legalAddressStreet1],
                [FieldsEnum.postalAddressStreet2]: values[FieldsEnum.legalAddressStreet2],
            };
        });

        formik.setTouched({
            ...formik.touched,
            [FieldsEnum.postalAddressCountryCode]: true,
            [FieldsEnum.postalAddressZipCode]: true,
            [FieldsEnum.postalAddressCity]: true,
            [FieldsEnum.postalAddressStreet1]: true,
            [FieldsEnum.postalAddressStreet2]: true,
        });
    };

    const handleCopyBillingAddress = () => {
        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.billingAddressCountryCode]: values[FieldsEnum.legalAddressCountryCode],
                [FieldsEnum.billingAddressZipCode]: values[FieldsEnum.legalAddressZipCode],
                [FieldsEnum.billingAddressCity]: values[FieldsEnum.legalAddressCity],
                [FieldsEnum.billingAddressStreet1]: values[FieldsEnum.legalAddressStreet1],
                [FieldsEnum.billingAddressStreet2]: values[FieldsEnum.legalAddressStreet2],
            };
        });

        formik.setTouched({
            ...formik.touched,
            [FieldsEnum.billingAddressCountryCode]: true,
            [FieldsEnum.billingAddressZipCode]: true,
            [FieldsEnum.billingAddressCity]: true,
            [FieldsEnum.billingAddressStreet1]: true,
            [FieldsEnum.billingAddressStreet2]: true,
        });
    };

    return (
        <div className={cx('wrap')}>
            {hasVerificationProblem && (
                <VerificationAlert
                    className={cx('alert')}
                    message={t('inline-alerts.verification-company-problem.message')}
                />
            )}
            {isShowLoader && <LoaderOverlay />}
            <form className={cx('form')} onSubmit={formik.handleSubmit}>
                <Widget
                    className={cx('widget')}
                    title={t('common:profile-page.basic-information.sections.base')}
                    afterTitleNode={
                        <CreatePartnerInfo
                            partnerId={partnerId}
                            partnerType={partnerType}
                            createdByBroker={!!company?.createdByBroker}
                            createdDate={company?.createdDate || null}
                            userId={company?.createdById || null}
                            userName={company?.createdByName || null}
                        />
                    }
                    rightNode={<WidgetChangesDetector fields={[FieldsEnum.companyName]} />}
                >
                    <div className={cx('widget__inner')}>
                        <FormikField
                            name={FieldsEnum.companyName}
                            error={formik.errors[FieldsEnum.companyName]}
                            meta={formik.getFieldMeta(FieldsEnum.companyName)}
                            label={t('common:profile-page.basic-information.field-labels.company-name')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isAlwaysShowError={!isShowLoader}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.companyName}
                                    value={formik.values[FieldsEnum.companyName]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            name={FieldsEnum.legalFormId}
                            error={formik.errors[FieldsEnum.legalFormId]}
                            meta={formik.getFieldMeta(FieldsEnum.legalFormId)}
                            label={t('common:profile-page.basic-information.field-labels.legal-form')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <LegalFormDropdown
                                    countryCode={formik.values[FieldsEnum.legalAddressCountryCode]}
                                    value={formik.values[FieldsEnum.legalFormId]}
                                    onChange={props.onChange}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            name={FieldsEnum.mainContactId}
                            error={formik.errors[FieldsEnum.mainContactId]}
                            meta={formik.getFieldMeta(FieldsEnum.mainContactId)}
                            label={t('common:profile-page.basic-information.field-labels.main-contact')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <ContactDropdown
                                    contactsFilter={contactFilters.availableForMainContact}
                                    value={formik.values[FieldsEnum.mainContactId]}
                                    onChange={props.onChange}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    companyId={partnerId}
                                    placeholder={t(
                                        'common:profile-page.basic-information.field-placeholders.main-contact',
                                    )}
                                />
                            )}
                        </FormikField>
                    </div>
                </Widget>
                <Widget
                    className={cx('widget')}
                    title={t('common:profile-page.basic-information.sections.legal-address')}
                    rightNode={
                        <WidgetChangesDetector
                            fields={[
                                FieldsEnum.legalAddressCountryCode,
                                FieldsEnum.legalAddressZipCode,
                                FieldsEnum.legalAddressCity,
                                FieldsEnum.legalAddressStreet1,
                                FieldsEnum.legalAddressStreet2,
                            ]}
                            defaultNotification={
                                company?.legalAddress ? (
                                    <VerificationStatus isVerified={!!company?.addressVerified} />
                                ) : null
                            }
                        />
                    }
                >
                    <div className={cx('widget__inner')}>
                        <FieldGroup spaces={1}>
                            <FormikField
                                className={cx('field--country')}
                                name={FieldsEnum.legalAddressCountryCode}
                                error={formik.errors[FieldsEnum.legalAddressCountryCode]}
                                meta={formik.getFieldMeta(FieldsEnum.legalAddressCountryCode)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <CountryDropdown
                                        value={formik.values[FieldsEnum.legalAddressCountryCode]}
                                        onChange={(countryCode) => {
                                            props.onChange(countryCode);
                                        }}
                                        hasChanges={props.hasChanges}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        testSelector="legal-address"
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--zip')}
                                name={FieldsEnum.legalAddressZipCode}
                                error={formik.errors[FieldsEnum.legalAddressZipCode]}
                                meta={formik.getFieldMeta(FieldsEnum.legalAddressZipCode)}
                                label={t('common:profile-page.basic-information.field-labels.zip-code')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.legalAddressZipCode}
                                        value={formik.values[FieldsEnum.legalAddressZipCode]}
                                        placeholder={getZipCodePlaceholder(
                                            formik.values[FieldsEnum.legalAddressCountryCode],
                                        )}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--city')}
                                name={FieldsEnum.legalAddressCity}
                                error={formik.errors[FieldsEnum.legalAddressCity]}
                                meta={formik.getFieldMeta(FieldsEnum.legalAddressCity)}
                                label={t('common:profile-page.basic-information.field-labels.city')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.legalAddressCity}
                                        value={formik.values[FieldsEnum.legalAddressCity]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            className={cx('field--street1')}
                            name={FieldsEnum.legalAddressStreet1}
                            error={formik.errors[FieldsEnum.legalAddressStreet1]}
                            meta={formik.getFieldMeta(FieldsEnum.legalAddressStreet1)}
                            label={t('common:profile-page.basic-information.field-labels.street1')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isAlwaysShowError={!isShowLoader}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.legalAddressStreet1}
                                    value={formik.values[FieldsEnum.legalAddressStreet1]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--street2')}
                            name={FieldsEnum.legalAddressStreet2}
                            error={formik.errors[FieldsEnum.legalAddressStreet2]}
                            meta={formik.getFieldMeta(FieldsEnum.legalAddressStreet2)}
                            label={t('common:profile-page.basic-information.field-labels.street2')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isAlwaysShowError={!isShowLoader}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.legalAddressStreet2}
                                    value={formik.values[FieldsEnum.legalAddressStreet2]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                    </div>
                </Widget>
                <Widget
                    className={cx('widget')}
                    title={t('common:profile-page.basic-information.sections.postal-address')}
                    rightNode={
                        <>
                            <WidgetChangesDetector
                                fields={[
                                    FieldsEnum.postalAddressCountryCode,
                                    FieldsEnum.postalAddressZipCode,
                                    FieldsEnum.postalAddressCity,
                                    FieldsEnum.postalAddressStreet1,
                                    FieldsEnum.postalAddressStreet2,
                                ]}
                                defaultNotification={
                                    company?.postalAddress ? (
                                        <VerificationStatus isVerified={!!company?.postalAddressVerified} />
                                    ) : null
                                }
                            />
                            <Tooltip
                                position={TooltipPositionEnum.topCenter}
                                theme={TooltipThemeEnum.black}
                                className={cx('copy__wrap')}
                                tooltipNode={
                                    <TooltipContent isCenter width={115} theme={TooltipContentThemeEnum.black}>
                                        {t('common:profile-page.basic-information.tooltips.copy-from-legal-address')}
                                    </TooltipContent>
                                }
                            >
                                {(isShow) => (
                                    <IconTrigger isFocused={isShow} onClick={handleCopyPostalAddress}>
                                        {() => (
                                            <CopyIcon className={cx('copy')} strokeColor={StyleGuideColorsEnum.light} />
                                        )}
                                    </IconTrigger>
                                )}
                            </Tooltip>
                        </>
                    }
                >
                    <div className={cx('widget__inner')}>
                        <FieldGroup spaces={1}>
                            <FormikField
                                className={cx('field--country')}
                                name={FieldsEnum.postalAddressCountryCode}
                                error={formik.errors[FieldsEnum.postalAddressCountryCode]}
                                meta={formik.getFieldMeta(FieldsEnum.postalAddressCountryCode)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <CountryDropdown
                                        value={formik.values[FieldsEnum.postalAddressCountryCode]}
                                        onChange={(countryCode) => {
                                            props.onChange(countryCode);
                                        }}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        hasChanges={props.hasChanges}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        testSelector="postal-address"
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--zip')}
                                name={FieldsEnum.postalAddressZipCode}
                                error={formik.errors[FieldsEnum.postalAddressZipCode]}
                                meta={formik.getFieldMeta(FieldsEnum.postalAddressZipCode)}
                                label={t('common:profile-page.basic-information.field-labels.zip-code')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.postalAddressZipCode}
                                        value={formik.values[FieldsEnum.postalAddressZipCode]}
                                        placeholder={getZipCodePlaceholder(
                                            formik.values[FieldsEnum.postalAddressCountryCode],
                                        )}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--city')}
                                name={FieldsEnum.postalAddressCity}
                                error={formik.errors[FieldsEnum.postalAddressCity]}
                                meta={formik.getFieldMeta(FieldsEnum.postalAddressCity)}
                                label={t('common:profile-page.basic-information.field-labels.city')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.postalAddressCity}
                                        value={formik.values[FieldsEnum.postalAddressCity]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            className={cx('field--street1')}
                            name={FieldsEnum.postalAddressStreet1}
                            error={formik.errors[FieldsEnum.postalAddressStreet1]}
                            meta={formik.getFieldMeta(FieldsEnum.postalAddressStreet1)}
                            label={t('common:profile-page.basic-information.field-labels.street1')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isAlwaysShowError={!isShowLoader}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.postalAddressStreet1}
                                    value={formik.values[FieldsEnum.postalAddressStreet1]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--street2')}
                            name={FieldsEnum.postalAddressStreet2}
                            error={formik.errors[FieldsEnum.postalAddressStreet2]}
                            meta={formik.getFieldMeta(FieldsEnum.postalAddressStreet2)}
                            label={t('common:profile-page.basic-information.field-labels.street2')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isAlwaysShowError={!isShowLoader}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.postalAddressStreet2}
                                    value={formik.values[FieldsEnum.postalAddressStreet2]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                    </div>
                </Widget>
                {shouldShowBillingInfo && (
                    <Widget
                        className={cx('widget')}
                        title={t('common:profile-page.basic-information.sections.billing-address')}
                        rightNode={
                            <>
                                <WidgetChangesDetector
                                    fields={[
                                        FieldsEnum.billingAddressCountryCode,
                                        FieldsEnum.billingAddressZipCode,
                                        FieldsEnum.billingAddressCity,
                                        FieldsEnum.billingAddressStreet1,
                                        FieldsEnum.billingAddressStreet2,
                                        FieldsEnum.billingEmail,
                                    ]}
                                    defaultNotification={
                                        company?.billingAddress ? (
                                            <VerificationStatus isVerified={!!company?.billingAddressVerified} />
                                        ) : null
                                    }
                                />
                                <Tooltip
                                    position={TooltipPositionEnum.topCenter}
                                    theme={TooltipThemeEnum.black}
                                    className={cx('copy__wrap')}
                                    tooltipNode={
                                        <TooltipContent isCenter width={115} theme={TooltipContentThemeEnum.black}>
                                            {t(
                                                'common:profile-page.basic-information.tooltips.copy-from-legal-address',
                                            )}
                                        </TooltipContent>
                                    }
                                >
                                    {(isShow) => (
                                        <IconTrigger isFocused={isShow} onClick={handleCopyBillingAddress}>
                                            {() => (
                                                <CopyIcon
                                                    className={cx('copy')}
                                                    strokeColor={StyleGuideColorsEnum.light}
                                                />
                                            )}
                                        </IconTrigger>
                                    )}
                                </Tooltip>
                            </>
                        }
                    >
                        <div className={cx('widget__inner')}>
                            <FieldGroup spaces={1}>
                                <FormikField
                                    className={cx('field--country')}
                                    name={FieldsEnum.billingAddressCountryCode}
                                    error={formik.errors[FieldsEnum.billingAddressCountryCode]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingAddressCountryCode)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    isAlwaysShowError={!isShowLoader}
                                >
                                    {(props) => (
                                        <CountryDropdown
                                            value={formik.values[FieldsEnum.billingAddressCountryCode]}
                                            onChange={(countryCode) => {
                                                props.onChange(countryCode);
                                            }}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                            hasChanges={props.hasChanges}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            testSelector="billing-address"
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--zip')}
                                    name={FieldsEnum.billingAddressZipCode}
                                    error={formik.errors[FieldsEnum.billingAddressZipCode]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingAddressZipCode)}
                                    label={t('common:profile-page.basic-information.field-labels.zip-code')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    isAlwaysShowError={!isShowLoader}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.billingAddressZipCode}
                                            value={formik.values[FieldsEnum.billingAddressZipCode]}
                                            placeholder={getZipCodePlaceholder(
                                                formik.values[FieldsEnum.billingAddressCountryCode],
                                            )}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--city')}
                                    name={FieldsEnum.billingAddressCity}
                                    error={formik.errors[FieldsEnum.billingAddressCity]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingAddressCity)}
                                    label={t('common:profile-page.basic-information.field-labels.city')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    isAlwaysShowError={!isShowLoader}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.billingAddressCity}
                                            value={formik.values[FieldsEnum.billingAddressCity]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                            <FormikField
                                className={cx('field--street1')}
                                name={FieldsEnum.billingAddressStreet1}
                                error={formik.errors[FieldsEnum.billingAddressStreet1]}
                                meta={formik.getFieldMeta(FieldsEnum.billingAddressStreet1)}
                                label={t('common:profile-page.basic-information.field-labels.street1')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.billingAddressStreet1}
                                        value={formik.values[FieldsEnum.billingAddressStreet1]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--street2')}
                                name={FieldsEnum.billingAddressStreet2}
                                error={formik.errors[FieldsEnum.billingAddressStreet2]}
                                meta={formik.getFieldMeta(FieldsEnum.billingAddressStreet2)}
                                label={t('common:profile-page.basic-information.field-labels.street2')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.billingAddressStreet2}
                                        value={formik.values[FieldsEnum.billingAddressStreet2]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--billing-email')}
                                name={FieldsEnum.billingEmail}
                                error={formik.errors[FieldsEnum.billingEmail]}
                                meta={formik.getFieldMeta(FieldsEnum.billingEmail)}
                                label={t('common:profile-page.basic-information.field-labels.billing-email')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isAlwaysShowError={!isShowLoader}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.billingEmail}
                                        value={formik.values[FieldsEnum.billingEmail]}
                                        placeholder=""
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </Widget>
                )}
                <HiddenSubmitButtonForKeyboardEnter />
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            </form>
            <ChangeVerifiedAddressConfirmation
                requestStatus={updateCompanyRequestStatus}
                data={confirmChangeAddressConfirmationData.data}
                company={company}
                onClose={confirmChangeAddressConfirmationData.onClose}
                onCancel={confirmChangeAddressConfirmationData.onCancel}
                onConfirm={(company, companyChanges) => {
                    dispatch(updateCompany(company, companyChanges));
                }}
            />
        </div>
    );
});

export default ProfileBasicInformation;
