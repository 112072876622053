import React from 'react';
import unset from 'lodash/unset';

export type UseAsyncFormErrorsT<AsyncErrorsT = {}> = {
    asyncFormErrors: AsyncErrorsT;
    resetAsyncFormErrors: (fieldName: string) => void;
};

const useAsyncFormErrors = <T extends {}>(asyncErrors: T): UseAsyncFormErrorsT<T> => {
    const [asyncFormErrors, setAsyncFormErrors] = React.useState<T>(asyncErrors);

    React.useEffect(() => {
        setAsyncFormErrors(asyncErrors);
    }, [asyncErrors]);

    const resetAsyncFormErrors = React.useCallback((fieldName: string) => {
        const newAsyncFormErrors = { ...asyncFormErrors };

        // for remove nested fields
        unset(newAsyncFormErrors, fieldName);

        setAsyncFormErrors(newAsyncFormErrors);
    }, []);

    return {
        asyncFormErrors,
        resetAsyncFormErrors,
    };
};

export default useAsyncFormErrors;
