import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Divider.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
};

const Divider: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    return <div className={cs(cx('divider'), className)} data-test-selector="divider" />;
});

export default Divider;
