import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './IconTrigger.scss';

const cx = classNames.bind(styles);

export enum IconTriggerThemeEnum {
    primary = 'primary',
    secondary = 'secondary',
    danger = 'danger',
    transparent = 'transparent',
}

export type IconMetaT = {
    isClickable: boolean;
    isDisabled: boolean;
    isToggled: boolean;
    isFocused: boolean;
    isPressed: boolean;
};

export type PropsT = {
    className?: string;
    onClick?: () => void;
    isDisabled?: boolean;
    isToggled?: boolean;
    isFocused?: boolean;
    isPressed?: boolean;
    theme?: IconTriggerThemeEnum;
    children: (meta: IconMetaT) => React.ReactNode;
};

const IconTrigger: React.FC<PropsT> = (props) => {
    const { className, children, onClick, isDisabled, isToggled, isFocused, isPressed, theme } = props;

    const isClickable = !!onClick;

    return (
        <div
            className={cs(
                cx('icon-trigger', {
                    [`icon-trigger--theme-${theme || IconTriggerThemeEnum.transparent}`]: true,
                    'icon-trigger--isClickable': isClickable,
                    'icon-trigger--isDisabled': isDisabled,
                    'icon-trigger--isToggled': isToggled,
                    'icon-trigger--isFocused': isFocused,
                    'icon-trigger--isPressed': isPressed,
                }),
                className,
            )}
            onClick={onClick}
            data-test-selector="icon-trigger"
        >
            {children({
                isClickable,
                isDisabled: !!isDisabled,
                isToggled: !!isToggled,
                isFocused: !!isFocused,
                isPressed: !!isPressed,
            })}
        </div>
    );
};

export default IconTrigger;
