import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './SubjectLink.scss';

const cx = classNames.bind(styles);

type PropsT = {
    number: React.ReactNode;
};

const SubjectLink: React.FC<PropsT> = React.memo((props) => {
    const { number } = props;

    return <span className={cx('subject-link')}>{number}</span>;
});

export default SubjectLink;
