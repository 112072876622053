import * as React from 'react';

import AuthLayout from 'common/layouts/AuthLayout/AuthLayout';
import ContinueSignUpForm from './ContinueSignUpForm/ContinueSignUpForm';

import { QueryKeysEnum } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmailVerificationRequestStatus } from 'common/store/auth/selectors';
import { FieldsEnum, FormValuesT } from './ContinueSignUpForm/constants';

import classNames from 'classnames/bind';
import styles from './ContinueSignUpPage.scss';
import { continueSignUp } from 'common/store/auth/actions';

import useQuery from 'common/utils/hooks/useQuery';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';
import PageTitle from 'common/components/PageTitle/PageTitle';

const cx = classNames.bind(styles);

type QueryT = {
    [QueryKeysEnum.email]: string;
    [QueryKeysEnum.oobCode]: string;
    [QueryKeysEnum.returnUrl]: string;
};

type PropsT = {};

const ContinueSignUpPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const query = useQuery<QueryT>();

    const requestStatus = useSelector(selectEmailVerificationRequestStatus);

    const handleSubmit = (formValue: FormValuesT): void => {
        const oobCode = query[QueryKeysEnum.oobCode] || null;
        const email = query[QueryKeysEnum.email] || null;
        const returnUrl = query[QueryKeysEnum.returnUrl] || null;

        dispatch(
            continueSignUp(
                formValue[FieldsEnum.firstName],
                formValue[FieldsEnum.lastName],
                formValue[FieldsEnum.phone],
                formValue[FieldsEnum.password],
                oobCode,
                email,
                returnUrl,
            ),
        );
    };

    if (
        checkIsAuthApiError(requestStatus.error) &&
        requestStatus.error.type === AuthErrorTypeEnum.failEmailVerification
    ) {
        return (
            <AuthLayout title={t('common:email-verification.error.title')} testSelector="confirm-email-page">
                <div className={cx('content')}>{t('common:email-verification.error.text')}</div>
                <br />
                <br />
                <div className={cx('error')}>{requestStatus.error?.message}</div>
            </AuthLayout>
        );
    }

    return (
        <>
            <AuthLayout title={t('common:continue-sign-up.title')} testSelector="continue-sign-up-page">
                <PageTitle title={t('common:page-titles.continue-sign-up')} />
                <ContinueSignUpForm onSubmit={handleSubmit} requestStatus={requestStatus} />
            </AuthLayout>
        </>
    );
});

export default ContinueSignUpPage;
