import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { DictDocumentByIdT, DictDocumentByTypeT, DictDocumentT } from './models';
import { DocumentRequiredEnum, PartnerTypeT } from 'common/utils/api/models';
import { PartnerTypeDocumentsStateT } from 'common/store/documents-dict/types';
import { initialPartnerTypeDocumentsState } from './reducer';

const selectDocumentsState = (partnerType: PartnerTypeT | null, state: SharableStateT): PartnerTypeDocumentsStateT => {
    if (!partnerType) {
        return initialPartnerTypeDocumentsState;
    }

    return state.documentsDict[partnerType] || initialPartnerTypeDocumentsState;
};

export const selectDocumentsDict = (partnerType: PartnerTypeT | null) => {
    return (state: SharableStateT): DictDocumentT[] => {
        const { ids, byId } = selectDocumentsState(partnerType, state);

        return ids.map((id) => byId[id]);
    };
};

export const selectDocumentsDictById = (partnerType: PartnerTypeT | null) => {
    return (state: SharableStateT): DictDocumentByIdT => {
        return selectDocumentsState(partnerType, state).byId;
    };
};

export const selectDocumentsDictByType = (partnerType: PartnerTypeT | null) => {
    return (state: SharableStateT): DictDocumentByTypeT => {
        return selectDocumentsState(partnerType, state).byType;
    };
};

export const selectRequiredDocumentIds =
    (partnerType: PartnerTypeT | null) =>
    (state: SharableStateT): DictDocumentIdT[] => {
        const { ids, byId } = selectDocumentsState(partnerType, state);

        return ids.filter((documentId) => {
            return byId[documentId]?.required === DocumentRequiredEnum.must;
        });
    };

export const selectDocumentsDictRequest = (partnerType: PartnerTypeT | null) => {
    return (state: SharableStateT): RequestStatusT => {
        return selectDocumentsState(partnerType, state).fetchRequest;
    };
};
