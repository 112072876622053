import * as React from 'react';
import { TransportOrderStatusEnum, ApiTransportOrderStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiTransportOrderStatusT | null | undefined;
};

export const TRANSPORT_ORDER_STATUS_T_MAP: Record<ApiTransportOrderStatusT, string> = {
    [TransportOrderStatusEnum.assetsUnassigned]: 'transport-order-status.ASSETS_UNASSIGNED',
    [TransportOrderStatusEnum.driverUnassigned]: 'transport-order-status.DRIVER_UNASSIGNED',
    [TransportOrderStatusEnum.assigned]: 'transport-order-status.ASSIGNED',
    [TransportOrderStatusEnum.inTransit]: 'transport-order-status.IN_TRANSIT',
    [TransportOrderStatusEnum.done]: 'transport-order-status.DONE',
    [TransportOrderStatusEnum.canceled]: 'transport-order-status.CANCELED',
    [TransportOrderStatusEnum.declined]: 'transport-order-status.DECLINED',
    [TransportOrderStatusEnum.unknown]: 'transport-order-status.UNKNOWN',
};

const TransportOrderStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(TRANSPORT_ORDER_STATUS_T_MAP[status]);
});

export default TransportOrderStatus;
