import * as React from 'react';

import RadioGroup, { RadioGroupOptionT } from 'common/components/RadioGroup/RadioGroup';
import { DEDICATION_T, DedicationValuesEnum } from './constants';
import { useTranslation } from 'react-i18next';

type ValueT = DedicationValuesEnum | null;

type PropsT = {
    value: ValueT;
    onChange: (value: ValueT) => void;
    className?: string;
};

const AssetDedicationRadioGroup: React.FC<PropsT> = React.memo((props) => {
    const { className, value, onChange } = props;

    const { t } = useTranslation();

    const dedicationOptions = React.useMemo(
        (): RadioGroupOptionT<ValueT>[] => [
            {
                label: t('common:trailers-page.filters.all'),
                value: null,
            },
            {
                label: t(DEDICATION_T[DedicationValuesEnum.dedicated]),
                value: DedicationValuesEnum.dedicated,
            },
            {
                label: t(DEDICATION_T[DedicationValuesEnum.notDedicated]),
                value: DedicationValuesEnum.notDedicated,
            },
        ],
        [t],
    );

    return <RadioGroup className={className} selectedValue={value} options={dedicationOptions} onChange={onChange} />;
});

export default AssetDedicationRadioGroup;
