import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LoaderOverlay.scss';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size?: number;
    isTransparent?: boolean;
};

const cx = classNames.bind(styles);

const LoaderOverlay: React.FC<PropsT> = React.memo((props) => {
    const { size, isTransparent } = props;

    return (
        <div className={cx('loader-overlay')} data-test-selector="overlay">
            {isTransparent ? null : <div className={cx('overlay')} />}
            <div className={cx('inner')}>
                <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.gray} size={size} />
            </div>
        </div>
    );
});

export default LoaderOverlay;
