import { v4 as uuidv4 } from 'uuid';

export type AnyAlertPayloadT<T = string, D = any> = {
    type: T;
    data: D;
};

export class AnyAlert<P = AnyAlertPayloadT> {
    id: AlertIdT;

    payload: P;

    constructor(payload: P) {
        this.id = uuidv4();
        this.payload = payload;
    }
}
