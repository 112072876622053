import * as React from 'react';

type PropsT = {};

const EditIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill="#8ABA2E" fillOpacity="0.3" d="M4.75 14.56v.69h.69l6.795-6.795-.69-.69z" />
                <path
                    fill="#4B6518"
                    d="M16.533 6.28a.748.748 0 000-1.058l-1.755-1.755c-.15-.15-.338-.217-.533-.217s-.382.075-.525.217L12.347 4.84l2.813 2.813 1.373-1.373zM3.25 13.937v2.813h2.813l8.294-8.295-2.812-2.813-8.295 8.296zm2.19 1.313h-.69v-.69l6.795-6.795.69.69L5.44 15.25z"
                />
            </g>
        </svg>
    );
};

EditIcon.displayName = 'EditIcon';

const storyProps: PropsT = {};

export { storyProps };
export default EditIcon;
