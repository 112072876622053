import requestStatus from 'common/utils/request-status';
import {
    CarrierContractDetailsActionT,
    CarrierContractsDetailStateT,
    CarrierContractStateT,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR,
    FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCarrierContractState: CarrierContractStateT = {
    data: null,
    fetchRequest: requestStatus.initial,
};

const initialState: CarrierContractsDetailStateT = {};

export default (
    state = initialState,
    action: CarrierContractDetailsActionT | DestroySessionActionT,
): CarrierContractsDetailStateT => {
    switch (action.type) {
        case FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN: {
            const { contractId } = action;
            const carrierContractState = state[contractId] || initialCarrierContractState;

            return {
                ...state,
                [contractId]: {
                    ...carrierContractState,
                    fetchRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR: {
            const { contractId, error } = action;
            const carrierContractState = state[contractId] || initialCarrierContractState;

            return {
                ...state,
                [contractId]: {
                    ...carrierContractState,
                    fetchRequest: requestStatus.setError(error),
                },
            };
        }

        case FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS: {
            const { contractId, contractDetails } = action;
            const carrierContractState = state[contractId] || initialCarrierContractState;

            return {
                ...state,
                [contractId]: {
                    ...carrierContractState,
                    data: contractDetails,
                    fetchRequest: requestStatus.ok,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
