import { RequestStatusT } from 'common/utils/request-status';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import { CloseCallbackOptionsT } from 'common/layouts/SideBars/models';

type CloseCallbackT = (options?: CloseCallbackOptionsT) => void;

const useCloseSidebarAfterRequest = (
    requestStatus: RequestStatusT | null | undefined,
    closeCallback: CloseCallbackT | null | undefined,
) => {
    useOnlyChangesEffect(() => {
        if (requestStatus?.ok && closeCallback) {
            closeCallback({ needCloseConfirmation: false });
        }
    }, [requestStatus]);
};

export default useCloseSidebarAfterRequest;
