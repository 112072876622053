import React from 'react';

import { ArrayHelpers, FieldArray, FormikProps, useFormikContext } from 'formik';
import get from 'lodash/get';

export type ChildrenPropsT<FormValuesT, FieldValueT> = OverwriteT<
    ArrayHelpers,
    {
        push: (obj: FieldValueT) => void;
        insert: (index: number, value: FieldValueT) => void;
        replace: (index: number, value: FieldValueT) => void;
        unshift: (value: FieldValueT) => number;
    }
> & {
    form: FormikProps<FormValuesT>;
};

export type PropsT<TFieldValues extends FieldValues, TFieldPath extends FieldPath<TFieldValues>> = {
    name: TFieldPath;
    children: (
        values: FieldPathValue<TFieldValues, TFieldPath>,
        props: ChildrenPropsT<TFieldValues, FieldPathValue<TFieldValues, TFieldPath>[number]>,
    ) => React.ReactElement | null;
};

const FormikFieldArray = <TFieldValues extends FieldValues, TFieldPath extends FieldPath<TFieldValues>>(
    props: PropsT<TFieldValues, TFieldPath>,
) => {
    const { name, children } = props;

    const formik = useFormikContext<TFieldValues>();
    const values = get(formik.values, name);

    return <FieldArray name={name} render={(props) => children(values, props)} />;
};

export default React.memo(FormikFieldArray) as typeof FormikFieldArray;
