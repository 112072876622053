import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
    fillColor: StyleGuideColorsEnum;
};

const LayoverIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={fillColor} d="M12.7 14.631v1H7.3v-1h5.4zm0-9.031v1.331l-2.7 2.7-2.7-2.7V5.6h5.4z" />
                <path
                    fill={strokeColor}
                    d="M13.6 3.7H6.4v3.6L9.1 10l-2.7 2.7v3.6h7.2v-3.6L10.9 10l2.7-2.7V3.7zm-.9 9.369V15.4H7.3v-2.331l2.7-2.7 2.7 2.7zm0-6.138l-2.7 2.7-2.7-2.7V4.6h5.4v2.331z"
                />
            </g>
        </svg>
    );
};

LayoverIcon.displayName = 'LayoverIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default LayoverIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getLayoverIconProps = (preset: 'regular'): Pick<PropsT, 'strokeColor' | 'fillColor'> => {
    switch (preset) {
        case 'regular':
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
