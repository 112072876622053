import { PriceOfferT } from 'common/store/order-creation/models';

export const getOffersDiff = (
    priceOffer: PriceOfferT | null,
    basePriceOffer: PriceOfferT | null,
): { co2: number } | null => {
    if (!priceOffer || !basePriceOffer) {
        return null;
    }

    return {
        co2: +(priceOffer.co2 && basePriceOffer?.co2 ? basePriceOffer.co2 - priceOffer.co2 : 0).toFixed(2),
    };
};
