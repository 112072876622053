import {
    FIND_SHIPPERS_REQUEST_BY_QUERY,
    FIND_SHIPPERS_REQUEST_BEGIN,
    FIND_SHIPPERS_REQUEST_ERROR,
    FIND_SHIPPERS_REQUEST_SUCCESS,
    FindShippersByQueryActionT,
    FindShippersBeginActionT,
    FindShippersErrorActionT,
    FindShippersSuccessActionT,
    RESET,
    ResetActionT,
    FindShippersByIdActionT,
    FIND_SHIPPERS_REQUEST_BY_ID,
} from './types';

import { FindShippersQueryT, PartnerT } from './models';

export const findShippersByQuery = (query: FindShippersQueryT): FindShippersByQueryActionT => ({
    type: FIND_SHIPPERS_REQUEST_BY_QUERY,
    query,
});

export const findShippersById = (partnerId: PartnerIdT, query: FindShippersQueryT): FindShippersByIdActionT => ({
    type: FIND_SHIPPERS_REQUEST_BY_ID,
    partnerId,
    query,
});

export const findShippersBegin = (query: FindShippersQueryT): FindShippersBeginActionT => ({
    type: FIND_SHIPPERS_REQUEST_BEGIN,
    query,
});

export const findShippersSuccess = (partners: PartnerT[]): FindShippersSuccessActionT => ({
    type: FIND_SHIPPERS_REQUEST_SUCCESS,
    partners,
});

export const findShippersError = (error: Error): FindShippersErrorActionT => ({
    type: FIND_SHIPPERS_REQUEST_ERROR,
    error,
});

export const reset = (): ResetActionT => ({
    type: RESET,
});
