import { FetchOrdersPageQueryT, OrderT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_ORDERS_PAGE_REQUEST = 'common/orders/FETCH_ORDERS_PAGE_REQUEST';
export const FETCH_ORDERS_PAGE_REQUEST_BEGIN = 'common/orders/FETCH_ORDERS_PAGE_REQUEST_BEGIN';
export const FETCH_ORDERS_PAGE_REQUEST_ERROR = 'common/orders/FETCH_ORDERS_PAGE_REQUEST_ERROR';
export const FETCH_ORDERS_PAGE_REQUEST_SUCCESS = 'common/orders/FETCH_ORDERS_PAGE_REQUEST_SUCCESS';
export const RESET_ORDERS = 'common/orders/RESET_ORDERS';

export type OrdersStateT = PaginationStateT<OrderT, FetchOrdersPageQueryT>;

export type FetchOrdersPageActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchOrdersPageQueryT;
    options?: FetchOptionsT;
};

export type FetchOrdersPageBeginActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_BEGIN;
    query: FetchOrdersPageQueryT;
    pageNumber: PageNumberT;
};

export type FetchOrdersPageSuccessActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_SUCCESS;
    query: FetchOrdersPageQueryT;
    pageResponse: PageResponseT<OrderT> | null;
    pageNumber: PageNumberT;
};

export type FetchOrdersPageErrorActionT = {
    type: typeof FETCH_ORDERS_PAGE_REQUEST_ERROR;
    query: FetchOrdersPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetOrdersActionT = {
    type: typeof RESET_ORDERS;
    mutationListOptions: MutationListOptionsT;
};

export type OrdersActionT =
    | FetchOrdersPageActionT
    | FetchOrdersPageBeginActionT
    | FetchOrdersPageSuccessActionT
    | FetchOrdersPageErrorActionT
    | ResetOrdersActionT;
