import { DriverStatusEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    name = 'name',
    excludedDriversStatus = 'excludedDriversStatus',
}

export type FormValuesT = {
    [FieldsEnum.name]: string;
    [FieldsEnum.excludedDriversStatus]: DriverStatusEnum[];
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
