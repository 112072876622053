import { FieldsEnum, FormValuesT } from './constants';

export type FormDataT = {
    reason: string;
};

const prepareData = (formValues: FormValuesT): FormDataT => {
    return {
        reason: formValues[FieldsEnum.reason],
    };
};

export { prepareData };
