import { eventChannel } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import isNil from 'lodash/isNil';

import {
    CONTINUE_SIGN_UP_REQUEST,
    ContinueSignUpActionT,
    EMAIL_VERIFICATION_REQUEST,
    EmailVerificationActionT,
    RESET_PASSWORD_REQUEST,
    ResetPasswordActionT,
    SIGN_IN_REQUEST,
    SIGN_OUT_REQUEST,
    SIGN_UP_REQUEST,
    SignInActionT,
    SignUpActionT,
    UPDATE_PASSWORD_REQUEST,
    UpdatePasswordActionT,
} from './types';
import {
    destroySession,
    emailVerificationBegin,
    emailVerificationError,
    emailVerificationSuccess,
    resetPasswordBegin,
    resetPasswordError,
    resetPasswordSuccess,
    setUser,
    signInBegin,
    signInError,
    signInSuccess,
    signOutBegin,
    signOutError,
    signOutSuccess,
    signUpBegin,
    signUpError,
    signUpSuccess,
    updatePasswordBegin,
    updatePasswordError,
    updatePasswordSuccess,
} from './actions';
import { authApi } from 'common/utils/api/auth/factory';
import history, { goToPossiblyExternalUrl } from 'common/utils/history';
import { authRoutesEnum, CommonRoutesEnum, CompanyTypeEnum, externalRoutesSet, QueryKeysEnum } from '../../constants';

import { getAuthReturnUrl, parseAuthReturnUrl } from 'common/utils/auth-return-url';
import { logWarning } from 'common/utils/logger';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { AuthApiError, AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';
import i18n from 'i18next';
import moment from 'moment';
import { checkRegisteredUser } from 'common/store/auth/selectors';
import { forceRefreshCurrentUserSaga } from '../user/sagas';
import { ApiCompleteRegistrationT, CountryCodeT } from 'common/utils/api/models';
import { AuthUserClaimsT, AuthUserT } from 'common/utils/api/auth/base-auth-service';
import { formatQuery } from 'common/utils/query';
import { SEC_IN_MIN } from 'common/utils/time';

const companyTypes: Array<string> = Object.values(CompanyTypeEnum);

function* continueSignInErrorSaga(error: Error) {
    yield put(emailVerificationError(error));
    yield authApi.doSignOut();
}

type ProtectedSignInResultT = {
    user: AuthUserT;
    userClaims: AuthUserClaimsT | null;
};

function* checkPasswordSaga(email: string, password: string): WrapGeneratorT<[AuthApiError | null, AuthUserT | null]> {
    return yield authApi.signInWithEmailAndPassword(email, password);
}

function* protectedSignInSaga(
    companyType: CompanyTypeEnum,
    email: string,
    password: string,
): WrapGeneratorT<[AuthApiError | null, ProtectedSignInResultT | null]> {
    const [signInError, signInResult]: ReturnApiT<typeof authApi.signInWithEmailAndPassword> =
        yield authApi.signInWithEmailAndPassword(email, password);

    if (signInError) {
        return [signInError, null];
    }

    if (!signInResult) {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'empty signInResult'), null];
    }

    const [userClaimsError, userClaimsResult]: ReturnApiT<typeof authApi.getAuthUserClaims> =
        yield authApi.getAuthUserClaims();

    if (userClaimsError) {
        return [userClaimsError, null];
    }

    if (!userClaimsResult) {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'empty idTokenResult'), null];
    }

    const roles = userClaimsResult?.roles || [];
    const hasRequiredRole = roles.includes(companyType);
    if (!hasRequiredRole) {
        const authError = new AuthApiError(AuthErrorTypeEnum.wrongRole, 'wrong role');
        return [authError, null];
    }

    return [
        null,
        {
            user: signInResult,
            userClaims: userClaimsResult,
        },
    ];
}

function getContinueSignInSaga(companyType: CompanyTypeEnum) {
    return function* continueSignInSaga(action: ContinueSignUpActionT) {
        const { firstName, lastName, phoneNumber, password, oobCode, email, returnUrl } = action;

        const isRegisteredUser: ReturnType<typeof checkRegisteredUser> = yield select(checkRegisteredUser);

        yield put(emailVerificationBegin());

        if (!isRegisteredUser && email && password) {
            const [checkPasswordError] = yield* checkPasswordSaga(email, password);
            if (checkPasswordError) {
                yield continueSignInErrorSaga(checkPasswordError);
                return;
            }
        }

        if (oobCode) {
            const [verificationError]: ReturnApiT<typeof authApi.verificationEmail> = yield authApi.verificationEmail(
                oobCode,
            );
            if (verificationError) {
                yield continueSignInErrorSaga(verificationError);
                return;
            }
        }

        if (!isRegisteredUser && email && password) {
            const [signInApiError, signInResult] = yield* protectedSignInSaga(companyType, email, password);
            if (signInApiError?.type === AuthErrorTypeEnum.wrongRole) {
                history.push(CommonRoutesEnum.accessDenied);
                return;
            }
            if (signInApiError) {
                yield continueSignInErrorSaga(signInApiError);
                return;
            }

            if (!signInResult) {
                const error = new AuthApiError(AuthErrorTypeEnum.unknown, 'empty signInResult');
                yield continueSignInErrorSaga(error);
                return;
            }

            yield put(signInSuccess(signInResult.user, signInResult.userClaims));
        }

        const [currentUserError, currentUser]: ReturnApiT<typeof commonTranziitApi.fetchCurrentUser> =
            yield commonTranziitApi.fetchCurrentUser();
        if (currentUserError) {
            yield continueSignInErrorSaga(currentUserError);
            return;
        }

        if (!currentUser) {
            const error = new AuthApiError(AuthErrorTypeEnum.unknown, 'empty currentUser');
            yield continueSignInErrorSaga(error);
            return;
        }

        const defaultTimeZoneSec = moment().utcOffset() * SEC_IN_MIN;

        const patchedApiUserUpdate: ApiCompleteRegistrationT = {
            language: i18n.language,
            name: firstName,
            phone: phoneNumber,
            surname: lastName,
            timezone: currentUser.timezone || defaultTimeZoneSec,
        };
        const [userPatchError]: ReturnApiT<typeof commonTranziitApi.completeUserRegistration> =
            yield commonTranziitApi.completeUserRegistration(patchedApiUserUpdate);
        if (userPatchError) {
            yield continueSignInErrorSaga(userPatchError);
            return;
        }

        yield put(emailVerificationSuccess());

        yield forceRefreshCurrentUserSaga();

        history.push(parseAuthReturnUrl(returnUrl));
    };
}

function getSignInSaga(companyType: CompanyTypeEnum) {
    return function* signInSaga(action: SignInActionT) {
        const { email, password, returnUrl } = action;

        yield put(signInBegin());

        const [signInApiError, signInResult] = yield* protectedSignInSaga(companyType, email, password);

        if (signInApiError?.type === AuthErrorTypeEnum.wrongRole) {
            history.push(CommonRoutesEnum.accessDenied);
            return;
        }

        if (signInApiError) {
            yield put(signInError(signInApiError));
            yield authApi.doSignOut();
            return;
        }

        if (!signInResult) {
            const error = new AuthApiError(AuthErrorTypeEnum.unknown, 'empty signInResult');
            yield put(signInError(error));
            return;
        }

        yield put(signInSuccess(signInResult.user, signInResult.userClaims));

        history.push(parseAuthReturnUrl(returnUrl));
    };
}

const authUserStateChangeChannel = () =>
    eventChannel((emit) => {
        return authApi.onAuthStateChanged((user) => {
            emit({ user });
        });
    });

export function* reAuthSaga(): WrapGeneratorT<void> {
    const [, anonymouslyUser]: ReturnApiT<typeof authApi.createAnonymouslyUser> = yield authApi.createAnonymouslyUser();

    if (anonymouslyUser) {
        yield put(setUser(anonymouslyUser, null));
    }

    const isContinueSignInRedirect = authApi.checkIsContinueSignInRedirect();
    if (isContinueSignInRedirect) {
        return;
    }

    const shouldRedirectToSignIn = !externalRoutesSet.has(history.location.pathname);
    if (shouldRedirectToSignIn) {
        authApi.destroySession();

        const continueReturnUrl = getAuthReturnUrl();
        const signInUrl = authApi.createSignInUrl(continueReturnUrl);
        goToPossiblyExternalUrl(signInUrl);
    }
}

function getAuthWatch(companyType: CompanyTypeEnum) {
    return function* authWatch(): WrapGeneratorT<void> {
        const userChannel = yield call(authUserStateChangeChannel);
        let { user } = yield take(userChannel);

        let [userClaimsError, userClaimsResult]: ReturnApiT<typeof authApi.getAuthUserClaims> =
            yield authApi.getAuthUserClaims();
        if (userClaimsError) {
            yield put(signInError(userClaimsError));
        }

        const roles = userClaimsResult?.roles;
        const isNotValidRole = !isNil(roles) && !roles.includes(companyType);
        if (isNotValidRole) {
            yield put(setUser(user, userClaimsResult));

            const hasAnyRole = !!roles.find((role) => {
                return companyTypes.includes(role);
            });

            if (hasAnyRole) {
                history.push(CommonRoutesEnum.accessDenied);
            } else {
                history.push({
                    pathname: authRoutesEnum.selfCreateCompany,
                    search: formatQuery({
                        [QueryKeysEnum.returnUrl]: getAuthReturnUrl(),
                    }),
                });
            }
            return;
        }

        const isNotValidUser = user && user.isAnonymous;
        if (isNotValidUser || !user) {
            yield reAuthSaga();
        }

        if (!user || isNotValidRole) {
            const [error, anonymouslyUser]: ReturnApiT<typeof authApi.createAnonymouslyUser> =
                yield authApi.createAnonymouslyUser();
            if (error) {
                yield put(signInError(error));
            }
            const result: ReturnApiT<typeof authApi.getAuthUserClaims> = yield authApi.getAuthUserClaims();
            [userClaimsError, userClaimsResult] = result;
            user = anonymouslyUser;
        }

        if (!userClaimsResult) {
            logWarning('empty idTokenResult');
            return;
        }

        yield put(setUser(user, userClaimsResult));
    };
}

function* signOutSaga(): WrapGeneratorT<void> {
    yield put(signOutBegin());
    const [apiError]: ReturnApiT<typeof authApi.doSignOut> = yield authApi.doSignOut();
    if (apiError) {
        yield put(signOutError(apiError));
    }
    const [error, anonymouslyUser]: ReturnApiT<typeof authApi.createAnonymouslyUser> =
        yield authApi.createAnonymouslyUser();
    if (error) {
        yield put(signOutError(error));
    }
    if (anonymouslyUser) {
        yield put(setUser(anonymouslyUser, null));
    }
    yield put(signOutSuccess());

    authApi.destroySession();
    history.push(authApi.createSignInUrl());
    yield put(destroySession());
}

function* resetPasswordSaga(action: ResetPasswordActionT): WrapGeneratorT<void> {
    yield put(resetPasswordBegin());
    const { email } = action;
    const origin = `${window.location.protocol}//${window.location.hostname}`;
    const [error]: ReturnApiT<typeof commonTranziitApi.forgotPassword> = yield commonTranziitApi.forgotPassword(
        email,
        origin,
    );
    if (error) {
        yield put(resetPasswordError(error));
    } else {
        yield put(resetPasswordSuccess());
    }
}

function* updatePasswordSaga(action: UpdatePasswordActionT): WrapGeneratorT<void> {
    yield put(updatePasswordBegin());
    const { password, oobCode } = action;
    const [error]: ReturnApiT<typeof authApi.updatePassword> = yield authApi.updatePassword(oobCode, password);
    if (error) {
        yield put(updatePasswordError(error));
    } else {
        yield put(updatePasswordSuccess());
    }
}

const getDefaultTimeZone = (): number => moment().utcOffset();

function getSignUpSaga(companyType: CompanyTypeEnum) {
    return function* signUpSaga(action: SignUpActionT): WrapGeneratorT<void> {
        yield put(signUpBegin());

        const { params } = action;

        const [apiError]: ReturnApiT<typeof commonTranziitApi.createUser> = yield commonTranziitApi.createUser({
            name: params.firstName,
            surname: params.lastName,
            email: params.email,
            password: params.password,
            companyName: params.companyName,
            // TODO backend task https://tranziit.atlassian.net/browse/TZT-2854
            timezone: getDefaultTimeZone(),
            dictLegalFormId: params.legalFormId || undefined,
            phoneNumber: params.phone,
            companyType,
            address: {
                city: params.city,
                country: params.countryCode as CountryCodeT,
                street1: params.street1,
                zipCode: params.zipCode,
            },
            language: i18n.language,
        });

        if (apiError) {
            yield put(signUpError(apiError));
            return;
        }

        /*
        const [authApiError, user]: ReturnApiT<typeof authApi.signInWithEmailAndPassword> =
            yield authApi.signInWithEmailAndPassword(params.email, params.password);

        yield authApi.sendVerificationEmail(user);
        yield authApi.doSignOut();
        */
        yield put(signUpSuccess());
    };
}

function* emailVerificationSaga(action: EmailVerificationActionT): WrapGeneratorT<void> {
    const { code } = action;

    yield put(emailVerificationBegin());

    const [error]: ReturnApiT<typeof authApi.verificationEmail> = yield authApi.verificationEmail(code);
    if (error) {
        yield put(emailVerificationError(error));
    } else {
        yield put(emailVerificationSuccess());
    }
}

function* authSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(SIGN_IN_REQUEST, getSignInSaga(companyType));
    yield takeEvery(CONTINUE_SIGN_UP_REQUEST, getContinueSignInSaga(companyType));
    yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordSaga);
    yield takeEvery(UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
    yield takeEvery(SIGN_OUT_REQUEST, signOutSaga);
    yield takeEvery(SIGN_UP_REQUEST, getSignUpSaga(companyType));
    yield takeEvery(EMAIL_VERIFICATION_REQUEST, emailVerificationSaga);
    yield call(getAuthWatch(companyType));
}

export default authSaga;
