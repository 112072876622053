import { put, select, takeEvery } from 'redux-saga/effects';
import {
    APPROVE_CARRIER_CONTRACT_REQUEST,
    ApproveCarrierContractActionT,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST,
    DownloadCarrierContractActionT,
    FETCH_CARRIER_CONTRACTS_REQUEST,
    FetchCarrierContractsActionT,
    REJECT_CARRIER_CONTRACT_REQUEST,
    RejectCarrierContractActionT,
    REVOKE_CARRIER_CONTRACT_REQUEST,
    RevokeCarrierContractActionT,
    UPDATE_CARRIER_CONTRACT_REQUEST,
    UpdateCarrierContractActionT,
    UPLOAD_CARRIER_CONTRACT_REQUEST,
    UploadCarrierContractActionT,
} from './types';
import {
    downloadCarrierContractBegin,
    downloadCarrierContractError,
    downloadCarrierContractSuccess,
    fetchCarrierContractsBegin,
    fetchCarrierContractsError,
    fetchCarrierContractsSuccess,
    updateCarrierContractBegin,
    updateCarrierContractError,
    updateCarrierContractSuccess,
    uploadCarrierContractBegin,
    uploadCarrierContractError,
    uploadCarrierContractSuccess,
} from './actions';

import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

import { checkIsDefaultCompanyId } from 'common/utils';
import { selectFetchCarrierContractsRequest } from 'common/store/carrier-contracts/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import isNil from 'lodash/isNil';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import downloadFile from 'common/utils/download-file';
import { ContractDetailsChangesT } from 'common/store/carrier-contracts/models';
import { carrierContractsRefreshChannel } from 'common/store/carrier-contracts/channels';
import { carrierContractDetailsRefreshChannel } from 'common/store/carrier-contract-details/channels';

function* fetchCarrierContractsSaga(action: FetchCarrierContractsActionT): WrapGeneratorT<void> {
    const { partnerId, options } = action;

    const requestStatus: ReReturnT<typeof selectFetchCarrierContractsRequest> = yield select(
        selectFetchCarrierContractsRequest(partnerId),
    );
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchCarrierContractsBegin(partnerId));

    let result: ReturnApiT<
        typeof carrierTranziitApi.fetchCarrierContracts | typeof brokerTranziitApi.fetchCarrierContracts
    >;

    if (checkIsDefaultCompanyId(partnerId)) {
        result = yield carrierTranziitApi.fetchCarrierContracts();
    } else {
        result = yield brokerTranziitApi.fetchCarrierContracts(partnerId);
    }

    const [error, partnerContracts] = result;

    if (error) {
        yield put(fetchCarrierContractsError(partnerId, error));
        return;
    }

    yield put(fetchCarrierContractsSuccess(partnerId, partnerContracts || []));
}

function* updateCarrierContractSaga(action: UpdateCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, contractChanges } = action;

    const [fetchDetailsError, contractDetails]: ReturnApiT<typeof brokerTranziitApi.fetchCarrierContractDetails> =
        yield brokerTranziitApi.fetchCarrierContractDetails(partnerId, contractId);

    if (fetchDetailsError) {
        yield put(updateCarrierContractError(partnerId, fetchDetailsError));
        return;
    }

    if (!contractDetails) {
        return;
    }

    const patchedPartnerContract: ContractDetailsChangesT = {
        ...contractDetails,
        ...contractChanges,
    };

    yield put(updateCarrierContractBegin(partnerId, contractId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateCarrierContract> =
        yield brokerTranziitApi.updateCarrierContract(partnerId, contractId, patchedPartnerContract);

    if (error) {
        yield put(updateCarrierContractError(partnerId, error));
        return;
    }

    carrierContractDetailsRefreshChannel.emit({
        contractId,
    });
    carrierContractsRefreshChannel.emit({});

    yield put(updateCarrierContractSuccess(partnerId));
}

function* revokeCarrierContractSaga(action: RevokeCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, revokeInfo } = action;

    if (isNil(contractId)) {
        return;
    }

    yield put(updateCarrierContractBegin(partnerId, contractId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.revokeCarrierContract> =
        yield brokerTranziitApi.revokeCarrierContract(partnerId, contractId, revokeInfo);

    if (error) {
        yield put(updateCarrierContractError(partnerId, error));
        return;
    }

    carrierContractDetailsRefreshChannel.emit({
        contractId,
    });
    carrierContractsRefreshChannel.emit({});

    yield put(updateCarrierContractSuccess(partnerId));
}

function* rejectCarrierContractSaga(action: RejectCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, reason } = action;

    yield put(updateCarrierContractBegin(partnerId, contractId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.rejectCarrierContract> =
        yield brokerTranziitApi.rejectCarrierContract(partnerId, contractId, {
            reason,
        });

    if (error) {
        yield put(updateCarrierContractError(partnerId, error));
        return;
    }

    carrierContractDetailsRefreshChannel.emit({
        contractId,
    });
    carrierContractsRefreshChannel.emit({});

    yield put(updateCarrierContractSuccess(partnerId));
}

function* approveCarrierContractSaga(action: ApproveCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, contractChanges } = action;

    if (isNil(contractId)) {
        return;
    }

    yield put(updateCarrierContractBegin(partnerId, contractId));

    const [fetchDetailsError, contractDetails]: ReturnApiT<typeof brokerTranziitApi.fetchCarrierContractDetails> =
        yield brokerTranziitApi.fetchCarrierContractDetails(partnerId, contractId);

    if (fetchDetailsError) {
        yield put(updateCarrierContractError(partnerId, fetchDetailsError));
        return;
    }

    if (!contractDetails) {
        return;
    }

    const patchedPartnerContract: ContractDetailsChangesT = {
        ...contractDetails,
        ...contractChanges,
    };

    const [error]: ReturnApiT<typeof brokerTranziitApi.approveCarrierContract> =
        yield brokerTranziitApi.approveCarrierContract(partnerId, contractId, patchedPartnerContract);

    if (error) {
        yield put(updateCarrierContractError(partnerId, error));
        return;
    }

    carrierContractDetailsRefreshChannel.emit({
        contractId,
    });
    carrierContractsRefreshChannel.emit({});

    yield put(updateCarrierContractSuccess(partnerId));
}

function* uploadCarrierContractSaga(action: UploadCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, file } = action;

    yield put(uploadCarrierContractBegin(partnerId));

    let response: ReturnApiT<
        typeof carrierTranziitApi.uploadCarrierContract | typeof brokerTranziitApi.uploadCarrierContract
    >;
    if (checkIsDefaultCompanyId(partnerId)) {
        response = yield carrierTranziitApi.uploadCarrierContract(file);
    } else {
        response = yield brokerTranziitApi.uploadCarrierContract(file, partnerId);
    }
    const [error] = response;

    if (error) {
        yield put(uploadCarrierContractError(error, partnerId));
        return;
    }

    yield put(uploadCarrierContractSuccess(partnerId));

    carrierContractsRefreshChannel.emit({});

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.contractUploaded,
                data: {},
            }),
        ),
    );
}

function* downloadCarrierContractSaga(action: DownloadCarrierContractActionT): WrapGeneratorT<void> {
    const { partnerId, contractId } = action;

    yield put(downloadCarrierContractBegin(partnerId));

    let response: ReturnApiT<
        typeof carrierTranziitApi.fetchCarrierContract | typeof brokerTranziitApi.fetchCarrierContract
    >;
    if (checkIsDefaultCompanyId(partnerId)) {
        response = yield carrierTranziitApi.fetchCarrierContract(contractId);
    } else {
        response = yield brokerTranziitApi.fetchCarrierContract(partnerId, contractId);
    }
    const [error, result] = response;

    if (error) {
        yield put(downloadCarrierContractError(error, partnerId));
        return;
    }

    if (result) {
        downloadFile({
            name: `${contractId}.pdf`,
            data: result,
            type: 'application/pdf',
        });
    }

    yield put(downloadCarrierContractSuccess(partnerId));
}

function* carrierContractsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_CARRIER_CONTRACTS_REQUEST, fetchCarrierContractsSaga);
    yield takeEvery(UPDATE_CARRIER_CONTRACT_REQUEST, updateCarrierContractSaga);
    yield takeEvery(REVOKE_CARRIER_CONTRACT_REQUEST, revokeCarrierContractSaga);
    yield takeEvery(REJECT_CARRIER_CONTRACT_REQUEST, rejectCarrierContractSaga);
    yield takeEvery(APPROVE_CARRIER_CONTRACT_REQUEST, approveCarrierContractSaga);
    yield takeEvery(UPLOAD_CARRIER_CONTRACT_REQUEST, uploadCarrierContractSaga);
    yield takeEvery(DOWNLOAD_CARRIER_CONTRACT_REQUEST, downloadCarrierContractSaga);
}

export default carrierContractsSaga;
