import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './Steps.scss';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { OrderCreationStepEnum } from 'common/store/order-creation/models';
import { matchPath, useLocation } from 'react-router-dom';
import { OMSRoutesEnum, QueryKeysEnum } from 'common/constants';
import history from 'common/utils/history';
import Stepper from 'common/components/Stepper/Stepper';
import useQuery from 'common/utils/hooks/useQuery';
import { useContext } from 'react';
import StepsContext from 'common/layouts/NewOrderPage/contexts/steps-context';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const STEP_PATH_NAMES = {
    [OrderCreationStepEnum.shipmentDetails]: OMSRoutesEnum.newOrder,
    [OrderCreationStepEnum.price]: OMSRoutesEnum.newOrderOffers,
    [OrderCreationStepEnum.cargoDetails]: OMSRoutesEnum.newOrderDetails,
};

type QueryT = {
    [QueryKeysEnum.orderCreationComplete]: string;
};

const STEP_T_MAP: Record<OrderCreationStepEnum, string> = {
    [OrderCreationStepEnum.shipmentDetails]: 'common:new-order-form.steps.route-and-trailer',
    [OrderCreationStepEnum.price]: 'common:new-order-form.steps.price',
    [OrderCreationStepEnum.cargoDetails]: 'common:new-order-form.steps.cargo-details',
};

const Steps: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    const stepsContext = useContext(StepsContext);
    const { hasSelectedContact } = stepsContext;

    const { pathname, search } = useLocation();

    const query = useQuery<QueryT>();

    let params = {};
    let activeStepIndex = 0;
    let stepsSequence: Array<OrderCreationStepEnum> = [];
    const match1 = matchPath(pathname, { path: OMSRoutesEnum.newOrder });
    if (match1) {
        params = { ...params, ...match1.params };
        activeStepIndex = 0;
        stepsSequence = hasSelectedContact
            ? [OrderCreationStepEnum.shipmentDetails, OrderCreationStepEnum.cargoDetails]
            : [OrderCreationStepEnum.shipmentDetails, OrderCreationStepEnum.price, OrderCreationStepEnum.cargoDetails];
    }

    const match2 = matchPath(pathname, { path: OMSRoutesEnum.newOrderOffers });
    if (match2) {
        params = { ...params, ...match2.params };
        activeStepIndex = 1;
        stepsSequence = [
            OrderCreationStepEnum.shipmentDetails,
            OrderCreationStepEnum.price,
            OrderCreationStepEnum.cargoDetails,
        ];
    }

    const match3 = matchPath(pathname, { path: OMSRoutesEnum.newOrderDetails });
    if (match3) {
        params = { ...params, ...match3.params };
        activeStepIndex = 2;
        stepsSequence = [
            OrderCreationStepEnum.shipmentDetails,
            OrderCreationStepEnum.price,
            OrderCreationStepEnum.cargoDetails,
        ];
    }

    const match3Lane = matchPath(pathname, { path: OMSRoutesEnum.newOrderDetailsWithLane });
    if (match3Lane) {
        params = { ...params, ...match3Lane.params };
        activeStepIndex = 1;
        stepsSequence = [OrderCreationStepEnum.shipmentDetails, OrderCreationStepEnum.cargoDetails];
    }

    const steps = stepsSequence.map((step) => ({
        label: t(STEP_T_MAP[step]),
        step,
        pathnameTemplate: STEP_PATH_NAMES[step],
    }));

    const goTo = (stepIndex: number): void => {
        const { pathnameTemplate } = steps[stepIndex];
        const pathname = generatePath(pathnameTemplate, params);

        history.push({
            pathname,
            search,
        });
    };

    const isContinue = !!query[QueryKeysEnum.orderCreationComplete];

    return (
        <Stepper
            className={cx('steps')}
            activeStepIndex={activeStepIndex}
            goTo={goTo}
            steps={steps}
            testSelector="new-order"
            isDisabledBack={isContinue}
            initShowStepIndex={isContinue ? 1 : 0}
        />
    );
});

export default Steps;
