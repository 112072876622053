import * as React from 'react';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { ADDITIONAL_SERVICES_ENUM_MAP, CurrencyEnum, StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { ALL_TOLL_TYPES, ApiTollByRoadTypeT, CountryCodeT } from 'common/utils/api/models';
import { TOLL_MAP_T } from 'common/components/TollLabel/TollLabel';
import isNumber from 'lodash/isNumber';
import { moneyOperations } from 'common/utils/money';
import classNames from 'classnames/bind';
import styles from './ShipperPriceOfferInfoTable.scss';
import keys from 'lodash/keys';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import map from 'lodash/map';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import FeeIcon from 'common/icons/FeeIcon';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';

const cx = classNames.bind(styles);

export type ShipperPriceOfferInfoTablePropsT = {
    className?: string;
    hasContract?: boolean;
    totalPriceLabel: React.ReactNode;
    totalPrice: number | undefined;
    lineHaulCost: number | undefined;
    tollCost: number | undefined;
    tollByRoadType: ApiTollByRoadTypeT | undefined;
    tranziitServiceFee: number | undefined;
    urgentOverprice: number | undefined;
    layoverCost: number | undefined;
    layoverSeconds: number | undefined;
    fuelCost: number | undefined;
    greenCost: number | undefined;
    additionalServicesCost: number | undefined;
    additionalServices:
        | Array<{
              enum: DictAdditionalServiceT['enum'];
              cost: DictAdditionalServiceT['cost'];
          }>
        | undefined;
    shouldAlwaysRenderExpandTrigger?: boolean;
};

const ShipperPriceOfferInfoTable: React.FC<ShipperPriceOfferInfoTablePropsT> = React.memo((props) => {
    const {
        className,
        hasContract,
        totalPrice,
        totalPriceLabel,
        lineHaulCost,
        tollCost,
        tollByRoadType,
        tranziitServiceFee,
        urgentOverprice,
        layoverCost,
        layoverSeconds,
        fuelCost,
        greenCost,
        additionalServicesCost,
        additionalServices,
        shouldAlwaysRenderExpandTrigger,
    } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const additionalServicesCostList = map(additionalServices, (additionalService): PriceDetailT => {
        if (additionalService.enum === ADDITIONAL_SERVICES_ENUM_MAP.EURO6) {
            return {
                iconNode: null,
                title: <AsyncAdditionalServiceFormatter type={additionalService.enum} />,
                price: greenCost,
            };
        }

        return {
            iconNode: null,
            title: <AsyncAdditionalServiceFormatter type={additionalService.enum} />,
            price: additionalService.cost,
        };
    });

    const tollList = tollByRoadType
        ? ALL_TOLL_TYPES.map((tollType): PriceDetailT | null => {
              const toll = tollByRoadType?.[tollType];
              if (!toll) {
                  return null;
              }

              const { cost, byCountry } = toll;
              if (!cost && cost !== 0) {
                  return null;
              }

              const list = map(keys(byCountry), (countryCode): PriceDetailT => {
                  const countryCost = byCountry?.[countryCode];

                  return {
                      iconNode: <FlagIcon countryCode={countryCode} />,
                      title:
                          !countryCode || countryCode === 'null' ? (
                              t('common:rfq-details.unspecified')
                          ) : (
                              <AsyncCountryFormatter countryCode={countryCode as CountryCodeT} />
                          ),
                      price: countryCost,
                  };
              });

              return {
                  iconNode: null,
                  title: t(TOLL_MAP_T[tollType]),
                  price: cost,
                  list,
              };
          }).filter(isNonNil)
        : [];

    const priceDetails: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: totalPriceLabel,
            price: totalPrice,
            list: hasContract
                ? []
                : [
                      {
                          ...commonPriceDetails.lineHaul,
                          price: lineHaulCost,
                      },
                      // {
                      //     iconNode: null,
                      //     name: t('common:order-details.columns.equipment-cost'),
                      //     value: <Money amount={equipment} currency={CurrencyEnum.EUR} />,
                      //     emptyValue: t('common:info-table.placeholders.not-assigned'),
                      //     isBoldValue: true,
                      // },
                      {
                          ...commonPriceDetails.roadTaxes,
                          price: tollCost,
                          list: [
                              {
                                  list: tollList,
                              },
                          ],
                      },
                      {
                          ...commonPriceDetails.fuelCost,
                          price: fuelCost,
                      },
                      {
                          ...commonPriceDetails.additionalServices,
                          price: moneyOperations.plus(additionalServicesCost, greenCost, CurrencyEnum.EUR),
                          list: additionalServicesCostList?.length
                              ? [
                                    {
                                        list: additionalServicesCostList,
                                    },
                                ]
                              : undefined,
                      },
                      {
                          iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                          title: t('common:order-details.columns.tranziit-service-fee-cost'),
                          price: tranziitServiceFee,
                      },
                      isNumber(urgentOverprice)
                          ? {
                                ...commonPriceDetails.urgentOverprice,
                                price: urgentOverprice,
                            }
                          : null,
                      checkShouldShowLayover(layoverSeconds, layoverCost)
                          ? {
                                ...commonPriceDetails.getLayoverCost(layoverSeconds),
                                price: layoverCost || 0,
                            }
                          : null,
                  ],
        },
    ].filter(isNonNil);

    return (
        <PriceDetails
            className={className}
            list={priceDetails}
            shouldAlwaysRenderExpandTrigger={shouldAlwaysRenderExpandTrigger}
        />
    );
});

export default ShipperPriceOfferInfoTable;
