import { TrailerTypeEnum } from 'common/constants';
import { CountryCodeT } from 'common/utils/api/models';

export enum FieldsEnum {
    trailerType = 'trailerType',
    trailerDictId = 'trailerDictId',

    countryCode = 'countryCode',
    plateNumber = 'plateNumber',

    contract = 'contract',
    ratePerKm = 'ratePerKm',
}

export type FormValuesT = {
    [FieldsEnum.trailerType]: TrailerTypeEnum;
    [FieldsEnum.trailerDictId]: TrailerDictIdT | null;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.plateNumber]: string;
    [FieldsEnum.contract]: CarrierContractIdT | null;
    [FieldsEnum.ratePerKm]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
