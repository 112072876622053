import * as React from 'react';
import { ApiRFQStatusT, RFQStatusEnum } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import { StyleGuideColorsEnum } from 'common/constants';
import RFQStatus from 'common/components/status/RFQStatus/RFQStatus';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiRFQStatusT | null | undefined;
};

export const RFQ_STATUS_COLOR_MAP: Record<ApiRFQStatusT, StyleGuideColorsEnum> = {
    [RFQStatusEnum.open]: StyleGuideColorsEnum.charcoal,
    [RFQStatusEnum.expireSoon]: StyleGuideColorsEnum.orange,
    [RFQStatusEnum.expired]: StyleGuideColorsEnum.charcoal,
    [RFQStatusEnum.completed]: StyleGuideColorsEnum.charcoal,
    [RFQStatusEnum.closed]: StyleGuideColorsEnum.charcoal,
};

const RFQColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    const color = RFQ_STATUS_COLOR_MAP[status];

    return <ColoredStatusLabel {...restProps} label={<RFQStatus status={status} />} color={color} />;
});

export default RFQColoredStatus;
