import { SharableStateT } from '../models';
import {
    initialShipperContractDetailsItemState,
    ShipperContractDetailsItemStateT,
    ShipperContractDetailsStateT,
} from './slice';

export const selectShipperContractDetailsState = (shipperContractId: ShipperContractIdT) => {
    return (state: SharableStateT): ShipperContractDetailsItemStateT => {
        return state.shipperContractDetails.detailsById[shipperContractId] || initialShipperContractDetailsItemState;
    };
};

export const selectUpdateShipperContractRequest = (
    state: SharableStateT,
): ShipperContractDetailsStateT['updateRequest'] => {
    return state.shipperContractDetails.updateRequest;
};

export const selectDownloadShipperContractRequest = (
    state: SharableStateT,
): ShipperContractDetailsStateT['downloadRequest'] => {
    return state.shipperContractDetails.downloadRequest;
};
