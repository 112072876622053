import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigPlusUsers: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M15 20h170v90H15z" />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.05"
                    d="M176 20a7 7 0 110 14h-40a7 7 0 110 14h22a7 7 0 110 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 110 14H62a7 7 0 110-14H23a7 7 0 110-14h40a7 7 0 100-14H38a7 7 0 110-14h40a7 7 0 110-14h98zm0 28a7 7 0 110 14 7 7 0 010-14z"
                />
                <path
                    stroke="#8ABA2E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M149.5 47h-17.176m24.176 0h-2.221m-11.103 7.777H135M48.5 68H31.324M25.5 68h-4.221m31.897 8.777H45"
                />
                <path
                    stroke="#8ABA2E"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M76.667 63.417c3.208 0 5.833-2.625 5.833-5.834 0-3.208-2.625-5.833-5.833-5.833-3.209 0-5.834 2.625-5.834 5.833 0 3.209 2.625 5.834 5.834 5.834zm3.296 3.208c-1.08-.175-2.159-.292-3.296-.292-2.888 0-5.63.613-8.109 1.692A5.865 5.865 0 0065 73.421V78h13.125v-4.696c0-2.42.67-4.696 1.838-6.679zm43.37-3.208c3.209 0 5.834-2.625 5.834-5.834 0-3.208-2.625-5.833-5.834-5.833-3.208 0-5.833 2.625-5.833 5.833 0 3.209 2.625 5.834 5.833 5.834zM135 73.42c0-2.363-1.4-4.463-3.558-5.396a20.263 20.263 0 00-8.109-1.692c-1.137 0-2.216.117-3.295.292a13.126 13.126 0 011.837 6.68V78H135v-4.58zM100 43a8.738 8.738 0 00-8.75 8.75A8.738 8.738 0 00100 60.5a8.738 8.738 0 008.75-8.75A8.738 8.738 0 00100 43h0zm12.367 22.313A30.458 30.458 0 00100 62.688c-4.754 0-8.954 1.137-12.367 2.625-3.15 1.4-5.133 4.55-5.133 7.991V78h35v-4.696c0-3.441-1.983-6.591-5.133-7.991z"
                />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.3"
                    d="M76.667 63.417c3.208 0 5.833-2.625 5.833-5.834 0-3.208-2.625-5.833-5.833-5.833-3.209 0-3.209 11.667 0 11.667zm23.806-4c4.283 0 7.787-3.6 7.787-8s-3.504-8-7.787-8c-4.283 0-4.283 16 0 16zm22.86 4c3.209 0 5.834-2.625 5.834-5.834 0-3.208-2.625-5.833-5.834-5.833-3.208 0-3.208 11.667 0 11.667zM135 73.42c0-2.363-1.4-4.463-3.558-5.396a20.263 20.263 0 00-8.109-1.692c-1.137 0-2.216.117-3.295.292a13.126 13.126 0 011.837 6.68V78H135v-4.58zM111.722 65c-4.754 0-8.31-3.07-11.722-1.583-3.15 1.4-7.5 6.445-7.5 9.887V78h25c-.683-8.667-2.609-13-5.778-13z"
                />
                <path
                    fill="#FFF"
                    stroke="#8ABA2E"
                    strokeOpacity="0.3"
                    strokeWidth="2.5"
                    d="M101 98.75a.745.745 0 00.53-.22.745.745 0 00.22-.53h0v-5.75h5.75a.745.745 0 00.53-.22.745.745 0 000-1.06.745.745 0 00-.53-.22h0-5.75V86a.745.745 0 00-.22-.53.745.745 0 00-1.06 0 .745.745 0 00-.22.53h0v4.75H94.5a.745.745 0 00-.53.22.745.745 0 00-.22.53h0v.75l6.5.001V98c0 .207.084.395.22.53.135.136.323.22.53.22z"
                />
            </g>
        </svg>
    );
};

BigPlusUsers.displayName = 'BigPlusUsers';

const storyProps: PropsT = {};

export { storyProps };
export default BigPlusUsers;
