import { BaseSecurityAuthApi } from './base-security-auth-api';
import { AuthApiError } from 'common/utils/api/auth/errors/auth-api-error';
import { AuthUserClaimRoleT } from 'common/utils/api/models';

type AuthUserClaimsT = {
    roles: Array<AuthUserClaimRoleT>;
};

type PasswordT = string;
type EmailAddressT = string;

type AuthUserT = {
    email: EmailAddressT | null;
    displayName: string | null;
    isAnonymous: boolean;
};

type AuthAccountUrlsT = {
    changeCreds: string;
};

type AuthStateChangedCallbackT = (user: AuthUserT | null) => void;

type AuthUnsubscribeCallbackT = () => void;

abstract class BaseAuthService extends BaseSecurityAuthApi {
    abstract checkAllowedAnonymouslyUserCreation: () => boolean;

    abstract doSignOut: () => Promise<[AuthApiError | null, null]>;

    abstract signInWithEmailAndPassword: (
        email: EmailAddressT,
        password: PasswordT,
    ) => Promise<[AuthApiError | null, AuthUserT | null]>;

    abstract getAuthUserClaims: () => Promise<[AuthApiError | null, AuthUserClaimsT | null]>;

    abstract getAccountUrls: () => Promise<[AuthApiError | null, AuthAccountUrlsT | null]>;

    abstract verificationEmail: (code: string) => Promise<[AuthApiError | null, null]>;

    abstract onAuthStateChanged: (resolver: AuthStateChangedCallbackT) => AuthUnsubscribeCallbackT;

    abstract createAnonymouslyUser: () => Promise<[AuthApiError | null, AuthUserT | null]>;

    abstract updatePassword: (code: string, password: PasswordT) => Promise<[AuthApiError | null, null]>;

    abstract reauthenticateWithCredential: (
        email: EmailAddressT,
        password: PasswordT,
    ) => Promise<[AuthApiError | null, AuthUserT | null]>;

    abstract changePassword: (newPassword: PasswordT) => Promise<[AuthApiError | null, null]>;

    abstract updateProfile: (displayName: string, photoURL?: string) => Promise<[AuthApiError | null, null]>;

    abstract checkIsContinueSignInRedirect: () => boolean;

    abstract destroySession: () => void;

    abstract createSignInUrl: (continueReturnUrl?: string) =>
        | {
              pathname: string;
              search: string;
          }
        | string;

    abstract createSignInUrlAfterChangePassword: () =>
        | {
              pathname: string;
              search: string;
          }
        | string;

    abstract createSignUpUrl: () => string;

    abstract createForgotPasswordUrl: () => string;

    abstract createChangePasswordUrl: () => string;

    abstract createAccountUrl: () => string;

    abstract forceRefreshToken: () => Promise<[AuthApiError | null, boolean | null]>;
}

export {
    AuthStateChangedCallbackT,
    BaseAuthService,
    EmailAddressT,
    PasswordT,
    AuthUserT,
    AuthUserClaimsT,
    AuthAccountUrlsT,
    AuthUnsubscribeCallbackT,
};
