import React from 'react';
import classNames from 'classnames/bind';

import styles from './DeactivateUserForm.scss';
import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';

import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import { DeactivateUserFormPropsT } from 'common/layouts/Members/dialogs/DeactivateUserModal/models';

const cx = classNames.bind(styles);

type PropsT = DeactivateUserFormPropsT;

const TEST_SELECTOR = 'deactivate-user-form';

const DeactivateUserForm: React.FC<PropsT> = (props) => {
    const { contact, onDeactivateUser, onCancel, updateMemberRequest } = props;

    const { t } = useTranslation();

    const actions = React.useMemo(() => {
        return [
            {
                children: t('common:error-modal.actions.cancel'),
                theme: ButtonThemeEnum.secondary,
                testSelector: `${TEST_SELECTOR}_cancel`,
                isDisabled: updateMemberRequest.loading,
                onClick: onCancel,
            },
            {
                children: t('common:error-modal.actions.deactivate'),
                theme: ButtonThemeEnum.danger,
                testSelector: `${TEST_SELECTOR}_ok`,
                onClick: () => {
                    const { userId } = contact;
                    if (!userId) {
                        return;
                    }

                    onDeactivateUser({ userId });
                },
                isLoading: updateMemberRequest.loading,
            },
        ];
    }, []);

    return <ModalDialogActions actions={actions} />;
};

export default DeactivateUserForm;
