import { CompanyTrucksStateT } from './types';
import { SharableTrucksStateT, SharableStateT } from '../models';
import { initialCompanyTrucksState } from './reducer';

const EMPTY_QUERY = {};

const selectCompanyState = (state: SharableTrucksStateT, companyId: CompanyIdT): CompanyTrucksStateT =>
    state.trucks[companyId] || initialCompanyTrucksState;

export const selectTrucksPages =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['pages'] =>
        selectCompanyState(state, companyId).pages;

export const selectTrucksQuery =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['query'] =>
        selectCompanyState(state, companyId).query || EMPTY_QUERY;

export const selectTrucksTotal =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['total'] =>
        selectCompanyState(state, companyId).total;

export const selectTrucksByIds =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['byId'] =>
        selectCompanyState(state, companyId).byId;

export const selectTrucksDetailsByIds =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['detailsById'] =>
        selectCompanyState(state, companyId).detailsById;

export const selectDeleteTrucksRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['deleteRequest'] =>
        selectCompanyState(state, companyId).deleteRequest;

export const selectAddTruckRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['addRequest'] =>
        selectCompanyState(state, companyId).addRequest;

export const selectFetchTruckDetailsRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['fetchDetailsRequest'] =>
        selectCompanyState(state, companyId).fetchDetailsRequest;

export const selectUpdateTruckRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['updateRequest'] =>
        selectCompanyState(state, companyId).updateRequest;

export const selectFetchTrucksStatsRequestStatus =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['fetchStatsRequest'] =>
        selectCompanyState(state, companyId).fetchStatsRequest;

export const selectTrucksStats =
    (companyId: CompanyIdT) =>
    (state: SharableStateT): CompanyTrucksStateT['stats'] =>
        selectCompanyState(state, companyId).stats;
