import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxLength, checkMinLength, checkNotEmpty, checkPhoneNumber } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT, isRegisteredUser: boolean): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, [FieldsEnum.firstName, FieldsEnum.lastName, FieldsEnum.phone], values),
        ...checkMinLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 2),
        ...checkMaxLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 255),
        ...checkPhoneNumber(t, [FieldsEnum.phone], values),
    };

    if (!isRegisteredUser) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.password], values),
        };
    }

    const isAcceptTerms = values[FieldsEnum.acceptTerms];
    if (!isAcceptTerms) {
        errors[FieldsEnum.acceptTerms] = t('common:form-errors.accept-terms');
    }

    return errors;
};

export default validate;
