import React, { ReactNode } from 'react';
import styles from './DocumentDetailsLayout.scss';
import classNames from 'classnames/bind';
import map from 'lodash/map';
import TabLabel, { TabLabelSizeEnum } from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import { useTranslation } from 'react-i18next';

import DocumentMessage from './DocumentMessage/DocumentMessage';
import BigEmptyIcon from 'common/icons/BigEmptyIcon';
import { PermissionsT } from 'common/store/auth/models';
import { DocumentVersionT } from 'common/store/documents/models';
import DocumentEventsSummary from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentDetailsLayout/DocumentEventsSummary/DocumentEventsSummary';
import DocumentSummary from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentDetailsLayout/DocumentSummary/DocumentSummary';
import DocumentVersion from './DocumentVersion/DocumentVersion';
import { DictDocumentT } from 'common/store/documents-dict/models';

const cx = classNames.bind(styles);

export type PropsT = {
    isUploading: boolean;
    dictDocument: DictDocumentT | null;
    onDownloadFile: (documentId: DocumentIdT) => void;
    onOpenUserDetails: (userId: UserIdT) => void;
    permissions: PermissionsT;
    actualVersion: DocumentVersionT | null;
    oldVersions: Array<DocumentVersionT> | null;
    actualVersionFormNode?: ReactNode;
};

enum TabEnum {
    actual = 'actual',
    history = 'history',
}

const TAB_LABEL_T: Record<TabEnum, string> = {
    [TabEnum.actual]: 'common:document.tabs.actual',
    [TabEnum.history]: 'common:document.tabs.history',
};

const DocumentDetailsLayout: React.FC<PropsT> = (props) => {
    const {
        dictDocument,
        isUploading,
        permissions,
        onDownloadFile,
        onOpenUserDetails,
        actualVersion,
        oldVersions,
        actualVersionFormNode,
    } = props;

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = React.useState<TabEnum>(TabEnum.actual);

    return (
        <>
            <DocumentSummary
                className={cx('document-summary')}
                title={dictDocument?.name}
                tooltipContentNode={t('common:document.actions.download')}
                canDownload={permissions.canDownloadDocuments}
                id={actualVersion?.id || null}
                onDownloadFile={onDownloadFile}
            />
            <div className={cx('tabs')}>
                {map(TabEnum, (tab, tabIndex) => (
                    <TabLabel
                        isCompact
                        className={cx('tabs__tab')}
                        key={tabIndex}
                        size={TabLabelSizeEnum.small}
                        theme={TabsThemesEnum.light}
                        onClick={() => {
                            setActiveTab(tab);
                        }}
                        isActive={tab === activeTab}
                    >
                        {t(TAB_LABEL_T[tab])}
                    </TabLabel>
                ))}
            </div>
            <div className={cx('tab-content')}>
                {activeTab === TabEnum.actual && (
                    <>
                        {!!actualVersion && (
                            <>
                                <DocumentEventsSummary
                                    className={cx('actual-version-details')}
                                    version={actualVersion}
                                    onOpenUserDetails={onOpenUserDetails}
                                />
                                {actualVersionFormNode}
                            </>
                        )}
                        {!actualVersion && !isUploading && (
                            <DocumentMessage
                                icon={<BigEmptyIcon />}
                                title={t('common:document.messages.not-uploaded.title')}
                            />
                        )}
                    </>
                )}
                {activeTab === TabEnum.history && (
                    <>
                        {oldVersions?.length ? (
                            <>
                                {oldVersions.map((version, versionIndex) => (
                                    <DocumentVersion
                                        key={versionIndex}
                                        className={cx('version')}
                                        version={version}
                                        dictDocument={dictDocument}
                                        canDownloadDocuments={permissions.canDownloadDocuments}
                                        onOpenUserDetails={onOpenUserDetails}
                                        onDownloadFile={onDownloadFile}
                                    />
                                ))}
                            </>
                        ) : (
                            <DocumentMessage
                                icon={<BigEmptyIcon />}
                                title={t('common:document.messages.empty-history.title')}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default DocumentDetailsLayout;
