import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { selectTrucksDictById, selectTrucksDictRequest } from 'common/store/trucks-dict/selectors';
import { getLabel } from 'common/store/trucks-dict/utils';

type PropsT = {
    truckModel?: string | null;
};

const TruckModelFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { truckModel } = props;

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrucksDict());
    }, []);

    const trucksDictById = useSelector(selectTrucksDictById);
    const fetchRequest = useSelector(selectTrucksDictRequest);

    if (!trucksDictById) {
        return null;
    }

    if (fetchRequest.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;
    }

    const truck = trucksDictById[truckModel as string];
    if (!truck) {
        return null;
    }

    return <span>{getLabel(truck)}</span>;
});

export default TruckModelFilterPill;
