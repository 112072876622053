import * as React from 'react';
import UiContext from 'common/contexts/ui-context';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectCurrentUser } from 'common/store/user/selectors';
import { selectCurrentCompany } from 'common/store/company/selectors';

import Navigation, { BadgeT, MenuLinkT } from './Navigation/Navigation';

import ContentRootContext from 'common/layouts/LeftMenuLayout/contexts/content-root-context';

import classNames from 'classnames/bind';
import styles from './LeftMenuLayout.scss';

const cx = classNames.bind(styles);

type PropsT = {
    menuLinks: Array<MenuLinkT | null>;
    badges: Record<string, BadgeT[]>;
    accountLink: string;
    logoLink: string;
    isAllowHelpButton?: boolean;
};

const NavigationWrap: React.FC<PropsT> = React.memo((props) => {
    const { menuLinks, badges, logoLink, accountLink, isAllowHelpButton } = props;

    const uiContext = React.useContext(UiContext);

    const location = useLocation();
    const user = useSelector(selectCurrentUser);
    const company = useSelector(selectCurrentCompany);

    React.useEffect(() => {
        if (uiContext.menu) {
            uiContext.menu.handleClose();
        }
    }, [location.pathname]);

    return (
        <Navigation
            className={cx('menu', {
                'menu--isOpen': uiContext.menu?.isVisible,
            })}
            userName={user?.fullName || ''}
            userEmail={user?.email || ''}
            companyName={company?.name || ''}
            badges={badges}
            menuLinks={menuLinks}
            accountLink={accountLink}
            logoLink={logoLink}
            isAllowHelpButton={isAllowHelpButton}
        />
    );
});

const LeftMenuLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, ...rest } = props;

    const [node, setRootNode] = React.useState<HTMLDivElement | null>(null);

    const uiContext = React.useContext(UiContext);

    return (
        <div className={cx('wrap')}>
            <NavigationWrap {...rest} />
            <div
                className={cx('content', 'menu', {
                    'menu--isOpen': uiContext.menu?.isVisible,
                })}
            >
                <ContentRootContext.Provider value={node}>
                    <div className={cx('inner')} ref={(node) => setRootNode(node)}>
                        {children}
                    </div>
                </ContentRootContext.Provider>
            </div>
        </div>
    );
});

export default LeftMenuLayout;
