import * as React from 'react';
import { ApiDriverStatusT, DriverStatusEnum } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import DriverStatus from 'common/components/status/DriverStatus/DriverStatus';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiDriverStatusT | null;
};

export const DRIVER_STATUS_COLOR_MAP: Record<ApiDriverStatusT, StyleGuideColorsEnum> = {
    [DriverStatusEnum.free]: StyleGuideColorsEnum.mediumGreen,
    [DriverStatusEnum.pending]: StyleGuideColorsEnum.orange,
    [DriverStatusEnum.inTransit]: StyleGuideColorsEnum.mediumGreen,
    [DriverStatusEnum.archived]: StyleGuideColorsEnum.light,
};

const DriverColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    return (
        <ColoredStatusLabel
            {...restProps}
            label={<DriverStatus status={status} />}
            color={DRIVER_STATUS_COLOR_MAP[status]}
        />
    );
});

export default DriverColoredStatus;
