import * as React from 'react';
import { useTranslation } from 'react-i18next';

type PropsT = {};

const ReplacedLocationsAlertContent: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    return <span>{t('common:new-order-form.warnings-alert.invalid-route')}</span>;
});

export default ReplacedLocationsAlertContent;
