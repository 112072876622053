import { put, select, takeEvery } from 'redux-saga/effects';
import { fetchRFQsStatsBegin, fetchRFQsStatsError, fetchRFQsStatsSuccess } from './actions';
import { FETCH_RFQS_STATS_REQUEST, FetchRFQsStatsActionT } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectRFQsStatsRequest } from './selectors';
import { FETCH_ORDERS_PAGE_REQUEST } from 'common/store/transport-orders/types';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';

function* fetchRFQsStatsSaga(action: FetchRFQsStatsActionT): WrapGeneratorT<void> {
    const { options } = action;
    const requestStatus: ReturnType<typeof selectRFQsStatsRequest> = yield select(selectRFQsStatsRequest);

    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchRFQsStatsBegin());

    const [error, response]: ReturnApiT<typeof shipperTranziitApi.fetchRFQsStats> =
        yield shipperTranziitApi.fetchRFQsStats();
    if (error) {
        yield put(fetchRFQsStatsError(error));
        return;
    }

    yield put(fetchRFQsStatsSuccess(response));
}

function* rfqsStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_RFQS_STATS_REQUEST, FETCH_ORDERS_PAGE_REQUEST], fetchRFQsStatsSaga);
}

export default rfqsStatsSaga;
