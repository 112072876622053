import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
    fillColor: StyleGuideColorsEnum;
    size: number;
    className?: string;
};

const CargoUnitIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, fillColor, size, className } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path fill={fillColor} d="M4.167 15.833h11.666V6.667H4.167z" />
                <path
                    fill={strokeColor}
                    d="M17.117 4.358l-1.159-1.4A1.21 1.21 0 0015 2.5H5a1.24 1.24 0 00-.967.458l-1.15 1.4A1.638 1.638 0 002.5 5.417v10.416c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V5.417c0-.4-.142-.775-.383-1.059zM5.2 4.167h9.6l.692.833H4.517l.683-.833zm10.633 11.666H4.167V6.667h11.666v9.166z"
                />
            </g>
        </svg>
    );
};

CargoUnitIcon.displayName = 'CargoUnitIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default CargoUnitIcon;

export const CargoUnitIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandAccent5 : StyleGuideColorsEnum.light,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.gray,
        };
    },
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getCargoUnitIconProps = (
    preset:
        | 'default'
        | 'selected'
        | 'schedule-event-regular'
        | 'schedule-event-regular'
        | 'schedule-event-past'
        | 'schedule-event-future'
        | 'schedule-event-accent',
): Pick<PropsT, 'fillColor' | 'strokeColor'> => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent5,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'schedule-event-future':
        case 'schedule-event-regular': {
            return {
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'schedule-event-past': {
            return {
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.gray,
            };
        }
        case 'schedule-event-accent': {
            return {
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.charcoal,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
