import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './LastAssetTrackPointPin.scss';
import TrailerIcon from 'common/icons/TrailerIcon';
import TruckIcon from 'common/icons/TruckIcon';
import { AssetTypeEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import MapTooltip from 'common/components/maps/MapTooltip/MapTooltip';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { formatTime } from 'common/utils/time';
import { useTranslation } from 'react-i18next';

type PropsT = {
    iconType: AssetTypeEnum;
    timestamp: number | null;
    lat?: number;
    lng?: number;
    className?: string;
};

const cx = classNames.bind(styles);

const iconTypeMap: Record<
    AssetTypeEnum,
    React.FC<{
        fillColor: StyleGuideColorsEnum;
        strokeColor: StyleGuideColorsEnum;
        size: number;
    }>
> = {
    [AssetTypeEnum.trailer]: TrailerIcon,
    [AssetTypeEnum.truck]: TruckIcon,
};

const LastAssetTrackPointPin: React.FC<PropsT> = React.memo((props) => {
    const { lat, lng, timestamp, iconType, className } = props;

    const { t } = useTranslation();

    const IconComponent = iconTypeMap[iconType];

    return (
        <MapTooltip lat={lat} lng={lng} className={className}>
            {() => (
                <Tooltip
                    tooltipNode={
                        timestamp ? (
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                <span className={cx('last-track-point-updated')}>
                                    {t('common:last-track-point-updated', {
                                        time: timestamp ? formatTime(timestamp) : null,
                                    })}
                                </span>
                            </TooltipContent>
                        ) : null
                    }
                    delay={0}
                    position={TooltipPositionEnum.topCenter}
                    theme={TooltipThemeEnum.black}
                >
                    {() => (
                        <div className={cx('content')}>
                            <IconComponent
                                fillColor={StyleGuideColorsEnum.brandDark}
                                strokeColor={StyleGuideColorsEnum.white}
                                size={DEFAULT_ICON_SIZE}
                            />
                        </div>
                    )}
                </Tooltip>
            )}
        </MapTooltip>
    );
});

export default LastAssetTrackPointPin;
