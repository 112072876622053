import React from 'react';
import classNames from 'classnames/bind';

import styles from './EditShipperContractDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { useSelector } from 'react-redux';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import FilePickerInput from 'design-system/components/FilePickerInput/FilePickerInput';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { ShipperContractDetailsT, UpdateShipperContractChangesT } from 'common/store/shipper-contract-details/models';
import { logWarning } from 'common/utils/logger';
import { selectUpdateShipperContractRequest } from 'common/store/shipper-contract-details/selectors';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import moment from 'moment';
import FieldValue, { EMPTY_VALUE_PLACEHOLDER } from 'common/components/FieldValue/FieldValue';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { DownloadDataT } from './models';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import isNumber from 'lodash/isNumber';
import { getMaxISODate } from 'common/utils/time';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(Object.values(FieldsEnum));

type PropsT = {
    partnerId: PartnerIdT;
    shipperContractId: ShipperContractIdT;
    shipperContractDetails: ShipperContractDetailsT | null;
    onDownloadShipperContract: (data: DownloadDataT) => void;
    onUpdateShipperContract: (data: {
        partnerId: PartnerIdT;
        shipperContractId: ShipperContractIdT;
        detailsChanges: UpdateShipperContractChangesT;
        file: File | null;
    }) => void;
};

const VALID_TILL_FORMAT = 'YYYY-MM-DD';

const EditShipperContractDetailsForm: React.FC<PropsT> = (props) => {
    const { partnerId, shipperContractId, shipperContractDetails, onDownloadShipperContract, onUpdateShipperContract } =
        props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(partnerId, shipperContractDetails);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [partnerId, shipperContractDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const file = values[FieldsEnum.file];
            if (!file) {
                logWarning('failed to upload shipper contract, empty file');
                return;
            }

            const detailsChanges: UpdateShipperContractChangesT = {
                name: simpleStringFormatter(values[FieldsEnum.name]),
                number: simpleStringFormatter(values[FieldsEnum.number]),
                validFrom: moment(values[FieldsEnum.activateDate]).format(VALID_TILL_FORMAT),
                validTill: moment(values[FieldsEnum.validTillDate]).format(VALID_TILL_FORMAT),
            };

            onUpdateShipperContract({
                partnerId,
                shipperContractId,
                detailsChanges,
                file: file instanceof File ? file : null,
            });

            formikHelpers.setTouched({});
        },
    });

    const uploadRequestStatus = useSelector(selectUpdateShipperContractRequest);
    const isLoading = uploadRequestStatus?.loading;

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const activateDate = formik.values[FieldsEnum.activateDate];
    const minValidTillDate = React.useMemo(() => {
        if (!activateDate) {
            return datePicketRangePresets.documentValidTill.minDate;
        }

        return (
            getMaxISODate(
                moment(activateDate).add(MIN_VALID_FROM_VALID_TILL_DAYS, 'day').format(),
                shipperContractDetails?.validTill,
            ) || datePicketRangePresets.documentValidTill.minDate
        );
    }, [activateDate]);

    const validTillDate = formik.values[FieldsEnum.validTillDate];
    const maxActivateDate = React.useMemo(() => {
        if (!validTillDate) {
            return datePicketRangePresets.documentValidTill.maxDate;
        }

        return moment(validTillDate).subtract(1, 'day').format();
    }, [validTillDate]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--name')}
                    name={FieldsEnum.name}
                    error={formik.errors[FieldsEnum.name]}
                    meta={formik.getFieldMeta(FieldsEnum.name)}
                    label={t('common:shipper-contract-details.editing-form.fields.name.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.name}
                            value={formik.values[FieldsEnum.name]}
                            placeholder={t('common:shipper-contract-details.editing-form.fields.name.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasChanges={props.hasChanges}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--number')}
                    name={FieldsEnum.number}
                    error={formik.errors[FieldsEnum.number]}
                    meta={formik.getFieldMeta(FieldsEnum.number)}
                    label={t('common:shipper-contract-details.editing-form.fields.number.label')}
                    tooltipNode={
                        <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={150}>
                            {t('common:shipper-contract-details.editing-form.fields.number.tooltip')}
                        </TooltipContent>
                    }
                    tooltipPosition={TooltipPositionEnum.centerLeft}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.number}
                            value={formik.values[FieldsEnum.number]}
                            placeholder={t('common:shipper-contract-details.editing-form.fields.number.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasChanges={props.hasChanges}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--activate-date')}
                    name={FieldsEnum.activateDate}
                    error={formik.errors[FieldsEnum.activateDate]}
                    meta={formik.getFieldMeta(FieldsEnum.activateDate)}
                    label={t('common:shipper-contract-details.editing-form.fields.activate-date.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.activateDate]}
                            placeholder={t(
                                'common:shipper-contract-details.editing-form.fields.activate-date.placeholder',
                            )}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            minDate={datePicketRangePresets.documentValidTill.minDate}
                            maxDate={maxActivateDate}
                            hasError={props.hasError}
                            hasChanges={props.hasChanges}
                            hasWarning={props.hasWarning}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                            hasYearMonthForm
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--valid-till')}
                    name={FieldsEnum.validTillDate}
                    error={formik.errors[FieldsEnum.validTillDate]}
                    meta={formik.getFieldMeta(FieldsEnum.validTillDate)}
                    label={t('common:shipper-contract-details.editing-form.fields.valid-till-date.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.validTillDate]}
                            placeholder={t(
                                'common:shipper-contract-details.editing-form.fields.valid-till-date.placeholder',
                            )}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            minDate={minValidTillDate}
                            maxDate={datePicketRangePresets.documentValidTill.maxDate}
                            hasError={props.hasError}
                            hasChanges={props.hasChanges}
                            hasWarning={props.hasWarning}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                            hasYearMonthForm
                        />
                    )}
                </FormikField>
                <FieldValue
                    className={cx('field', 'field--orders-left')}
                    label={t('common:shipper-contract-details.editing-form.fields.orders-left.label')}
                    value={
                        isNumber(shipperContractDetails?.ordersLeft) &&
                        isNumber(shipperContractDetails?.maxNumberOfOrders)
                            ? `${shipperContractDetails?.ordersLeft}/${shipperContractDetails?.maxNumberOfOrders}`
                            : null
                    }
                    placeholder={EMPTY_VALUE_PLACEHOLDER}
                />
            </FieldGroup>
            <FormikField
                className={cx('field', 'field--file')}
                name={FieldsEnum.file}
                error={formik.errors[FieldsEnum.file]}
                meta={formik.getFieldMeta(FieldsEnum.file)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                withoutLabel
            >
                {(props) => (
                    <FilePickerInput
                        accept="application/pdf"
                        name={FieldsEnum.file}
                        value={formik.values[FieldsEnum.file]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        hasChanges={props.hasChanges}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        onDownload={onDownloadShipperContract}
                        hasUploadNewControl
                        renderLeftIcon={({ isEmpty }) => (
                            <PdfFileIcon
                                fillColor={isEmpty ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.brandDark}
                            />
                        )}
                    />
                )}
            </FormikField>
            <HiddenSubmitButtonForKeyboardEnter />
        </form>
    );
};

export default EditShipperContractDetailsForm;
