import * as React from 'react';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { goBackIfHasRePath } from 'common/utils/history';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CloseIcon from 'common/icons/CloseIcon';
import SideBarLayoutContextWrap from '../LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import CloseRFQConfirmation, {
    CloseRFQConfirmationDataT,
} from 'common/layouts/RFQDetailsPageLayout/dialogs/CloseRFQConfirmation/CloseRFQConfirmation';
import {
    selectRFQDefaultPriceOffer,
    selectRFQDetails,
    selectRFQDetailsRequestStatus,
} from 'common/store/rfq-details/selectors';
import { fetchRFQDetails } from 'common/store/rfq-details/actions';
import RFQStatusPill from 'common/components/status-pill/RFQStatusPill/RFQStatusPill';
import RFQDetails from 'common/layouts/RFQDetailsPageLayout/RFQDetails/RFQDetails';
import { closeRFQ } from 'common/store/rfqs-changes/actions';
import { selectRFQsChangesRequest } from 'common/store/rfqs-changes/selectors';
import { RFQStatusEnum } from 'common/utils/api/models';
import FooterSideBarLayout from '../LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import ContinueRFQ from './footers/ContinueRFQ/ContinueRFQ';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useMemo } from 'react';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import MultiPointOrderRouteMap, {
    MultiPointOrderRouteMapPropsT,
} from 'common/components/MultiPointOrderRouteMap/MultiPointOrderRouteMap';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { rfqsRefreshChannel } from 'common/store/rfqs/channels';

type PropsT = {
    goToBack: () => void;
    goToUserDetails: (userId: UserIdT | null) => void;
};

const canCloseStatusSet = new Set<RFQStatusEnum>([RFQStatusEnum.expireSoon, RFQStatusEnum.open]);
const canCompleteStatusSet = new Set<RFQStatusEnum>([RFQStatusEnum.expireSoon, RFQStatusEnum.open]);

const RFQDetailsPageLayout: React.FC<PropsT> = React.memo((props) => {
    const { goToBack, goToUserDetails } = props;

    const params = useParams<{ rfqId: RFQIdT }>();
    const { rfqId } = params;

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const changesRequest = useSelector(selectRFQsChangesRequest);
    const rfqDetails = useSelector(selectRFQDetails(rfqId));
    const rfqDefaultPriceOffer = useSelector(selectRFQDefaultPriceOffer(rfqId));
    const requestStatus = useSelector(selectRFQDetailsRequestStatus(rfqId));

    const dispatch = useDispatch();

    const polylineIds = useMemo(() => {
        return [rfqDetails?.polylineId].filter(isNonNil);
    }, [rfqDetails?.polylineId]);

    useRouteGeometry(polylineIds);

    React.useEffect(() => {
        dispatch(fetchRFQDetails(rfqId, { isForceUpdate: false }));
    }, [rfqId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchRFQDetails(rfqId, { isForceUpdate: false }));
    }, [rfqId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchRFQDetails(rfqId, { isForceUpdate: true }));
    }, [rfqId]);
    useChannelSubscribe(rfqsRefreshChannel, refreshPageHandler);

    const closeRFQDetails = () => {
        goBackIfHasRePath(() => {
            goToBack();
        });
    };

    const closeConfirmationModalDialog = useModalDialog<CloseRFQConfirmationDataT>();

    useCloseModalDialogAfterRequest(requestStatus, [closeConfirmationModalDialog]);

    const options: Array<DropdownControlOptionT | null> = React.useMemo(() => {
        const canClose = canCloseStatusSet.has(rfqDetails?.status as RFQStatusEnum);

        return [
            canClose
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                              label={t('common:rfq-details.actions.close')}
                          />
                      ),
                      onSelect: () => {
                          closeConfirmationModalDialog.setData({ id: rfqId });
                      },
                  }
                : null,
        ];
    }, [rfqDetails]);

    useCloseModalDialogAfterRequest(changesRequest, [closeConfirmationModalDialog]);

    const handleConfirmClose = () => {
        const rfqId = closeConfirmationModalDialog.data?.id;
        if (rfqId) {
            dispatch(closeRFQ(rfqId));
        }
    };

    const isShowLineLoader = !rfqDetails && requestStatus.loading;

    const routingGeometryState = useSelector(selectRoutingGeometryState(rfqDetails?.polylineId));

    const canComplete = canCompleteStatusSet.has(rfqDetails?.status as RFQStatusEnum);

    const mapPoints = useMemo((): MultiPointOrderRouteMapPropsT['points'] => {
        return (
            rfqDetails?.points?.map((point, index) => {
                return {
                    longitude: point?.address?.longitude,
                    latitude: point?.address?.latitude,
                    driveThru: point?.driveThru,
                    index,
                };
            }) || []
        );
    }, [rfqDetails]);

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <HorizontalColumnsLayout
                testSelector="rfq-details"
                leftColumnTitle={t('common:rfq-details.main-tabs.details')}
                leftColumnNode={
                    <SideBarLayoutContextWrap>
                        {changesRequest.loading && <LoaderOverlay />}
                        <HeaderSideBarLayout>
                            <HeaderSideBarContent
                                title={t('common:rfq-details.title')}
                                onClose={closeRFQDetails}
                                afterTitleNode={
                                    <>
                                        <RFQStatusPill
                                            isSymmetrical
                                            status={rfqDetails?.status}
                                            isImported={rfqDetails?.isImported}
                                        />
                                    </>
                                }
                                rightNode={
                                    <DropdownControl
                                        options={options}
                                        renderTrigger={(isActive, onClick) => (
                                            <TransparentTrigger
                                                isPressed={isActive}
                                                onClick={onClick}
                                                leftIcon={<MoreIcon />}
                                                reflectionTheme={ReflectionThemeEnum.light}
                                            />
                                        )}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                    />
                                }
                            />
                            <HeaderSideBarLoader isShow={isShowLineLoader} />
                        </HeaderSideBarLayout>
                        <SideBarLayout>
                            {rfqDetails ? (
                                <RFQDetails rfqDetails={rfqDetails} goToUserDetails={goToUserDetails} />
                            ) : (
                                <EntitySidebarSkeleton />
                            )}
                        </SideBarLayout>
                        {canComplete && (
                            <FooterSideBarLayout hasPaddings>
                                <ContinueRFQ id={rfqId} defaultPriceOffer={rfqDefaultPriceOffer} />
                            </FooterSideBarLayout>
                        )}
                    </SideBarLayoutContextWrap>
                }
                rightColumnTitle={t('common:rfq-details.main-tabs.info')}
                rightColumnNode={
                    <MultiPointOrderRouteMap
                        points={mapPoints}
                        key={rfqId}
                        route={routingGeometryState?.data}
                        routeRequest={routingGeometryState?.requestStatus}
                    />
                }
            />
            <CloseRFQConfirmation
                data={closeConfirmationModalDialog.data}
                onConfirm={handleConfirmClose}
                onCancel={closeConfirmationModalDialog.onCancel}
                onClose={closeConfirmationModalDialog.onClose}
                requestStatus={changesRequest}
            />
        </GoogleMapContext.Provider>
    );
});

export default RFQDetailsPageLayout;
