import * as React from 'react';
import isNumber from 'lodash/isNumber';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiShipperContractLaneStatusT, ShipperContractLaneStatusEnum } from 'common/utils/api/models';

type PropsT = {
    ordersLeft: number | null | undefined;
    maxNumberOfOrders: number | null | undefined;
    status: ApiShipperContractLaneStatusT | null | undefined;
};

const THEME_BY_STATUS: Record<ApiShipperContractLaneStatusT, PillLabelThemeEnum> = {
    [ShipperContractLaneStatusEnum.active]: PillLabelThemeEnum.charcoal,
    [ShipperContractLaneStatusEnum.completed]: PillLabelThemeEnum.grey,
    [ShipperContractLaneStatusEnum.expired]: PillLabelThemeEnum.grey,
    [ShipperContractLaneStatusEnum.revoked]: PillLabelThemeEnum.grey,
};

const DEFAULT_THEME = PillLabelThemeEnum.grey;

const OrdersCountCell: React.FC<PropsT> = React.memo((props) => {
    const { ordersLeft, maxNumberOfOrders, status } = props;

    if (!isNumber(ordersLeft) || !isNumber(maxNumberOfOrders)) {
        return null;
    }

    const theme = status ? THEME_BY_STATUS[status] : DEFAULT_THEME;

    return <PillLabel isSymmetrical theme={theme}>{`${ordersLeft}/${maxNumberOfOrders}`}</PillLabel>;
});

export default OrdersCountCell;
