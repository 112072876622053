import * as React from 'react';
import SkeletonFormatter from 'design-system/components/InfoTable/formatters/SkeletonFormatter/SkeletonFormatter';
import { useSelector } from 'react-redux';
import { selectCountriesByCode, selectCountriesDictRequest } from 'common/store/countries-dict/selectors';
import { CountryCodeT } from 'common/utils/api/models';

type PropsT = {
    countryCode?: CountryCodeT;
};

const AsyncCountryFormatter: React.FC<PropsT> = React.memo((props) => {
    const { countryCode } = props;

    const countriesDictRequest = useSelector(selectCountriesDictRequest);
    const countriesByCode = useSelector(selectCountriesByCode);

    if (countriesDictRequest.loading) {
        return <SkeletonFormatter />;
    }

    const country = countriesByCode[countryCode as CountryCodeT] || null;

    return <span>{country?.userLangDisplayName}</span>;
});

export default AsyncCountryFormatter;
