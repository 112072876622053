import React, { memo } from 'react';
import { DocumentTypeEnum } from 'common/utils/api/models';
import CommonDocumentParamsDetailsForm from './CommonDocumentParamsDetailsForm/CommonDocumentParamsDetailsForm';
import EvidenceOfTaxDetailsForm from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentParamsDetailsForm/EvidenceOfTaxDetailsForm/EvidenceOfTaxDetailsForm';
import { DocumentVersionT } from 'common/store/documents/models';
import { DictDocumentT } from 'common/store/documents-dict/models';

type PropsT = {
    isReview: boolean;
    actualVersion: DocumentVersionT | null;
    dictDocument: DictDocumentT | null;
    setNeedCloseConfirmation: (needCloseConfirmation: boolean) => void;
};

const DocumentParamsDetailsForm: React.FC<PropsT> = memo((props) => {
    const { isReview, actualVersion, dictDocument, setNeedCloseConfirmation } = props;

    switch (dictDocument?.type) {
        case DocumentTypeEnum.evidenceOfTaxVat: {
            return (
                <EvidenceOfTaxDetailsForm
                    isReview={isReview}
                    dictDocument={dictDocument}
                    actualVersion={actualVersion}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                />
            );
        }
        default: {
            return (
                <CommonDocumentParamsDetailsForm
                    isReview={isReview}
                    dictDocument={dictDocument}
                    actualVersion={actualVersion}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                />
            );
        }
    }
});

export default DocumentParamsDetailsForm;
