import React from 'react';
import { PartnerTypeEnum } from 'common/utils/api/models';

export type PartnerContextT = {
    partnerId: PartnerIdT;
    partnerType: PartnerTypeEnum;
};

const PartnerContext = React.createContext<PartnerContextT>({
    // NB: the values will always be defined because context is used in the App root!
    partnerId: '-',
    partnerType: PartnerTypeEnum.shipper,
});

export default PartnerContext;
