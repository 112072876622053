import { NotificationRendererT } from 'common/components/notifications/NotificationsBarContent/models';
import { AnyNotificationT, NotificationEmotionEnum, NotificationViewT } from 'common/store/notifications/models';

export const renderCommonNotification: NotificationRendererT = (notification) => {
    return null;
};
export const renderUnknownNotification = (notification: AnyNotificationT): NotificationViewT => {
    return {
        emotion: NotificationEmotionEnum.neutral,
        text: `${notification.sourceAction} ${JSON.stringify(notification.sourceBody)}`,
    };
};
