import keyBy from 'lodash/keyBy';
import {
    LEGAL_FORMS_DICT_REQUEST_BEGIN,
    LEGAL_FORMS_DICT_REQUEST_ERROR,
    LEGAL_FORMS_DICT_REQUEST_SUCCESS,
    LegalFormsDictActionT,
    LegalFormsDictStateT,
    CountryLegalFormsDictStateT,
} from './types';
import requestStatus from 'common/utils/request-status';

export const initialCountryLegalFormsState: CountryLegalFormsDictStateT = {
    allIds: [],
    byId: {},
    request: requestStatus.initial,
};

const initialState: LegalFormsDictStateT = {};

export default (state = initialState, action: LegalFormsDictActionT): LegalFormsDictStateT => {
    switch (action.type) {
        case LEGAL_FORMS_DICT_REQUEST_BEGIN: {
            const { countryCode } = action;

            const countryLegalFormsState = state[countryCode] || initialCountryLegalFormsState;

            return {
                ...state,
                [countryCode]: {
                    ...countryLegalFormsState,
                    request: requestStatus.loading,
                },
            };
        }

        case LEGAL_FORMS_DICT_REQUEST_SUCCESS: {
            const { countryCode, legalFormsList } = action;

            const countryLegalFormsState = state[countryCode] || initialCountryLegalFormsState;

            const allIds = legalFormsList.map((legalForm) => legalForm.id);
            const byId = keyBy(legalFormsList, 'id');

            return {
                ...state,
                [countryCode]: {
                    ...countryLegalFormsState,
                    allIds,
                    byId,
                    request: requestStatus.ok,
                },
            };
        }

        case LEGAL_FORMS_DICT_REQUEST_ERROR: {
            const { countryCode, error } = action;

            const countryLegalFormsState = state[countryCode] || initialCountryLegalFormsState;

            return {
                ...state,
                [countryCode]: {
                    ...countryLegalFormsState,
                    request: requestStatus.setError(error),
                },
            };
        }

        default: {
            return state;
        }
    }
};
