import { InvoicesStateT } from './types';
import { StateT } from '../models';

export const selectInvoicesPages = (state: StateT): InvoicesStateT['pages'] => state.invoices.pages;

export const selectInvoicesQuery = (state: StateT): InvoicesStateT['query'] => state.invoices.query;

export const selectInvoicesTotal = (state: StateT): InvoicesStateT['total'] => state.invoices.total;

export const selectInvoicesByIds = (state: StateT): InvoicesStateT['byId'] => state.invoices.byId;

export const selectInvoicesPagingState = (state: StateT): InvoicesStateT['pagingState'] => state.invoices.pagingState;
