import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './MultiPointOrderDraftDetails.scss';

import TrailerIcon from 'common/icons/TrailerIcon';
import RouteIcon from 'common/icons/RouteIcon';

import { useTranslation } from 'react-i18next';
import { PriceOfferT } from 'common/store/order-creation/models';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { ApiLocationAddressT } from 'mobile-app/src/utils/api/carrier-tranziit/models';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import NumberIcon from 'common/icons/NumberIcon';

const cx = classNames.bind(styles);

export type MultiPointOrderDraftDetailsPropsT = {
    points: Array<{
        index?: number;
        driveThru?: boolean;
        address: ApiLocationAddressT | null;
    } | null> | null;
    offer: PriceOfferT | null;
    trailerType: ApiTrailerDictTypeT | null;
};

type RowT = {
    leftColumn: React.ReactNode;
    leftColumnClassName?: string;
    rightColumn: React.ReactNode;
    rightColumnClassName?: string;
};

const MultiPointOrderDraftDetails: React.FC<MultiPointOrderDraftDetailsPropsT> = React.memo((props) => {
    const { offer, trailerType, points } = props;

    const { t } = useTranslation();

    const renderRow = (row: RowT): React.ReactNode => {
        return (
            <div className={cx('row')}>
                <div className={cs(cx('column', 'column--isLeftColumn'), row.leftColumnClassName)}>
                    {row.leftColumn}
                </div>
                <div className={cs(cx('column', 'column--isRightColumn'), row.rightColumnClassName)}>
                    {row.rightColumn}
                </div>
            </div>
        );
    };

    const getPlaceholderRow = (width: number, key?: string): RowT => ({
        leftColumn: <div key={`placeholder-icon-${key}`} className={cx('placeholder', 'placeholder__icon')} />,
        rightColumn: (
            <div
                key={`placeholder-content-${key}`}
                className={cx('placeholder', 'placeholder__content')}
                style={{
                    width: `${width}%`,
                }}
            />
        ),
    });

    return (
        <div className={cx('inner')}>
            {renderRow(
                offer
                    ? {
                          leftColumn: <RouteIcon fillColor={StyleGuideColorsEnum.charcoal} />,
                          rightColumn: t('common:new-order-details.distance', {
                              distance: offer.distanceKm,
                          }),
                          rightColumnClassName: cx('column--isWeight'),
                      }
                    : getPlaceholderRow(20),
            )}
            {renderRow(
                trailerType
                    ? {
                          leftColumn: (
                              <TrailerIcon
                                  fillColor={StyleGuideColorsEnum.brandAccent}
                                  strokeColor={StyleGuideColorsEnum.brandDark}
                                  size={DEFAULT_ICON_SIZE}
                              />
                          ),
                          rightColumn: t('common:trailer-subtype-template', {
                              length: trailerType.length,
                              model: trailerType.model,
                              eur1Pallets: trailerType.eur1Pallets,
                              eur2Pallets: trailerType.eur2Pallets,
                          }),
                          rightColumnClassName: cx('column--isWeight'),
                      }
                    : getPlaceholderRow(45),
            )}
            <div className={cx('direction')}>
                {points?.map((point) => {
                    if (!point) {
                        return null;
                    }

                    let iconFillColor = StyleGuideColorsEnum.charcoal;
                    if (point.driveThru) {
                        iconFillColor = StyleGuideColorsEnum.gray;
                    }

                    return renderRow({
                        leftColumn: <NumberIcon number={(point?.index || 0) + 1} fillColor={iconFillColor} />,
                        rightColumn: (
                            <LocationLabel format="s1_s2_zip_city_country" location={point.address} separator=", " />
                        ),
                        rightColumnClassName: cx('column--isDirection'),
                    });
                })}
                {!points?.length && (
                    <>
                        {renderRow(getPlaceholderRow(65, '0'))}
                        {renderRow(getPlaceholderRow(65, '1'))}
                    </>
                )}
            </div>
        </div>
    );
});

export default MultiPointOrderDraftDetails;
