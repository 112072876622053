import { DictDocumentT } from 'common/store/documents-dict/models';
import { DocumentIdsByTypeT, DocumentT } from 'common/store/documents/models';
import { isNonNil } from 'common/utils';

const useDocumentInfo = (
    dictDocument: DictDocumentT | null,
    documentIdsByType: DocumentIdsByTypeT | null,
    documentsById: Record<DocumentIdT, DocumentT> | null,
) => {
    if (!dictDocument) {
        return null;
    }

    const versionIds = documentIdsByType?.[dictDocument.type] || null;
    const actualVersionId = versionIds?.[0] || null;
    const actualVersion = documentsById?.[actualVersionId as DocumentIdT] || null;
    const oldVersionIds = versionIds?.slice(1) || [];
    const oldVersions = oldVersionIds
        .map((versionId) => {
            return documentsById?.[versionId];
        })
        .filter(isNonNil);

    return {
        dictDocument,
        actualVersion,
        oldVersions,
    };
};

export { useDocumentInfo };
