import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './SideBarLayout.scss';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';
import SideBarLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-layout-context';
import debounce from 'lodash/debounce';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    testSelector?: string;
    withoutScrollbar?: boolean;
    isWide?: boolean;
};

const COLD_TIME = 300;

const DEFAULT_TOP_PADDING = 20;
const DEFAULT_BOTTOM_PADDING = 30;

const SideBarLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, className, testSelector, withoutScrollbar, isWide } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const context = React.useContext(SideBarLayoutContext);

    React.useEffect(() => {
        const scrollBarWidth = getScrollbarWidth(containerRef?.current);

        if (context.scrollBarWidth) {
            context.scrollBarWidth.setValue(scrollBarWidth);
        }
    }, [containerRef, children]);

    const handleContainerScroll = (): void => {
        if (context.scrollTop) {
            context.scrollTop.setValue(containerRef?.current?.scrollTop || 0);
        }
    };

    const footerHeightValue = context.footerHeight?.value || 0;
    const headerHeightValue = context.headerHeight?.value || 0;

    const paddingTopPx = isWide ? 0 : headerHeightValue + DEFAULT_TOP_PADDING;
    const paddingBottomPx = isWide ? 0 : footerHeightValue + DEFAULT_BOTTOM_PADDING;

    return (
        <div
            className={cs(
                cx('content', {
                    'content--without-scrollbar': withoutScrollbar,
                    'content--is-wide': isWide,
                }),
                className,
            )}
            style={{
                paddingTop: `${paddingTopPx}px`,
                paddingBottom: `${paddingBottomPx}px`,
            }}
            ref={containerRef}
            onScroll={debounce(handleContainerScroll, COLD_TIME)}
            data-test-selector={`${testSelector}_sidebar-layout`}
        >
            {children}
        </div>
    );
});

export default SideBarLayout;
