import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './TeamDrivePill.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = {
    className?: string;
    isSymmetrical?: boolean;
};

const cx = classNames.bind(styles);

const TeamDrivePill: React.FC<PropsT> = React.memo((props) => {
    const { className, isSymmetrical } = props;
    const { t } = useTranslation();

    return (
        <PillLabel
            isNoWrap
            isSymmetrical={isSymmetrical}
            theme={PillLabelThemeEnum.charcoal}
            testSelector="team-drive"
            className={className}
        >
            {t('common:team-drive')}
        </PillLabel>
    );
});

export default TeamDrivePill;
