import {
    CompanyRFQsStateT,
    FETCH_RFQS_PAGE_REQUEST_BEGIN,
    FETCH_RFQS_PAGE_REQUEST_ERROR,
    FETCH_RFQS_PAGE_REQUEST_SUCCESS,
    RESET_RFQS,
    RFQsActionT,
    RFQsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { pageBeginReducer, pageSuccessReducer, resetPagesCacheReducer } from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyRFQsState: CompanyRFQsStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
};

const initialState: RFQsStateT = {};

export default (state = initialState, action: RFQsActionT | DestroySessionActionT): RFQsStateT => {
    switch (action.type) {
        case FETCH_RFQS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query, companyId } = action;

            const companyState = state[companyId] || initialCompanyRFQsState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageBeginReducer(companyState, pageNumber, query),
                },
            };
        }
        case FETCH_RFQS_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse, companyId } = action;

            const companyState = state[companyId] || initialCompanyRFQsState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageSuccessReducer(companyState, pageNumber, query, pageResponse),
                },
            };
        }
        case FETCH_RFQS_PAGE_REQUEST_ERROR: {
            const { error, pageNumber, companyId } = action;

            const companyState = state[companyId] || initialCompanyRFQsState;

            const newPages = [...companyState.pages];
            newPages.splice(pageNumber, 1, {
                ...companyState.pages[pageNumber],
                requestStatus: requestStatus.setError(error),
            });

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    pages: newPages,
                },
            };
        }
        case RESET_RFQS: {
            const { companyId, mutationListOptions } = action;

            const companyState = state[companyId] || initialCompanyRFQsState;

            return {
                ...initialState,
                [companyId]: {
                    ...initialCompanyRFQsState,
                    ...resetPagesCacheReducer(initialCompanyRFQsState, companyState, mutationListOptions),
                },
            };
        }

        case DESTROY_SESSION: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
