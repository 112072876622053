import React from 'react';

type RemoteFormRequestT = {
    isLoading?: boolean;
};

type RemoteFormStateT = {
    hasChanges?: boolean;
    hasErrors?: boolean;
};

type RemoteFormCallbacksT = {
    submit?: () => void;
    reset?: () => void;
};

export type RemoteFormActionsT = {
    remoteFormState?: RemoteFormStateT;
    setRemoteFormState?: (remoteFormState: RemoteFormStateT) => void;
    remoteFormCallbacks?: RemoteFormCallbacksT;
    setRemoteFormCallbacks?: (remoteFormCallbacks: RemoteFormCallbacksT) => void;
    remoteFormRequest?: RemoteFormRequestT;
    setRemoteFormRequest?: (remoteFormRequest: RemoteFormRequestT) => void;
};

const useRemoteFormActions = (): RemoteFormActionsT => {
    const [remoteFormState, setRemoteFormState] = React.useState<RemoteFormStateT>({});
    const [remoteFormCallbacks, setRemoteFormCallbacks] = React.useState<RemoteFormCallbacksT>({});
    const [remoteFormRequest, setRemoteFormRequest] = React.useState<RemoteFormRequestT>({});

    return {
        remoteFormState,
        setRemoteFormState,
        remoteFormCallbacks,
        setRemoteFormCallbacks,
        remoteFormRequest,
        setRemoteFormRequest,
    };
};

export default useRemoteFormActions;
