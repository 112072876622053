import React from 'react';
import classNames from 'classnames/bind';

import styles from './ActivateAssetForm.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, RateSourceEnum } from './constants';
import validateForm from './validate-form';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import FormikField from 'common/components/forms/FormikField/FormikField';
import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { prepareRate } from './prepare-rate';
import { AssetDataT } from 'common/layouts/dialogs/ActivateAssetDialogModal/models';
import Money from 'common/components/Money/Money';

const cx = classNames.bind(styles);

type PropsT = {
    assets: Array<AssetDataT>;
    onCancel: () => void;
    onSubmit: (ratesPerKm: Array<number | null>) => void;
    requestStatus: RequestStatusT;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.source]: RateSourceEnum.current,
    [FieldsEnum.rate]: '',
};

const TEST_SELECTOR = 'activate-asset-form';

type RateSourceOptionT = {
    label: React.ReactNode;
    value: RateSourceEnum;
};

const T_MAP = {
    oneRatesOptionLabel: 'common:assets.activation.rates.one-current-rate',
    manyRatesOptionLabel: 'common:assets.activation.rates.many-current-rates',
    newRateOptionLabel: 'common:assets.activation.rates.new-rate',
};

const ActivateAssetForm: React.FC<PropsT> = (props) => {
    const { onCancel, onSubmit, requestStatus, assets } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const ratesPerKm = prepareRate(assets, values);
            onSubmit(ratesPerKm);

            formikHelpers.setTouched({});
        },
    });

    const rateSourceOptions = React.useMemo(() => {
        let currentRatesOptionLabel;
        if (assets.length === 1) {
            currentRatesOptionLabel = (
                <Trans
                    i18nKey={T_MAP.oneRatesOptionLabel}
                    components={{
                        rate: <Money amount={assets[0]?.ratePerKm} currency={CurrencyEnum.EUR} />,
                    }}
                />
            );
        } else {
            currentRatesOptionLabel = t(T_MAP.manyRatesOptionLabel);
        }

        return [
            {
                label: currentRatesOptionLabel,
                value: RateSourceEnum.current,
            },
            {
                label: t(T_MAP.newRateOptionLabel),
                value: RateSourceEnum.new,
            },
        ];
    }, [assets, t]);

    const selectedSource = formik.values[FieldsEnum.source];

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FieldGroup spaces={1}>
                    <FormikField
                        name={FieldsEnum.source}
                        className={cx('field', 'field--source')}
                        label={t('common:assets.activation.fields.source.label')}
                        error={formik.errors[FieldsEnum.source]}
                        meta={formik.getFieldMeta(FieldsEnum.source)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownInput<RateSourceOptionT, RateSourceOptionT['value']>
                                selectedValue={formik.values[FieldsEnum.source]}
                                options={rateSourceOptions}
                                onSelect={props.onChange}
                                renderOption={(option) => (option ? option.label : null)}
                                getOptionValue={(option) => option.value}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                    {selectedSource === RateSourceEnum.new && (
                        <FormikField
                            className={cx('field', 'field--rate')}
                            name={FieldsEnum.rate}
                            error={formik.errors[FieldsEnum.rate]}
                            meta={formik.getFieldMeta(FieldsEnum.rate)}
                            label={t('common:assets.activation.fields.rate.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <NumberInput
                                    name={FieldsEnum.rate}
                                    unitType={UnitTypeEnum.euroAbbreviation}
                                    value={formik.values[FieldsEnum.rate]}
                                    placeholder={t('common:assets.activation.fields.rate.placeholder')}
                                    step={1}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                    )}
                </FieldGroup>
            </div>
            <ModalDialogActions
                actions={[
                    {
                        children: t('common:error-modal.actions.cancel'),
                        theme: ButtonThemeEnum.secondary,
                        isDisabled: requestStatus.loading,
                        testSelector: `${TEST_SELECTOR}_cancel`,
                        onClick: () => {
                            onCancel();
                        },
                    },
                    {
                        children: t('common:error-modal.actions.activate'),
                        theme: ButtonThemeEnum.primary,
                        isDisabled: requestStatus.loading,
                        isLoading: requestStatus.loading,
                        testSelector: `${TEST_SELECTOR}_ok`,
                        type: 'submit',
                    },
                ]}
            />
        </form>
    );
};

export default ActivateAssetForm;
