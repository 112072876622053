import {
    CHECK_GPS_STATUS_REQUEST,
    CHECK_GPS_STATUS_REQUEST_BEGIN,
    CHECK_GPS_STATUS_REQUEST_ERROR,
    CHECK_GPS_STATUS_REQUEST_SUCCESS,
    CheckGPSStatusRequestActionT,
    CheckGPSStatusRequestBeginActionT,
    CheckGPSStatusRequestErrorActionT,
    CheckGPSStatusRequestSuccessActionT,
    GPSTrackingStatusQueryT,
    RESET,
    ResetActionT,
} from './types';
import { GPSStatusT } from './models';

export const checkGPSStatusRequest = (query: GPSTrackingStatusQueryT | null): CheckGPSStatusRequestActionT => ({
    type: CHECK_GPS_STATUS_REQUEST,
    query,
});

export const checkRequestBegin = (query: GPSTrackingStatusQueryT): CheckGPSStatusRequestBeginActionT => ({
    type: CHECK_GPS_STATUS_REQUEST_BEGIN,
    query,
});

export const checkRequestSuccess = (
    query: GPSTrackingStatusQueryT,
    data: GPSStatusT,
): CheckGPSStatusRequestSuccessActionT => ({
    type: CHECK_GPS_STATUS_REQUEST_SUCCESS,
    query,
    data,
});

export const checkRequestError = (query: GPSTrackingStatusQueryT, error: Error): CheckGPSStatusRequestErrorActionT => ({
    type: CHECK_GPS_STATUS_REQUEST_ERROR,
    error,
    query,
});

export const reset = (): ResetActionT => ({
    type: RESET,
});
