import * as React from 'react';
import { ApiEmissionClassT } from 'common/constants';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';

type PropsT = {
    emissionClass: ApiEmissionClassT | null | undefined;
};

const EmissionClassFormatter: React.FC<PropsT> = React.memo((props) => {
    const { emissionClass } = props;

    return <EmissionClassLabel emissionClass={emissionClass} />;
});

export default EmissionClassFormatter;
