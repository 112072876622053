import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxLength, checkMinLength, checkNotEmpty } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, [FieldsEnum.password], values),
        ...checkMinLength(t, [FieldsEnum.password], values, 6),
        ...checkMaxLength(t, [FieldsEnum.password], values, 255),
    };

    return errors;
};

export default validate;
