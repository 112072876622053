import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LinkButton.scss';
import cs from 'classnames';

import noop from 'lodash/noop';

const cx = classNames.bind(styles);

export enum LinkButtonThemeEnum {
    brandDark = 'brand-dark',
}

export enum LinkButtonHeightEnum {
    regular = 'regular',
    narrow = 'narrow',
}

export type LinkButtonPropsT = {
    className?: string;
    theme?: LinkButtonThemeEnum;
    height?: LinkButtonHeightEnum;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    testSelector?: string;
};

const LinkButton: React.FC<LinkButtonPropsT> = (props) => {
    const {
        className,
        theme = LinkButtonThemeEnum.brandDark,
        height = LinkButtonHeightEnum.regular,
        onClick,
        isDisabled,
        children,
        leftIcon,
        rightIcon,
        testSelector,
    } = props;

    const isClickable = !isDisabled;

    return (
        <div
            className={cs(
                cx('button', {
                    'button--isClickable': isClickable,
                    'button--isDisabled': isDisabled,
                    [`button--height-${height}`]: true,
                }),
                className,
            )}
            data-test-selector={`${testSelector}_link`}
            onClick={isClickable ? onClick : noop}
        >
            <div className={cx('inner')}>
                {leftIcon && (
                    <div
                        className={cx('icon', {
                            'icon--isLeft': true,
                            'icon--isHidden': false,
                        })}
                    >
                        {leftIcon}
                    </div>
                )}
                <div
                    className={cx('text', {
                        [`text--theme-${theme}`]: true,
                        'text--isHidden': false,
                    })}
                >
                    {children}
                    <div
                        className={cx('border', {
                            [`border--height-${height}`]: true,
                            [`border--theme-${theme}`]: true,
                        })}
                    />
                </div>
                {rightIcon && (
                    <div
                        className={cx('icon', {
                            'icon--isRight': true,
                            'icon--isHidden': false,
                        })}
                    >
                        {rightIcon}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LinkButton;
