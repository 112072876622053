import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { getZipCodeExample, hasZipCodeValidation, isValid } from 'common/utils/postal-codes';
import { checkEmail, checkMaxLength, checkMinLength, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.companyName,
    FieldsEnum.legalAddressCountryCode,
    FieldsEnum.legalAddressCity,
    FieldsEnum.legalAddressZipCode,
    FieldsEnum.legalAddressStreet1,
] as const;

type MetaT = {
    shouldShowBillingInfo: boolean;
};

const validate = (t: TFunction, values: FormValuesT, { shouldShowBillingInfo }: MetaT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinLength(t, [FieldsEnum.companyName], values, 2),
        ...checkMaxLength(t, [FieldsEnum.companyName], values, 255),
        ...(shouldShowBillingInfo ? checkEmail(t, [FieldsEnum.billingEmail], values) : {}),
        ...(shouldShowBillingInfo
            ? checkNotEmpty(
                  t,
                  [
                      FieldsEnum.billingAddressCountryCode,
                      FieldsEnum.billingAddressCity,
                      FieldsEnum.billingAddressZipCode,
                      FieldsEnum.billingAddressStreet1,
                      FieldsEnum.billingEmail,
                  ],
                  values,
              )
            : {}),
    };

    const legalAddressZipCode = values[FieldsEnum.legalAddressZipCode];
    const legalAddressCountryCode = values[FieldsEnum.legalAddressCountryCode];
    if (hasZipCodeValidation(legalAddressCountryCode) && !isValid(legalAddressCountryCode, legalAddressZipCode)) {
        errors[FieldsEnum.legalAddressZipCode] = t('common:form-errors.zip-code', {
            example: getZipCodeExample(legalAddressCountryCode),
        });
    }

    const postalAddressZipCode = values[FieldsEnum.postalAddressZipCode];
    const postalAddressCountryCode = values[FieldsEnum.postalAddressCountryCode];
    if (hasZipCodeValidation(postalAddressCountryCode) && !isValid(postalAddressCountryCode, postalAddressZipCode)) {
        errors[FieldsEnum.postalAddressZipCode] = t('common:form-errors.zip-code', {
            example: getZipCodeExample(postalAddressCountryCode),
        });
    }

    if (shouldShowBillingInfo) {
        const billingAddressZipCode = values[FieldsEnum.billingAddressZipCode];
        const billingAddressCountryCode = values[FieldsEnum.billingAddressCountryCode];
        if (
            hasZipCodeValidation(billingAddressCountryCode) &&
            !isValid(billingAddressCountryCode, billingAddressZipCode)
        ) {
            errors[FieldsEnum.billingAddressZipCode] = t('common:form-errors.zip-code', {
                example: getZipCodeExample(billingAddressCountryCode),
            });
        }
    }

    return errors;
};

export default validate;
