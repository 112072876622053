import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_SYMBOLS, UnitTypeEnum } from 'common/constants';

type PropsT =
    | {
          type: UnitTypeEnum | null | undefined;
      }
    | {
          types: Array<UnitTypeEnum>;
      };

type CurrencyUnitTypesT = UnitTypeEnum.euroAbbreviation;
const CURRENCY_TYPE_MAP: Record<CurrencyUnitTypesT, string> = {
    [UnitTypeEnum.euroAbbreviation]: CURRENCY_SYMBOLS.EUR,
};

type SimpleUnitTypesT = UnitTypeEnum.percentsAbbreviation;
const SIMPLE_UNIT_MAP: Record<SimpleUnitTypesT, string> = {
    [UnitTypeEnum.percentsAbbreviation]: '%',
};

type OtherUnitTypesT = Exclude<UnitTypeEnum, CurrencyUnitTypesT | SimpleUnitTypesT>;
const UNIT_TYPE_T_MAP: Record<OtherUnitTypesT, string> = {
    [UnitTypeEnum.eur1Pallets]: 'common:unit-type.eur-1-pallets',
    [UnitTypeEnum.eur2Pallets]: 'common:unit-type.eur-2-pallets',
    [UnitTypeEnum.other]: 'common:unit-type.other',
    [UnitTypeEnum.kilogramsAbbreviation]: 'common:unit-type.kilograms-abbreviation',
    [UnitTypeEnum.oneHundredKilometersAbbreviation]: 'common:unit-type.100-kilometers-abbreviation',
    [UnitTypeEnum.metersAbbreviation]: 'common:unit-type.meters-abbreviation',
    [UnitTypeEnum.kilometersAbbreviation]: 'common:unit-type.kilometers-abbreviation',
    [UnitTypeEnum.litersAbbreviation]: 'common:unit-type.liters-abbreviation',
    [UnitTypeEnum.days]: 'common:unit-type.days',
    [UnitTypeEnum.hoursAbbreviation]: 'common:unit-type.hours-abbreviation',
    [UnitTypeEnum.minutesAbbreviation]: 'common:unit-type.minutes-abbreviation',
    [UnitTypeEnum.milliSecondsAbbreviation]: 'common:unit-type.milli-seconds-abbreviation',
    [UnitTypeEnum.shipments]: 'common:unit-type.shipments',
    [UnitTypeEnum.secondsAbbreviation]: 'common:unit-type.seconds-abbreviation',
    [UnitTypeEnum.kilometersPerHourAbbreviation]: 'common:unit-type.kilometers-per-hour-abbreviation',
};

const UnitType: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    if ('types' in props) {
        const { types: subTypes } = props;

        const formattedSubTypes = subTypes.map((subType) => {
            if (CURRENCY_TYPE_MAP[subType as CurrencyUnitTypesT]) {
                return CURRENCY_TYPE_MAP[subType as CurrencyUnitTypesT];
            }

            if (SIMPLE_UNIT_MAP[subType as SimpleUnitTypesT]) {
                return SIMPLE_UNIT_MAP[subType as SimpleUnitTypesT];
            }

            return t(UNIT_TYPE_T_MAP[subType as OtherUnitTypesT]);
        });

        return <span>{formattedSubTypes.join('/')}</span>;
    }

    const { type } = props;
    if (!type) {
        return null;
    }

    const label =
        CURRENCY_TYPE_MAP[type as CurrencyUnitTypesT] ||
        SIMPLE_UNIT_MAP[type as SimpleUnitTypesT] ||
        t(UNIT_TYPE_T_MAP[type as OtherUnitTypesT]);

    return <span>{label}</span>;
});

export default UnitType;
