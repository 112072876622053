import React, { useState } from 'react';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { DocumentDetailsSidebarDataT } from './models';
import { DocumentRequiredEnum, DocumentStatusEnum } from 'common/utils/api/models';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCompanyDocumentIdsByType,
    selectCompanyDocumentsById,
    selectFetchCompanyDocumentsRequest,
    selectUpdateCompanyDocumentsRequest,
    selectUpdateEvidenceOfTaxDetailsRequest,
    selectUploadCompanyDocumentsRequest,
} from 'common/store/documents/selectors';
import {
    selectDocumentsDictById,
    selectDocumentsDictByType,
    selectDocumentsDictRequest,
} from 'common/store/documents-dict/selectors';
import { useDocumentInfo } from 'common/layouts/BaseDocumentsLayout/hooks/use-document-info';
import { fetchDocumentsDict } from 'common/store/documents-dict/actions';
import {
    downloadDocument,
    fetchDocuments,
    rejectDocument,
    revokeDocument,
    uploadDocument,
} from 'common/store/documents/actions';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { selectPermissions } from 'common/store/auth/selectors';
import { useDocumentsActionOptions } from 'common/layouts/BaseDocumentsLayout/hooks/use-documents-action-options';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import isEmpty from 'lodash/isEmpty';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import UploadDocumentConfirmation, {
    ConfirmUploadDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/UploadDocumentConfirmation/UploadDocumentConfirmation';
import { pickFile } from 'common/utils/pick-file';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { selectCurrentCompany } from 'common/store/company/selectors';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import DocumentParamsDetails from './DocumentParamsDetails/DocumentParamsDetails';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import UploadFooterContent from './footers/UploadFooterContent/UploadFooterContent';
import RevokeDocumentsConfirmation, {
    ConfirmRevokeDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/RevokeDocumentsConfirmation/RevokeDocumentsConfirmation';
import RejectDocumentConfirmation, {
    ConfirmRejectDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/RejectDocumentConfirmation/RejectDocumentConfirmation';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import DocumentParamsDetailsForm from './DocumentParamsDetailsForm/DocumentParamsDetailsForm';
import ReviewFooterContent from './footers/ReviewFooterContent/ReviewFooterContent';
import SaveFooterContent from './footers/SaveFooterContent/SaveFooterContent';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import DocumentDetailsLayout from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentDetailsLayout/DocumentDetailsLayout';
import UploadLoader from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/UploadLoader/UploadLoader';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import DocumentStatusPill from 'common/components/status-pill/DocumentStatusPill/DocumentStatusPill';

type PropsT = SidebarContentPropsT<DocumentDetailsSidebarDataT, CommonSidebarDataT>;

const DocumentDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, onOpenNextSidebar, setNeedCloseConfirmation } = props;

    const { initIsEditing, documentType } = data;

    const partnerContext = usePartnerContext();

    const partnerId = data.partnerId || partnerContext.partnerId;
    const partnerType = data.partnerType || partnerContext.partnerType;

    const [isEditing, setIsEditing] = useState(initIsEditing);

    const remoteFormActions = useRemoteFormActions();

    const currentCompany = useSelector(selectCurrentCompany);

    const uploadRequest = useSelector(selectUploadCompanyDocumentsRequest(partnerId));
    const updateRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));
    const updateEvidenceOfTaxDetailsRequest = useSelector(selectUpdateEvidenceOfTaxDetailsRequest(partnerId));

    const documentsDictById = useSelector(selectDocumentsDictById(partnerType));
    const documentsDictByType = useSelector(selectDocumentsDictByType(partnerType));
    const dictDocument = documentsDictByType[documentType] || null;

    const documentIdsByType = useSelector(selectCompanyDocumentIdsByType(partnerId));

    const documentsById = useSelector(selectCompanyDocumentsById(partnerId));

    const documentInfo = useDocumentInfo(dictDocument, documentIdsByType, documentsById);

    const fetchDocumentsDictRequest = useSelector(selectDocumentsDictRequest(partnerType));
    const fetchRequest = useSelector(selectFetchCompanyDocumentsRequest(partnerId));

    const isRequestLoading = fetchRequest.loading || fetchDocumentsDictRequest.loading;
    const isShowLineLoader = isRequestLoading && !isEmpty(documentsById);
    const isShowOverlayLoader = isRequestLoading && !isShowLineLoader;

    const confirmUploadDocumentModalDialog = useModalDialog<ConfirmUploadDocumentModalDataT>();
    const confirmRevokeDocumentModalDialog = useModalDialog<ConfirmRevokeDocumentModalDataT>();
    const confirmRejectDocumentModalDialog = useModalDialog<ConfirmRejectDocumentModalDataT>();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId));
    }, [partnerType, partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId));
    }, [partnerType, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const permissions = useSelector(selectPermissions);

    const handleDownloadDocument = (documentId: DocumentIdT) => {
        dispatch(downloadDocument(documentId, partnerId));
    };

    const handleUploadDocument = (dictDocumentId: DictDocumentIdT) => {
        const dictDocument = documentsDictById[dictDocumentId];

        const shouldConfirm = dictDocument?.required === DocumentRequiredEnum.must && currentCompany?.documentsVerified;
        if (shouldConfirm) {
            confirmUploadDocumentModalDialog.setData({
                dictDocumentId,
            });
        } else {
            onConfirmUploadDocument({ dictDocumentId });
        }
    };

    const handleEditDocumentInfo = () => {
        setIsEditing(true);
    };

    const handleRevokeDocument = (dictDocumentId: DictDocumentIdT, id: DocumentIdT) => {
        confirmRevokeDocumentModalDialog.setData({
            dictDocumentId,
            id,
            partnerType,
        });
    };

    const handleRejectDocument = (dictDocumentId: DictDocumentIdT, id: DocumentIdT) => {
        confirmRejectDocumentModalDialog.setData({
            id,
            dictDocumentId,
            partnerType,
        });
    };

    const actualVersion = documentInfo?.actualVersion || null;
    const headerDropdownOptions = useDocumentsActionOptions(
        actualVersion,
        dictDocument,
        {
            ...permissions,
            canEditDocuments: isEditing ? false : permissions.canEditDocuments,
        },
        {
            onDownloadDocument: handleDownloadDocument,
            onUploadDocument: handleUploadDocument,
            onRevokeDocument: handleRevokeDocument,
            onEditDocumentInfo: handleEditDocumentInfo,
        },
    );

    const onConfirmUploadDocument = (data: ConfirmUploadDocumentModalDataT): void => {
        pickFile('application/pdf', (file) => {
            dispatch(uploadDocument(data.dictDocumentId, file, partnerId, partnerType));
        });
    };

    const onConfirmRevokeDocument = (id: DocumentIdT) => {
        dispatch(revokeDocument(id, partnerId, partnerType));
    };

    const onConfirmRejectDocument = (documentId: DocumentIdT, reason: string) => {
        dispatch(rejectDocument(documentId, reason, partnerId, partnerType));
    };

    useCloseModalDialogAfterRequest(uploadRequest, [confirmUploadDocumentModalDialog]);
    useCloseModalDialogAfterRequest(updateRequest, [
        confirmRevokeDocumentModalDialog,
        confirmRejectDocumentModalDialog,
    ]);

    useOnlyChangesEffect(() => {
        if (updateEvidenceOfTaxDetailsRequest.ok) {
            setIsEditing(false);
        }
    }, [updateEvidenceOfTaxDetailsRequest]);

    useOnlyChangesEffect(() => {
        if (updateRequest.ok) {
            setIsEditing(false);
        }
    }, [updateRequest]);

    const handleOpenUserDetails = (userId: UserIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const shouldReview = documentInfo?.actualVersion?.status === DocumentStatusEnum.waitingForApprove;

    const isShowSkeleton =
        (!documentInfo?.actualVersion && !fetchRequest.ok && !fetchRequest.error) ||
        (isEmpty(documentsDictByType) && !fetchDocumentsDictRequest.ok && fetchDocumentsDictRequest.error);

    const isRequiredDocument = documentInfo?.dictDocument?.required === DocumentRequiredEnum.must;

    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    onGoBack={onGoBack}
                    title={
                        <DocumentStatusPill
                            isSymmetrical
                            status={documentInfo?.actualVersion?.status}
                            isRequiredDocument={isRequiredDocument}
                        />
                    }
                    onClose={onClose}
                    dropdownOptions={headerDropdownOptions}
                />
                <HeaderSideBarLoader isShow={isShowLineLoader} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                {isShowSkeleton ? (
                    <SideBarLoader />
                ) : (
                    <DocumentDetailsLayout
                        dictDocument={documentInfo?.dictDocument || null}
                        onDownloadFile={handleDownloadDocument}
                        onOpenUserDetails={handleOpenUserDetails}
                        permissions={permissions}
                        isUploading={uploadRequest.loading}
                        actualVersion={documentInfo?.actualVersion || null}
                        oldVersions={documentInfo?.oldVersions || null}
                        actualVersionFormNode={
                            isEditing ? (
                                <DocumentParamsDetailsForm
                                    isReview={shouldReview}
                                    actualVersion={documentInfo?.actualVersion || null}
                                    dictDocument={documentInfo?.dictDocument || null}
                                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                                />
                            ) : (
                                <DocumentParamsDetails
                                    dictDocument={documentInfo?.dictDocument || null}
                                    actualVersion={documentInfo?.actualVersion || null}
                                />
                            )
                        }
                    />
                )}
            </SideBarLayout>
            {isShowOverlayLoader && <SideBarLoader />}
            {uploadRequest.loading && <UploadLoader />}
            {!documentInfo?.actualVersion && !uploadRequest.loading && permissions.canUploadDocuments && (
                <FooterSideBarLayout>
                    <UploadFooterContent
                        onUploadDocument={handleUploadDocument}
                        dictDocument={documentInfo?.dictDocument || null}
                    />
                </FooterSideBarLayout>
            )}
            {isEditing && shouldReview && (
                <FooterSideBarLayout>
                    <ReviewFooterContent
                        dictDocument={documentInfo?.dictDocument || null}
                        actualVersion={documentInfo?.actualVersion || null}
                        onReject={handleRejectDocument}
                    />
                </FooterSideBarLayout>
            )}
            {isEditing && !shouldReview && (
                <FooterSideBarLayout>
                    <SaveFooterContent />
                </FooterSideBarLayout>
            )}
            <UploadDocumentConfirmation
                data={confirmUploadDocumentModalDialog.data}
                onCancel={confirmUploadDocumentModalDialog.onCancel}
                onClose={confirmUploadDocumentModalDialog.onClose}
                requestStatus={uploadRequest}
                documentsDictById={documentsDictById}
                onConfirm={onConfirmUploadDocument}
            />
            <RejectDocumentConfirmation
                name={dictDocument?.name}
                data={confirmRejectDocumentModalDialog.data}
                onCancel={confirmRejectDocumentModalDialog.onCancel}
                onClose={confirmRejectDocumentModalDialog.onClose}
                onConfirm={onConfirmRejectDocument}
                requestStatus={updateRequest}
            />
            <RevokeDocumentsConfirmation
                data={confirmRevokeDocumentModalDialog.data}
                onCancel={confirmRevokeDocumentModalDialog.onCancel}
                onClose={confirmRevokeDocumentModalDialog.onClose}
                onConfirm={onConfirmRevokeDocument}
                requestStatus={updateRequest}
            />
        </RemoteFormActionsContext.Provider>
    );
};

export default DocumentDetailsSidebarContent;
