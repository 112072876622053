import { logWarning } from 'common/utils/logger';

type FeatureT = string;
type ParsedFeaturesConfigT = Set<FeatureT>;

const SEPARATOR = ',';

const parseFeaturesConfig = (stringifiedConfig: string | null | undefined): ParsedFeaturesConfigT => {
    if (!stringifiedConfig) {
        return new Set([]);
    }

    try {
        return new Set(stringifiedConfig.split(SEPARATOR));
    } catch (error) {
        logWarning(error?.message);

        return new Set([]);
    }
};

export enum ServerFeatureEnum {
    spot = 'spot',
}

const ALLOWED_FEATURES_SET = parseFeaturesConfig(window.ALLOWED_FEATURES);
const DEFAULT_ALLOWED_FEATURES_SET = new Set<ServerFeatureEnum>([ServerFeatureEnum.spot]);

const DISABLED_FEATURES_SET = parseFeaturesConfig(window.DISABLED_FEATURES);
const DEFAULT_DISABLED_FEATURES_SET = new Set<ServerFeatureEnum>([]);

export const checkActiveFeature = (feature: ServerFeatureEnum): boolean => {
    const isAllowed = ALLOWED_FEATURES_SET.has(feature) || DEFAULT_ALLOWED_FEATURES_SET.has(feature);
    const isDisabled = DISABLED_FEATURES_SET.has(feature) || DEFAULT_DISABLED_FEATURES_SET.has(feature);

    return isAllowed && !isDisabled;
};
