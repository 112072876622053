import { put, select, takeEvery } from 'redux-saga/effects';
import { fetchOrdersStatsBegin, fetchOrdersStatsError, fetchOrdersStatsSuccess } from './actions';
import { FETCH_ORDERS_STATS_REQUEST } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectOrdersStatsRequest } from './selectors';
import { FETCH_ORDERS_PAGE_REQUEST } from 'common/store/orders/types';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';

function* fetchOrdersStatsSaga(): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectOrdersStatsRequest> = yield select(selectOrdersStatsRequest);

    const isNeedRequest = checkNeedRequest(requestStatus);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchOrdersStatsBegin());

    const [error, response]: ReturnApiT<typeof commonTranziitApi.fetchOrdersStatistics> =
        yield commonTranziitApi.fetchOrdersStatistics();
    if (error) {
        yield put(fetchOrdersStatsError(error));
        return;
    }

    yield put(fetchOrdersStatsSuccess(response));
}

function* ordersStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_ORDERS_STATS_REQUEST, FETCH_ORDERS_PAGE_REQUEST], fetchOrdersStatsSaga);
}

export default ordersStatsSaga;
