import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_COMPANY_REQUEST, UPDATE_COMPANY_REQUEST, UpdateCompanyActionT, FetchCompanyActionT } from './types';
import {
    fetchCompanyBegin,
    fetchCompanyError,
    fetchCompanySuccess,
    updateCompanyBegin,
    updateCompanyError,
    updateCompanySuccess,
} from './actions';

import { logWarning } from 'common/utils/logger';
import { selectCurrentCompanyRequestStatus } from 'common/store/company/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { companyRefreshChannel } from 'common/store/company/channels';

function* fetchCompanySaga(action: FetchCompanyActionT): WrapGeneratorT<void> {
    const { options } = action;

    const requestStatus: ReturnType<typeof selectCurrentCompanyRequestStatus> = yield select(
        selectCurrentCompanyRequestStatus,
    );
    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchCompanyBegin());
    const [error, currentCompany]: ReturnApiT<typeof commonTranziitApi.fetchCurrentCompany> =
        yield commonTranziitApi.fetchCurrentCompany();

    if (!currentCompany) {
        logWarning('fetched empty company');
    }

    if (error) {
        yield put(fetchCompanyError(error));
    } else {
        yield put(fetchCompanySuccess(currentCompany));
    }
}

function* updateCompanySaga(action: UpdateCompanyActionT): WrapGeneratorT<void> {
    const { companyChanges } = action;
    yield put(updateCompanyBegin());
    const [error]: ReturnApiT<typeof commonTranziitApi.patchCompany> = yield commonTranziitApi.patchCompany(
        companyChanges,
    );
    if (error) {
        yield put(updateCompanyError(error));
        return;
    }

    const [fetchError, currentCompany]: ReturnApiT<typeof commonTranziitApi.fetchCurrentCompany> =
        yield commonTranziitApi.fetchCurrentCompany();
    if (fetchError) {
        yield put(fetchCompanyError(fetchError));
        return;
    }

    if (!currentCompany) {
        logWarning('fetched empty company, after update');
    }

    yield put(updateCompanySuccess(currentCompany));

    companyRefreshChannel.emit({});
}

function* userSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_COMPANY_REQUEST, fetchCompanySaga);
    yield takeEvery(UPDATE_COMPANY_REQUEST, updateCompanySaga);
}

export default userSaga;
