import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import {
    AvailableSubjectStatusesResponseT,
    StateMachineEntityDescriptorT,
    StateMachineEntityHashT,
    StateMachineUpdateT,
    TypedAvailableSubjectStatusesResponseT,
} from 'common/store/state-machine/models';

export type StateMachineItemStateT<T = string> = {
    fetchRequest: RequestStatusT;
    availableSubjectStatuses: TypedAvailableSubjectStatusesResponseT<T> | null;
    updateRequest: RequestStatusT;
};

export const initialStateMachineItemState: StateMachineItemStateT = {
    fetchRequest: requestStatus.initial,
    availableSubjectStatuses: null,
    updateRequest: requestStatus.initial,
};

export type StateMachineStateT = Record<StateMachineEntityHashT, StateMachineItemStateT>;

const initialState: StateMachineStateT = {};

export const stateMachineSlice = createSlice({
    name: 'state-machine',
    initialState,
    reducers: {
        fetchStateMachineSteps: (
            state,
            action: PayloadAction<{
                descriptor: StateMachineEntityDescriptorT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchStateMachineStepsBegin: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
            }>,
        ) => {
            const { hash } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                fetchRequest: requestStatus.loading,
            };
        },
        fetchStateMachineStepsSuccess: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
                data: AvailableSubjectStatusesResponseT | null;
            }>,
        ) => {
            const { hash, data } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                fetchRequest: requestStatus.ok,
                availableSubjectStatuses: data,
            };
        },
        fetchStateMachineStepsError: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
                error: Error;
            }>,
        ) => {
            const { hash, error } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                fetchRequest: requestStatus.setError(error),
            };
        },
        updateEntityStatus: (
            state,
            action: PayloadAction<{
                update: StateMachineUpdateT;
                descriptor: StateMachineEntityDescriptorT;
                skipRefreshAvailableSteps?: boolean;
            }>,
        ) => {
            // nothing
        },
        updateEntityStatusBegin: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
            }>,
        ) => {
            const { hash } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                updateRequest: requestStatus.loading,
            };
        },
        updateEntityStatusSuccess: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
            }>,
        ) => {
            const { hash } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                updateRequest: requestStatus.ok,
            };
        },
        updateEntityStatusError: (
            state,
            action: PayloadAction<{
                hash: StateMachineEntityHashT;
                error: Error;
            }>,
        ) => {
            const { hash, error } = action.payload;

            const itemState = state[hash] || initialStateMachineItemState;

            state[hash] = {
                ...itemState,
                updateRequest: requestStatus.setError(error),
            };
        },
    },
});

export const {
    fetchStateMachineSteps,
    fetchStateMachineStepsBegin,
    fetchStateMachineStepsSuccess,
    fetchStateMachineStepsError,
    updateEntityStatus,
    updateEntityStatusBegin,
    updateEntityStatusSuccess,
    updateEntityStatusError,
} = stateMachineSlice.actions;

export default stateMachineSlice.reducer;
