import * as React from 'react';

type PropsT = {};

const HelpIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    fill="#6B6C6F"
                    d="M10 3c3.783 0 6.857 3.138 6.857 7 .629 0 1.143.525 1.143 1.167V13.5c0 .642-.514 1.167-1.143 1.167v1.166c0 .642-.514 1.167-1.143 1.167H8.857v-1.167h6.857V10c0-3.22-2.56-5.833-5.714-5.833-3.154 0-5.714 2.613-5.714 5.833v4.667H3.143C2.514 14.667 2 14.142 2 13.5v-2.333C2 10.525 2.514 10 3.143 10c0-3.862 3.074-7 6.857-7zm-2.857 7c.473 0 .857.392.857.875a.866.866 0 01-.857.875.866.866 0 01-.857-.875c0-.483.383-.875.857-.875zm5.714 0c.474 0 .857.392.857.875a.866.866 0 01-.857.875.866.866 0 01-.857-.875c0-.483.384-.875.857-.875zM10 5.333c2.114 0 3.874 1.47 4.389 3.465-2.823.234-4.458-1.691-5.063-3.033-.389 2.03-1.92 3.628-3.886 4.095.126-3.932 3.783-4.527 4.56-4.527z"
                />
            </g>
        </svg>
    );
};

HelpIcon.displayName = 'HelpIcon';

const storyProps: PropsT = {};

export { storyProps };
export default HelpIcon;
