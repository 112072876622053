import React from 'react';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { SidebarCloseConfirmationT } from 'common/layouts/SideBars/models';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type PropsT<T> = {
    isShow: boolean;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: () => void;
    data: T;
    getSidebarCloseConfirmation: (t: TFunction, data: T) => SidebarCloseConfirmationT | null;
};

const SideBarCloseConfirmation = <T,>(props: PropsT<T>): React.ReactElement | null => {
    const { isShow, onCancel, onClose, onConfirm, data, getSidebarCloseConfirmation } = props;

    const { t } = useTranslation();

    const modalContentProps = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return getSidebarCloseConfirmation(t, data);
    }, [t, data]);

    if (!modalContentProps) {
        return null;
    }

    const TEST_SELECTOR = 'close-sidebar-confirmation';

    const { confirmAction, cancelAction, icon, title, message } = modalContentProps;

    if (!isShow) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={icon}
                    title={title}
                    message={message}
                    actions={[
                        {
                            children: cancelAction,
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: confirmAction,
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm();
                            },
                        },
                    ]}
                />
            </ModalContent>
        </Modal>
    );
};

export default React.memo(SideBarCloseConfirmation) as typeof SideBarCloseConfirmation;
