import { ApiBrokerShipperContractDetailsT } from 'common/utils/api/models';
import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';

export const checkIsApiBrokerShipperContractDetails = (
    details: ShipperContractDetailsT | null,
): details is ApiBrokerShipperContractDetailsT => {
    if (!details) {
        return false;
    }

    return 'createdById' in details;
};
