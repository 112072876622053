import { NotificationActionEnum, NotificationBodyT, NotificationConverterT } from 'common/store/notifications/models';
import { logWarning } from 'common/utils/logger';
import { isNonNil } from 'common/utils';
import { TourStatusEnum } from 'common/utils/api/models';

const parseNotificationBody = <T extends keyof NotificationBodyT>(
    body: string | null | undefined,
): NotificationBodyT[T] => {
    try {
        return JSON.parse(body || '{}');
    } catch (error) {
        logWarning(`failed parse notification body: ${body}`);
        logWarning(error);
        return {} as NotificationBodyT[T];
    }
};

export const convertNotification: NotificationConverterT = (apiNotification, options) => {
    const baseNotification = {
        ...apiNotification,
        sourceAction: apiNotification.action || '',
        sourceBody: apiNotification.body || '',
        isNew: options.isNew,
    };

    switch (apiNotification.action) {
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPMENT_STATUS_CHANGED>(
                apiNotification.body,
            );

            return {
                ...baseNotification,
                action: NotificationActionEnum.SHIPMENT_STATUS_CHANGED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId, parsedBody.dispatchId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SHIPMENT_MISSED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPMENT_MISSED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SHIPMENT_MISSED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId, parsedBody.dispatchId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SHIPMENT_NO_SHOW: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPMENT_NO_SHOW>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SHIPMENT_NO_SHOW,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId, parsedBody.dispatchId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.ORDER_STATUS_CHANGED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.ORDER_STATUS_CHANGED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.TOUR_STATUS_CHANGED>(apiNotification.body);
            const baseTourStatusNotification = {
                ...baseNotification,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId, parsedBody.dispatchId].filter(isNonNil),
            };

            if (parsedBody.status === TourStatusEnum.assetsUnassigned && parsedBody.runOutOfTime) {
                return {
                    ...baseTourStatusNotification,
                    action: NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS,
                };
            }

            if (parsedBody.status === TourStatusEnum.assetsUnassigned && parsedBody.runningOutOfTime) {
                return {
                    ...baseTourStatusNotification,
                    action: NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS,
                };
            }

            if (parsedBody.status === TourStatusEnum.driverUnassigned && parsedBody.runOutOfTime) {
                return {
                    ...baseTourStatusNotification,
                    action: NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER,
                };
            }

            if (parsedBody.status === TourStatusEnum.driverUnassigned && parsedBody.runningOutOfTime) {
                return {
                    ...baseTourStatusNotification,
                    action: NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER,
                };
            }

            return {
                ...baseTourStatusNotification,
                action: NotificationActionEnum.TOUR_STATUS_CHANGED,
            };
        }
        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED>(
                apiNotification.body,
            );

            return {
                ...baseNotification,
                action: NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const parsedBody =
                parseNotificationBody<NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED>(
                    apiNotification.body,
                );

            return {
                ...baseNotification,
                action: NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.RFQ_ALERT: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.RFQ_ALERT>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.RFQ_ALERT,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_PLACED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_PLACED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_PLACED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_CANCELLED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_CANCELLED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_CANCELLED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_ASSIGNED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_ASSIGNED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_ASSIGNED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_EXPIRED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_EXPIRED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_EXPIRED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_ACCEPTED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_BID_ACCEPTED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_BID_ACCEPTED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_BID_EXPIRED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_BID_EXPIRED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_MISSED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_BID_MISSED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_BID_MISSED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_PLACED: {
            const parsedBody = parseNotificationBody<NotificationActionEnum.SPOT_BID_PLACED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.SPOT_BID_PLACED,
                body: parsedBody,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'CARRIER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.CARRIER_CONTRACT_EXPIRED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_EXPIRED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_EXPIRED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_EXPIRED,
                body: parsedBody,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'CARRIER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.CARRIER_CONTRACT_COMPLETED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_COMPLETED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_COMPLETED>(apiNotification.body);

            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_COMPLETED,
                body: parsedBody,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            if (baseNotification.subjectType === 'CARRIER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_EXPIRATION_SOON>(
                apiNotification.body,
            );

            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_EXPIRATION_SOON,
                body: parsedBody,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_REVOKED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_REVOKED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }
            if (baseNotification.subjectType === 'CARRIER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.CARRIER_CONTRACT_REVOKED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_REVOKED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_REVOKED>(apiNotification.body);
            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_REVOKED,
                body: parsedBody,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_UPLOAD>(apiNotification.body);
            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_UPLOAD,
                body: parsedBody,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }
            if (baseNotification.subjectType === 'SHIPPER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.SHIPPER_CONTRACT_UPDATED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_UPDATED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }
            if (baseNotification.subjectType === 'CARRIER_CONTRACT') {
                const parsedBody = parseNotificationBody<NotificationActionEnum.CARRIER_CONTRACT_UPDATED>(
                    apiNotification.body,
                );

                return {
                    ...baseNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_UPDATED,
                    body: parsedBody,
                    relatedIds: [],
                };
            }

            const parsedBody = parseNotificationBody<NotificationActionEnum.DOCUMENT_UPDATED>(apiNotification.body);
            return {
                ...baseNotification,
                action: NotificationActionEnum.DOCUMENT_UPDATED,
                body: parsedBody,
                relatedIds: [],
            };
        }
        default: {
            logWarning(`Not supported notification: ${apiNotification.action}`);

            return {
                ...baseNotification,
                action: NotificationActionEnum.UNKNOWN_ACTION,
                originAction: apiNotification.action,
                body: {},
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
    }
};
