import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { RevokeContractActionEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type ValueT = RevokeContractActionEnum | null;

export type ActionOptionT = {
    label: React.ReactNode;
    value: ValueT;
};

type PropsT = {
    className?: string;
    value: ValueT;
    onSelect: (value: ValueT) => void;
    onBlur?: () => void;
    onFocus?: () => void;
};

const RevokeContractActionDropdown: React.FC<PropsT> = React.memo((props) => {
    const { className, value, onSelect, onBlur, onFocus } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): Array<ActionOptionT> => {
        return [
            {
                value: RevokeContractActionEnum.setNewContract,
                label: t('common:carrier-contracts.revoke-confirmation.actions.set-new-contract'),
            },
            {
                value: RevokeContractActionEnum.keepOldRate,
                label: t('common:carrier-contracts.revoke-confirmation.actions.keep-old-rate'),
            },
            {
                value: RevokeContractActionEnum.setNewRate,
                label: t('common:carrier-contracts.revoke-confirmation.actions.set-new-rate'),
            },
        ];
    }, [t]);

    return (
        <DropdownInput<ActionOptionT, ActionOptionT['value']>
            className={className}
            selectedValue={value}
            options={options}
            onSelect={onSelect}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTriggerContent={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            getOptionValue={(option) => option?.value}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
});

export default RevokeContractActionDropdown;
