import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BaseCarrierContractsLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    ApiCarrierContractStatusT,
    ApiRevokePartnerContractInfoT,
    CarrierContractStatusEnum,
} from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { pickFile } from 'common/utils/pick-file';

import { selectPermissions } from 'common/store/auth/selectors';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import BaseCarrierContractsTable from 'common/layouts/BaseCarrierContractsLayout/BaseCarrierContractsTable/BaseCarrierContractsTable';
import RevokeContractConfirmation, {
    ConfirmRevokeContractModalDataT,
} from './dialogs/RevokeContractConfirmation/RevokeContractConfirmation';
import { ContractRowT } from 'common/layouts/BaseCarrierContractsLayout/BaseCarrierContractsTable/models';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import {
    downloadCarrierContract,
    fetchCarrierContracts,
    revokeCarrierContract,
    uploadCarrierContract,
} from 'common/store/carrier-contracts/actions';
import {
    selectCarrierContracts,
    selectFetchCarrierContractsRequest,
    selectUpdateCarrierContractRequest,
    selectUploadCarrierContractRequest,
} from 'common/store/carrier-contracts/selectors';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { carrierContractsRefreshChannel } from 'common/store/carrier-contracts/channels';

const cx = classNames.bind(styles);

type PropsT = {
    tableName: string;
    isLoading: boolean;

    testSelector?: string;
    contactStatusesAttentionSet?: Set<ApiCarrierContractStatusT | null>;

    navigationTabs: React.ReactNode;
};

const BaseCarrierContractsLayout: React.FC<PropsT> = React.memo((props) => {
    const { tableName, testSelector, navigationTabs, contactStatusesAttentionSet } = props;

    const { partnerId, partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchCarrierContracts(partnerId, { isForceUpdate: true }));
    }, [partnerId]);
    useChannelSubscribe(carrierContractsRefreshChannel, refreshPageHandler);

    const carrierContracts = useSelector(selectCarrierContracts(partnerId));

    const confirmRevokeContractModalDialog = useModalDialog<ConfirmRevokeContractModalDataT>();

    const fetchContractsRequest = useSelector(selectFetchCarrierContractsRequest(partnerId));
    const updateCarrierContractRequest = useSelector(selectUpdateCarrierContractRequest(partnerId));

    const uploadCarrierContractRequest = useSelector(selectUploadCarrierContractRequest(partnerId));

    const isWaitAction = uploadCarrierContractRequest.loading || updateCarrierContractRequest.loading;

    const handleDownloadContract = (carrierContractId: CarrierContractIdT) => {
        dispatch(downloadCarrierContract(carrierContractId, partnerId));
    };

    const handleConfirmRevokeContract = (contractId: CarrierContractIdT, revokeInfo: ApiRevokePartnerContractInfoT) => {
        dispatch(revokeCarrierContract({ partnerId, contractId, revokeInfo }));
    };

    const handleUploadContract = (): void => {
        pickFile('application/pdf', (file) => {
            dispatch(uploadCarrierContract(partnerId, file));
        });
    };

    const handleRevokeContract = (data: ConfirmRevokeContractModalDataT) => {
        confirmRevokeContractModalDialog.setData(data);
    };

    const handleEditContractInfo = (contractId: ContactIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.carrierContract,
            contractId,
            initIsEditing: true,
        });
    };

    useCloseModalDialogAfterRequest(updateCarrierContractRequest, [confirmRevokeContractModalDialog]);

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in dispatch details, empty user id');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const handleSelectTableRow = (row: ContractRowT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.carrierContract,
            contractId: row.id,
            initIsEditing:
                permissions.canReviewCarrierContracts && row?.status === CarrierContractStatusEnum.waitingForApprove,
        });
    };

    const isLoading = props.isLoading || fetchContractsRequest.loading;

    return (
        <>
            <div className={cx('navigation-tabs')}>{navigationTabs}</div>
            {isWaitAction && <LoaderOverlay />}
            <BaseCarrierContractsTable
                tableName={tableName}
                testSelector={testSelector}
                isLoading={isLoading}
                rows={carrierContracts}
                contactStatusesAttentionSet={contactStatusesAttentionSet}
                onSelectRow={handleSelectTableRow}
                onOpenUserDetails={handleOpenUserDetails}
                onRevokeContract={handleRevokeContract}
                onEditContractDetails={handleEditContractInfo}
                onDownloadContract={handleDownloadContract}
                headerNode={
                    permissions.canUploadCarrierContracts ? (
                        <div>
                            <Button
                                className={cx('upload-pseudo-row')}
                                theme={ButtonThemeEnum.secondary}
                                onClick={handleUploadContract}
                            >
                                <span className={cx('upload-text')}>
                                    {t('common:carrier-contracts.table.actions.upload')}
                                </span>
                            </Button>
                        </div>
                    ) : null
                }
            />
            <RevokeContractConfirmation
                data={confirmRevokeContractModalDialog.data}
                onCancel={confirmRevokeContractModalDialog.onCancel}
                onClose={confirmRevokeContractModalDialog.onClose}
                onConfirm={handleConfirmRevokeContract}
                requestStatus={updateCarrierContractRequest}
            />
        </>
    );
});

export default BaseCarrierContractsLayout;
