import * as React from 'react';
import { ApiTourStatusT, TourStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status?: ApiTourStatusT | null | undefined;
};

export const TOUR_STATUS_T_MAP: Record<ApiTourStatusT, string> = {
    [TourStatusEnum.carrierUnassigned]: 'common:tour-status.CARRIER_UNASSIGNED',
    [TourStatusEnum.assetsUnassigned]: 'common:tour-status.UNASSIGNED',
    [TourStatusEnum.assigned]: 'common:tour-status.ASSIGNED',
    [TourStatusEnum.inTransit]: 'common:tour-status.IN_TRANSIT',
    [TourStatusEnum.finished]: 'common:tour-status.FINISHED',
    [TourStatusEnum.canceled]: 'common:tour-status.CANCELED',
    [TourStatusEnum.driverUnassigned]: 'common:tour-status.DRIVER_UNASSIGNED',
};

const TourStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(TOUR_STATUS_T_MAP[status]);
});

export default TourStatus;
