import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DictAdditionalServiceEnumT } from 'common/store/additional-services-dict/models';
import isNil from 'lodash/isNil';

export type PropsT = {
    enumType?: DictAdditionalServiceEnumT;
};

const i18nMap: Record<DictAdditionalServiceEnumT, string> = {
    A_WARNING_SIGN_WASTE: 'common:additional-service.by-enum.A_WARNING_SIGN_WASTE',
    ADR_HAZMAT: 'common:additional-service.by-enum.ADR_HAZMAT',
    ATP_CERTIFICATE_FRC_CATEGORY: 'common:additional-service.by-enum.ATP_CERTIFICATE_FRC_CATEGORY',
    CATEGORY_III_CERTIFICATE: 'common:additional-service.by-enum.CATEGORY_III_CERTIFICATE',
    CUSTOMS_SEAL_PROOF_TIR: 'common:additional-service.by-enum.CUSTOMS_SEAL_PROOF_TIR',
    DROP_TRAILER_REQUIRED: 'common:additional-service.by-enum.DROP_TRAILER_REQUIRED',
    DUAL_TEMPERATURE: 'common:additional-service.by-enum.DUAL_TEMPERATURE',
    FLOWER_TROLLEY_WIDE: 'common:additional-service.by-enum.FLOWER_TROLLEY_WIDE',
    MEAT_HOOKS: 'common:additional-service.by-enum.MEAT_HOOKS',
    PALLET_EXCHANGE_CATEGORY_A: 'common:additional-service.by-enum.PALLET_EXCHANGE_CATEGORY_A',
    PALLET_EXCHANGE_CATEGORY_B_AND_C: 'common:additional-service.by-enum.PALLET_EXCHANGE_CATEGORY_B_AND_C',
    PARTITION_WALL: 'common:additional-service.by-enum.PARTITION_WALL',
    TAIL_LIFT: 'common:additional-service.by-enum.TAIL_LIFT',
    COIL_TRANSPORTS: 'common:additional-service.by-enum.COIL_TRANSPORTS',
    JOLODA_LOADING_SYSTEM: 'common:additional-service.by-enum.JOLODA_LOADING_SYSTEM',
    LIGHT_VERSION: 'common:additional-service.by-enum.LIGHT_VERSION',
    WALKING_FLOOR: 'common:additional-service.by-enum.WALKING_FLOOR',
    XL_CODE_CERTIFIED: 'common:additional-service.by-enum.XL_CODE_CERTIFIED',
    ANTI_SLIP_MATS: 'common:additional-service.by-enum.ANTI_SLIP_MATS',
    CARGO_EDGE_PROTECTORS: 'common:additional-service.by-enum.CARGO_EDGE_PROTECTORS',
    CARGO_LOAD_LOCKS: 'common:additional-service.by-enum.CARGO_LOAD_LOCKS',
    CARGO_RATCHET_STRAPS: 'common:additional-service.by-enum.CARGO_RATCHET_STRAPS',
    CARGO_SHORING_BEAMS: 'common:additional-service.by-enum.CARGO_SHORING_BEAMS',
    TIME_SLOTS_APPOINTMENT_BY_BROKER: 'common:additional-service.by-enum.TIME_SLOTS_APPOINTMENT_BY_BROKER',
    GDP_PHARMACEUTICAL: 'common:additional-service.by-enum.GDP_PHARMACEUTICAL',
    IFS_FOOD: 'common:additional-service.by-enum.IFS_FOOD',
    ISO_14001: 'common:additional-service.by-enum.ISO_14001',
    ISO_9001: 'common:additional-service.by-enum.ISO_9001',
    SQAS_CHEMICAL: 'common:additional-service.by-enum.SQAS_CHEMICAL',
    WASTE_MANAGEMENT_A_SIGN: 'common:additional-service.by-enum.WASTE_MANAGEMENT_A_SIGN',
    BATTERY_ELECTRIC_VEHICLE: 'common:additional-service.by-enum.BATTERY_ELECTRIC_VEHICLE',
    COMPRESSED_GAS: 'common:additional-service.by-enum.COMPRESSED_GAS',
    ALTERNATIVE_FUEL_VEHICLE: 'common:additional-service.by-enum.ALTERNATIVE_FUEL_VEHICLE',
};

const AdditionalServiceLabel: React.FC<PropsT> = React.memo((props) => {
    const { enumType } = props;

    const { t } = useTranslation();

    if (isNil(enumType)) {
        return null;
    }

    return <span>{i18nMap[enumType] ? t(i18nMap[enumType]) : enumType}</span>;
});

export default AdditionalServiceLabel;
