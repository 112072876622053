import { AnyAlert } from './models';

export const ADD_ALERT = 'common/alert/ADD_ALERT';
export const DELETE_ALERT = 'common/alert/DELETE_ALERT';

export type AddAlertActionT = {
    type: typeof ADD_ALERT;
    anyAlert: AnyAlert;
};

export type DeleteAlertActionT = {
    type: typeof DELETE_ALERT;
    alertId: AlertIdT;
};

export type AlertsActionT = AddAlertActionT | DeleteAlertActionT;

export type AlertsStateT = {
    byId: Record<AlertIdT, AnyAlert>;
    ids: Array<AlertIdT>;
};
