import {
    FETCH_SETTINGS_BEGIN,
    FETCH_SETTINGS_ERROR,
    FETCH_SETTINGS_SUCCESS,
    SettingsActionT,
    SettingsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';

const initialState: SettingsStateT = {
    fetchRequest: requestStatus.initial,
    data: {},
};

export default (state = initialState, action: SettingsActionT): SettingsStateT => {
    switch (action.type) {
        case FETCH_SETTINGS_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_SETTINGS_SUCCESS: {
            const { settings } = action;

            return {
                ...state,
                data: settings,
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_SETTINGS_ERROR: {
            const { error } = action;
            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        default: {
            return state;
        }
    }
};
