import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import { PartnerTypeEnum } from 'common/utils/api/models';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { StyleGuideColorsEnum } from 'common/constants';

export type BaseConfirmApproveDocumentModalDataT<T = any> = T & {
    partnerType: PartnerTypeEnum;
    dictDocumentId: DictDocumentIdT;
};

type PropsT<T = any> = {
    data: BaseConfirmApproveDocumentModalDataT<T> | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: BaseConfirmApproveDocumentModalDataT<T>) => void;
    isLoading: boolean;
    name: string | undefined;
};

const TEST_SELECTOR = 'approve-document-confirmation';

const ApproveDocumentsConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { name, data, onClose, onConfirm, isLoading } = props;
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: isLoading,
                            onClick: () => {
                                onClose();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.approve'),
                            theme: ButtonThemeEnum.primary,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading,
                            isDisabled: isLoading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                    title={t('common:documents.approve-confirmation.title')}
                    message={t('common:documents.approve-confirmation.description', {
                        document: name,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default ApproveDocumentsConfirmation;
