import { FieldsEnum, FormValuesT } from './constants';
import { formateEndDateForQuery, formateStartDateForQuery } from 'common/utils/query';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { QueryFiltersT, QueryFiltersKeysEnum } from 'common/layouts/TrucksPageLayout/query-models';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.model]: values[FieldsEnum.model] || undefined,
        [QueryFiltersKeysEnum.status]: values[FieldsEnum.status] || undefined,
        [QueryFiltersKeysEnum.emissionClass]: values[FieldsEnum.emissionClass] || undefined,
        [QueryFiltersKeysEnum.link]: values[FieldsEnum.link] || undefined,
        [QueryFiltersKeysEnum.GPSTracker]: values[FieldsEnum.GPSTracker] || undefined,
        [QueryFiltersKeysEnum.dedication]: values[FieldsEnum.dedication] || undefined,
    };

    if (values[FieldsEnum.plateNumber]) {
        queryFilters[QueryFiltersKeysEnum.plateNumber] = simpleStringFormatter(values[FieldsEnum.plateNumber]);
    }

    const createDates = values[FieldsEnum.createDates];
    if (createDates?.from) {
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = formateStartDateForQuery(createDates.from);
    }
    if (createDates?.to) {
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = formateEndDateForQuery(createDates.to);
    }

    const lastModifyDates = values[FieldsEnum.lastModifyDates];
    if (lastModifyDates?.from) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] = formateStartDateForQuery(lastModifyDates.from);
    }
    if (lastModifyDates?.to) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] = formateEndDateForQuery(lastModifyDates.to);
    }

    return queryFilters;
};

export default getQueryFilters;
