import { CountriesListT, CountryLanguageT, CountryT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const COUNTRIES_DICT_REQUEST = 'common/countries-dict/COUNTRIES_DICT_REQUEST';
export const COUNTRIES_DICT_REQUEST_BEGIN = 'common/countries-dict/COUNTRIES_DICT_REQUEST_BEGIN';
export const COUNTRIES_DICT_REQUEST_SUCCESS = 'common/countries-dict/COUNTRIES_DICT_REQUEST_SUCCESS';
export const COUNTRIES_DICT_REQUEST_ERROR = 'common/countries-dict/COUNTRIES_DICT_REQUEST_ERROR';

export type FetchCountriesDictActionT = {
    type: typeof COUNTRIES_DICT_REQUEST;
};

export type FetchCountriesDictBeginActionT = {
    type: typeof COUNTRIES_DICT_REQUEST_BEGIN;
};

export type FetchCountriesDictSuccessActionT = {
    type: typeof COUNTRIES_DICT_REQUEST_SUCCESS;
    countriesList: CountriesListT;
};

export type FetchCountriesDictErrorActionT = {
    type: typeof COUNTRIES_DICT_REQUEST_ERROR;
    error: Error;
};

export type CountriesDictActionT =
    | FetchCountriesDictActionT
    | FetchCountriesDictBeginActionT
    | FetchCountriesDictSuccessActionT
    | FetchCountriesDictErrorActionT;

export type CountriesDictStateT = {
    list: CountriesListT | [];
    allLangCodes: Array<CountryT['langCode']>;
    byLangCode: Record<CountryT['langCode'], CountryLanguageT>;
    allCodes: Array<CountryT['code']>;
    byCode: Partial<Record<CountryT['code'], CountryT>>;
    request: RequestStatusT;
};
