import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TableError.scss';
import ContactError from 'common/components/ContactError/ContactError';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const TableError: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    return (
        <div className={cs(className, cx('content'))}>
            <ContactError testSelector="table-message" />
        </div>
    );
});

export default TableError;
