import React from 'react';
import { LocationDescriptor } from 'history';

export type CommonUrlFactoryContextT = {
    trailerDetails: (partnerId: PartnerIdT, trailerId: TrailerIdT) => LocationDescriptor<HistoryStateT>;
    truckDetails: (partnerId: PartnerIdT, truckId: TruckIdT) => LocationDescriptor<HistoryStateT>;
};

const CommonUrlFactoryContext = React.createContext<CommonUrlFactoryContextT>({
    // NB: the values will always be defined because context is used in the App root!
    trailerDetails: () => {
        return {};
    },
    truckDetails: () => {
        return {};
    },
});

export default CommonUrlFactoryContext;
