import { RequestStatusT } from 'common/utils/request-status';
import { PartnerContractDetailsT } from './models';

export const FETCH_CARRIER_CONTRACT_DETAILS_REQUEST =
    'common/carrier-contract-details/FETCH_CARRIER_CONTRACT_DETAILS_REQUEST';
export const FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN =
    'common/carrier-contract-details/FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN';
export const FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR =
    'common/carrier-contract-details/FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR';
export const FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS =
    'common/carrier-contract-details/FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS';

export type CarrierContractStateT = {
    data: PartnerContractDetailsT | null;
    fetchRequest: RequestStatusT;
};

export type CarrierContractsDetailStateT = Record<CarrierContractIdT, CarrierContractStateT>;

export type FetchCarrierContractDetailsActionT = {
    type: typeof FETCH_CARRIER_CONTRACT_DETAILS_REQUEST;
    contractId: CarrierContractIdT;
    partnerId: PartnerIdT;
    options?: FetchOptionsT;
};

export type FetchCarrierContractDetailsBeginActionT = {
    type: typeof FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_BEGIN;
    contractId: CarrierContractIdT;
};

export type FetchCarrierContractDetailsSuccessActionT = {
    type: typeof FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_SUCCESS;
    contractId: CarrierContractIdT;
    contractDetails: PartnerContractDetailsT | null;
};

export type FetchCarrierContractDetailsErrorActionT = {
    type: typeof FETCH_CARRIER_CONTRACT_DETAILS_REQUEST_ERROR;
    error: Error;
    contractId: CarrierContractIdT;
};

export type CarrierContractDetailsActionT =
    | FetchCarrierContractDetailsActionT
    | FetchCarrierContractDetailsBeginActionT
    | FetchCarrierContractDetailsSuccessActionT
    | FetchCarrierContractDetailsErrorActionT;
