import { ApiCompanyContactMediaT, ApiUpdateDriverRequestT } from 'common/utils/api/models';
import { FieldsEnum, FormValuesT, MEDIA_FIELDS_NAME } from './constants';
import moment from 'moment';
import { parsePhoneNumber, parseSimpleNumber } from 'common/utils/input-parsers';
import { CompanyDriverDetailsT } from 'common/store/drivers/models';
import isNumber from 'lodash/isNumber';

const prepareApiDriverChanges = (
    formValues: FormValuesT,
    driverDetails?: CompanyDriverDetailsT | null,
): ApiUpdateDriverRequestT | null => {
    const medias = MEDIA_FIELDS_NAME.reduce<ApiCompanyContactMediaT[]>((acc, fieldName) => {
        if (formValues[fieldName]) {
            acc.push({
                type: fieldName,
                login: formValues[fieldName] as string,
            });
        }

        return acc;
    }, []);

    const rate = parseSimpleNumber(formValues[FieldsEnum.ratePerKm]);
    if (!driverDetails?.contact || !isNumber(rate)) {
        return null;
    }

    const language = formValues[FieldsEnum.language] || undefined;

    return {
        ...driverDetails,
        contact: {
            ...driverDetails.contact,
            formal: formValues[FieldsEnum.formal],
            name: formValues[FieldsEnum.firstName],
            surname: formValues[FieldsEnum.lastName],

            deskPhone: parsePhoneNumber(formValues[FieldsEnum.desktopPhone]) || undefined,

            language,

            birthday: moment(formValues[FieldsEnum.birthday]).format('YYYY-MM-DD'),
            residence: formValues[FieldsEnum.countryCode] || undefined,

            medias,
        },
        homeAddress: formValues[FieldsEnum.address],
        rate,
        language,
    };
};

export default prepareApiDriverChanges;
