import React from 'react';

import classNames from 'classnames/bind';

import styles from './LinkDatePicker.scss';

import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import Calendar, {
    CalendarDateRangeValuePropsT,
    CalendarDateValuePropsT,
} from 'design-system/components/Calendar/Calendar';
import DatePickerOverlay, { DatePickerOverlayPositionEnum } from '../internals/DatePickerOverlay/DatePickerOverlay';
import DateLinkTrigger, {
    DateLinkTriggerPropsT,
    DateLinkTriggerRangePropsT,
} from 'design-system/components/date-pickers/internals/DateLinkTrigger/DateLinkTrigger';

const cx = classNames.bind(styles);

type BasePropsT = {
    leftIcon?: React.ReactNode;
    placeholder?: string;
    isDisabled?: boolean;
    minDate?: string | null;
    maxDate?: string | null;
    overlayPosition?: DatePickerOverlayPositionEnum;
    hasYearMonthForm?: boolean;
};

export type LinkDatePickerPropsT = (BasePropsT & CalendarDateValuePropsT) | (BasePropsT & CalendarDateRangeValuePropsT);

/* eslint-disable react/destructuring-assignment */
const LinkDatePicker: React.FC<LinkDatePickerPropsT> = (props) => {
    const { placeholder, isDisabled, minDate, maxDate, overlayPosition, hasYearMonthForm, leftIcon } = props;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleCalendarRangeChange: CalendarDateRangeValuePropsT['onChange'] = (value, selectedDate) => {
        if (props.isRange) {
            props.onChange(value, selectedDate);
        }
    };

    const handleCalendarDateChange: CalendarDateValuePropsT['onChange'] = (value, selectedDate) => {
        toggleOpen(false);

        if (!props.isRange) {
            props.onChange(value, selectedDate);
        }
    };

    const handleOpen = () => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);
    };

    const handleOuterEvent = () => {
        toggleOpen(false);
    };

    let dateLinkTriggerProps: DateLinkTriggerPropsT | DateLinkTriggerRangePropsT;
    if (props.isRange) {
        dateLinkTriggerProps = {
            isRange: true,
            value: props.value,
        };
    } else {
        dateLinkTriggerProps = {
            isRange: false,
            value: props.value,
        };
    }

    let calendarValueProps: CalendarDateValuePropsT | CalendarDateRangeValuePropsT;
    if (props.isRange) {
        calendarValueProps = {
            isRange: true,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarRangeChange,
            focusInitialDate: props.value?.from,
        };
    } else {
        calendarValueProps = {
            isRange: false,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarDateChange,
            focusInitialDate: props.value,
        };
    }

    return (
        <div className={cx('dropdown')}>
            <OutsideClickHandler isDisabled={!isOpen} onOutsideClick={handleOuterEvent}>
                <div className={cx('input')}>
                    <DateLinkTrigger
                        leftIcon={leftIcon}
                        isDisabled={isDisabled}
                        className={cx('trigger')}
                        {...dateLinkTriggerProps}
                        onClick={() => handleOpen()}
                        placeholder={placeholder}
                    />
                    {isOpen && (
                        <DatePickerOverlay position={overlayPosition}>
                            <Calendar
                                minDate={minDate}
                                maxDate={maxDate}
                                hasYearMonthForm={hasYearMonthForm}
                                testSelectorPrefix="link-date-picker"
                                {...calendarValueProps}
                            />
                        </DatePickerOverlay>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default LinkDatePicker;
export { DatePickerOverlayPositionEnum };
