import * as React from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { selectCountriesByCode, selectCountriesDictRequest } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { CountryCodeT } from 'common/utils/api/models';

export type PropsT = {
    className?: string;
    countryCode: CountryCodeT | null | undefined;
};

const AsyncDictCountryNameLabel: React.FC<PropsT> = memo((props) => {
    const { className, countryCode } = props;

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const requestStatus = useSelector(selectCountriesDictRequest);

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const country = countryByCode[countryCode as CountryCodeT] || null;

    if (requestStatus.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.gray} size={DEFAULT_ICON_SIZE} />;
    }

    return <span className={className}>{country?.userLangDisplayName}</span>;
});

export default AsyncDictCountryNameLabel;
