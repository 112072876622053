import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';
import SelectedFilters from '../SelectedFilters/SelectedFilters';
import { clearEmptyValues } from 'common/utils/query';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import BrokerAdminUserSuggest from 'common/components/suggests/BrokerAdminUserSuggest/BrokerAdminUserSuggest';
import { selectPermissions } from 'common/store/auth/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose, setQueryFilters, queryFilters } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const permissions = useSelector(selectPermissions);

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            clearEmptyValues(queryFilters);

            setQueryFilters({
                ...initialQueryFilters,
                ...(queryFilters[QueryFiltersKeysEnum.address]
                    ? {
                          [QueryFiltersKeysEnum.text]: undefined,
                      }
                    : {}),
                ...queryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const hasAddressValue = !!formik.values[FieldsEnum.address];

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:shipper-contract-lanes.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field', 'field--trailer-type')}
                    name={FieldsEnum.dictTrailerId}
                    error={formik.errors[FieldsEnum.dictTrailerId]}
                    meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                    label={t('common:shipper-contract-lanes.filters.fields.trailer-type.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DictTrailerDropdown
                            placeholder={t('common:shipper-contract-lanes.filters.fields.trailer-type.placeholder')}
                            value={formik.values[FieldsEnum.dictTrailerId]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--address')}
                    name={FieldsEnum.address}
                    error={formik.errors[FieldsEnum.address]}
                    meta={formik.getFieldMeta(FieldsEnum.address)}
                    label={t('common:shipper-contract-lanes.filters.fields.address.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipPosition={TooltipPositionEnum.topLeft}
                >
                    {(props) => (
                        <Input
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            name={FieldsEnum.address}
                            value={formik.values[FieldsEnum.address] || ''}
                            placeholder={t('common:shipper-contract-lanes.filters.fields.address.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.validFromDates}
                        error={formik.errors[FieldsEnum.validFromDates]}
                        meta={formik.getFieldMeta(FieldsEnum.validFromDates)}
                        label={t('common:shipper-contract-lanes.filters.fields.valid-from-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.validFromDates]}
                                placeholder={t(
                                    'common:shipper-contract-lanes.filters.fields.valid-from-dates.placeholder',
                                )}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                                hasYearMonthForm
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.validTillDates}
                        error={formik.errors[FieldsEnum.validTillDates]}
                        meta={formik.getFieldMeta(FieldsEnum.validTillDates)}
                        label={t('common:shipper-contract-lanes.filters.fields.valid-till-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.validTillDates]}
                                placeholder={t(
                                    'common:shipper-contract-lanes.filters.fields.valid-till-dates.placeholder',
                                )}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                                hasYearMonthForm
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.createdDates}
                        error={formik.errors[FieldsEnum.createdDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createdDates)}
                        label={t('common:shipper-contract-lanes.filters.fields.added-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createdDates]}
                                placeholder={t('common:shipper-contract-lanes.filters.fields.added-dates.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                                hasYearMonthForm
                            />
                        )}
                    </FormikField>
                    {permissions.canUseBrokerAdminSuggest ? (
                        <FormikField
                            className={cx('field', 'field--user')}
                            name={FieldsEnum.createdById}
                            error={formik.errors[FieldsEnum.createdById]}
                            meta={formik.getFieldMeta(FieldsEnum.createdById)}
                            label={t('common:shipper-contract-lanes.filters.fields.added-by.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <BrokerAdminUserSuggest
                                    initialValue={formik.values[FieldsEnum.createdById]}
                                    initialLabel={formik.values[FieldsEnum.createdByName]}
                                    value={formik.values[FieldsEnum.createdById]}
                                    placeholder={t('common:shipper-contract-lanes.filters.fields.added-by.placeholder')}
                                    onSelect={(userId, userName) => {
                                        props.onChange(userId);
                                        formik.setFieldValue(FieldsEnum.createdByName, userName);
                                    }}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldGroupEmptyItem className={cx('field', 'field--user')} />
                    )}
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:shipper-contract-lanes.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;
