import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty, checkRangeNotIncludeDisabledDates } from 'common/utils/form-validators';
import isNumber from 'lodash/isNumber';

const REQUIRED_FIELDS = [FieldsEnum.dateRange, FieldsEnum.dropoffLocation] as const;

const validate = (t: TFunction, values: FormValuesT, disabledDates: string[]): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkRangeNotIncludeDisabledDates(t, [FieldsEnum.dateRange], values, disabledDates),
    };

    if (
        values[FieldsEnum.dropoffLocation] &&
        (!isNumber(values[FieldsEnum.dropoffLocation]?.point?.lng) ||
            !isNumber(values[FieldsEnum.dropoffLocation]?.point?.lat))
    ) {
        errors[FieldsEnum.dropoffLocation] = t('common:assets.set-unavailable.errors.wrong-location');
    }

    return errors;
};

export default validate;
