import { AdditionalServicesDictStateT } from './types';
import { SharableStateT } from 'common/store/models';

export const selectAdditionalServicesById = (state: SharableStateT): AdditionalServicesDictStateT['byId'] => {
    return state.additionalServicesDict.byId;
};

export const selectAdditionalServicesByType = (state: SharableStateT): AdditionalServicesDictStateT['byType'] => {
    return state.additionalServicesDict.byType;
};

export const selectAdditionalServicesByEnum = (state: SharableStateT): AdditionalServicesDictStateT['byEnum'] => {
    return state.additionalServicesDict.byEnum;
};

export const selectAdditionalServicesDictStatus = (
    state: SharableStateT,
): AdditionalServicesDictStateT['fetchRequest'] => {
    return state.additionalServicesDict.fetchRequest;
};
