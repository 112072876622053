import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SkeletonFormatter.scss';

const cx = classNames.bind(styles);

type PropsT = {};

const SkeletonFormatter: React.FC<PropsT> = React.memo((props) => {
    return <div className={cx('skeleton')} />;
});

export default SkeletonFormatter;
