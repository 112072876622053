import React, { useCallback, useMemo } from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import keyBy from 'lodash/keyBy';
import { AsyncRequestFactoryT } from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import { checkIsDefaultCompanyId } from 'common/utils';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import isNil from 'lodash/isNil';
import UserDropdownOptionLabel from 'common/components/dropdowns/options/UserDropdownOptionLabel/UserDropdownOptionLabel';
import { useAsyncFilterOptions } from 'design-system/components/dropdowns/DropdownSearchInput/hooks/use-async-filter-options';

type OptionT = {
    fullName?: string;
    userId?: UserIdT;
};

type ValueT = OptionT['userId'] | null;

export type PropsT = {
    className?: string;
    companyId: CompanyIdT;
    initialValue: ValueT;
    initialLabel: string | null;
    onSelect: (value: ValueT, label?: string) => void;
    value: ValueT;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
    hasClearControl?: boolean;
};

const TeamMemberSuggest: React.FC<PropsT> = (props) => {
    const {
        className,
        companyId,
        initialValue,
        initialLabel,
        value,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        onBlur,
        onFocus,
        hasChanges,
        hasClearControl,
        placeholder,
    } = props;

    const initialOption = useMemo((): OptionT | null => {
        if (!initialValue) {
            return null;
        }

        return {
            fullName: initialLabel || 'Unknown user',
            userId: initialValue,
        };
    }, [initialLabel, initialValue]);

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback(
        (query) => {
            const isPartner = !checkIsDefaultCompanyId(companyId);
            if (isPartner) {
                return brokerTranziitApi.findPartnerUsersByName(companyId, query);
            }

            return commonTranziitApi.findCompanyUsersByName(query);
        },
        [companyId],
    );

    const getOptionText = useCallback((option: OptionT) => option.fullName || '', []);

    const asyncFilterOptionsRequest = useAsyncFilterOptions<OptionT>({
        initialOption,
        requestFactory,
        getOptionText,
    });

    const optionByUserId = React.useMemo(() => {
        return keyBy(asyncFilterOptionsRequest.options, 'userId');
    }, [asyncFilterOptionsRequest.options]);

    const renderOption = (option?: OptionT, placeholder?: string): React.ReactNode => {
        if (isNil(option)) {
            return <UserDropdownOptionLabel label={placeholder || ''} isPlaceholder />;
        }

        return <UserDropdownOptionLabel label={option?.fullName || ''} />;
    };

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }

        asyncFilterOptionsRequest.onRefresh();
    }, [onFocus, asyncFilterOptionsRequest.onRefresh]);

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={asyncFilterOptionsRequest.options}
            isDisabled={isDisabled}
            onSelect={(value) => {
                const option = value ? optionByUserId[value] : null;
                const label = option?.fullName || '';

                onSelect(value, label);
            }}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncFilterOptionsRequest.onChangeQuery}
            renderRightIcon={() =>
                asyncFilterOptionsRequest.requestStatus.loading ? (
                    <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                ) : null
            }
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={(option) => option?.userId}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={handleFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={
                hasClearControl
                    ? () => {
                          onSelect(null);
                      }
                    : undefined
            }
        />
    );
};

export default TeamMemberSuggest;
