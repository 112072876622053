import React from 'react';

import classNames from 'classnames/bind';
import styles from './ZoneText.scss';
import { RangeSelectorZoneThemeEnum } from '../constants';

const cx = classNames.bind(styles);

export type PropsT = {
    text: React.ReactNode;
    containerRef: React.RefObject<HTMLDivElement>;
    selectionRangeRef: React.RefObject<HTMLDivElement>;
    theme: RangeSelectorZoneThemeEnum;
    values: number[];
};

enum PositionEnum {
    left = 'left',
    center = 'center',
    right = 'right',
}

const PERMISSIBLE_ERROR = 2;

const ZoneText: React.FC<PropsT> = React.memo((props) => {
    const { containerRef, selectionRangeRef, text, theme, values } = props;

    const textRef = React.useRef<HTMLDivElement>(null);

    const [position, setPosition] = React.useState(PositionEnum.center);

    React.useEffect((): void => {
        if (!textRef.current || !containerRef.current || !selectionRangeRef.current) {
            setPosition(PositionEnum.center);
            return;
        }

        const textNodeRect = textRef.current.getBoundingClientRect();
        const containerNodeRect = containerRef.current.getBoundingClientRect();
        const selectionRangeNodeRect = selectionRangeRef.current.getBoundingClientRect();

        const couldPositionCenter = selectionRangeNodeRect.width > textNodeRect.width + PERMISSIBLE_ERROR;
        if (couldPositionCenter) {
            setPosition(PositionEnum.center);
            return;
        }

        const isLeftOverlap = textNodeRect.left < containerNodeRect.left - PERMISSIBLE_ERROR;
        if (isLeftOverlap) {
            setPosition(PositionEnum.left);
            return;
        }

        const isRightOverlap = textNodeRect.right > containerNodeRect.right + PERMISSIBLE_ERROR;
        if (isRightOverlap) {
            setPosition(PositionEnum.right);
            return;
        }

        const couldPositionLeft =
            containerNodeRect.right > selectionRangeNodeRect.left + textNodeRect.width + PERMISSIBLE_ERROR;
        if (couldPositionLeft) {
            setPosition(PositionEnum.left);
        }
    }, [text, containerRef.current, textRef.current, selectionRangeRef.current, values]);

    if (!text) {
        return null;
    }

    return (
        <div
            className={cx('zone-title', {
                [`zone-title--theme-${theme}`]: true,
                [`zone-title--position-${position}`]: true,
            })}
            data-test-selector="range-selector-zone-text"
        >
            <span ref={textRef}>{text}</span>
        </div>
    );
});

export default ZoneText;
