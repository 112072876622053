import { PHONE_NUMBER_PREFIX } from 'common/constants';

export const parseStringFormatter = (value: string | null | undefined): string => {
    return (value || '').trim();
};

export const parseSimpleNumber = (sourceValue: string | null | undefined): number | undefined => {
    if (sourceValue === null) {
        return undefined;
    }

    if (sourceValue === undefined) {
        return undefined;
    }

    if (sourceValue === '') {
        return undefined;
    }

    const rawValue = sourceValue.trim().replace(/,/g, '').replace(/ /g, '');

    return +rawValue;
};

export const parsePhoneNumber = (sourceValue: string | null | undefined): string => {
    const rawValue = (sourceValue || '').trim();

    if (!rawValue || rawValue === PHONE_NUMBER_PREFIX) {
        return '';
    }

    return rawValue;
};
