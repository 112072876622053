import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
};

const PlusSignIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, size, ...rest } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path fill={strokeColor} d="M15.25 10.75h-4.5v4.5h-1.5v-4.5h-4.5v-1.5h4.5v-4.5h1.5v4.5h4.5z" />
            </g>
        </svg>
    );
};

PlusSignIcon.displayName = 'PlusSignIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PlusSignIcon;
