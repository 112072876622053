import { TruckStatusEnum } from 'common/utils/api/models';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { EmissionStandartEnum } from 'common/constants';

export enum FieldsEnum {
    model = 'model',
    plateNumber = 'plateNumber',
    emissionClass = 'emissionClass',
    status = 'status',
    link = 'link',
    GPSTracker = 'GPS-tracker',
    dedication = 'dedication',
    createDates = 'create-dates',
    lastModifyDates = 'last-modify-dates',
}

export type FormValuesT = {
    [FieldsEnum.model]: string | null;
    [FieldsEnum.plateNumber]: string;
    [FieldsEnum.emissionClass]: EmissionStandartEnum | null;
    [FieldsEnum.status]: TruckStatusEnum | null;
    [FieldsEnum.link]: LinkValuesEnum | null;
    [FieldsEnum.GPSTracker]: GPSTrackedValuesEnum | null;
    [FieldsEnum.dedication]: DedicationValuesEnum | null;
    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.lastModifyDates]: DateRangeT | null;
};
