import { CarrierContractStateT } from './types';
import { SharableStateT } from '../models';
import { initialCarrierContractState } from 'common/store/carrier-contracts/reducer';
import { PartnerContractT } from 'common/store/carrier-contracts/models';
import keyBy from 'lodash/keyBy';

const selectCarrierContractState = (state: SharableStateT, partnerId: PartnerIdT): CarrierContractStateT => {
    return state.carrierContracts[partnerId] || initialCarrierContractState;
};

export const selectFetchCarrierContractsRequest = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['fetchContractsRequest'] => {
        return selectCarrierContractState(state, partnerId).fetchContractsRequest;
    };
};

export const selectUpdateCarrierContractRequest = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['updateContractRequest'] => {
        return selectCarrierContractState(state, partnerId).updateContractRequest;
    };
};

export const selectUploadCarrierContractRequest = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['uploadContractRequest'] => {
        return selectCarrierContractState(state, partnerId).uploadContractRequest;
    };
};

export const selectDownloadCarrierContractRequest = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['downloadContractRequest'] => {
        return selectCarrierContractState(state, partnerId).downloadContractRequest;
    };
};

export const selectUpdatingCarrierContractId = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['updatingContractId'] => {
        return selectCarrierContractState(state, partnerId).updatingContractId;
    };
};

export const selectCarrierContracts = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['contracts'] => {
        return selectCarrierContractState(state, partnerId).contracts;
    };
};

export const selectCarrierMainContract = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): CarrierContractStateT['contracts']['0'] => {
        return selectCarrierContractState(state, partnerId).contracts[0];
    };
};

export const selectCarrierContractById = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): Record<CarrierContractIdT, PartnerContractT> => {
        const { contracts } = selectCarrierContractState(state, partnerId);

        return keyBy(contracts, 'id');
    };
};
