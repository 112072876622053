import React from 'react';
import { useQueryParam } from 'use-query-params';
import { JSONArrayParam } from 'common/utils/query';
import {
    CommonSidebarContentPropsT,
    CommonSidebarMetaT,
    GetSidebarCloseConfirmationT,
    RenderSidebarContentT,
    SidebarDataWithMetaT,
    SideBarPositionEnum,
    SidebarQueryKeysEnum,
} from './models';
import { commonGetSidebarCloseConfirmation, renderCommonSidebarContent } from './utils';
import SideBarParanja from './SideBarParanja/SideBarParanja';
import SideBar from './SideBar/SideBar';
import isNumber from 'lodash/isNumber';
import { pushSidebarsArray } from 'common/layouts/SideBars/hooks';
import last from 'lodash/last';
import { SideBarSizeEnum } from 'common/layouts/SideBars/SideBarLayout/SideBarLayout';

type PropsT<T> = {
    renderSidebarContent?: PipedPropsT<T>['renderSidebarContent'];
    getSidebarCloseConfirmation?: PipedPropsT<T>['getSidebarCloseConfirmation'];
    paranjaShowLevel?: number;
};

type PipedPropsT<T> = {
    renderSidebarContent: RenderSidebarContentT<T>;
    getSidebarCloseConfirmation: GetSidebarCloseConfirmationT<T>;
};

const pipeProps = <T,>(props: PropsT<T>): PipedPropsT<T> => {
    return {
        renderSidebarContent: (...args) => {
            const result = props?.renderSidebarContent?.(...args);
            if (result) {
                return result;
            }

            // @ts-ignore
            return renderCommonSidebarContent(...args);
        },
        getSidebarCloseConfirmation: (...args) => {
            const result = props?.getSidebarCloseConfirmation?.(...args);
            if (result) {
                return result;
            }

            // @ts-ignore
            return commonGetSidebarCloseConfirmation(...args);
        },
    };
};

const SideBars = <T,>(props: PropsT<T>): React.ReactElement | null => {
    const { renderSidebarContent, getSidebarCloseConfirmation, paranjaShowLevel } = props;

    const [leftSidebarsData, setLeftSidebarsData] = useQueryParam<Array<SidebarDataWithMetaT<T>>>(
        SidebarQueryKeysEnum.leftSidebar,
        JSONArrayParam,
    );

    const [rightSidebarsData, setRightSidebarsData] = useQueryParam<Array<SidebarDataWithMetaT<T>>>(
        SidebarQueryKeysEnum.rightSidebar,
        JSONArrayParam,
    );

    const commonLeftSidebarProps = React.useMemo((): CommonSidebarContentPropsT<T> => {
        const hasBackAction = leftSidebarsData.length >= 2 || last(leftSidebarsData)?.isForceShowBackAction;

        return {
            onOpenNextSidebar: (data: T, meta?: CommonSidebarMetaT) => {
                setLeftSidebarsData(
                    pushSidebarsArray(leftSidebarsData, {
                        ...data,
                        ...meta,
                    }),
                );
            },
            onClose: () => {
                setLeftSidebarsData([]);
            },
            onGoBack: hasBackAction
                ? () => {
                      setLeftSidebarsData(leftSidebarsData.slice(0, -1));
                  }
                : null,
            position: SideBarPositionEnum.left,
        };
    }, [leftSidebarsData, setLeftSidebarsData]);

    const commonRightSidebarProps = React.useMemo((): CommonSidebarContentPropsT<T> => {
        const hasBackAction = rightSidebarsData.length >= 2 || last(rightSidebarsData)?.isForceShowBackAction;

        return {
            onOpenNextSidebar: (data: T, meta?: CommonSidebarMetaT) => {
                setRightSidebarsData(
                    pushSidebarsArray(rightSidebarsData, {
                        ...data,
                        ...meta,
                    }),
                );
            },
            onClose: () => {
                setRightSidebarsData([]);
            },
            onGoBack: hasBackAction
                ? () => {
                      setRightSidebarsData(rightSidebarsData.slice(0, -1));
                  }
                : null,
            position: SideBarPositionEnum.right,
        };
    }, [rightSidebarsData, setRightSidebarsData]);

    const isShowParanja = isNumber(paranjaShowLevel)
        ? leftSidebarsData?.length > paranjaShowLevel || rightSidebarsData?.length > paranjaShowLevel
        : false;

    const commonSidebarProps = React.useMemo(() => {
        return pipeProps({
            renderSidebarContent,
            getSidebarCloseConfirmation,
        });
    }, [renderSidebarContent, getSidebarCloseConfirmation]);

    const leftSidebarData = last(leftSidebarsData) || null;
    const rightSidebarData = last(rightSidebarsData) || null;

    return (
        <>
            {isShowParanja && <SideBarParanja />}
            {leftSidebarData && (
                <SideBar
                    key="left"
                    data={leftSidebarData}
                    size={leftSidebarData.isNarrowSidebar ? SideBarSizeEnum.narrow : SideBarSizeEnum.regular}
                    {...commonSidebarProps}
                    {...commonLeftSidebarProps}
                />
            )}
            {rightSidebarData && (
                <SideBar
                    key="right"
                    data={rightSidebarData}
                    size={rightSidebarData.isNarrowSidebar ? SideBarSizeEnum.narrow : SideBarSizeEnum.regular}
                    {...commonSidebarProps}
                    {...commonRightSidebarProps}
                />
            )}
        </>
    );
};

export default React.memo(SideBars) as typeof SideBars;
