import { AnyAlert, AnyAlertPayloadT } from 'common/store/alerts/models';

export enum ShipperAlertTypeEnum {
    unknown = 'unknown',
}

export type ShipperAnyAlertPayloadT = AnyAlertPayloadT<
    ShipperAlertTypeEnum.unknown,
    {
        // TODO
    }
>;

export class ShipperAnyAlert extends AnyAlert<ShipperAnyAlertPayloadT> {}
