import { put, select, takeEvery } from 'redux-saga/effects';
import {
    APPROVE_DOCUMENT_REQUEST,
    APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST,
    ApproveDocumentActionT,
    ApproveEvidenceOfTaxDocumentActionT,
    DOWNLOAD_DOCUMENT_REQUEST,
    DownloadDocumentActionT,
    FETCH_DOCUMENTS_REQUEST,
    FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    FetchDocumentsActionT,
    FetchEvidenceOfTaxDetailsActionT,
    REJECT_DOCUMENT_REQUEST,
    RejectDocumentActionT,
    REVOKE_DOCUMENT_REQUEST,
    RevokeDocumentActionT,
    UPDATE_DOCUMENT_REQUEST,
    UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST,
    UpdateDocumentActionT,
    UpdateEvidenceOfTaxDetailsActionT,
    UPLOAD_DOCUMENT_REQUEST,
    UploadDocumentActionT,
} from './types';
import {
    downloadDocumentBegin,
    downloadDocumentError,
    downloadDocumentSuccess,
    fetchDocumentsBegin,
    fetchDocumentsError,
    fetchDocumentsSuccess,
    fetchEvidenceOfTaxDetails,
    fetchEvidenceOfTaxDetailsBegin,
    fetchEvidenceOfTaxDetailsError,
    fetchEvidenceOfTaxDetailsSuccess,
    updateDocumentBegin,
    updateDocumentError,
    updateDocumentSuccess,
    updateEvidenceOfTaxDetailsBegin,
    updateEvidenceOfTaxDetailsError,
    updateEvidenceOfTaxDetailsSuccess,
    uploadDocumentBegin,
    uploadDocumentError,
    uploadDocumentSuccess,
} from './actions';

import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import prepareDocument from './utils/prepare-document';
import { DocumentIdsByTypeT, DocumentIdsT, EvidenceOfTaxDetailsT } from 'common/store/documents/models';
import downloadFile from 'common/utils/download-file';
import { ApiDocumentT, DocumentStatusEnum } from 'common/utils/api/models';
import { checkIsDefaultCompanyId, isNonNil } from 'common/utils';

import { logWarning } from 'common/utils/logger';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { selectDocumentsDictById } from 'common/store/documents-dict/selectors';
import { selectCompanyDocumentsById, selectFetchCompanyDocumentsRequest } from 'common/store/documents/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { companyRefreshChannel } from 'common/store/company/channels';
import { partnerDetailsRefreshChannel } from 'broker-admin/store/partner/details/channels';
import { documentsRefreshChannel, partnerDocumentsRefreshChannel } from 'common/store/documents/channels';

const STATUSES_RANK: Record<DocumentStatusEnum, number> = {
    [DocumentStatusEnum.waitingForApprove]: 6,
    [DocumentStatusEnum.approved]: 5,
    [DocumentStatusEnum.expired]: 4,
    [DocumentStatusEnum.rejected]: 3,
    [DocumentStatusEnum.revoked]: 2,
    [DocumentStatusEnum.archived]: 1,
};

const byStatusDesc = (documentA: ApiDocumentT, documentB: ApiDocumentT) => {
    const aRank = documentA.status ? STATUSES_RANK[documentA.status] : 0;
    const bRank = documentB.status ? STATUSES_RANK[documentB.status] : 0;
    return bRank - aRank;
};

function* fetchDocumentsSaga(action: FetchDocumentsActionT): WrapGeneratorT<void> {
    const { companyId, options } = action;

    const requestStatus: ReReturnT<typeof selectFetchCompanyDocumentsRequest> = yield select(
        selectFetchCompanyDocumentsRequest(companyId),
    );
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchDocumentsBegin(companyId));

    let response: ReturnApiT<typeof commonTranziitApi.fetchDocuments | typeof brokerTranziitApi.fetchDocuments>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.fetchDocuments();
    } else {
        response = yield brokerTranziitApi.fetchDocuments(companyId);
    }
    const [error, documentsByType] = response;

    if (error) {
        yield put(fetchDocumentsError(error, companyId));
        return;
    }

    const documentIdsByType = mapValues(documentsByType, (documents): DocumentIdsT => {
        return documents
            .sort(byStatusDesc)
            .map((document) => document.id)
            .filter(isNonNil);
    }) as DocumentIdsByTypeT;

    const documents = flatten(values(documentsByType));
    const preparedDocuments = documents.map(prepareDocument);

    yield put(fetchDocumentsSuccess(documentIdsByType, preparedDocuments, companyId));
}

function* uploadDocumentSaga(action: UploadDocumentActionT): WrapGeneratorT<void> {
    const { partnerType, companyId, dictDocumentId, file } = action;

    const documentsDictById: ReReturnT<typeof selectDocumentsDictById> = yield select(
        selectDocumentsDictById(partnerType),
    );
    const dictDocument = documentsDictById[dictDocumentId as DictDocumentIdT];
    if (!dictDocument) {
        logWarning(`failed to upload document, empty dictDocument! id: ${dictDocumentId} partner type: ${partnerType}`);
        return;
    }

    yield put(uploadDocumentBegin(companyId, dictDocument.id));

    let response: ReturnApiT<typeof commonTranziitApi.uploadDocument | typeof brokerTranziitApi.uploadDocument>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.uploadDocument(dictDocument.type, file);
    } else {
        response = yield brokerTranziitApi.uploadDocument(dictDocument.type, file, companyId);
    }
    const [error] = response;

    if (error) {
        yield put(uploadDocumentError(error, companyId));
        return;
    }

    yield put(uploadDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.documentUploaded,
                data: {
                    dictDocumentName: dictDocument.name,
                },
            }),
        ),
    );
}

function* downloadDocumentSaga(action: DownloadDocumentActionT): WrapGeneratorT<void> {
    const { companyId, documentId } = action;

    yield put(downloadDocumentBegin(companyId));

    let response: ReturnApiT<typeof commonTranziitApi.fetchDocument | typeof brokerTranziitApi.fetchDocument>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.fetchDocument(documentId);
    } else {
        response = yield brokerTranziitApi.fetchDocument(documentId);
    }
    const [error, result] = response;

    if (error) {
        yield put(downloadDocumentError(error, companyId));
        return;
    }

    if (result) {
        downloadFile({
            name: `${documentId}.pdf`,
            data: result,
            type: 'application/pdf',
        });
    }

    yield put(downloadDocumentSuccess(companyId));
}

function* approveEvidenceOfTaxDocumentSaga(action: ApproveEvidenceOfTaxDocumentActionT): WrapGeneratorT<void> {
    const { detailsChanges, companyId, documentId, validTill, partnerType } = action;

    if (!validTill) {
        logWarning('empty validTill');
        return;
    }

    yield put(updateDocumentBegin(companyId, documentId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.approvePartnerEvidenceOfTax> =
        yield brokerTranziitApi.approvePartnerEvidenceOfTax(documentId, {
            validTill,
            ...detailsChanges,
        });

    if (error) {
        yield put(updateDocumentError(error, companyId));
        return;
    }

    yield put(updateDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});

    const documentsDictById: ReReturnT<typeof selectDocumentsDictById> = yield select(
        selectDocumentsDictById(partnerType),
    );
    const documentsById: ReReturnT<typeof selectCompanyDocumentsById> = yield select(
        selectCompanyDocumentsById(companyId),
    );
    const dictDocumentId = documentsById?.[documentId]?.dictDocumentId || null;
    const dictDocument = documentsDictById[dictDocumentId as DictDocumentIdT];
    if (dictDocument) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.documentApproved,
                    data: {
                        dictDocumentName: dictDocument.name,
                    },
                }),
            ),
        );
    }
}

function* approveDocumentSaga(action: ApproveDocumentActionT): WrapGeneratorT<void> {
    const { companyId, documentId, validTill, partnerType } = action;

    if (!validTill) {
        logWarning('empty validTill');
        return;
    }

    yield put(updateDocumentBegin(companyId, documentId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.approvePartnerDocument> =
        yield brokerTranziitApi.approvePartnerDocument(documentId, validTill);

    if (error) {
        yield put(updateDocumentError(error, companyId));
        return;
    }

    yield put(updateDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});

    const documentsDictById: ReReturnT<typeof selectDocumentsDictById> = yield select(
        selectDocumentsDictById(partnerType),
    );
    const documentsById: ReReturnT<typeof selectCompanyDocumentsById> = yield select(
        selectCompanyDocumentsById(companyId),
    );
    const dictDocumentId = documentsById?.[documentId]?.dictDocumentId || null;
    const dictDocument = documentsDictById[dictDocumentId as DictDocumentIdT];
    if (dictDocument) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.documentApproved,
                    data: {
                        dictDocumentName: dictDocument.name,
                    },
                }),
            ),
        );
    }
}

function* rejectDocumentSaga(action: RejectDocumentActionT): WrapGeneratorT<void> {
    const { companyId, documentId, reason, partnerType } = action;

    yield put(updateDocumentBegin(companyId, documentId));
    const [error]: ReturnApiT<typeof brokerTranziitApi.rejectPartnerDocument> =
        yield brokerTranziitApi.rejectPartnerDocument(documentId, reason);

    if (error) {
        yield put(updateDocumentError(error, companyId));
        return;
    }

    yield put(updateDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});

    const documentsDictById: ReReturnT<typeof selectDocumentsDictById> = yield select(
        selectDocumentsDictById(partnerType),
    );
    const documentsById: ReReturnT<typeof selectCompanyDocumentsById> = yield select(
        selectCompanyDocumentsById(companyId),
    );
    const dictDocumentId = documentsById?.[documentId]?.dictDocumentId || null;
    const dictDocument = documentsDictById[dictDocumentId as DictDocumentIdT];
    if (dictDocument) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.documentRejected,
                    data: {
                        dictDocumentName: dictDocument.name,
                    },
                }),
            ),
        );
    }
}

function* revokeDocumentSaga(action: RevokeDocumentActionT): WrapGeneratorT<void> {
    const { companyId, documentId, partnerType } = action;

    yield put(updateDocumentBegin(companyId, documentId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.revokePartnerDocument> =
        yield brokerTranziitApi.revokePartnerDocument(documentId);
    if (error) {
        yield put(updateDocumentError(error, companyId));
        return;
    }

    yield put(updateDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});

    const documentsDictById: ReReturnT<typeof selectDocumentsDictById> = yield select(
        selectDocumentsDictById(partnerType),
    );
    const documentsById: ReReturnT<typeof selectCompanyDocumentsById> = yield select(
        selectCompanyDocumentsById(companyId),
    );
    const dictDocumentId = documentsById?.[documentId]?.dictDocumentId || null;
    const dictDocument = documentsDictById[dictDocumentId as DictDocumentIdT];
    if (dictDocument) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.documentRevoked,
                    data: {
                        dictDocumentName: dictDocument.name,
                    },
                }),
            ),
        );
    }
}

function* updateDocumentSaga(action: UpdateDocumentActionT): WrapGeneratorT<void> {
    const { companyId, documentId, detailChanges } = action;

    yield put(updateDocumentBegin(companyId, documentId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.updatePartnerDocument> =
        yield brokerTranziitApi.updatePartnerDocument(documentId, detailChanges);
    if (error) {
        yield put(updateDocumentError(error, companyId));
        return;
    }

    yield put(updateDocumentSuccess(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});
}

function* updateEvidenceOfTaxDetailsSaga(action: UpdateEvidenceOfTaxDetailsActionT): WrapGeneratorT<void> {
    const { documentId, companyId, detailsChanges } = action;

    yield put(updateEvidenceOfTaxDetailsBegin(companyId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateEvidenceOfTax> =
        yield brokerTranziitApi.updateEvidenceOfTax(documentId, detailsChanges);

    if (error) {
        yield put(updateEvidenceOfTaxDetailsError(error, companyId));
        return;
    }

    yield put(updateEvidenceOfTaxDetailsSuccess(companyId));

    yield put(fetchEvidenceOfTaxDetails(companyId));

    companyRefreshChannel.emit({});
    documentsRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
    partnerDocumentsRefreshChannel.emit({});
}

function* fetchEvidenceOfTaxDetailsSaga(action: FetchEvidenceOfTaxDetailsActionT): WrapGeneratorT<void> {
    const { companyId } = action;

    yield put(fetchEvidenceOfTaxDetailsBegin(companyId));

    let error: Error | null = null;
    let evidenceOfTaxDetails: EvidenceOfTaxDetailsT | null = null;

    if (checkIsDefaultCompanyId(companyId)) {
        const response: ReturnApiT<typeof commonTranziitApi.fetchCurrentCompany> =
            yield commonTranziitApi.fetchCurrentCompany();

        error = response[0] || null;
        evidenceOfTaxDetails = response[1] || null;
    } else {
        const response: ReturnApiT<typeof brokerTranziitApi.fetchPartnerDetails> =
            yield brokerTranziitApi.fetchPartnerDetails(companyId);
        error = response[0] || null;

        evidenceOfTaxDetails = response[1] || null;
    }

    if (error) {
        yield put(fetchEvidenceOfTaxDetailsError(companyId, error));
    }

    if (evidenceOfTaxDetails) {
        yield put(fetchEvidenceOfTaxDetailsSuccess(companyId, evidenceOfTaxDetails));
    }
}

function* documentsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DOCUMENTS_REQUEST, fetchDocumentsSaga);
    yield takeEvery(UPLOAD_DOCUMENT_REQUEST, uploadDocumentSaga);
    yield takeEvery(DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentSaga);
    yield takeEvery(APPROVE_DOCUMENT_REQUEST, approveDocumentSaga);
    yield takeEvery(APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST, approveEvidenceOfTaxDocumentSaga);
    yield takeEvery(REJECT_DOCUMENT_REQUEST, rejectDocumentSaga);
    yield takeEvery(REVOKE_DOCUMENT_REQUEST, revokeDocumentSaga);
    yield takeEvery(UPDATE_DOCUMENT_REQUEST, updateDocumentSaga);
    yield takeEvery(FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST, fetchEvidenceOfTaxDetailsSaga);
    yield takeEvery(UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST, updateEvidenceOfTaxDetailsSaga);
}

export default documentsSaga;
