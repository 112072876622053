import { saveAs } from 'file-saver';

type DownloadParamsT = {
    data: string[] | string;
    type: string;
    name: string;
};

const downloadFile = ({ data, type, name }: DownloadParamsT): void => {
    // @ts-ignore string[] is not compatible with BlobPart
    const blob = new Blob([data], { type });

    saveAs(blob, name);
};

export default downloadFile;
