import * as React from 'react';
import { useCallback, useMemo } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import isEmpty from 'lodash/isEmpty';

import styles from './InfoTable.scss';
import ExpandIcon from 'common/icons/ExpandIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

export type InfoTableRowT = {
    icon: React.ReactNode;
    name: React.ReactNode;
    hasBottomBorder?: boolean;
    isBoldName?: boolean;
    value?: React.ReactNode;
    isBoldValue?: boolean;
    emptyValue?: React.ReactNode;
    testSelector?: string;
    openedClassName?: string;
    closedClassName?: string;
    rightNode?: React.ReactNode;
    rightNodeEmptyValue?: React.ReactNode;
    isBoldRightNode?: boolean;
    isLoading?: boolean;
};

export type PropsT = {
    shouldRenderIcons?: boolean;
    rows: Array<InfoTableRowT | null>;
    isCollapsable?: boolean;
    className?: string;
    hasSymmetricalOpenedMargins?: boolean;
    testSelector?: string;
    isInitOpen?: boolean;
};

const cx = classNames.bind(styles);

const InfoTable: React.FC<PropsT> = React.memo((props) => {
    const { rows, shouldRenderIcons, className, hasSymmetricalOpenedMargins, isInitOpen, testSelector } = props;

    const [isOpen, setOpen] = React.useState<boolean>(!!isInitOpen);

    const toggle = useCallback(() => {
        setOpen((isOpen) => !isOpen);
    }, [setOpen]);

    const tableFullTestSelector = `${testSelector}_info-table`;

    const filteredRows = useMemo(() => {
        return rows.filter(isNonNil);
    }, [rows]);

    const isCollapsable = props.isCollapsable && filteredRows.length > 1;

    const hasIcons = useMemo(() => {
        return shouldRenderIcons || filteredRows.some((row) => !!row.icon);
    }, [filteredRows, shouldRenderIcons]);

    if (isEmpty(filteredRows)) {
        return null;
    }

    return (
        <div
            className={cs(
                cx('grid', {
                    'grid--has-symmetrical-opened-margins': isOpen && hasSymmetricalOpenedMargins,
                }),
                className,
            )}
            data-test-selector={tableFullTestSelector}
        >
            {filteredRows.map((row, rowIndex) => {
                const isFirstRow = rowIndex === 0;

                if (!isFirstRow && !isOpen && isCollapsable) {
                    return null;
                }

                const isEmptyValue = isEmpty(row.value) && isEmpty(row.rightNode);

                const rowFullTestSelector = `${tableFullTestSelector}_${row.testSelector}_row`;

                const isFullNameWidth = !row.emptyValue && !row.value;

                return (
                    <div
                        key={rowIndex}
                        className={cs(
                            cx('grid__row', {
                                'grid__row--is-opened-first-row': isFirstRow && isCollapsable && isOpen,
                            }),
                            isOpen ? row.openedClassName : row.closedClassName,
                        )}
                    >
                        {hasIcons && (
                            <div
                                className={cx('grid__cell', 'grid__cell--isIcon')}
                                data-test-selector={`${rowFullTestSelector}_icon`}
                            >
                                {row.icon}
                            </div>
                        )}
                        <div
                            className={cx('grid__cell', 'grid__cell--isName', {
                                'grid__cell--hasBottomBorder': row.hasBottomBorder,
                                'grid__cell--isBold': row.isBoldName,
                                'grid__cell--isFixedName': !isFullNameWidth,
                            })}
                            data-test-selector={`${rowFullTestSelector}_name`}
                        >
                            {row.name}
                        </div>
                        {(row.emptyValue || row.value || row.isLoading) && (
                            <div
                                className={cx('grid__cell', 'grid__cell--isValue', {
                                    'grid__cell--hasBottomBorder': row.hasBottomBorder,
                                    'grid__cell--isEmpty': isEmptyValue,
                                    'grid__cell--isBold': row.isBoldValue,
                                })}
                                data-test-selector={`${rowFullTestSelector}_value`}
                            >
                                {row.isLoading ? (
                                    <ControlLoaderIcon
                                        size={DEFAULT_ICON_SIZE}
                                        fillColor={StyleGuideColorsEnum.brandAccent}
                                    />
                                ) : (
                                    <>{isEmptyValue ? row.emptyValue : row.value}</>
                                )}
                            </div>
                        )}
                        {(row.rightNodeEmptyValue || row.rightNode) && (
                            <div
                                className={cx('grid__cell', 'grid__cell--isRightNode', {
                                    'grid__cell--isFlexContainer': true,
                                    'grid__cell--hasBottomBorder': row.hasBottomBorder,
                                    'grid__cell--isEmpty': isEmptyValue,
                                    'grid__cell--isBold': row.isBoldRightNode,
                                })}
                                data-test-selector={`${rowFullTestSelector}_right-node`}
                            >
                                <>{isEmptyValue ? row.rightNodeEmptyValue : row.rightNode}</>
                            </div>
                        )}
                        {isCollapsable && isFirstRow && (
                            <div
                                className={cx('grid__cell', 'grid__cell--isTrigger', {
                                    'grid__cell--hasBottomBorder': row.hasBottomBorder,
                                })}
                                data-test-selector={`${tableFullTestSelector}_trigger`}
                            >
                                <TransparentTrigger
                                    spaces="xs"
                                    onClick={toggle}
                                    leftIcon={
                                        <ExpandIcon fillColor={StyleGuideColorsEnum.brandAccent} isInvert={isOpen} />
                                    }
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
});

export default InfoTable;
