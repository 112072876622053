import React from 'react';
import { SyncFormValuesT } from 'common/utils/hooks/useSyncFormValues';

const createSyncFormValuesContext = <V extends FieldValues>() => {
    return React.createContext<SyncFormValuesT<V>>({
        formValues: null,
        syncFormValues: () => {
            // nothing
        },
        setFieldValueCallback: null,
        syncSetFieldValueCallback: () => {
            // nothing
        },
    });
};

export { createSyncFormValuesContext };
