import {
    FETCH_ORDERS_PAGE_REQUEST,
    FETCH_ORDERS_PAGE_REQUEST_BEGIN,
    FETCH_ORDERS_PAGE_REQUEST_ERROR,
    FETCH_ORDERS_PAGE_REQUEST_SUCCESS,
    FetchOrdersPageActionT,
    FetchOrdersPageBeginActionT,
    FetchOrdersPageErrorActionT,
    FetchOrdersPageSuccessActionT,
    RESET_ORDERS,
    ResetOrdersActionT,
} from './types';
import { FetchOrdersPageQueryT, OrderT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchOrdersPage = (
    pageNumber: PageNumberT,
    query: FetchOrdersPageQueryT,
    options?: FetchOptionsT,
): FetchOrdersPageActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchOrdersPageBegin = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
): FetchOrdersPageBeginActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchOrdersPageSuccess = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<OrderT> | null,
): FetchOrdersPageSuccessActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchOrdersPageError = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchOrdersPageErrorActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetOrders = (mutationListOptions: MutationListOptionsT): ResetOrdersActionT => ({
    type: RESET_ORDERS,
    mutationListOptions,
});
