import React from 'react';
import classNames from 'classnames/bind';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';

import styles from './FilterPills.scss';
import { useTranslation } from 'react-i18next';
import FilterPill, { FilterPillThemeEnum } from './FilterPill/FilterPill';
import noop from 'lodash/noop';

const cx = classNames.bind(styles);

export type FilterPillsConfigT<QueryFiltersT> = Array<{
    render: (queryFilters: QueryFiltersT) => React.ReactNode;
    renderIcon?: (queryFilters: QueryFiltersT, isCompact?: boolean) => React.ReactNode;
    clearKeys: Array<keyof QueryFiltersT>;
    theme?: FilterPillThemeEnum;
}>;

type PropsT<QueryFiltersT> = {
    config: FilterPillsConfigT<QueryFiltersT>;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: Partial<QueryFiltersT>) => void;
    isCompact?: boolean;
    isHideClearAll?: boolean;
    className?: string;
    testSelector?: string;
};

const FilterPills = <QueryFiltersT extends {}>(props: PropsT<QueryFiltersT>): React.ReactElement => {
    const { queryFilters, setQueryFilters, config, isHideClearAll, isCompact, className, testSelector } = props;
    const { t } = useTranslation();

    const clearFilters = (keys: Array<string>) => {
        const newQueryFilters = omit(queryFilters, keys as Array<string>);

        setQueryFilters(newQueryFilters);
    };

    const pills = config
        .map((configItem) => {
            const { renderIcon, render, theme, clearKeys } = configItem;

            const hasValues = clearKeys.some((key) => queryFilters[key] !== undefined);
            if (!hasValues) {
                return null;
            }

            return {
                testSelector: `${testSelector}_${clearKeys.join('-')}`,
                label: render(queryFilters),
                icon: renderIcon ? renderIcon(queryFilters, isCompact) : null,
                theme,
                handleClick: () => clearFilters(clearKeys as string[]),
            };
        })
        .filter(Boolean);

    const allKeys = flatten(config.map((configItem) => configItem.clearKeys)) as string[];

    const clearAllFilters = () => clearFilters(allKeys);

    return (
        <>
            {!!pills.length && !isHideClearAll && (
                <FilterPill
                    className={cx('pill')}
                    label={t('common:clear-all-filters')}
                    withCloser={false}
                    testSelector={`${testSelector}_clear-all`}
                    theme={FilterPillThemeEnum.charcoal}
                    onClick={clearAllFilters}
                    isCompact={isCompact}
                />
            )}
            {pills.map((pill, pillIndex) => (
                <FilterPill
                    key={pillIndex}
                    className={cx('pill')}
                    icon={pill?.icon}
                    label={pill?.label}
                    withCloser
                    testSelector={pill?.testSelector}
                    theme={pill?.theme || FilterPillThemeEnum.brandDark}
                    onClick={pill?.handleClick || noop}
                    isCompact={isCompact}
                />
            ))}
        </>
    );
};

export default React.memo(FilterPills) as typeof FilterPills;
