import { RFQDetailsT, RFQPriceOfferT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_RFQ_DETAILS_REQUEST = 'common/rfq-details/FETCH_RFQ_DETAILS_REQUEST';
export const FETCH_RFQ_DETAILS_REQUEST_BEGIN = 'common/rfq-details/FETCH_RFQ_DETAILS_REQUEST_BEGIN';
export const FETCH_RFQ_DETAILS_REQUEST_ERROR = 'common/rfq-details/FETCH_RFQ_DETAILS_REQUEST_ERROR';
export const FETCH_RFQ_DETAILS_REQUEST_SUCCESS = 'common/rfq-details/FETCH_RFQ_DETAILS_REQUEST_SUCCESS';

export type RFQDetailsStateT = {
    details: RFQDetailsT | null;
    defaultOffer: RFQPriceOfferT | null;
    requestStatus: RequestStatusT;
};

export type RFQDetailsByIdStateT = Record<RFQIdT, RFQDetailsStateT>;

export type FetchRFQDetailsRequestActionT = {
    type: typeof FETCH_RFQ_DETAILS_REQUEST;
    id: RFQIdT;
    options?: FetchOptionsT;
};

export type FetchRFQDetailsRequestBeginActionT = {
    type: typeof FETCH_RFQ_DETAILS_REQUEST_BEGIN;
    id: RFQIdT;
};

export type FetchRFQDetailsRequestErrorActionT = {
    type: typeof FETCH_RFQ_DETAILS_REQUEST_ERROR;
    error: Error;
    id: RFQIdT;
};

export type FetchRFQDetailsRequestSuccessActionT = {
    type: typeof FETCH_RFQ_DETAILS_REQUEST_SUCCESS;
    details: RFQDetailsT | null;
    defaultOffer: RFQPriceOfferT | null;
    id: RFQIdT;
};

export type RFQDetailsActionT =
    | FetchRFQDetailsRequestActionT
    | FetchRFQDetailsRequestBeginActionT
    | FetchRFQDetailsRequestErrorActionT
    | FetchRFQDetailsRequestSuccessActionT;
