import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentT } from 'common/store/documents/models';
import DocumentsTable, {
    TableColumnModsEnum,
    TableColumnT,
    TableRowMetaT,
    TableRowModsEnum,
    TableRowThemeEnum,
} from 'common/components/DocumentsTable/DocumentsTable';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import classNames from 'classnames/bind';
import styles from './BaseDocumentsTable.scss';
import DocumentTypeCell from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/cell-renderers/DocumentTypeCell/DocumentTypeCell';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import ActionsCell from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/cell-renderers/ActionsCell/ActionsCell';
import { DocumentActionsT } from '../models';
import ExpireDateCell from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/cell-renderers/ExpireDateCell/ExpireDateCell';
import { DictDocumentT } from 'common/store/documents-dict/models';
import { DocumentRequiredEnum, DocumentStatusEnum } from 'common/utils/api/models';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import DocumentStatusPill from 'common/components/status-pill/DocumentStatusPill/DocumentStatusPill';

type PropsT = {
    className?: string;
    tableName: string;
    rows: Array<DocumentRowT>;
    onSelectRow?: (row: DocumentRowT) => void;
    isLoading: boolean;
    isShowReviewUser: boolean;
    testSelector?: string;
    footerNode?: ReactNode;
    onOpenUserDetails: (userId: UserIdT) => void;
    documentStatusesAttentionSet?: Set<DocumentStatusEnum | null>;
} & DocumentActionsT;

export type DocumentRowT = {
    dictDocument: DictDocumentT;
    actualVersion: DocumentT | null;
    oldVersions: Array<DocumentT> | null;
};

const cx = classNames.bind(styles);

const BaseDocumentsTable: React.FC<PropsT> = React.memo((props) => {
    const {
        tableName,
        rows,
        isLoading,
        onOpenUserDetails,
        testSelector,
        className,
        footerNode,
        onSelectRow,
        onUploadDocument,
        onDownloadDocument,
        onEditDocumentInfo,
        onRevokeDocument,
        isShowReviewUser,
        documentStatusesAttentionSet,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<DocumentRowT, void>> = [
        {
            renderHeader: () => <span>{t('common:documents.table.columns.type')}</span>,
            headerClassName: cx('table__header', 'table__header--type'),
            render: (row: DocumentRowT) => (
                <DocumentTypeCell name={row.dictDocument.name} isEmpty={!row?.actualVersion} />
            ),
            className: cx('table__column', 'table__column--type'),
            testSelector: 'type',
        },
        {
            renderHeader: () => <span>{t('common:documents.table.columns.uploaded')}</span>,
            headerClassName: cx('table__header'),
            render: (row: DocumentRowT) => {
                if (!row.actualVersion) {
                    return null;
                }

                return (
                    <EventCell
                        date={row.actualVersion.createdDate}
                        userId={row.actualVersion.createdById}
                        userFullName={row.actualVersion.createdByName}
                        byBroker={row.actualVersion.createdByBroker}
                        openUserDetails={onOpenUserDetails}
                    />
                );
            },
            className: cx('table__column', 'table__column--upload'),
            testSelector: 'upload',
        },
        {
            renderHeader: () => <span>{t('common:documents.table.columns.reviewed')}</span>,
            headerClassName: cx('table__header'),
            render: (row: DocumentRowT) => {
                if (!row.actualVersion) {
                    return null;
                }

                if (isShowReviewUser) {
                    return (
                        <EventCell
                            date={row.actualVersion.reviewedDate}
                            userId={row.actualVersion.reviewedById}
                            userFullName={row.actualVersion.reviewedByName}
                            byBroker
                            openUserDetails={onOpenUserDetails}
                        />
                    );
                }

                return <DateCell isBigFont isBold date={row.actualVersion?.reviewedDate} />;
            },
            className: cx('table__column', 'table__column--review'),
            testSelector: 'review',
        },
        {
            renderHeader: () => <span>{t('common:documents.table.columns.expire-date')}</span>,
            headerClassName: cx('table__header'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: DocumentRowT) => {
                return <ExpireDateCell row={row} onUploadDocument={onUploadDocument} onSelectRow={onSelectRow} />;
            },
            className: cx('table__column', 'table__column--expire-date'),
            testSelector: 'valid-till',
        },
        {
            renderHeader: () => <span>{t('common:documents.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (row: DocumentRowT) => {
                const status = row.actualVersion ? row.actualVersion.status : null;

                return (
                    <DocumentStatusPill
                        status={status}
                        isSymmetrical
                        isRequiredDocument={row?.dictDocument?.required === DocumentRequiredEnum.must}
                    />
                );
            },
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
        {
            renderHeader: () => null,
            headerClassName: cx('table__header'),
            render: (row: DocumentRowT) => {
                return (
                    <ActionsCell
                        row={row}
                        onUploadDocument={onUploadDocument}
                        onDownloadDocument={onDownloadDocument}
                        onEditDocumentInfo={onEditDocumentInfo}
                        onRevokeDocument={onRevokeDocument}
                    />
                );
            },
            className: cx('table__column', 'table__column--actions'),
            testSelector: 'actions',
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, row: DocumentRowT) => {
        return {
            [TableRowModsEnum.hasLeftOrangeBorder]: documentStatusesAttentionSet?.has(
                row.actualVersion?.status || null,
            ),
        };
    };

    const getRowTheme = (meta: TableRowMetaT, row: DocumentRowT): TableRowThemeEnum => {
        return row?.actualVersion ? TableRowThemeEnum.normal : TableRowThemeEnum.empty;
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<DocumentRowT> tableName={tableName} isLoading={isLoading} rows={rows}>
                {(rows, isUsedPrevRows) => (
                    <DocumentsTable<DocumentRowT, void>
                        testSelector={testSelector}
                        className={className}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        getRowMods={getRowMods}
                        getRowTheme={getRowTheme}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                        footerNode={footerNode}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default BaseDocumentsTable;
