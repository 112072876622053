import { useCallback, useEffect, useRef } from 'react';

const useDebouncedEffect = (effect: () => void, delay: number, deps: Array<any>) => {
    const didMount = useRef(false);
    const callback = useCallback(effect, deps);

    useEffect(() => {
        // trigger only change
        if (!didMount.current) {
            didMount.current = true;
            return () => {
                // nothing
            };
        }

        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export default useDebouncedEffect;
