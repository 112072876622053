import classNames from 'classnames/bind';
import styles from './MapLoader.scss';
import * as React from 'react';
import cs from 'classnames';
import ControlLoaderLabel from 'common/components/ControlLoaderLabel/ControlLoaderLabel';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const MapLoader: React.FC<PropsT> = React.memo((props) => {
    const { className, children } = props;

    const { t } = useTranslation();

    return (
        <div className={cs(cx('map-loader__container'), className)}>
            <ControlLoaderLabel isInvert>{children || t('common:maps.loading')}</ControlLoaderLabel>
        </div>
    );
});

export default MapLoader;
