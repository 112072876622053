import {
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST,
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN,
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR,
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS,
    FetchGroupedNotificationCountsActionT,
    FetchGroupedNotificationCountsBeginActionT,
    FetchGroupedNotificationCountsErrorActionT,
    FetchGroupedNotificationCountsSuccessActionT,
} from './types';
import { ApiGroupedNotificationCountsT } from 'common/utils/api/models';

export const fetchGroupedNotificationCounts = (options?: FetchOptionsT): FetchGroupedNotificationCountsActionT => ({
    type: FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST,
    options,
});

export const fetchGroupedNotificationCountsBegin = (): FetchGroupedNotificationCountsBeginActionT => ({
    type: FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN,
});

export const fetchGroupedNotificationCountsSuccess = (
    counts: ApiGroupedNotificationCountsT | null,
): FetchGroupedNotificationCountsSuccessActionT => ({
    type: FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS,
    counts,
});

export const fetchGroupedNotificationCountsError = (error: Error): FetchGroupedNotificationCountsErrorActionT => ({
    type: FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR,
    error,
});
