import { put, select } from 'redux-saga/effects';
import { fetchLegalFormsDictBegin, fetchLegalFormsDictError, fetchLegalFormsDictSuccess } from './actions';
import { FetchLegalFormsDictActionT, LEGAL_FORMS_DICT_REQUEST } from './types';
import { selectLegalFormsDictRequest } from './selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { CountryCodeUnionT } from 'common/utils/api/models';
import { semaphoreSaga } from 'common/utils/semaphore-saga';

function* fetchLegalFormsDictSaga(action: FetchLegalFormsDictActionT): WrapGeneratorT<void> {
    const { countryCode } = action;

    const currentRequest: ReReturnT<typeof selectLegalFormsDictRequest> = yield select(
        selectLegalFormsDictRequest(countryCode),
    );

    if (currentRequest.ok || currentRequest.loading) {
        return;
    }

    yield put(fetchLegalFormsDictBegin(countryCode));

    const [error, countriesDict]: ReturnApiT<typeof commonTranziitApi.fetchCountryLegalForms> =
        yield commonTranziitApi.fetchCountryLegalForms(countryCode as CountryCodeUnionT);
    if (error) {
        yield put(fetchLegalFormsDictError(countryCode, error));
    }

    yield put(fetchLegalFormsDictSuccess(countryCode, countriesDict || []));
}

function* countriesSaga(): WrapGeneratorT<void> {
    yield semaphoreSaga(LEGAL_FORMS_DICT_REQUEST, fetchLegalFormsDictSaga);
}

export default countriesSaga;
