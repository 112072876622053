import React from 'react';

export type StepContextT = {
    hasSelectedContact: boolean;
    setHasSelectedContract: (hasSelectedContact: boolean) => void;
};

const StepsContext = React.createContext<StepContextT>({
    hasSelectedContact: false,
    setHasSelectedContract: () => {
        // nothing
    },
});

export default StepsContext;
