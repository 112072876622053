import { FieldsEnum, FormValuesT, RoutePointFieldsEnum, RoutePointFormValuesT, RoutePointTypeEnum } from './constants';
import { ApiActualOrderRequestT } from 'common/utils/api/models';
import { ReserveQueryChangesT, ReserveT } from 'common/store/order-creation/models';
import { prepareLocation } from 'common/utils/prepare-location';
import { AvailableChangesT, AvailableRoutePointChangesT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/models';

const prepareApiActualOrderRequest = (values: FormValuesT, reserve: ReserveT): ApiActualOrderRequestT | null => {
    const timeWindows: ApiActualOrderRequestT['timeWindows'] = [];

    reserve.points.forEach((point, index) => {
        const routePointFormValue = values[FieldsEnum.route]?.[index];

        const timeWindow = routePointFormValue?.[RoutePointFieldsEnum.timeWindow];
        if (timeWindow && !point.driveThru) {
            timeWindows.push({
                id: point.id,
                brokerWindow: routePointFormValue?.[RoutePointFieldsEnum.isBrokerTimeWindow],
                from: timeWindow.start,
                to: timeWindow.end,
            });
        }
    });

    return {
        timeWindows,
    };
};

const prepareApiReserveChanges = (changes: AvailableChangesT): ReserveQueryChangesT => {
    let apiChanges: ReserveQueryChangesT = {};

    if (FieldsEnum.shipperId in changes) {
        apiChanges = {
            ...apiChanges,
            shipperId: changes[FieldsEnum.shipperId],
        };
    }

    if (FieldsEnum.trailerDictId in changes) {
        apiChanges = {
            ...apiChanges,
            trailerDictId: changes[FieldsEnum.trailerDictId],
        };
    }

    if (FieldsEnum.laneId in changes) {
        apiChanges = {
            ...apiChanges,
            laneId: changes[FieldsEnum.laneId],
        };
    }

    if (FieldsEnum.excludedCountries in changes) {
        apiChanges = {
            ...apiChanges,
            excludedCountries: changes[FieldsEnum.excludedCountries],
        };
    }

    if (FieldsEnum.route in changes) {
        apiChanges = {
            ...apiChanges,
            route: changes[FieldsEnum.route]?.map(
                (routePointValue): NonNullable<ReserveQueryChangesT['route']>[number] => {
                    const timeWindow = routePointValue[RoutePointFieldsEnum.timeWindow];
                    return {
                        address: prepareLocation(routePointValue[RoutePointFieldsEnum.location]),
                        driveThru: routePointValue[RoutePointFieldsEnum.type] === RoutePointTypeEnum.driveThrough,
                        isBrokerTimeWindow: routePointValue[RoutePointFieldsEnum.isBrokerTimeWindow],
                        timeWindowFrom: timeWindow?.start || null,
                        timeWindowTo: timeWindow?.end || null,
                    };
                },
            ),
        };
    }

    return apiChanges;
};

const prepareApiRoutePointReserveChanges = (
    routePointIndex: number,
    changes: AvailableRoutePointChangesT,
    prevRoutePointValues: Array<RoutePointFormValuesT>,
): FormValuesT[FieldsEnum.route] => {
    const routePointValues = [...prevRoutePointValues];
    if (!routePointValues[routePointIndex]) {
        return [];
    }

    if (RoutePointFieldsEnum.location in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.location]: changes[RoutePointFieldsEnum.location],
        };
    }

    if (RoutePointFieldsEnum.isBrokerTimeWindow in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.isBrokerTimeWindow]: changes[RoutePointFieldsEnum.isBrokerTimeWindow],
        };
    }

    if (RoutePointFieldsEnum.timeWindow in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.timeWindow]: changes[RoutePointFieldsEnum.timeWindow],
        };
    }

    return routePointValues;
};

export { prepareApiActualOrderRequest, prepareApiReserveChanges, prepareApiRoutePointReserveChanges };
