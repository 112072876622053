import {
    FETCH_NOTIFICATIONS_PAGE_REQUEST,
    FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN,
    FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR,
    FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
    FetchNotificationsErrorActionT,
    FetchNotificationsPageActionT,
    FetchNotificationsPageBeginActionT,
    FetchNotificationsPageSuccessActionT,
    FetchUnreadNotificationsActionT,
    FetchUnreadNotificationsBeginActionT,
    FetchUnreadNotificationsErrorActionT,
    FetchUnreadNotificationsSuccessActionT,
    MARK_AS_OLD,
    MARK_AS_READ_REQUEST,
    MARK_AS_READ_REQUEST_BEGIN,
    MARK_AS_READ_REQUEST_ERROR,
    MARK_AS_READ_REQUEST_SUCCESS,
    MarkAsOldActionT,
    MarkAsReadActionT,
    MarkAsReadBeginActionT,
    MarkAsReadErrorActionT,
    MarkAsReadSuccessActionT,
    RECEIVED_NOTIFICATIONS,
    ReceivedNotificationsActionT,
    RESET_NOTIFICATION_PAGES,
    ResetNotificationPagesT,
    SEND_TEST_NOTIFICATION,
    SendTestNotificationActionT,
    SUBSCRIBE_TO_NOTIFICATIONS,
    SUBSCRIBE_TO_NOTIFICATIONS_ERROR,
    SubscribeToNotificationsActionT,
    SubscribeToNotificationsErrorActionT,
} from './types';

import { AnyNotificationT, FetchNotificationsPageQueryT } from 'common/store/notifications/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const receivedNotifications = (notifications: AnyNotificationT[]): ReceivedNotificationsActionT => ({
    type: RECEIVED_NOTIFICATIONS,
    notifications,
});

export const sendTestNotification = (text: string): SendTestNotificationActionT => ({
    type: SEND_TEST_NOTIFICATION,
    text,
});

export const subscribeToNotifications = (): SubscribeToNotificationsActionT => ({
    type: SUBSCRIBE_TO_NOTIFICATIONS,
});

export const subscribeToNotificationsError = (error: Error): SubscribeToNotificationsErrorActionT => ({
    type: SUBSCRIBE_TO_NOTIFICATIONS_ERROR,
    error,
});

export const fetchNotificationsPage = (
    pageNumber: PageNumberT,
    query: FetchNotificationsPageQueryT,
    options: FetchOptionsT,
): FetchNotificationsPageActionT => ({
    type: FETCH_NOTIFICATIONS_PAGE_REQUEST,
    pageNumber,
    query,
    options,
});

export const fetchNotificationsPageBegin = (
    query: FetchNotificationsPageQueryT,
    pageNumber: PageNumberT,
): FetchNotificationsPageBeginActionT => ({
    type: FETCH_NOTIFICATIONS_PAGE_REQUEST_BEGIN,
    pageNumber,
    query,
});

export const fetchNotificationsPageSuccess = (
    query: FetchNotificationsPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<AnyNotificationT> | null,
): FetchNotificationsPageSuccessActionT => ({
    type: FETCH_NOTIFICATIONS_PAGE_REQUEST_SUCCESS,
    pageNumber,
    query,
    pageResponse,
});

export const fetchNotificationsError = (
    query: FetchNotificationsPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchNotificationsErrorActionT => ({
    type: FETCH_NOTIFICATIONS_PAGE_REQUEST_ERROR,
    query,
    pageNumber,
    error,
});

export const markAsOld = (ids: NotificationIdT[]): MarkAsOldActionT => ({
    type: MARK_AS_OLD,
    ids,
});

export const markAsRead = (ids: NotificationIdT[], isAll?: boolean): MarkAsReadActionT => ({
    type: MARK_AS_READ_REQUEST,
    ids,
    isAll,
});

export const markAsReadBegin = (): MarkAsReadBeginActionT => ({
    type: MARK_AS_READ_REQUEST_BEGIN,
});

export const markAsReadSuccess = (ids: NotificationIdT[]): MarkAsReadSuccessActionT => ({
    type: MARK_AS_READ_REQUEST_SUCCESS,
    ids,
});

export const markAsReadError = (error: Error): MarkAsReadErrorActionT => ({
    type: MARK_AS_READ_REQUEST_ERROR,
    error,
});

export const fetchUnreadNotifications = (): FetchUnreadNotificationsActionT => ({
    type: FETCH_UNREAD_NOTIFICATIONS_REQUEST,
});

export const fetchUnreadNotificationsBegin = (): FetchUnreadNotificationsBeginActionT => ({
    type: FETCH_UNREAD_NOTIFICATIONS_REQUEST_BEGIN,
});

export const fetchUnreadNotificationsSuccess = (
    notifications: AnyNotificationT[] | null,
): FetchUnreadNotificationsSuccessActionT => ({
    type: FETCH_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
    notifications,
});

export const fetchUnreadNotificationsError = (error: Error): FetchUnreadNotificationsErrorActionT => ({
    type: FETCH_UNREAD_NOTIFICATIONS_REQUEST_ERROR,
    error,
});

export const resetNotificationPages = (): ResetNotificationPagesT => ({
    type: RESET_NOTIFICATION_PAGES,
});
