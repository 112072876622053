import React from 'react';
import { MenuVisibilityT } from 'common/utils/hooks/useMenuVisibility';

type UiContextT = {
    menu: MenuVisibilityT | null;
};

const UiContext = React.createContext<UiContextT>({ menu: null });

export default UiContext;
