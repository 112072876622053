import {
    OMSRoutesEnum,
    ProfileDocumentsRoutesEnum,
    QueryKeysEnum,
    ShipperContractRoutesEnum,
    ShipperContractsRoutesEnum,
} from 'common/constants';
import history from 'common/utils/history';
import { parseAuthReturnUrl } from './auth-return-url';
import { LocationDescriptor } from 'history';
import { generatePath } from 'react-router';
import { formatQuery } from 'common/utils/query';
import qs from 'qs';

export const getRePath = () => {
    const query = qs.parse(history.location.search.slice(1));

    return [
        history.location.pathname,
        `${query ? `?` : ''}${qs.stringify(query)}`,
        history.location.hash ? `#${history.location.hash}` : '',
    ]
        .filter(Boolean)
        .join('');
};

export const urlFactory = {
    completeRFQ: ({
        id,
        offerId,
        dropoffDate,
        pickupDate,
    }: {
        id: string;
        offerId: string | null;
        dropoffDate: string | null;
        pickupDate: string | null;
    }) => {
        return {
            pathname: generatePath(OMSRoutesEnum.newOrderOffers, {
                rfqId: id,
            }),
            search: formatQuery({
                [QueryKeysEnum.orderCreationComplete]: '1',
                [QueryKeysEnum.orderCreationPriceOfferId]: offerId,
                [QueryKeysEnum.orderCreationDropOffDate]: dropoffDate,
                [QueryKeysEnum.orderCreationPickUpDate]: pickupDate,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    shipperContracts: () => {
        return ProfileDocumentsRoutesEnum.shipperContracts;
    },
    shipperContractDetails: ({ shipperContractId }: { shipperContractId: ShipperContractIdT }) => {
        return {
            pathname: generatePath(ShipperContractRoutesEnum.shipperContractDetails, {
                contractId: shipperContractId,
            }),
        };
    },
    shipperContractLanes: ({ shipperContractId }: { shipperContractId: ShipperContractIdT }) => {
        return {
            pathname: generatePath(ShipperContractRoutesEnum.shipperContractLanes, {
                contractId: shipperContractId,
            }),
        };
    },
    shipperContractLaneDetails: ({
        shipperContractId,
        shipperContractLaneId,
    }: {
        shipperContractId: ShipperContractIdT;
        shipperContractLaneId: ShipperContractLaneIdT;
    }) => {
        return {
            pathname: generatePath(ShipperContractsRoutesEnum.shipperContractLaneDetails, {
                contractId: shipperContractId,
                laneId: shipperContractLaneId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    toReturnUrl: (returnUrl: string | undefined): LocationDescriptor => {
        const parsedReturnUrl = parseAuthReturnUrl(returnUrl);

        return parsedReturnUrl;
    },
};
