import * as React from 'react';

import moment from 'moment';
import classNames from 'classnames/bind';

import styles from './NotificationLayout.scss';
import { AnyNotificationT, NotificationViewT } from 'common/store/notifications/models';

const cx = classNames.bind(styles);

export type PropsT = NotificationViewT & {
    source: AnyNotificationT;
    onClick?: () => void;
};

const NotificationLayout: React.FC<PropsT> = React.memo((props) => {
    const { emotion, text, source, onClick } = props;

    return (
        <div
            className={cx('wrap', {
                'wrap--has-emotion': !!emotion,
                [`wrap--emotion-${emotion}`]: true,
                'wrap--is-clickable': !!onClick,
            })}
            onClick={onClick}
        >
            {text}
            <div className={cx('time')}>
                {source.timestamp ? moment(source.timestamp).format('DD MMM YYYY, HH:mm') : null}
            </div>
        </div>
    );
});

export default NotificationLayout;
