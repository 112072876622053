import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { FetchShipperContractLanesPageQueryT, ShipperContractLaneT } from './models';

export type PartnerShipperContractLanesStateT = PaginationStateT<
    ShipperContractLaneT,
    FetchShipperContractLanesPageQueryT
>;

export const initialPartnerShipperContractLanesState: PartnerShipperContractLanesStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
};

export type ShipperContractLanesStateT = Record<
    PartnerIdT,
    Record<ShipperContractIdT, PartnerShipperContractLanesStateT>
>;

const initialState: ShipperContractLanesStateT = {};

export const shipperContractLanesSlice = createSlice({
    name: 'shipperContractLanes',
    initialState,
    reducers: {
        fetchShipperContractLanesPage: (
            state,
            action: PayloadAction<{
                pageNumber: PageNumberT;
                query: FetchShipperContractLanesPageQueryT;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchShipperContractLanesPageBegin: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractLanesPageQueryT;
                pageNumber: PageNumberT;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
            }>,
        ) => {
            const { partnerId, contractId, pageNumber, query } = action.payload;
            const partnerState = state[partnerId]?.[contractId] || initialPartnerShipperContractLanesState;

            if (!state[partnerId]) {
                state[partnerId] = {};
            }

            state[partnerId][contractId] = {
                ...partnerState,
                ...pageBeginReducer(partnerState, pageNumber, query),
            };
        },
        fetchShipperContractLanesPageSuccess: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractLanesPageQueryT;
                pageNumber: PageNumberT;
                response: PageResponseT<ShipperContractLaneT> | null;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
            }>,
        ) => {
            const { partnerId, contractId, pageNumber, query, response } = action.payload;
            const partnerState = state[partnerId]?.[contractId] || initialPartnerShipperContractLanesState;

            if (!state[partnerId]) {
                state[partnerId] = {};
            }

            state[partnerId][contractId] = {
                ...partnerState,
                ...pageSuccessReducer(partnerState, pageNumber, query, response),
            };
        },
        fetchShipperContractLanesPageError: (
            state,
            action: PayloadAction<{
                query: FetchShipperContractLanesPageQueryT;
                pageNumber: PageNumberT;
                error: Error;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
            }>,
        ) => {
            const { partnerId, contractId, pageNumber, query, error } = action.payload;
            const partnerState = state[partnerId]?.[contractId] || initialPartnerShipperContractLanesState;

            if (!state[partnerId]) {
                state[partnerId] = {};
            }

            state[partnerId][contractId] = {
                ...partnerState,
                ...pageErrorReducer(partnerState, pageNumber, query, error),
            };
        },
        resetShipperContractLanes: (
            state,
            action: PayloadAction<{
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
                mutationListOptions: MutationListOptionsT;
            }>,
        ) => {
            const { partnerId, contractId, mutationListOptions } = action.payload;
            const partnerState = state[partnerId]?.[contractId] || initialPartnerShipperContractLanesState;

            if (!state[partnerId]) {
                state[partnerId] = {};
            }

            state[partnerId][contractId] = {
                ...partnerState,
                ...resetPagesCacheReducer(initialPartnerShipperContractLanesState, partnerState, mutationListOptions),
            };
        },
    },
});

export const {
    fetchShipperContractLanesPage,
    fetchShipperContractLanesPageBegin,
    fetchShipperContractLanesPageSuccess,
    fetchShipperContractLanesPageError,
    resetShipperContractLanes,
} = shipperContractLanesSlice.actions;

export default shipperContractLanesSlice.reducer;
