import * as React from 'react';
import { useFormik } from 'formik';
import AuthLayout from 'common/layouts/AuthLayout/AuthLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from '../../constants';

import styles from './ForgotPasswordPage.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { selectResetPasswordRequestStatus } from 'common/store/auth/selectors';
import { resetPassword } from 'common/store/auth/actions';
import validateForm from './validate-form';
import { FieldsEnum, FormValuesT } from './constants';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import DoneIcon from 'common/icons/DoneIcon';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import useAsyncFormErrorMessage from 'common/utils/hooks/useAsyncFormErrorMessage';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import RedirectSignedUser from 'common/components/RedirectSignedUser/RedirectSignedUser';
import { authApi } from 'common/utils/api/auth/factory';

const cx = classNames.bind(styles);

type PropsT = {};

const INITIAL_VALUES: FormValuesT = { [FieldsEnum.email]: '' };

const ForgotPassword: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const requestStatus = useSelector(selectResetPasswordRequestStatus);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, INITIAL_VALUES);
    }, [t, INITIAL_VALUES]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues: INITIAL_VALUES,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const email = simpleStringFormatter(values[FieldsEnum.email]);
            dispatch(resetPassword(email));

            formikHelpers.setTouched({});
        },
    });

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(requestStatus);
    }, [requestStatus.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const emailAsyncError = useAsyncFormErrorMessage(asyncFormErrors[FieldsEnum.email]);

    if (requestStatus.ok) {
        return (
            <AuthLayout title={t('common:forgot-password.success')} testSelector="forgot-password-page">
                <div className={cx('result')}>
                    <div className={cx('result__done')}>
                        <DoneIcon fillColor={StyleGuideColorsEnum.white} size={40} />
                    </div>
                </div>
            </AuthLayout>
        );
    }

    return (
        <>
            <RedirectSignedUser />
            <AuthLayout title={t('common:forgot-password.title')} testSelector="forgot-password-page">
                <PageTitle title={t('common:page-titles.forgot-password')} />
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('fields')}>
                        <div className={cx('input')}>
                            <FormikField
                                name={FieldsEnum.email}
                                error={formik.errors[FieldsEnum.email]}
                                meta={formik.getFieldMeta(FieldsEnum.email)}
                                label={t('common:forgot-password.fields.email.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                asyncError={emailAsyncError}
                                resetAsyncError={resetAsyncFormErrors}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.email}
                                        placeholder={t('common:forgot-password.fields.email.placeholder')}
                                        value={formik.values[FieldsEnum.email]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </div>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={requestStatus.loading}
                        type="submit"
                        className={cx('submit')}
                    >
                        {t('common:forgot-password.submit')}
                    </Button>
                    <div className={cx('footer-links')}>
                        <Link theme={LinkThemeEnum.boldAzul} testSelector="sign-in" to={authApi.createSignInUrl()}>
                            {t('common:forgot-password.links.sign-in')}
                        </Link>
                    </div>
                </form>
            </AuthLayout>
        </>
    );
});

export default ForgotPassword;
