import * as React from 'react';
import EditTruckSidebarContent from './EditTruckSidebarContent/EditTruckSidebarContent';
import TruckDetailsSidebarContent from './TruckDetailsSidebarContent/TruckDetailsSidebarContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectTrucksByIds,
    selectTrucksDetailsByIds,
    selectUpdateTruckRequestStatus,
} from 'common/store/trucks/selectors';
import { SetUnavailableVehicleQueryT, TruckStatusEnum } from 'common/utils/api/models';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { selectPermissions } from 'common/store/auth/selectors';
import SetUnavailableAssetConfirmationModal, {
    SetUnavailableAssetConfirmationDataT,
} from 'common/layouts/dialogs/SetUnavailableAssetConfirmationModal/SetUnavailableAssetConfirmationModal';
import { AssetTypeEnum } from 'common/constants';
import ArchiveAssetConfirmationModal, {
    ArchiveAssetConfirmationDataT,
} from 'common/layouts/dialogs/ArchiveAssetConfirmationModal/ArchiveAssetConfirmationModal';
import ActivateAssetDialogModal, {
    ActivateAssetConfirmationDataT,
} from 'common/layouts/dialogs/ActivateAssetDialogModal/ActivateAssetDialogModal';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { selectSetUnavailableVehicleRequestStatus } from 'common/store/vehicle-schedules/selectors';
import { setUnavailableVehicleRequest } from 'common/store/vehicle-schedules/actions';
import { activationTrucksRequest, archiveTrucksRequest } from 'common/store/trucks/actions';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';

type PropsT = {
    truckId: TruckIdT;
    partnerId: PartnerIdT;
    onGoBack?: () => void;
    onClose?: () => void;
    isShowMap: boolean;
    onToggleShowMap: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    isDisableShowOnMap?: boolean;
    isReadOnly?: boolean;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
    onOpenContactDetails: (contractId: CarrierContractIdT) => void;
};

const CommonEditableTruckDetailsLayout: React.FC<PropsT> = (props) => {
    const {
        isDisableShowOnMap,
        isReadOnly,
        truckId,
        partnerId,
        onGoBack,
        onClose,
        isShowMap,
        onToggleShowMap,
        setNeedCloseConfirmation,
        onOpenTruckDetails,
        onOpenTrailerDetails,
        onOpenContactDetails,
    } = props;

    const permissions = useSelector(selectPermissions);

    const [isActiveEditing, setActiveEditing] = React.useState<boolean>(false);

    const updateRequestStatus = useSelector(selectUpdateTruckRequestStatus(partnerId));

    React.useEffect(() => {
        if (updateRequestStatus.ok) {
            setActiveEditing(false);
        }
    }, [updateRequestStatus.ok]);

    const dispatch = useDispatch();

    const detailsById = useSelector(selectTrucksDetailsByIds(partnerId));
    const handleEdit = () => {
        const truckDetails = detailsById[truckId as TruckIdT];

        if (truckDetails?.status === TruckStatusEnum.inTransit) {
            dispatch(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.truckNotEditable,
                        data: {},
                    }),
                ),
            );
            return;
        }

        setActiveEditing(true);
    };

    const truckDetailsById = useSelector(selectTrucksDetailsByIds(partnerId));

    const activateAssetConfirmation = useModalDialog<ActivateAssetConfirmationDataT>();
    const handleActivate = (truckId: TruckIdT | null): void => {
        if (!truckId) {
            return;
        }

        const truckDetails = truckDetailsById[truckId];

        activateAssetConfirmation.setData({
            companyId: partnerId,
            assets: [
                {
                    id: truckId,
                    plateNumber: truckDetails?.plateNumber || '',
                    ratePerKm: truckDetails?.ratePerKm || null,
                },
            ],
        });
    };

    const handleConfirmActivate = (data: ActivateAssetConfirmationDataT, ratesPerKm: Array<number | null>): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(activationTrucksRequest(ids, ratesPerKm, partnerId));
    };

    const truckById = useSelector(selectTrucksByIds(partnerId));

    const archiveAssetConfirmation = useModalDialog<ArchiveAssetConfirmationDataT>();
    const handleArchive = (truckId: TruckIdT | null): void => {
        if (!truckId) {
            return;
        }

        const truck = truckById[truckId];

        archiveAssetConfirmation.setData({
            companyId: partnerId,
            assets: [
                {
                    id: truckId,
                    plateNumber: truck?.plateNumber || '',
                },
            ],
        });
    };

    const handleConfirmArchive = (data: ArchiveAssetConfirmationDataT): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(archiveTrucksRequest(ids, data.companyId));
    };

    const setUnavailableModalConfirmation = useModalDialog<SetUnavailableAssetConfirmationDataT>();
    const setUnavailableStatus = useSelector(selectSetUnavailableVehicleRequestStatus);

    const handleSetUnavailable = (truckId: TruckIdT, query: SetUnavailableVehicleQueryT): void => {
        const truck = truckById[truckId];

        setUnavailableModalConfirmation.setData({
            companyId: partnerId,
            query: {
                ...query,
                truckId,
            },
            assets: [
                {
                    plateNumber: truck?.plateNumber || '',
                },
            ],
        });
    };

    const handleSetUnavailableConfirmation = (data: SetUnavailableAssetConfirmationDataT): void => {
        const { companyId, query } = data;

        dispatch(setUnavailableVehicleRequest(query, companyId));
    };

    useCloseModalDialogAfterRequest(setUnavailableStatus, [setUnavailableModalConfirmation]);
    useCloseModalDialogAfterRequest(updateRequestStatus, [activateAssetConfirmation, archiveAssetConfirmation]);

    return (
        <>
            {isActiveEditing ? (
                <EditTruckSidebarContent
                    partnerId={partnerId}
                    truckId={truckId}
                    onClose={onClose}
                    onGoBack={onGoBack}
                    onCancel={() => {
                        setActiveEditing(false);
                    }}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onOpenTruckDetails={onOpenTruckDetails}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                />
            ) : (
                <TruckDetailsSidebarContent
                    partnerId={partnerId}
                    truckId={truckId}
                    isShowMap={isShowMap}
                    isReadOnly={isReadOnly}
                    isDisableShowOnMap={isDisableShowOnMap}
                    onToggleShowMap={onToggleShowMap}
                    onClose={onClose}
                    onGoBack={onGoBack}
                    onActivate={handleActivate}
                    onArchive={handleArchive}
                    onEdit={handleEdit}
                    canEditTrucks={permissions.canEditTrucks}
                    onSetUnavailable={handleSetUnavailable}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                    onOpenContactDetails={onOpenContactDetails}
                />
            )}
            <SetUnavailableAssetConfirmationModal
                assetType={AssetTypeEnum.truck}
                data={setUnavailableModalConfirmation.data}
                onConfirm={handleSetUnavailableConfirmation}
                onCancel={setUnavailableModalConfirmation.onCancel}
                onClose={setUnavailableModalConfirmation.onClose}
                requestStatus={setUnavailableStatus}
            />
            <ArchiveAssetConfirmationModal
                assetType={AssetTypeEnum.truck}
                data={archiveAssetConfirmation.data}
                onConfirm={handleConfirmArchive}
                onCancel={archiveAssetConfirmation.onCancel}
                onClose={archiveAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
            <ActivateAssetDialogModal
                assetType={AssetTypeEnum.truck}
                data={activateAssetConfirmation.data}
                onConfirm={handleConfirmActivate}
                onCancel={activateAssetConfirmation.onCancel}
                onClose={activateAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
        </>
    );
};

export default CommonEditableTruckDetailsLayout;
