import { SharableStateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';
import { TrucksDictT } from './models';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import { ApiTruckDictTypeT } from 'common/utils/api/models';

export const selectTrucksDict = (state: SharableStateT): TrucksDictT | null => state.trucksDict.trucksDict;

export const selectTrucksDictRequest = (state: SharableStateT): RequestStatusT => state.trucksDict.trucksDictRequest;

export const selectTrucksDictList = (state: SharableStateT): Array<ApiTruckDictTypeT> => {
    return flatten(Object.values(state?.trucksDict?.trucksDict || {}));
};

export const selectTrucksDictById = (state: SharableStateT): Record<string, ApiTruckDictTypeT> => {
    const list = selectTrucksDictList(state);

    return keyBy(list, 'id');
};
