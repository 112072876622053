import React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipperContractLaneOtherDetails.scss';
import { useTranslation } from 'react-i18next';
import { ShipperContractLaneDetailsT } from 'common/store/shipper-contract-lane-details/models';
import cs from 'classnames';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import FeeIcon from 'common/icons/FeeIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

const HISTORY_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

type PropsT = {
    className?: string;
    data: ShipperContractLaneDetailsT | null;
    onOpenUserDetails?: (userId: UserIdT) => void;
};

const ShipperContractLaneOtherDetails: React.FC<PropsT> = (props) => {
    const { className, data, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const createDetails: Array<InfoTableRowT | null> = data?.createdDate
        ? [
              {
                  icon: null,
                  name: t('common:shipper-lane-details.history.date-added'),
                  value: <DateFormatter date={data?.createdDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-added',
              },
              onOpenUserDetails
                  ? {
                        icon: null,
                        name: t('common:shipper-lane-details.history.added-by'),
                        value: (
                            <UserLinkFormatter
                                fullName={data?.createdByName}
                                userId={data?.createdById}
                                byBroker
                                onOpenUserDetails={onOpenUserDetails}
                            />
                        ),
                        emptyValue: null,
                        isBoldValue: true,
                        testSelector: 'added-by',
                    }
                  : null,
          ]
        : [];

    const modifyDetails: Array<InfoTableRowT | null> = data?.lastEditDate
        ? [
              {
                  icon: null,
                  name: t('common:shipper-lane-details.history.date-modified'),
                  value: <DateFormatter date={data?.lastEditDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-modify',
              },
              onOpenUserDetails
                  ? {
                        icon: null,
                        name: t('common:shipper-lane-details.history.modified-by'),
                        value: (
                            <UserLinkFormatter
                                fullName={data?.lastEditByName}
                                userId={data?.lastEditById}
                                byBroker
                                onOpenUserDetails={onOpenUserDetails}
                            />
                        ),
                        emptyValue: null,
                        isBoldValue: true,
                        testSelector: 'modified-by',
                    }
                  : null,
          ]
        : [];

    const revokeDetails: Array<InfoTableRowT | null> = data?.revokedDate
        ? [
              {
                  icon: null,
                  name: t('common:shipper-lane-details.history.date-revoked'),
                  value: <DateFormatter date={data?.revokedDate} format={HISTORY_DATE_FORMAT} />,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'date-revoked',
              },
              onOpenUserDetails
                  ? {
                        icon: null,
                        name: t('common:shipper-lane-details.history.revoked-by'),
                        value: (
                            <UserLinkFormatter
                                fullName={data?.revokedByName}
                                userId={data?.revokedById}
                                byBroker
                                onOpenUserDetails={onOpenUserDetails}
                            />
                        ),
                        emptyValue: null,
                        isBoldValue: true,
                        testSelector: 'revoked-by',
                    }
                  : null,
          ]
        : [];

    const otherPrices: Array<PriceDetailT | null> = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return [
            {
                iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:shipper-lane-details.prices.total'),
                price: data?.totalPrice,
            },
            isNumber(data?.serviceFee)
                ? {
                      iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                      title: t('common:shipper-lane-details.prices.service-fee'),
                      price: data?.serviceFee,
                  }
                : null,
        ];
    }, [data]);

    return (
        <div className={cs(cx('wrap'), className)}>
            <PriceDetails className={cx('prices')} list={otherPrices} />
            <InfoTable className={cx('history-table')} rows={createDetails} testSelector="create-details" />
            <InfoTable className={cx('history-table')} rows={modifyDetails} testSelector="modify-details" />
            <InfoTable className={cx('history-table')} rows={revokeDetails} testSelector="revoke-details" />
        </div>
    );
};

export default ShipperContractLaneOtherDetails;
