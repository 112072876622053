import { UserChangesT, UserT } from 'common/store/user/models';
import { ApiUserT, ApiUserUpdateT } from 'common/utils/api/models';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { SEC_IN_MIN } from 'common/utils/time';

export const convertToApiUserUpdate = (user: UserT): ApiUserUpdateT => {
    return {
        language: user.language,
        name: user.name,
        phone: user.phone,
        surname: user.surname,
        timezone: user.timezone,
    };
};

const getAbbreviation = (firstName: string, lastName: string) => {
    return [firstName, lastName]
        .filter(Boolean)
        .map((name) => name.slice(0, 1))
        .join('')
        .toUpperCase();
};

const getFullName = (firstName: string, lastName: string) => {
    return [firstName, lastName].filter(Boolean).join(' ');
};

export const prepareUser = (apiUser: ApiUserT): UserT => {
    const defaultTimeZoneSec = moment().utcOffset() * SEC_IN_MIN;

    return {
        ...apiUser,
        timezone: isNil(apiUser?.timezone) ? defaultTimeZoneSec : apiUser?.timezone,
        abbreviation: getAbbreviation(apiUser?.name || '', apiUser?.surname || ''),
        fullName: getFullName(apiUser?.name || '', apiUser?.surname || ''),
    };
};

export const patchUser = (user: UserT, changes: UserChangesT): UserT => {
    return {
        ...user,
        language: changes.language || user.language,
        name: changes.firstName,
        phone: changes.phoneNumber,
        surname: changes.lastName,
        timezone: isNil(changes.timezone) ? user.timezone : changes.timezone,
        abbreviation: getAbbreviation(changes.firstName, changes.lastName),
        fullName: getFullName(changes.firstName, changes.lastName),
    };
};
