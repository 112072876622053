import * as React from 'react';
import { ShipperInvoiceT } from 'shipper/store/invoices/models';
import Link, { LinkSizeEnum, LinkThemeEnum, LinkToT } from 'common/components/Link/Link';

type PropsT = {
    invoice: ShipperInvoiceT | null;
    getOrderDetailsLink: (orderId: OrderIdT) => LinkToT;
};

const OrderCell: React.FC<PropsT> = React.memo((props) => {
    const { getOrderDetailsLink, invoice } = props;

    if (!invoice?.orderId) {
        return null;
    }

    return (
        <Link to={getOrderDetailsLink(invoice?.orderId)} theme={LinkThemeEnum.boldBrandDark} size={LinkSizeEnum.medium}>
            {invoice.orderNumber || '-'}
        </Link>
    );
});

export default OrderCell;
