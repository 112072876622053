import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ListPageHeaderLayout.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    leftToolsNode?: React.ReactNode;
    rightToolsNode?: React.ReactNode;
    filterTagsNode?: React.ReactNode;
    withTopPadding?: boolean;
};

const ListPageHeaderLayout: React.FC<PropsT> = React.memo((props) => {
    const { withTopPadding, leftToolsNode, rightToolsNode, filterTagsNode, className } = props;

    return (
        <div
            className={cs(
                cx('content', {
                    'content--with-top-padding': withTopPadding,
                }),
                className,
            )}
        >
            {(leftToolsNode || rightToolsNode) && (
                <div className={cx('tools')}>
                    <div className={cx('left-tools')}>{leftToolsNode}</div>
                    <div className={cx('right-tools')}>{rightToolsNode}</div>
                </div>
            )}
            {filterTagsNode && <div className={cx('filter-tags')}>{filterTagsNode}</div>}
        </div>
    );
});

export default ListPageHeaderLayout;
