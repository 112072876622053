import isNumber from 'lodash/isNumber';

export type ParamsT = {
    availableValues: number[];
    minValuesRangeWidth: number | undefined;
    maxValuesRangeWidth: number | undefined;
};

export const checkValuesIsAllowed = (values: number[] | null, params: ParamsT): boolean => {
    if (!values) {
        return true;
    }

    return (
        isNumber(values[0]) &&
        isNumber(values[1]) &&
        (isNumber(params.minValuesRangeWidth) ? values[1] - values[0] >= params.minValuesRangeWidth : true) &&
        (isNumber(params.maxValuesRangeWidth) ? values[1] - values[0] <= params.maxValuesRangeWidth : true) &&
        params.availableValues[0] <= values[0] &&
        values[0] <= params.availableValues[1] &&
        params.availableValues[0] <= values[1] &&
        values[1] <= params.availableValues[1] &&
        values[0] <= values[1]
    );
};

export const getSuggestAllowedValues = (values: number[] | null, params: ParamsT): number[] | null => {
    if (!values) {
        return null;
    }

    if (checkValuesIsAllowed(values, params)) {
        return values;
    }

    const startValue = params.availableValues[0];
    const newValues = [startValue, startValue + (params.minValuesRangeWidth || 0)];

    const leftLimitedValue = Math.max(newValues[0], params.availableValues[0]);
    const rightLimitedValue = Math.min(newValues[1], params.availableValues[1]);

    return [leftLimitedValue, rightLimitedValue];
};
