import React from 'react';
import classNames from 'classnames/bind';
import styles from './TruckFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import EmissionClassDropdown from 'common/components/dropdowns/EmissionClassDropdown/EmissionClassDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TruckStatusDropdown from 'common/components/dropdowns/TruckStatusDropdown/TruckStatusDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import GPSTrackerRadioGroup from 'common/components/radio-groups/GPSTrackerRadioGroup/GPSTrackerRadioGroup';
import AssetDedicationRadioGroup from 'common/components/radio-groups/AssetDedicationRadioGroup/AssetDedicationRadioGroup';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import TruckModelDropdown from 'common/components/dropdowns/TruckModelDropdown/TruckModelDropdown';
import { clearEmptyValues, createJsonParams } from 'common/utils/query';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { TruckFiltersSidebarDataT } from 'common/layouts/SideBars/contents/TruckFiltersSidebarContent/models';
import { useQueryParam } from 'use-query-params';
import { QueryFiltersT, QueryKeysEnum } from 'common/layouts/TrucksPageLayout/query-models';
import SelectedFilters from 'common/layouts/TrucksPageLayout/SelectedFilters/SelectedFilters';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<TruckFiltersSidebarDataT, CommonSidebarDataT>;

const TruckFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.trucksFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);
    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            clearEmptyValues(queryFilters);

            setQueryFilters({
                ...initialQueryFilters,
                ...queryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const handleLinkChange = (value: LinkValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.link, value);
    };

    const handleGPSTrackerChange = (value: GPSTrackedValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.GPSTracker, value);
    };

    const handleDedicationChange = (value: DedicationValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.dedication, value);
    };

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:trucks-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--truck-model')}
                        name={FieldsEnum.model}
                        error={formik.errors[FieldsEnum.model]}
                        meta={formik.getFieldMeta(FieldsEnum.model)}
                        label={t('common:trucks-page.filters.fields.truck-model.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TruckModelDropdown
                                placeholder={t('common:trucks-page.filters.fields.truck-model.placeholder')}
                                value={formik.values[FieldsEnum.model]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--plate-number')}
                        name={FieldsEnum.plateNumber}
                        error={formik.errors[FieldsEnum.plateNumber]}
                        meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                        label={t('common:trucks-page.filters.fields.plate-number.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.plateNumber}
                                value={formik.values[FieldsEnum.plateNumber]}
                                placeholder={t('common:trucks-page.filters.fields.plate-number.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--emission-class')}
                        name={FieldsEnum.emissionClass}
                        error={formik.errors[FieldsEnum.emissionClass]}
                        meta={formik.getFieldMeta(FieldsEnum.emissionClass)}
                        label={t('common:trucks-page.filters.fields.emission-class.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <EmissionClassDropdown
                                value={formik.values[FieldsEnum.emissionClass]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                placeholder={t('common:trucks-page.filters.fields.emission-class.placeholder')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--status')}
                        name={FieldsEnum.status}
                        error={formik.errors[FieldsEnum.status]}
                        meta={formik.getFieldMeta(FieldsEnum.status)}
                        label={t('common:trucks-page.filters.fields.status.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TruckStatusDropdown
                                value={formik.values[FieldsEnum.status]}
                                placeholder={t('common:trucks-page.filters.fields.status.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    {/* <AssetLinkRadioGroup */}
                    {/*    className={cx('field', 'field--link')} */}
                    {/*    value={formik.values[FieldsEnum.link]} */}
                    {/*    onChange={handleLinkChange} */}
                    {/* /> */}
                    <GPSTrackerRadioGroup
                        className={cx('field', 'field--gps-tracker')}
                        value={formik.values[FieldsEnum.GPSTracker]}
                        onChange={handleGPSTrackerChange}
                    />
                    <AssetDedicationRadioGroup
                        className={cx('field', 'field--dedication')}
                        value={formik.values[FieldsEnum.dedication]}
                        onChange={handleDedicationChange}
                    />
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('common:trucks-page.filters.fields.creation-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('common:trucks-page.filters.fields.creation-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastModifyDates}
                        error={formik.errors[FieldsEnum.lastModifyDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyDates)}
                        label={t('common:trucks-page.filters.fields.last-modify-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastModifyDates]}
                                placeholder={t('common:trucks-page.filters.fields.last-modify-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:trucks-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default TruckFiltersSidebarContent;
