import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './OrderStatusPill.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiOrderStatusT, OrderStatusEnum } from 'common/utils/api/models';
import { ORDER_STATUS_T_MAP } from 'common/components/status/OrderStatus/OrderStatus';

type PropsT = {
    status?: ApiOrderStatusT | null;
    isArchived?: boolean;
    isSymmetrical?: boolean;
};

const cx = classNames.bind(styles);

const PILL_THEME_BY_STATUS: Record<ApiOrderStatusT, PillLabelThemeEnum> = {
    [OrderStatusEnum.placed]: PillLabelThemeEnum.slate,
    [OrderStatusEnum.inProgress]: PillLabelThemeEnum.slate,
    [OrderStatusEnum.delivered]: PillLabelThemeEnum.slate,
    [OrderStatusEnum.canceled]: PillLabelThemeEnum.slate,
    [OrderStatusEnum.done]: PillLabelThemeEnum.brandDark,
};

const OrderStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isArchived, isSymmetrical } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    const theme = PILL_THEME_BY_STATUS[status] || PillLabelThemeEnum.charcoal;

    return (
        <>
            <PillLabel isNoWrap theme={theme} testSelector="order-status" isSymmetrical={isSymmetrical}>
                {t(ORDER_STATUS_T_MAP[status])}
            </PillLabel>
            {isArchived && (
                <PillLabel isNoWrap theme={theme} testSelector="order-archived" isSymmetrical={isSymmetrical}>
                    {t('common:order-archived')}
                </PillLabel>
            )}
        </>
    );
});

export default OrderStatusPill;
