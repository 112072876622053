import React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './AddOtherMessengerDropdown.scss';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import ExpandIcon from 'common/icons/ExpandIcon';
import { SocialMediaEnum, StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { isNonNil } from 'common/utils';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { MEDIA_FIELDS_NAME } from '../constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    formValues: {
        [SocialMediaEnum.skype]?: string;
        [SocialMediaEnum.whatsapp]?: string;
        [SocialMediaEnum.viber]?: string;
        [SocialMediaEnum.telegram]?: string;
        [SocialMediaEnum.zoom]?: string;
        [SocialMediaEnum.msTeams]?: string;
        [SocialMediaEnum.webEx]?: string;
    };
    onCreateField: (socialMedia: SocialMediaEnum) => void;
};

const AddOtherMessengerDropdown: React.FC<PropsT> = React.memo((props) => {
    const { formValues, className, onCreateField } = props;

    const { t } = useTranslation();

    const options: DropdownControlOptionT[] = React.useMemo(() => {
        return MEDIA_FIELDS_NAME.map((media) => {
            const alreadyHasField = typeof formValues[media] === 'string';
            if (alreadyHasField) {
                return null;
            }

            return {
                label: <DropdownControlOptionLabel label={t(`common:team-members.edit-contact.messengers.${media}`)} />,
                onSelect: () => {
                    onCreateField(media);
                },
            };
        }).filter(isNonNil);
    }, [formValues, onCreateField]);

    return (
        <DropdownControl
            className={cs(cx('dropdown'), className)}
            overlayClassName={cx('overlay')}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    label={t('common:team-members.edit-contact.add-messenger-trigger')}
                    rightIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default AddOtherMessengerDropdown;
