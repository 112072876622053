import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
};

const DetailsIcon: React.FC<PropsT> = (props) => {
    const { className, strokeColor, ...restProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className={className}
            {...restProps}
        >
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M12 5c2.07 0 3.75 1.68 3.75 3.75a3.73 3.73 0 01-.623 2.063L18 13.692l-1.057 1.057-2.873-2.872A3.751 3.751 0 018.25 8.75C8.25 6.68 9.93 5 12 5zm-1.5 8.25v1.5H3v-1.5h7.5zM6.75 9.5V11H3V9.5h3.75zm5.25-3a2.257 2.257 0 00-2.25 2.25A2.257 2.257 0 0012 11a2.257 2.257 0 002.25-2.25A2.257 2.257 0 0012 6.5zm-5.25-.75v1.5H3v-1.5h3.75z"
                />
            </g>
        </svg>
    );
};

DetailsIcon.displayName = 'DetailsIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default DetailsIcon;
