import * as React from 'react';
import { ErrorInfo } from 'react';

type PropsT = {};

type StateT = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<PropsT, StateT> {
    constructor(props: PropsT) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    static getDerivedStateFromError() {
        return {
            error: new Error('syntetic getDerivedStateFromError'),
            errorInfo: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error(error);
        console.info(errorInfo);
        this.setState({
            error,
            errorInfo,
        });
    }

    render(): React.ReactNode {
        const { children } = this.props;
        const { error, errorInfo } = this.state;

        if (error) {
            return (
                <div>
                    <div>
                        <b>Something went wrong...</b>
                    </div>
                    <div>
                        <div>Error message: {error.message}</div>
                        <div>Error info: {errorInfo?.componentStack}</div>
                    </div>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
