import * as React from 'react';

type PropsT = {};

const BigLaneIcon: React.FC<PropsT> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130">
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.05"
                    d="M176 20a7 7 0 010 14h-40a7 7 0 010 14h22a7 7 0 010 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 010 14H62a7 7 0 010-14H23a7 7 0 010-14h40a7 7 0 000-14H38a7 7 0 010-14h40a7 7 0 010-14h98zm0 28a7 7 0 110 14 7 7 0 010-14z"
                />
                <path
                    stroke="#8ABA2E"
                    strokeWidth="2"
                    d="M128.5 64c-8.57 0-15.5 6.573-15.5 14.7 0 11.025 15.5 27.3 15.5 27.3S144 89.725 144 78.7c0-8.127-6.93-14.7-15.5-14.7z"
                />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.3"
                    fillRule="nonzero"
                    d="M131.5 67c-8.57 0-15.5 6.573-15.5 14.7 0 11.025 12.5 24.3 12.5 24.3S144 91.604 144 80.579C144 72.452 140.07 67 131.5 67z"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M128.5 85c-2.852 0-5.167-2.352-5.167-5.25s2.315-5.25 5.167-5.25 5.167 2.352 5.167 5.25S131.352 85 128.5 85z"
                />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.3"
                    d="M64.915 31.882c0 2.136 1.762 3.883 3.916 3.883h55.466c3.25 0 5.872 2.6 5.872 5.823 0 3.223-2.623 5.824-5.872 5.824H76.66c-8.653 0-15.661 6.95-15.661 15.53V78.47C61 87.05 68.008 94 76.661 94h28.712v-7.765H76.66c-4.307 0-7.83-3.494-7.83-7.764V59.059v3.882c0-4.27 3.523-7.765 7.83-7.765h47.636c7.556 0 13.703-6.095 13.703-13.588C138 34.095 131.853 28 124.297 28H68.83c-2.154 0-3.916 1.747-3.916 3.882z"
                />
                <path
                    fill="#8ABA2E"
                    d="M64.915 31.882c0 2.136 1.762 3.883 3.916 3.883h55.466c3.25 0 5.872 2.6 5.872 5.823 0 3.223 7.831 3.223 7.831 0C138 34.095 131.853 28 124.297 28H68.83c-2.154 0-3.916 1.747-3.916 3.882z"
                />
                <path
                    stroke="#8ABA2E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M162.5 35h-17.176m24.176 0h-2.221m-11.103 7.777H148M51.5 66H34.324M28.5 66h-4.221m31.897 8.777H48"
                />
            </g>
        </svg>
    );
};

BigLaneIcon.displayName = 'BigLaneIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigLaneIcon;
