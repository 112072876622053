import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import { NotificationToastViewT, AnyNotificationT } from 'common/store/notifications/models';

export const renderCommonNotificationToast: NotificationToastRendererT = (notification) => {
    return null;
};

export const getUnknownNotificationToast = (notification: AnyNotificationT): NotificationToastViewT => {
    return {
        icon: null,
        text: notification.action,
    };
};
