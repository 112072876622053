import React from 'react';
import classNames from 'classnames/bind';

import styles from './EditTrailerSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import TrailerDetailsForm from './TrailerDetailsForm/TrailerDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAddTrailerRequestStatus,
    selectTrailersDetailsByIds,
    selectUpdateTrailersRequestStatus,
} from 'common/store/trailers/selectors';
import { addTrailerRequest, updateTrailer } from 'common/store/trailers/actions';
import { FormValuesT as DetailsFormValuesT } from './TrailerDetailsForm/constants';
import { prepareApiCreateTrailer, prepareApiModifyTrailer } from './prepare-api-trailer';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    trailerId: TrailerIdT | null;
    onGoBack?: () => void;
    onClose?: () => void;
    onCancel: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const EditTrailerSidebarContent: React.FC<PropsT> = (props) => {
    const {
        onGoBack,
        partnerId,
        trailerId,
        onClose,
        onCancel,
        setNeedCloseConfirmation,
        onOpenTruckDetails,
        onOpenTrailerDetails,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateRequestStatus = useSelector(selectUpdateTrailersRequestStatus(partnerId));
    const addRequestStatus = useSelector(selectAddTrailerRequestStatus(partnerId));

    const detailsById = useSelector(selectTrailersDetailsByIds(partnerId));
    const trailerDetails = detailsById[trailerId as string];

    const isNewTrailer = !trailerId;

    const handleSubmit = (detailsFormValues: DetailsFormValuesT) => {
        if (isNewTrailer) {
            const request = prepareApiCreateTrailer(detailsFormValues);
            dispatch(addTrailerRequest(request, partnerId));
        } else if (trailerId) {
            const request = prepareApiModifyTrailer(trailerId, detailsFormValues);
            dispatch(updateTrailer(trailerId, request, partnerId));
        }
    };

    const title = isNewTrailer ? t('common:trailers-page.add-trailer.title') : trailerDetails?.plateNumber || '';

    return (
        <>
            <HeaderSideBarLayout>
                <HeaderSideBarContent onGoBack={onGoBack} title={title} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <TrailerDetailsForm
                    partnerId={partnerId}
                    isLoading={updateRequestStatus.loading || addRequestStatus.loading}
                    isNewTrailer={isNewTrailer}
                    trailerDetails={trailerDetails}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onOpenTruckDetails={onOpenTruckDetails}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                />
            </SideBarLayout>
        </>
    );
};

export default EditTrailerSidebarContent;
