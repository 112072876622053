import React from 'react';
import styles from './SubmitButtonContent.scss';
import classNames from 'classnames/bind';
import { Trans } from 'react-i18next';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import { ApiReserveT } from 'common/store/order-creation/models';

const cx = classNames.bind(styles);

type PropsT = {
    hasReservePreview: boolean;
    isSubmitting: boolean;
    isLoadingReservePreview: boolean;
    selectedLane: ApiReserveT['contractLanes'][number] | null;
};

const SubmitButtonContent: React.FC<PropsT> = (props) => {
    const { hasReservePreview, isLoadingReservePreview, isSubmitting, selectedLane } = props;

    const [isFirstReservePreview, setIsFirstReservePreview] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (hasReservePreview) {
            setIsFirstReservePreview(false);
        }
    }, [hasReservePreview]);

    const loaderNode = <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;

    let textNode: React.ReactNode = null;
    let iconNode: React.ReactNode = null;

    if (isLoadingReservePreview) {
        iconNode = loaderNode;
        textNode = (
            <Trans
                i18nKey={
                    isFirstReservePreview
                        ? 'common:new-order-form.submit.wait-reserve-preview'
                        : 'common:new-order-form.submit.wait-new-reserve-preview'
                }
            />
        );
    } else if (selectedLane) {
        iconNode = isSubmitting ? loaderNode : null;
        textNode = (
            <Trans
                i18nKey="common:new-order-form.submit.ready-with-lane"
                components={{
                    amount: <Money amount={selectedLane.totalPrice} currency={CurrencyEnum.EUR} />,
                }}
            />
        );
    } else {
        iconNode = isSubmitting ? loaderNode : null;
        textNode = <Trans i18nKey="common:new-order-form.submit.ready" />;
    }

    return (
        <div className={cx('content-wrap')}>
            {!!iconNode && <div className={cx('loader')}>{iconNode}</div>}
            {textNode}
        </div>
    );
};

export default SubmitButtonContent;
