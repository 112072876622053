import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';

export enum FieldsEnum {
    email = 'email',
    password = 'password',
}

export type FormValuesT = {
    [FieldsEnum.email]: string;
    [FieldsEnum.password]: string;
};

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.email]: AuthErrorTypeEnum.userNotFound | AuthErrorTypeEnum.userDisabled | AuthErrorTypeEnum.wrongRole;
    [FieldsEnum.password]: AuthErrorTypeEnum.wrongPassword;
}>;

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
