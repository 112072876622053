import { CountryCodeT } from 'common/store/countries-dict/models';

export enum FieldsEnum {
    companyName = 'companyName',
    countryCode = 'countryCode',
    zipCode = 'zipCode',
    city = 'city',
    street = 'street',
    legalFormId = 'legalFormId',
}

export type FormValuesT = {
    [FieldsEnum.companyName]: string;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.zipCode]: string;
    [FieldsEnum.city]: string;
    [FieldsEnum.street]: string;
    [FieldsEnum.legalFormId]: LegalFormIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
