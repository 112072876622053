import * as React from 'react';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import TrailerIcon from 'common/icons/TrailerIcon';

export type PropsT = {
    className?: string;
    plateNumber: string | null | undefined;
    trailerId: TrailerIdT;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const LinkedTrailerAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, plateNumber, trailerId, onOpenTrailerDetails } = props;

    const { t } = useTranslation();

    return (
        <Alert
            className={className}
            icon={
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.white}
                    fillColor={StyleGuideColorsEnum.transparent}
                />
            }
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.gray}
            actionText={t('common:trucks-page.add-truck.linked-trailer.actions.open-details')}
            onClickAction={() => {
                onOpenTrailerDetails(trailerId);
            }}
        >
            {t('common:trucks-page.add-truck.linked-trailer.title', {
                plateNumber: plateNumber || '',
            })}
        </Alert>
    );
});

export default LinkedTrailerAlert;
