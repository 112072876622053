import React from 'react';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import classNames from 'classnames/bind';
import cs from 'classnames';
import find from 'lodash/find';

import styles from './SortDropdown.scss';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import DoneIcon from 'common/icons/DoneIcon';
import { useTranslation } from 'react-i18next';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

const cx = classNames.bind(styles);

export enum SortDropdownOverlayPositionEnum {
    left = 'left',
    right = 'right',
}

export type SortDropdownOptionT<T extends string> = {
    label: string;
    value: PageSortT<T>['value'];
};

export type PropsT<T extends string> = {
    selectedValue: PageSortT<T>;
    isDisabled?: boolean;
    options: Array<SortDropdownOptionT<T>>;
    onSelect: (value: PageSortT<T>) => void;
    className?: string;
    triggerClassName?: string;
    overlayPosition: SortDropdownOverlayPositionEnum;
    testSelector?: string;
};

const TOOLTIP_DELAY = 300;

const SortDropdown = <T extends string = string>(props: PropsT<T>) => {
    const { selectedValue, onSelect, options, isDisabled, className, triggerClassName, overlayPosition, testSelector } =
        props;

    const [isActive, toggleActive] = React.useState(false);
    const [isOpen, toggleOpen] = React.useState(false);

    const { t } = useTranslation();

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);
    };

    const handleClose = (): void => {
        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        handleClose();
    };

    const selectedOption = find(options, (option) => {
        return selectedValue.value === option.value;
    });

    const fullTestSelector = `${testSelector}_sort`;

    return (
        <OutsideClickHandler onOutsideClick={handleOuterEvent} isDisabled={!isOpen}>
            <div className={cs(cx('input'), className)}>
                <div
                    className={cs(
                        cx('trigger', {
                            'trigger--isClickable': !isDisabled,
                            'trigger--isPressed': isOpen,
                            'trigger--isActive': isActive,
                        }),
                        triggerClassName,
                    )}
                    onMouseUp={(event) => {
                        toggleActive(false);
                    }}
                    onMouseLeave={(event) => {
                        toggleActive(false);
                    }}
                    onMouseDown={(event) => {
                        toggleActive(true);
                    }}
                    onClick={handleOpen}
                    data-test-selector={`${fullTestSelector}_trigger`}
                >
                    <div className={cx('trigger__icon')}>
                        <ClickInterceptorLabel
                            onClick={() => {
                                const invertDirection =
                                    selectedValue?.direction === SortDirectionEnum.DESC
                                        ? SortDirectionEnum.ASC
                                        : SortDirectionEnum.DESC;

                                onSelect({
                                    value: selectedValue?.value,
                                    direction: invertDirection,
                                });
                            }}
                            onMouseUp={(event) => {
                                event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Tooltip
                                position={
                                    overlayPosition === SortDropdownOverlayPositionEnum.right
                                        ? TooltipPositionEnum.centerLeft
                                        : TooltipPositionEnum.centerRight
                                }
                                theme={TooltipThemeEnum.black}
                                delay={TOOLTIP_DELAY}
                                tooltipNode={
                                    <div className={cx('trigger-tooltip')}>
                                        {selectedValue?.direction === SortDirectionEnum.ASC
                                            ? t('common:change-sort.desc')
                                            : t('common:change-sort.asc')}
                                    </div>
                                }
                            >
                                {() => (
                                    <div
                                        className={cx('trigger-icon', {
                                            'trigger-icon--isRevert':
                                                selectedValue?.direction === SortDirectionEnum.ASC,
                                        })}
                                        data-test-selector={`${fullTestSelector}_direction`}
                                    />
                                )}
                            </Tooltip>
                        </ClickInterceptorLabel>
                    </div>
                    <div className={cx('trigger__label')}>{selectedOption?.label || ''}</div>
                    <div className={cx('trigger__icon')}>
                        <ArrowsIcon className={cx('icon')} />
                    </div>
                </div>
                {isOpen && (
                    <div
                        className={cx('overlay', {
                            [`overlay--position-${overlayPosition}`]: true,
                        })}
                    >
                        {options.map((option, index): React.ReactElement => {
                            const { label, value } = option || {};

                            const isSelected = selectedValue.value === value;

                            return (
                                <div
                                    key={index}
                                    className={cx('option', {
                                        'option--isSelected': isSelected,
                                    })}
                                    onClick={(): void => {
                                        if (isDisabled) {
                                            return;
                                        }

                                        onSelect({
                                            value,
                                            direction: selectedValue.direction,
                                        });

                                        handleClose();
                                    }}
                                    data-test-selector={`${fullTestSelector}_option_${index}`}
                                >
                                    <div className={cx('option__label')}>{label}</div>
                                    {isSelected && (
                                        <div
                                            className={cx('option__icon')}
                                            data-test-selector={`${fullTestSelector}_selected`}
                                        >
                                            <DoneIcon
                                                fillColor={StyleGuideColorsEnum.charcoal}
                                                size={DEFAULT_ICON_SIZE}
                                                className={cx('icon')}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
};

export default React.memo(SortDropdown) as typeof SortDropdown;
