import * as React from 'react';
import PillLabel, { colorCodingPillLabelTheme, PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiShipperContractLaneStatusT, ShipperContractLaneStatusEnum } from 'common/utils/api/models';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import { useSelector } from 'react-redux';
import { selectProfileType } from 'common/store/auth/selectors';
import ShipperContractLaneStatus from 'common/components/status/ShipperContractLaneStatus/ShipperContractLaneStatus';

type PropsT = {
    status?: ApiShipperContractLaneStatusT | null;
    isSymmetrical?: boolean;
    className?: string;
};

type ContractLaneStatusColorMapT = Record<ApiShipperContractLaneStatusT, PillLabelThemeEnum>;

const THEME_MAP: Record<AuthProfileTypeEnum, ContractLaneStatusColorMapT> = {
    [AuthProfileTypeEnum.shipper]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingPillLabelTheme.success,
    },
    [AuthProfileTypeEnum.carrier]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingPillLabelTheme.success,
    },
    [AuthProfileTypeEnum.broker]: {
        [ShipperContractLaneStatusEnum.revoked]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.expired]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.completed]: colorCodingPillLabelTheme.normal,
        [ShipperContractLaneStatusEnum.active]: colorCodingPillLabelTheme.success,
    },
};

const DEFAULT_THEME = colorCodingPillLabelTheme.normal;

const ShipperContractLaneStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, className, isSymmetrical } = props;

    const profileTypeEnum = useSelector(selectProfileType);

    if (!status) {
        return null;
    }

    const theme = status ? THEME_MAP[profileTypeEnum]?.[status] : DEFAULT_THEME;

    return (
        <>
            <PillLabel
                isNoWrap
                theme={theme}
                testSelector="shipper-contact-lane-status"
                className={className}
                isSymmetrical={isSymmetrical}
            >
                <ShipperContractLaneStatus status={status} />
            </PillLabel>
        </>
    );
});

export default ShipperContractLaneStatusPill;
