import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './ShipmentPicker.scss';
import { FieldsEnum, FormValuesT, RoutePointT, ShipmentFieldsEnum } from '../constants';
import { useTranslation } from 'react-i18next';
import Input from 'common/components/Input/Input';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import TemperatureRangePicker from 'common/layouts/NewOrderPage/CargoDetailsForm/TemperatureRangePicker/TemperatureRangePicker';
import NumberInput from 'common/components/NumberInput/NumberInput';
import CargoUnitIcon, { CargoUnitIconProps } from 'common/icons/CargoUnitIcon';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import WeightIcon, { WeightIconProps } from 'common/icons/WeightIcon';
import { getWeightMask } from 'common/utils/input-masks';
import UnitType from 'common/components/units/UnitType/UnitType';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { useMemo } from 'react';
import { formatAddressComponents } from 'common/utils/google-places';
import NumberIcon from 'common/icons/NumberIcon';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import noop from 'lodash/noop';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    shipmentIndex: number;
    trailerType: ApiTrailerDictTypeT | null;
    routePoints: Array<RoutePointT>;
    closeOverloadingValidationAlertCallback: (() => void) | null;
    closeNotUsedPointValidationAlertCallback: (() => void) | null;
    closeTemperatureRangesValidationAlertCallback: (() => void) | null;
};

type RoutePointOptionT = {
    icon: React.ReactNode;
    label: React.ReactNode;
    value: RoutePointIdT | null;
};

type UnitTypeOptionT = {
    label: React.ReactNode;
    value: UnitTypeEnum;
};

const AVAILABLE_UNIT_TYPES = [UnitTypeEnum.eur1Pallets, UnitTypeEnum.eur2Pallets, UnitTypeEnum.other] as const;

const DEFAULT_MIN_VALUE_TEMPERATURE_RANGE = 2;

const ShipmentPicker: React.FC<PropsT> = React.memo((props) => {
    const { shipmentIndex, className, trailerType, routePoints } = props;

    const closeOverloadingValidationAlertCallback = props.closeOverloadingValidationAlertCallback || noop;

    const closeNotUsedPointValidationAlertCallback = props.closeNotUsedPointValidationAlertCallback || noop;

    const closeTemperatureRangesValidationAlertCallback = props.closeTemperatureRangesValidationAlertCallback || noop;

    const { t } = useTranslation();

    const isReefer = !!trailerType?.reefer;

    const cargoUnitsCountFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.cargoUnitsCount}` as const;
    const cargoUnitTypeFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.cargoUnitType}` as const;
    const cargoWeightFieldName = `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.cargoWeight}` as const;
    const cargoTemperatureRangeFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.cargoTemperatureRange}` as const;
    const cargoDescriptionFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.cargoDescription}` as const;

    const pickUpReferenceNumberFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.pickUpReferenceNumber}` as const;
    const dropOffReferenceNumberFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.dropOffReferenceNumber}` as const;
    const poNumberFieldName = `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.poNumber}` as const;

    const weightMask = React.useMemo(() => getWeightMask(), []);

    const unitOptions: UnitTypeOptionT[] = React.useMemo(
        () =>
            AVAILABLE_UNIT_TYPES.map((type) => {
                return {
                    value: type,
                    label: <UnitType type={type} />,
                };
            }),
        [],
    );

    const pickUpPointIdFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.pickUpPointId}` as const;

    const pickUpPointOptions = useMemo((): Array<RoutePointOptionT> => {
        return routePoints
            .filter((routePoint, index) => {
                const isLastIndex = index === routePoints.length - 1;
                if (isLastIndex) {
                    return false;
                }

                return !routePoint.driveThru;
            })
            .map((routePoint): RoutePointOptionT => {
                return {
                    icon: <NumberIcon number={(routePoint.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
                    label: formatAddressComponents(routePoint.address),
                    value: routePoint.id,
                };
            });
    }, [routePoints]);

    const dropOffPointIdFieldName =
        `${FieldsEnum.shipments}.${shipmentIndex}.${ShipmentFieldsEnum.dropOffPointId}` as const;

    const dropOffPointOptions = useMemo((): Array<RoutePointOptionT> => {
        return routePoints
            .filter((routePoint, index) => {
                const isFirstIndex = index === 0;
                if (isFirstIndex) {
                    return false;
                }

                return !routePoint.driveThru;
            })
            .map((routePoint): RoutePointOptionT => {
                return {
                    icon: <NumberIcon number={(routePoint.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
                    label: formatAddressComponents(routePoint.address),
                    value: routePoint.id,
                };
            });
    }, [routePoints]);

    return (
        <div className={className}>
            <FieldGroup>
                <FieldGroup isFullWidth className={cx('field', 'field--units')}>
                    <FormikConnectContextField<FormValuesT, typeof cargoUnitsCountFieldName>
                        name={cargoUnitsCountFieldName}
                        label={t('common:new-order-form.fields.units.label')}
                    >
                        {(props, extendsProps) => (
                            <div className={cx('combined-field')}>
                                <NumberInput
                                    className={cx('combined-field__full-width')}
                                    inputClassName={cx('combined-field__input', {
                                        'combined-field__input--unit-value': true,
                                    })}
                                    placeholder={t('common:new-order-form.fields.units.input.placeholder')}
                                    name={extendsProps.name}
                                    value={extendsProps.value}
                                    onChange={(value) => {
                                        closeOverloadingValidationAlertCallback();
                                        props.onChange(value);
                                    }}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    renderLeftIcon={(iconMeta) => (
                                        <CargoUnitIcon {...CargoUnitIconProps.getControlProps(iconMeta)} />
                                    )}
                                />
                                <FormikConnectContextField<FormValuesT, typeof cargoUnitTypeFieldName>
                                    name={cargoUnitTypeFieldName}
                                    withoutLabelAndNotes
                                >
                                    {(props, extendsProps) => (
                                        <DropdownInput<UnitTypeOptionT, UnitTypeOptionT['value']>
                                            triggerClassName={cx('combined-field__input', {
                                                'combined-field__input--unit-type': true,
                                            })}
                                            selectedValue={extendsProps.value}
                                            options={unitOptions}
                                            onSelect={(value) => {
                                                closeOverloadingValidationAlertCallback();
                                                props.onChange(value);
                                            }}
                                            renderOption={(option) =>
                                                option ? (
                                                    <DropdownControlOptionLabel withoutPaddings label={option.label} />
                                                ) : null
                                            }
                                            getOptionValue={(option) => option.value}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                            placeholder={t('common:new-order-form.fields.units.dropdown.placeholder')}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            isInline
                                        />
                                    )}
                                </FormikConnectContextField>
                            </div>
                        )}
                    </FormikConnectContextField>
                </FieldGroup>
                <FormikConnectContextField<FormValuesT, typeof cargoWeightFieldName>
                    name={cargoWeightFieldName}
                    label={t('common:new-order-form.fields.cargo-weight.label')}
                    className={cx('field', 'field--cargo-weight')}
                >
                    {(props, extendsProps) => (
                        <NumberInput
                            name={extendsProps.name}
                            placeholder={t('common:new-order-form.fields.cargo-weight.placeholder')}
                            unitType={UnitTypeEnum.kilogramsAbbreviation}
                            renderLeftIcon={(iconMeta) => <WeightIcon {...WeightIconProps.getControlProps(iconMeta)} />}
                            value={extendsProps.value}
                            onChange={(value) => {
                                closeOverloadingValidationAlertCallback();
                                props.onChange(value);
                            }}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            mask={weightMask}
                            step={1}
                        />
                    )}
                </FormikConnectContextField>
            </FieldGroup>
            {isReefer && (
                <FormikConnectContextField<FormValuesT, typeof cargoTemperatureRangeFieldName>
                    name={cargoTemperatureRangeFieldName}
                    withoutLabel
                    className={cx('field')}
                >
                    {(props, extendsProps) => (
                        <TemperatureRangePicker
                            className={cx('temperature')}
                            minValueRange={DEFAULT_MIN_VALUE_TEMPERATURE_RANGE}
                            name={extendsProps.name}
                            value={extendsProps.value}
                            onChange={(value) => {
                                closeTemperatureRangesValidationAlertCallback();
                                props.onChange(value);
                            }}
                            label={t('common:new-order-form.fields.temperature-range.label')}
                        />
                    )}
                </FormikConnectContextField>
            )}
            <FormikConnectContextField<FormValuesT, typeof pickUpPointIdFieldName>
                name={pickUpPointIdFieldName}
                label={t('common:new-order-form.fields.pickup-point.label')}
                className={cx('field', 'field--pickup-point')}
            >
                {(props, extendsProps) => (
                    <DropdownInput<RoutePointOptionT, RoutePointOptionT['value']>
                        selectedValue={extendsProps.value}
                        options={pickUpPointOptions}
                        onSelect={(value) => {
                            closeOverloadingValidationAlertCallback();
                            closeNotUsedPointValidationAlertCallback();
                            props.onChange(value);
                        }}
                        renderOption={(option) =>
                            option ? (
                                <DropdownControlOptionLabel withoutPaddings icon={option.icon} label={option.label} />
                            ) : null
                        }
                        getOptionValue={(option) => option.value}
                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                        placeholder={t('common:new-order-form.fields.pickup-point.placeholder')}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikConnectContextField>
            <FormikConnectContextField<FormValuesT, typeof dropOffPointIdFieldName>
                name={dropOffPointIdFieldName}
                label={t('common:new-order-form.fields.dropoff-point.label')}
                className={cx('field', 'field--dropoff-point')}
            >
                {(props, extendsProps) => (
                    <DropdownInput<RoutePointOptionT, RoutePointOptionT['value']>
                        selectedValue={extendsProps.value}
                        options={dropOffPointOptions}
                        onSelect={(value) => {
                            closeOverloadingValidationAlertCallback();
                            closeNotUsedPointValidationAlertCallback();
                            props.onChange(value);
                        }}
                        renderOption={(option) =>
                            option ? (
                                <DropdownControlOptionLabel withoutPaddings icon={option.icon} label={option.label} />
                            ) : null
                        }
                        getOptionValue={(option) => option.value}
                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                        placeholder={t('common:new-order-form.fields.dropoff-point.placeholder')}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikConnectContextField>
            <FormikConnectContextField<FormValuesT, typeof cargoDescriptionFieldName>
                name={cargoDescriptionFieldName}
                label={t('common:new-order-form.fields.cargo-description.label')}
                className={cx('field', 'field--cargo-description')}
            >
                {(props, extendsProps) => (
                    <Input
                        name={extendsProps.name}
                        value={extendsProps.value}
                        placeholder={t('common:new-order-form.fields.cargo-description.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikConnectContextField>
            <FormikConnectContextField<FormValuesT, typeof poNumberFieldName>
                name={poNumberFieldName}
                label={t('common:new-order-form.fields.po-number.label')}
                className={cx('field', 'field--po-number')}
            >
                {(props, extendsProps) => (
                    <Input
                        name={extendsProps.name}
                        value={extendsProps.value}
                        placeholder={t('common:new-order-form.fields.po-number.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikConnectContextField>
            <FieldGroup spaces={1}>
                <FormikConnectContextField<FormValuesT, typeof pickUpReferenceNumberFieldName>
                    name={pickUpReferenceNumberFieldName}
                    label={t('common:new-order-form.fields.puck-up-ref-number.label')}
                    className={cx('field', 'field--puck-up-ref-number')}
                >
                    {(props, extendsProps) => (
                        <Input
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-form.fields.puck-up-ref-number.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
                <FormikConnectContextField<FormValuesT, typeof dropOffReferenceNumberFieldName>
                    name={dropOffReferenceNumberFieldName}
                    label={t('common:new-order-form.fields.drop-off-ref-number.label')}
                    className={cx('field', 'field--drop-off-ref-number')}
                >
                    {(props, extendsProps) => (
                        <Input
                            name={extendsProps.name}
                            value={extendsProps.value}
                            placeholder={t('common:new-order-form.fields.drop-off-ref-number.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikConnectContextField>
            </FieldGroup>
        </div>
    );
});

export default ShipmentPicker;
