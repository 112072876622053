import { FetchRFQsPageQueryT, RFQT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_RFQS_PAGE_REQUEST = 'common/rfqs/FETCH_RFQS_PAGE_REQUEST';
export const FETCH_RFQS_PAGE_REQUEST_BEGIN = 'common/rfqs/FETCH_RFQS_PAGE_REQUEST_BEGIN';
export const FETCH_RFQS_PAGE_REQUEST_ERROR = 'common/rfqs/FETCH_RFQS_PAGE_REQUEST_ERROR';
export const FETCH_RFQS_PAGE_REQUEST_SUCCESS = 'common/rfqs/FETCH_RFQS_PAGE_REQUEST_SUCCESS';

export const RESET_RFQS = 'common/rfqs/RESET_RFQS';

export type RFQsStateT = Record<TransportOrderIdT, CompanyRFQsStateT>;

export type CompanyRFQsStateT = PaginationStateT<RFQT, FetchRFQsPageQueryT>;

export type FetchRFQsPageActionT = {
    type: typeof FETCH_RFQS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchRFQsPageQueryT;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchRFQsPageBeginActionT = {
    type: typeof FETCH_RFQS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchRFQsPageQueryT;
    companyId: CompanyIdT;
};

export type FetchRFQsPageSuccessActionT = {
    type: typeof FETCH_RFQS_PAGE_REQUEST_SUCCESS;
    query: FetchRFQsPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<RFQT> | null;
    companyId: CompanyIdT;
};

export type FetchRFQsPageErrorActionT = {
    type: typeof FETCH_RFQS_PAGE_REQUEST_ERROR;
    query: FetchRFQsPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
    companyId: CompanyIdT;
};

export type ResetRFQsActionT = {
    type: typeof RESET_RFQS;
    companyId: CompanyIdT;
    mutationListOptions: MutationListOptionsT;
};

export type RFQsActionT =
    | FetchRFQsPageActionT
    | FetchRFQsPageBeginActionT
    | FetchRFQsPageSuccessActionT
    | FetchRFQsPageErrorActionT
    | ResetRFQsActionT;
