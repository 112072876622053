import 'common/base';

import './locales/i18n';

import * as React from 'react';
import { render } from 'react-dom';

import App from './layouts/App/App';

const rootNode = document.getElementById('root');

render(<App />, rootNode);
