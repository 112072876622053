import { useMemo, useState } from 'react';

export type UseEntriesTabsResultT<T extends string> = {
    activeEntriesTabType: T;
    setActiveEntriesTabType: (tabType: T) => void;
};

export const useEntriesTabs = <T extends string>(initialActiveTabType: T): UseEntriesTabsResultT<T> => {
    const [activeEntriesTabType, setActiveEntriesTabType] = useState<T>(initialActiveTabType);

    return useMemo(() => {
        return {
            activeEntriesTabType,
            setActiveEntriesTabType,
        };
    }, [activeEntriesTabType, setActiveEntriesTabType]);
};
