import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import * as React from 'react';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';

const useCountriesByCode = (): ReturnType<typeof selectCountriesByCode> => {
    const dispatch = useDispatch();

    const countriesByCode = useSelector(selectCountriesByCode);
    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    return countriesByCode;
};

export { useCountriesByCode };
