import {
    CLOSE_RFQ,
    CHANGE_RFQ_REQUEST_BEGIN,
    CHANGE_RFQ_REQUEST_ERROR,
    CHANGE_RFQ_REQUEST_SUCCESS,
    CloseRFQActionT,
    ChangeRFQBeginActionT,
    ChangeRFQErrorActionT,
    ChangeRFQSuccessActionT,
} from './types';

export const closeRFQ = (id: RFQIdT): CloseRFQActionT => ({
    type: CLOSE_RFQ,
    id,
});

export const changeRFQBegin = (id: RFQIdT): ChangeRFQBeginActionT => ({
    type: CHANGE_RFQ_REQUEST_BEGIN,
    id,
});

export const changeRFQSuccess = (id: RFQIdT): ChangeRFQSuccessActionT => ({
    type: CHANGE_RFQ_REQUEST_SUCCESS,
    id,
});

export const changeRFQError = (id: RFQIdT, error: Error): ChangeRFQErrorActionT => ({
    type: CHANGE_RFQ_REQUEST_ERROR,
    error,
    id,
});
