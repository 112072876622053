import * as React from 'react';
import { ApiDriverStatusT, DriverStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import DriverStatus from 'common/components/status/DriverStatus/DriverStatus';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiDriverStatusT | null;
    isSymmetrical?: boolean;
};

export const PILL_THEME_BY_DRIVER_STATUS: Record<ApiDriverStatusT, PillLabelThemeEnum> = {
    [DriverStatusEnum.free]: PillLabelThemeEnum.brandDark,
    [DriverStatusEnum.pending]: PillLabelThemeEnum.orange,
    [DriverStatusEnum.inTransit]: PillLabelThemeEnum.brandDark,
    [DriverStatusEnum.archived]: PillLabelThemeEnum.light,
};

const DriverStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap {...restProps} theme={PILL_THEME_BY_DRIVER_STATUS[status]}>
            <DriverStatus status={status} />
        </PillLabel>
    );
});

export default DriverStatusPill;
