import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Avatar.scss';

export enum AvatarSizeEnum {
    small = 'small',
    medium = 'medium',
    large = 'large',
    extraLarge = 'extra-large',
}

type PropsT = {
    className?: string;
    hash: string | null;
    size?: AvatarSizeEnum;
};

const cx = classNames.bind(styles);

const Avatar: React.FC<PropsT> = React.memo((props) => {
    const { className, hash, size = AvatarSizeEnum.small } = props;

    const index = (hash || '').split('').reduce((summ, char) => summ + char.charCodeAt(0), 0) % 6;

    return <div className={cs(cx('avatar', `avatar--${hash ? index : ''}`, `avatar--size-${size}`), className)} />;
});

export default Avatar;
