import * as React from 'react';
import isNumber from 'lodash/isNumber';
import { useMemo } from 'react';

type LatT = number;
type LonT = number;

export type BoundPointT = [LatT | undefined | null, LonT | undefined | null];

type PropsT = {
    map: MapT | null | undefined;
    maps: MapsT | null | undefined;
    allMapPoints?: Array<BoundPointT> | null | undefined;
    boundingBox?: BoundingBoxT | null | undefined;
};

const MapBound: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, allMapPoints, boundingBox } = props;

    // to block the map's refocus at the same points
    const allMapPointsHash = useMemo(() => {
        return JSON.stringify(allMapPoints);
    }, [allMapPoints]);

    React.useEffect(() => {
        if (!maps || !map || !allMapPoints?.length) {
            return;
        }

        const bounds = new maps.LatLngBounds();
        allMapPoints.forEach((point) => {
            if (isNumber(point[0]) && isNumber(point[1])) {
                const boundPoint = new maps.LatLng(point[0], point[1]);
                bounds.extend(boundPoint);
            }
        });

        map.fitBounds(bounds);
    }, [map, maps, allMapPointsHash]);

    // to block the map's refocus at the same points
    const boundingBoxHash = useMemo(() => {
        return JSON.stringify(boundingBox);
    }, [boundingBox]);

    React.useEffect(() => {
        if (!map || !maps || !boundingBox?.length) {
            return;
        }

        const point1 = new maps.LatLng(...boundingBox[0]);
        const point2 = new maps.LatLng(...boundingBox[1]);
        const bounds = new maps.LatLngBounds(point1, point2);
        map.fitBounds(bounds);
    }, [map, maps, boundingBoxHash]);

    return null;
});

export default MapBound;
