import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_RFQS_PAGE_REQUEST, FetchRFQsPageActionT } from './types';
import { fetchRFQsPageBegin, fetchRFQsPageError, fetchRFQsPageSuccess, resetRFQs } from './actions';
import { selectRFQsPages, selectRFQsQuery } from './selectors';

import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { checkIsDefaultCompanyId } from 'common/utils';
import { rfqsPaginationChannel } from 'common/store/rfqs/channels';

function* fetchRFQsSaga(action: FetchRFQsPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query: rawQuery, companyId } = action;

    const query: typeof rawQuery = {
        ...rawQuery,
    };

    const prevQuery: ReReturnT<typeof selectRFQsQuery> = yield select(selectRFQsQuery(companyId));
    const pages: ReReturnT<typeof selectRFQsPages> = yield select(selectRFQsPages(companyId));
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetRFQs(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchRFQsPageBegin(query, pageNumber, companyId));

    let result: ReturnApiT<typeof shipperTranziitApi.fetchRFQsPage | typeof brokerTranziitApi.fetchRFQsPage>;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield shipperTranziitApi.fetchRFQsPage({
            ...query,
            page: pageNumber,
        });
    } else {
        result = yield brokerTranziitApi.fetchRFQsPage({
            ...query,
            page: pageNumber,
        });
    }

    const [error, response] = result;

    if (error) {
        yield put(fetchRFQsPageError(query, pageNumber, error, companyId));
        return;
    }

    yield put(fetchRFQsPageSuccess(query, pageNumber, response, companyId));

    checkShouldEmitChangePage(pageNumber, response, rfqsPaginationChannel);
}

function* rfqsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_RFQS_PAGE_REQUEST, fetchRFQsSaga);
}

export default rfqsSaga;
