import * as React from 'react';

import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { CountryCodeT } from 'common/store/countries-dict/models';

import classNames from 'classnames/bind';
import styles from './ExcludedCountries.scss';
import ExcludedCountry from './ExcludedCountry/ExcludedCountry';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

import isEmpty from 'lodash/isEmpty';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    countryCodes: Array<CountryCodeT>;
    titleNode?: React.ReactNode;
    tooltipNode?: React.ReactNode;
};

const ExcludedCountries: React.FC<PropsT> = React.memo((props) => {
    const { countryCodes, titleNode, tooltipNode, className } = props;

    const countryByCode = useSelector(selectCountriesByCode);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    if (isEmpty(countryCodes)) {
        return null;
    }
    return (
        <div className={cs(cx('wrap'), className)}>
            <Tooltip
                className={cx('tooltip')}
                position={TooltipPositionEnum.centerRight}
                theme={TooltipThemeEnum.black}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={300}>
                        {tooltipNode}
                    </TooltipContent>
                }
            >
                {(isShow) => <TooltipIconTrigger className={cx('tooltip__icon')} isShow={isShow} />}
            </Tooltip>
            <div className={cx('title')}>{titleNode}</div>
            {countryCodes.map((countryCode: CountryCodeT, index: number) => {
                const country = countryByCode[countryCode];

                return (
                    <ExcludedCountry
                        key={index}
                        countryCode={countryCode}
                        countryLabel={country?.userLangDisplayName}
                        className={cx('country')}
                    />
                );
            })}
        </div>
    );
});

export default ExcludedCountries;
