import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectAllowedCreateAnonymously, selectUser } from 'common/store/auth/selectors';
import LeftMenuLayoutBase from 'common/layouts/LeftMenuLayout/LeftMenuLayout';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';

type PropsT = {
    children: React.ReactElement;
};

const AuthLoader: React.FC<PropsT> = (props) => {
    const { children } = props;

    const shouldCreateAnonymously = useSelector(selectAllowedCreateAnonymously);
    const user = useSelector(selectUser);

    if (!shouldCreateAnonymously) {
        return children;
    }

    if (shouldCreateAnonymously && user) {
        return children;
    }

    return (
        <LeftMenuLayoutBase menuLinks={[]} accountLink="" logoLink="" badges={{}}>
            <LoaderOverlay />
        </LeftMenuLayoutBase>
    );
};

export default AuthLoader;
