import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './RFQStatusPill.scss';

import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiRFQStatusT, RFQStatusEnum } from 'common/utils/api/models';
import { RFQ_STATUS_T_MAP } from 'common/components/status/RFQStatus/RFQStatus';

const cx = classNames.bind(styles);

type PropsT = {
    status?: ApiRFQStatusT | null;
    isImported?: boolean;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_STATUS: Record<ApiRFQStatusT, PillLabelThemeEnum> = {
    [RFQStatusEnum.open]: PillLabelThemeEnum.slate,
    [RFQStatusEnum.expireSoon]: PillLabelThemeEnum.orange,
    [RFQStatusEnum.expired]: PillLabelThemeEnum.slate,
    [RFQStatusEnum.completed]: PillLabelThemeEnum.slate,
    [RFQStatusEnum.closed]: PillLabelThemeEnum.slate,
};

const RFQStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isSymmetrical, isImported } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    const theme = PILL_THEME_BY_STATUS[status] || PillLabelThemeEnum.charcoal;

    return (
        <>
            <PillLabel
                isNoWrap
                isSymmetrical={isSymmetrical}
                theme={theme}
                testSelector="rfq-status"
                className={cx('status')}
            >
                {t(RFQ_STATUS_T_MAP[status])}
            </PillLabel>
            {isImported && (
                <PillLabel
                    isNoWrap
                    isSymmetrical={isSymmetrical}
                    theme={PillLabelThemeEnum.charcoal}
                    testSelector="rfq-imported"
                >
                    {t('common:rfq-source.imported')}
                </PillLabel>
            )}
        </>
    );
});

export default RFQStatusPill;
