import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const Invoice2Icon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={size} height={size}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 1h18v18H0z" />
                <path fill={fillColor} d="M5 4h10v12l-1 1H4l-1-1v-2h2z" />
                <path
                    fill={strokeColor}
                    d="M15.75 2.5v12.75a2.247 2.247 0 0 1-2.25 2.25h-9a2.247 2.247 0 0 1-2.25-2.25V13H4.5V2.5l1.125 1.125L6.75 2.5l1.125 1.125L9 2.5l1.125 1.125L11.25 2.5l1.125 1.125L13.5 2.5l1.125 1.125L15.75 2.5zm-4.5 12h-7.5v.75c0 .412.338.75.75.75h6.75v-1.5zm3-9.75H6V13h6.75v2.25c0 .412.338.75.75.75s.75-.338.75-.75V4.75zm-3 3.75V10h-4.5V8.5h4.5zm2.25 0V10H12V8.5h1.5zm-2.25-2.25v1.5h-4.5v-1.5h4.5zm2.25 0v1.5H12v-1.5h1.5z"
                />
            </g>
        </svg>
    );
};

Invoice2Icon.displayName = 'Invoice2Icon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default Invoice2Icon;
