import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RFQListPageLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { OMSRoutesEnum } from 'common/constants';
import { QueryFiltersKeysEnum, QueryKeysEnum, QueryFiltersT } from './query-models';
import RFQsTable from 'common/layouts/RFQListPageLayout/RFQsTable/RFQsTable';
import { useTranslation } from 'react-i18next';
import history from 'common/utils/history';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import omit from 'lodash/omit';
import { RFQsQuickFilterEnum, RFQsSortEnum, RFQStatusEnum } from 'common/utils/api/models';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { RFQT } from 'common/store/rfqs/models';
import FiltersSidebarContent from './FiltersSidebarContent/FiltersSidebarContent';
import SideBar from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import { selectRFQsByIds, selectRFQsPages, selectRFQsTotal } from 'common/store/rfqs/selectors';
import { fetchRFQsPage } from 'common/store/rfqs/actions';
import { isNonNil } from 'common/utils';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import { fetchGroupedNotificationCounts } from 'common/store/grouped-notification-counts/actions';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { fetchRFQsStats } from 'common/store/rfqs-stats/actions';
import { selectRFQsStats } from 'common/store/rfqs-stats/selectors';
import { selectGroupedNotificationCounts } from 'common/store/grouped-notification-counts/selectors';
import { NotificationLabelThemeEnum } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { rfqsPaginationChannel, rfqsRefreshChannel } from 'common/store/rfqs/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { selectCompanyVerificationProblems, selectCurrentCompany } from 'common/store/company/selectors';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { useQueryParams } from 'use-query-params';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import QuickFiltersButtonGroups from 'common/components/Table/QuickFiltersButtonGroups/QuickFiltersButtonGroups';

const cx = classNames.bind(styles);

type PropsT = {
    onOpenRFQDetails: (event: React.MouseEvent, rfqId: RFQIdT | null) => void;
    onOpenUserDetails: (userId: UserIdT) => void;
};

const quickFilterOptionSeparators: number[] = [];

const RFQListPageLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerId } = usePartnerContext();

    const { onOpenRFQDetails, onOpenUserDetails } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const total = useSelector(selectRFQsTotal(partnerId));
    const rfqsPages = useSelector(selectRFQsPages(partnerId));
    const rfqById = useSelector(selectRFQsByIds(partnerId));

    const currentCompany = useSelector(selectCurrentCompany);
    const isDisabledCreateOrder = !currentCompany;

    const [isShowFilters, triggerFilters] = React.useState<boolean>(false);
    const showFilters = (): void => {
        triggerFilters(true);
    };
    const hideFilters = (): void => {
        triggerFilters(false);
    };

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.rfqsPage]: createPageNumberParam(),
        [QueryKeysEnum.rfqsSort]: createSortParams<RFQsSortEnum>({
            direction: SortDirectionEnum.DESC,
            value: RFQsSortEnum.creationDate,
        }),
        [QueryKeysEnum.rfqsFilters]: createJsonParams<QueryFiltersT>({}),
    });

    const pageNumber = query[QueryKeysEnum.rfqsPage];
    const selectedSort = query[QueryKeysEnum.rfqsSort];
    const queryFilters = query[QueryKeysEnum.rfqsFilters];

    const page = rfqsPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchRFQsStats());

        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRFQsPage(pageNumber, query, partnerId, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRFQsPage(pageNumber, query, partnerId, { isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters, partnerId]);
    useChannelSubscribe(rfqsRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        dispatch(fetchRFQsStats());

        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRFQsPage(pageNumber, query, partnerId));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSort, queryFilters, partnerId]);

    const rfqs = React.useMemo(() => {
        return (ids || []).map((id): RFQT => rfqById[id as string]).filter(isNonNil);
    }, [ids, rfqById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.rfqsPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof rfqsPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(rfqsPaginationChannel, setPageHandler);

    const verificationProblems = useSelector(selectCompanyVerificationProblems);

    const sortOptions: Array<SortDropdownOptionT<RFQsSortEnum>> = React.useMemo(
        () => [
            {
                label: t('common:rfqs-page.table.sorts.creation-date'),
                value: RFQsSortEnum.creationDate,
            },
            {
                label: t('common:rfqs-page.table.sorts.deadline'),
                value: RFQsSortEnum.deadline,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<RFQsSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.rfqsPage]: 0,
            [QueryKeysEnum.rfqsSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: RFQsQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.rfqsFilters] || {};

        const queryFilters: QueryFiltersT = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.rfqsPage]: 0,
            [QueryKeysEnum.rfqsFilters]: queryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.rfqsPage]: 0,
            [QueryKeysEnum.rfqsFilters]: selectedQueryFilters,
        });

        hideFilters();
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.rfqsFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.rfqsPage]: 0,
            [QueryKeysEnum.rfqsFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const stats = useSelector(selectRFQsStats);
    const unReadNotificationsGroupedCounts = useSelector(selectGroupedNotificationCounts);

    const quickFilterOptions: Array<QuickFiltersOptionT<RFQsQuickFilterEnum>> = React.useMemo(
        () => [
            {
                id: undefined,
                showPriority: 0,
                hidePriority: 0,
                label: t('common:rfqs-page.table.quick-filters.all'),
                testSelector: 'all',
            },
            {
                id: RFQsQuickFilterEnum.open,
                showPriority: 1,
                hidePriority: 1,
                label: t('common:rfqs-page.table.quick-filters.open'),
                testSelector: RFQsQuickFilterEnum.open,
                notificationProps: {
                    count: stats?.countByState[RFQStatusEnum.open],
                    theme: NotificationLabelThemeEnum.charcoal,
                },
            },
            {
                id: RFQsQuickFilterEnum.preExpire,
                showPriority: 2,
                hidePriority: 2,
                label: t('common:rfqs-page.table.quick-filters.pre-expire'),
                testSelector: RFQsQuickFilterEnum.preExpire,
                notificationProps: {
                    count: stats?.countByState[RFQStatusEnum.expireSoon],
                    theme: NotificationLabelThemeEnum.orange,
                },
            },
            {
                id: RFQsQuickFilterEnum.expired,
                showPriority: 3,
                hidePriority: 3,
                label: t('common:rfqs-page.table.quick-filters.expired'),
                testSelector: RFQsQuickFilterEnum.expired,
            },
            {
                id: RFQsQuickFilterEnum.completed,
                showPriority: 4,
                hidePriority: 4,
                label: t('common:rfqs-page.table.quick-filters.completed'),
                testSelector: RFQsQuickFilterEnum.completed,
            },
            {
                id: RFQsQuickFilterEnum.closed,
                showPriority: 5,
                hidePriority: 5,
                label: t('common:rfqs-page.table.quick-filters.closed'),
                testSelector: RFQsQuickFilterEnum.closed,
            },
        ],
        [unReadNotificationsGroupedCounts, stats, t],
    );

    const goToCreateOrderForm = (): void => {
        history.push({
            pathname: OMSRoutesEnum.newOrder,
        });
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(rfqs, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters(
            [queryFilters[QueryFiltersKeysEnum.quickFilter]],
            [omit(queryFilters, [QueryFiltersKeysEnum.quickFilter])],
        );

        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:rfqs-page.table.messages.not-found.title')}
                    description={t('common:rfqs-page.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('common:rfqs-page.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="empty-state-has-secondary-filters"
                />
            );
        }

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('common:rfqs-page.table.messages.empty-quick-filters.title')}
                    description=""
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:rfqs-page.table.messages.empty.title')}
                description={t('common:rfqs-page.table.messages.empty.description')}
                testSelector="empty"
                isShowAction
                actionTitle={t('common:rfqs-page.table.messages.empty.action')}
                actionTheme={ButtonThemeEnum.primary}
                onActionClick={goToCreateOrderForm}
            />
        );
    };

    return (
        <>
            <ContentMargins>
                <ListPageLayout>
                    {quickFilterOptions && (
                        <ListPageHeaderTabsLayout>
                            <QuickFiltersButtonGroups
                                options={quickFilterOptions}
                                revertSeparatorIndexes={quickFilterOptionSeparators}
                                selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                                onSelect={handleSetStatusFilter}
                                moreStatusesLabel={t('common:rfqs-page.table.quick-filters.more-statuses')}
                            />
                        </ListPageHeaderTabsLayout>
                    )}
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('common:rfqs-page.table.filters.trigger')}
                                    isActive={isShowFilters}
                                    onClick={showFilters}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('common:rfqs-page.table.search.placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                        rightToolsNode={
                            <>
                                {!verificationProblems.any && (
                                    <Button
                                        theme={ButtonThemeEnum.primary}
                                        type="button"
                                        onClick={goToCreateOrderForm}
                                        isDisabled={isDisabledCreateOrder}
                                        testSelector="create-new-order"
                                    >
                                        {t('common:rfqs-page.actions.create-rfq')}
                                    </Button>
                                )}
                            </>
                        }
                    />
                    {renderTableMessage()}
                    <RFQsTable
                        rfqs={rfqs}
                        goToRFQDetails={onOpenRFQDetails}
                        goToUserDetails={onOpenUserDetails}
                        isLoading={requestStatus?.loading}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <SideBar isShow={isShowFilters} onClose={hideFilters}>
                {(onClose) => (
                    <FiltersSidebarContent
                        onClose={onClose}
                        queryFilters={queryFilters}
                        setQueryFilters={handleSetQueryFilters}
                    />
                )}
            </SideBar>
        </>
    );
});

export default RFQListPageLayout;
