import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const EmissionIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    fill={strokeColor}
                    d="M11.667 7.5h-2.5a.836.836 0 00-.834.833v3.334c0 .458.375.833.834.833h2.5a.836.836 0 00.833-.833V8.333a.836.836 0 00-.833-.833zm-.417 3.75H9.583v-2.5h1.667v2.5zm-4.583-.417v.834a.836.836 0 01-.834.833h-2.5a.836.836 0 01-.833-.833V8.333c0-.458.375-.833.833-.833h2.5c.459 0 .834.375.834.833v.834h-1.25V8.75H3.75v2.5h1.667v-.417h1.25zm10.416 2.084h-1.666v.833h2.5V15h-3.75v-2.083c0-.459.375-.834.833-.834h1.667v-.833h-2.5V10h2.916c.459 0 .834.375.834.833v1.25a.836.836 0 01-.834.834z"
                />
            </g>
        </svg>
    );
};

EmissionIcon.displayName = 'EmissionIcon';

export const EmissionIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};
const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default EmissionIcon;
