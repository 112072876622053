import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './SignUpForm.scss';

import { useDispatch, useSelector } from 'react-redux';
import { selectSignUpRequestStatus } from 'common/store/auth/selectors';
import { FormValuesT as UserFormDataT } from './UserSignUpForm/constants';
import UserSignUpForm from './UserSignUpForm/UserSignUpForm';
import { FormValuesT as CompanyFormDataT } from './CompanySignUpForm/constants';
import CompanySignUpForm from './CompanySignUpForm/CompanySignUpForm';
import { signUp } from 'common/store/auth/actions';
import { prepareSignUpParams } from './prepare-sign-up-params';
import { useTranslation } from 'react-i18next';
import AuthLayoutMessage, { AuthMessageIconsEnum } from 'common/layouts/AuthLayout/AuthLayoutMessage/AuthLayoutMessage';

const cx = classNames.bind(styles);

enum FormStepEnum {
    user = 'user',
    company = 'company',
}

type PropsT = {
    titleSignUpUser: string;
    titleSignUpCompany: string;
};

const SignUpForm: React.FC<PropsT> = React.memo((props) => {
    const { titleSignUpUser, titleSignUpCompany } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [step, setStep] = React.useState(FormStepEnum.user);
    const [userFormData, setUserFormData] = React.useState<UserFormDataT | null>(null);

    const signUpRequest = useSelector(selectSignUpRequestStatus);

    if (signUpRequest.ok) {
        return (
            <AuthLayoutMessage
                iconType={AuthMessageIconsEnum.success}
                title={t('common:sign-up.success-message.title')}
                description={t('common:sign-up.success-message.description')}
                testSelector="success"
            />
        );
    }

    return (
        <>
            <div className={cx('title')}>
                {step === FormStepEnum.user && titleSignUpUser}
                {step === FormStepEnum.company && titleSignUpCompany}
            </div>
            {step === FormStepEnum.user && (
                <UserSignUpForm
                    loading={signUpRequest.loading}
                    onSubmit={(userFormData: UserFormDataT): void => {
                        setUserFormData(userFormData);
                        setStep(FormStepEnum.company);
                    }}
                />
            )}
            {step === FormStepEnum.company && (
                <CompanySignUpForm
                    loading={signUpRequest.loading}
                    error={signUpRequest.error}
                    onSubmit={(companyFormData: CompanyFormDataT): void => {
                        const params = prepareSignUpParams(userFormData, companyFormData);
                        if (!params) {
                            return;
                        }

                        dispatch(signUp(params));
                    }}
                />
            )}
        </>
    );
});

export default SignUpForm;
