import * as React from 'react';

import cs from 'classnames';

import classNames from 'classnames/bind';
import styles from './ExcludedCountry.scss';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import CloseIcon from 'common/icons/CloseIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';

const cx = classNames.bind(styles);

type PropsT<T extends string> = {
    className?: string;
    countryCode: T;
    countryLabel?: React.ReactNode;
    onClick: (countryCode: T) => void;
};

const ExcludedCountry = <T extends string>(props: PropsT<T>) => {
    const { className, countryCode, countryLabel, onClick } = props;

    return (
        <div className={cs(className, cx('wrap'))}>
            <div className={cx('inner')}>
                <Tooltip
                    tooltipNode={
                        countryLabel ? (
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                {countryLabel}
                            </TooltipContent>
                        ) : null
                    }
                    delay={500}
                    position={TooltipPositionEnum.bottomCenter}
                    theme={TooltipThemeEnum.black}
                >
                    {(isShow, childrenClassName) => (
                        <FlagIcon countryCode={countryCode} className={cs(cx('flag'), childrenClassName)} />
                    )}
                </Tooltip>
                <div
                    className={cx('close')}
                    onClick={() => {
                        onClick(countryCode);
                    }}
                >
                    <CloseIcon fillColor="currentColor" />
                </div>
            </div>
        </div>
    );
};

export default React.memo(ExcludedCountry) as typeof ExcludedCountry;
