export enum QueryFiltersKeysEnum {
    text = 'text',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.text]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    shipperContractsPage = 'shipper-contracts-page',
    shipperContractsSort = 'shipper-contracts-sort',
    shipperContractsFilters = 'shipper-contracts-filters',
}
