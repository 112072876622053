import { InvoicesQuickFilterEnum } from './constants';

export enum QueryKeysEnum {
    invoicesPage = 'invoices-page',
    invoicesSort = 'invoices-sort',
    invoicesFilters = 'invoices-filters',
    invoicesPagingEndingBefore = 'invoices-paging-ending-before',
    invoicesPagingStartingAfter = 'invoices-paging-starting-after',
}

export enum QueryFiltersKeysEnum {
    quickFilter = 'quick-filter',
    searchText = 'search-text',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.quickFilter]: InvoicesQuickFilterEnum;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
}>;
