import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;

const MIN_QUERY_LENGTH = 3;

type QueryT = string;

class GoogleMapsAutocompleteApi {
    private maps: MapsT | null;

    private sessionToken: AutocompleteSessionToken | null;

    constructor(maps: MapsT | null | undefined) {
        this.maps = maps || null;

        if (maps) {
            this.sessionToken = new maps.places.AutocompleteSessionToken();
        } else {
            this.sessionToken = null;
        }
    }

    getQueryPredictions = async (
        query: QueryT | null,
        mapBounds: MapBoundsT,
    ): Promise<[QueryT | null, google.maps.places.QueryAutocompletePrediction[]]> => {
        const { maps, sessionToken } = this;

        if (!maps || !sessionToken) {
            return [query, []];
        }

        if (!query || query.length < MIN_QUERY_LENGTH) {
            return [query, []];
        }

        const bounds = new maps.LatLngBounds(new maps.LatLng(...mapBounds[0]), new maps.LatLng(...mapBounds[1]));

        const request = {
            input: query,
            sessionToken,
            bounds,
        };

        return new Promise((resolve) => {
            // @ts-expect-error
            const autocompleteService = new maps.places.AutocompleteService();
            autocompleteService.getQueryPredictions(
                request,
                (
                    results: google.maps.places.QueryAutocompletePrediction[],
                    status: google.maps.places.PlacesServiceStatus,
                ) => {
                    if (status !== 'OK') {
                        return;
                    }

                    resolve([query, results]);
                },
            );
        });
    };
}

export { GoogleMapsAutocompleteApi };
