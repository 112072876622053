import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import {
    checkEmail,
    checkMaxLength,
    checkMinLength,
    checkNotEmpty,
    checkPhoneNumber,
} from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.firstName,
    FieldsEnum.lastName,
    FieldsEnum.email,
    FieldsEnum.phone,
    FieldsEnum.password,
] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),

        ...checkMinLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 2),
        ...checkMaxLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 255),

        ...checkMinLength(t, [FieldsEnum.password], values, 6),
        ...checkMaxLength(t, [FieldsEnum.password], values, 255),

        ...checkPhoneNumber(t, [FieldsEnum.phone], values),

        ...checkEmail(t, [FieldsEnum.email], values),
    };

    const isAcceptTerms = values[FieldsEnum.acceptTerms];
    if (!isAcceptTerms) {
        errors[FieldsEnum.acceptTerms] = t('common:form-errors.accept-terms');
    }

    return errors;
};

export default validate;
