import { FieldsEnum, FormValuesT } from './constants';
import { RemoteFile } from 'design-system/components/FilePickerInput/models';
import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';
import moment from 'moment/moment';
import { DownloadDataT } from './models';

const getInitialValues = (
    partnerId: PartnerIdT,
    shipperContractDetails: ShipperContractDetailsT | null,
): FormValuesT => {
    return {
        [FieldsEnum.name]: shipperContractDetails?.name || '',
        [FieldsEnum.number]: shipperContractDetails?.number || '',

        [FieldsEnum.activateDate]: shipperContractDetails?.validFrom
            ? moment(shipperContractDetails?.validFrom).toDate()
            : null,
        [FieldsEnum.validTillDate]: shipperContractDetails?.validTill
            ? moment(shipperContractDetails?.validTill).toDate()
            : null,

        [FieldsEnum.file]: new RemoteFile<DownloadDataT>({
            name: shipperContractDetails?.fileName || '',
            downloadData: {
                shipperContractId: shipperContractDetails?.id || '-',
                partnerId,
            },
        }),
    };
};

export default getInitialValues;
