import { RequestStatusT } from 'common/utils/request-status';
import { GPSStatusT } from 'common/store/gps-tracking-status/models';
import { AssetTypeEnum } from 'common/constants';

export const CHECK_GPS_STATUS_REQUEST = 'common/gps-tracking-status/CHECK_GPS_STATUS_REQUEST';
export const CHECK_GPS_STATUS_REQUEST_BEGIN = 'common/gps-tracking-status/CHECK_GPS_STATUS_REQUEST_BEGIN';
export const CHECK_GPS_STATUS_REQUEST_ERROR = 'common/gps-tracking-status/CHECK_GPS_STATUS_REQUEST_ERROR';
export const CHECK_GPS_STATUS_REQUEST_SUCCESS = 'common/gps-tracking-status/CHECK_GPS_STATUS_REQUEST_SUCCESS';
export const RESET = 'common/gps-tracking-status/RESET';

export type GPSTrackingStatusQueryT = {
    plateNumber: PlateNumberT;
    companyId: CompanyIdT;
    assetType: AssetTypeEnum;
};

export type GPSTrackingStatusStateT = {
    query: GPSTrackingStatusQueryT | null;
    requestStatus: RequestStatusT;
    data: GPSStatusT | null;
};

export type CheckGPSStatusRequestActionT = {
    type: typeof CHECK_GPS_STATUS_REQUEST;
    query: GPSTrackingStatusQueryT | null;
};

export type CheckGPSStatusRequestBeginActionT = {
    type: typeof CHECK_GPS_STATUS_REQUEST_BEGIN;
    query: GPSTrackingStatusQueryT;
};

export type CheckGPSStatusRequestErrorActionT = {
    type: typeof CHECK_GPS_STATUS_REQUEST_ERROR;
    error: Error;
    query: GPSTrackingStatusQueryT;
};

export type CheckGPSStatusRequestSuccessActionT = {
    type: typeof CHECK_GPS_STATUS_REQUEST_SUCCESS;
    query: GPSTrackingStatusQueryT;
    data: GPSStatusT;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type GPSTrackingStatusActionT =
    | CheckGPSStatusRequestActionT
    | CheckGPSStatusRequestBeginActionT
    | CheckGPSStatusRequestSuccessActionT
    | CheckGPSStatusRequestErrorActionT
    | ResetActionT;
