import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './FooterSideBarAlertsLayout.scss';
import FooterSideBarAlert from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarAlertsLayout/FooterSideBarAlert/FooterSideBarAlert';
import { useContext } from 'react';
import SideBarFooterAlertsContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-footer-alerts-context';

const cx = classNames.bind(styles);

type PropsT = {};

const FooterSideBarAlertsLayout: React.FC<PropsT> = React.memo((props) => {
    const sideBarFooterAlertsContext = useContext(SideBarFooterAlertsContext);

    if (!sideBarFooterAlertsContext?.alerts?.length) {
        return null;
    }

    return (
        <>
            {sideBarFooterAlertsContext?.alerts?.map((alert) => (
                <FooterSideBarAlert
                    key={alert.id}
                    className={cx('alert')}
                    id={alert.id}
                    theme={alert.theme}
                    onCloseAlert={sideBarFooterAlertsContext?.onCloseAlert}
                >
                    {alert.content}
                </FooterSideBarAlert>
            ))}
        </>
    );
});

export default FooterSideBarAlertsLayout;
