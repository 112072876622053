import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ListPageLayout.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
};

const ListPageLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, className } = props;

    return <div className={cs(cx('content'), className)}>{children}</div>;
});

export default ListPageLayout;
