import React from 'react';

export type SideBarFooterHeightContextT = {
    value: number;
    setValue: (value: number) => void;
};

const useSideBarFooterHeight = (): SideBarFooterHeightContextT => {
    const [value, setValue] = React.useState<number>(0);

    return {
        value,
        setValue,
    };
};

export default useSideBarFooterHeight;
