import isNil from 'lodash/isNil';

export const M_IN_KM = 1000;

export const convertToKm = (meters: number | null | undefined): number | null => {
    if (isNil(meters)) {
        return null;
    }

    return Math.floor(meters / M_IN_KM);
};
