import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CostCell.scss';
import Money from 'common/components/Money/Money';
import isNumber from 'lodash/isNumber';
import { CurrencyEnum } from 'common/constants';

type PropsT = {
    cost: number | null | undefined;
};

const cx = classNames.bind(styles);

const CostCell: React.FC<PropsT> = React.memo((props) => {
    const { cost } = props;

    return <div className={cx('cost')}>{isNumber(cost) && <Money amount={cost} currency={CurrencyEnum.EUR} />}</div>;
});

export default CostCell;
