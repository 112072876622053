import React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipperContractLaneDetailsSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import ShipperContractLaneDetails from './ShipperContractLaneDetails/ShipperContractLaneDetails';
import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import EditIcon from 'common/icons/EditIcon';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { ShipperContractLaneStatusEnum } from 'common/utils/api/models';
import { StyleGuideColorsEnum } from 'common/constants';
import DeleteIcon from 'common/icons/DeleteIcon';
import { ShipperContractLaneDetailsT } from 'common/store/shipper-contract-lane-details/models';
import ShipperContractLaneOtherDetails from '../ShipperContractLaneOtherDetails/ShipperContractLaneOtherDetails';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';

const cx = classNames.bind(styles);

type PropsT = {
    data: ShipperContractLaneDetailsT | null;
    isReadOnly?: boolean;
    onClose?: () => void;
    onGoBack?: () => void;
    onRevoke: () => void;
    onEdit: () => void;
    onOpenUserDetails?: (userId: UserIdT) => void;
};

const ShipperContractLaneDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, isReadOnly, onClose, onGoBack, onRevoke, onEdit, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const options: Array<DropdownControlOptionT | SpecialOptionEnum | null> = React.useMemo(() => {
        if (isReadOnly) {
            return [];
        }

        return [
            data?.status === ShipperContractLaneStatusEnum.active && onEdit
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              label={t('common:shipper-lane-details.actions.edit')}
                              icon={<EditIcon />}
                          />
                      ),
                      onSelect: () => {
                          onEdit();
                      },
                  }
                : null,
            data?.status === ShipperContractLaneStatusEnum.active && onRevoke
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              label={t('common:shipper-lane-details.actions.revoke')}
                              icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          />
                      ),
                      onSelect: () => {
                          onRevoke();
                      },
                  }
                : null,
        ];
    }, [t, onEdit, onRevoke, data, isReadOnly]);

    return (
        <>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={data?.tztLaneId || ''}
                    afterTitleNode={
                        <>
                            <ShipperContractLaneStatusPill className={cx('status')} status={data?.status} />
                            {data?.teamDrive && <TeamDrivePill />}
                        </>
                    }
                    dropdownOptions={options}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                    onGoBack={onGoBack}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <ShipperContractLaneDetails data={data} />
                <ShipperContractLaneOtherDetails data={data} onOpenUserDetails={onOpenUserDetails} />
            </SideBarLayout>
        </>
    );
};
export default ShipperContractLaneDetailsSidebarContent;
