import React from 'react';
import classNames from 'classnames/bind';

import styles from './DriverSidebarHeader.scss';
import { CompanyDriverDetailsT } from 'common/store/drivers/models';

const cx = classNames.bind(styles);

type PropsT = {
    driverDetails: CompanyDriverDetailsT | null;
};

const DriverSidebarHeader: React.FC<PropsT> = (props) => {
    const { driverDetails } = props;

    if (!driverDetails) {
        return null;
    }

    let fullName = [driverDetails?.contact?.name, driverDetails?.contact?.surname].filter(Boolean).join(' ');
    if (!fullName && driverDetails?.contact?.mobilePhone) {
        fullName = driverDetails?.contact?.mobilePhone;
    }

    return <span>{fullName}</span>;
};

export default DriverSidebarHeader;
