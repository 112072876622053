import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    className?: string;
};

const PdfFileIcon: React.FC<PropsT> = (props) => {
    const { fillColor, className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M5.014 11.718c.306 0 .579.046.819.14.24.093.442.226.607.4.166.174.29.384.374.63.084.246.126.522.126.828 0 .306-.042.582-.126.828a1.699 1.699 0 01-.373.63 1.65 1.65 0 01-.608.4c-.24.094-.513.14-.819.14H3.943V18H2.584v-6.282h2.43zm4.887 0c.846 0 1.473.27 1.881.81.408.54.612 1.317.612 2.331s-.204 1.791-.612 2.331c-.408.54-1.035.81-1.881.81H7.876v-6.282h2.025zm7.686 0v1.107H14.77v1.44h2.52v1.107h-2.52V18h-1.35v-6.282h4.167zM9.811 12.78h-.603v4.158h.603c.402 0 .696-.129.882-.387.186-.258.279-.633.279-1.125v-1.143c0-.486-.093-.858-.279-1.116-.186-.258-.48-.387-.882-.387zm-5.139.027h-.729v1.818h.729c.318 0 .538-.06.662-.18.123-.12.184-.318.184-.594v-.27c0-.276-.061-.474-.184-.594-.124-.12-.344-.18-.662-.18zM13.979 2L18 6v4h-2V8h-4V4H4v6H2V2h11.979z"
            />
        </svg>
    );
};

PdfFileIcon.displayName = 'PdfFileIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default PdfFileIcon;
