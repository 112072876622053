import * as React from 'react';
import { TransportOrderStatusEnum, ApiTransportOrderStatusT } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import TransportOrderStatus from 'common/components/status/TransportOrderStatus/TransportOrderStatus';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiTransportOrderStatusT | null | undefined;
};

export const TRANSPORT_ORDER_STATUS_COLOR_MAP: Record<ApiTransportOrderStatusT, StyleGuideColorsEnum> = {
    [TransportOrderStatusEnum.assetsUnassigned]: StyleGuideColorsEnum.tomatoRed,
    [TransportOrderStatusEnum.driverUnassigned]: StyleGuideColorsEnum.tomatoRed,
    [TransportOrderStatusEnum.assigned]: StyleGuideColorsEnum.light,
    [TransportOrderStatusEnum.inTransit]: StyleGuideColorsEnum.light,
    [TransportOrderStatusEnum.done]: StyleGuideColorsEnum.mediumGreen,
    [TransportOrderStatusEnum.canceled]: StyleGuideColorsEnum.light,
    [TransportOrderStatusEnum.declined]: StyleGuideColorsEnum.light,
    [TransportOrderStatusEnum.unknown]: StyleGuideColorsEnum.light,
};

const TransportOrderColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    const color = TRANSPORT_ORDER_STATUS_COLOR_MAP[status];

    return <ColoredStatusLabel {...restProps} label={<TransportOrderStatus status={status} />} color={color} />;
});

export default TransportOrderColoredStatus;
