export type ViewEventHandlerT<T> = (event: T) => void;

export type InferChannelEventT<T extends ViewEventChannel<TODO>> = Parameters<T['emit']>[0];

export class ViewEventChannel<EventT extends {}> {
    private subscribers: Array<ViewEventHandlerT<EventT>> = [];

    subscribe = (handler: ViewEventHandlerT<EventT>) => {
        this.subscribers.push(handler);
    };

    unsubscribe = (handler: ViewEventHandlerT<EventT>) => {
        this.subscribers = this.subscribers.filter((subscriber) => subscriber !== handler);
    };

    emit = (event: EventT): void => {
        this.subscribers.forEach((subscribe) => {
            subscribe(event);
        });
    };
}
