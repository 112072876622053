import { useCallback, useLayoutEffect, useState } from 'react';

export const useIsScrollable = (dependencies: any[]): any => {
    const [node, setNode] = useState<HTMLDivElement>();
    const ref = useCallback((node: HTMLDivElement) => {
        setNode(node);
    }, []);

    const [isScrollable, setIsScrollable] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (!node) return;

        setIsScrollable(node.scrollHeight > node.clientHeight);
    }, [...dependencies, node]);

    useLayoutEffect(() => {
        if (!node) {
            return (): void => {
                // nothing
            };
        }

        const handleWindowResize = () => {
            setIsScrollable(node.scrollHeight > node.clientHeight);
        };

        window.addEventListener('resize', handleWindowResize);

        return (): void => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [node]);

    return [isScrollable, ref, node] as const;
};
