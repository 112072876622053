import React from 'react';

import classNames from 'classnames/bind';
import styles from './Steps.scss';
import times from 'lodash/times';

const cx = classNames.bind(styles);

export type PropsT = {
    minValue: number;
    stepsCount: number;
    step: number;
    shownLabelCount: number;
    shownStepCount: number;
    hasStartStep?: boolean;
    hasEndStep?: boolean;
    filledPositions: number[];
    renderLabel: (value: number) => string | number;
};

const Steps: React.FC<PropsT> = React.memo((props) => {
    const { minValue, stepsCount, step, shownLabelCount, shownStepCount, renderLabel } = props;

    return (
        <>
            {times(stepsCount + 1).map((_, index) => {
                const isShowLabel = index % shownLabelCount === 0;
                const isShowStep = index % shownStepCount === 0;

                // const hasBorder = !((!hasStartStep && index === 0) || (!hasEndStep && index === stepsCount));
                const position = (100 / stepsCount) * index;
                // const isFilledPosition = filledPositions[0] <= position && position <= filledPositions[1];

                return (
                    <div
                        key={index}
                        className={cx('step', {
                            'step--isHideStep': !isShowStep,
                        })}
                        data-test-selector={`step_${index}`}
                        style={{ left: `${position}%` }}
                    >
                        {isShowLabel && (
                            <div
                                className={cx('step__label', {
                                    'step__label--isFirst': index === 0,
                                    'step__label--isLast': index === stepsCount,
                                })}
                            >
                                {renderLabel(minValue + index * step)}
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
});

export default Steps;
