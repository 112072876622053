import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';

export enum FieldsEnum {
    email = 'email',
}

export type FormValuesT = {
    [FieldsEnum.email]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.email]: AuthErrorTypeEnum.userNotFound | AuthErrorTypeEnum.unknown;
}>;
