import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DevLocalizationLanguageSwitcher.scss';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';
import LanguageSwitcher from 'common/components/LanguageSwitcher/LanguageSwitcher';
import { CIMODE, LangEnum } from 'common/constants';
import { changeLanguage } from 'common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const DevLocalizationLanguageSwitcher: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const { i18n } = useTranslation();

    const [lang, setLang] = React.useState<LangEnum | typeof CIMODE>(i18n.language as LangEnum);

    const featuresContext = React.useContext(FeaturesContext);
    if (!featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.i18nLanguageSwitcher)) {
        return null;
    }

    return (
        <div className={className}>
            <LanguageSwitcher
                lang={lang}
                isShowCIMCode
                overlayPosition={DropdownOverlayPositionEnum.topRight}
                onChange={(language) => {
                    if (!language) {
                        return;
                    }

                    changeLanguage(language);
                    setLang(language);
                }}
            />
        </div>
    );
});

export default DevLocalizationLanguageSwitcher;
