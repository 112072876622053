import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentsTable, {
    TableColumnModsEnum,
    TableColumnT,
    TableRowMetaT,
    TableRowModsEnum,
    TableRowThemeEnum,
} from 'common/components/DocumentsTable/DocumentsTable';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import classNames from 'classnames/bind';
import styles from './BaseCarrierContractsTable.scss';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import { ContractsActionsT } from '../models';
import { ApiCarrierContractStatusT } from 'common/utils/api/models';
import ActionsCell from './cell-renderers/ActionsCell/ActionsCell';
import { ContractRowT } from './models';
import ExpireDateCell from './cell-renderers/ExpireDateCell/ExpireDateCell';
import ContractNameCell from 'common/layouts/BaseCarrierContractsLayout/BaseCarrierContractsTable/cell-renderers/ContractNameCell/ContractNameCell';
import CarrierContractStatusPill from 'common/components/status-pill/CarrierContractStatusPill/CarrierContractStatusPill';

type PropsT = {
    className?: string;
    tableName: string;
    rows: Array<ContractRowT>;
    onSelectRow?: (row: ContractRowT) => void;
    isLoading: boolean;
    testSelector?: string;
    headerNode?: ReactNode;
    footerNode?: ReactNode;
    onOpenUserDetails: (userId: UserIdT) => void;
    contactStatusesAttentionSet?: Set<ApiCarrierContractStatusT | null>;
} & ContractsActionsT;

const cx = classNames.bind(styles);

const BaseCarrierContractsTable: React.FC<PropsT> = React.memo((props) => {
    const {
        tableName,
        rows,
        isLoading,
        onOpenUserDetails,
        testSelector,
        className,
        headerNode,
        footerNode,
        onSelectRow,
        onDownloadContract,
        onEditContractDetails,
        onRevokeContract,
        contactStatusesAttentionSet,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ContractRowT, void>> = [
        {
            renderHeader: () => <span>{t('common:carrier-contracts.table.columns.name')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (row: ContractRowT) => <ContractNameCell name={row?.name || ''} />,
            className: cx('table__column', 'table__column--name'),
            testSelector: 'name',
        },
        {
            renderHeader: () => <span>{t('common:carrier-contracts.table.columns.uploaded')}</span>,
            headerClassName: cx('table__header', 'table__header--uploaded'),
            render: (row: ContractRowT) => {
                return (
                    <EventCell
                        date={row.createdDate}
                        userId={row.createdById}
                        userFullName={row.createdByName}
                        byBroker={row.createdByBroker}
                        openUserDetails={onOpenUserDetails}
                    />
                );
            },
            className: cx('table__column', 'table__column--uploaded'),
            testSelector: 'upload',
        },
        {
            renderHeader: () => <span>{t('common:carrier-contracts.table.columns.reviewed')}</span>,
            headerClassName: cx('table__header', 'table__header--reviewed'),
            render: (row: ContractRowT) => {
                return (
                    <EventCell
                        date={row.reviewedDate}
                        userId={row.reviewedById}
                        userFullName={row.reviewedByName}
                        byBroker
                        openUserDetails={onOpenUserDetails}
                    />
                );
            },
            className: cx('table__column', 'table__column--reviewed'),
            testSelector: 'review',
        },
        {
            renderHeader: () => <span>{t('common:carrier-contracts.table.columns.expire-date')}</span>,
            headerClassName: cx('table__header', 'table__header--expire-date'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: ContractRowT) => {
                return <ExpireDateCell row={row} onSelectRow={onSelectRow} />;
            },
            className: cx('table__column', 'table__column--expire-date'),
            testSelector: 'valid-till',
        },
        {
            renderHeader: () => <span>{t('common:carrier-contracts.table.columns.status')}</span>,
            headerClassName: cx('table__header', 'table__header--status'),
            render: (row: ContractRowT) => {
                return <CarrierContractStatusPill isSymmetrical status={row.status || null} />;
            },
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
        {
            renderHeader: () => null,
            headerClassName: cx('table__header', 'table__header--actions'),
            render: (row: ContractRowT) => {
                return (
                    <ActionsCell
                        row={row}
                        onEditContractDetails={onEditContractDetails}
                        onRevokeContract={onRevokeContract}
                        onDownloadContract={onDownloadContract}
                    />
                );
            },
            className: cx('table__column', 'table__column--actions'),
            testSelector: 'actions',
        },
    ];

    const getRowMods = (meta: TableRowMetaT, row: ContractRowT) => {
        return {
            [TableRowModsEnum.hasLeftOrangeBorder]: row?.status && contactStatusesAttentionSet?.has(row.status),
        };
    };

    const getRowTheme = (): TableRowThemeEnum => {
        return TableRowThemeEnum.normal;
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ContractRowT> tableName={tableName} isLoading={isLoading} rows={rows}>
                {(rows, isUsedPrevRows) => (
                    <DocumentsTable<ContractRowT, void>
                        testSelector={testSelector}
                        className={className}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        getRowMods={getRowMods}
                        getRowTheme={getRowTheme}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                        headerNode={headerNode}
                        footerNode={footerNode}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default BaseCarrierContractsTable;
