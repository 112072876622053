import * as React from 'react';

import { useDispatch } from 'react-redux';

import { fetchUser } from 'common/store/user/actions';

type PropsT = {};

const User: React.FC<PropsT> = React.memo((props) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchUser());
    }, []);

    return null;
});

export default User;
