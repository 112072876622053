import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AlertsDebugForm.scss';
import { useDispatch } from 'react-redux';
import { addAlert } from 'common/store/alerts/actions';
import { AnyAlert } from 'common/store/alerts/models';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const AlertsDebugForm: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const dispatch = useDispatch();

    const defaultAlert = React.useMemo((): string => {
        return JSON.stringify({}, null, 4);
    }, []);

    const [type, setType] = React.useState<string>('type');
    const [text, setText] = React.useState<string>(defaultAlert);

    const featuresContext = React.useContext(FeaturesContext);
    if (!featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.debugAlertForm)) {
        return null;
    }

    return (
        <div className={className} style={{ marginBottom: '20px' }}>
            <button
                onClick={() => {
                    const data = JSON.parse(text);

                    const alert = new AnyAlert({ type, data });

                    dispatch(addAlert(alert));
                }}
            >
                send test alert
            </button>
            <br />
            <br />
            <div>
                <input
                    value={type}
                    onChange={(event) => {
                        setType(event.target.value);
                    }}
                />
                <br />
                <textarea
                    style={{
                        width: '100%',
                        height: '400px',
                    }}
                    value={text}
                    onChange={(event) => {
                        setText(event.target.value);
                    }}
                />
            </div>
        </div>
    );
});

export default AlertsDebugForm;
