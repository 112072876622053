import * as React from 'react';
import Link, { LinkPropsT, LinkThemeEnum } from 'common/components/Link/Link';

type PropsT = Omit<LinkPropsT, 'theme'>;

const LinkFormatter: React.FC<PropsT> = React.memo((props) => {
    const { children, ...rest } = props;

    return (
        <Link {...rest} theme={LinkThemeEnum.boldBrandDark}>
            {children}
        </Link>
    );
});

export default LinkFormatter;
