import React from 'react';

import classNames from 'classnames/bind';
import styles from './UserDropdownOptionLabel.scss';
import Avatar from 'common/components/Avatar/Avatar';

const cx = classNames.bind(styles);

type PropsT = {
    label: string;
    isPlaceholder?: boolean;
};

const UserDropdownOptionLabel: React.FC<PropsT> = (props) => {
    const { label, isPlaceholder } = props;

    return (
        <div className={cx('option')}>
            <Avatar className={cx('avatar')} hash={isPlaceholder ? null : label} />
            <div className={cx('label')}>{label}</div>
        </div>
    );
};

export default UserDropdownOptionLabel;
