import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const DeleteIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M5.5 15.25c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-9h-9v9zM15.25 4h-2.625l-.75-.75h-3.75l-.75.75H4.75v1.5h10.5V4z"
                />
            </g>
        </svg>
    );
};

DeleteIcon.displayName = 'DeleteIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default DeleteIcon;
