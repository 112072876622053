import {
    FETCH_ADDITIONAL_SERVICES,
    FETCH_ADDITIONAL_SERVICES_BEGIN,
    FETCH_ADDITIONAL_SERVICES_ERROR,
    FETCH_ADDITIONAL_SERVICES_SUCCESS,
    FetchAdditionalServicesActionT,
    FetchAdditionalServicesBeginActionT,
    FetchAdditionalServicesErrorActionT,
    FetchAdditionalServicesSuccessActionT,
} from './types';
import { ApiDictAdditionalServiceT } from 'common/utils/api/models';

export const fetchAdditionalServices = (): FetchAdditionalServicesActionT => ({
    type: FETCH_ADDITIONAL_SERVICES,
});

export const fetchAdditionalServicesBegin = (): FetchAdditionalServicesBeginActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_BEGIN,
});

export const fetchAdditionalServicesSuccess = (
    additionalServices: Record<ApiDictAdditionalServiceT['type'], ApiDictAdditionalServiceT[]> | null,
): FetchAdditionalServicesSuccessActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_SUCCESS,
    additionalServices,
});

export const fetchAdditionalServicesError = (error: Error): FetchAdditionalServicesErrorActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_ERROR,
    error,
});
