import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import styles from './HeaderSideBarPortal.scss';
import SidedBarHeaderLayoutContext from '../contexts/side-bar-header-layout-context';

const cx = classNames.bind(styles);

type PropsT = {};

const HeaderSideBarPortal: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;

    const contentRootRef = React.useContext(SidedBarHeaderLayoutContext);
    if (!contentRootRef?.current) {
        return null;
    }

    return createPortal(children, contentRootRef.current);
});

export default HeaderSideBarPortal;
