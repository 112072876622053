import { RemoteFile } from 'design-system/components/FilePickerInput/models';

export const MIN_VALID_FROM_VALID_TILL_DAYS = 7;

export enum FieldsEnum {
    name = 'name',
    number = 'number',

    activateDate = 'activate-date',
    validTillDate = 'valid-till-date',

    file = 'file',
}

export type FormValuesT = {
    [FieldsEnum.name]: string;
    [FieldsEnum.number]: string;

    [FieldsEnum.activateDate]: Date | null;
    [FieldsEnum.validTillDate]: Date | null;

    [FieldsEnum.file]: File | RemoteFile<{}> | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
