import { useState } from 'react';
import { StepContextT } from '../contexts/steps-context';

export const useStepsContextValue = (): StepContextT => {
    const [hasSelectedContact, setHasSelectedContract] = useState<boolean>(false);

    return {
        hasSelectedContact,
        setHasSelectedContract,
    };
};
