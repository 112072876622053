import { ApiShipperContractBrokerT } from 'common/utils/api/models';
import { ShipperContractT } from 'common/store/shipper-contracts/models';

export const checkIsApiBrokerShipperContract = (
    details: ShipperContractT | null,
): details is ApiShipperContractBrokerT => {
    if (!details) {
        return false;
    }

    return 'createdById' in details;
};
