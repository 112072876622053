import {
    DOCUMENTS_DICT_REQUEST,
    DOCUMENTS_DICT_REQUEST_BEGIN,
    DOCUMENTS_DICT_REQUEST_ERROR,
    DOCUMENTS_DICT_REQUEST_SUCCESS,
    FetchDocumentsDictActionT,
    FetchDocumentsDictBeginActionT,
    FetchDocumentsDictErrorActionT,
    FetchDocumentsDictSuccessActionT,
} from './types';
import { DictDocumentT } from './models';
import { PartnerTypeT } from 'common/utils/api/models';

export const fetchDocumentsDict = (partnerType: PartnerTypeT | null): FetchDocumentsDictActionT => ({
    type: DOCUMENTS_DICT_REQUEST,
    partnerType,
});

export const fetchDocumentsDictBegin = (partnerType: PartnerTypeT): FetchDocumentsDictBeginActionT => ({
    type: DOCUMENTS_DICT_REQUEST_BEGIN,
    partnerType,
});

export const fetchDocumentsDictSuccess = (
    partnerType: PartnerTypeT,
    documentsList: DictDocumentT[],
): FetchDocumentsDictSuccessActionT => ({
    type: DOCUMENTS_DICT_REQUEST_SUCCESS,
    partnerType,
    documentsList,
});

export const fetchDocumentsDictError = (partnerType: PartnerTypeT, error: Error): FetchDocumentsDictErrorActionT => ({
    type: DOCUMENTS_DICT_REQUEST_ERROR,
    partnerType,
    error,
});
