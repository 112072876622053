import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './InvoicesListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import QuickFilters, { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { urlFactory as shipperUrlFactory } from 'shipper/utils/urls';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { StringParam, useQueryParams } from 'use-query-params';
import { LinkToT } from 'common/components/Link/Link';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { InvoicesQuickFilterEnum } from './constants';
import InvoicesTable from './InvoicesTable/InvoicesTable';
import {
    selectInvoicesByIds,
    selectInvoicesPages,
    selectInvoicesPagingState,
    selectInvoicesTotal,
} from 'shipper/store/invoices/selectors';
import { ShipperInvoiceT } from 'shipper/store/invoices/models';
import { fetchInvoicesPage } from 'shipper/store/invoices/actions';
import { invoicesPaginationChannel, invoicesRefreshChannel } from 'shipper/store/invoices/channels';
import { ShipperInvoicesSortEnum } from 'shipper/utils/api/shipper-tranziit/models';
import DummyPagination from 'common/components/Table/DummyPagination/DummyPagination';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import omit from 'lodash/omit';
import { ButtonThemeEnum } from 'common/components/Button/Button';

const cx = classNames.bind(styles);

type PropsT = {};

const InvoicesListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const total = useSelector(selectInvoicesTotal);
    const pages = useSelector(selectInvoicesPages);
    const byId = useSelector(selectInvoicesByIds);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.invoicesFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.invoicesPage]: createPageNumberParam(),
        [QueryKeysEnum.invoicesSort]: createSortParams({
            value: ShipperInvoicesSortEnum.createDate,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.invoicesPagingEndingBefore]: StringParam,
        [QueryKeysEnum.invoicesPagingStartingAfter]: StringParam,
    });

    const pagingState = useSelector(selectInvoicesPagingState);

    const invoicesPagingEndingBefore = query[QueryKeysEnum.invoicesPagingEndingBefore];
    const invoicesPagingStartingAfter = query[QueryKeysEnum.invoicesPagingStartingAfter];

    const pageNumber = query[QueryKeysEnum.invoicesPage];
    const queryFilters = query[QueryKeysEnum.invoicesFilters];
    const selectedSort = query[QueryKeysEnum.invoicesSort];

    const page = pages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, {
            startingAfter: invoicesPagingStartingAfter,
            endingBefore: invoicesPagingEndingBefore,
        });
        dispatch(fetchInvoicesPage(pageNumber, query, { isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters, invoicesPagingStartingAfter, invoicesPagingEndingBefore]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, {
            startingAfter: invoicesPagingStartingAfter,
            endingBefore: invoicesPagingEndingBefore,
        });
        dispatch(fetchInvoicesPage(pageNumber, query, { isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters, invoicesPagingStartingAfter, invoicesPagingEndingBefore]);
    useChannelSubscribe(invoicesRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort, {
            startingAfter: invoicesPagingStartingAfter,
            endingBefore: invoicesPagingEndingBefore,
        });
        dispatch(fetchInvoicesPage(pageNumber, query));

        return (): void => {
            // TODO reset
        };
    }, [page, pageNumber, selectedSort, queryFilters, invoicesPagingStartingAfter, invoicesPagingEndingBefore]);

    const invoices = React.useMemo(() => {
        return (ids || []).map((id): ShipperInvoiceT => byId[id]);
    }, [ids, byId]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.invoicesPage]: pageNumber,
            });
        },
        [changeQuery],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof invoicesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(invoicesPaginationChannel, setPageHandler);

    const getOrderDetailsLink = (orderId: OrderIdT): LinkToT => {
        return shipperUrlFactory.orderDetails(orderId);
    };

    const invoicesQuickFilterOptions: Array<QuickFiltersOptionT<InvoicesQuickFilterEnum>> = [
        {
            id: undefined,
            showPriority: 0,
            hidePriority: 0,
            label: t('invoices.list.status-filter.all'),
            testSelector: 'all',
        },
        {
            id: InvoicesQuickFilterEnum.open,
            showPriority: 1,
            hidePriority: 1,
            label: t('invoices.list.status-filter.open'),
            testSelector: InvoicesQuickFilterEnum.open,
            notificationProps: {
                count: 0,
                theme: colorCodingNotificationLabelTheme.normal,
            },
        },
        {
            id: InvoicesQuickFilterEnum.void,
            showPriority: 2,
            hidePriority: 2,
            label: t('invoices.list.status-filter.void'),
            testSelector: InvoicesQuickFilterEnum.void,
            notificationProps: {
                count: 0,
                theme: colorCodingNotificationLabelTheme.attention,
            },
        },
        {
            id: InvoicesQuickFilterEnum.paid,
            showPriority: 3,
            hidePriority: 3,
            label: t('invoices.list.status-filter.paid'),
            testSelector: InvoicesQuickFilterEnum.paid,
            notificationProps: {
                count: 0,
                theme: colorCodingNotificationLabelTheme.success,
            },
        },
        {
            id: InvoicesQuickFilterEnum.uncollectible,
            showPriority: 4,
            hidePriority: 4,
            label: t('invoices.list.status-filter.uncollectible'),
            testSelector: InvoicesQuickFilterEnum.uncollectible,
            notificationProps: {
                count: 0,
                theme: colorCodingNotificationLabelTheme.warning,
            },
        },
    ];

    const sortOptions: Array<SortDropdownOptionT<ShipperInvoicesSortEnum>> = React.useMemo(
        () => [
            {
                label: t('invoices.list.table.sorts.triggers.created'),
                value: ShipperInvoicesSortEnum.createDate,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<ShipperInvoicesSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.invoicesPage]: 0,
            [QueryKeysEnum.invoicesPagingStartingAfter]: undefined,
            [QueryKeysEnum.invoicesPagingEndingBefore]: undefined,
            [QueryKeysEnum.invoicesSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: InvoicesQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.invoicesFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
            [QueryFiltersKeysEnum.searchText]: undefined, // Fix: reset quick filter - stripe logic
        };

        changeQuery({
            [QueryKeysEnum.invoicesPage]: 0,
            [QueryKeysEnum.invoicesPagingStartingAfter]: undefined,
            [QueryKeysEnum.invoicesPagingEndingBefore]: undefined,
            [QueryKeysEnum.invoicesFilters]: queryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.invoicesFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.invoicesPage]: 0,
            [QueryKeysEnum.invoicesPagingStartingAfter]: undefined,
            [QueryKeysEnum.invoicesPagingEndingBefore]: undefined,
            [QueryKeysEnum.invoicesFilters]: queryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.invoicesFilters] || {};

        const queryFilters = {
            [QueryFiltersKeysEnum.quickFilter]: prevQueryFilters[QueryFiltersKeysEnum.quickFilter],
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.invoicesPage]: 0,
            [QueryKeysEnum.invoicesPagingStartingAfter]: undefined,
            [QueryKeysEnum.invoicesPagingEndingBefore]: undefined,
            [QueryKeysEnum.invoicesFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.quickFilter]: undefined, // Fix: reset quick filter - stripe logic
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const goToPrevPage = () => {
        if (!pagingState?.endingBefore) {
            return;
        }

        changeQuery({
            [QueryKeysEnum.invoicesPagingStartingAfter]: undefined,
            [QueryKeysEnum.invoicesPagingEndingBefore]: pagingState.endingBefore,
        });
    };

    const goToNextPage = () => {
        if (!pagingState?.startingAfter) {
            return;
        }

        changeQuery({
            [QueryKeysEnum.invoicesPagingStartingAfter]: pagingState.startingAfter,
            [QueryKeysEnum.invoicesPagingEndingBefore]: undefined,
        });
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(invoices, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters(
            [queryFilters[QueryFiltersKeysEnum.quickFilter]],
            [omit(queryFilters, [QueryFiltersKeysEnum.quickFilter])],
        );

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('invoices.list.table.messages.empty-state.title')}
                    testSelector="empty-state"
                    isShowAction={false}
                />
            );
        }

        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('invoices.list.table.messages.not-found.title')}
                    description={t('invoices.list.table.messages.not-found.description')}
                    testSelector="not-found"
                    isShowAction
                    actionTitle={t('invoices.list.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('invoices.list.table.messages.empty.title')}
                description={t('invoices.list.table.messages.empty.description')}
                testSelector="empty"
                isShowAction={false}
            />
        );
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.invoices')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('invoices.list.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <QuickFilters
                            options={invoicesQuickFilterOptions}
                            selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                            onSelect={handleSetStatusFilter}
                            moreStatusesLabel={t('invoices.list.status-filter.more-statuses')}
                        />
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                {false && (
                                    <SortDropdown
                                        className={cx('sort-trigger')}
                                        overlayPosition={SortDropdownOverlayPositionEnum.left}
                                        selectedValue={selectedSort}
                                        options={sortOptions}
                                        onSelect={handleSelectSort}
                                    />
                                )}
                                <SearchControl
                                    placeholder={t('invoices.list.search-placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                    />
                    {renderTableMessage()}
                    <InvoicesTable
                        invoices={invoices}
                        className={cx('table')}
                        isLoading={requestStatus?.loading}
                        getOrderDetailsLink={getOrderDetailsLink}
                    />
                </ListPageLayout>
                <StickyFooter>
                    <DummyPagination
                        isDisabledPrevPage={!pagingState?.endingBefore}
                        goToPrevPage={goToPrevPage}
                        isDisabledNextPage={!pagingState?.startingAfter || !pagingState?.hasMore}
                        goToNextPage={goToNextPage}
                    />
                </StickyFooter>
                <SideBars />
            </ContentMargins>
        </ScrollableContent>
    );
});

export default InvoicesListPage;
