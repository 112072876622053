import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneDetailsMap.scss';
import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import DestinationPinIcon from 'common/icons/DestinationPinIcon';
import OriginPinIcon from 'common/icons/OriginPinIcon';
import GoogleMapContext from 'common/contexts/google-map-context';
import MapArea, { MapAreaThemeEnum } from 'common/components/maps/MapArea/MapArea';
import { M_IN_KM } from 'common/utils/distance';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';

const cx = classNames.bind(styles);

const MAP_PIN_KEYS = {
    origin: 'origin',
    destination: 'destination',
};

export type ShipperContractLaneAddressT = {
    point: GeoPointT | null;
    radiusKm: number | null;
};

type PropsT = {
    originAddress: ShipperContractLaneAddressT | null;
    destinationAddress: ShipperContractLaneAddressT | null;
};

const ShipperContractLaneDetailsMap: React.FC<PropsT> = React.memo((props) => {
    const { originAddress, destinationAddress } = props;

    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const destinationLatLngProps = useMemo(() => {
        let latLngProps: GeoPointT | null = null;

        if (!destinationAddress?.point) {
            return latLngProps;
        }

        latLngProps = {
            lat: destinationAddress.point.lat,
            lng: destinationAddress.point.lng,
        };

        return latLngProps;
    }, [destinationAddress]);

    const originLatLngProps = useMemo(() => {
        let latLngProps: GeoPointT | null = null;

        if (!originAddress?.point) {
            return latLngProps;
        }

        latLngProps = {
            lat: originAddress.point.lat,
            lng: originAddress.point.lng,
        };

        return latLngProps;
    }, [originAddress]);

    const allMapPoints = React.useMemo((): Array<BoundPointT> => {
        return [
            [originLatLngProps?.lat, originLatLngProps?.lng],
            [destinationLatLngProps?.lat, destinationLatLngProps?.lng],
        ];
    }, [destinationLatLngProps, originLatLngProps]);

    return (
        <div className={cx('map')}>
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {destinationLatLngProps && (
                    <DestinationPinIcon
                        key={MAP_PIN_KEYS.destination}
                        className={cx('marker')}
                        {...destinationLatLngProps}
                    />
                )}

                {originLatLngProps && (
                    <OriginPinIcon key={MAP_PIN_KEYS.origin} className={cx('marker')} {...originLatLngProps} />
                )}
            </GoogleMapReact>
            {!!destinationAddress?.radiusKm && destinationLatLngProps && (
                <MapArea
                    key={`${MAP_PIN_KEYS.destination}-area`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    theme={MapAreaThemeEnum.destinationArea}
                    centerLat={destinationLatLngProps.lat}
                    centerLon={destinationLatLngProps.lng}
                    radiusM={destinationAddress.radiusKm * M_IN_KM}
                />
            )}
            {!!originAddress?.radiusKm && originLatLngProps && (
                <MapArea
                    key={`${MAP_PIN_KEYS.origin}-area`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    theme={MapAreaThemeEnum.originArea}
                    centerLat={originLatLngProps.lat}
                    centerLon={originLatLngProps.lng}
                    radiusM={originAddress.radiusKm * M_IN_KM}
                />
            )}
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={allMapPoints}
            />
        </div>
    );
});

export default ShipperContractLaneDetailsMap;
