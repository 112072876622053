import { SharableStateT } from '../models';
import {
    initialPartnerShipperContractsState,
    PartnerShipperContractStateT,
} from 'common/store/shipper-contracts/slice';

const selectCompanyState = (state: SharableStateT, partnerId: PartnerIdT): PartnerShipperContractStateT => {
    return state.shipperContracts[partnerId] || initialPartnerShipperContractsState;
};

export const selectShipperContractsPages = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): PartnerShipperContractStateT['pages'] => {
        return selectCompanyState(state, partnerId).pages;
    };
};

export const selectShipperContractsQuery = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): PartnerShipperContractStateT['query'] => selectCompanyState(state, partnerId).query;
};

export const selectShipperContractsTotal = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): PartnerShipperContractStateT['total'] => selectCompanyState(state, partnerId).total;
};

export const selectShipperContractsByIds = (partnerId: PartnerIdT) => {
    return (state: SharableStateT): PartnerShipperContractStateT['byId'] => selectCompanyState(state, partnerId).byId;
};
