import * as React from 'react';

import RadioGroup, { RadioGroupOptionT } from 'common/components/RadioGroup/RadioGroup';
import { GPS_TRACKED_T, GPSTrackedValuesEnum } from './constants';
import { useTranslation } from 'react-i18next';

type ValueT = GPSTrackedValuesEnum | null;

type PropsT = {
    value: ValueT;
    onChange: (value: ValueT) => void;
    className?: string;
};

const GPSTrackerRadioGroup: React.FC<PropsT> = React.memo((props) => {
    const { className, value, onChange } = props;

    const { t } = useTranslation();

    const gpsOptions = React.useMemo(
        (): RadioGroupOptionT<GPSTrackedValuesEnum | null>[] => [
            {
                label: t('common:trailers-page.filters.all'),
                value: null,
            },
            {
                label: t(GPS_TRACKED_T[GPSTrackedValuesEnum.traked]),
                value: GPSTrackedValuesEnum.traked,
            },
            {
                label: t(GPS_TRACKED_T[GPSTrackedValuesEnum.notTracked]),
                value: GPSTrackedValuesEnum.notTracked,
            },
        ],
        [t],
    );

    return <RadioGroup className={className} selectedValue={value} options={gpsOptions} onChange={onChange} />;
});

export default GPSTrackerRadioGroup;
