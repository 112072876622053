import React from 'react';

import classNames from 'classnames/bind';
import styles from './LegalFormDropdown.scss';
import { useTranslation } from 'react-i18next';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import { LegalFormT } from 'common/store/legal-forms-dict/models';
import {
    selectLegalFormsDictAllIds,
    selectLegalFormsDictById,
    selectLegalFormsDictRequest,
} from 'common/store/legal-forms-dict/selectors';
import { fetchLegalFormsDict } from 'common/store/legal-forms-dict/actions';
import usePrevious from 'common/utils/hooks/usePrevious';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { CountryCodeT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type LegalFormIdT = LegalFormT['id'] | null;
type ValueT = LegalFormIdT | null;
type OptionT = LegalFormIdT | null;

type PropsT = {
    countryCode: CountryCodeT | undefined | null;
    onChange: (legalFormId: LegalFormIdT | null) => void;
    value?: LegalFormIdT;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    isDisabled?: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    placeholder?: string;
};

const LegalFormDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        onChange,
        value,
        countryCode,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        isDisabled,
        hasChanges,
        onBlur,
        onFocus,
        placeholder,
    } = props;

    const prevCountryCode = usePrevious<PropsT['countryCode']>(countryCode);

    const { t } = useTranslation();

    const allIds = useSelector(selectLegalFormsDictAllIds(countryCode || null));
    const byId = useSelector(selectLegalFormsDictById(countryCode || null));
    const fetchRequest = useSelector(selectLegalFormsDictRequest(countryCode || null));

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (countryCode) {
            dispatch(fetchLegalFormsDict(countryCode));
        }
    }, [prevCountryCode, countryCode]);

    const renderOption = (id: ValueT): React.ReactElement | null => {
        if (!id) {
            return null;
        }

        const legalForm = byId[id];

        return <span>{legalForm?.name || id || ''}</span>;
    };

    const getOptionValue = (option: OptionT): ValueT => option;

    const handleOptionClick = (id: ValueT) => {
        onChange(id);
    };

    return (
        <DropdownInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            testSelector="legal-form"
            placeholder={placeholder || t('common:legal-form-selector.placeholder')}
            options={allIds}
            onSelect={handleOptionClick}
            isDisabled={fetchRequest.loading || isDisabled}
            hasError={hasError}
            hasWarning={hasWarning}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
});

export default LegalFormDropdown;
