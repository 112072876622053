import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RFQsPage.scss';
import { Route, Switch } from 'react-router-dom';
import RFQsListPage from 'shipper/layouts/RFQsPage/RFQsListPage/RFQsListPage';
import RFQDetailsPage from 'shipper/layouts/RFQsPage/RFQDetailsPage/RFQDetailsPage';

import { RFQRoutesEnum } from 'common/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

type PropsT = {};

const RFQsPage: React.FC<PropsT> = React.memo(() => {
    return (
        <Switch>
            <Route path={RFQRoutesEnum.details}>
                <RFQDetailsPage />
            </Route>
            <Route path="*">
                <RFQsListPage />
            </Route>
        </Switch>
    );
});

export default RFQsPage;
