import { ApiAdditionalServiceEnum, ApiDictAdditionalServiceT, TruckTypesEnum } from 'common/utils/api/models';
import { PriceOfferT } from 'common/store/order-creation/models';
import { ADDITIONAL_SERVICES_ID_MAP } from 'common/constants';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';

export const checkAvailableService = (
    service: ApiDictAdditionalServiceT | undefined,
    offer: PriceOfferT | undefined,
) => {
    if (!service || !offer) {
        return true;
    }

    const isAvailableForOffer =
        service.type !== ApiAdditionalServiceEnum.emissionStandard ||
        (offer.isGreenTruck && service.truckType === TruckTypesEnum.green) ||
        (!offer.isGreenTruck && service.truckType === TruckTypesEnum.regular);

    return isAvailableForOffer;
};

export const summaryAdditionalServicesPrice = (
    serviceIds: DictAdditionalServiceT['id'][],
    additionalServicesById: Record<DictAdditionalServiceT['id'], DictAdditionalServiceT> | null,
    offer: PriceOfferT | undefined,
): number => {
    return serviceIds.reduce((summary, serviceId) => {
        const service = additionalServicesById?.[serviceId];

        if (!service) {
            return summary;
        }

        const isAvailableForOffer = checkAvailableService(service, offer);
        if (!isAvailableForOffer) {
            return summary;
        }

        return summary + (service?.cost || 0);
    }, 0);
};

export const getFakeAdditionalServicesPrice = (
    serviceIds: DictAdditionalServiceT['id'][],
    offer: PriceOfferT | undefined,
): number => {
    return serviceIds.reduce((summary, serviceId) => {
        let cost = 0;

        if (serviceId === ADDITIONAL_SERVICES_ID_MAP.EURO6) {
            cost = offer?.priceComponents?.green || 0;
        }

        return summary + cost;
    }, 0);
};
