import { RequestStatusT } from 'common/utils/request-status';
import { SharableDocumentsStateT } from 'common/store/models';

import { CompanyDocumentsStateT } from './types';
import { initialCompanyDocumentsState } from './reducer';
import { EvidenceOfTaxDetailsT } from 'common/store/documents/models';

const selectDocumentsState = (companyId: CompanyIdT, state: SharableDocumentsStateT): CompanyDocumentsStateT => {
    return state.documents[companyId] || initialCompanyDocumentsState;
};

export const selectCompanyDocumentIdsByType =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): CompanyDocumentsStateT['idsByType'] =>
        selectDocumentsState(companyId, state).idsByType || ({} as CompanyDocumentsStateT['idsByType']);

export const selectCompanyDocumentsById =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): CompanyDocumentsStateT['byId'] =>
        selectDocumentsState(companyId, state).byId || {};

export const selectFetchCompanyDocumentsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): RequestStatusT =>
        selectDocumentsState(companyId, state).fetchDocumentsRequest;

export const selectUploadCompanyDocumentsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): RequestStatusT =>
        selectDocumentsState(companyId, state).uploadRequest;

export const selectUploadingDictDocumentId =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): DictDocumentIdT | null =>
        selectDocumentsState(companyId, state).uploadingDictDocumentId;

export const selectUpdateCompanyDocumentsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): RequestStatusT =>
        selectDocumentsState(companyId, state).updateRequest;

export const selectUpdatingDocumentId =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): DictDocumentIdT | null =>
        selectDocumentsState(companyId, state).updatingDocumentId;

export const selectFetchEvidenceOfTaxDetailsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): RequestStatusT =>
        selectDocumentsState(companyId, state).fetchEvidenceOfTaxDetailsRequest;

export const selectFetchEvidenceOfTaxDetails =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): EvidenceOfTaxDetailsT | null =>
        selectDocumentsState(companyId, state).evidenceOfTaxDetails;

export const selectUpdateEvidenceOfTaxDetailsRequest =
    (companyId: CompanyIdT) =>
    (state: SharableDocumentsStateT): RequestStatusT =>
        selectDocumentsState(companyId, state).updateEvidenceOfTaxDetailsRequest;
