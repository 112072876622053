import { RFQsQuickFilterEnum } from 'common/utils/api/models';

export enum QueryFiltersKeysEnum {
    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',
    dictTrailerId = 'dictTrailerId',
    pickupDateFrom = 'pickupDateFrom',
    pickupDateTo = 'pickupDateTo',
    dropOffDateFrom = 'dropOffDateFrom',
    dropOffDateTo = 'dropOffDateTo',
    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',
    createdByName = 'createdByName',
    createdById = 'createdById',
    source = 'source',

    quickFilter = 'quickFilter',

    searchText = 'searchText',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dropoffAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dictTrailerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdById]: QueryParam.StringT;
    [QueryFiltersKeysEnum.source]: QueryParam.StringT;

    [QueryFiltersKeysEnum.quickFilter]: RFQsQuickFilterEnum;

    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
}>;

export enum QueryKeysEnum {
    rfqsPage = 'rfqs-page',
    rfqsSort = 'rfqs-sort',
    rfqsFilters = 'rfqs-filters',
}
