import classNames from 'classnames/bind';
import styles from './MapFooter.scss';
import * as React from 'react';
import cs from 'classnames';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const MapFooter: React.FC<PropsT> = React.memo((props) => {
    const { className, children } = props;

    return <div className={cs(cx('map-footer'), className)}>{children}</div>;
});

export default MapFooter;
