import {
    CompanyActionT,
    CompanyStateT,
    FETCH_COMPANY_REQUEST_BEGIN,
    FETCH_COMPANY_REQUEST_ERROR,
    FETCH_COMPANY_REQUEST_SUCCESS,
    UPDATE_COMPANY_REQUEST_BEGIN,
    UPDATE_COMPANY_REQUEST_ERROR,
    UPDATE_COMPANY_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: CompanyStateT = {
    currentCompanyRequest: requestStatus.initial,
    updateCompanyRequest: requestStatus.initial,
    currentCompany: null,
};

export default (state = initialState, action: CompanyActionT | DestroySessionActionT): CompanyStateT => {
    switch (action.type) {
        case FETCH_COMPANY_REQUEST_BEGIN: {
            return {
                ...state,
                currentCompanyRequest: requestStatus.loading,
            };
        }
        case FETCH_COMPANY_REQUEST_SUCCESS: {
            const { currentCompany } = action;
            return {
                ...state,
                currentCompany,
                currentCompanyRequest: requestStatus.ok,
            };
        }
        case FETCH_COMPANY_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                currentCompanyRequest: requestStatus.setError(error),
            };
        }
        case UPDATE_COMPANY_REQUEST_BEGIN: {
            return {
                ...state,
                updateCompanyRequest: requestStatus.loading,
            };
        }
        case UPDATE_COMPANY_REQUEST_SUCCESS: {
            const { currentCompany } = action;
            return {
                ...state,
                currentCompany,
                updateCompanyRequest: requestStatus.ok,
            };
        }
        case UPDATE_COMPANY_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateCompanyRequest: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
