import * as React from 'react';
import { DEFAULT_ICON_SIZE } from 'common/constants';

type PropsT = {
    size: number;
};

const EcoIcon: React.FC<PropsT> = (props) => {
    const { size, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill="#8ABA2E"
                    d="M5.537 7.037a5.251 5.251 0 00-.014 7.41c1.102-2.55 3.067-4.68 5.52-5.947A11.96 11.96 0 007 15.49c1.95.922 4.35.585 5.963-1.027C15.572 11.853 16 4 16 4s-7.853.428-10.463 3.037z"
                />
            </g>
        </svg>
    );
};

EcoIcon.displayName = 'EcoIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
};

export { storyProps };
export default EcoIcon;
