import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
    isWrong?: boolean;
    className?: string;
};

const OriginPinIcon: React.FC<PropsT> = (props) => {
    const { className, isWrong } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="45" viewBox="0 0 40 45" className={className}>
            <defs>
                <filter
                    id="5sn0ldxkca"
                    width="390%"
                    height="357.8%"
                    x="-145%"
                    y="-128.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="15" />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g filter="url(#5sn0ldxkca)" transform="translate(-979 -124) translate(569) translate(410 124)">
                            <path
                                fill={isWrong ? StyleGuideColorsEnum.tomatoRed : StyleGuideColorsEnum.dodgerBlue}
                                d="M25 0c8.284 0 15 6.716 15 15v10c0 8.284-6.716 15-15 15l-5 5-5-5C6.716 40 0 33.284 0 25V15C0 6.716 6.716 0 15 0h10z"
                            />
                            <path
                                fill="#FFF"
                                fillRule="nonzero"
                                d="M16.082 30l1.138-3.736h6.519L24.89 30H30l-7.291-20h-4.472L11 30h5.082zm6.519-7.459h-4.242l2.114-6.937 2.128 6.937z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

OriginPinIcon.displayName = 'OriginPinIcon';

const storyProps: PropsT = {};

export { storyProps };
export default OriginPinIcon;
