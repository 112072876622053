import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import styles from './StickyFooter.scss';
import ContentRootContext from 'common/layouts/LeftMenuLayout/contexts/content-root-context';
import ContentLimiter from 'common/layouts/LeftMenuLayout/ContentLimiter/ContentLimiter';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';

const cx = classNames.bind(styles);

type PropsT = {
    children: React.ReactNode;
};

const StickyFooter: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;

    const contentRootNode = React.useContext(ContentRootContext);
    if (!contentRootNode) {
        return null;
    }

    return createPortal(
        <div className={cx('footer')}>
            <ContentLimiter>
                <ContentMargins>
                    <div className={cx('container')}>
                        <div className={cx('content')}>{children}</div>
                    </div>
                </ContentMargins>
            </ContentLimiter>
        </div>,
        contentRootNode,
    );
});

export default StickyFooter;
