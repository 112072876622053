import * as React from 'react';
import { useEffect, useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './AccessDeniedPage.scss';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { Trans, useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout/AuthLayout';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import AuthLayoutMessage, { AuthMessageIconsEnum } from 'common/layouts/AuthLayout/AuthLayoutMessage/AuthLayoutMessage';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfileType, selectSignOutRequestStatus, selectUserClaims } from 'common/store/auth/selectors';
import { CommonRoutesEnum, CompanyTypeEnum } from 'common/constants';
import { AuthUserClaimRoleT } from 'common/utils/api/models';
import { signOut } from 'common/store/auth/actions';
import history from 'common/utils/history';

const cx = classNames.bind(styles);

type PropsT = {};

const tRoleMap: Record<AuthProfileTypeEnum, string> = {
    [AuthProfileTypeEnum.shipper]: 'common:access-denied-page.wrong-account-role.roles.shipper',
    [AuthProfileTypeEnum.carrier]: 'common:access-denied-page.wrong-account-role.roles.carrier',
    [AuthProfileTypeEnum.broker]: 'common:access-denied-page.wrong-account-role.roles.broker',
};

const tAuthProfileTypeMap: Record<CompanyTypeEnum, AuthProfileTypeEnum> = {
    [CompanyTypeEnum.shipper]: AuthProfileTypeEnum.shipper,
    [CompanyTypeEnum.carrier]: AuthProfileTypeEnum.carrier,
    [CompanyTypeEnum.broker]: AuthProfileTypeEnum.broker,
};

const anyCompanyTypeSet = new Set<AuthUserClaimRoleT>(Object.values(CompanyTypeEnum));

const getRedirectLink = (authProfileType: AuthProfileTypeEnum | null): string | null => {
    if (!authProfileType) {
        return null;
    }

    const currentHost = window.location.host;

    if (/^(ds|dc|db)\.test\.tranziit\.com/.test(currentHost)) {
        const hostMap1: Record<AuthProfileTypeEnum, string> = {
            [AuthProfileTypeEnum.shipper]: 'https://ds.test.tranziit.com/',
            [AuthProfileTypeEnum.carrier]: 'https://dc.test.tranziit.com/',
            [AuthProfileTypeEnum.broker]: 'https://db.test.tranziit.com/',
        };

        return hostMap1[authProfileType];
    }

    if (/^(qs|qc|qb)\.test\.tranziit\.com/.test(currentHost)) {
        const hostMap2: Record<AuthProfileTypeEnum, string> = {
            [AuthProfileTypeEnum.shipper]: 'https://qs.test.tranziit.com/',
            [AuthProfileTypeEnum.carrier]: 'https://qc.test.tranziit.com/',
            [AuthProfileTypeEnum.broker]: 'https://qb.test.tranziit.com/',
        };

        return hostMap2[authProfileType];
    }

    if (/^(shipper|carrier|office)\.test\.tranziit\.com/.test(currentHost)) {
        const hostMap3: Record<AuthProfileTypeEnum, string> = {
            [AuthProfileTypeEnum.shipper]: 'https://shipper.test.tranziit.com/',
            [AuthProfileTypeEnum.carrier]: 'https://carrier.test.tranziit.com/',
            [AuthProfileTypeEnum.broker]: 'https://office.test.tranziit.com/',
        };

        return hostMap3[authProfileType];
    }

    return null;
};

const AccessDeniedPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const userClaims = useSelector(selectUserClaims);

    const firstUserClaimCompanyType = useMemo(() => {
        if (!userClaims) {
            return null;
        }

        return userClaims.roles.find((role) => anyCompanyTypeSet.has(role));
    }, [userClaims, anyCompanyTypeSet]);

    const firstUserClaimAuthProfileType = firstUserClaimCompanyType
        ? tAuthProfileTypeMap[firstUserClaimCompanyType as CompanyTypeEnum]
        : null;

    const currentProfileType = useSelector(selectProfileType);

    const signOutRequest = useSelector(selectSignOutRequestStatus);

    const onRelogin = () => {
        dispatch(signOut());
    };

    const onOpenPageClick = () => {
        const redirectLink = getRedirectLink(firstUserClaimAuthProfileType);
        if (!redirectLink) {
            return;
        }

        window.location.href = redirectLink;
    };

    useEffect(() => {
        if (
            firstUserClaimAuthProfileType &&
            currentProfileType &&
            firstUserClaimAuthProfileType === currentProfileType
        ) {
            history.push({
                pathname: CommonRoutesEnum.home,
            });
        }
    }, [firstUserClaimAuthProfileType, currentProfileType]);

    const roleLabel = firstUserClaimAuthProfileType ? t(tRoleMap[firstUserClaimAuthProfileType]) : null;

    return (
        <>
            <PageTitle title={t('common:page-titles.access-denied')} />
            <AuthLayout testSelector="not-found-page">
                <AuthLayoutMessage
                    iconType={AuthMessageIconsEnum.warning}
                    title={t('common:access-denied-page.wrong-account-role.title')}
                    description={
                        <Trans
                            i18nKey="common:access-denied-page.wrong-account-role.description"
                            components={{
                                role: roleLabel,
                                currentRole: roleLabel,
                            }}
                        />
                    }
                    testSelector="access-denied"
                    actionsProps={[
                        {
                            theme: ButtonThemeEnum.secondary,
                            onClick: onOpenPageClick,
                            children: t('common:access-denied-page.wrong-account-role.actions.open-page', {
                                role: roleLabel,
                            }),
                        },
                        {
                            theme: ButtonThemeEnum.secondary,
                            onClick: onRelogin,
                            isLoading: signOutRequest.loading,
                            children: t('common:access-denied-page.wrong-account-role.actions.relogin'),
                        },
                    ]}
                />
            </AuthLayout>
        </>
    );
});

export default AccessDeniedPage;
