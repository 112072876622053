import { FieldsEnum, FormErrorsT, RoutePointFieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import { checkIsTranziitApiErrorResponse } from 'common/utils/api/tranziit/errors/error-response';
import set from 'lodash/set';
import { TFunction } from 'i18next';

const asyncValidate = (t: TFunction, requestError: RequestStatusT): FormErrorsT => {
    const { error } = requestError;

    const asyncErrors: FormErrorsT = {};

    if (!checkIsTranziitApiRequestError(error)) {
        return asyncErrors;
    }

    if (
        checkIsTranziitApiErrorResponse(
            error,
            TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteNotFound,
        )
    ) {
        error?.response?.indexes?.forEach((index) => {
            set(
                asyncErrors,
                `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.location}`,
                t('common:new-order-form.async-errors.route-not-found'),
            );
        });
    }

    if (
        checkIsTranziitApiErrorResponse(
            error,
            TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable,
        )
    ) {
        error?.response?.indexes?.forEach((index) => {
            set(
                asyncErrors,
                `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.location}`,
                t('common:new-order-form.async-errors.route-waypoint-not-linkable'),
            );
        });
    }

    return asyncErrors;
};

export default asyncValidate;
