import * as React from 'react';

import styles from './FooterSideBarAlert.scss';
import classNames from 'classnames/bind';
import cs from 'classnames';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import InfoIcon from 'common/icons/InfoIcon';

type OnCloseAlertCallbackT = (id: SidebarFooterAlertIdT) => void;

type PropsT = {
    className?: string;
    id: SidebarFooterAlertIdT;
    onCloseAlert: OnCloseAlertCallbackT | null;
    theme: AlertThemeEnum;
};

const cx = classNames.bind(styles);

const FooterSideBarAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, id, children, onCloseAlert, theme } = props;

    return (
        <Alert
            className={cs(cx('wrap'), className)}
            icon={<InfoIcon className={cx('icon')} fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.medium}
            theme={theme}
            onClose={() => {
                if (onCloseAlert) {
                    onCloseAlert(id);
                }
            }}
        >
            {children}
        </Alert>
    );
});

export default FooterSideBarAlert;
