import React from 'react';
import {
    NotificationLinkFactoryT,
    NotificationRendererT,
} from 'common/components/notifications/NotificationsBarContent/models';

export type NotificationSidebarContextT = {
    renderNotification: NotificationRendererT | null;
    getNotificationLink: NotificationLinkFactoryT | null;
};

const NotificationSidebarContext = React.createContext<NotificationSidebarContextT>({
    renderNotification: null,
    getNotificationLink: null,
});

export default NotificationSidebarContext;
