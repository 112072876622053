import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxLength, checkMinLength, checkNotEmpty, checkPhoneNumber } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, [FieldsEnum.firstName, FieldsEnum.lastName], values),
        ...checkMinLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 2),
        ...checkMaxLength(t, [FieldsEnum.firstName, FieldsEnum.lastName], values, 255),
        ...checkPhoneNumber(t, [FieldsEnum.phoneNumber], values),
    };

    if (!values[FieldsEnum.currentPassword] && !!values[FieldsEnum.newPassword]) {
        errors = {
            ...errors,
            [FieldsEnum.currentPassword]: t('common:form-errors.required'),
        };
    }

    if (values[FieldsEnum.currentPassword]) {
        errors = {
            ...errors,
            ...checkMinLength(t, [FieldsEnum.currentPassword], values, 6),
            ...checkMaxLength(t, [FieldsEnum.currentPassword], values, 255),
        };
    }

    if (values[FieldsEnum.newPassword]) {
        errors = {
            ...errors,
            ...checkMinLength(t, [FieldsEnum.newPassword], values, 6),
            ...checkMaxLength(t, [FieldsEnum.newPassword], values, 255),
        };
    }

    return errors;
};

export default validate;
