import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';

export const ALL_ROLES_BY_PARTNER_TYPE: Record<PartnerTypeEnum, RolesEnum[]> = {
    [PartnerTypeEnum.carrier]: [RolesEnum.user, RolesEnum.companyAdmin],
    [PartnerTypeEnum.shipper]: [RolesEnum.user, RolesEnum.companyAdmin],
    [PartnerTypeEnum.broker]: [RolesEnum.companyAdmin, RolesEnum.keyAccountManager, RolesEnum.dispatcher],
    // DEPRECATED ROLES: RolesEnum.customService
};

export enum AuthProfileTypeEnum {
    shipper = 'shipper',
    carrier = 'carrier',
    broker = 'broker',
}

export const ALL_AUTH_PROFILE_TYPES = Object.values(AuthProfileTypeEnum);
