import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './NotificationLabel.scss';

const cx = classNames.bind(styles);

export enum NotificationLabelThemeEnum {
    slate = 'slate',
    charcoal = 'charcoal',
    orange = 'orange',
    green = 'green',
    red = 'red',
}

// confluence entity
export const colorCodingNotificationLabelTheme = {
    normal: NotificationLabelThemeEnum.slate,
    attention: NotificationLabelThemeEnum.orange,
    warning: NotificationLabelThemeEnum.red,
    notImportant: NotificationLabelThemeEnum.charcoal,
    success: NotificationLabelThemeEnum.green,
    focus: NotificationLabelThemeEnum.charcoal,
} as const;

export type NotificationPropsT = {
    count?: number | null | undefined;
    theme: NotificationLabelThemeEnum;
    className?: string;
    isShowPlusSign?: boolean;
    isShowWarning?: boolean;
};

const NotificationLabel: React.FC<NotificationPropsT> = React.memo((props) => {
    const { count, className, theme, isShowPlusSign, isShowWarning } = props;

    if (!count && !isShowWarning) {
        return null;
    }

    return (
        <div
            className={cs(
                cx('notification', {
                    [`notification--theme-${theme}`]: true,
                }),
                className,
            )}
        >
            {isShowWarning ? '!' : ''}
            {isShowPlusSign ? '+' : ''}
            {count}
        </div>
    );
});

export default NotificationLabel;
