import { ADDITIONAL_SERVICES_ID_MAP } from 'common/constants';

const getDefaultAdditionalServices = (isBrokerWindow: boolean): number[] => {
    const services = [];

    if (isBrokerWindow) {
        services.push(ADDITIONAL_SERVICES_ID_MAP.TIME_SLOTS_APPOINTMENT_BY_BROKER);
    }

    return services;
};

export { getDefaultAdditionalServices };
