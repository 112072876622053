import * as React from 'react';
import type { DocumentRowT } from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/BaseDocumentsTable';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './ExpireDateCell.scss';
import { DocumentStatusEnum } from 'common/utils/api/models';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { DEFAULT_ICON_SIZE, documentsWithoutExpirySet, StyleGuideColorsEnum } from 'common/constants';
import {
    selectUploadingDictDocumentId,
    selectUploadCompanyDocumentsRequest,
    selectUpdatingDocumentId,
    selectUpdateCompanyDocumentsRequest,
} from 'common/store/documents/selectors';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';

const cx = classNames.bind(styles);

type PropsT = {
    row: DocumentRowT;
    className?: string;
    onUploadDocument: (id: DocumentIdT) => void;
    onSelectRow?: (row: DocumentRowT) => void;
};

const ExpireDateCell: React.FC<PropsT> = React.memo((props) => {
    const { row, onUploadDocument, onSelectRow } = props;

    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const uploadingDictDocumentId = useSelector(selectUploadingDictDocumentId(partnerId));
    const uploadRequest = useSelector(selectUploadCompanyDocumentsRequest(partnerId));

    const updatingDocumentId = useSelector(selectUpdatingDocumentId(partnerId));
    const updateRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));

    const permissions = useSelector(selectPermissions);

    if (
        (row.dictDocument.id === uploadingDictDocumentId && uploadRequest.loading) ||
        (row.actualVersion?.id === updatingDocumentId && updateRequest.loading)
    ) {
        return <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} size={DEFAULT_ICON_SIZE} />;
    }

    if (permissions.canUploadDocuments && !row.actualVersion) {
        return (
            <ClickInterceptorLabel>
                <Button
                    className={cx('action')}
                    theme={ButtonThemeEnum.secondary}
                    onClick={() => {
                        if (onUploadDocument) {
                            onUploadDocument(row.dictDocument.id);
                        }
                    }}
                >
                    {t('common:documents.table.actions.upload')}
                </Button>
            </ClickInterceptorLabel>
        );
    }

    if (
        permissions.canUploadDocuments &&
        (row.actualVersion?.status === DocumentStatusEnum.rejected ||
            row.actualVersion?.status === DocumentStatusEnum.revoked)
    ) {
        return (
            <ClickInterceptorLabel>
                <Button
                    className={cx('action')}
                    theme={ButtonThemeEnum.secondary}
                    onClick={() => {
                        if (onUploadDocument) {
                            onUploadDocument(row.dictDocument.id);
                        }
                    }}
                >
                    {t('common:documents.table.actions.upload-new')}
                </Button>
            </ClickInterceptorLabel>
        );
    }

    if (permissions.canReviewDocuments && row.actualVersion?.status === DocumentStatusEnum.waitingForApprove) {
        return (
            <ClickInterceptorLabel>
                <Button
                    className={cx('action')}
                    theme={ButtonThemeEnum.secondary}
                    onClick={() => {
                        if (onSelectRow) {
                            onSelectRow(row);
                        }
                    }}
                >
                    {t('common:documents.table.actions.review')}
                </Button>
            </ClickInterceptorLabel>
        );
    }

    if (documentsWithoutExpirySet.has(row.dictDocument.type)) {
        return <div className={cx('without-expiry')}>{t('common:documents.table.without-expiry')}</div>;
    }

    return <DateCell className={cx('date')} date={row.actualVersion?.validTill} />;
});

export default ExpireDateCell;
