import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    isInvert?: boolean;
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const ExpandIcon: React.FC<PropsT> = (props) => {
    const { isInvert, className, fillColor, ...restProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            style={{
                transform: `rotate(${isInvert ? '180' : '0'}deg)`,
            }}
            className={className}
            {...restProps}
        >
            <path
                d="M13.293 7.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 10.586l3.293-3.293z"
                fill={fillColor}
                fillRule="evenodd"
            />
        </svg>
    );
};

ExpandIcon.displayName = 'ExpandIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default ExpandIcon;
