import React from 'react';

import { CompanyContactT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { useSelector } from 'react-redux';
import { selectContactById, selectUpdateMemberRequest } from 'common/store/members/selectors';
import { logWarning } from 'common/utils/logger';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { StyleGuideColorsEnum } from 'common/constants';

export type RestoreUserConfirmationDataT = {
    partnerId: CompanyIdT;
    contactId: CompanyContactT['id'];
};

export type RestoreUserConfirmationPropsT = {
    data: RestoreUserConfirmationDataT | null;
    onClose: () => void;
    onConfirm: (userId: UserIdT, partnerId: PartnerIdT) => void;
};

const TEST_SELECTOR = 'restore-user-confirmation';

const RestoreUserConfirmation: React.FC<RestoreUserConfirmationPropsT> = React.memo((props) => {
    const { data, onClose, onConfirm } = props;

    const { t } = useTranslation();

    const contactById = useSelector(selectContactById(data?.partnerId || null));
    const updateMemberRequestStatus = useSelector(selectUpdateMemberRequest(data?.partnerId || null));

    if (!data || !data?.partnerId || !data?.contactId) {
        return null;
    }

    const contact = contactById[data.contactId];

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: updateMemberRequestStatus.loading,
                            onClick: () => {
                                onClose();
                            },
                        },
                        {
                            children: t('common:team-members.dialogs.restore-user.restore'),
                            theme: ButtonThemeEnum.primary,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: updateMemberRequestStatus.loading,
                            onClick: () => {
                                if (contact?.userId) {
                                    onConfirm(contact.userId, data.partnerId);
                                } else {
                                    logWarning('empty user id');
                                }
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                    title={t('common:team-members.dialogs.restore-user.title', {
                        fullName: contact?.fullName,
                    })}
                    message={t('common:team-members.dialogs.restore-user.description', {
                        fullName: contact?.fullName,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default RestoreUserConfirmation;
