import * as React from 'react';
import { fetchRoutesPlainGeometry, resetRouteGeometry } from 'common/store/routing-geometry/slice';
import { useDispatch } from 'react-redux';

export const useRouteGeometry = (polylineIds: Array<PolylineIdT | null>) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            fetchRoutesPlainGeometry({
                polylineIds,
            }),
        );

        return () => {
            dispatch(
                resetRouteGeometry({
                    polylineIds,
                }),
            );
        };
    }, [polylineIds]);
};
