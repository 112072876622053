import * as React from 'react';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiEmissionClassT } from 'common/constants';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';

type PropsT = {
    className?: string;
    emissionClass: ApiEmissionClassT | null | undefined;
};

const EmissionClassCell: React.FC<PropsT> = React.memo((props) => {
    const { emissionClass, className } = props;

    return (
        <PillLabel className={className} theme={PillLabelThemeEnum.slate} isSymmetrical>
            <EmissionClassLabel isCompact emissionClass={emissionClass} />
        </PillLabel>
    );
});

export default EmissionClassCell;
