import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';
import { useTranslation } from 'react-i18next';

const T_MAP: Partial<Record<AuthErrorTypeEnum, string>> = {
    [AuthErrorTypeEnum.wrongPassword]: 'common:auth-errors.wrong-password',
    [AuthErrorTypeEnum.failReauthenticateWithCredential]: 'common:auth-errors.fail-reauthenticate',
    [AuthErrorTypeEnum.failUpdatePassword]: 'common:auth-errors.fail-update-password',
    [AuthErrorTypeEnum.userNotFound]: 'common:auth-errors.user-not-found',
    [AuthErrorTypeEnum.userDisabled]: 'common:auth-errors.user-disabled',
    [AuthErrorTypeEnum.expiredActionCode]: 'common:auth-errors.expired-action-code',
    [AuthErrorTypeEnum.invalidActionCode]: 'common:auth-errors.invalid-action-code',
    [AuthErrorTypeEnum.weakPassword]: 'common:auth-errors.weak-password',
    [AuthErrorTypeEnum.wrongRole]: 'common:auth-errors.wrong-role',
    [AuthErrorTypeEnum.unknown]: 'common:something-went-wrong',
};

const DEFAULT_T = 'common:something-went-wrong';

type ErrorMessageT = string;

function useAsyncFormErrorMessage(errorType?: AuthErrorTypeEnum | null): ErrorMessageT | null {
    const { t } = useTranslation();

    if (!errorType || errorType === AuthErrorTypeEnum.networkRequestFailed) {
        return null;
    }

    return t(T_MAP[errorType] || DEFAULT_T);
}

export default useAsyncFormErrorMessage;
