import classNames from 'classnames/bind';
import styles from './MapTooltip.scss';
import * as React from 'react';
import isNil from 'lodash/isNil';
import MapPin, { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number | undefined;
    lng: number | undefined;
    className?: string;
    hasPaddings?: boolean;
    theme?: MapPinThemeEnum;
    children: (isHover: boolean) => React.ReactElement;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onHover?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MapTooltip: React.FC<PropsT> = React.memo((props) => {
    const { onHover, hasPaddings, lat, lng, theme } = props;

    const [isHover, setHover] = React.useState<boolean>(false);

    if (isNil(lat) || isNil(lng)) {
        return null;
    }

    return (
        <div
            className={cx('map-tooltip-wrap')}
            onMouseMove={(event) => {
                if (!isHover && onHover) {
                    onHover(event);
                }
                if (!isHover) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            onClick={props.onClick}
        >
            <MapPin className={props.className} hasPaddings={hasPaddings} theme={theme}>
                {props.children(isHover)}
            </MapPin>
        </div>
    );
});

export default MapTooltip;
