import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    borderColor: StyleGuideColorsEnum;
    className?: string;
};

const CircleIcon: React.FC<PropsT> = (props) => {
    const { borderColor, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
            <path
                fill={borderColor}
                fillRule="evenodd"
                d="M10 13a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 11.001-10.001A5 5 0 0110 15z"
            />
        </svg>
    );
};

CircleIcon.displayName = 'CircleIcon';

const storyProps: PropsT = {
    borderColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default CircleIcon;
