import head from 'lodash/head';
import GeocoderResult = google.maps.GeocoderResult;

class GoogleMapsGeocoderApi {
    private maps: MapsT | null;

    constructor(maps: MapsT | null | undefined) {
        this.maps = maps || null;
    }

    geocode = async (point: GeoPointT): Promise<GeocoderResult | null> => {
        const { maps } = this;
        if (!maps) {
            return Promise.resolve(null);
        }

        const gLocation = new maps.LatLng(point.lat, point.lng);
        const geocoder = new maps.Geocoder();

        return new Promise((resolve) => {
            if (!geocoder) {
                resolve(null);
                return;
            }

            geocoder.geocode({ location: gLocation }, (results): void => {
                const firstGeocodeResultWithPostalAddress = results.find((data) => {
                    return data.address_components?.find((component) => component.types?.includes('postal_code'));
                });
                const firstGeocodeResultWithCountryCode = results.find((data) => {
                    return data.address_components?.find((component) => component.types?.includes('country'));
                });
                const firstGeocodeResult = head(results);
                const geocodeResult =
                    firstGeocodeResultWithPostalAddress || firstGeocodeResultWithCountryCode || firstGeocodeResult;

                resolve(geocodeResult || null);
            });
        });
    };
}

export { GoogleMapsGeocoderApi };
