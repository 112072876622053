import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TollIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M12.25 5.5a4.504 4.504 0 00-4.5 4.5c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5z"
                />
                <path
                    fill={strokeColor}
                    d="M12.25 4c-3.315 0-6 2.685-6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 10.5a4.504 4.504 0 01-4.5-4.5c0-2.482 2.018-4.5 4.5-4.5s4.5 2.018 4.5 4.5-2.018 4.5-4.5 4.5zm-9-4.5a4.493 4.493 0 013-4.237V4.194A5.996 5.996 0 001.75 10a5.996 5.996 0 004.5 5.805v-1.567a4.493 4.493 0 01-3-4.238z"
                />
            </g>
        </svg>
    );
};

TollIcon.displayName = 'TollIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default TollIcon;
