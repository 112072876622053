import * as React from 'react';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, RenderSidebarContentT, SidebarCloseConfirmationT } from './models';
import { TFunction } from 'i18next';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import ContactDetailsSidebarContent from 'common/layouts/SideBars/contents/ContactDetailsSidebarContent/ContactDetailsSidebarContent';
import EditContactDetailsSidebarContent from 'common/layouts/SideBars/contents/EditContactDetailsSidebarContent/EditContactDetailsSidebarContent';
import DocumentDetailsSidebarContent from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/DocumentDetailsSidebarContent';
import ContractDetailsSidebarContent from 'common/layouts/SideBars/contents/ContractDetailsSidebarContent/ContractDetailsSidebarContent';
import DriverFiltersSidebarContent from 'common/layouts/SideBars/contents/DriverFiltersSidebarContent/DriverFiltersSidebarContent';
import EditDriverSidebarContent from 'common/layouts/SideBars/contents/EditDriverSidebarContent/EditDriverSidebarContent';
import DriverDetailsSidebarContent from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/DriverDetailsSidebarContent';
import TrailerFiltersSidebarContent from 'common/layouts/SideBars/contents/TrailerFiltersSidebarContent/TrailerFiltersSidebarContent';
import AddTrailerSidebarContent from 'common/layouts/SideBars/contents/AddTrailerSidebarContent/AddTrailerSidebarContent';
import EditableTrailerDetailsSidebarContent from 'common/layouts/SideBars/contents/EditableTrailerDetailsSidebarContent/EditableTrailerDetailsSidebarContent';
import TruckFiltersSidebarContent from 'common/layouts/SideBars/contents/TruckFiltersSidebarContent/TruckFiltersSidebarContent';
import AddTruckSidebarContent from 'common/layouts/SideBars/contents/AddTruckSidebarContent/AddTruckSidebarContent';
import EditableTruckDetailsSidebarContent from 'common/layouts/SideBars/contents/EditableTruckDetailsSidebarContent/EditableTruckDetailsSidebarContent';

export const commonGetSidebarCloseConfirmation = (
    t: TFunction,
    data: CommonSidebarDataT,
): SidebarCloseConfirmationT | null => {
    if (data.type === CommonSidebarsTypeEnum.editContact) {
        return {
            icon: <BigWarningIcon />,
            title: t('common:sidebar.unsaved-changes-confimration.title'),
            message: t('common:sidebar.unsaved-changes-confimration.message'),
            cancelAction: t('common:error-modal.actions.cancel'),
            confirmAction: t('common:error-modal.actions.close'),
        };
    }

    return {
        icon: <BigWarningIcon />,
        title: t('common:sidebar.unsaved-changes-confimration.title'),
        message: t('common:sidebar.unsaved-changes-confimration.message'),
        cancelAction: t('common:error-modal.actions.cancel'),
        confirmAction: t('common:error-modal.actions.close'),
    };
};

export const renderCommonSidebarContent: RenderSidebarContentT<CommonSidebarDataT> = (props): React.ReactNode => {
    const { data, ...restProps } = props;

    switch (data.type) {
        case CommonSidebarsTypeEnum.contact: {
            return <ContactDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.editContact: {
            return <EditContactDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.document: {
            return <DocumentDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.carrierContract: {
            return <ContractDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.driverFilters: {
            return <DriverFiltersSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.driverDetails: {
            return <DriverDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.editDriverDetails: {
            return <EditDriverSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.trailerFilters: {
            return <TrailerFiltersSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.trailerDetails: {
            return <EditableTrailerDetailsSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.addTrailer: {
            return <AddTrailerSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.truckFilters: {
            return <TruckFiltersSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.addTruck: {
            return <AddTruckSidebarContent {...restProps} data={data} />;
        }
        case CommonSidebarsTypeEnum.truckDetails: {
            return <EditableTruckDetailsSidebarContent {...restProps} data={data} />;
        }
        default: {
            return null;
        }
    }
};
