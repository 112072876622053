import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import { ApiRegistrationCompanyRequestDraftT } from 'common/utils/api/models';

export type CreateCompanyStateT = {
    requestStatus: RequestStatusT;
    isLocked: boolean | null;
};

const initialState: CreateCompanyStateT = {
    requestStatus: requestStatus.initial,
    isLocked: null,
};

export const createCompanySlice = createSlice({
    name: 'createCompany',
    initialState,
    reducers: {
        setCompanyIsLocked: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                isLocked: boolean | null;
            }>,
        ) => {
            state.isLocked = action.payload.isLocked;
        },
        createCompanyRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                registrationCompanyRequestDraft: ApiRegistrationCompanyRequestDraftT;
                returnUrl: string | null;
            }>,
        ) => {
            state.isLocked = null;
        },
        createCompanyRequestBegin: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.requestStatus = requestStatus.loading;
        },
        createCompanyRequestSuccess: (
            state,
            action: PayloadAction<{
                isLocked: boolean | null;
            }>,
        ) => {
            state.requestStatus = requestStatus.ok;
            state.isLocked = action.payload.isLocked;
        },
        createCompanyRequestError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;

            state.requestStatus = requestStatus.setError(error);
        },
    },
});

export const {
    setCompanyIsLocked,
    createCompanyRequest,
    createCompanyRequestBegin,
    createCompanyRequestSuccess,
    createCompanyRequestError,
} = createCompanySlice.actions;

export default createCompanySlice.reducer;
