import React from 'react';

import classNames from 'classnames/bind';
import styles from './CustomZoneTextWrap.scss';
import { RangeSelectorRangeT } from '../models';

const cx = classNames.bind(styles);

export type PropsT = {
    values: number[] | null;
    currentRanges: RangeSelectorRangeT[] | null;
    renderZoneTextNode: (currentRanges: RangeSelectorRangeT[] | null, values: number[] | null) => React.ReactNode;
};

const CustomZoneTextWrap: React.FC<PropsT> = React.memo((props) => {
    const { values, currentRanges, renderZoneTextNode } = props;

    return (
        <div className={cx('zone-title')} data-test-selector="custom-range-selector-zone-text">
            {renderZoneTextNode(currentRanges, values)}
        </div>
    );
});

export default CustomZoneTextWrap;
