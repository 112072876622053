import * as React from 'react';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import { useTranslation } from 'react-i18next';

type PropsT = {};

const UploadLoader: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    return <SideBarLoader message={t('common:documents.upload-loader.message')} />;
});

export default UploadLoader;
