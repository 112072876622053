import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FooterSideBarLayout.scss';
import SideBarLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-layout-context';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isAbsolute?: boolean;
    topNode?: React.ReactNode;
    hasPaddings?: boolean;
    hasTopBorder?: boolean;
    isTransparent?: boolean;
    hasShadow?: boolean;
};

const FooterSideBarLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, isAbsolute, topNode, hasPaddings, hasTopBorder, isTransparent, hasShadow, className } = props;

    const ref = React.useRef<HTMLDivElement | null>(null);

    const context = React.useContext(SideBarLayoutContext);

    const measureContainer = () => {
        if (!ref.current) {
            return;
        }

        const newHeight = ref.current?.clientHeight;
        context.footerHeight?.setValue(newHeight);
    };

    React.useEffect(() => {
        requestAnimationFrame(measureContainer);
    }, [children, topNode, hasPaddings, hasTopBorder]);

    React.useEffect(() => {
        window.addEventListener('resize', measureContainer);

        return () => {
            window.removeEventListener('resize', measureContainer);
            context.footerHeight?.setValue(0);
        };
    }, []);

    return (
        <div
            ref={ref}
            className={cs(
                cx('wrap', {
                    'wrap--hasTopBorder': hasTopBorder,
                }),
                className,
            )}
            style={{ marginRight: `${context.scrollBarWidth?.value || 0}px` }}
        >
            <div
                className={cx('inner', {
                    'inner--isAbsolute': isAbsolute,
                })}
            >
                {topNode}
                {children && (
                    <div
                        className={cx('content', {
                            'content--hasPaddings': hasPaddings,
                            'content--hasBackground': !isTransparent,
                            'content--hasShadow': hasShadow,
                        })}
                    >
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
});

export default FooterSideBarLayout;
