import {
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN,
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR,
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS,
    GroupedNotificationCountsActionT,
    GroupedNotificationCountsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: GroupedNotificationCountsStateT = {
    fetchRequest: requestStatus.initial,
    counts: null,
};

export default (
    state = initialState,
    action: GroupedNotificationCountsActionT | DestroySessionActionT,
): GroupedNotificationCountsStateT => {
    switch (action.type) {
        case FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS: {
            const { counts } = action;

            return {
                ...state,
                fetchRequest: requestStatus.ok,
                counts,
            };
        }

        case FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
