export type RequestStatusT = {
    initial: boolean;
    loading: boolean;
    error: Error | null;
    ok: boolean;
    time: number;
};

type RequestStatusStateT = {
    initial: RequestStatusT;
    loading: RequestStatusT;
    ok: RequestStatusT;
    setError: (error: Error) => RequestStatusT;
};

const getDefaultState = (): RequestStatusT => ({
    initial: false,
    loading: false,
    error: null,
    ok: false,
    time: process.env.TEST ? 11111 : Date.now(),
});

const requestStatus: RequestStatusStateT = {
    get initial() {
        return {
            ...getDefaultState(),
            time: 0,
            initial: true,
        };
    },
    get loading() {
        return {
            ...getDefaultState(),
            loading: true,
        };
    },
    get ok() {
        return {
            ...getDefaultState(),
            ok: true,
        };
    },
    setError: (error: Error) => ({
        ...getDefaultState(),
        error,
    }),
};

export default requestStatus;
