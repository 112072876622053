import { put, takeEvery } from 'redux-saga/effects';
import {
    createCompanyRequest,
    createCompanyRequestBegin,
    createCompanyRequestError,
    createCompanyRequestSuccess,
} from './slice';
import { CreateCompanyRequestActionT } from './types';
import { CompanyTypeEnum } from 'common/constants';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import i18n from 'i18next';
import history from 'common/utils/history';
import { parseAuthReturnUrl } from 'common/utils/auth-return-url';
import { authApi } from 'common/utils/api/auth/factory';

function getCreateCompanySaga(companyType: CompanyTypeEnum) {
    return function* (action: CreateCompanyRequestActionT): WrapGeneratorT<void> {
        const { registrationCompanyRequestDraft, returnUrl } = action.payload;

        yield put(createCompanyRequestBegin({}));

        const [error] = yield commonTranziitApi.createCompany({
            ...registrationCompanyRequestDraft,
            companyType,
            language: i18n.language,
        });

        if (error) {
            yield put(createCompanyRequestError({ error }));
            return;
        }

        const [forceRefreshTokenError] = yield authApi.forceRefreshToken();

        yield put(
            createCompanyRequestSuccess({
                isLocked: !!forceRefreshTokenError,
            }),
        );

        if (!forceRefreshTokenError) {
            history.push(parseAuthReturnUrl(returnUrl));
        }
    };
}

function* createCompanySaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(createCompanyRequest.type, getCreateCompanySaga(companyType));
}

export { createCompanySaga };
