const concatTestSelectors = (testSelectors: Array<string | null | undefined>): string => {
    let result = '';

    testSelectors.forEach((testSelector) => {
        if (testSelector) {
            result += `${result ? '_' : ''}${testSelector}`;
        }
    });

    return result;
};

export { concatTestSelectors };
