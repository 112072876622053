import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './RuleImpact.scss';
import { RuleImpactStrategyEnum, UnitTypeEnum } from 'common/constants';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';

type PropsT = {
    className?: string;
    value?: number;
    targetPriceValueType?: RuleImpactStrategyEnum;
};

const cx = classNames.bind(styles);

const UNIT_BY_TYPE = {
    [RuleImpactStrategyEnum.percent]: UnitTypeEnum.percentsAbbreviation,
    [RuleImpactStrategyEnum.exactValue]: UnitTypeEnum.euroAbbreviation,
};

const signedUnitTypesSet = new Set<RuleImpactStrategyEnum>([
    RuleImpactStrategyEnum.percent,
    RuleImpactStrategyEnum.exactValue,
]);

const RuleImpact: React.FC<PropsT> = React.memo((props) => {
    const { className, value, targetPriceValueType } = props;

    if (value === undefined || targetPriceValueType === undefined) {
        return null;
    }

    const sign = value > 0 ? '+ ' : '- ';
    const absValue = Math.abs(value);
    const isShowSign = signedUnitTypesSet.has(targetPriceValueType) || value < 0;

    return (
        <span className={cs(cx('rule'), className)}>
            {isShowSign ? sign : null}
            <UnitTypeCount count={absValue} type={UNIT_BY_TYPE[targetPriceValueType as RuleImpactStrategyEnum]} />
        </span>
    );
});

export default RuleImpact;
