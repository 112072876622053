import React from 'react';

import { CompanyContactT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import Modal from 'common/components/Modal/Modal';
import { useSelector } from 'react-redux';
import { selectContactById, selectUpdateMemberRequest } from 'common/store/members/selectors';
import { logWarning } from 'common/utils/logger';

export type DeleteContactConfirmationDataT = {
    partnerId: CompanyIdT;
    contactId: CompanyContactT['id'];
};

export type DeleteContactConfirmationPropsT = {
    data: DeleteContactConfirmationDataT | null;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (partnerId: PartnerIdT, contactId: ContactIdT) => void;
};

const TEST_SELECTOR = 'delete-contact-confirmation';

const DeleteContactConfirmation: React.FC<DeleteContactConfirmationPropsT> = React.memo((props) => {
    const { data, onClose, onCancel, onConfirm } = props;

    const { t } = useTranslation();

    const contactById = useSelector(selectContactById(data?.partnerId || null));
    const updateMemberRequestStatus = useSelector(selectUpdateMemberRequest(data?.partnerId || null));

    if (!data) {
        return null;
    }

    const contact = contactById[data?.contactId as ContactIdT];
    if (!contact) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: updateMemberRequestStatus.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:team-members.dialogs.delete-contact.delete'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: updateMemberRequestStatus.loading,
                            onClick: () => {
                                if (data.contactId) {
                                    onConfirm(data.partnerId, data.contactId);
                                } else {
                                    logWarning('empty deletingContactId');
                                }
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('common:team-members.dialogs.delete-contact.title', {
                        fullName: contact?.fullName,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default DeleteContactConfirmation;
