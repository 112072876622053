import { RequestStatusT } from './request-status';
import { MS_IN_SEC } from './time';
import isNumber from 'lodash/isNumber';
import { checkIsUnauthorizedError } from 'common/utils/api/errors/api-request-error';

const DEFAULT_REQUEST_TTL = MS_IN_SEC;
const DEFAULT_ERROR_TTL = MS_IN_SEC;

export const checkIsExpiredRequest = (time: number, ttl = DEFAULT_REQUEST_TTL, now = Date.now()): boolean => {
    return time + ttl < now;
};

export const checkIsExpiredError = (time: number, ttl = DEFAULT_ERROR_TTL, now = Date.now()): boolean => {
    return time + ttl < now;
};

const checkNeedRequest = (
    request: RequestStatusT | null | undefined,
    options?: FetchOptionsT,
    requestOkTTL?: number,
    requestErrorTTL?: number,
): boolean => {
    if (!request) {
        return true;
    }

    if (options?.isForceUpdate) {
        return true;
    }

    const isUnauthorizedError = checkIsUnauthorizedError(request.error);
    const errorTTL = isNumber(requestErrorTTL) ? requestErrorTTL : DEFAULT_ERROR_TTL;
    if (request.error && !checkIsExpiredError(request.time, errorTTL) && !isUnauthorizedError) {
        return false;
    }

    if (request.loading) {
        return false;
    }

    const okTTL = isNumber(requestOkTTL) ? requestOkTTL : DEFAULT_REQUEST_TTL;
    if (request.ok && !checkIsExpiredRequest(request.time, okTTL)) {
        return false;
    }

    return true;
};

export default checkNeedRequest;
