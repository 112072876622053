import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Notification.scss';
import CloseIcon from 'common/icons/CloseIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    title: string;
    message?: string;
    testSelector?: string;
};

const Notification: React.FC<PropsT> = React.memo((props) => {
    const { title, message, testSelector } = props;
    const [isClosed, setClosed] = React.useState(false);

    if (isClosed) {
        return null;
    }

    return (
        <div className={cx('notification', 'content')}>
            <div className={cx('content__left')}>
                <div className={cx('inner')} data-test-selector={testSelector}>
                    <div className={cx('title')}>{title}</div>
                    {!!message && <p className={cx('message')}>{message}</p>}
                </div>
            </div>
            <div className={cx('content__right')}>
                <button
                    className={cx('close')}
                    data-dismiss="alert"
                    type="button"
                    data-test-selector={`${testSelector}-close`}
                    onClick={(): void => {
                        setClosed(true);
                    }}
                >
                    <CloseIcon fillColor={StyleGuideColorsEnum.white} />
                </button>
            </div>
        </div>
    );
});

export default Notification;
