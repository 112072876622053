import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

type FormFieldT = string;
type FormValueT = object | number | string | boolean | null | undefined | Array<number> | Array<string>;
type FormValuesT = Record<FormFieldT, FormValueT>;

type ChangesDetactorT = <T extends FormValuesT>(valueA: ValueOfT<T>, valueB: ValueOfT<T>) => boolean;

const defaultChangesDetactor: ChangesDetactorT = (valueA, valueB) => isEqual(valueA, valueB);

export const checkValuesChanges = <T extends FormValuesT>(
    fields: Array<keyof T>,
    initValues: T,
    values: T,
    customChangesDetector?: ChangesDetactorT,
): boolean => {
    const changesDetector = customChangesDetector || defaultChangesDetactor;

    return fields.some((field) => {
        const isSameValue = changesDetector(initValues[field], values[field]);

        return !isSameValue;
    });
};

export const checkHasAnyErrors = (errorsMap: Record<FormFieldT, string | string[]>): boolean => {
    return !isEmpty(errorsMap);
};
