import isNumber from 'lodash/isNumber';

export type PlaceResultT = OverwriteT<
    google.maps.places.PlaceResult,
    {
        address_components: NonNullable<google.maps.places.PlaceResult['address_components']>;
        geometry: NonNullable<google.maps.places.PlaceResult['geometry']>;
        utc_offset_minutes: NonNullable<google.maps.places.PlaceResult['utc_offset_minutes']>;
    }
>;

const checkIsValidPlacesResult = (
    placesResult: google.maps.places.PlaceResult | null | undefined,
): placesResult is PlaceResultT => {
    return !!placesResult?.address_components && !!placesResult?.geometry && isNumber(placesResult?.utc_offset_minutes);
};

class GoogleMapsPlacesApi {
    private map: MapT | null;

    private maps: MapsT | null;

    constructor(map: MapT | null | undefined, maps: MapsT | null | undefined) {
        this.map = map || null;
        this.maps = maps || null;
    }

    getPlaceDetails = async (placeId: string | null): Promise<PlaceResultT | null> => {
        const { maps, map } = this;

        if (!maps || !map || !placeId) {
            return Promise.resolve(null);
        }

        return new Promise((resolve) => {
            const placesService = new maps.places.PlacesService(map);
            placesService.getDetails(
                {
                    placeId,
                    fields: ['ALL'],
                },
                (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus): void => {
                    if (status !== 'OK') {
                        resolve(null);
                        return;
                    }

                    const isValidResult = checkIsValidPlacesResult(result);
                    if (!isValidResult) {
                        resolve(null);
                        return;
                    }

                    resolve(result || null);
                },
            );
        });
    };
}

export { GoogleMapsPlacesApi };
