import React from 'react';
import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useDispatch, useSelector } from 'react-redux';
import { findShippersById, findShippersByQuery } from 'common/store/shipper-suggest/actions';
import {
    selectFindShippersRequest,
    selectShippersById,
    selectShippersIds,
} from 'common/store/shipper-suggest/selectors';
import { PartnerT } from 'common/store/shipper-suggest/models';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { ALL_SHIPPERS_ID } from 'common/store/shipper-suggest/constants';
import { useTranslation } from 'react-i18next';
import ShipperIcon, { ShipperIconProps } from 'common/icons/ShipperIcon';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import { CountryCodeT } from 'common/store/countries-dict/models';

type PartnerIdT = PartnerT['id'];

type ValueT = PartnerIdT | null;

type OptionT = {
    id: ValueT;
    name: string;
    number: string;
    countryCode: CountryCodeT | null;
};

export type PropsT = {
    className?: string;
    initialValue: ValueT;
    initialLabel: string;
    hasAllOption?: boolean;
    onSelect: (value: ValueT, name: string) => void;
    value: ValueT;
    overlayPosition: DropdownOverlayPositionEnum;
    hasClearControl?: InputPropsT['hasClearControl'];
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
};

const ShipperDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        initialValue,
        initialLabel,
        hasAllOption,
        value,
        onSelect,
        hasWarning,
        hasError,
        hasClearControl,
        overlayPosition,
        isDisabled,
        placeholder,
        onBlur,
        onFocus,
        hasChanges,
    } = props;

    const dispatch = useDispatch();

    const ids = useSelector(selectShippersIds);
    const byId = useSelector(selectShippersById);
    const requestStatus = useSelector(selectFindShippersRequest);

    const { t } = useTranslation();

    const options = React.useMemo(() => {
        const options = ids
            .filter((id) => byId[id])
            .map((id): OptionT => {
                const partner = byId[id];

                return {
                    id: partner.id || null,
                    name: partner.name || '',
                    number: partner.number || '',
                    countryCode: partner.countryCode || null,
                };
            });

        if (hasAllOption) {
            options.push({
                id: ALL_SHIPPERS_ID,
                name: t('common:partner-suggest.all'),
                number: '',
                countryCode: null,
            });
        }

        return options;
    }, [t, ids, byId, hasAllOption]);

    React.useEffect(() => {
        if (initialValue === ALL_SHIPPERS_ID) {
            return;
        }

        if (initialValue && !byId[initialValue]) {
            dispatch(findShippersById(initialValue, initialLabel));
        }
    }, [initialValue]);

    const handleChangeQuery = React.useCallback((query: string): void => {
        dispatch(findShippersByQuery(query));
    }, []);

    const handleSelect = (value: ValueT) => {
        const partner = byId[value as string];

        onSelect(value || null, partner?.name || '');
    };

    const hasValue = !!value;

    const handleReset = () => {
        onSelect(null, '');
    };

    const handleFocus: InputPropsT['onFocus'] = () => {
        if (onFocus) {
            onFocus();
        }
    };

    const renderOption = (option: OptionT | undefined, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return (
            <DropdownControlOptionLabel
                withoutPaddings
                icon={option.countryCode ? <FlagIcon countryCode={option.countryCode} /> : null}
                label={option.name || ''}
                description={option.number || ''}
            />
        );
    };

    const getOptionValue = (option: OptionT): ValueT => option.id;

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            isDisabled={isDisabled}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={handleChangeQuery}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            renderLeftIcon={(iconMeta) => <ShipperIcon {...ShipperIconProps.getControlProps(iconMeta)} />}
            onBlur={onBlur}
            onFocus={handleFocus}
            overlayPosition={overlayPosition}
            isLoading={requestStatus.loading}
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
};

export default ShipperDropdown;
