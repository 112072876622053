import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './LocationCell.scss';

type PropsT = {
    address: React.ReactNode;
};

const cx = classNames.bind(styles);

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { address } = props;

    return <div className={cx('location')}>{address}</div>;
});

export default LocationCell;
