import { useEffect, useRef } from 'react';

type HandlerT = () => void;

const useDocumentVisibilityChange = (handler: HandlerT) => {
    const visibilitychangeHandler = useRef<HandlerT>();

    useEffect(() => {
        visibilitychangeHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const onVisibilityChange = (): void => {
            if (document.visibilityState === 'visible') {
                if (visibilitychangeHandler.current) {
                    visibilitychangeHandler.current();
                }
            }
        };

        document.addEventListener('visibilitychange', onVisibilityChange);

        return (): void => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);
};

export default useDocumentVisibilityChange;
