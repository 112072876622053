import * as React from 'react';
import BaseProfileLayout, { ProfileAdditionalTabT } from 'common/layouts/BaseProfileLayout/BaseProfileLayout';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import { Route, Switch } from 'react-router-dom';
import ShipperContractsPage from './ShipperContractsPage/ShipperContractsPage';
import { routesEnum } from 'shipper/constants';

type PropsT = {};

const ADDITIONAL_TABS: Array<ProfileAdditionalTabT> = [];

const ProfilePage: React.FC<PropsT> = React.memo(() => {
    return (
        <>
            <Switch>
                <Route path={routesEnum.shipperContracts}>
                    <ShipperContractsPage />
                </Route>
                <Route>
                    <BaseProfileLayout additionalTabs={ADDITIONAL_TABS} />
                </Route>
            </Switch>
            <SideBars />
        </>
    );
});

export default ProfilePage;
