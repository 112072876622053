import React, { useMemo } from 'react';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyContacts, selectFetchContactsRequest } from 'common/store/members/selectors';
import keyBy from 'lodash/keyBy';
import { fetchContacts } from 'common/store/members/actions';
import { CompanyContactT } from 'common/store/members/models';
import { isNonNil } from 'common/utils';
import UserDropdownOptionLabel from 'common/components/dropdowns/options/UserDropdownOptionLabel/UserDropdownOptionLabel';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { UserStatusEnum } from 'common/utils/api/models';

type ValueT = CarrierContractIdT | null;
type OptionT = CarrierContractIdT;

type ContactsFilterT = (contact: CompanyContactT) => boolean;

export const contactFilters: Record<'availableForMainContact', ContactsFilterT> = {
    availableForMainContact: (member) => {
        const isActiveUser = member.userId && member.userStatus === UserStatusEnum.active;
        if (isActiveUser) {
            return true;
        }

        const isActiveContact = !member.userId;
        if (isActiveContact) {
            return true;
        }

        return false;
    },
} as const;

type PropsT = {
    onChange: (contractId: CarrierContractIdT | null, contractName?: string) => void;
    value: CarrierContractIdT | null;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChangeHighlight?: boolean;
    companyId: CompanyIdT;
    placeholder?: string;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    contactsFilter: ContactsFilterT;
    hasClearControl?: boolean;
};

const ContactDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        onChange,
        value,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        companyId,
        placeholder,
        hasChanges,
        onBlur,
        onFocus,
        contactsFilter,
        hasClearControl,
    } = props;

    const fetchRequest = useSelector(selectFetchContactsRequest(companyId));
    const members = useSelector(selectCompanyContacts(companyId)) || [];

    const allIds = useMemo(() => {
        return members
            .filter(contactsFilter)
            .map((contract) => contract?.id)
            .filter(isNonNil);
    }, [members]);

    const memberById = useMemo(() => {
        return keyBy<CompanyContactT>(members, 'id');
    }, [members]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchContacts(companyId));
    }, []);

    const renderOption = React.useCallback(
        (id: ValueT | null | undefined): React.ReactElement | null => {
            if (!id) {
                return null;
            }

            const member = memberById[id];

            return <UserDropdownOptionLabel label={member?.fullName || id || ''} />;
        },
        [memberById],
    );

    const getOptionValue = React.useCallback((option: OptionT): ValueT => option, []);

    const handleReset = React.useCallback(() => {
        onChange(null);
    }, [onChange]);

    const handleSelect = React.useCallback(
        (contactId: ValueT) => {
            const member = memberById[contactId as ContactIdT];
            onChange(contactId, member?.fullName);
        },
        [memberById],
    );

    return (
        <DropdownInput<OptionT, ValueT>
            testSelector="contact"
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={allIds}
            onSelect={handleSelect}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            hasError={hasError}
            hasWarning={hasWarning}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            onReset={handleReset}
            hasClearControl={hasClearControl}
        />
    );
});

export default ContactDropdown;
