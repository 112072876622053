import * as React from 'react';

type PropsT = {};

const MoreIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill="#3E3F42"
                    d="M5.5 8.5C4.675 8.5 4 9.175 4 10s.675 1.5 1.5 1.5S7 10.825 7 10s-.675-1.5-1.5-1.5zm9 0c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S16 10.825 16 10s-.675-1.5-1.5-1.5zm-4.5 0c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5z"
                />
            </g>
        </svg>
    );
};

MoreIcon.displayName = 'MoreIcon';

const storyProps: PropsT = {};

export { storyProps };
export default MoreIcon;
