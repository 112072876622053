import React from 'react';
import classNames from 'classnames/bind';
import styles from './SideBar.scss';
import {
    CloseCallbackOptionsT,
    CommonSidebarContentPropsT,
    GetSidebarCloseConfirmationT,
    RenderSidebarContentT,
    SidebarContentPropsT,
    SideBarPositionEnum,
} from 'common/layouts/SideBars/models';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import SideBarCloseConfirmation from 'common/layouts/SideBars/SideBarCloseConfirmation/SideBarCloseConfirmation';
import { KeyEnum } from 'common/constants';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import SideBarLayout, { SideBarSizeEnum } from 'common/layouts/SideBars/SideBarLayout/SideBarLayout';
import SideBarLayoutContextWrap from '../../LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';

const cx = classNames.bind(styles);

type PropsT<T> = CommonSidebarContentPropsT<T> & {
    data: T;
    size: SideBarSizeEnum;
    renderSidebarContent: RenderSidebarContentT<T>;
    getSidebarCloseConfirmation: GetSidebarCloseConfirmationT<T>;
};

enum CloseActionEnum {
    close = 'close',
    goBack = 'go-back',
}

type CloseSideBarConfirmationT = {
    type: CloseActionEnum;
};

const SideBar = <T,>(props: PropsT<T>): React.ReactElement | null => {
    const {
        data,
        size,
        position,
        onClose,
        onGoBack,
        onOpenNextSidebar,
        renderSidebarContent,
        getSidebarCloseConfirmation,
    } = props;

    const closeSideBarConfirmation = useModalDialog<CloseSideBarConfirmationT>();

    const handleConfirm = React.useCallback(() => {
        switch (closeSideBarConfirmation.data?.type) {
            case CloseActionEnum.close: {
                if (onClose) {
                    onClose();
                }
                break;
            }
            case CloseActionEnum.goBack: {
                if (onGoBack) {
                    onGoBack();
                }
                break;
            }
            default: {
                // TODO
            }
        }

        closeSideBarConfirmation.setData(null);
    }, [onClose, onGoBack, closeSideBarConfirmation.data, closeSideBarConfirmation.setData]);

    const [needCloseConfirmation, setNeedCloseConfirmation] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (needCloseConfirmation) {
            setNeedCloseConfirmation(false);
        }
    }, [data]);

    const handleClose = React.useCallback(
        (options?: CloseCallbackOptionsT) => {
            const isShowConfirmation = options ? options.needCloseConfirmation : needCloseConfirmation;
            if (isShowConfirmation) {
                closeSideBarConfirmation.setData({ type: CloseActionEnum.close });
            } else if (onClose) {
                onClose();
            }
        },
        [closeSideBarConfirmation.setData, needCloseConfirmation, onClose],
    );

    const handleGoBack = React.useCallback(
        (options?: CloseCallbackOptionsT) => {
            const isShowConfirmation = options ? options.needCloseConfirmation : needCloseConfirmation;
            if (isShowConfirmation) {
                closeSideBarConfirmation.setData({ type: CloseActionEnum.goBack });
            } else if (onGoBack) {
                onGoBack();
            }
        },
        [closeSideBarConfirmation.setData, needCloseConfirmation, onGoBack],
    );

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === KeyEnum.escape) {
                handleClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleOutsideClick = React.useCallback(
        (event: any) => {
            if (position === SideBarPositionEnum.left) {
                const navigationNode = document.querySelector('div[data-dismiss="navigation"]');
                if (navigationNode?.contains(event.target)) {
                    return;
                }
            }

            const modalNode = document.querySelector('div[data-dismiss="modal"]');
            if (modalNode?.contains(event.target)) {
                return;
            }

            handleClose();
        },
        [position, handleClose],
    );

    const commonSidebarContentProps: SidebarContentPropsT<T, T> = {
        onOpenNextSidebar,
        data,
        onClose: handleClose,
        onGoBack: onGoBack ? handleGoBack : null,
        position,
        setNeedCloseConfirmation,
    };

    return (
        <>
            <SideBarLayout position={position} size={size}>
                <OutsideClickHandler onOutsideClick={handleOutsideClick} className={cx('wrapper')}>
                    <SideBarLayoutContextWrap>
                        {renderSidebarContent(commonSidebarContentProps)}
                    </SideBarLayoutContextWrap>
                </OutsideClickHandler>
            </SideBarLayout>
            <SideBarCloseConfirmation
                isShow={closeSideBarConfirmation.isShow}
                onCancel={closeSideBarConfirmation.onCancel}
                onClose={closeSideBarConfirmation.onClose}
                onConfirm={handleConfirm}
                data={data}
                getSidebarCloseConfirmation={getSidebarCloseConfirmation}
            />
        </>
    );
};

export default React.memo(SideBar) as typeof SideBar;
