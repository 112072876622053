import React from 'react';

import classNames from 'classnames/bind';
import styles from './SearchControl.scss';
import Input from 'common/components/Input/Input';
import SearchIcon, { SearchIconProps } from 'common/icons/SearchIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const cx = classNames.bind(styles);

type PropsT = {
    searchText: string | null | undefined;
    placeholder: string;
    onChangeSearchText: (searchText: string) => void;
    className?: string;
};

let timeoutId: NodeJS.Timeout | null = null;

const COLD_TIME = 500;

const SearchControl: React.FC<PropsT> = React.memo((props) => {
    const { searchText, placeholder, onChangeSearchText } = props;

    const [isFocused, setFocused] = React.useState<boolean>(false);
    const [isShow, setShow] = React.useState<boolean>(!!searchText);
    const [text, setText] = React.useState<string>(searchText || '');

    React.useEffect(() => {
        if (searchText !== simpleStringFormatter(text)) {
            setText(searchText || '');

            if (!searchText && !isFocused) {
                setShow(false);
            }
        }
    }, [searchText]);

    const handleChangeText = (text: string): void => {
        setText(text);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            onChangeSearchText(simpleStringFormatter(text));
        }, COLD_TIME);

        // if clear button
        if (!isFocused) {
            setShow(false);
        }
    };

    const handleFocus = (): void => {
        setFocused(true);
    };

    const handleBlur = (): void => {
        setFocused(false);

        const isEmpty = !text;
        if (isEmpty) {
            setShow(false);
        }
    };

    if (!isShow) {
        return (
            <TransparentTrigger
                onClick={() => {
                    setShow(true);
                }}
                leftIcon={<SearchIcon fillColor={StyleGuideColorsEnum.gray} />}
                reflectionTheme={ReflectionThemeEnum.light}
            />
        );
    }

    return (
        <Input
            autoFocus
            name="search-text"
            className={cx('search')}
            renderLeftIcon={(iconMeta) => <SearchIcon {...SearchIconProps.getControlProps(iconMeta)} />}
            placeholder={placeholder}
            value={text}
            onChange={handleChangeText}
            hasClearControl
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
});

export default SearchControl;
