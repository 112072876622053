import * as React from 'react';
import ControlsCell from 'common/components/Table/cell-renderers/ControlsCell/ControlsCell';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import { ContractsActionsT } from '../../../models';
import { useContractsActionOptions } from 'common/layouts/BaseCarrierContractsLayout/hooks/use-contracts-action-options';
import { ContractRowT } from '../../models';

type PropsT = {
    row: ContractRowT;
    className?: string;
} & ContractsActionsT;

const ActionsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, row, onDownloadContract, onRevokeContract, onEditContractDetails } = props;

    const permissions = useSelector(selectPermissions);

    const options = useContractsActionOptions(row, permissions, {
        onDownloadContract,
        onRevokeContract,
        onEditContractDetails,
    });

    return <ControlsCell className={className} options={options} />;
});

export default ActionsCell;
