import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const RestoreIcon: React.FC<PropsT> = (props) => {
    const { strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M10.75 3.25A6.75 6.75 0 004 10H1.75l3 2.992 3-2.992H5.5a5.246 5.246 0 015.25-5.25A5.246 5.246 0 0116 10a5.246 5.246 0 01-5.25 5.25 5.208 5.208 0 01-3.705-1.545L5.98 14.77a6.716 6.716 0 004.77 1.98 6.75 6.75 0 000-13.5zM10 7v3.75l3.188 1.89.577-.96-2.64-1.567V7H10z"
                />
            </g>
        </svg>
    );
};

RestoreIcon.displayName = 'RestoreIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default RestoreIcon;
