import { LocationDescriptor } from 'history';
import { authRoutesEnum } from 'common/constants';
import { getCurrentRelativePath } from 'common/utils/history';

const parseAuthReturnUrl = (returnUrl: string | null | undefined): LocationDescriptor<TODO> => {
    if (!returnUrl) {
        return {
            pathname: authRoutesEnum.home,
        };
    }

    const [pathname, search] = returnUrl.split('?');

    return {
        pathname,
        search: search ? `?${search}` : '',
    };
};

const getAuthReturnUrl = (): string => getCurrentRelativePath(window.location.href);

export { getAuthReturnUrl, parseAuthReturnUrl };
