import * as React from 'react';

import styles from './LineLoader.scss';

import cs from 'classnames';
import classNames from 'classnames/bind';

export type PropsT = {
    className?: string;
    isShow: boolean;
    style?: React.CSSProperties;
};

const cx = classNames.bind(styles);

const LineLoader: React.FC<PropsT> = React.memo((props) => {
    const { isShow, className, style } = props;

    return (
        <div
            className={cs(
                cx('line-loader', {
                    'line-loader--is-show': isShow,
                }),
                className,
            )}
            data-test-selector="line-loader"
            style={style}
        />
    );
});

export default LineLoader;
