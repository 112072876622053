import React from 'react';

import classNames from 'classnames/bind';
import styles from './DictTrailerDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';

import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, TrailerTypeEnum } from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrailersDict, selectTrailersDictRequest } from 'common/store/trailers-dict/selectors';
import { useTranslation } from 'react-i18next';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import flatten from 'lodash/flatten';

enum TrailerIconTypesEnum {
    default = 'default',
}

const cx = classNames.bind(styles);

const ICONS_MAP = {
    [TrailerIconTypesEnum.default]: (
        <TrailerIcon
            fillColor={StyleGuideColorsEnum.brandAccent}
            strokeColor={StyleGuideColorsEnum.brandDark}
            size={DEFAULT_ICON_SIZE}
        />
    ),
};

type OptionValueT = TrailerDictIdT | null;

type PropsT = {
    selectedTrailerType?: TrailerTypeEnum | null;
    placeholder?: string;
    value: OptionValueT;
    isDisabled?: boolean;
    onChange: (value: OptionValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
};

type TrailerDropdownOptionT = {
    value: OptionValueT;
    label: string;
    iconType: TrailerIconTypesEnum;
};

const DictTrailerDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        selectedTrailerType,
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const trailersDict = useSelector(selectTrailersDict);
    const fetchRequest = useSelector(selectTrailersDictRequest);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const rawTrailerOptions = React.useMemo((): ApiTrailerDictTypeT[] => {
        if (!selectedTrailerType) {
            return flatten(Object.values(trailersDict || {}));
        }

        return trailersDict?.[selectedTrailerType as string] || [];
    }, [trailersDict, selectedTrailerType]);

    const trailerOptions = React.useMemo((): TrailerDropdownOptionT[] => {
        return rawTrailerOptions.map((trailer) => ({
            value: String(trailer.id) || null,
            label: t('common:trailer-subtype-template', {
                length: trailer.length,
                model: trailer.model,
                eur1Pallets: trailer.eur1Pallets,
                eur2Pallets: trailer.eur2Pallets,
            }),
            iconType: TrailerIconTypesEnum.default,
        }));
    }, [rawTrailerOptions, t]);

    const renderTrigger = (option: TrailerDropdownOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                {option.label}
            </div>
        );
    };

    const renderOption = (option: TrailerDropdownOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                <div className={cx('option__icon')}>{ICONS_MAP[option.iconType]}</div>
                <div className={cx('option__label')}>{option.label}</div>
            </div>
        );
    };

    const handleSelect = (value: OptionValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: TrailerDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownInput<TrailerDropdownOptionT, OptionValueT>
            selectedValue={value}
            placeholder={placeholder}
            options={trailerOptions}
            onSelect={handleSelect}
            isDisabled={isDisabled || !trailersDict}
            renderOption={renderOption}
            renderTriggerContent={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderLeftIcon={(iconMeta) => <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />}
            renderRightIcon={() =>
                fetchRequest.loading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            testSelector="trailer"
            hasClearControl={hasClearControl}
            onReset={() => {
                onChange(null);
            }}
        />
    );
});

export default DictTrailerDropdown;
