import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './FormTitle.scss';

import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { OMSRoutesEnum, StyleGuideColorsEnum } from 'common/constants';
import CloseIcon from 'common/icons/CloseIcon';
import { useContext } from 'react';
import OrderCreationPercentContext from 'common/layouts/NewOrderPage/contexts/order-creation-percent-context';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
};

const STEPS_COUNT = 3;

const FormTitle: React.FC<PropsT> = React.memo((props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const orderCreationPercentContext = useContext(OrderCreationPercentContext);
    const { percent, setPercent } = orderCreationPercentContext;

    const { pathname } = useLocation();

    React.useEffect(() => {
        setPercent(0);
    }, [pathname]);

    let isFinalStep = false;

    let basePercent = 0;
    const step = 100 / STEPS_COUNT;
    if (matchPath(pathname, { path: OMSRoutesEnum.newOrder })) {
        basePercent = 0;
    }
    if (matchPath(pathname, { path: OMSRoutesEnum.newOrderOffers })) {
        basePercent = step;
    }
    if (matchPath(pathname, { path: OMSRoutesEnum.newOrderDetails })) {
        basePercent = 2 * step;
        isFinalStep = true;
    }
    if (matchPath(pathname, { path: OMSRoutesEnum.newOrderDetailsWithLane })) {
        basePercent = 2 * step;
        isFinalStep = true;
    }

    const summaryPercent = basePercent + (percent / 100) * step;
    const roundedPercent = Math.round(summaryPercent);

    const title = isFinalStep
        ? t('common:new-order-page.titles.new-order')
        : t('common:new-order-page.titles.new-quotation');

    return (
        <div className={cx('content')}>
            <div className={cx('column', 'column--isLeft')}>
                <div className={cx('title')}>{title}</div>
            </div>
            <div className={cx('column', 'column--isRight')}>
                <div className={cx('close')} onClick={onClose}>
                    <CloseIcon fillColor={StyleGuideColorsEnum.charcoal} />
                </div>
            </div>
            <div className={cx('progress')}>
                <div className={cx('progress__title')}>
                    {t('common:new-order-page.percent', {
                        percent: roundedPercent,
                    })}
                </div>
                <div className={cx('progress__bar')} style={{ width: `${roundedPercent}%` }} />
            </div>
        </div>
    );
});

export default FormTitle;
