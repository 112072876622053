import { ADD_ALERT, AlertsActionT, AlertsStateT, DELETE_ALERT } from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: AlertsStateT = {
    byId: {},
    ids: [],
};

export default (state = initialState, action: AlertsActionT | DestroySessionActionT): AlertsStateT => {
    switch (action.type) {
        case ADD_ALERT: {
            const { anyAlert } = action;

            const { id } = anyAlert;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: anyAlert,
                },
                ids: [...state.ids, id],
            };
        }

        case DELETE_ALERT: {
            const { alertId } = action;

            const newById = {
                ...state.byId,
            };

            delete newById[alertId];

            const newIds = state.ids.filter((id) => id !== alertId);

            return {
                ...state,
                byId: newById,
                ids: newIds,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
