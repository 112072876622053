import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import values from 'lodash/values';

const REQUIRED_FIELDS = values<FieldsEnum>([FieldsEnum.taxId, FieldsEnum.iban, FieldsEnum.bic]);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    return errors;
};

export default validate;
