import createBrowserHistory from 'history/createBrowserHistory';
// import createHashHistory from 'history/createHashHistory';
import { SidebarQueryKeysEnum } from 'common/layouts/SideBars/models';
import qs from 'qs';

const customHistory = createBrowserHistory<HistoryStateT>();
export default customHistory;

type OptionsT = {
    shouldCloseSidebars?: boolean;
};

export const goBackIfHasRePath = (callback: () => void, options?: OptionsT) => {
    if (customHistory.location.state?.rePath) {
        let rePath = customHistory.location.state?.rePath;

        if (options) {
            const url = new URL(window.location.origin + rePath);

            const query = qs.parse(url.search.slice(1));

            if (options?.shouldCloseSidebars) {
                delete query[SidebarQueryKeysEnum.rightSidebar];
                delete query[SidebarQueryKeysEnum.leftSidebar];
            }
            rePath = [url.pathname, `${query ? `?` : ''}${qs.stringify(query)}`, url.hash ? `#${url.hash}` : '']
                .filter(Boolean)
                .join('');
        }

        customHistory.push(rePath);
        return;
    }

    callback();
};

export const goToPossiblyExternalUrl = (url: string | object) => {
    if (typeof url === 'string' && url.startsWith('http')) {
        window.location.href = url;
        return;
    }

    customHistory.push(url);
};

const ROOT_BASEPATH = ''; // or '/#'

export const createSelfExternalUrl = (relativePath: string) => {
    return `${window.location.origin}${ROOT_BASEPATH}${relativePath}`;
};

export const getCurrentRelativePath = (url: string): string => {
    return url.replace(`${window.location.origin}${ROOT_BASEPATH}`, '');
};

export const createExternalRelativePath = (restUrl: string): string => {
    return `${ROOT_BASEPATH}${restUrl}`;
};
