import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkEmail, checkNotEmpty } from 'common/utils/form-validators';

const ALL_FIELDS = [FieldsEnum.email] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, ALL_FIELDS, values),
        ...checkEmail(t, ALL_FIELDS, values),
    };

    return errors;
};

export default validate;
