import * as React from 'react';
import { ApiInvoiceStatusT, InvoiceStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiInvoiceStatusT | null | undefined;
};

export const STATUS_T_MAP: Record<ApiInvoiceStatusT, string> = {
    [InvoiceStatusEnum.draft]: 'common:invoice-status.DRAFT',
    [InvoiceStatusEnum.open]: 'common:invoice-status.OPEN',
    [InvoiceStatusEnum.void]: 'common:invoice-status.VOID',
    [InvoiceStatusEnum.paid]: 'common:invoice-status.PAID',
    [InvoiceStatusEnum.uncollectible]: 'common:invoice-status.UNCOLLECTIBLE',
};

const InvoiceStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(STATUS_T_MAP[status]);
});

export default InvoiceStatus;
