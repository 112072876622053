import { ApiDocumentT, DocumentStatusEnum, DocumentTypeEnum } from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';
import { DocumentT } from '../models';

const prepareDocument = (apiDocument: ApiDocumentT): DocumentT => {
    if (!apiDocument.id) {
        logWarning('document without id!');
    }

    if (!apiDocument.status) {
        logWarning('document without status!');
    }

    return {
        ...apiDocument,
        id: apiDocument.id || '-',
        dictDocumentId: String(apiDocument.dictDocumentId),
        documentType: apiDocument.documentType as DocumentTypeEnum,
        status: apiDocument.status as DocumentStatusEnum,
    };
};

export default prepareDocument;
