import React from 'react';

import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import TrailerDetails from './TrailerDetails/TrailerDetails';
import { selectFetchTrailerDetailsRequestStatus, selectTrailersDetailsByIds } from 'common/store/trailers/selectors';
import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { fetchTrailerDetails } from 'common/store/trailers/actions';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import ParanjaSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/ParanjaSideBarLayout/ParanjaSideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import SetUnavailableFooterContent from 'common/components/AssetSidebarContent/SetUnavailableFooterContent/SetUnavailableFooterContent';
import { AssetTypeEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import CircleIcon from 'common/icons/CircleIcon';
import MapIcon from 'common/icons/MapIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { SetUnavailableVehicleQueryT, TrailerStatusEnum } from 'common/utils/api/models';
import DeleteIcon from 'common/icons/DeleteIcon';
import { selectSetUnavailableVehicleRequestStatus } from 'common/store/vehicle-schedules/selectors';
import EditIcon from 'common/icons/EditIcon';
import TrailerStatusPill from 'common/components/status-pill/TrailerStatusPill/TrailerStatusPill';

type PropsT = {
    trailerId: TrailerIdT | null;
    partnerId: PartnerIdT;
    onGoBack?: () => void;
    onClose?: () => void;
    onEdit: () => void;
    onActivate: (trailerId: TrailerIdT | null) => void;
    onArchive: (trailerId: TrailerIdT | null) => void;
    onSetUnavailable: (trailerId: TrailerIdT, query: SetUnavailableVehicleQueryT) => void;
    isShowMap?: boolean;
    onToggleShowMap?: () => void;
    canEditTrailers: boolean;
    isReadOnly?: boolean;
    isDisableShowOnMap?: boolean;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenContactDetails: (contractId: CarrierContractIdT) => void;
};

const TrailerDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const {
        isReadOnly,
        isDisableShowOnMap,
        onEdit,
        onGoBack,
        onClose,
        isShowMap,
        onToggleShowMap,
        trailerId,
        canEditTrailers,
        onActivate,
        onArchive,
        onSetUnavailable,
        partnerId,
        onOpenTruckDetails,
        onOpenContactDetails,
    } = props;

    const { t } = useTranslation();

    const fetchDetailsRequestStatus = useSelector(selectFetchTrailerDetailsRequestStatus(partnerId));
    const detailsById = useSelector(selectTrailersDetailsByIds(partnerId));

    const trailerDetails = detailsById[trailerId as string];

    const [isShowUnavailableForm, setIsShowUnavailableForm] = React.useState<boolean>(false);

    const status = trailerDetails?.status;
    const options: Array<DropdownControlOptionT | SpecialOptionEnum | null> = React.useMemo(() => {
        if (isReadOnly) {
            return [];
        }

        if (status === TrailerStatusEnum.archived) {
            return [
                {
                    label: (
                        <DropdownControlOptionLabel
                            label={t('common:trailers-page.actions.activate')}
                            icon={<CircleIcon borderColor={StyleGuideColorsEnum.brandAccent} />}
                        />
                    ),
                    onSelect: () => {
                        onActivate(trailerId);
                    },
                },
            ];
        }

        return [
            canEditTrailers
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              label={t('common:trailers-page.actions.edit')}
                              icon={<EditIcon />}
                          />
                      ),
                      onSelect: () => {
                          onEdit();
                      },
                  }
                : null,
            canEditTrailers ? SpecialOptionEnum.separator : null,
            {
                label: (
                    <DropdownControlOptionLabel
                        label={t('common:trailers-page.actions.set-unavailable')}
                        icon={<CircleIcon borderColor={StyleGuideColorsEnum.tomatoRed} />}
                    />
                ),
                onSelect: () => {
                    setIsShowUnavailableForm(true);
                },
            },
            {
                label: (
                    <DropdownControlOptionLabel
                        label={t('common:trailers-page.actions.archive')}
                        icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                    />
                ),
                onSelect: () => {
                    onArchive(trailerId);
                },
            },
        ];
    }, [trailerId, setIsShowUnavailableForm, status, onEdit, onActivate, onArchive, canEditTrailers, isReadOnly, t]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!trailerId) {
            return;
        }

        dispatch(fetchTrailerDetails(trailerId, partnerId));
    }, []);

    const setUnavailableStatus = useSelector(selectSetUnavailableVehicleRequestStatus);
    React.useEffect(() => {
        if (setUnavailableStatus.ok) {
            setIsShowUnavailableForm(false);
        }
    }, [setUnavailableStatus.ok]);

    let footerNode: React.ReactNode = null;

    if (isShowUnavailableForm) {
        footerNode = (
            <>
                <ParanjaSideBarLayout />
                <FooterSideBarLayout isTransparent hasShadow>
                    <SetUnavailableFooterContent
                        assetId={trailerId}
                        onClose={() => {
                            setIsShowUnavailableForm(false);
                        }}
                        isLoading={setUnavailableStatus.loading}
                        onSetUnavailable={onSetUnavailable}
                        assetType={AssetTypeEnum.trailer}
                    />
                </FooterSideBarLayout>
            </>
        );
    }

    return (
        <>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={trailerDetails?.plateNumber || ''}
                    afterTitleNode={<TrailerStatusPill isSymmetrical status={trailerDetails?.status || null} />}
                    dropdownOptions={options}
                    onGoBack={onGoBack}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                    afterDropdownNode={
                        onToggleShowMap && !isDisableShowOnMap ? (
                            <TransparentTrigger
                                onClick={() => {
                                    if (onToggleShowMap) {
                                        onToggleShowMap();
                                    }
                                }}
                                isPressed={isShowMap}
                                leftIcon={
                                    <MapIcon
                                        size={DEFAULT_ICON_SIZE}
                                        fillColor={StyleGuideColorsEnum.gray}
                                        strokeColor={StyleGuideColorsEnum.slate}
                                    />
                                }
                                reflectionTheme={ReflectionThemeEnum.light}
                            />
                        ) : null
                    }
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <TrailerDetails
                    onOpenTruckDetails={onOpenTruckDetails}
                    onOpenContactDetails={onOpenContactDetails}
                    partnerId={partnerId}
                    isLoading={fetchDetailsRequestStatus.loading}
                    trailerDetails={trailerDetails}
                />
            </SideBarLayout>
            {footerNode}
        </>
    );
};

export default TrailerDetailsSidebarContent;
