import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';

export enum FieldsEnum {
    password = 'password',
}

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.password]:
        | AuthErrorTypeEnum.failUpdatePassword
        | AuthErrorTypeEnum.failReauthenticateWithCredential
        | AuthErrorTypeEnum.expiredActionCode
        | AuthErrorTypeEnum.invalidActionCode
        | AuthErrorTypeEnum.weakPassword
        | AuthErrorTypeEnum.userNotFound
        | AuthErrorTypeEnum.userDisabled;
}>;

export type FormValuesT = {
    [FieldsEnum.password]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
