import {
    FetchTrailersDictActionT,
    FetchTrailersDictBeginActionT,
    FetchTrailersDictErrorActionT,
    FetchTrailersDictSuccessActionT,
    TRAILERS_DICT_REQUEST,
    TRAILERS_DICT_REQUEST_BEGIN,
    TRAILERS_DICT_REQUEST_ERROR,
    TRAILERS_DICT_REQUEST_SUCCESS,
} from './types';
import { TrailersDictT } from './models';

export const fetchTrailersDict = (): FetchTrailersDictActionT => ({
    type: TRAILERS_DICT_REQUEST,
});

export const fetchTrailersDictBegin = (): FetchTrailersDictBeginActionT => ({
    type: TRAILERS_DICT_REQUEST_BEGIN,
});

export const fetchTrailersDictSuccess = (trailersDict: TrailersDictT): FetchTrailersDictSuccessActionT => ({
    type: TRAILERS_DICT_REQUEST_SUCCESS,
    trailersDict,
});

export const fetchTrailersDictError = (error: Error): FetchTrailersDictErrorActionT => ({
    type: TRAILERS_DICT_REQUEST_ERROR,
    error,
});
