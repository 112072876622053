import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_ORDER_REQUEST, FetchOrderRequestActionT } from './types';
import { fetchOrderBegin, fetchOrderError, fetchOrderSuccess } from './actions';
import { selectOrderRequestStatus } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';

function* fetchOrderSaga(action: FetchOrderRequestActionT): WrapGeneratorT<void> {
    const { id, options } = action;

    const requestStatus: ReReturnT<typeof selectOrderRequestStatus> = yield select(selectOrderRequestStatus(id));
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchOrderBegin(id));

    const [error, order]: ReturnApiT<typeof shipperTranziitApi.getOrderDetails> =
        yield shipperTranziitApi.getOrderDetails(id);
    if (error) {
        yield put(fetchOrderError(id, error));
    } else {
        yield put(fetchOrderSuccess(id, order));
    }
}

function* orderDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_ORDER_REQUEST, fetchOrderSaga);
}

export default orderDetailsSaga;
