import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RoutingGeometrySliceT, RoutingGeometryStateT } from './types';
import requestStatus from 'common/utils/request-status';

export const initialRoutingGeometryState: RoutingGeometrySliceT = {
    data: [],
    requestStatus: requestStatus.initial,
};

const initialState: RoutingGeometryStateT = {};

export const routingGeometrySlice = createSlice({
    name: 'routingGeometry',
    initialState,
    reducers: {
        fetchRoutesPlainGeometry: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                polylineIds: Array<PolylineIdT | null>;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchRoutePlainGeometry: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                polylineId: PolylineIdT;
                options?: FetchOptionsT;
            }>,
        ) => {
            // nothing
        },
        fetchRouteGeometryBegin: (
            state,
            action: PayloadAction<{
                polylineId: PolylineIdT;
            }>,
        ) => {
            const { polylineId } = action.payload;
            const routingGeometryState = state[polylineId] || initialRoutingGeometryState;

            state[polylineId] = {
                ...routingGeometryState,
                requestStatus: requestStatus.loading,
            };
        },
        fetchRouteGeometrySuccess: (
            state,
            action: PayloadAction<{
                polylineId: PolylineIdT;
                data: Array<GooglePolylineT>;
            }>,
        ) => {
            const { polylineId, data } = action.payload;
            const routingGeometryState = state[polylineId] || initialRoutingGeometryState;

            state[polylineId] = {
                ...routingGeometryState,
                requestStatus: requestStatus.ok,
                data,
            };
        },
        fetchRouteGeometryError: (
            state,
            action: PayloadAction<{
                polylineId: PolylineIdT;
                error: Error;
            }>,
        ) => {
            const { polylineId, error } = action.payload;
            const routingGeometryState = state[polylineId] || initialRoutingGeometryState;

            state[polylineId] = {
                ...routingGeometryState,
                requestStatus: requestStatus.setError(error),
            };
        },
        resetRouteGeometry: (
            state,
            action: PayloadAction<{
                polylineIds: Array<PolylineIdT | null>;
            }>,
        ) => {
            const { polylineIds } = action.payload;

            polylineIds.forEach((polylineId) => {
                if (polylineId) {
                    delete state[polylineId];
                }
            });
        },
    },
});

export const {
    fetchRoutesPlainGeometry,
    fetchRoutePlainGeometry,
    fetchRouteGeometryBegin,
    fetchRouteGeometrySuccess,
    fetchRouteGeometryError,
    resetRouteGeometry,
} = routingGeometrySlice.actions;

export default routingGeometrySlice.reducer;
