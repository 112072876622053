import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, MAX_RADIUS_KM, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import {
    checkIsPositiveNumberValue,
    checkMaxValue,
    checkNotEmpty,
    checkRoundValue,
} from 'common/utils/form-validators';
import { getDayDiff } from 'common/utils/time';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(
            t,
            [
                FieldsEnum.pickUpLocationRadius,

                FieldsEnum.dropOffLocationRadius,

                FieldsEnum.validFrom,
                FieldsEnum.validTill,

                FieldsEnum.layoverLimit,
            ],
            values,
        ),
        ...checkIsPositiveNumberValue(
            t,
            [FieldsEnum.pickUpLocationRadius, FieldsEnum.dropOffLocationRadius, FieldsEnum.layoverLimit],
            values,
        ),
        ...checkMaxValue(t, [FieldsEnum.pickUpLocationRadius, FieldsEnum.dropOffLocationRadius], values, MAX_RADIUS_KM),
        ...checkMaxValue(t, [FieldsEnum.layoverLimit], values, 48),
        ...checkRoundValue(t, [FieldsEnum.layoverLimit], values),
    };

    const validFrom = values[FieldsEnum.validFrom];
    const validTill = values[FieldsEnum.validTill];

    if (validFrom && validTill && getDayDiff(validFrom, validTill) < MIN_VALID_FROM_VALID_TILL_DAYS) {
        errors[FieldsEnum.validTill] = t('common:shipper-lane-details.errors.valid-till-inteval-valid-from', {
            count: MIN_VALID_FROM_VALID_TILL_DAYS,
        });
    }

    return errors;
};

export default validate;
