import { LegalFormsListT, LegalFormT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { CountryCodeT } from 'common/store/countries-dict/models';

export const LEGAL_FORMS_DICT_REQUEST = 'common/legal-forms-dict/LEGAL_FORMS_DICT_REQUEST';
export const LEGAL_FORMS_DICT_REQUEST_BEGIN = 'common/legal-forms-dict/LEGAL_FORMS_DICT_REQUEST_BEGIN';
export const LEGAL_FORMS_DICT_REQUEST_SUCCESS = 'common/legal-forms-dict/LEGAL_FORMS_DICT_REQUEST_SUCCESS';
export const LEGAL_FORMS_DICT_REQUEST_ERROR = 'common/legal-forms-dict/LEGAL_FORMS_DICT_REQUEST_ERROR';

export type FetchLegalFormsDictActionT = {
    type: typeof LEGAL_FORMS_DICT_REQUEST;
    countryCode: CountryCodeT;
};

export type FetchLegalFormsDictBeginActionT = {
    type: typeof LEGAL_FORMS_DICT_REQUEST_BEGIN;
    countryCode: CountryCodeT;
};

export type FetchLegalFormsDictSuccessActionT = {
    type: typeof LEGAL_FORMS_DICT_REQUEST_SUCCESS;
    countryCode: CountryCodeT;
    legalFormsList: LegalFormsListT;
};

export type FetchLegalFormsDictErrorActionT = {
    type: typeof LEGAL_FORMS_DICT_REQUEST_ERROR;
    countryCode: CountryCodeT;
    error: Error;
};

export type LegalFormsDictActionT =
    | FetchLegalFormsDictActionT
    | FetchLegalFormsDictBeginActionT
    | FetchLegalFormsDictSuccessActionT
    | FetchLegalFormsDictErrorActionT;

export type CountryLegalFormsDictStateT = {
    allIds: Array<LegalFormT['id']>;
    byId: Record<string, LegalFormT>;
    request: RequestStatusT;
};

export type LegalFormsDictStateT = Partial<Record<CountryCodeT, CountryLegalFormsDictStateT>>;
