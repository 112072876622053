import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const getMoneyMask = (): MaskT => {
    return createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ' ',
        allowDecimal: true,
    });
};

export const getPercentDiffMask = (): MaskT => {
    return [/[+-]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/];
};

export const getMoneyDiffMask = (): MaskT => {
    return [/[+-]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/];
};

export const getDistanceMask = (): MaskT => {
    return createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ' ',
    });
};

export const getWeightMask = (): MaskT => {
    return createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ' ',
        allowDecimal: true,
        decimalLimit: 3,
    });
};

export const getDateMask = (): MaskT => {
    return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
};

export const getDateRangeMask = (): MaskT => {
    return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        '-',
        ' ',
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];
};
