import * as React from 'react';

const useModalDialog = <T extends {}>() => {
    const [data, setData] = React.useState<T | null>(null);

    return {
        isShow: !!data,
        data,
        setData,
        onClose: () => {
            setData(null);
        },
        onCancel: () => {
            setData(null);
        },
    };
};

export default useModalDialog;
