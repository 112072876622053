import { actionChannel, take } from 'redux-saga/effects';
import { buffers } from 'redux-saga';

function* semaphoreSaga<ActionT extends { type: string }>(
    actionType: ActionT['type'],
    handleSaga: (action: ActionT) => WrapGeneratorT<void>,
): WrapGeneratorT<void> {
    const channel = yield actionChannel(actionType, buffers.expanding(10));
    while (true) {
        const action: ActionT = yield take(channel);
        yield handleSaga(action);
    }
}

export { semaphoreSaga };
