import * as React from 'react';
import { ApiCarrierContractStatusT, CarrierContractStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import { useSelector } from 'react-redux';
import { selectProfileType } from 'common/store/auth/selectors';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import CarrierContractStatus from 'common/components/status/CarrierContractStatus/CarrierContractStatus';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiCarrierContractStatusT | null | undefined;
    isSymmetrical?: boolean;
};

type ContractStatusColorMapT = Record<CarrierContractStatusEnum, PillLabelThemeEnum>;

const statusColorMap: Record<AuthProfileTypeEnum, ContractStatusColorMapT> = {
    [AuthProfileTypeEnum.shipper]: {
        [CarrierContractStatusEnum.waitingForApprove]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.approved]: PillLabelThemeEnum.brandAccent,
        [CarrierContractStatusEnum.rejected]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.revoked]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.expired]: PillLabelThemeEnum.slate,
    },
    [AuthProfileTypeEnum.carrier]: {
        [CarrierContractStatusEnum.waitingForApprove]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.approved]: PillLabelThemeEnum.brandAccent,
        [CarrierContractStatusEnum.rejected]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.revoked]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.expired]: PillLabelThemeEnum.slate,
    },
    [AuthProfileTypeEnum.broker]: {
        [CarrierContractStatusEnum.waitingForApprove]: PillLabelThemeEnum.orange,
        [CarrierContractStatusEnum.approved]: PillLabelThemeEnum.brandAccent,
        [CarrierContractStatusEnum.rejected]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.revoked]: PillLabelThemeEnum.slate,
        [CarrierContractStatusEnum.expired]: PillLabelThemeEnum.slate,
    },
};

const CarrierContractStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    const profileTypeEnum = useSelector(selectProfileType);

    let theme: PillLabelThemeEnum;

    // not upload
    if (!status) {
        theme = PillLabelThemeEnum.slate;
    } else {
        theme = statusColorMap[profileTypeEnum]?.[status];
    }

    return (
        <PillLabel isNoWrap {...restProps} theme={theme}>
            <CarrierContractStatus status={status} />
        </PillLabel>
    );
});

export default CarrierContractStatusPill;
