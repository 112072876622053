import * as React from 'react';
import { NavigationTabConfigT } from 'common/components/NavigationTabs/NavigationTabs';
import { ShipperContractRoutesEnum } from 'common/constants';
import { urlFactory } from 'common/utils/urls';
import { useTranslation } from 'react-i18next';

export const useShipperContractTabsConfig = (params: { contractId: ShipperContractIdT }) => {
    const { t } = useTranslation();

    return React.useMemo((): Array<NavigationTabConfigT | null> => {
        const tabs: Array<NavigationTabConfigT> = [
            {
                pathname: ShipperContractRoutesEnum.shipperContractLanes,
                to: urlFactory.shipperContractLanes({
                    shipperContractId: params.contractId || '-',
                }),
                badge: null,
                render: () => t('common:profile-page.documents-tabs.shipper-contract-lanes'),
                testSelector: 'shipper-contract-lanes',
            },
            {
                pathname: ShipperContractRoutesEnum.shipperContractDetails,
                to: urlFactory.shipperContractDetails({
                    shipperContractId: params.contractId || '-',
                }),
                badge: null,
                render: () => t('common:profile-page.documents-tabs.shipper-contract-details'),
                testSelector: 'shipper-contract-details',
            },
        ];

        return tabs;
    }, [params, t]);
};
