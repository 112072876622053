import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkEmail, checkMaxLength, checkMinLength, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.email, FieldsEnum.password];

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinLength(t, [FieldsEnum.password], values, 6),
        ...checkMaxLength(t, [FieldsEnum.password], values, 255),
        ...checkEmail(t, [FieldsEnum.email], values),
    };

    return errors;
};

export default validate;
