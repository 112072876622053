import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ModalDialog.scss';
import { ButtonPropsT } from 'common/components/Button/Button';
import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';

const cx = classNames.bind(styles);

export type ModalDialogActionT = ButtonPropsT & { children: React.ReactNode };

export type ModalDialogPropsT = {
    width: number;
    testSelector?: string;
    actions?: ModalDialogActionT[];
    icon: React.ReactNode;
    title: React.ReactNode;
    message?: React.ReactNode;
    body?: React.ReactNode;
    className?: string;
};

export const DEFAULT_MODAL_DIALOG_WIDTH = 340;

const ModalDialog: React.FC<ModalDialogPropsT> = (props) => {
    const { testSelector, width, body, actions, icon, title, message, children, className } = props;

    const fullTestSelector = `${testSelector}_modal-dialog`;

    return (
        <div
            className={cs(
                cx('wrap', {
                    'wrap--hasTopPadding': !!actions?.length,
                }),
                className,
            )}
            data-test-selector={fullTestSelector}
            style={{
                width: `${width}px`,
            }}
        >
            <div className={cx('content')}>
                <div className={cx('icon')}>{icon}</div>
                <div className={cx('title')}>{title}</div>
                {!!message && <div className={cx('message')}>{message}</div>}
                {body}
            </div>
            <ModalDialogActions actions={actions} />
            {children}
        </div>
    );
};

export default ModalDialog;
