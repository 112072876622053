import React from 'react';
import { DocumentTypeEnum } from 'common/utils/api/models';
import CommonDocumentParamsDetails from './CommonDocumentParamsDetails/CommonDocumentParamsDetails';
import EvidenceOfTaxDetails from './EvidenceOfTaxDetails/EvidenceOfTaxDetails';
import { DocumentVersionT } from 'common/store/documents/models';
import { DictDocumentT } from 'common/store/documents-dict/models';

type PropsT = {
    dictDocument: DictDocumentT | null;
    actualVersion: DocumentVersionT | null;
};

const DocumentParamsDetails: React.FC<PropsT> = (props) => {
    const { actualVersion, dictDocument } = props;

    switch (dictDocument?.type) {
        case DocumentTypeEnum.evidenceOfTaxVat: {
            return <EvidenceOfTaxDetails actualVersion={actualVersion} />;
        }
        default: {
            return <CommonDocumentParamsDetails actualVersion={actualVersion} />;
        }
    }
};

export default DocumentParamsDetails;
