import { put, takeEvery } from 'redux-saga/effects';
import { checkError, extractErrorFromAction } from 'common/store/errors/utils';
import { AnyAction } from 'redux';
import { reAuthSaga } from 'common/store/auth/sagas';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import {
    ApiRequestErrorTypeEnum,
    checkIsApiRequestError,
    checkIsUnauthorizedError,
} from 'common/utils/api/errors/api-request-error';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { AuthErrorTypeEnum, checkIsAuthApiError } from 'common/utils/api/auth/errors/auth-api-error';

function* anyActionSaga(action: AnyAction): WrapGeneratorT<void> {
    const isErrorAction = checkError(action.type);
    if (!isErrorAction) {
        return;
    }

    const actionError = extractErrorFromAction(action);

    if (
        (checkIsAuthApiError(actionError) && actionError?.type === AuthErrorTypeEnum.networkRequestFailed) ||
        (checkIsApiRequestError(actionError) && actionError?.type === ApiRequestErrorTypeEnum.networkError)
    ) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.networkError,
                    data: {},
                }),
            ),
        );
        return;
    }

    if (
        checkIsUnauthorizedError(actionError) ||
        (checkIsTranziitApiRequestError(actionError) &&
            (actionError?.subType === TranziitApiRequestErrorSubTypeEnum.notAuthorizedUser ||
                actionError?.subType === TranziitApiRequestErrorSubTypeEnum.notFoundUser ||
                actionError?.subType === TranziitApiRequestErrorSubTypeEnum.notFoundCompany))
    ) {
        yield reAuthSaga();
    }
}

function* errorsSaga(): WrapGeneratorT<void> {
    yield takeEvery('*', anyActionSaga);
}

export default errorsSaga;
