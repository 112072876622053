import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Stepper.scss';
import cs from 'classnames';

import noop from 'lodash/noop';
import BackButton from 'common/components/BackButton/BackButton';

const cx = classNames.bind(styles);

export type StepT = {
    label: string;
};

type PropsT = {
    className?: string;
    activeStepIndex: number;
    goTo: (stepIndex: number) => void;
    steps: StepT[];
    testSelector?: string;
    isDisabledBack?: boolean;
    initShowStepIndex: number;
};

const Stepper: React.FC<PropsT> = (props) => {
    const { className, activeStepIndex, goTo, steps, initShowStepIndex, testSelector } = props;

    const goBack = () => goTo(activeStepIndex - 1);

    const isDisabledBack = activeStepIndex === 0 || !!props.isDisabledBack;

    if (!steps.length) {
        return null;
    }

    const fullTestSelector = `${testSelector}_stepper`;

    return (
        <div className={cs(cx('stepper'), className)} data-test-selector={fullTestSelector}>
            <BackButton className={cx('back-button')} onClick={goBack} isDisabled={isDisabledBack} />
            {steps.map((step, stepIndex) => {
                if (stepIndex < initShowStepIndex) {
                    return null;
                }

                const isPrevStep = stepIndex < activeStepIndex;
                const isSelectable = isPrevStep;
                const isActive = stepIndex === activeStepIndex;
                const number = stepIndex + 1;

                return (
                    <div
                        key={stepIndex}
                        className={cx('step', {
                            'step--isSelectable': isSelectable,
                        })}
                        data-test-selector={`${fullTestSelector}_step_${stepIndex}`}
                        onClick={isSelectable ? () => goTo(stepIndex) : noop}
                    >
                        <div
                            className={cx('step__number', {
                                'step__number--isActive': isActive,
                                'step__number--isPrevStep': isPrevStep,
                            })}
                        >
                            {isPrevStep ? null : number}
                        </div>
                        <div className={cx('step__label', { 'step__label--isActive': isActive })}>{step.label}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Stepper;
