import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const CommentIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M16 13.877V4H4v9h11.123l.877.877zM14.5 11.5h-9V10h9v1.5zm0-2.25h-9v-1.5h9v1.5zm0-2.25h-9V5.5h9V7z"
                />
                <path
                    fill={strokeColor}
                    d="M4 14.5h10.5l3 3L17.492 4c0-.825-.667-1.5-1.492-1.5H4c-.825 0-1.5.675-1.5 1.5v9c0 .825.675 1.5 1.5 1.5zM4 4h12v9.877L15.123 13H4V4zm1.5 6h9v1.5h-9V10zm0-2.25h9v1.5h-9v-1.5zm0-2.25h9V7h-9V5.5z"
                />
            </g>
        </svg>
    );
};

CommentIcon.displayName = 'CommentIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default CommentIcon;
