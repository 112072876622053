import React from 'react';

export type AssetServiceLocationContextT = {
    location: LocationT | null;
    setLocation: (location: LocationT | null) => void;
};

const AssetServiceLocationContext = React.createContext<AssetServiceLocationContextT>({
    location: null,
    setLocation: () => {
        // nothing
    },
});

export default AssetServiceLocationContext;
