import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    fillOpacity?: number;
    strokeColor: StyleGuideColorsEnum;
};

const ThumbUpIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, fillOpacity, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" opacity="0.87" />
                <path
                    fill={fillColor}
                    fillOpacity={fillOpacity || '0.3'}
                    d="M11.005 4.495L7.75 7.75v7.5h6.75L16.75 10V8.5h-6.578z"
                />
                <path
                    fill={strokeColor}
                    d="M16.75 7h-4.732l.712-3.428.022-.24c0-.307-.127-.592-.33-.794l-.795-.788-4.935 4.942A1.47 1.47 0 006.25 7.75v7.5c0 .825.675 1.5 1.5 1.5h6.75a1.49 1.49 0 001.38-.915l2.265-5.288c.067-.172.105-.352.105-.547V8.5c0-.825-.675-1.5-1.5-1.5zm0 3l-2.25 5.25H7.75v-7.5l3.255-3.255-.833 4.005h6.578V10zm-15-2.25h3v9h-3v-9z"
                />
            </g>
        </svg>
    );
};

ThumbUpIcon.displayName = 'ThumbUpIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default ThumbUpIcon;
