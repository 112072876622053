import * as React from 'react';
import { TemperatureModeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { parseTemperatureMode } from 'common/utils/parse-temperature-mode';

type PropsT = {
    className?: string;
    lowestTemperature?: number;
    highestTemperature?: number;
};

export const TEMPERATURE_MODE_T: Record<TemperatureModeEnum, string> = {
    [TemperatureModeEnum.deepFrozen]: 'common:new-order-form.fields.temperature-range.presets.deep-frozen',
    [TemperatureModeEnum.ultraFresh]: 'common:new-order-form.fields.temperature-range.presets.ultra-fresh',
    [TemperatureModeEnum.dry]: 'common:new-order-form.fields.temperature-range.presets.dry',
    [TemperatureModeEnum.chilled]: 'common:new-order-form.fields.temperature-range.presets.chilled',
    [TemperatureModeEnum.ambient]: 'common:new-order-form.fields.temperature-range.presets.ambient',
};

const TemperatureRange: React.FC<PropsT> = React.memo((props) => {
    const { lowestTemperature, highestTemperature } = props;

    const { t } = useTranslation();

    if (isNil(lowestTemperature) || isNil(highestTemperature)) {
        return null;
    }

    const temperatureMode = parseTemperatureMode(lowestTemperature, highestTemperature);

    if (!temperatureMode) {
        return <span>{`${lowestTemperature}...${highestTemperature}°C`}</span>;
    }

    return (
        <span>
            {t(TEMPERATURE_MODE_T[temperatureMode], {
                minTemperature: lowestTemperature,
                maxTemperature: highestTemperature,
            })}
        </span>
    );
});

export default TemperatureRange;
