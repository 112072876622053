export enum FieldsEnum {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    password = 'password',
    acceptTerms = 'acceptTerms',
}

export type FormValuesT = {
    [FieldsEnum.firstName]: string;
    [FieldsEnum.lastName]: string;
    [FieldsEnum.email]: string;
    [FieldsEnum.phone]: string;
    [FieldsEnum.password]: string;
    [FieldsEnum.acceptTerms]: boolean;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
