import React from 'react';
import { SyncShipmentDetailsFormMapStateContextValueT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/usy-sync-map-state';

const SyncShipmentDetailsFormMapStateContext = React.createContext<SyncShipmentDetailsFormMapStateContextValueT>({
    isMapInitialized: false,
    setIsMapInitialized: () => {
        // nothing
    },
    editingRoutePointIndex: null,
    setEditingRoutePointIndex: () => {
        // nothing
    },
    syncMapSettings: null,
    setSyncMapSettings: () => {
        // nothing
    },
    editingRoutePointErrorsIndexes: [],
    setHasEditingRoutePointErrorIndexes: () => {
        // nothing
    },
    hasEditingRoutePointError: false,
    initialBoundingBox: null,
    setInitialBoundingBox: () => {
        // nothing
    },
});

export { SyncShipmentDetailsFormMapStateContext };
