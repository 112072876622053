import { CountryCodeT } from 'common/utils/api/models';

export enum FieldsEnum {
    companyName = 'companyName',
    countryCode = 'countryCode',
    zipCode = 'zipCode',
    city = 'city',
    street1 = 'street1',
    legalFormId = 'legalFormId',
}

export type FormValuesT = {
    [FieldsEnum.companyName]: string;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.zipCode]: string;
    [FieldsEnum.city]: string;
    [FieldsEnum.street1]: string;
    [FieldsEnum.legalFormId]: LegalFormIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
