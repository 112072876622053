import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ContactsTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';

import { CompanyContactT } from 'common/store/members/models';

import AvatarCell from 'common/components/Table/cell-renderers/AvatarCell/AvatarCell';
import PositionsCell from './cell-renderers/PositionsCell/PositionsCell';
import ControlsCell from './cell-renderers/ControlsCell/ControlsCell';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import StatusCell from 'common/layouts/Members/ContactsTable/cell-renderers/StatusCell/StatusCell';
import { RoleT } from 'common/utils/api/models';
import EmailCell from 'common/components/Table/cell-renderers/EmailCell/EmailCell';
import PhoneCell from 'common/components/Table/cell-renderers/PhoneCell/PhoneCell';
import FullNameCell from 'common/components/Table/cell-renderers/FullNameCell/FullNameCell';
import PendingUserCell from 'common/layouts/Members/ContactsTable/cell-renderers/PendingUserCell/PendingUserCell';

const cx = classNames.bind(styles);

type PropsT = {
    contacts: Array<CompanyContactT>;
    selectedContactId: ContactIdT | null;
    className?: string;
    onDeleteUser: (contact: CompanyContactT) => void;
    onDeleteContact: (contact: CompanyContactT) => void;
    onChangeUserRole: (contact: CompanyContactT) => void;
    onRestoreUser: (contact: CompanyContactT) => void;
    onCancelInvite: (contact: CompanyContactT) => void;
    onMakeMainContact: (contact: CompanyContactT) => void;
    onInviteUser: (email: string) => void;
    onResendInviteUser: (email: string, role: RoleT) => void;
    onSelectRow: (event: React.MouseEvent, contact: CompanyContactT) => void;
    isLoading: boolean;
    canEditTeamMembers: boolean;
    canInviteUsers: boolean;
};

const ContactsTable: React.FC<PropsT> = (props) => {
    const {
        contacts,
        selectedContactId,
        className,
        onDeleteUser,
        onDeleteContact,
        onChangeUserRole,
        onRestoreUser,
        onCancelInvite,
        onInviteUser,
        onResendInviteUser,
        onSelectRow,
        onMakeMainContact,
        isLoading,
        canEditTeamMembers,
        canInviteUsers,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<CompanyContactT, void>> = [
        {
            renderHeader: () => null,
            render: (contact: CompanyContactT) => (
                <StatusCell userStatus={contact.userStatus}>
                    <AvatarCell fullName={contact.fullName || ''} />
                </StatusCell>
            ),
            className: cx('table__column', 'table__column--avatar'),
        },
        {
            renderHeader: () => <span>{t('common:team-members.columns.full-name')}</span>,
            headerClassName: cx('table__header'),
            render: (contact: CompanyContactT) => (
                <StatusCell userStatus={contact.userStatus}>
                    <PendingUserCell userStatus={contact.userStatus}>
                        <FullNameCell fullName={contact.fullName || ''} />
                    </PendingUserCell>
                </StatusCell>
            ),
            className: cx('table__column', 'table__column--any'),
        },
        {
            renderHeader: () => <span>{t('common:team-members.columns.email')}</span>,
            headerClassName: cx('table__header'),
            render: (contact: CompanyContactT) => (
                <StatusCell userStatus={contact.userStatus}>
                    <EmailCell email={contact.email || ''} />
                </StatusCell>
            ),
            className: cx('table__column', 'table__column--any'),
        },
        {
            renderHeader: () => <span>{t('common:team-members.columns.phone')}</span>,
            headerClassName: cx('table__header'),
            render: (contact: CompanyContactT) => (
                <StatusCell userStatus={contact.userStatus}>
                    <PhoneCell phone={contact.phone || ''} />
                </StatusCell>
            ),
            className: cx('table__column', 'table__column--any'),
        },
        {
            renderHeader: () => <span>{t('common:team-members.columns.roles')}</span>,
            headerClassName: cx('table__header'),
            render: (contact: CompanyContactT) => (
                <StatusCell userStatus={contact.userStatus}>
                    <PositionsCell contact={contact} />
                </StatusCell>
            ),
            className: cx('table__column', 'table__column--any'),
        },
        {
            renderHeader: () => null,
            render: (contact, _, rowIndex) =>
                canEditTeamMembers ? (
                    <ControlsCell
                        contact={contact}
                        onInviteUser={onInviteUser}
                        onResendInviteUser={onResendInviteUser}
                        onDeleteContact={onDeleteContact}
                        onDeleteUser={onDeleteUser}
                        onChangeUserRole={onChangeUserRole}
                        onRestoreUser={onRestoreUser}
                        onCancelInvite={onCancelInvite}
                        onMakeMainContact={onMakeMainContact}
                        isLastRow={rowIndex === contacts.length - 1}
                        canInviteUsers={canInviteUsers}
                    />
                ) : null,
            className: cx('table__column--controls'),
        },
    ];

    const getRowMods = (meta: TableRowMetaT, row: CompanyContactT) => ({
        isSelected: row?.id === selectedContactId,
    });

    return (
        <div className={cx('table')}>
            <MemoizedTable<CompanyContactT> tableName="members" isLoading={isLoading} rows={contacts}>
                {(rows, isUsedPrevRows) => (
                    <Table<CompanyContactT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        onSelectRow={onSelectRow}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
};

export default ContactsTable;
