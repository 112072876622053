import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TopBarContent.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    testSelector?: string;
    leftNode?: React.ReactNode;
    title?: React.ReactNode;
    rightNode?: React.ReactNode;
};

const TopBarContent: React.FC<PropsT> = React.memo((props) => {
    const { title, leftNode, rightNode, className, testSelector } = props;

    return (
        <div className={cs(cx('content'), className)} data-test-selector={`${testSelector}_top-bar-content`}>
            <div className={cx('left-content')}>
                {leftNode && <div className={cx('left-node')}>{leftNode}</div>}
                {title && <div className={cx('title')}>{title}</div>}
            </div>
            {rightNode && <div className={cx('right-node')}>{rightNode}</div>}
        </div>
    );
});

export default TopBarContent;
