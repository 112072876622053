import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ErrorModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsShowErrorModal, selectNewErrorLogs } from 'common/store/errors/selectors';
import { closeErrorModal } from 'common/store/errors/actions';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import Modal from 'common/components/Modal/Modal';
import ErrorDetails from 'common/components/ErrorModal/ErrorDetails/ErrorDetails';
import ModalDialog, { ModalDialogPropsT } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ErrorActionT } from 'common/store/errors/models';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import BigSleepIcon from 'common/icons/BigSleepIcon';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import { ApiRequestErrorTypeEnum, checkIsApiRequestError } from 'common/utils/api/errors/api-request-error';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';

const cx = classNames.bind(styles);

type PropsT = {};

const checkIsNotBlockingError = (errorAction: ErrorActionT): boolean => {
    return (
        checkIsTranziitApiRequestError(errorAction.error) &&
        (errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreationExpiredRFQ ||
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_AlreadyCompletedRFQ ||
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_ExpiredPriceOffer)
    );
};

const ErrorModal: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const newErrorLogs = useSelector(selectNewErrorLogs);

    const featuresContext = React.useContext(FeaturesContext);

    const isShowErrorDetails = featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.errorDetails);

    const isShowErrorModal = useSelector(selectIsShowErrorModal);
    if (!isShowErrorModal) {
        return null;
    }

    const handleClose = () => {
        dispatch(closeErrorModal());
    };

    const testSelector = 'error-modal';

    const firstErrorLog = newErrorLogs[0];

    const getModalDialogProps = (errorAction: ErrorActionT): ModalDialogPropsT => {
        const defaultOkAction = {
            children: t('common:error-modal.actions.ok'),
            theme: ButtonThemeEnum.secondary,
            testSelector: `${testSelector}_ok`,
            onClick: handleClose,
        };
        const defaultWidthPx = 340;

        if (
            checkIsApiRequestError(errorAction.error) &&
            errorAction.error?.type === ApiRequestErrorTypeEnum.timeoutError
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.timeout-error.title'),
                message: t('common:error-modal.timeout-error.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.canNotDowngradeLastAdminInTeam
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.can-not-downgrade-last-admin-in-team.title'),
                message: t('common:error-modal.can-not-downgrade-last-admin-in-team.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.canNotDeleteHimself
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.can-not-delete-himself.title'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.successorHasNotActiveStatus
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.successor-has-not-active-status.title'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreationExpiredRFQ
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.expired-rfq.title'),
                message: t('common:error-modal.expired-rfq.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_ExpiredPriceOffer
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.order-creation-expired-price-offer.title'),
                message: t('common:error-modal.order-creation-expired-price-offer.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_AlreadyCompletedRFQ
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.order-creation-already-completed-rfq.title'),
                message: t('common:error-modal.order-creation-already-completed-rfq.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.failedArchiveInTransitDriver
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.failed-archive-in-transit-driver.title'),
                message: t('common:error-modal.failed-archive-in-transit-driver.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.couldNotUnLockCompany
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.could-not-unlock-company.title'),
                message: t('common:error-modal.could-not-unlock-company.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.alreadyAssignedDrivers
        ) {
            const isManyDrivers = !!errorAction.error.requestConfig?.query?.secondDriver;

            return {
                width: defaultWidthPx,
                title: t(
                    isManyDrivers
                        ? 'common:error-modal.already-assigned-drivers.title'
                        : 'common:error-modal.already-assigned-driver.title',
                ),
                message: t(
                    isManyDrivers
                        ? 'common:error-modal.already-assigned-drivers.description'
                        : 'common:error-modal.already-assigned-driver.description',
                ),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.firebaseTooManyResendInviteRequests
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.too-many-reinvite-user-request.title'),
                message: t('common:error-modal.too-many-reinvite-user-request.description'),
                icon: <BigSleepIcon />,
                actions: [defaultOkAction],
            };
        }

        if (
            checkIsTranziitApiRequestError(errorAction.error) &&
            errorAction.error?.subType === TranziitApiRequestErrorSubTypeEnum.noRouteFound
        ) {
            return {
                width: defaultWidthPx,
                title: t('common:error-modal.no-route-found.title'),
                message: t('common:error-modal.no-route-found.description'),
                icon: <BigWarningIcon />,
                actions: [defaultOkAction],
            };
        }

        return {
            width: defaultWidthPx,
            title: t('common:error-modal.unexpected-error.title'),
            message: t('common:error-modal.unexpected-error.description'),
            icon: <BigWarningIcon />,
            actions: [
                defaultOkAction,
                {
                    children: t('common:error-modal.actions.contact-support'),
                    theme: ButtonThemeEnum.transparent,
                    testSelector: `${testSelector}_support`,
                    onClick: () => {
                        handleClose();

                        if (window.openZendeskWidget) {
                            window.openZendeskWidget();
                        }
                    },
                },
            ],
        };
    };

    const modalDialogProps = firstErrorLog ? getModalDialogProps(firstErrorLog) : null;
    const isNotBlockingError = firstErrorLog ? checkIsNotBlockingError(firstErrorLog) : false;

    const content = (
        <>
            {modalDialogProps && <ModalDialog {...modalDialogProps} />}
            {isShowErrorDetails && (
                <div className={cx('logs')}>
                    {newErrorLogs.map((errorLog, errorIndex) => {
                        return <ErrorDetails errorLog={errorLog} key={errorIndex} />;
                    })}
                </div>
            )}
        </>
    );

    if (isNotBlockingError) {
        return <div className={cx('background-error')}>{content}</div>;
    }

    return (
        <Modal>
            <ModalContent onClose={handleClose} testSelector={testSelector}>
                {content}
            </ModalContent>
        </Modal>
    );
});

export default ErrorModal;
