import {
    ACTIVATION_TRAILERS_REQUEST,
    ActivationTrailersRequestActionT,
    ADD_TRAILER_REQUEST,
    ADD_TRAILER_REQUEST_BEGIN,
    ADD_TRAILER_REQUEST_ERROR,
    ADD_TRAILER_REQUEST_SUCCESS,
    AddTrailerRequestActionT,
    AddTrailerRequestBeginActionT,
    AddTrailerRequestErrorActionT,
    AddTrailerRequestSuccessActionT,
    ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    AssignToContractRequestActionT,
    ARCHIVE_TRAILERS_REQUEST,
    ArchiveTrailersRequestActionT,
    DELETE_TRAILERS_REQUEST,
    DELETE_TRAILERS_REQUEST_BEGIN,
    DELETE_TRAILERS_REQUEST_ERROR,
    DELETE_TRAILERS_REQUEST_SUCCESS,
    DeleteTrailersRequestActionT,
    DeleteTrailersRequestBeginActionT,
    DeleteTrailersRequestErrorActionT,
    DeleteTrailersRequestSuccessActionT,
    FETCH_TRAILER_DETAILS_REQUEST,
    FETCH_TRAILER_DETAILS_REQUEST_BEGIN,
    FETCH_TRAILER_DETAILS_REQUEST_ERROR,
    FETCH_TRAILER_DETAILS_REQUEST_SUCCESS,
    FETCH_TRAILERS_PAGE_REQUEST,
    FETCH_TRAILERS_PAGE_REQUEST_BEGIN,
    FETCH_TRAILERS_PAGE_REQUEST_ERROR,
    FETCH_TRAILERS_PAGE_REQUEST_SUCCESS,
    FetchTrailerDetailsActionT,
    FetchTrailerDetailsBeginActionT,
    FetchTrailerDetailsErrorActionT,
    FetchTrailerDetailsSuccessActionT,
    FetchTrailersPageActionT,
    FetchTrailersPageBeginActionT,
    FetchTrailersPageErrorActionT,
    FetchTrailersPageSuccessActionT,
    RESET_TRAILERS,
    ResetTrailersActionT,
    UPDATE_TRAILERS_REQUEST,
    UPDATE_TRAILERS_REQUEST_BEGIN,
    UPDATE_TRAILERS_REQUEST_ERROR,
    UPDATE_TRAILERS_REQUEST_SUCCESS,
    UpdateTrailerActionT,
    UpdateTrailersRequestBeginActionT,
    UpdateTrailersRequestErrorActionT,
    UpdateTrailersRequestSuccessActionT,
    FETCH_TRAILERS_STATS_REQUEST,
    FETCH_TRAILERS_STATS_REQUEST_BEGIN,
    FETCH_TRAILERS_STATS_REQUEST_ERROR,
    FETCH_TRAILERS_STATS_REQUEST_SUCCESS,
    FetchTrailersStatsActionT,
    FetchTrailersStatsErrorActionT,
    FetchTrailersStatsBeginActionT,
    FetchTrailersStatsSuccessActionT,
} from './types';
import { CompanyTrailersStatsT, FetchTrailersPageQueryT, TrailerDetailsT, TrailerT } from './models';
import { ApiTrailerAddRequestT, ApiTrailerUpdateRequestT } from 'common/utils/api/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchTrailersPage = (
    pageNumber: PageNumberT,
    query: FetchTrailersPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTrailersPageActionT => {
    return {
        type: FETCH_TRAILERS_PAGE_REQUEST,
        query,
        pageNumber,
        companyId,
        options,
    };
};

export const fetchTrailersPageBegin = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchTrailersPageBeginActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchTrailersPageSuccess = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
    pageResponse: PageResponseT<TrailerT>,
): FetchTrailersPageSuccessActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_SUCCESS,
    query,
    pageNumber,
    companyId,
    pageResponse,
});

export const fetchTrailersPageError = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchTrailersPageErrorActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
    companyId,
});

export const resetTrailers = (
    companyId: CompanyIdT,
    mutationListOptions: MutationListOptionsT,
): ResetTrailersActionT => ({
    type: RESET_TRAILERS,
    companyId,
    mutationListOptions,
});

export const addTrailerRequest = (data: ApiTrailerAddRequestT, companyId: CompanyIdT): AddTrailerRequestActionT => ({
    type: ADD_TRAILER_REQUEST,
    data,
    companyId,
});

export const addTrailerRequestBegin = (companyId: CompanyIdT): AddTrailerRequestBeginActionT => ({
    type: ADD_TRAILER_REQUEST_BEGIN,
    companyId,
});

export const addTrailerRequestError = (error: Error, companyId: CompanyIdT): AddTrailerRequestErrorActionT => ({
    type: ADD_TRAILER_REQUEST_ERROR,
    error,
    companyId,
});

export const addTrailerRequestSuccess = (companyId: CompanyIdT): AddTrailerRequestSuccessActionT => ({
    type: ADD_TRAILER_REQUEST_SUCCESS,
    companyId,
});

export const deleteTrailersRequest = (
    trailerIds: TrailerIdT[],
    companyId: CompanyIdT,
): DeleteTrailersRequestActionT => ({
    type: DELETE_TRAILERS_REQUEST,
    trailerIds,
    companyId,
});

export const deleteTrailersRequestBegin = (companyId: CompanyIdT): DeleteTrailersRequestBeginActionT => ({
    type: DELETE_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const deleteTrailersRequestError = (error: Error, companyId: CompanyIdT): DeleteTrailersRequestErrorActionT => ({
    type: DELETE_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const deleteTrailersRequestSuccess = (companyId: CompanyIdT): DeleteTrailersRequestSuccessActionT => ({
    type: DELETE_TRAILERS_REQUEST_SUCCESS,
    companyId,
});

export const updateTrailer = (
    trailerId: TrailerIdT,
    updateTrailer: ApiTrailerUpdateRequestT,
    companyId: CompanyIdT,
): UpdateTrailerActionT => ({
    type: UPDATE_TRAILERS_REQUEST,
    trailerId,
    updateTrailer,
    companyId,
});

export const assignToContractRequest = (
    isContracted: boolean,
    trailerIds: TrailerIdT[],
    companyId: CompanyIdT,
): AssignToContractRequestActionT => ({
    type: ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    isContracted,
    trailerIds,
    companyId,
});

export const activationTrailersRequest = (
    trailerIds: TrailerIdT[],
    ratesPerKm: Array<number | null>,
    companyId: CompanyIdT,
): ActivationTrailersRequestActionT => ({
    type: ACTIVATION_TRAILERS_REQUEST,
    trailerIds,
    ratesPerKm,
    companyId,
});

export const archiveTrailersRequest = (
    trailerIds: TrailerIdT[],
    companyId: CompanyIdT,
): ArchiveTrailersRequestActionT => ({
    type: ARCHIVE_TRAILERS_REQUEST,
    trailerIds,
    companyId,
});

export const updateTrailersRequestBegin = (companyId: CompanyIdT): UpdateTrailersRequestBeginActionT => ({
    type: UPDATE_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const updateTrailersRequestError = (error: Error, companyId: CompanyIdT): UpdateTrailersRequestErrorActionT => ({
    type: UPDATE_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const updateTrailersRequestSuccess = (companyId: CompanyIdT): UpdateTrailersRequestSuccessActionT => ({
    type: UPDATE_TRAILERS_REQUEST_SUCCESS,
    companyId,
});

export const fetchTrailerDetails = (
    trailerId: TrailerIdT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTrailerDetailsActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST,
    trailerId,
    companyId,
    options,
});

export const fetchTrailerDetailsRequestBegin = (companyId: CompanyIdT): FetchTrailerDetailsBeginActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchTrailerDetailsRequestError = (
    error: Error,
    companyId: CompanyIdT,
): FetchTrailerDetailsErrorActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTrailerDetailsRequestSuccess = (
    trailerId: TrailerIdT,
    details: TrailerDetailsT | null,
    companyId: CompanyIdT,
): FetchTrailerDetailsSuccessActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_SUCCESS,
    trailerId,
    details,
    companyId,
});

export const fetchTrailersStats = (companyId: CompanyIdT, options?: FetchOptionsT): FetchTrailersStatsActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST,
    companyId,
    options,
});

export const fetchTrailersStatsRequestBegin = (companyId: CompanyIdT): FetchTrailersStatsBeginActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_BEGIN,
    companyId,
});

export const fetchTrailersStatsRequestError = (
    error: Error,
    companyId: CompanyIdT,
): FetchTrailersStatsErrorActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTrailersStatsRequestSuccess = (
    stats: CompanyTrailersStatsT | null,
    companyId: CompanyIdT,
): FetchTrailersStatsSuccessActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_SUCCESS,
    stats,
    companyId,
});
