import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { RequestStatusT } from 'common/utils/request-status';
import { SetUnavailableVehicleQueryT } from 'common/utils/api/models';
import { checkIsSameDay, clearDateTimezone, formatDate } from 'common/utils/time';
import { formatDateInterval } from 'common/utils/formatters';

export type SetUnavailableAssetConfirmationDataT = {
    companyId: CompanyIdT;
    assets: Array<{
        plateNumber: string;
    }>;
    query: SetUnavailableVehicleQueryT;
};

type PropsT = {
    assetType: AssetTypeEnum;
    data: SetUnavailableAssetConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: SetUnavailableAssetConfirmationDataT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'set-unavailable-asset';

const T_MAP: Record<AssetTypeEnum, { title: string; message: string }> = {
    [AssetTypeEnum.truck]: {
        title: 'common:trucks-page.set-unavailable-confirmation.title',
        message: 'common:trucks-page.set-unavailable-confirmation.message',
    },
    [AssetTypeEnum.trailer]: {
        title: 'common:trailers-page.set-unavailable-confirmation.title',
        message: 'common:trailers-page.set-unavailable-confirmation.message',
    },
};

const FORMAT_DATE = 'D MMM';

const SetUnavailableAssetConfirmationModal: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, requestStatus, onConfirm, assetType } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const { assets, query } = data;

    const tSet = T_MAP[assetType];

    const dateFrom = assetType === AssetTypeEnum.truck ? query.truckDateFrom : query.trailerDateFrom;
    const dateTo = assetType === AssetTypeEnum.trailer ? query.truckDateTo : query.trailerDateTo;

    const isSameDay = checkIsSameDay(dateFrom, dateTo);
    const formattedInterval = isSameDay
        ? formatDate(FORMAT_DATE, clearDateTimezone(dateFrom))
        : formatDateInterval(dateFrom, dateTo, FORMAT_DATE);

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.set'),
                            theme: ButtonThemeEnum.danger,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t(tSet.title)}
                    message={t(tSet.message, {
                        plateNumber: assets?.[0]?.plateNumber || '',
                        interval: formattedInterval,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default SetUnavailableAssetConfirmationModal;
