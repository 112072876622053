import React from 'react';
import classNames from 'classnames/bind';

import styles from './EvidenceOfTaxDetails.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FieldValue from 'common/components/FieldValue/FieldValue';
import { fetchEvidenceOfTaxDetails } from 'common/store/documents/actions';
import {
    selectFetchEvidenceOfTaxDetails,
    selectFetchEvidenceOfTaxDetailsRequest,
} from 'common/store/documents/selectors';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import { DocumentVersionT } from 'common/store/documents/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    actualVersion: DocumentVersionT | null;
};

const EvidenceOfTaxDetails: React.FC<PropsT> = (props) => {
    const { actualVersion } = props;

    const { partnerId } = usePartnerContext();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(fetchEvidenceOfTaxDetails(partnerId));
    }, [partnerId]);

    const evidenceOfTaxDetails = useSelector(selectFetchEvidenceOfTaxDetails(partnerId));

    const fetchEvidenceOfTaxDetailsRequest = useSelector(selectFetchEvidenceOfTaxDetailsRequest(partnerId));
    if (fetchEvidenceOfTaxDetailsRequest.loading && !evidenceOfTaxDetails) {
        return <SideBarLoader isRelative />;
    }

    return (
        <>
            <FieldValue
                label={t('common:document.evidence-of-tax-details.tax-id')}
                value={evidenceOfTaxDetails?.taxId || ''}
                placeholder={t('common:fields.placeholder.empty')}
            />
            <FieldValue
                label={t('common:document.evidence-of-tax-details.vat-number')}
                value={evidenceOfTaxDetails?.vat || ''}
                placeholder={t('common:fields.placeholder.empty')}
            />
            <FieldValue
                label={t('common:document.evidence-of-tax-details.iban')}
                value={evidenceOfTaxDetails?.iban || ''}
                placeholder={t('common:fields.placeholder.empty')}
            />
            <FieldValue
                label={t('common:document.evidence-of-tax-details.bic')}
                value={evidenceOfTaxDetails?.bic || ''}
                placeholder={t('common:fields.placeholder.empty')}
            />
        </>
    );
};

export default EvidenceOfTaxDetails;
