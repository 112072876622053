import { ReserveOpenTimeWindowT, ReserveTimeWindowT } from 'common/store/order-creation/models';
import moment from 'moment';
import isNumber from 'lodash/isNumber';
import { FieldsEnum, RoutePointFieldsEnum } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/constants';

export const suggestLastTimeWindowOfDay = (
    availableReserveTimeWindow: ReserveOpenTimeWindowT | null,
    baseDate: ReserveTimeWindowT['start'] | ReserveOpenTimeWindowT['start'],
): ReserveTimeWindowT | null => {
    if (!availableReserveTimeWindow) {
        return null;
    }

    let start = moment.parseZone(baseDate).startOf('day').format();
    const startLimit = availableReserveTimeWindow.start
        ? moment.parseZone(availableReserveTimeWindow.start).format()
        : null;
    if (startLimit && start < startLimit) {
        start = startLimit;
    }

    let end = moment.parseZone(baseDate).endOf('day').add(1, 'second').format();
    if (startLimit && end <= startLimit) {
        return null;
    }
    const endLimit = availableReserveTimeWindow.end ? moment.parseZone(availableReserveTimeWindow.end).format() : null;
    if (endLimit && start >= endLimit) {
        return null;
    }
    if (endLimit && end > endLimit) {
        end = endLimit;
    }

    return {
        start,
        end,
    };
};

export const getReserveTimeWindowWidthMs = (reserveTimeWindow: ReserveTimeWindowT | null): number => {
    if (!reserveTimeWindow) {
        return 0;
    }

    const start = moment.parseZone(reserveTimeWindow.start).valueOf();
    const end = moment.parseZone(reserveTimeWindow.end).valueOf();

    return end - start;
};

export const addToTimeMs = (isoTime: ISODateTimeT | undefined | null, ms: number | null): ISODateTimeT | null => {
    if (!isoTime || !isNumber(ms)) {
        return null;
    }

    return moment.parseZone(isoTime).add(ms, 'milliseconds').format();
};

export const convertReserveTimeWindowToRelativeTimeWindow = (
    reserveTimeWindow: ReserveTimeWindowT | null,
): TimeWindowT | null => {
    if (!reserveTimeWindow) {
        return null;
    }

    const offset = moment.parseZone(reserveTimeWindow.start).startOf('day').valueOf();
    const start = moment.parseZone(reserveTimeWindow.start).valueOf();
    const end = moment.parseZone(reserveTimeWindow.end).valueOf();

    return [start - offset, end - offset];
};

export const createReserveTimeWindow = (start: ISODateTimeT, minWindowSizeMs: number): ReserveTimeWindowT => {
    const end = moment.parseZone(start).add('ms', minWindowSizeMs).format();

    return {
        start,
        end,
    };
};

export const checkIsISODateMidnight = (isoDate: ISODateTimeT): boolean => {
    return isoDate.includes('T00:00:00');
};

export const setDateForReserveTimeWindow = (
    date: Date | null,
    timeWindow: ReserveTimeWindowT | null,
): ReserveTimeWindowT | null => {
    if (!date || !timeWindow) {
        return null;
    }

    const dateStringPart = moment(date).format('YYYY-MM-DD');

    const start = dateStringPart + timeWindow.start.slice(10);

    let end = dateStringPart + timeWindow.end.slice(10);
    if (checkIsISODateMidnight(end)) {
        end = moment.parseZone(end).add(1, 'days').format();
    }

    return {
        start,
        end,
    };
};

export const setTimeWindowForReserveTimeWindow = (
    timeWindow: TimeWindowT | null,
    reserveTimeWindow: ReserveTimeWindowT | null,
): ReserveTimeWindowT | null => {
    if (!timeWindow || !reserveTimeWindow) {
        return null;
    }

    const startDay = moment.parseZone(reserveTimeWindow.start).startOf('day');
    const start = startDay.clone().add(timeWindow[0], 'ms').format();
    const end = startDay.clone().add(timeWindow[1], 'ms').format();

    return {
        start,
        end,
    };
};

export const checkIsSameTimezone = (isoDateA: ISODateTimeT, isoDateB: ISODateTimeT): boolean => {
    return moment.parseZone(isoDateA).utcOffset() === moment.parseZone(isoDateB).utcOffset();
};

export const getRoutePointFieldName = <T extends RoutePointFieldsEnum>(
    pointIndex: number,
    routePointField: T,
): `${FieldsEnum.route}.${number}.${T}` => {
    return `${FieldsEnum.route}.${pointIndex}.${routePointField}`;
};
