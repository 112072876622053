import { FormikHelpers, FormikState } from 'formik';
import { FieldsEnum, FormValuesT, RoutePointFieldsEnum } from '../constants';
import { useEffect, useRef } from 'react';

type FormikT = FormikState<FormValuesT> & FormikHelpers<FormValuesT>;

export const useEditRouteWatcher = (formik: FormikT) => {
    const prevFirstEmptyLocationRoutePointIndex = useRef<number | null>(null);

    const routeValue = formik.values[FieldsEnum.route];

    useEffect(() => {
        const firstEmptyLocationRoutePointIndex = routeValue?.findIndex((routePointValue) => {
            return !routePointValue[RoutePointFieldsEnum.location];
        });

        if (prevFirstEmptyLocationRoutePointIndex.current !== firstEmptyLocationRoutePointIndex) {
            if (firstEmptyLocationRoutePointIndex !== -1 && firstEmptyLocationRoutePointIndex !== null) {
                const newRouteValue = routeValue.map((routePointValue, index) => {
                    const isShouldResetTimeWindow = index >= firstEmptyLocationRoutePointIndex;
                    if (routePointValue[RoutePointFieldsEnum.timeWindow] && isShouldResetTimeWindow) {
                        return {
                            ...routePointValue,
                            [RoutePointFieldsEnum.timeWindow]: null,
                        };
                    }

                    return routePointValue;
                });

                formik.setFieldValue(FieldsEnum.route, newRouteValue);
            }

            prevFirstEmptyLocationRoutePointIndex.current = firstEmptyLocationRoutePointIndex;
        }
    }, [routeValue]);
};
