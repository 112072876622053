import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
    className?: string;
};

const CalendarAltIcon: React.FC<PropsT> = (props) => {
    const { size, strokeColor, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} viewBox="0 0 20 20">
            <path
                fill={strokeColor}
                fillRule="evenodd"
                d="M6.889 3v1.5h6.222V3h1.556v1.5h.777C16.3 4.5 17 5.175 17 6v9.5c0 .825-.7 1.5-1.556 1.5H4.556C3.692 17 3 16.325 3 15.5L3.008 6c0-.825.684-1.5 1.548-1.5h.777V3H6.89zM15 8H5v7h10V8zm-5 2v3H7v-3h3z"
            />
        </svg>
    );
};

CalendarAltIcon.displayName = 'CalendarAltIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default CalendarAltIcon;
