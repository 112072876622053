import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { documentsWithoutExpirySet, StyleGuideColorsEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import CloseIcon from 'common/icons/CloseIcon';
import { DocumentStatusEnum } from 'common/utils/api/models';
import { PermissionsT } from 'common/store/auth/models';
import { DocumentActionsT } from '../models';
import { DocumentT } from 'common/store/documents/models';
import UploadFileIcon from 'common/icons/UploadFileIcon';
import EditIcon from 'common/icons/EditIcon';
import { DictDocumentT } from 'common/store/documents-dict/models';
import { logWarning } from 'common/utils/logger';

enum DocumentActionsEnum {
    download = 'download',
    upload = 'upload',
    revoke = 'revoke',
    edit = 'edit',
}

type CallbacksT = DocumentActionsT;

export const useDocumentsActionOptions = (
    actualDocumentVersion: DocumentT | null,
    dictDocument: DictDocumentT | null,
    permissions: PermissionsT,
    callbacks: CallbacksT,
): Array<DropdownControlOptionT | SpecialOptionEnum> => {
    const { onDownloadDocument, onUploadDocument, onRevokeDocument, onEditDocumentInfo } = callbacks;
    const { canDownloadDocuments, canUploadDocuments, canVerifyDocuments, canRevokeDocuments, canEditDocuments } =
        permissions;

    const { t } = useTranslation();

    const optionsConfigMap: Record<
        DocumentActionsEnum,
        { icon: React.ReactNode; label: React.ReactNode; onSelect: () => void }
    > = {
        [DocumentActionsEnum.download]: {
            icon: <PdfFileIcon fillColor={StyleGuideColorsEnum.charcoal} />,
            label: t('common:documents.table.actions.download'),
            onSelect: () => {
                if (actualDocumentVersion) {
                    onDownloadDocument(actualDocumentVersion.id);
                } else {
                    logWarning('failed to download document');
                }
            },
        },
        [DocumentActionsEnum.upload]: {
            icon: <UploadFileIcon fillColor={StyleGuideColorsEnum.charcoal} />,
            label:
                actualDocumentVersion?.status === null
                    ? t('common:documents.table.actions.upload')
                    : t('common:documents.table.actions.upload-new'),
            onSelect: () => {
                if (actualDocumentVersion?.dictDocumentId) {
                    onUploadDocument(actualDocumentVersion.dictDocumentId);
                } else {
                    logWarning('failed to upload document');
                }
            },
        },
        [DocumentActionsEnum.revoke]: {
            icon: <CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />,
            label: t('common:documents.table.actions.revoke'),
            onSelect: () => {
                if (dictDocument && actualDocumentVersion) {
                    onRevokeDocument(dictDocument.id, actualDocumentVersion.id);
                } else {
                    logWarning('failed to revoke document');
                }
            },
        },
        [DocumentActionsEnum.edit]: {
            icon: <EditIcon />,
            label: t('common:documents.table.actions.edit'),
            onSelect: () => {
                if (dictDocument) {
                    onEditDocumentInfo(dictDocument.type);
                } else {
                    logWarning('failed to edit document');
                }
            },
        },
    };

    const actions = React.useMemo((): Array<DocumentActionsEnum | SpecialOptionEnum> => {
        const hasAnyVersion = !!actualDocumentVersion;

        const actions: Array<DocumentActionsEnum | SpecialOptionEnum> = [];

        if (hasAnyVersion && canDownloadDocuments) {
            actions.push(DocumentActionsEnum.download);
        }

        if (hasAnyVersion && canUploadDocuments) {
            actions.push(DocumentActionsEnum.upload);
        }

        if (actualDocumentVersion?.status === DocumentStatusEnum.approved && hasAnyVersion && canEditDocuments) {
            actions.push(SpecialOptionEnum.separator);

            const isAllowEdit =
                !!actualDocumentVersion?.documentType &&
                !documentsWithoutExpirySet.has(actualDocumentVersion.documentType);
            if (isAllowEdit) {
                actions.push(DocumentActionsEnum.edit);
            }
        }

        if (actualDocumentVersion?.status === DocumentStatusEnum.approved && hasAnyVersion && canRevokeDocuments) {
            actions.push(SpecialOptionEnum.separator);
            actions.push(DocumentActionsEnum.revoke);
        }

        return actions;
    }, [
        actualDocumentVersion,
        canDownloadDocuments,
        canUploadDocuments,
        canVerifyDocuments,
        canRevokeDocuments,
        canEditDocuments,
    ]);

    const options = actions.map((action) => {
        if (action === SpecialOptionEnum.separator) {
            return SpecialOptionEnum.separator;
        }

        const optionConfig = optionsConfigMap[action];

        return {
            label: <DropdownControlOptionLabel icon={optionConfig.icon} label={optionConfig.label} />,
            onSelect: optionConfig.onSelect,
        };
    });

    return options;
};
