import * as React from 'react';
import { memo } from 'react';
import { ApiLocationT } from 'common/utils/api/models';
import AsyncDictCountryNameLabel from 'common/components/AsyncDictCountryNameLabel/AsyncDictCountryNameLabel';
import reduce from 'lodash/reduce';

export type PropsT = {
    className?: string;
    format: 's1_s2_zip_city_country' | 'zip_city_country';
    location: ApiLocationT | null | undefined;
    separator?: React.ReactNode;
};

const LocationLabel: React.FC<PropsT> = memo((props) => {
    const { className, location } = props;

    if (!location) {
        return null;
    }

    const separator = props.separator || ', ';

    const nodes = reduce(
        [location.street1, location.street2, location.zipCode, location.city],
        (acc: Array<React.ReactNode>, value) => {
            acc.push(
                <>
                    {value}
                    {value ? separator : null}
                </>,
            );
            return acc;
        },
        [],
    );

    nodes.push(<AsyncDictCountryNameLabel countryCode={location.country} />);

    return <span className={className}>{nodes}</span>;
});

export default LocationLabel;
