import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersTrigger.scss';
import FilterIcon from 'common/icons/FilterIcon';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

const cx = classNames.bind(styles);

type PropsT = {
    onClick: () => void;
    isActive: boolean;
    title: string;
    className?: string;
    testSelector?: string;
};

const FiltersTrigger: React.FC<PropsT> = (props) => {
    const { onClick, isActive, title, className, testSelector } = props;

    return (
        <TransparentTrigger
            testSelector={`${testSelector}_filters`}
            onClick={onClick}
            isPressed={isActive}
            label={title}
            className={className}
            leftIcon={<FilterIcon />}
            rightIcon={<ArrowsIcon />}
            reflectionTheme={ReflectionThemeEnum.light}
        />
    );
};

export default FiltersTrigger;
