import * as React from 'react';
import { checkHasAnyErrors } from 'common/utils/form-helpers';

type FormFieldT = string;
type FormErrorT = string | string[];

export const useWatchFormAnyErrors = (errorsMap: Record<FormFieldT, FormErrorT>): boolean => {
    const hasErrors = React.useMemo(() => {
        return checkHasAnyErrors(errorsMap);
    }, [errorsMap]);

    return hasErrors;
};
