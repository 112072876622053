const ECLIPSE = '...';
const TAIL_LENGTH = 15;

export const reduceFileNameIfNeed = (fileName: string, limit: number): string => {
    if (fileName.length < limit - ECLIPSE.length) {
        return fileName;
    }

    return [fileName.slice(0, limit - ECLIPSE.length - TAIL_LENGTH), ECLIPSE, fileName.slice(-1 * TAIL_LENGTH)].join(
        '',
    );
};
