import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './SaveChangesNotification.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    hasChanges?: boolean;
    className?: string;
};

const SaveChangesNotification: React.FC<PropsT> = (props) => {
    const { hasChanges, className } = props;
    const { t } = useTranslation();

    const label = hasChanges
        ? t('common:save-changes-notification.has-changes')
        : t('common:save-changes-notification.has-not-changes');

    return (
        <div className={cs(cx('notification'), className)}>
            <div className={cx('notification__icon')}>
                <div
                    className={cx('circle', {
                        circle__orange: hasChanges,
                        circle__green: !hasChanges,
                    })}
                />
            </div>
            <div className={cx('notification__label')}>{label}</div>
        </div>
    );
};

export default SaveChangesNotification;
