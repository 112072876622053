import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigTrashIcon: React.FC<PropsT> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={200}
            height={130}
            viewBox="0 0 200 130"
            {...props}
        >
            <defs>
                <path id="prefix__a" d="M37 32c20.435 0 37-2.686 37-6S57.435 0 37 0 0 22.686 0 26s16.565 6 37 6z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h200v130H0z" />
                <path
                    fill="#D32406"
                    d="M103 27a7 7 0 110 14h64a7 7 0 110 14h22a7 7 0 110 14h-19a7 7 0 100 14h6a7 7 0 110 14h-52a7.056 7.056 0 01-1.5-.161A7.056 7.056 0 01121 97H40a7 7 0 110-14H7a7 7 0 110-14h34a7 7 0 100-14H22a7 7 0 110-14h34a7 7 0 110-14h47zm90 56a7 7 0 110 14 7 7 0 010-14z"
                    opacity={0.1}
                />
                <path
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    d="M36 108.5h21.454m-34.326 0H30m136.128 0H169m-30 0h22.428"
                />
                <ellipse
                    cx={98}
                    cy={121}
                    fill="#D32406"
                    fillOpacity={0.3}
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeWidth={2.5}
                    rx={37}
                    ry={7}
                />
                <path
                    fill="#FFF"
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeWidth={2.5}
                    d="M61 84.215V44c0-1.657 1.35-3 3.017-3h69.019c1.085 0 1.964.895 1.964 2v73c0 2.21-8 8-37 8s-37-5.79-37-8V97m0-4v-5"
                />
                <g stroke="#D32406" strokeLinecap="round" strokeWidth={2.5} transform="translate(61 36)">
                    <ellipse cx={37} cy={6} fill="#FFF" rx={37} ry={6} />
                    <ellipse cx={37} cy={6} fill="#D32406" fillOpacity={0.3} rx={37} ry={6} />
                </g>
                <g transform="translate(61 13)">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <g mask="url(#prefix__b)">
                        <path
                            fill="#FFF"
                            stroke="#D32406"
                            strokeLinecap="round"
                            strokeWidth={2.5}
                            d="M47.943 16.191l5.43 39.632.489 3.976a2.33 2.33 0 01-2.021 2.602l-34.014 4.176a2.33 2.33 0 01-2.591-2.036L9.969 21.648a1.166 1.166 0 011.01-1.301l.013-.002 2.822-.316m2.282-.256l2.664-.298"
                        />
                        <path
                            fill="#D32406"
                            fillOpacity={0.3}
                            d="M46.489 18.673l4.89 35.915.44 3.604c.142 1.16-.668 2.215-1.808 2.356L19.55 64.31c-1.141.14-2.18-.686-2.322-1.847l-4.633-37.951a2 2 0 011.74-2.228l2.94-.363"
                        />
                        <path
                            fill="#FFF"
                            stroke="#D32406"
                            strokeWidth={2.5}
                            d="M49.152 9.25c.73 0 1.43.29 1.946.807h0l6.848 6.858c.515.516.804 1.215.804 1.944h0V53A2.744 2.744 0 0156 55.75h0-31A2.744 2.744 0 0122.25 53h0V12A2.744 2.744 0 0125 9.25h0z"
                        />
                        <path
                            stroke="#D32406"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2.5}
                            d="M50 10v7.15c0 1.022.737 1.85 1.646 1.85H56"
                        />
                        <path
                            stroke="#D32406"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity={0.3}
                            strokeWidth={2.5}
                            d="M28 18.288h15.116M28 25.212h25m-25 7.5h25m-25 7.5h25m-25 7.5h15.116"
                        />
                    </g>
                </g>
                <g stroke="#D32406" strokeWidth={2.5} transform="translate(81 55)">
                    <ellipse cx={4} cy={3.5} fill="#D32406" fillOpacity={0.3} rx={4} ry={2.545} />
                    <path strokeLinecap="round" d="M56 7c0 8.837-11.64 16-26 16S4 15.837 4 7" />
                </g>
                <path
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    d="M117 7l-8 9M98.5 4v12M79 7l8 9"
                    opacity={0.3}
                />
            </g>
        </svg>
    );
};

BigTrashIcon.displayName = 'BigTrashIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigTrashIcon;
