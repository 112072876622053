import {
    ApiNotificationT,
    ApiOrderStatusT,
    ApiTourStatusT,
    DocumentTypeT,
    ShipmentStatusEnum,
    OverwritePageRequestWithSortQueryT,
    PartnerTypeEnum,
    TransportOrderStatusEnum,
} from 'common/utils/api/models';

import * as React from 'react';

export enum NotificationActionEnum {
    SHIPMENT_STATUS_CHANGED = 'SHIPMENT_STATUS_CHANGED',
    SHIPMENT_MISSED = 'SHIPMENT_MISSED',
    SHIPMENT_NO_SHOW = 'SHIPMENT_NO_SHOW',
    ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
    TOUR_STATUS_CHANGED = 'TOUR_STATUS_CHANGED',
    RUNNING_OUT_OF_TIME_ASSIGN_ASSETS = 'RUNNING_OUT_OF_TIME_ASSIGN_ASSETS',
    RUN_OUT_OF_TIME_ASSIGN_ASSETS = 'RUN_OUT_OF_TIME_ASSIGN_ASSETS',
    RUNNING_OUT_OF_TIME_ASSIGN_DRIVER = 'RUNNING_OUT_OF_TIME_ASSIGN_DRIVER',
    RUN_OUT_OF_TIME_ASSIGN_DRIVER = 'RUN_OUT_OF_TIME_ASSIGN_DRIVER',
    TRANSPORTATION_ORDER_STATUS_CHANGED = 'TRANSPORTATION_ORDER_STATUS_CHANGED',
    TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED = 'TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED',
    RFQ_ALERT = 'RFQ_ALERT',
    SPOT_PLACED = 'SPOT_PLACED',
    SPOT_CANCELLED = 'SPOT_CANCELLED',
    SPOT_ASSIGNED = 'SPOT_ASSIGNED',
    SPOT_EXPIRED = 'SPOT_EXPIRED',
    SPOT_BID_ACCEPTED = 'SPOT_BID_ACCEPTED',
    SPOT_BID_EXPIRED = 'SPOT_BID_EXPIRED',
    SPOT_BID_MISSED = 'SPOT_BID_MISSED',
    SPOT_BID_PLACED = 'SPOT_BID_PLACED',
    DOCUMENT_COMPLETED = 'DOCUMENT_COMPLETED',
    DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
    DOCUMENT_REVOKED = 'DOCUMENT_REVOKED',
    DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
    DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
    DOCUMENT_EXPIRATION_SOON = 'DOCUMENT_EXPIRATION_SOON',
    SHIPPER_CONTRACT_EXPIRED = 'SHIPPER_CONTRACT_EXPIRED',
    SHIPPER_CONTRACT_REVOKED = 'SHIPPER_CONTRACT_REVOKED',
    SHIPPER_CONTRACT_UPLOADED = 'SHIPPER_CONTRACT_UPLOADED',
    SHIPPER_CONTRACT_UPDATED = 'SHIPPER_CONTRACT_UPDATED',
    SHIPPER_CONTRACT_EXPIRATION_SOON = 'SHIPPER_CONTRACT_EXPIRATION_SOON',
    SHIPPER_CONTRACT_COMPLETED = 'SHIPPER_CONTRACT_COMPLETED',
    CARRIER_CONTRACT_EXPIRED = 'CARRIER_CONTRACT_EXPIRED',
    CARRIER_CONTRACT_REVOKED = 'CARRIER_CONTRACT_REVOKED',
    CARRIER_CONTRACT_UPDATED = 'CARRIER_CONTRACT_UPDATED',
    CARRIER_CONTRACT_EXPIRATION_SOON = 'CARRIER_CONTRACT_EXPIRATION_SOON',
    CARRIER_CONTRACT_COMPLETED = 'CARRIER_CONTRACT_COMPLETED',
    SHIPPER_CONTRACT_LANE_EXPIRED = 'SHIPPER_CONTRACT_LANE_EXPIRED',
    SHIPPER_CONTRACT_LANE_REVOKED = 'SHIPPER_CONTRACT_LANE_REVOKED',
    SHIPPER_CONTRACT_LANE_UPDATED = 'SHIPPER_CONTRACT_LANE_UPDATED',
    SHIPPER_CONTRACT_LANE_UPLOAD = 'SHIPPER_CONTRACT_LANE_UPLOAD',
    SHIPPER_CONTRACT_LANE_EXPIRATION_SOON = 'SHIPPER_CONTRACT_LANE_EXPIRATION_SOON',
    SHIPPER_CONTRACT_LANE_COMPLETED = 'SHIPPER_CONTRACT_LANE_COMPLETED',
    UNKNOWN_ACTION = 'unknown',
}

export enum NotificationIconEnum {
    success = 'success',
    attention = 'attention',
    fail = 'fail',
    new = 'new',
    expired = 'expired',
}

export type NotificationToastViewT = {
    icon: NotificationIconEnum | null;
    text: React.ReactNode;
};

export enum NotificationEmotionEnum {
    neutral = 'neutral',
    positive = 'positive',
    negative = 'negative',
    attention = 'attention',
}

export type NotificationViewT = {
    emotion: NotificationEmotionEnum | null;
    text: React.ReactNode;
};

type NotificationT<T extends keyof NotificationBodyT> = OverwriteT<
    ApiNotificationT,
    {
        action: T;
        sourceAction: string;
        body: NotificationBodyT[T];
        sourceBody: string;
        relatedIds: string[];
        isNew: boolean;
    }
>;

export type AnyNotificationT =
    | NotificationT<NotificationActionEnum.SHIPMENT_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.SHIPMENT_MISSED>
    | NotificationT<NotificationActionEnum.SHIPMENT_NO_SHOW>
    | NotificationT<NotificationActionEnum.ORDER_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.TOUR_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS>
    | NotificationT<NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS>
    | NotificationT<NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER>
    | NotificationT<NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER>
    | NotificationT<NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED>
    | NotificationT<NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED>
    | NotificationT<NotificationActionEnum.RFQ_ALERT>
    | NotificationT<NotificationActionEnum.SPOT_PLACED>
    | NotificationT<NotificationActionEnum.SPOT_CANCELLED>
    | NotificationT<NotificationActionEnum.SPOT_ASSIGNED>
    | NotificationT<NotificationActionEnum.SPOT_EXPIRED>
    | NotificationT<NotificationActionEnum.SPOT_BID_ACCEPTED>
    | NotificationT<NotificationActionEnum.SPOT_BID_EXPIRED>
    | NotificationT<NotificationActionEnum.SPOT_BID_MISSED>
    | NotificationT<NotificationActionEnum.SPOT_BID_PLACED>
    | NotificationT<NotificationActionEnum.DOCUMENT_EXPIRED>
    | NotificationT<NotificationActionEnum.DOCUMENT_REVOKED>
    | NotificationT<NotificationActionEnum.DOCUMENT_UPDATED>
    | NotificationT<NotificationActionEnum.DOCUMENT_UPLOAD>
    | NotificationT<NotificationActionEnum.DOCUMENT_COMPLETED>
    | NotificationT<NotificationActionEnum.DOCUMENT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_REVOKED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_UPDATED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_EXPIRED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_REVOKED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_UPDATED>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.CARRIER_CONTRACT_COMPLETED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON>
    | NotificationT<NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED>
    | NotificationT<NotificationActionEnum.UNKNOWN_ACTION>;

type NotificationConverterOptionsT = {
    isNew: boolean;
};

export type NotificationConverterT = (
    apiNotification: ApiNotificationT,
    options: NotificationConverterOptionsT,
) => AnyNotificationT;

export type NotificationBodyT = {
    [NotificationActionEnum.SHIPMENT_STATUS_CHANGED]: {
        status?: ShipmentStatusEnum;
        cityFrom?: string;
        cityTo?: string;
        dispatchId?: DispatchIdT;
        dispatchNumber?: string;
        transportationOrderId?: TransportOrderIdT;
        transportationOrderNumber?: string;
        tourId?: TourIdT;
        tourNumber?: string;
    };
    [NotificationActionEnum.SHIPMENT_MISSED]: {
        status?: ShipmentStatusEnum;
        cityFrom?: string;
        cityTo?: string;
        dispatchId?: DispatchIdT;
        dispatchNumber?: string;
        transportationOrderId?: TransportOrderIdT;
        transportationOrderNumber?: string;
        tourId?: TourIdT;
        tourNumber?: string;
    };
    [NotificationActionEnum.SHIPMENT_NO_SHOW]: {
        status?: ShipmentStatusEnum;
        cityFrom?: string;
        cityTo?: string;
        dispatchId?: DispatchIdT;
        dispatchNumber?: string;
        transportationOrderId?: TransportOrderIdT;
        transportationOrderNumber?: string;
        tourId?: TourIdT;
        tourNumber?: string;
    };
    [NotificationActionEnum.ORDER_STATUS_CHANGED]: {
        status?: ApiOrderStatusT;
        cityFrom?: string;
        cityTo?: string;
    };
    [NotificationActionEnum.TOUR_STATUS_CHANGED]: OverwriteT<
        Api2.Definitions.EntityStatusDetails,
        {
            status: ApiTourStatusT;
        }
    >;
    [NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS]: OverwriteT<
        Api2.Definitions.EntityStatusDetails,
        {
            status: ApiTourStatusT;
        }
    >;
    [NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS]: OverwriteT<
        Api2.Definitions.EntityStatusDetails,
        {
            status: ApiTourStatusT;
        }
    >;
    [NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER]: OverwriteT<
        Api2.Definitions.EntityStatusDetails,
        {
            status: ApiTourStatusT;
        }
    >;
    [NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER]: OverwriteT<
        Api2.Definitions.EntityStatusDetails,
        {
            status: ApiTourStatusT;
        }
    >;
    [NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED]: {
        status?: TransportOrderStatusEnum;
        cityFrom?: string;
        cityTo?: string;
    };
    [NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED]: {
        fromCity: string;
        toCity: string;
        transportationOrderId: TransportOrderIdT;
        transportationOrderNumber: string;
        dispatchId: DispatchIdT;
        dispatchNumber: string;
    };
    [NotificationActionEnum.SPOT_CANCELLED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_ASSIGNED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_EXPIRED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_BID_ACCEPTED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_BID_EXPIRED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_BID_MISSED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_BID_PLACED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.SPOT_PLACED]: Api2.Definitions.SpotNotificationBodyDto;
    [NotificationActionEnum.RFQ_ALERT]: Api2.Definitions.RFQMultipointShortDto;
    [NotificationActionEnum.DOCUMENT_COMPLETED]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.DOCUMENT_EXPIRED]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.DOCUMENT_EXPIRATION_SOON]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.DOCUMENT_REVOKED]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.DOCUMENT_UPDATED]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.DOCUMENT_UPLOAD]: {
        documentType: DocumentTypeT;
        companyName: string;
        companyType: PartnerTypeEnum;
        companyId: PartnerIdT;
    };
    [NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_REVOKED]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_UPDATED]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED]: Api2.Definitions.ShipperContractNotificationBodyDto;
    [NotificationActionEnum.CARRIER_CONTRACT_EXPIRED]: Api2.Definitions.CarrierContractNotificationBodyDto;
    [NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON]: Api2.Definitions.CarrierContractNotificationBodyDto;
    [NotificationActionEnum.CARRIER_CONTRACT_REVOKED]: Api2.Definitions.CarrierContractNotificationBodyDto;
    [NotificationActionEnum.CARRIER_CONTRACT_UPDATED]: Api2.Definitions.CarrierContractNotificationBodyDto;
    [NotificationActionEnum.CARRIER_CONTRACT_COMPLETED]: Api2.Definitions.CarrierContractNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED]: Api2.Definitions.ShipperContractLaneNotificationBodyDto;
    [NotificationActionEnum.UNKNOWN_ACTION]: {};
};

export type AnyNotificationBodyT = ValueOfT<NotificationBodyT>;

export type FetchNotificationsPageQueryT =
    OverwritePageRequestWithSortQueryT<ApiPaths.ApiV1Notifications.Get.QueryParameters>;
