import * as React from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal');

type PropsT = {};

class Modal extends React.Component<PropsT> {
    element: HTMLDivElement;

    constructor(props: PropsT) {
        super(props);

        this.element = document.createElement('div');
    }

    componentDidMount(): void {
        if (modalRoot) {
            modalRoot.appendChild(this.element);
        }
    }

    componentWillUnmount(): void {
        if (modalRoot) {
            modalRoot.removeChild(this.element);
        }
    }

    render(): React.ReactElement {
        const { children } = this.props;

        return createPortal(children, this.element);
    }
}
export default Modal;
