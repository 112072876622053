import postalCodesJs from 'postal-codes-js';
import formats from 'postal-codes-js/formats-web';

const prepareCountryCode = (countryCode?: string | null): string => (countryCode ? countryCode.toUpperCase() : '');

export const isValid = (countryCode: string | null, postalCode: string | number): boolean => {
    if (!countryCode) {
        return false;
    }

    const result = postalCodesJs.validate(countryCode, postalCode);

    return typeof result !== 'string';
};

export const hasZipCodeValidation = (countryCode?: string | null): boolean => {
    const preparedCountryCode = prepareCountryCode(countryCode);

    return !!formats(`${preparedCountryCode}.json`);
};

export const DEFAULT_PLACEHOLDER = '';

export const getZipCodePlaceholder = (countryCode: string | null): string => {
    if (!countryCode) {
        return '';
    }

    const preparedCountryCode = prepareCountryCode(countryCode);

    const formatConfig = formats(`${preparedCountryCode}.json`);

    const description = formatConfig?.Description;
    const descriptionPlaceholder = (description || '').split(':')[1];
    if (descriptionPlaceholder) {
        return descriptionPlaceholder.trim();
    }

    const firstValidExample = formatConfig?.TestData?.Valid?.[0];
    if (firstValidExample) {
        return firstValidExample;
    }

    return DEFAULT_PLACEHOLDER;
};

export const getZipCodeExample = (countryCode: string | null): string => {
    const preparedCountryCode = prepareCountryCode(countryCode);

    const formatConfig = formats(`${preparedCountryCode}.json`);

    const firstValidExample = formatConfig?.TestData?.Valid?.[0];
    if (firstValidExample) {
        return firstValidExample;
    }

    return DEFAULT_PLACEHOLDER;
};
