import { SharableStateT } from '../models';
import { NotificationsStateT } from 'common/store/notifications/types';
import { RequestStatusT } from 'common/utils/request-status';
import { isNonNil } from 'common/utils';
import { AnyNotificationT } from './models';
import { createSelector } from 'reselect';

const NEW_NOTIFICATIONS_LIMIT = 6;

export const selectNewNotifications = (state: SharableStateT): AnyNotificationT[] => {
    return state.notifications.newNotificationIds.slice(0, NEW_NOTIFICATIONS_LIMIT).map((id) => {
        return state.notifications.byId[id];
    });
};

export const selectUnReadNotificationsCount = (state: SharableStateT): number => {
    return state.notifications.unreadNotifications.ids.length;
};

export const selectUnReadNotificationsState = (state: SharableStateT): NotificationsStateT['unreadNotifications'] => {
    return state.notifications.unreadNotifications;
};

export const selectUnReadNotifications = createSelector(
    selectUnReadNotificationsState,
    (unreadNotifications): AnyNotificationT[] => {
        const { ids, byId } = unreadNotifications;

        return ids
            .map((id) => {
                return id ? byId[id] : null;
            })
            .filter(isNonNil);
    },
);

export const selectRelatedIdsSet = createSelector(
    selectUnReadNotificationsState,
    (unreadNotifications): Set<string> => {
        const updatedOrderIdsSet = new Set<string>();

        unreadNotifications.ids.forEach((id) => {
            if (!id) {
                return;
            }

            unreadNotifications.byId[id].relatedIds.forEach((id) => {
                updatedOrderIdsSet.add(id);
            });
        });

        return updatedOrderIdsSet;
    },
);

export const selectUnReadNotificationsRequest = (state: SharableStateT): RequestStatusT => {
    return state.notifications.unreadNotifications.fetchRequest;
};

export const selectCurrentPageNumber = (state: SharableStateT): number => {
    return state.notifications.currentPageNumber;
};

export const selectNotificationsQuery = (state: SharableStateT): NotificationsStateT['query'] => {
    return state.notifications.query;
};

export const selectNotificationsById = (state: SharableStateT): NotificationsStateT['byId'] => {
    return state.notifications.byId;
};

export const selectNotificationsPages = (state: SharableStateT): NotificationsStateT['pages'] => {
    return state.notifications.pages;
};

export const selectNotificationsTotal = (state: SharableStateT): NotificationsStateT['total'] => {
    return state.notifications.total;
};

export const selectMarkAsReadRequest = (state: SharableStateT): NotificationsStateT['markAsReadRequest'] => {
    return state.notifications.markAsReadRequest;
};
