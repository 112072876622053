import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LeftCardLayout.scss';

const cx = classNames.bind(styles);

type PropsT = {
    cardNode: React.ReactNode;
    testSelector?: string;
};

const LeftCardLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, cardNode, testSelector } = props;

    return (
        <div className={cx('left-card-layout')} data-test-selector={testSelector}>
            <div className={cx('left-card-layout__left')}>{cardNode}</div>
            <div className={cx('left-card-layout__right')}>{children}</div>
        </div>
    );
});

export default LeftCardLayout;
