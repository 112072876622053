import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredContractCard.scss';
import { Trans } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    contractName: string;
};

const ExpiredContractCard: React.FC<PropsT> = React.memo((props) => {
    const { contractName } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="common:notifications.contract-expired"
                components={{
                    contractName,
                }}
            />
        </div>
    );
});

export default ExpiredContractCard;
