import {
    APPROVE_CARRIER_CONTRACT_REQUEST,
    ApproveCarrierContractActionT,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
    DownloadCarrierContractActionT,
    DownloadCarrierContractBeginActionT,
    DownloadCarrierContractErrorActionT,
    DownloadCarrierContractSuccessActionT,
    FETCH_CARRIER_CONTRACTS_REQUEST,
    FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN,
    FETCH_CARRIER_CONTRACTS_REQUEST_ERROR,
    FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS,
    FetchCarrierContractsActionT,
    FetchCarrierContractsBeginActionT,
    FetchCarrierContractsErrorActionT,
    FetchCarrierContractsSuccessActionT,
    REJECT_CARRIER_CONTRACT_REQUEST,
    RejectCarrierContractActionT,
    REVOKE_CARRIER_CONTRACT_REQUEST,
    RevokeCarrierContractActionT,
    UPDATE_CARRIER_CONTRACT_REQUEST,
    UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN,
    UPDATE_CARRIER_CONTRACT_REQUEST_ERROR,
    UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS,
    UpdateCarrierContractActionT,
    UpdateCarrierContractBeginActionT,
    UpdateCarrierContractErrorActionT,
    UpdateCarrierContractSuccessActionT,
    UPLOAD_CARRIER_CONTRACT_REQUEST,
    UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
    UploadCarrierContractActionT,
    UploadCarrierContractBeginActionT,
    UploadCarrierContractErrorActionT,
    UploadCarrierContractSuccessActionT,
} from './types';
import { ContractDetailsChangesT, PartnerContractT } from './models';
import { ApiRevokePartnerContractInfoT } from 'common/utils/api/models';

export const fetchCarrierContracts = (
    partnerId: PartnerIdT,
    options?: FetchOptionsT,
): FetchCarrierContractsActionT => ({
    type: FETCH_CARRIER_CONTRACTS_REQUEST,
    partnerId,
    options,
});

export const fetchCarrierContractsBegin = (partnerId: PartnerIdT): FetchCarrierContractsBeginActionT => ({
    type: FETCH_CARRIER_CONTRACTS_REQUEST_BEGIN,
    partnerId,
});

export const fetchCarrierContractsSuccess = (
    partnerId: PartnerIdT,
    partnerContracts: Array<PartnerContractT>,
): FetchCarrierContractsSuccessActionT => ({
    type: FETCH_CARRIER_CONTRACTS_REQUEST_SUCCESS,
    partnerId,
    partnerContracts,
});

export const fetchCarrierContractsError = (partnerId: PartnerIdT, error: Error): FetchCarrierContractsErrorActionT => ({
    type: FETCH_CARRIER_CONTRACTS_REQUEST_ERROR,
    error,
    partnerId,
});

export const revokeCarrierContract = ({
    partnerId,
    contractId,
    revokeInfo,
}: {
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    revokeInfo: ApiRevokePartnerContractInfoT;
}): RevokeCarrierContractActionT => ({
    type: REVOKE_CARRIER_CONTRACT_REQUEST,
    revokeInfo,
    contractId,
    partnerId,
});

export const approveCarrierContract = ({
    partnerId,
    contractId,
    contractChanges,
}: {
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    contractChanges: ContractDetailsChangesT;
}): ApproveCarrierContractActionT => ({
    type: APPROVE_CARRIER_CONTRACT_REQUEST,
    partnerId,
    contractId,
    contractChanges,
});

export const rejectCarrierContract = ({
    partnerId,
    contractId,
    reason,
}: {
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    reason: string;
}): RejectCarrierContractActionT => ({
    type: REJECT_CARRIER_CONTRACT_REQUEST,
    reason,
    contractId,
    partnerId,
});

export const updateCarrierContract = ({
    partnerId,
    contractId,
    contractChanges,
}: {
    partnerId: PartnerIdT;
    contractId: CarrierContractIdT;
    contractChanges: ContractDetailsChangesT;
}): UpdateCarrierContractActionT => ({
    type: UPDATE_CARRIER_CONTRACT_REQUEST,
    contractChanges,
    contractId,
    partnerId,
});

export const updateCarrierContractBegin = (
    partnerId: PartnerIdT,
    updatingContractId: CarrierContractIdT | null,
): UpdateCarrierContractBeginActionT => ({
    type: UPDATE_CARRIER_CONTRACT_REQUEST_BEGIN,
    partnerId,
    updatingContractId,
});

export const updateCarrierContractSuccess = (partnerId: PartnerIdT): UpdateCarrierContractSuccessActionT => ({
    type: UPDATE_CARRIER_CONTRACT_REQUEST_SUCCESS,
    partnerId,
});

export const updateCarrierContractError = (partnerId: PartnerIdT, error: Error): UpdateCarrierContractErrorActionT => ({
    type: UPDATE_CARRIER_CONTRACT_REQUEST_ERROR,
    error,
    partnerId,
});

export const uploadCarrierContract = (partnerId: PartnerIdT, file: File): UploadCarrierContractActionT => ({
    type: UPLOAD_CARRIER_CONTRACT_REQUEST,
    file,
    partnerId,
});

export const uploadCarrierContractBegin = (partnerId: PartnerIdT): UploadCarrierContractBeginActionT => ({
    type: UPLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    partnerId,
});

export const uploadCarrierContractSuccess = (partnerId: PartnerIdT): UploadCarrierContractSuccessActionT => ({
    type: UPLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
    partnerId,
});

export const uploadCarrierContractError = (error: Error, partnerId: PartnerIdT): UploadCarrierContractErrorActionT => ({
    type: UPLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    error,
    partnerId,
});

export const downloadCarrierContract = (
    contractId: CarrierContractIdT,
    partnerId: PartnerIdT,
): DownloadCarrierContractActionT => ({
    type: DOWNLOAD_CARRIER_CONTRACT_REQUEST,
    contractId,
    partnerId,
});

export const downloadCarrierContractBegin = (partnerId: PartnerIdT): DownloadCarrierContractBeginActionT => ({
    type: DOWNLOAD_CARRIER_CONTRACT_REQUEST_BEGIN,
    partnerId,
});

export const downloadCarrierContractSuccess = (partnerId: PartnerIdT): DownloadCarrierContractSuccessActionT => ({
    type: DOWNLOAD_CARRIER_CONTRACT_REQUEST_SUCCESS,
    partnerId,
});

export const downloadCarrierContractError = (
    error: Error,
    partnerId: PartnerIdT,
): DownloadCarrierContractErrorActionT => ({
    type: DOWNLOAD_CARRIER_CONTRACT_REQUEST_ERROR,
    error,
    partnerId,
});
