import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DeleteIcon from 'common/icons/DeleteIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

type PropsT = {
    className?: string;
    onClick: () => void;
};

const DeleteWaypointTrigger: React.FC<PropsT> = React.memo((props) => {
    const { className, onClick } = props;

    const { t } = useTranslation();

    return (
        <Tooltip
            position={TooltipPositionEnum.centerLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                    {t('common:new-order-form.route-editor.delete-point')}
                </TooltipContent>
            }
        >
            {() => (
                <TransparentTrigger
                    className={className}
                    spaces="s"
                    onClick={onClick}
                    leftIcon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                    reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                />
            )}
        </Tooltip>
    );
});

export default DeleteWaypointTrigger;
