import React from 'react';
import classNames from 'classnames/bind';

import styles from './InviteUserForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AsyncFormErrorsT, FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { ApiAddCompanyUserRequestT, RolesEnum, UserStatusEnum } from 'common/utils/api/models';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import { CompanyContactT } from 'common/store/members/models';
import { RequestStatusT } from 'common/utils/request-status';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import { TranziitApiRequestErrorSubTypeEnum } from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import UserRoleDropdown, {
    getAvailableRolesByPartnerType,
} from 'common/components/dropdowns/UserRoleDropdown/UserRoleDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    contacts: CompanyContactT[];
    addMemberRequestStatus: RequestStatusT;
    onInviteUser: (newUser: ApiAddCompanyUserRequestT) => void;
    initialEmail: string | null | undefined;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.email]: '',
    [FieldsEnum.role]: RolesEnum.driver,
};

const INITIAL_TOUCHED: Record<FieldsEnum, boolean> = {
    [FieldsEnum.role]: true,
    [FieldsEnum.email]: false,
};

const ROLES_SET: Record<string, Set<RolesEnum>> = {
    addUsers: new Set([RolesEnum.companyAdmin]),
};

const InviteUserForm: React.FC<PropsT> = (props) => {
    const { contacts, onInviteUser, initialEmail, addMemberRequestStatus } = props;

    const { partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const availableRoles = React.useMemo((): Array<RolesEnum> => {
        return getAvailableRolesByPartnerType(partnerType);
    }, [partnerType]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        if (initialEmail) {
            values[FieldsEnum.email] = initialEmail;
        }

        values[FieldsEnum.role] = availableRoles[0] || null;

        const errors = validateForm(t, values);

        return [values, errors];
    }, [initialEmail, availableRoles]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        initialTouched: INITIAL_TOUCHED,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const role = values[FieldsEnum.role];
            if (!role) {
                return;
            }

            onInviteUser({
                email: values[FieldsEnum.email],
                role,
            });

            formikHelpers.setTouched({});
        },
    });

    const selectedRole = formik.values[FieldsEnum.role];
    const warnings = React.useMemo(() => {
        const hasActivedAdmin = contacts.find((contact) => {
            return (
                contact.userStatus !== UserStatusEnum.archived &&
                contact.roles.some((roleInfo) => {
                    return roleInfo.role === RolesEnum.companyAdmin;
                })
            );
        });

        if (selectedRole && !ROLES_SET.addUsers.has(selectedRole) && !hasActivedAdmin) {
            return {
                [FieldsEnum.role]: t('common:team-members.warnings.wrong-role-for-add-users', {
                    role: t(`common:team-members.roles.${selectedRole}`),
                }),
            };
        }

        return {
            [FieldsEnum.role]: null,
        };
    }, [contacts, selectedRole]);

    const renderEmailError = (asyncError: AsyncFormErrorsT[FieldsEnum.email]): string | null => {
        if (asyncError === TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist) {
            const sameEmailContact = contacts.find((contact) => {
                return contact.email === formik.values[FieldsEnum.email];
            });

            if (sameEmailContact?.userStatus === UserStatusEnum.pending) {
                return t('common:team-members.invite-form.errors.email-already-exist-pending');
            }

            if (sameEmailContact?.userStatus === UserStatusEnum.archived) {
                return t('common:team-members.invite-form.errors.email-already-exist-archived');
            }

            return t('common:team-members.invite-form.errors.email-already-exist');
        }

        return asyncError || null;
    };

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(addMemberRequestStatus);
    }, [addMemberRequestStatus.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    name={FieldsEnum.email}
                    error={formik.errors[FieldsEnum.email]}
                    meta={formik.getFieldMeta(FieldsEnum.email)}
                    asyncError={renderEmailError(asyncFormErrors[FieldsEnum.email])}
                    resetAsyncError={resetAsyncFormErrors}
                    label={t('common:team-members.invite-form.fields.email.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.email}
                            value={formik.values[FieldsEnum.email]}
                            placeholder=""
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.role}
                    error={formik.errors[FieldsEnum.role]}
                    meta={formik.getFieldMeta(FieldsEnum.role)}
                    warning={warnings[FieldsEnum.role]}
                    label={t('common:team-members.invite-form.fields.position.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <UserRoleDropdown
                            value={formik.values[FieldsEnum.role]}
                            onChange={props.onChange}
                            placeholder={t('common:team-members.invite-form.fields.position.placeholder')}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            availableRoles={availableRoles}
                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                        />
                    )}
                </FormikField>
            </div>
            <Button
                theme={ButtonThemeEnum.primary}
                isLoading={addMemberRequestStatus.loading}
                className={cx('button')}
                type="submit"
            >
                {t('common:team-members.submit.ready')}
            </Button>
        </form>
    );
};

export default InviteUserForm;
