import React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const SkypeIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M11.641 15.816c3.553-.754 4.02-4.054 2.641-5.581-.66-.745-1.65-1.158-4.003-1.736-1.073-.255-3.105-.731-2.352-1.86.247-.415.867-.662 1.691-.704 2.843-.195 2.248 2.951 4.21 2.026.908-.455.825-1.943-.165-2.812-2.17-1.796-8.378-1.774-8.378 2.15 0 3.82 5.39 2.972 6.768 4.507.713.891-.05 2.33-2.31 2.233-2.57-.191-1.889-2.646-3.467-2.646-1.197 0-1.61.992-.99 2.274 1.07 2.226 4.08 2.638 6.396 2.15h-.041zm1.175 3.027c-1.216-.398-1.155-.373-2.259-.323-4.348.202-7.708-2.522-8.79-6.22-.449-1.657.06-3.12-.45-4.725-1.57-4.204 3.02-7.923 6.734-6.1.33.163.535.202 1.808.202 1.15 0 1.602.04 2.176.203 2.588.646 4.683 2.301 5.791 4.604.7 1.413.945 2.868.781 4.523-.082.89-.082.97.123 1.495 1.282 3.703-2.224 7.299-5.874 6.342h-.04v-.001z"
            />
        </svg>
    );
};

SkypeIcon.displayName = 'SkypeIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.light,
};

export { storyProps };
export default SkypeIcon;
