import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerTypeCell.scss';
import parseTrailerModel from 'common/utils/parse-trailer-model';
import { RFQT } from 'common/store/rfqs/models';

type PropsT = {
    rfq: RFQT;
};

const cx = classNames.bind(styles);

const TrailerTypeCell: React.FC<PropsT> = React.memo((props) => {
    const { rfq } = props;

    const [trailer] = parseTrailerModel(rfq.trailerModel);

    return <div className={cx('type')}>{trailer}</div>;
});

export default TrailerTypeCell;
