import React, { useCallback } from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FilePickerInput.scss';
import CloseIcon from 'common/icons/CloseIcon';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import useTestSelector from 'common/utils/hooks/useTestSelector';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { pickFile } from 'common/utils/pick-file';
import { useTranslation } from 'react-i18next';
import { RemoteFile } from './models';
import noop from 'lodash/noop';
import { reduceFileNameIfNeed } from 'common/utils/file-name';
import { util } from 'prettier';
import hasSpaces = util.hasSpaces;

const cx = classNames.bind(styles);

type ValueT<T> = File | RemoteFile<T> | null;

export type IconMetaT = {
    isLoading: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    isDisabled: boolean;
    hasChanges: boolean;
    isEmpty: boolean;
};

export type PropsT<T = any> = {
    className?: string;
    renderLeftIcon: (meta: IconMetaT) => React.ReactNode;
    name: string;
    value: ValueT<T>;
    isLoading?: boolean;
    placeholder?: string;
    onChange: (value: ValueT<T>) => void;
    onBlur?: () => void;
    onDownload?: (data: T) => void;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    isDisabled?: boolean;
    hasChanges?: boolean;
    hasClearControl?: boolean;
    hasUploadNewControl?: boolean;
    testSelectorPrefix?: string;
    limitFileNameLength?: number;
    accept: 'application/pdf';
};

const DEFAULT_LIMIT_FILE_NAME_LENGTH = 65;

const FilePickerInput: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        name,
        renderLeftIcon,
        placeholder,
        onChange,
        onBlur,
        onDownload,
        hasError,
        hasWarning,
        isLoading,
        isDisabled,
        hasSuccess,
        className,
        hasChanges,
        hasClearControl,
        hasUploadNewControl,
        testSelectorPrefix,
        accept,
    } = props;

    const handleBlur = (): void => {
        if (onBlur) {
            onBlur();
        }
    };

    const handlePickFile = (): void => {
        pickFile(accept, (file) => {
            onChange(file);
            handleBlur();
        });
    };

    const handleReset = () => {
        onChange(null);
        handleBlur();
    };

    const isEmpty = !value;

    const testSelector = useTestSelector(testSelectorPrefix || name, 'file-picker');
    const resetControlTestSelector = useTestSelector(testSelector, 'reset');

    const leftIcon = isLoading ? (
        <ControlLoaderIcon fillColor={StyleGuideColorsEnum.charcoal} size={DEFAULT_ICON_SIZE} />
    ) : (
        renderLeftIcon({
            isLoading: !!isLoading,
            hasError: !!hasError,
            hasWarning: !!hasWarning,
            hasSuccess: !!hasSuccess,
            isDisabled: !!isDisabled,
            hasChanges: !!hasChanges,
            isEmpty,
        })
    );

    const { t } = useTranslation();

    const handleDownload = useCallback(() => {
        if (onDownload && value instanceof RemoteFile) {
            onDownload(value.downloadData);
        }
    }, [value, onDownload]);

    const labelNode = React.useMemo(() => {
        const limitFileNameLength = props.limitFileNameLength || DEFAULT_LIMIT_FILE_NAME_LENGTH;

        if (value instanceof RemoteFile) {
            return (
                <ClickInterceptorLabel>
                    <Link theme={LinkThemeEnum.charcoal} size={LinkSizeEnum.medium} onClick={handleDownload}>
                        <span title={value.name}>{reduceFileNameIfNeed(value.name, limitFileNameLength)}</span>
                    </Link>
                </ClickInterceptorLabel>
            );
        }

        if (value instanceof File) {
            return <div title={value.name}>{reduceFileNameIfNeed(value.name, limitFileNameLength)}</div>;
        }

        return placeholder || t('common:file-picker.placeholder');
    }, [value, placeholder, t]);

    return (
        <div
            className={cs(
                cx('input', {
                    'input--hasWarning': hasWarning,
                    'input--hasChanges': hasChanges,
                    'input--hasError': hasError,
                    'input--hasRightNode': isEmpty || (hasClearControl && !isEmpty),
                    'input--isDisabled': isDisabled,
                    'input--isClickable': isEmpty,
                }),
                className,
            )}
            onClick={isEmpty ? handlePickFile : noop}
        >
            {leftIcon && <div className={cx('icon', 'icon--isLeft')}>{leftIcon}</div>}
            <div
                className={cx('label', {
                    'label--isEmpty': isEmpty,
                    'label--isDisabled': isDisabled,
                })}
            >
                {labelNode}
            </div>
            {isEmpty && (
                <ClickInterceptorLabel>
                    <TransparentTrigger
                        spaces="xs"
                        isDisabled={isDisabled}
                        onClick={isDisabled ? noop : handlePickFile}
                        labelClassName={cx('upload-trigger')}
                        label={t('common:file-picker.upload')}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    />
                </ClickInterceptorLabel>
            )}
            {hasClearControl && !isEmpty && (
                <ClickInterceptorLabel>
                    <TransparentTrigger
                        spaces="xs"
                        isDisabled={isDisabled}
                        testSelector={resetControlTestSelector}
                        onClick={isDisabled ? noop : handleReset}
                        leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    />
                </ClickInterceptorLabel>
            )}
            {hasUploadNewControl && !isEmpty && (
                <ClickInterceptorLabel>
                    <TransparentTrigger
                        spaces="xs"
                        isDisabled={isDisabled}
                        onClick={isDisabled ? noop : handlePickFile}
                        labelClassName={cx('upload-trigger')}
                        label={t('common:file-picker.upload-new')}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    />
                </ClickInterceptorLabel>
            )}
        </div>
    );
});

export default FilePickerInput;
