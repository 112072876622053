import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import { RequestStatusT } from 'common/utils/request-status';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { useDictDocumentById } from 'common/layouts/BaseDocumentsLayout/hooks/use-dict-document';
import BigTrashIcon from 'common/icons/BigTrashIcon';

export type ConfirmRevokeDocumentModalDataT = {
    id: DocumentIdT;
    partnerType: PartnerTypeEnum;
    dictDocumentId: DictDocumentIdT;
};

type PropsT = {
    data: ConfirmRevokeDocumentModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (id: ConfirmRevokeDocumentModalDataT['id']) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'revoke-document-confirmation';

const RevokeDocumentsConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, onConfirm, requestStatus } = props;
    const { t } = useTranslation();

    const dictDocument = useDictDocumentById(data?.partnerType, data?.dictDocumentId);

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.keep'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_keep`,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onClose();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.revoke'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: requestStatus.loading,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onConfirm(data.id);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('common:documents.revoke-confirmation.title')}
                    message={t('common:documents.revoke-confirmation.description', {
                        document: dictDocument?.name,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default RevokeDocumentsConfirmation;
