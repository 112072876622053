import {
    CHANGE_RFQ_REQUEST_BEGIN,
    CHANGE_RFQ_REQUEST_ERROR,
    CHANGE_RFQ_REQUEST_SUCCESS,
    RFQsChangesActionT,
    RFQsChangesStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: RFQsChangesStateT = {
    id: null,
    request: requestStatus.initial,
};

export default (state = initialState, action: RFQsChangesActionT | DestroySessionActionT): RFQsChangesStateT => {
    switch (action.type) {
        case CHANGE_RFQ_REQUEST_BEGIN: {
            const { id } = action;

            return {
                ...state,
                id,
                request: requestStatus.loading,
            };
        }

        case CHANGE_RFQ_REQUEST_ERROR: {
            const { id, error } = action;

            if (id !== state.id) {
                return state;
            }

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }

        case CHANGE_RFQ_REQUEST_SUCCESS: {
            const { id } = action;

            if (id !== state.id) {
                return state;
            }

            return {
                ...state,
                request: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
