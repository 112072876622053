import React from 'react';

export type SyncFormValuesT<V extends FieldValues> = {
    formValues: V | null;
    syncFormValues: (formValues: V) => void;
    setFieldValueCallback: SetFieldValueCallbackT<V> | null;
    syncSetFieldValueCallback: (setFieldValue: SetFieldValueCallbackT<V>) => void;
};

export type SetFieldValueCallbackT<V extends FieldValues> = <T extends FieldPath<V>>(
    name: T,
    value: FieldPathValue<V, T>,
) => void;

const useSyncFormValues = <V extends FieldValues>(): SyncFormValuesT<V> => {
    const [formValues, syncFormValues] = React.useState<V | null>(null);
    const setFieldValueCallbackRef = React.useRef<SetFieldValueCallbackT<V> | null>(null);

    return React.useMemo(() => {
        return {
            formValues,
            syncFormValues,
            setFieldValueCallback: setFieldValueCallbackRef.current,
            syncSetFieldValueCallback: (callback) => {
                setFieldValueCallbackRef.current = callback;
            },
        };
    }, [formValues, syncFormValues]);
};

export { useSyncFormValues };
