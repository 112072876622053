import React from 'react';
import { GoogleMapsContextT } from 'common/utils/hooks/useGoogleMaps';

export type GoogleMapContextT = {
    googleMaps: GoogleMapsContextT | null;
};

const GoogleMapContext = React.createContext<GoogleMapContextT>({ googleMaps: null });

export default GoogleMapContext;
