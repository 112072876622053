import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ReviewFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { useSelector } from 'react-redux';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { selectUpdateCarrierContractRequest } from 'common/store/carrier-contracts/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onReject: () => void;
};

const ReviewFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { onReject } = props;

    const { partnerId } = usePartnerContext();

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const updateContractRequest = useSelector(selectUpdateCarrierContractRequest(partnerId));

    const { t } = useTranslation();

    const isLoading = updateContractRequest.loading;

    return (
        <div className={cx('content')}>
            <div className={cx('actions')}>
                <Button
                    theme={ButtonThemeEnum.danger}
                    className={cx('action', 'action--reject')}
                    onClick={onReject}
                    isDisabled={isLoading}
                >
                    {t('common:carrier-contracts.actions.reject')}
                </Button>
                <Button
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--approve')}
                    onClick={remoteFormActionsContext?.remoteFormCallbacks?.submit}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                >
                    {t('common:carrier-contracts.actions.approve')}
                </Button>
            </div>
        </div>
    );
});

export default ReviewFooterContent;
