import React from 'react';

import { FieldMetaProps } from 'formik';
import FieldLabel from 'design-system/components/form-field/FieldLabel/FieldLabel';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import FieldNotes from 'design-system/components/form-field/FieldNotes/FieldNotes';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './FormikField.scss';

const cx = classNames.bind(styles);

export type FormikFieldChildrenPropsT<ValueT> = {
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    onChange: (value: ValueT) => void;
    onBlur: () => void;
    onFocus: () => void;
    hasChanges: boolean;
};

export type FormikFieldPropsT<ValueT> = {
    name: string;
    label?: React.ReactNode;
    subLabel?: React.ReactNode;
    labelRightNode?: React.ReactNode;
    error?: any;
    warning?: string | null;
    success?: string | null;
    asyncError?: React.ReactNode;
    resetAsyncError?: (name: string) => void;
    meta: FieldMetaProps<ValueT>;
    isShowFocusError?: boolean;
    isAlwaysShowError?: boolean;
    isForceShowWarning?: boolean;
    className?: string;
    fieldLabelClassName?: string;
    withoutLabel?: boolean;
    withoutLabelAndNotes?: boolean;
    submitCount?: number;
    children: (props: FormikFieldChildrenPropsT<ValueT>) => React.ReactElement | null;
    setFieldValue: (name: string, value: ValueT) => void;
    setFieldTouched: (name: string, value: boolean) => void;
    tooltipNode?: React.ReactNode;
    tooltipPosition?: TooltipPositionEnum;
    isCheckbox?: boolean;
};

const FormikField = <ValueT,>(props: FormikFieldPropsT<ValueT>) => {
    const {
        name,
        label,
        subLabel,
        labelRightNode,
        error,
        warning,
        success,
        meta,
        isShowFocusError,
        isAlwaysShowError,
        isForceShowWarning,
        submitCount,
        asyncError,
        resetAsyncError,
        className,
        fieldLabelClassName,
        children,
        setFieldValue,
        setFieldTouched,
        withoutLabelAndNotes,
        withoutLabel,
        tooltipNode,
        tooltipPosition,
        isCheckbox,
    } = props;

    const [isFocused, setFocused] = React.useState<boolean>(false);

    const isShowFormikError =
        meta.touched ||
        (isNumber(submitCount) && submitCount > 0) ||
        (isShowFocusError && isFocused) ||
        isAlwaysShowError;
    const hasError = !!(error && isShowFormikError) || !!asyncError;

    const hasWarning = !!warning && (meta.touched || !!isForceShowWarning);

    const hasSuccess = !!success && !hasWarning && !hasError;

    const hasChanges = !isEqual(meta.value, meta.initialValue) && (meta.touched || isFocused);

    const onChange = React.useCallback(
        (value: ValueT) => {
            setFieldValue(name, value);

            if (asyncError && resetAsyncError) {
                resetAsyncError(name);
            }
        },
        [setFieldValue, name, resetAsyncError, asyncError],
    );

    const onFocus = React.useCallback(() => {
        setFocused(true);
    }, [setFocused]);

    const onBlur = React.useCallback(() => {
        setFocused(false);

        if (setFieldTouched) {
            setFieldTouched(name, true);
        }
    }, [setFocused, setFieldTouched, name]);

    const childrenProps: FormikFieldChildrenPropsT<ValueT> = React.useMemo(() => {
        return {
            hasError,
            hasWarning,
            hasSuccess,
            onChange,
            onBlur,
            onFocus,
            hasChanges,
        };
    }, [hasError, hasWarning, hasSuccess, onChange, onBlur, onFocus, hasChanges]);

    if (withoutLabelAndNotes) {
        return children(childrenProps);
    }

    return (
        <div className={cs(cx('field'), className)}>
            {!withoutLabel ? (
                <FieldLabel
                    label={label}
                    className={fieldLabelClassName}
                    subLabel={subLabel}
                    tooltipNode={tooltipNode}
                    tooltipPosition={tooltipPosition}
                    rightNode={labelRightNode}
                />
            ) : null}
            {children(childrenProps)}
            <FieldNotes
                className={cx('notes', {
                    'notes--checkbox': isCheckbox,
                })}
                hasError={hasError}
                hasWarning={hasWarning}
                hasSuccess={hasSuccess}
                error={error || asyncError}
                success={success}
                warning={warning}
            />
        </div>
    );
};

export default React.memo(FormikField) as typeof FormikField;
