import React from 'react';

export type OrderCreationPercentContextT = {
    percent: number;
    setPercent: (percent: number) => void;
};

const OrderCreationPercentContext = React.createContext<OrderCreationPercentContextT>({
    percent: 0,
    setPercent: () => {
        // nothing
    },
});

export default OrderCreationPercentContext;
