import * as React from 'react';
import { useTranslation } from 'react-i18next';

import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';

import classNames from 'classnames/bind';
import styles from './NewOrderPage.scss';
import ShipmentDetailsForm from './ShipmentDetailsForm/ShipmentDetailsForm';
import RouteEditingMap from './RouteEditingMap/RouteEditingMap';
import FormTitle from './FormTitle/FormTitle';
import GoogleMapContext from 'common/contexts/google-map-context';
import Steps from 'common/layouts/NewOrderPage/Steps/Steps';
import { Route, Switch } from 'react-router-dom';
import { OMSRoutesEnum } from 'common/constants';
import OffersForm from './OffersForm/OffersForm';
import CargoDetailsForm from './CargoDetailsForm/CargoDetailsForm';
import RouteMap from 'common/layouts/NewOrderPage/RouteMap/RouteMap';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { useDispatch } from 'react-redux';
import { resetOrderCreation } from 'common/store/order-creation/actions';
import SidedBarHeaderLayoutContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-header-layout-context';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import SideBarLayoutContextWrap from '../LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import PageTitle from 'common/components/PageTitle/PageTitle';
import PriceMatrixWithMap from './PriceMatrixWithMap/PriceMatrixWithMap';
import { useStepsContextValue } from 'common/layouts/NewOrderPage/hooks/use-steps-context-value';
import { useOrderCreationPercentContextValue } from 'common/layouts/NewOrderPage/hooks/use-order-creation-percent-context-value';
import StepsContext from 'common/layouts/NewOrderPage/contexts/steps-context';
import OrderCreationPercentContext from 'common/layouts/NewOrderPage/contexts/order-creation-percent-context';
import { SyncShipmentDetailsFormMapStateContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-map-state';
import { useSyncShipmentDetailsFormMapState } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/usy-sync-map-state';
import { SyncShipmentDetailsFormValuesContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-form-values';
import { useSyncFormValues } from 'common/utils/hooks/useSyncFormValues';
import { FormValuesT } from './ShipmentDetailsForm/constants';
import useSideBarFooterAlertsContextValue from 'common/utils/hooks/useSideBarFooterAlertsContextValue';
import SideBarFooterAlertsContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-footer-alerts-context';

const cx = classNames.bind(styles);

type PropsT = {
    isBroker?: boolean;
    onClose: () => void;
};

const NewOrderPage: React.FC<PropsT> = React.memo((props) => {
    const { isBroker, onClose } = props;

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const stepsContextValue = useStepsContextValue();
    const orderCreationPercentContextValue = useOrderCreationPercentContextValue();
    const syncShipmentDetailsFormValuesContextValue = useSyncFormValues<FormValuesT>();
    const syncShipmentDetailsFormMapStateContextValue = useSyncShipmentDetailsFormMapState();

    const { t } = useTranslation();

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchCountriesDict());

        return () => {
            dispatch(resetOrderCreation());
        };
    }, []);

    const containerRef = React.useRef<HTMLHeadingElement>(null);

    const sideBarFooterAlertsContext = useSideBarFooterAlertsContextValue();

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <SideBarFooterAlertsContext.Provider value={sideBarFooterAlertsContext}>
                <OrderCreationPercentContext.Provider value={orderCreationPercentContextValue}>
                    <SyncShipmentDetailsFormValuesContext.Provider value={syncShipmentDetailsFormValuesContextValue}>
                        <SyncShipmentDetailsFormMapStateContext.Provider
                            value={syncShipmentDetailsFormMapStateContextValue}
                        >
                            <PageTitle title={t('common:page-titles.order-creation')} />
                            <HorizontalColumnsLayout
                                leftColumnTitle={t('common:new-order-page.tabs.form')}
                                leftColumnNode={
                                    <SideBarLayoutContextWrap>
                                        <SidedBarHeaderLayoutContext.Provider value={containerRef}>
                                            <HeaderSideBarLayout className={cx('header')}>
                                                <FormTitle onClose={onClose} />
                                                <div ref={containerRef} />
                                            </HeaderSideBarLayout>
                                            <SideBarLayout>
                                                <>
                                                    <StepsContext.Provider value={stepsContextValue}>
                                                        <Steps className={cx('steps')} />
                                                        <Switch>
                                                            <Route exact path={OMSRoutesEnum.newOrder}>
                                                                <ShipmentDetailsForm isBroker={!!isBroker} />
                                                            </Route>
                                                            <Route path={OMSRoutesEnum.newOrderDetails}>
                                                                <CargoDetailsForm />
                                                            </Route>
                                                            <Route path={OMSRoutesEnum.newOrderDetailsWithLane}>
                                                                <CargoDetailsForm />
                                                            </Route>
                                                            <Route path={OMSRoutesEnum.newOrderOffers}>
                                                                <OffersForm />
                                                            </Route>
                                                        </Switch>
                                                    </StepsContext.Provider>
                                                </>
                                            </SideBarLayout>
                                        </SidedBarHeaderLayoutContext.Provider>
                                    </SideBarLayoutContextWrap>
                                }
                                rightColumnTitle={
                                    <Switch>
                                        <Route exact path={OMSRoutesEnum.newOrder}>
                                            {t('common:new-order-page.tabs.map')}
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderDetails}>
                                            {t('common:new-order-page.tabs.map')}
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderDetailsWithLane}>
                                            {t('common:new-order-page.tabs.map')}
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderOffers}>
                                            {t('common:new-order-page.tabs.info')}
                                        </Route>
                                    </Switch>
                                }
                                rightColumnNode={
                                    <Switch>
                                        <Route exact path={OMSRoutesEnum.newOrder}>
                                            <RouteEditingMap />
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderDetails}>
                                            <RouteMap />
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderDetailsWithLane}>
                                            <RouteMap />
                                        </Route>
                                        <Route path={OMSRoutesEnum.newOrderOffers}>
                                            <PriceMatrixWithMap />
                                        </Route>
                                    </Switch>
                                }
                            />
                        </SyncShipmentDetailsFormMapStateContext.Provider>
                    </SyncShipmentDetailsFormValuesContext.Provider>
                </OrderCreationPercentContext.Provider>
            </SideBarFooterAlertsContext.Provider>
        </GoogleMapContext.Provider>
    );
});

export default NewOrderPage;
