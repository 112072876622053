import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const MapIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    fillOpacity="0.3"
                    d="M4.75 14.732l2.25-.87V5.089l-2.25.757v8.887zm8.25.18l2.25-.757V5.268L13 6.144v8.768z"
                />
                <path
                    fill={strokeColor}
                    d="M16.375 3.25l-.12.022-4.005 1.553-4.5-1.575-4.23 1.425a.378.378 0 00-.27.36v11.34c0 .21.165.375.375.375l.12-.023 4.005-1.552 4.5 1.575 4.23-1.425a.378.378 0 00.27-.36V3.625a.371.371 0 00-.375-.375zM7 13.863l-2.25.87V5.845L7 5.088v8.774zm4.5 1.035l-3-1.05V5.101l3 1.05v8.745zm3.75-.743l-2.25.758V6.144l2.25-.87v8.88z"
                />
            </g>
        </svg>
    );
};

MapIcon.displayName = 'MapIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default MapIcon;
