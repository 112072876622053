import React from 'react';
import times from 'lodash/times';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './YearMonthForm.scss';
import { checkIsSameYear, getISOFutureDate, getISOPastDate, getYear } from 'common/utils/time';

const cx = classNames.bind(styles);

const MIN_MONTH_INDEX = 0;
const MAX_MONTH_INDEX = 11;

type PropsT = {
    date: Date;
    onChange: (month: Date) => void;
    minDate?: string | null;
    maxDate?: string | null;
};

const YearMonthForm: React.FC<PropsT> = React.memo((props) => {
    const { date, onChange } = props;

    const minDate = React.useMemo(() => {
        return props.minDate || getISOPastDate(80, 'year');
    }, [props.minDate]);

    const maxDate = React.useMemo(() => {
        return props.maxDate || getISOFutureDate(2, 'year');
    }, [props.maxDate]);

    const { t } = useTranslation();

    const monthsLabels = React.useMemo(() => {
        return times(12).map((index) => t(`common:month-name.${index}`));
    }, [t]);

    const minMonthIndexDayYear = React.useMemo(() => {
        if (!checkIsSameYear(minDate, date)) {
            return MIN_MONTH_INDEX;
        }

        return moment(minDate).month();
    }, [minDate, date]);

    const maxMonthIndexDayYear = React.useMemo(() => {
        if (!checkIsSameYear(maxDate, date)) {
            return MAX_MONTH_INDEX;
        }

        return moment(maxDate).month();
    }, [maxDate, date]);

    const years = React.useMemo(() => {
        const maxYear = getYear(maxDate);
        const minYear = getYear(minDate);

        const yearList = times(maxYear - minYear + 1)
            .map(Number)
            .map((index) => minYear + index);

        return yearList;
    }, [minDate, maxDate]);

    const handleChange = (event: any): void => {
        const { year, month } = event.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className={cx('wrap')}>
            <select
                className={cx('select', 'select--month')}
                name="month"
                onChange={handleChange}
                value={date.getMonth()}
            >
                {monthsLabels.map((monthLabel, index) => {
                    return (
                        <option
                            key={index}
                            value={index}
                            hidden={index < minMonthIndexDayYear || maxMonthIndexDayYear < index}
                        >
                            {monthLabel}
                        </option>
                    );
                })}
            </select>
            <select className={cx('select')} name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map((year, index) => (
                    <option key={index} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
});

export default YearMonthForm;
