import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const PartnersIcon: React.FC<PropsT> = (props) => {
    const { fillColor, fillOpacity, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M.9.9h16.2v16.2H.9z" />
                <path
                    fill={fillColor}
                    fillOpacity={fillOpacity || 1}
                    d="M6.347 11.7c.061-.155.088-.263.614-.466A5.583 5.583 0 019 10.856c.695 0 1.384.122 2.038.378.52.203.547.31.615.466H6.347zM9 6.3c.371 0 .675.304.675.675A.677.677 0 019 7.65a.677.677 0 01-.675-.675c0-.371.304-.675.675-.675"
                    opacity="0.3"
                />
                <path
                    fill={strokeColor}
                    d="M3.6 9.675c.742 0 1.35-.607 1.35-1.35 0-.742-.607-1.35-1.35-1.35-.743 0-1.35.607-1.35 1.35 0 .743.607 1.35 1.35 1.35zm.763.742c-.25-.04-.5-.067-.763-.067-.668 0-1.303.142-1.877.392A1.355 1.355 0 00.9 11.99v1.06h3.038v-1.087c0-.56.155-1.087.425-1.546zM14.4 9.676c.742 0 1.35-.607 1.35-1.35 0-.742-.607-1.35-1.35-1.35-.742 0-1.35.607-1.35 1.35 0 .743.607 1.35 1.35 1.35zm2.7 2.315c0-.546-.324-1.033-.823-1.248a4.692 4.692 0 00-1.877-.392c-.263 0-.513.027-.763.068.27.458.425.985.425 1.545v1.087H17.1v-1.06zm-5.238-1.876c-.79-.351-1.762-.608-2.862-.608s-2.072.264-2.862.608a2.017 2.017 0 00-1.188 1.85v1.086h8.1v-1.087a2.02 2.02 0 00-1.188-1.85zM6.347 11.7c.061-.155.088-.263.614-.466A5.583 5.583 0 019 10.856c.695 0 1.384.122 2.039.378.52.203.546.31.614.466H6.347zM9 6.3c.371 0 .675.304.675.675A.677.677 0 019 7.65a.677.677 0 01-.675-.675c0-.371.304-.675.675-.675m0-1.35c-1.12 0-2.025.905-2.025 2.025C6.975 8.095 7.879 9 9 9c1.12 0 2.025-.904 2.025-2.025 0-1.12-.905-2.025-2.025-2.025z"
                />
            </g>
        </svg>
    );
};

PartnersIcon.displayName = 'PartnersIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default PartnersIcon;
