import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ShipmentStatusPill.scss';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ShipmentStatusEnum, ApiShipmentStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    status?: ApiShipmentStatusT;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_STATUS: Record<ApiShipmentStatusT, PillLabelThemeEnum> = {
    [ShipmentStatusEnum.awaitingLoading]: PillLabelThemeEnum.slate,
    [ShipmentStatusEnum.loading]: PillLabelThemeEnum.slate,
    [ShipmentStatusEnum.inTransit]: PillLabelThemeEnum.slate,
    [ShipmentStatusEnum.unloading]: PillLabelThemeEnum.slate,
    [ShipmentStatusEnum.delivered]: PillLabelThemeEnum.brandDark,
    [ShipmentStatusEnum.canceled]: PillLabelThemeEnum.slate,
};

export const LOAD_STATUS_T_MAP: Record<ApiShipmentStatusT, string> = {
    [ShipmentStatusEnum.awaitingLoading]: 'common:load-status.AWAITING_LOADING',
    [ShipmentStatusEnum.loading]: 'common:load-status.LOADING',
    [ShipmentStatusEnum.inTransit]: 'common:load-status.IN_TRANSIT',
    [ShipmentStatusEnum.unloading]: 'common:load-status.UNLOADING',
    [ShipmentStatusEnum.delivered]: 'common:load-status.DELIVERED',
    [ShipmentStatusEnum.canceled]: 'common:load-status.CANCELED',
};

const ShipmentStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isSymmetrical } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap theme={PILL_THEME_BY_STATUS[status]} isSymmetrical={isSymmetrical}>
            {t(LOAD_STATUS_T_MAP[status])}
        </PillLabel>
    );
});

export default ShipmentStatusPill;
