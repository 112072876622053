import { ApiContactFormalEnum, CountryCodeT } from 'common/utils/api/models';
import { SocialMediaEnum } from 'common/constants';

export enum FieldsEnum {
    id = 'id',

    formal = 'formal',
    firstName = 'firstName',
    lastName = 'lastName',

    desktopPhone = 'desktopPhone',

    skype = 'skype',
    whatsapp = 'whatsapp',
    viber = 'viber',
    telegram = 'telegram',
    zoom = 'zoom',
    msTeams = 'msTeams',
    webEx = 'webEx',

    address = 'address',

    birthday = 'birthday',
    countryCode = 'countryCode',
    language = 'language',

    ratePerKm = 'ratePerKm',
}

export type FormValuesT = {
    [FieldsEnum.id]: string | null;

    [FieldsEnum.formal]: ApiContactFormalEnum;
    [FieldsEnum.firstName]: string;
    [FieldsEnum.lastName]: string;

    [FieldsEnum.desktopPhone]: string;

    [FieldsEnum.skype]?: string;
    [FieldsEnum.whatsapp]?: string;
    [FieldsEnum.viber]?: string;
    [FieldsEnum.telegram]?: string;
    [FieldsEnum.zoom]?: string;
    [FieldsEnum.msTeams]?: string;
    [FieldsEnum.webEx]?: string;

    [FieldsEnum.address]: string;

    [FieldsEnum.birthday]: Date | null;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.language]: string | null;

    [FieldsEnum.ratePerKm]: string;
};

export const MEDIA_FIELDS_NAME = [
    SocialMediaEnum.skype,
    SocialMediaEnum.whatsapp,
    SocialMediaEnum.viber,
    SocialMediaEnum.telegram,
    SocialMediaEnum.zoom,
    SocialMediaEnum.msTeams,
    SocialMediaEnum.webEx,
] as const;

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
