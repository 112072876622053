import React, { useMemo } from 'react';

export type MapSettingsT = {
    zoom: number | null;
    center: GeoPointT | null;
    boundingBox: BoundingBoxT | null;
};

export type SyncMapSettingsCallbackT = (mapSettings: MapSettingsT) => void;

export type SyncShipmentDetailsFormMapStateContextValueT = {
    isMapInitialized: boolean;
    setIsMapInitialized: (isMapInitialized: boolean) => void;
    editingRoutePointIndex: number | null;
    setEditingRoutePointIndex: (editingRoutePointIndex: number | null) => void;
    syncMapSettings: SyncMapSettingsCallbackT | null;
    setSyncMapSettings: (syncMapSettings: SyncMapSettingsCallbackT | null) => void;
    editingRoutePointErrorsIndexes: Array<number>;
    setHasEditingRoutePointErrorIndexes: (editingRoutePointErrorsIndexes: Array<number>) => void;
    hasEditingRoutePointError: boolean;
    initialBoundingBox: BoundingBoxT | null;
    setInitialBoundingBox: (initialBoundingBox: BoundingBoxT | null) => void;
};

export const useSyncShipmentDetailsFormMapState = (): SyncShipmentDetailsFormMapStateContextValueT => {
    const [isMapInitialized, setIsMapInitialized] = React.useState<boolean>(false);
    const [initialBoundingBox, setInitialBoundingBox] = React.useState<BoundingBoxT | null>(null);
    const [editingRoutePointErrorsIndexes, setHasEditingRoutePointErrorIndexes] = React.useState<Array<number>>([]);
    const [editingRoutePointIndex, setEditingRoutePointIndex] = React.useState<number | null>(null);
    const syncMapSettingsCallbackRef = React.useRef<SyncMapSettingsCallbackT | null>(null);

    return useMemo(() => {
        return {
            isMapInitialized,
            setIsMapInitialized,
            initialBoundingBox,
            setInitialBoundingBox,
            editingRoutePointIndex,
            setEditingRoutePointIndex,
            editingRoutePointErrorsIndexes,
            setHasEditingRoutePointErrorIndexes,
            syncMapSettings: syncMapSettingsCallbackRef.current,
            setSyncMapSettings: (callback) => {
                syncMapSettingsCallbackRef.current = callback;
            },
            hasEditingRoutePointError:
                editingRoutePointIndex !== null && editingRoutePointErrorsIndexes.includes(editingRoutePointIndex),
        };
    }, [
        isMapInitialized,
        setIsMapInitialized,
        initialBoundingBox,
        setInitialBoundingBox,
        editingRoutePointIndex,
        setEditingRoutePointIndex,
        editingRoutePointErrorsIndexes,
        setHasEditingRoutePointErrorIndexes,
    ]);
};
