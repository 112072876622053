export enum FieldsEnum {
    dateRange = 'date-range',
    dropoffLocation = 'dropoff-location',
}

export type FormValuesT = {
    [FieldsEnum.dateRange]: DateRangeT | null;
    [FieldsEnum.dropoffLocation]: LocationT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
