import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './SetUnavailableForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { prepareQuery } from './prepare-query';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { AssetTypeEnum } from 'common/constants';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';
import OpenedDatePicker from 'design-system/components/date-pickers/OpenedDatePicker/OpenedDatePicker';
import { getDateFromDate, getDatesBetweenDates } from 'common/utils/time';
import { SetUnavailableVehicleQueryT } from 'common/utils/api/models';
import { selectVehicleSchedule } from 'common/store/vehicle-schedules/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleSchedule } from 'common/store/vehicle-schedules/actions';
import uniq from 'lodash/uniq';
import { isNonNil } from 'common/utils';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import AssetServiceLocationContext from 'common/components/AssetSidebarContent/contexts/asset-service-location-context';
import isEqual from 'lodash/isEqual';

const cx = classNames.bind(styles);

type PropsT = {
    isLoading: boolean;
    assetId: AssetIdT | null;
    assetType: AssetTypeEnum;
    onSetUnavailable: (assetId: AssetIdT, query: SetUnavailableVehicleQueryT) => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.dateRange]: null,
    [FieldsEnum.dropoffLocation]: null,
};

const SetUnavailableForm: React.FC<PropsT> = (props) => {
    const { onSetUnavailable, assetId, assetType, isLoading } = props;

    const dispatch = useDispatch();

    const { location, setLocation } = useContext(AssetServiceLocationContext);
    useEffect(() => {
        return () => {
            setLocation(null);
        };
    }, []);

    const { t } = useTranslation();

    const schedule = useSelector(selectVehicleSchedule(assetId || ''));
    const disabledDates = React.useMemo(() => {
        let dates: string[] = [];

        schedule.forEach((event) => {
            let eventDates: Array<string> | null = null;

            if (event.truckId === assetId) {
                eventDates = getDatesBetweenDates([event.truckDateFrom, event.truckDateTo]);
            }

            if (event.trailerId === assetId) {
                eventDates = getDatesBetweenDates([event.trailerDateFrom, event.trailerDateTo]);
            }

            if (eventDates) {
                dates = [...dates, ...eventDates];
            }
        });

        return uniq(dates).filter(isNonNil);
    }, [schedule, assetId]);

    React.useEffect(() => {
        if (!assetId) {
            return;
        }

        dispatch(fetchVehicleSchedule(assetId));
    }, [assetId]);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, disabledDates);
    }, [t, disabledDates]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values, disabledDates);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const query = prepareQuery(values, assetType);
            if (!query) {
                return;
            }

            if (!assetId) {
                return;
            }

            onSetUnavailable(assetId, query);

            formikHelpers.setTouched({});
        },
    });

    useEffect(() => {
        const currentLocation = formik.values[FieldsEnum.dropoffLocation];

        if (!isEqual(location, currentLocation)) {
            formik.setFieldValue(FieldsEnum.dropoffLocation, location);
            formik.setFieldTouched(FieldsEnum.dropoffLocation, true);
        }
    }, [location]);

    const minTodayDate = React.useMemo(() => {
        const now = new Date();
        return getDateFromDate(now);
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    className={cx('field', 'field--date-range')}
                    name={FieldsEnum.dateRange}
                    error={formik.errors[FieldsEnum.dateRange]}
                    meta={formik.getFieldMeta(FieldsEnum.dateRange)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <OpenedDatePicker
                            isRange
                            hasYearMonthForm
                            value={formik.values[FieldsEnum.dateRange]}
                            placeholder={t('common:assets.set-unavailable.fields.date-range.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            minDate={minTodayDate}
                            disabledDates={disabledDates}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.dropoffLocation}
                    error={formik.errors[FieldsEnum.dropoffLocation]}
                    meta={formik.getFieldMeta(FieldsEnum.dropoffLocation)}
                    label={t('common:assets.set-unavailable.fields.dropoff-location.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:assets.set-unavailable.fields.dropoff-location.tooltip')}
                        </TooltipContent>
                    }
                >
                    {(props) => (
                        <GeoSuggest
                            name={FieldsEnum.dropoffLocation}
                            value={formik.values[FieldsEnum.dropoffLocation]}
                            placeholder={t('common:assets.set-unavailable.fields.dropoff-location.placeholder')}
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            onChange={(value) => {
                                props.onChange(value);

                                setLocation(value);
                            }}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            testSelector="point-to-drop-truck"
                            showClearControl={!!formik.values[FieldsEnum.dropoffLocation]}
                            overlayPosition="top"
                        />
                    )}
                </FormikField>
            </div>
            <Button
                theme={ButtonThemeEnum.danger}
                className={cx('submit')}
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
            >
                {t('common:assets.set-unavailable.submit')}
            </Button>
        </form>
    );
};

export default SetUnavailableForm;
