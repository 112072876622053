import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DummyPagination.scss';
import noop from 'lodash/noop';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import RightArrowIcon from 'common/icons/RightArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';

export type PropsT = {
    isDisabledPrevPage?: boolean;
    goToPrevPage: () => void;
    isDisabledNextPage?: boolean;
    goToNextPage: () => void;
};

const cx = classNames.bind(styles);

const colorsMap = {
    disabled: StyleGuideColorsEnum.light,
    active: StyleGuideColorsEnum.charcoal,
};

const DummyPagination: React.FC<PropsT> = React.memo((props) => {
    const { isDisabledPrevPage, goToNextPage, isDisabledNextPage, goToPrevPage } = props;

    const handlePrevPage = () => {
        if (isDisabledPrevPage) {
            return;
        }

        goToPrevPage();
    };

    const handleNextPage = () => {
        if (isDisabledNextPage) {
            return;
        }

        goToNextPage();
    };

    return (
        <div className={cx('pagination')} data-test-selector="pagination">
            <TransparentTrigger
                withoutBorderRadius
                isDisabled={isDisabledPrevPage}
                onClick={isDisabledPrevPage ? noop : handlePrevPage}
                testSelector="prev-page"
                leftIcon={<LeftArrowIcon fillColor={isDisabledPrevPage ? colorsMap.disabled : colorsMap.active} />}
                reflectionTheme={ReflectionThemeEnum.light}
            />
            <TransparentTrigger
                withoutBorderRadius
                isDisabled={isDisabledNextPage}
                onClick={isDisabledNextPage ? noop : handleNextPage}
                testSelector="next-page"
                leftIcon={<RightArrowIcon fillColor={isDisabledNextPage ? colorsMap.disabled : colorsMap.active} />}
                reflectionTheme={ReflectionThemeEnum.light}
            />
        </div>
    );
});

export default DummyPagination;
