import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchShipperContractsPageQueryT } from 'common/store/shipper-contracts/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { PageSortT } from 'common/utils/query';
import { ShipperContractsSortEnum } from 'common/utils/api/models';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<ShipperContractsSortEnum> | null,
): FetchShipperContractsPageQueryT => {
    return {
        textSearch: queryFilters[QueryFiltersKeysEnum.text] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
