import { authRoutesEnum, CommonRoutesEnum } from 'common/constants';

export const routesEnum = {
    home: authRoutesEnum.home,
    signIn: authRoutesEnum.signIn,
    signUp: authRoutesEnum.signUp,
    continueSignUp: authRoutesEnum.continueSignUp,
    forgotPassword: authRoutesEnum.forgotPassword,
    changePassword: authRoutesEnum.changePassword,
    confirmEmail: authRoutesEnum.confirmEmail,
    selfCreateCompany: authRoutesEnum.selfCreateCompany,

    accessDenied: CommonRoutesEnum.accessDenied,
    notFound: CommonRoutesEnum.notFound,
    profile: CommonRoutesEnum.profile,
    team: CommonRoutesEnum.team,
    account: CommonRoutesEnum.account,

    rfqs: '/rfqs',
    archive: '/archive',
    orders: CommonRoutesEnum.orders,
    invoices: '/invoices',

    shipperContracts: '/profile/shipper-contracts',
};

export default {};
