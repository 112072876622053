import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const DeleteUserIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M2 1h18v18H2z" />
                <path
                    fill={fillColor}
                    d="M9.2 8.65c.747 0 1.35-.603 1.35-1.35 0-.747-.603-1.35-1.35-1.35-.747 0-1.35.603-1.35 1.35 0 .747.603 1.35 1.35 1.35zm3.852 4.644A7.64 7.64 0 009.2 12.25a7.64 7.64 0 00-3.852 1.044c-.126.072-.198.225-.198.378v.369h8.1v-.369a.456.456 0 00-.198-.378z"
                />
                <path
                    fill={strokeColor}
                    d="M9.2 10.9a8.86 8.86 0 014.527 1.233c.549.315.873.918.873 1.548V15.4H3.8v-1.719c0-.63.324-1.233.873-1.548A8.86 8.86 0 019.2 10.9zm0 1.35c-1.35 0-2.682.36-3.852 1.053a.444.444 0 00-.198.378v.369h8.1v-.378c0-.153-.072-.306-.198-.378A7.64 7.64 0 009.2 12.25zm0-7.65c1.494 0 2.7 1.206 2.7 2.7 0 1.494-1.206 2.7-2.7 2.7a2.696 2.696 0 01-2.7-2.7c0-1.494 1.206-2.7 2.7-2.7zm9 3.375v1.35h-4.5v-1.35h4.5zm-9-2.025c-.747 0-1.35.603-1.35 1.35 0 .747.603 1.35 1.35 1.35.747 0 1.35-.603 1.35-1.35 0-.747-.603-1.35-1.35-1.35z"
                />
            </g>
        </svg>
    );
};

DeleteUserIcon.displayName = 'DeleteUserIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default DeleteUserIcon;
