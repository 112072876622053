import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ControlLoaderWithShadow.scss';
import cs from 'classnames';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';

const DEFAULT_OPACITY = 0.7;

const cx = classNames.bind(styles);

export type PropsT = {
    opacity?: number;
    size?: number;
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const ControlLoaderWithShadow: React.FC<PropsT> = (props) => {
    const { className, fillColor, size } = props;

    return (
        <div className={cs(cx('wrap'), className)}>
            <ControlLoaderIcon opacity={DEFAULT_OPACITY} fillColor={fillColor} size={size || DEFAULT_ICON_SIZE} />
        </div>
    );
};

export default ControlLoaderWithShadow;
