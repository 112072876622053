import {
    DOCUMENTS_DICT_REQUEST_BEGIN,
    DOCUMENTS_DICT_REQUEST_ERROR,
    DOCUMENTS_DICT_REQUEST_SUCCESS,
    DocumentsDictActionT,
    DocumentsDictStateT,
    PartnerTypeDocumentsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { isNonNil } from 'common/utils';
import keyBy from 'lodash/keyBy';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialPartnerTypeDocumentsState: PartnerTypeDocumentsStateT = {
    ids: [],
    byId: {},
    byType: {},
    fetchRequest: requestStatus.initial,
};

const initialState: DocumentsDictStateT = {};

export default (state = initialState, action: DocumentsDictActionT | DestroySessionActionT): DocumentsDictStateT => {
    switch (action.type) {
        case DOCUMENTS_DICT_REQUEST_BEGIN: {
            const { partnerType } = action;

            const currentPartnerTypeState = state[partnerType] || initialPartnerTypeDocumentsState;

            return {
                ...state,
                [partnerType]: {
                    ...currentPartnerTypeState,
                    fetchRequest: requestStatus.loading,
                },
            };
        }

        case DOCUMENTS_DICT_REQUEST_SUCCESS: {
            const { partnerType, documentsList } = action;

            const currentPartnerTypeState = state[partnerType] || initialPartnerTypeDocumentsState;

            const ids = documentsList.map((document) => document?.id).filter(isNonNil);
            const byId = keyBy(documentsList, 'id');
            const byType = keyBy(documentsList, 'type');

            return {
                ...state,
                [partnerType]: {
                    ...currentPartnerTypeState,
                    ids,
                    byId,
                    byType,
                    fetchRequest: requestStatus.ok,
                },
            };
        }

        case DOCUMENTS_DICT_REQUEST_ERROR: {
            const { error, partnerType } = action;

            const currentPartnerTypeState = state[partnerType] || initialPartnerTypeDocumentsState;

            return {
                ...state,
                [partnerType]: {
                    ...currentPartnerTypeState,
                    fetchRequest: requestStatus.setError(error),
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
