import * as React from 'react';
import { TrailerStatusEnum, ApiTrailerStatusT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiTrailerStatusT | null | undefined;
};

export const TRAILER_STATUS_T_MAP: Record<ApiTrailerStatusT, string> = {
    [TrailerStatusEnum.free]: 'common:trailer-status.FREE',
    [TrailerStatusEnum.inTransit]: 'common:trailer-status.IN_TRANSIT',
    [TrailerStatusEnum.unavailable]: 'common:trailer-status.UNAVAILABLE',
    [TrailerStatusEnum.archived]: 'common:trailer-status.ARCHIVED',
};

const TrailerStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(TRAILER_STATUS_T_MAP[status]);
});

export default TrailerStatus;
