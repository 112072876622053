import * as React from 'react';
import { ApiInvoiceStatusT, InvoiceStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import InvoiceStatus from 'common/components/status/InvoiceStatus/InvoiceStatus';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiInvoiceStatusT | null | undefined;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_INVOICE_STATUS: Record<ApiInvoiceStatusT, PillLabelThemeEnum> = {
    [InvoiceStatusEnum.draft]: PillLabelThemeEnum.slate,
    [InvoiceStatusEnum.open]: PillLabelThemeEnum.slate,
    [InvoiceStatusEnum.void]: PillLabelThemeEnum.orange,
    [InvoiceStatusEnum.paid]: PillLabelThemeEnum.brandAccent,
    [InvoiceStatusEnum.uncollectible]: PillLabelThemeEnum.tomatoRed,
};

const InvoiceStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isSymmetrical } = props;

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap isSymmetrical={isSymmetrical} theme={PILL_THEME_BY_INVOICE_STATUS[status]}>
            <InvoiceStatus status={status} />
        </PillLabel>
    );
});

export default InvoiceStatusPill;
