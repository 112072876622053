import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ReviewFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { logWarning } from 'common/utils/logger';
import { useSelector } from 'react-redux';
import { selectUpdateCompanyDocumentsRequest } from 'common/store/documents/selectors';
import { DocumentVersionT } from 'common/store/documents/models';
import { DictDocumentT } from 'common/store/documents-dict/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dictDocument: DictDocumentT | null;
    actualVersion: DocumentVersionT | null;
    onReject: (dictDocumentId: DictDocumentIdT, id: DocumentIdT) => void;
};

const ReviewFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { actualVersion, onReject, dictDocument } = props;

    const { partnerId } = usePartnerContext();

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const updateRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));

    const { t } = useTranslation();

    return (
        <div className={cx('content')}>
            <div className={cx('actions')}>
                <Button
                    theme={ButtonThemeEnum.danger}
                    className={cx('action', 'action--reject')}
                    onClick={() => {
                        if (!actualVersion?.id) {
                            logWarning('failed to reject, empty actualVersion?.id');
                            return;
                        }

                        if (!dictDocument?.id) {
                            logWarning('failed to reject, empty dictDocument?.id');
                            return;
                        }

                        onReject(dictDocument.id, actualVersion.id);
                    }}
                    isDisabled={updateRequest.loading}
                >
                    {t('common:document.actions.reject')}
                </Button>
                <Button
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--approve')}
                    onClick={remoteFormActionsContext?.remoteFormCallbacks?.submit}
                    isLoading={updateRequest.loading}
                    isDisabled={updateRequest.loading}
                >
                    {t('common:document.actions.approve')}
                </Button>
            </div>
        </div>
    );
});

export default ReviewFooterContent;
