import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentsTable, {
    TableColumnModsEnum,
    TableColumnT,
    TableRowMetaT,
    TableRowModsEnum,
    TableRowThemeEnum,
} from 'common/components/DocumentsTable/DocumentsTable';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import classNames from 'classnames/bind';
import styles from './BaseShipperContractsTable.scss';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import { ApiShipperContractStatusT } from 'common/utils/api/models';
import { ShipperContractsTableRowT } from './models';
import ContractNameCell from './cell-renderers/ContractNameCell/ContractNameCell';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import IdCell from './cell-renderers/IdCell/IdCell';
import OrdersCountCell from 'common/layouts/BaseShipperContractsLayout/BaseShipperContractsTable/cell-renderers/OrdersCountCell/OrdersCountCell';
import ShipperContractStatusPill from 'common/components/status-pill/ShipperContractStatusPill/ShipperContractStatusPill';
import { checkIsApiBrokerShipperContract } from 'common/store/shipper-contracts/type-guards';

type PropsT = {
    className?: string;
    tableName: string;
    rows: Array<ShipperContractsTableRowT>;
    onSelectRow?: (row: ShipperContractsTableRowT) => void;
    isLoading: boolean;
    testSelector?: string;
    headerNode?: ReactNode;
    footerNode?: ReactNode;
    onOpenUserDetails: (userId: UserIdT) => void;
    contactStatusesAttentionSet?: Set<ApiShipperContractStatusT | null>;
};

const cx = classNames.bind(styles);

const VALID_DATE_FORMAT = 'DD MMM YYYY';

const BaseShipperContractsTable: React.FC<PropsT> = React.memo((props) => {
    const {
        tableName,
        rows,
        isLoading,
        onOpenUserDetails,
        testSelector,
        className,
        headerNode,
        footerNode,
        onSelectRow,
        contactStatusesAttentionSet,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ShipperContractsTableRowT, void>> = [
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (row: ShipperContractsTableRowT) => <IdCell id={row.tztContractId} />,
            className: cx('table__column', 'table__column--id'),
            testSelector: 'id',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.name')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (row: ShipperContractsTableRowT) => <ContractNameCell name={row.name} />,
            className: cx('table__column', 'table__column--name'),
            testSelector: 'name',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.orders-counter')}</span>,
            headerClassName: cx('table__header', 'table__header--orders-counter'),
            headerMods: {
                [TableColumnModsEnum.center]: true,
            },
            render: (row: ShipperContractsTableRowT) => (
                <OrdersCountCell
                    status={row.status}
                    ordersLeft={row.ordersLeft}
                    maxNumberOfOrders={row.maxNumberOfOrders}
                />
            ),
            className: cx('table__column', 'table__column--orders-counter'),
            testSelector: 'orders-counter',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.upload')}</span>,
            headerClassName: cx('table__header', 'table__header--uploaded'),
            render: (row: ShipperContractsTableRowT) => {
                if (checkIsApiBrokerShipperContract(row)) {
                    return (
                        <EventCell
                            date={row.createdDate}
                            userId={row.createdById}
                            userFullName={row.createdByName}
                            byBroker
                            openUserDetails={onOpenUserDetails}
                        />
                    );
                }

                return <DateCell isBold isBigFont date={row.createdDate} />;
            },
            className: cx('table__column', 'table__column--uploaded'),
            testSelector: 'upload',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.valid-till')}</span>,
            headerClassName: cx('table__header', 'table__header--expire-date'),
            render: (row: ShipperContractsTableRowT) => {
                return <DateCell date={row.validTill} dateFormat={VALID_DATE_FORMAT} isBigFont isBold />;
            },
            className: cx('table__column', 'table__column--expire-date'),
            testSelector: 'valid-till',
        },
        {
            renderHeader: () => <span>{t('common:shipper-contracts.table.columns.status')}</span>,
            headerClassName: cx('table__header', 'table__header--status'),
            render: (row: ShipperContractsTableRowT) => {
                return <ShipperContractStatusPill status={row.status} isSymmetrical />;
            },
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const getRowMods = (meta: TableRowMetaT, row: ShipperContractsTableRowT) => {
        return {
            [TableRowModsEnum.hasLeftOrangeBorder]: row?.status && contactStatusesAttentionSet?.has(row.status),
        };
    };

    const getRowTheme = (): TableRowThemeEnum => {
        return TableRowThemeEnum.normal;
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ShipperContractsTableRowT> tableName={tableName} isLoading={isLoading} rows={rows}>
                {(rows, isUsedPrevRows) => (
                    <DocumentsTable<ShipperContractsTableRowT, void>
                        testSelector={testSelector}
                        className={className}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        getRowMods={getRowMods}
                        getRowTheme={getRowTheme}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                        headerNode={headerNode}
                        footerNode={footerNode}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default BaseShipperContractsTable;
