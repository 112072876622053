import * as React from 'react';

type PropsT = {
    isSelected?: boolean;
    isDisabled?: boolean;
    className?: string;
};

const BigRadioIcon: React.FC<PropsT> = (props) => {
    const { isSelected, isDisabled, className } = props;

    if (isDisabled) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                className={className}
            >
                <defs>
                    <rect id="po7krle2xa" width="30" height="30" x="0" y="0" rx="15" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="hy0vxgn9bb" fill="#fff">
                        <use xlinkHref="#po7krle2xa" />
                    </mask>
                    <use fill="#FFF" xlinkHref="#po7krle2xa" />
                    <rect
                        width="28"
                        height="28"
                        x="1"
                        y="1"
                        fill="#E2E5ED"
                        fillOpacity="0.3"
                        stroke="#E2E5ED"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        rx="14"
                    />
                    {isSelected && <circle cx="15" cy="15" r="6" fill="#9EA0A5" mask="url(#hy0vxgn9bb)" />}
                    <path fill="#BCBCBC" d="M0 0h30v30H0z" mask="url(#hy0vxgn9bb)" opacity="0.25" />
                </g>
            </svg>
        );
    }

    if (isSelected) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                className={className}
            >
                <defs>
                    <rect id="skzwne2zwa" width="30" height="30" x="0" y="0" rx="15" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <use fill="#8ABA2E" xlinkHref="#skzwne2zwa" />
                    <use fill="#000" fillOpacity="0.1" xlinkHref="#skzwne2zwa" />
                    <circle cx="15" cy="15" r="6" fill="#FFF" />
                </g>
            </svg>
        );
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            className={className}
        >
            <defs>
                <rect id="aa2nmlk0ha" width="30" height="30" x="0" y="0" rx="15" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill="#FFF" xlinkHref="#aa2nmlk0ha" />
                <rect
                    width="28"
                    height="28"
                    x="1"
                    y="1"
                    fill="#E2E5ED"
                    fillOpacity="0.3"
                    stroke="#E2E5ED"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    rx="14"
                />
            </g>
        </svg>
    );
};

BigRadioIcon.displayName = 'BigRadioIcon';

const storyProps: PropsT = {
    isSelected: true,
    isDisabled: false,
};

export { storyProps };
export default BigRadioIcon;
