import {
    OrdersStatsActionT,
    OrdersStatsStateT,
    FETCH_ORDERS_STATS_REQUEST_BEGIN,
    FETCH_ORDERS_STATS_REQUEST_ERROR,
    FETCH_ORDERS_STATS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: OrdersStatsStateT = {
    stats: null,
    fetchRequest: requestStatus.initial,
};

export default (state = initialState, action: OrdersStatsActionT | DestroySessionActionT): OrdersStatsStateT => {
    switch (action.type) {
        case FETCH_ORDERS_STATS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_ORDERS_STATS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case FETCH_ORDERS_STATS_REQUEST_SUCCESS: {
            const { statistics } = action;

            return {
                ...state,
                stats: statistics,
                fetchRequest: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
