import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { urlFactory } from 'common/utils/urls';
import classNames from 'classnames/bind';
import styles from './ShipperContractHeader.scss';

const cx = classNames.bind(styles);

type PropsT = {};

const ShipperContractHeader: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<{ contractId: ShipperContractIdT }>();

    const { contractId } = params;

    const contractDetailsState = useSelector(selectShipperContractDetailsState(contractId));

    const onGoBack = () => {
        goBackIfHasRePath(() => {
            history.push(urlFactory.shipperContracts());
        });
    };

    return (
        <div className={cx('header')}>
            <TransparentTrigger
                onClick={onGoBack}
                leftIcon={<LeftArrowIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                testSelector="shipper-contract-go-back"
                reflectionTheme={ReflectionThemeEnum.light}
            />
            <div className={cx('title')}>{contractDetailsState.data?.name}</div>
        </div>
    );
});

export default ShipperContractHeader;
