import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const DatabaseIcon: React.FC<PropsT> = (props) => {
    const { fillColor, fillOpacity, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M.9.9h16.2v16.2H.9z" />
                <path
                    fill={fillColor}
                    fillOpacity={fillOpacity || 1}
                    d="M4.275 6.975h9.45v-2.7h-9.45v2.7zm1.35-2.362a1.011 1.011 0 110 2.025 1.012 1.012 0 110-2.026zm-1.35 9.112h9.45v-2.7h-9.45v2.7zm1.35-2.362a1.011 1.011 0 110 2.024 1.011 1.011 0 110-2.024z"
                />
                <path
                    fill={strokeColor}
                    d="M14.4 9.675H3.6a.677.677 0 00-.675.675v4.05c0 .371.304.675.675.675h10.8a.677.677 0 00.675-.675v-4.05a.677.677 0 00-.675-.675zm-.675 4.05h-9.45v-2.7h9.45v2.7zm-8.1-.338a1.011 1.011 0 100-2.024 1.011 1.011 0 100 2.024zM14.4 2.925H3.6a.677.677 0 00-.675.675v4.05c0 .371.304.675.675.675h10.8a.677.677 0 00.675-.675V3.6a.677.677 0 00-.675-.675zm-.675 4.05h-9.45v-2.7h9.45v2.7zm-8.1-.337a1.012 1.012 0 10.001-2.025 1.012 1.012 0 00-.001 2.025z"
                />
            </g>
        </svg>
    );
};

DatabaseIcon.displayName = 'DatabaseIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default DatabaseIcon;
