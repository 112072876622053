import React from 'react';

import moment from 'moment';

import Input, { PropsT as InputPropsT } from 'common/components/Input/Input';
import { getDateMask, getDateRangeMask } from 'common/utils/input-masks';
import { formatDate } from 'common/utils/time';

export type InputDateValueT = Date | null;
export type InputDateValuePropsT = {
    isRange?: false;
    value: InputDateValueT;
    onChange: (value: InputDateValueT, selectedDate: InputDateValueT) => void;
};

export type InputRangeValueT = DateRangeT | null;
export type InputRangeValuePropsT = {
    isRange: true;
    value: InputRangeValueT;
    onChange: (value: InputRangeValueT, selectedDate: InputDateValueT) => void;
};

export type IconMetaT = {
    isLoading: boolean;
    isDisabled: boolean;
    isFocused: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    isHighlighted: boolean;
    hasValue: boolean;
};

type BasePropsT = {
    name?: InputPropsT['name'];
    placeholder?: InputPropsT['placeholder'];
    isDisabled?: InputPropsT['isDisabled'];
    isForcedFocused?: InputPropsT['isFocused'];
    minDate?: string | null;
    maxDate?: string | null;
    hasError?: InputPropsT['hasError'];
    hasWarning?: InputPropsT['hasWarning'];
    autoComplete?: InputPropsT['autoComplete'];
    hasChanges?: InputPropsT['hasChanges'];
    onKeyUp?: InputPropsT['onKeyUp'];
    onBlur?: InputPropsT['onBlur'];
    onFocus?: InputPropsT['onFocus'];
    className?: string;
    renderLeftIcon?: (iconMeta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (iconMeta: IconMetaT) => React.ReactNode;
    isTransparent?: InputPropsT['isTransparent'];
    hasClearControl?: InputPropsT['hasClearControl'];
};

export type DateInputPropsT = (BasePropsT & InputDateValuePropsT) | (BasePropsT & InputRangeValuePropsT);

const EMPTY_RANGE: InputRangeValueT = { from: null, to: null };

const DATE_FORMAT = 'DD.MM.YYYY';
const DATES_SEPARATOR = ' - ';
const MASK_SYMBOL = '_';

const formatDateValue = (value: InputDateValueT): string | null => {
    return formatDate(DATE_FORMAT, value);
};

const formatDateRangeValue = (value: InputRangeValueT): string | null => {
    const startDate = formatDate(DATE_FORMAT, value?.from);
    const endDate = formatDate(DATE_FORMAT, value?.to);

    return [startDate, endDate].filter(Boolean).join(DATES_SEPARATOR);
};

const checkIsEmptyDateValue = (value: InputDateValueT): boolean => {
    return !value;
};

const checkIsEmptyDateRangeValue = (value: InputRangeValueT): boolean => {
    return !value?.from && !value?.to;
};

/* eslint-disable react/destructuring-assignment */
const DateInputTrigger: React.FC<DateInputPropsT> = (props) => {
    const {
        name,
        placeholder,
        onFocus,
        onBlur,
        onKeyUp,
        minDate,
        maxDate,
        isDisabled,
        isForcedFocused,
        hasError,
        hasWarning,
        hasChanges,
        autoComplete,
        className,
        renderLeftIcon,
        renderRightIcon,
        hasClearControl,
        isTransparent,
    } = props;

    const [isFocusedInput, setFocusedInput] = React.useState(false);

    const [inputValue, setInputValue] = React.useState(() => {
        if (props.isRange) {
            return formatDateRangeValue(props.value || null);
        }
        return formatDateValue(props.value || null);
    });

    const dateRangeMask = React.useMemo(() => getDateRangeMask(), []);
    const dateMask = React.useMemo(() => getDateMask(), []);

    React.useEffect(() => {
        if (!isFocusedInput) {
            const initInputValue = props.isRange ? formatDateRangeValue(props.value) : formatDateValue(props.value);
            setInputValue(initInputValue);
        }
    }, [props.value]);

    const handleChangeInputValue = (value: string) => {
        setInputValue(value);

        if (!value) {
            if (props.isRange) {
                props.onChange(EMPTY_RANGE, null);
            } else {
                props.onChange(null, null);
            }
            return;
        }

        if (!props.isRange) {
            const isValid = !value.includes(MASK_SYMBOL);
            const date = moment(value, DATE_FORMAT);
            if (
                isValid &&
                date.isValid() &&
                (minDate ? date.isAfter(minDate) : true) &&
                (maxDate ? date.isBefore(maxDate) : true)
            ) {
                props.onChange(date.toDate(), null);
            }
        }

        if (props.isRange) {
            const [startDateString, endDateString] = value.split(DATES_SEPARATOR);

            let fromDateRange: DateRangeT['from'] = null;

            const isValidStartDate = !startDateString.includes(MASK_SYMBOL);
            const startDate = moment(startDateString, DATE_FORMAT);

            // fix: for others timezones
            startDate.add(12, 'hours');

            const checkIsInMinMax = (date: moment.Moment): boolean => {
                return (minDate ? date.isAfter(minDate) : true) && (maxDate ? date.isBefore(maxDate) : true);
            };

            if (isValidStartDate && startDate.isValid() && checkIsInMinMax(startDate)) {
                fromDateRange = startDate.toDate();
            }

            let toDateRange: DateRangeT['to'] = null;

            const isValidEndDate = !endDateString.includes(MASK_SYMBOL);
            const endDate = moment(endDateString, DATE_FORMAT);

            // fix: for others timezones
            endDate.add(12, 'hours');

            if (isValidEndDate && endDate.isValid() && checkIsInMinMax(endDate)) {
                toDateRange = endDate.toDate();
            }

            let dateRange: DateRangeT = {
                from: fromDateRange,
                to: toDateRange,
            };

            if (fromDateRange && toDateRange && fromDateRange.valueOf() > toDateRange.valueOf()) {
                dateRange = {
                    from: toDateRange,
                    to: fromDateRange,
                };
            }

            props.onChange(dateRange, null);
        }
    };

    const isEmpty = props.isRange ? checkIsEmptyDateRangeValue(props.value) : checkIsEmptyDateValue(props.value);

    const handleBlur = () => {
        setFocusedInput(false);

        const initInputValue = props.isRange ? formatDateRangeValue(props.value) : formatDateValue(props.value);
        setInputValue(initInputValue);

        if (onBlur) {
            onBlur();
        }
    };

    const handleFocus = () => {
        setFocusedInput(true);

        if (onFocus) {
            onFocus();
        }
    };

    return (
        <Input
            className={className}
            name={name || 'date-input'}
            value={inputValue || ''}
            renderLeftIcon={
                renderLeftIcon
                    ? (iconMeta) =>
                          renderLeftIcon({
                              ...iconMeta,
                              hasValue: !isEmpty,
                          })
                    : undefined
            }
            placeholder={placeholder}
            onChange={handleChangeInputValue}
            hasError={hasError}
            onKeyUp={onKeyUp}
            onFocus={handleFocus}
            onBlur={handleBlur}
            hasWarning={hasWarning}
            isDisabled={isDisabled}
            isFocused={isForcedFocused}
            mask={props.isRange ? dateRangeMask : dateMask}
            hasChanges={hasChanges}
            autoComplete={autoComplete}
            renderRightIcon={
                renderRightIcon
                    ? (iconMeta) =>
                          renderRightIcon({
                              ...iconMeta,
                              hasValue: !isEmpty,
                          })
                    : undefined
            }
            isTransparent={isTransparent}
            guide
            hasClearControl={hasClearControl}
        />
    );
};

export default DateInputTrigger;
