import { RequestStatusT } from 'common/utils/request-status';
import {
    DocumentDetailsChangesT,
    DocumentIdsByTypeT,
    DocumentT,
    EvidenceOfTaxDetailsChangesT,
    EvidenceOfTaxDetailsT,
} from 'common/store/documents/models';
import { PartnerTypeEnum } from 'common/utils/api/models';

export const FETCH_DOCUMENTS_REQUEST = 'common/documents/FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_REQUEST_BEGIN = 'common/documents/FETCH_DOCUMENTS_REQUEST_BEGIN';
export const FETCH_DOCUMENTS_REQUEST_ERROR = 'common/documents/FETCH_DOCUMENTS_REQUEST_ERROR';
export const FETCH_DOCUMENTS_REQUEST_SUCCESS = 'common/documents/FETCH_DOCUMENTS_REQUEST_SUCCESS';

export const UPLOAD_DOCUMENT_REQUEST = 'common/documents/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_REQUEST_BEGIN = 'common/documents/UPLOAD_DOCUMENT_REQUEST_BEGIN';
export const UPLOAD_DOCUMENT_REQUEST_ERROR = 'common/documents/UPLOAD_DOCUMENT_REQUEST_ERROR';
export const UPLOAD_DOCUMENT_REQUEST_SUCCESS = 'common/documents/UPLOAD_DOCUMENT_REQUEST_SUCCESS';

export const APPROVE_DOCUMENT_REQUEST = 'common/documents/APPROVE_DOCUMENT_REQUEST';
export const APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST = 'common/documents/APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST';
export const REJECT_DOCUMENT_REQUEST = 'common/documents/REJECT_DOCUMENT_REQUEST';
export const REVOKE_DOCUMENT_REQUEST = 'common/documents/REVOKE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_REQUEST = 'common/documents/UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_REQUEST_BEGIN = 'common/documents/UPDATE_DOCUMENT_REQUEST_BEGIN';
export const UPDATE_DOCUMENT_REQUEST_ERROR = 'common/documents/UPDATE_DOCUMENT_REQUEST_ERROR';
export const UPDATE_DOCUMENT_REQUEST_SUCCESS = 'common/documents/UPDATE_DOCUMENT_REQUEST_SUCCESS';

export const DOWNLOAD_DOCUMENT_REQUEST = 'common/documents/DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_REQUEST_BEGIN = 'common/documents/DOWNLOAD_DOCUMENT_REQUEST_BEGIN';
export const DOWNLOAD_DOCUMENT_REQUEST_ERROR = 'common/documents/DOWNLOAD_DOCUMENT_REQUEST_ERROR';
export const DOWNLOAD_DOCUMENT_REQUEST_SUCCESS = 'common/documents/DOWNLOAD_DOCUMENT_REQUEST_SUCCESS';

export const FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST = 'common/documents/FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST';
export const FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN =
    'common/documents/FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN';
export const FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR =
    'common/documents/FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR';
export const FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS =
    'common/documents/FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS';

export const UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST = 'common/documents/UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST';
export const UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN =
    'common/documents/UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN';
export const UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR =
    'common/documents/UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR';
export const UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS =
    'common/documents/UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS';

export const RESET = 'common/documents/RESET';

export type FetchDocumentsActionT = {
    type: typeof FETCH_DOCUMENTS_REQUEST;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchDocumentsBeginActionT = {
    type: typeof FETCH_DOCUMENTS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchDocumentsSuccessActionT = {
    type: typeof FETCH_DOCUMENTS_REQUEST_SUCCESS;
    documentIdsByType: DocumentIdsByTypeT;
    documentsList: DocumentT[];
    companyId: CompanyIdT;
};

export type FetchDocumentsErrorActionT = {
    type: typeof FETCH_DOCUMENTS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type UploadDocumentActionT = {
    type: typeof UPLOAD_DOCUMENT_REQUEST;
    partnerType: PartnerTypeEnum;
    companyId: CompanyIdT;
    dictDocumentId: DictDocumentIdT;
    file: File;
};

export type UploadDocumentBeginActionT = {
    type: typeof UPLOAD_DOCUMENT_REQUEST_BEGIN;
    companyId: CompanyIdT;
    dictDocumentId: DictDocumentIdT;
};

export type UploadDocumentSuccessActionT = {
    type: typeof UPLOAD_DOCUMENT_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type UploadDocumentErrorActionT = {
    type: typeof UPLOAD_DOCUMENT_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DownloadDocumentActionT = {
    type: typeof DOWNLOAD_DOCUMENT_REQUEST;
    documentId: string;
    companyId: CompanyIdT;
};

export type DownloadDocumentBeginActionT = {
    type: typeof DOWNLOAD_DOCUMENT_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type DownloadDocumentSuccessActionT = {
    type: typeof DOWNLOAD_DOCUMENT_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type DownloadDocumentErrorActionT = {
    type: typeof DOWNLOAD_DOCUMENT_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type ApproveDocumentActionT = {
    type: typeof APPROVE_DOCUMENT_REQUEST;
    companyId: CompanyIdT;
    documentId: DocumentIdT;
    partnerType: PartnerTypeEnum;
    validTill: string | null;
};

export type ApproveEvidenceOfTaxDocumentActionT = {
    type: typeof APPROVE_EVIDENCE_OF_TAX_DOCUMENT_REQUEST;
    companyId: CompanyIdT;
    documentId: DocumentIdT;
    partnerType: PartnerTypeEnum;
    validTill: string | null;
    detailsChanges: EvidenceOfTaxDetailsChangesT;
};

export type RejectDocumentActionT = {
    type: typeof REJECT_DOCUMENT_REQUEST;
    documentId: DocumentIdT;
    reason: string;
    companyId: CompanyIdT;
    partnerType: PartnerTypeEnum;
};

export type RevokeDocumentActionT = {
    type: typeof REVOKE_DOCUMENT_REQUEST;
    documentId: DocumentIdT;
    companyId: CompanyIdT;
    partnerType: PartnerTypeEnum;
};

export type UpdateDocumentActionT = {
    type: typeof UPDATE_DOCUMENT_REQUEST;
    documentId: DocumentIdT;
    companyId: CompanyIdT;
    detailChanges: DocumentDetailsChangesT;
};

export type UpdateEvidenceOfTaxDetailsActionT = {
    type: typeof UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST;
    companyId: CompanyIdT;
    documentId: DocumentIdT;
    detailsChanges: EvidenceOfTaxDetailsChangesT;
};

export type UpdateEvidenceOfTaxDetailsBeginActionT = {
    type: typeof UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type UpdateEvidenceOfTaxDetailsSuccessActionT = {
    type: typeof UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type UpdateEvidenceOfTaxDetailsErrorActionT = {
    type: typeof UPDATE_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type UpdateDocumentBeginActionT = {
    type: typeof UPDATE_DOCUMENT_REQUEST_BEGIN;
    companyId: CompanyIdT;
    documentId: DocumentIdT;
};

export type UpdateDocumentSuccessActionT = {
    type: typeof UPDATE_DOCUMENT_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type UpdateDocumentErrorActionT = {
    type: typeof UPDATE_DOCUMENT_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type FetchEvidenceOfTaxDetailsActionT = {
    type: typeof FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST;
    companyId: CompanyIdT;
};

export type FetchEvidenceOfTaxDetailsBeginActionT = {
    type: typeof FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchEvidenceOfTaxDetailsSuccessActionT = {
    type: typeof FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_SUCCESS;
    evidenceOfTaxDetails: EvidenceOfTaxDetailsT;
    companyId: CompanyIdT;
};

export type FetchEvidenceOfTaxDetailsErrorActionT = {
    type: typeof FETCH_EVIDENCE_OF_TAX_DETAILS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type ResetDocumentActionT = {
    type: typeof RESET;
    companyId: CompanyIdT;
};

export type DocumentsActionT =
    | FetchDocumentsActionT
    | FetchDocumentsBeginActionT
    | FetchDocumentsSuccessActionT
    | FetchDocumentsErrorActionT
    | UpdateEvidenceOfTaxDetailsActionT
    | UpdateEvidenceOfTaxDetailsBeginActionT
    | UpdateEvidenceOfTaxDetailsSuccessActionT
    | UpdateEvidenceOfTaxDetailsErrorActionT
    | UploadDocumentActionT
    | UploadDocumentBeginActionT
    | UploadDocumentSuccessActionT
    | UploadDocumentErrorActionT
    | UpdateDocumentBeginActionT
    | UpdateDocumentSuccessActionT
    | UpdateDocumentErrorActionT
    | DownloadDocumentActionT
    | DownloadDocumentBeginActionT
    | DownloadDocumentSuccessActionT
    | DownloadDocumentErrorActionT
    | ApproveDocumentActionT
    | ApproveEvidenceOfTaxDocumentActionT
    | RejectDocumentActionT
    | RevokeDocumentActionT
    | FetchEvidenceOfTaxDetailsActionT
    | FetchEvidenceOfTaxDetailsBeginActionT
    | FetchEvidenceOfTaxDetailsSuccessActionT
    | FetchEvidenceOfTaxDetailsErrorActionT
    | ResetDocumentActionT;

export type CompanyDocumentsStateT = {
    idsByType: DocumentIdsByTypeT | null;
    byId: Record<DocumentIdT, DocumentT> | null;
    ids: DocumentT['id'][] | null;
    fetchDocumentsRequest: RequestStatusT;
    downloadDocumentRequest: RequestStatusT;
    uploadRequest: RequestStatusT;
    uploadingDictDocumentId: DictDocumentIdT | null;
    updateRequest: RequestStatusT;
    updatingDocumentId: DictDocumentIdT | null;
    evidenceOfTaxDetails: EvidenceOfTaxDetailsT | null;
    fetchEvidenceOfTaxDetailsRequest: RequestStatusT;
    updateEvidenceOfTaxDetailsRequest: RequestStatusT;
};

export type DocumentsStateT = Record<CompanyIdT, CompanyDocumentsStateT>;
