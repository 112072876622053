import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TooltipIconTrigger.scss';
import InfoIcon from 'common/icons/InfoIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isShow?: boolean;
};

const TooltipIconTrigger: React.FC<PropsT> = (props) => {
    const { className, isShow } = props;

    return (
        <InfoIcon
            className={cs(cx('icon'), className)}
            fillColor={isShow ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.light}
        />
    );
};

export default TooltipIconTrigger;
