import * as React from 'react';
import { useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import classNames from 'classnames/bind';

import scrollIntoView from 'scroll-into-view';

import MultiPointOrderDraftDetails, {
    MultiPointOrderDraftDetailsPropsT,
} from '../OrderDraftDetails/MultiPointOrderDraftDetails';
import OfferDetails from '../OfferDetails/OfferDetails';

import styles from './CargoDetailsForm.scss';
import {
    CARGO_DETAILS_FORM_NAME,
    FieldsEnum,
    FormTouchedT,
    FormValuesT,
    PointContactsFieldsEnum,
    PointContactsFormValuesT,
    RoutePointT,
    ShipmentFieldsEnum,
    ShipmentFormValuesT,
} from './constants';
import ValuesStorage from 'common/utils/form-values-storage';
import { FormikContext, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import validateForm from './validate-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, ensureCreatedRFQ } from 'common/store/order-creation/actions';
import {
    selectCreateOrderRequest,
    selectLanePriceOffer,
    selectPriceOffersById,
    selectRFQ,
} from 'common/store/order-creation/selectors';
import { formatAddressComponents } from 'common/utils/google-places';
import getterFormFillPercent from 'common/layouts/NewOrderPage/getter-form-fill-percent';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import {
    DEFAULT_ICON_SIZE,
    OMSRoutesEnum,
    PHONE_NUMBER_PREFIX,
    QueryKeysEnum,
    StyleGuideColorsEnum,
    UnitTypeEnum,
} from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { fetchAdditionalServices } from 'common/store/additional-services-dict/actions';
import { selectAdditionalServicesById } from 'common/store/additional-services-dict/selectors';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import isNumber from 'lodash/isNumber';
import ButtonTimer from 'common/components/ButtonTimer/ButtonTimer';
import { MS_IN_MIN } from 'common/utils/time';

import history from 'common/utils/history';
import OrderCreationPercentContext from 'common/layouts/NewOrderPage/contexts/order-creation-percent-context';
import { isNonNil } from 'common/utils';
import times from 'lodash/times';
import ShipmentPicker from 'common/layouts/NewOrderPage/CargoDetailsForm/ShipmentPicker/ShipmentPicker';
import FormikFieldArray from 'common/components/forms/FormikFieldArray/FormikFieldArray';
import PointContactsPicker from 'common/layouts/NewOrderPage/CargoDetailsForm/PointContactsPicker/PointContactsPicker';
import Card from 'design-system/components/Card/Card';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import NumberIcon from 'common/icons/NumberIcon';
import ErrorIndicatorLabel from 'common/layouts/NewOrderPage/CargoDetailsForm/ErrorIndicatorLabel/ErrorIndicatorLabel';
import PlusSignIcon from 'common/icons/PlusSignIcon';
import { checkAvailableService } from '../additional-services';
import { getApiLoadUnitType } from 'common/utils/units';
import { parsePhoneNumber, parseSimpleNumber, parseStringFormatter } from 'common/utils/input-parsers';
import { ApiCreateOrderRequestT } from 'common/utils/api/models';
import NotUsedRoutePointValidationAlertContent from 'common/layouts/NewOrderPage/CargoDetailsForm/footer-alert-contents/NotUsedRoutePointValidationAlertContent/NotUsedRoutePointValidationAlertContent';
import OverloadingTrailerValidationAlertContent from 'common/layouts/NewOrderPage/CargoDetailsForm/footer-alert-contents/OverloadingTrailerValidationAlertContent/OverloadingTrailerValidationAlertContent';
import { validateNotUsedRoutePoint } from 'common/layouts/NewOrderPage/CargoDetailsForm/validations/not-used-route-points';
import { validateOverloadingTrailer } from 'common/layouts/NewOrderPage/CargoDetailsForm/validations/overloading-check';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DeleteIcon from 'common/icons/DeleteIcon';
import TemperatureRangesCompatibleValidationAlertContent from './footer-alert-contents/TemperatureRangesCompatibleValidationAlertContent/TemperatureRangesCompatibleValidationAlertContent';
import { checkTemperatureRangesCompatible } from 'common/layouts/NewOrderPage/CargoDetailsForm/validations/check-temperature-ranges-compatible';
import SideBarFooterAlertsContext from 'common/layouts/LeftMenuLayout/SideBarLayout/contexts/side-bar-footer-alerts-context';
import { AlertThemeEnum } from 'common/components/Alert/Alert';
import FooterSideBarAlertsLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarAlertsLayout/FooterSideBarAlertsLayout';
import { CloseSideBarFooterAlertCallbackT } from 'common/utils/hooks/useSideBarFooterAlertsContextValue';
import ExpirationRFQAlertContent from 'common/layouts/NewOrderPage/CargoDetailsForm/footer-alert-contents/ExpirationRFQAlertContent/ExpirationRFQAlertContent';
import asyncValidate from './async-validations';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import FormAsyncErrorsContext from 'common/components/forms/contexts/form-async-errors-context';

const cx = classNames.bind(styles);

type PropsT = {};

const storage = new ValuesStorage(CARGO_DETAILS_FORM_NAME);

const DEFAULT_POINT_CONTACTS_VALUES: PointContactsFormValuesT = {
    [PointContactsFieldsEnum.companyName]: '',
    [PointContactsFieldsEnum.fullName]: '',
    [PointContactsFieldsEnum.email]: '',
    [PointContactsFieldsEnum.phoneNumber]: PHONE_NUMBER_PREFIX,
    [PointContactsFieldsEnum.comment]: '',
};

const DEFAULT_SHIPMENT_VALUES: ShipmentFormValuesT = {
    [ShipmentFieldsEnum.fakeId]: null,
    [ShipmentFieldsEnum.pickUpPointId]: null,
    [ShipmentFieldsEnum.dropOffPointId]: null,
    [ShipmentFieldsEnum.cargoUnitsCount]: '',
    [ShipmentFieldsEnum.cargoUnitType]: UnitTypeEnum.eur1Pallets,
    [ShipmentFieldsEnum.cargoWeight]: '',
    [ShipmentFieldsEnum.cargoTemperatureRange]: [-25, 0],
    [ShipmentFieldsEnum.cargoDescription]: '',
    [ShipmentFieldsEnum.poNumber]: '',
    [ShipmentFieldsEnum.pickUpReferenceNumber]: '',
    [ShipmentFieldsEnum.dropOffReferenceNumber]: '',
};

const getInitialValues = (routePoints: Array<RoutePointT>, isFinallyFetchInitializedData: boolean): FormValuesT => {
    if (!isFinallyFetchInitializedData) {
        return {
            [FieldsEnum.contacts]: [],
            [FieldsEnum.shipments]: [],
        };
    }

    const prevValues: FormValuesT = storage.getValues();

    const isSameContactsSizes = routePoints?.length === prevValues?.[FieldsEnum.contacts]?.length;

    const prevPointIds =
        prevValues?.[FieldsEnum.shipments]?.flatMap((shipmentValues) => {
            return [
                shipmentValues[ShipmentFieldsEnum.pickUpPointId],
                shipmentValues[ShipmentFieldsEnum.dropOffPointId],
            ].filter(isNonNil);
        }) || [];
    const routePointIds = routePoints?.map((point) => point.id);
    const hasEveryPointIds = prevPointIds.every((pointId) => {
        return routePointIds.includes(pointId);
    });

    const shouldUsePrevValues = isSameContactsSizes && hasEveryPointIds;
    if (shouldUsePrevValues) {
        return {
            [FieldsEnum.contacts]: prevValues[FieldsEnum.contacts] || [],
            [FieldsEnum.shipments]: prevValues[FieldsEnum.shipments] || [],
        };
    }

    const pickupDeliveryPoints = routePoints?.filter((routePoint) => !routePoint.driveThru);
    const autoSelectPickUpPointId = (pickupDeliveryPoints?.length === 2 && pickupDeliveryPoints[0]?.id) || null;
    const autoSelectDropOffPointId = (pickupDeliveryPoints?.length === 2 && pickupDeliveryPoints[1]?.id) || null;
    return {
        [FieldsEnum.contacts]: times(routePoints?.length || 0).map(() => {
            return {
                ...DEFAULT_POINT_CONTACTS_VALUES,
            };
        }),
        [FieldsEnum.shipments]: [
            {
                ...DEFAULT_SHIPMENT_VALUES,
                [ShipmentFieldsEnum.pickUpPointId]: autoSelectPickUpPointId || null,
                [ShipmentFieldsEnum.dropOffPointId]: autoSelectDropOffPointId || null,
            },
        ],
    };
};

type QueryT = {
    [QueryKeysEnum.orderCreationAdditionalServices]: string[];
};

const CargoDetailsForm: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const orderCreationPercentContext = useContext(OrderCreationPercentContext);
    const sideBarFooterAlertsContext = useContext(SideBarFooterAlertsContext);

    const ref = React.createRef<HTMLDivElement>();

    const isCreateNextOrderRef = React.useRef<boolean>(false);

    const rfq = useSelector(selectRFQ);
    const lanePriceOffer = useSelector(selectLanePriceOffer);
    const reserveExpireTime = rfq?.expireTime || lanePriceOffer?.expirationMs || null;

    const multiPointOrderDraftDetailPoints = useMemo((): MultiPointOrderDraftDetailsPropsT['points'] => {
        if (rfq) {
            return rfq.points.map((point, index) => {
                return {
                    address: point.address,
                    driveThru: point.driveThru,
                    index,
                };
            });
        }

        return null;
    }, [rfq]);

    const trailerTypeId = isNumber(rfq?.trailerTypeId) ? String(rfq?.trailerTypeId) : null;

    const trailersDictById = useSelector(selectTrailersDictById);
    const trailerType = trailersDictById[trailerTypeId as TrailerDictIdT] || null;

    const query = useQuery<QueryT>();
    const selectedServicesIds = (query[QueryKeysEnum.orderCreationAdditionalServices] || []).map((id) => +id);

    React.useEffect((): void => {
        if (!ref.current) {
            return;
        }

        scrollIntoView(ref.current, {
            time: 300,
        });
    }, []);

    const params = useParams<{ rfqId: string; laneId?: string; offerId: string }>();
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (params.rfqId) {
            dispatch(ensureCreatedRFQ(params.rfqId));
        }

        dispatch(fetchAdditionalServices());
        dispatch(fetchTrailersDict());
    }, []);

    const priceOffersById = useSelector(selectPriceOffersById);
    const selectedOffer = priceOffersById[params.offerId];

    const offer = lanePriceOffer || selectedOffer || null;

    const routePoints = useMemo((): Array<RoutePointT> => {
        if (rfq) {
            return rfq.points
                .map((point, index) => {
                    return {
                        id: point.id,
                        address: point.address,
                        driveThru: point.driveThru,
                        index,
                    };
                })
                .filter(isNonNil);
        }

        return [];
    }, [rfq]);

    const additionalServicesById = useSelector(selectAdditionalServicesById);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) =>
            validateForm(t, values, {
                trailerType,
                routePoints,
            });
    }, [t, routePoints, trailerType]);

    const isFinallyFetchInitializedData = !!routePoints?.length;

    const [initialValues, initialTouched, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(routePoints, isFinallyFetchInitializedData);
        const errors = validateForm(t, values, {
            trailerType,
            routePoints,
        });

        const touched: FormTouchedT = {};

        values?.shipments.forEach((shipmentValue, index) => {
            const shipmentFields = Object.keys(shipmentValue) as ShipmentFieldsEnum[];

            if (!touched.shipments) {
                touched.shipments = [];
            }

            touched.shipments[index] = shipmentFields.reduce<NonNullable<FormTouchedT['shipments']>[number]>(
                (acc, key) => {
                    acc[key] = DEFAULT_SHIPMENT_VALUES[key] !== shipmentValue[key];

                    return acc;
                },
                {},
            );
        });

        values?.contacts.forEach((pointContactsValue, index) => {
            const pointContactsFields = Object.keys(pointContactsValue) as PointContactsFieldsEnum[];

            if (!touched.contacts) {
                touched.contacts = [];
            }

            touched.contacts[index] = pointContactsFields.reduce<NonNullable<FormTouchedT['contacts']>[number]>(
                (acc, key) => {
                    if (key === PointContactsFieldsEnum.fullName || key === PointContactsFieldsEnum.phoneNumber) {
                        acc[key] = true;
                    } else {
                        acc[key] = DEFAULT_POINT_CONTACTS_VALUES[key] !== pointContactsValue[key];
                    }

                    return acc;
                },
                {},
            );
        });

        return [values, touched, errors];
    }, [routePoints, trailerType]);

    const [closeOverloadingAlert, setCloseOverloadingAlert] = React.useState<{
        callback: CloseSideBarFooterAlertCallbackT | null;
    }>({ callback: null });
    const [closeNotUsedPointAlert, setCloseNotUsedPointAlert] = React.useState<{
        callback: CloseSideBarFooterAlertCallbackT | null;
    }>({ callback: null });
    const [closeTemperatureRangesCompatibleAlert, setCloseTemperatureRangesCompatibleAlert] = React.useState<{
        callback: CloseSideBarFooterAlertCallbackT | null;
    }>({ callback: null });

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        initialTouched,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const notUsedRoutePointValidationResult = validateNotUsedRoutePoint(formik.values, {
                routePoints,
            });
            if (notUsedRoutePointValidationResult) {
                setCloseNotUsedPointAlert({
                    callback:
                        sideBarFooterAlertsContext?.showAlert({
                            theme: AlertThemeEnum.tomatoRed,
                            content: (
                                <NotUsedRoutePointValidationAlertContent
                                    validationResult={notUsedRoutePointValidationResult}
                                />
                            ),
                        }) || null,
                });
                return;
            }

            const overloadingTrailerValidationResult = validateOverloadingTrailer(formik.values, {
                routePoints,
                trailerType,
            });
            if (overloadingTrailerValidationResult) {
                setCloseOverloadingAlert({
                    callback:
                        sideBarFooterAlertsContext?.showAlert({
                            theme: AlertThemeEnum.tomatoRed,
                            content: (
                                <OverloadingTrailerValidationAlertContent
                                    validationResult={overloadingTrailerValidationResult}
                                />
                            ),
                        }) || null,
                });
                return;
            }

            const temperatureRangesCompatibleValidationResult = checkTemperatureRangesCompatible(formik.values, {
                trailerType,
            });
            if (temperatureRangesCompatibleValidationResult) {
                setCloseTemperatureRangesCompatibleAlert({
                    callback:
                        sideBarFooterAlertsContext?.showAlert({
                            theme: AlertThemeEnum.tomatoRed,
                            content: (
                                <TemperatureRangesCompatibleValidationAlertContent
                                    validationResult={temperatureRangesCompatibleValidationResult}
                                />
                            ),
                        }) || null,
                });
                return;
            }

            const availableSelectedServiceIds = (selectedServicesIds || []).filter((serviceId) => {
                const service = additionalServicesById?.[serviceId];
                if (!service) {
                    return true;
                }

                return checkAvailableService(service, offer);
            });

            const shipmentsFormValues = values[FieldsEnum.shipments];
            const contactsFormValues = values[FieldsEnum.contacts];
            const isCreateNextOrder = isCreateNextOrderRef.current;
            const isReefer = !!trailerType?.reefer;

            const createOrderRequest: ApiCreateOrderRequestT = {
                priceOfferId: params.offerId,
                contacts: contactsFormValues.map((contactFormValues, index) => {
                    const routePoint = routePoints[index];

                    return {
                        contact: {
                            fullName: contactsFormValues?.[index]?.[PointContactsFieldsEnum.fullName].trim(),
                            companyName: contactsFormValues?.[index]?.[PointContactsFieldsEnum.companyName].trim(),
                            email: contactsFormValues?.[index]?.[PointContactsFieldsEnum.email].trim() || undefined,
                            phone:
                                parsePhoneNumber(contactsFormValues?.[index]?.[PointContactsFieldsEnum.phoneNumber]) ||
                                undefined,
                            comment: contactsFormValues?.[index]?.[PointContactsFieldsEnum.comment].trim() || undefined,
                        },
                        pointId: routePoint.id,
                    };
                }),
                shipments: shipmentsFormValues.map((shipmentFormValues) => {
                    return {
                        description: shipmentFormValues?.[ShipmentFieldsEnum.cargoDescription].trim(),
                        lowestTemperature: isReefer
                            ? shipmentFormValues?.[ShipmentFieldsEnum.cargoTemperatureRange]?.[0]
                            : undefined,
                        highestTemperature: isReefer
                            ? shipmentFormValues?.[ShipmentFieldsEnum.cargoTemperatureRange]?.[1]
                            : undefined,
                        numberOfUnits: parseSimpleNumber(shipmentFormValues?.[ShipmentFieldsEnum.cargoUnitsCount]) || 0,
                        maxNumberOfUnits: 0, // TODO
                        unitsType:
                            getApiLoadUnitType(shipmentFormValues?.[ShipmentFieldsEnum.cargoUnitType]) || 'OTHER',
                        weight: parseSimpleNumber(shipmentFormValues?.[ShipmentFieldsEnum.cargoWeight]) || 0,
                        poNumber: parseStringFormatter(shipmentFormValues?.[ShipmentFieldsEnum.poNumber]) || '',
                        dropOffReference:
                            parseStringFormatter(shipmentFormValues?.[ShipmentFieldsEnum.dropOffReferenceNumber]) || '',
                        pickupReference:
                            parseStringFormatter(shipmentFormValues?.[ShipmentFieldsEnum.pickUpReferenceNumber]) || '',
                        pickupPointId: shipmentFormValues?.[ShipmentFieldsEnum.pickUpPointId] || '',
                        dropOffPointId: shipmentFormValues?.[ShipmentFieldsEnum.dropOffPointId] || '',
                    };
                }),
                additionalServiceIds: availableSelectedServiceIds,
            };

            dispatch(createOrder(params.rfqId, createOrderRequest, isCreateNextOrder));
        },
    });

    React.useEffect(() => {
        const shipmentsLength = formik.values[FieldsEnum.shipments]?.length || 0;

        const percent = getterFormFillPercent([
            ...times(shipmentsLength).flatMap((index) => {
                return [
                    `${FieldsEnum.shipments}.${index}.${ShipmentFieldsEnum.pickUpPointId}`,
                    `${FieldsEnum.shipments}.${index}.${ShipmentFieldsEnum.dropOffPointId}`,
                ];
            }),
        ])(formik.values);

        orderCreationPercentContext.setPercent(percent);
    }, [formik.values]);

    React.useEffect(() => {
        if (isFinallyFetchInitializedData) {
            storage.saveValues(formik.values);
        }
    }, [formik.values, isFinallyFetchInitializedData]);

    const createOrderRequestStatus = useSelector(selectCreateOrderRequest);

    const pointContactsFormValues = formik.values[FieldsEnum.contacts];

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(t, createOrderRequestStatus);
    }, [t, createOrderRequestStatus.error]);

    const asyncFormErrors = useAsyncFormErrors(asyncErrors);

    return (
        <FormAsyncErrorsContext.Provider value={asyncFormErrors}>
            <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('inner')} data-test-selector="new-order-cargo-details">
                        <div ref={ref} />
                        <MultiPointOrderDraftDetails
                            offer={offer}
                            trailerType={trailerType}
                            points={multiPointOrderDraftDetailPoints}
                        />
                        <OfferDetails
                            className={cx('offer')}
                            offer={offer}
                            selectedServicesIds={selectedServicesIds || []}
                            hasSelectedLane={!!params.laneId}
                        />
                        {!!offer && (
                            <>
                                <div className={cx('block')}>
                                    <div className={cx('block__content')}>
                                        <FormikFieldArray<FormValuesT, typeof FieldsEnum.shipments>
                                            name={FieldsEnum.shipments}
                                        >
                                            {(values, { push, remove }) => {
                                                const isAllowRemove = values?.length > 1;
                                                return (
                                                    <>
                                                        {values.map((value, index) => {
                                                            return (
                                                                <Card
                                                                    key={`shipment-card-${
                                                                        value?.[ShipmentFieldsEnum.fakeId]
                                                                    }`}
                                                                    initIsOpen
                                                                    titleNode={t(
                                                                        'common:new-order-form.shipment-title',
                                                                        {
                                                                            number: index + 1,
                                                                        },
                                                                    )}
                                                                    className={cx('shipments-card')}
                                                                    afterTitleNode={
                                                                        <CargoUnitIcon
                                                                            size={DEFAULT_ICON_SIZE}
                                                                            strokeColor={StyleGuideColorsEnum.gray}
                                                                            fillColor={StyleGuideColorsEnum.light}
                                                                        />
                                                                    }
                                                                    rightNode={
                                                                        <>
                                                                            <ErrorIndicatorLabel
                                                                                fieldNamesPrefix={FieldsEnum.shipments}
                                                                                index={index}
                                                                            />
                                                                            {isAllowRemove && (
                                                                                <Tooltip
                                                                                    position={
                                                                                        TooltipPositionEnum.topCenter
                                                                                    }
                                                                                    theme={TooltipThemeEnum.black}
                                                                                    tooltipNode={
                                                                                        <TooltipContent
                                                                                            theme={
                                                                                                TooltipContentThemeEnum.black
                                                                                            }
                                                                                            isNoWrap
                                                                                        >
                                                                                            {t(
                                                                                                'common:new-order-form.delete-shipment-tooltip',
                                                                                            )}
                                                                                        </TooltipContent>
                                                                                    }
                                                                                >
                                                                                    {() => (
                                                                                        <TransparentTrigger
                                                                                            onClick={() => {
                                                                                                remove(index);
                                                                                            }}
                                                                                            reflectionTheme={
                                                                                                ReflectionThemeEnum.light
                                                                                            }
                                                                                            leftIcon={
                                                                                                <DeleteIcon
                                                                                                    fillColor={
                                                                                                        StyleGuideColorsEnum.tomatoRed
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Tooltip>
                                                                            )}
                                                                        </>
                                                                    }
                                                                >
                                                                    <ShipmentPicker
                                                                        shipmentIndex={index}
                                                                        trailerType={trailerType}
                                                                        routePoints={routePoints}
                                                                        closeNotUsedPointValidationAlertCallback={
                                                                            closeNotUsedPointAlert?.callback
                                                                        }
                                                                        closeOverloadingValidationAlertCallback={
                                                                            closeOverloadingAlert?.callback
                                                                        }
                                                                        closeTemperatureRangesValidationAlertCallback={
                                                                            closeTemperatureRangesCompatibleAlert?.callback
                                                                        }
                                                                    />
                                                                </Card>
                                                            );
                                                        })}
                                                        <Button
                                                            theme={ButtonThemeEnum.transparent}
                                                            type="button"
                                                            onClick={() => {
                                                                const pickupDeliveryPoints = routePoints?.filter(
                                                                    (routePoint) => !routePoint?.driveThru,
                                                                );
                                                                const autoSelectPickUpPointId =
                                                                    (pickupDeliveryPoints?.length === 2 &&
                                                                        pickupDeliveryPoints?.[0]?.id) ||
                                                                    null;
                                                                const autoSelectDropOffPointId =
                                                                    (pickupDeliveryPoints?.length === 2 &&
                                                                        pickupDeliveryPoints?.[1]?.id) ||
                                                                    null;

                                                                push({
                                                                    ...DEFAULT_SHIPMENT_VALUES,
                                                                    [ShipmentFieldsEnum.fakeId]: uuidv4(),
                                                                    [ShipmentFieldsEnum.pickUpPointId]:
                                                                        autoSelectPickUpPointId,
                                                                    [ShipmentFieldsEnum.dropOffPointId]:
                                                                        autoSelectDropOffPointId,
                                                                });
                                                            }}
                                                            leftIcon={
                                                                <PlusSignIcon
                                                                    size={DEFAULT_ICON_SIZE}
                                                                    strokeColor={StyleGuideColorsEnum.charcoal}
                                                                />
                                                            }
                                                        >
                                                            {t('common:new-order-form.actions.add-shipment')}
                                                        </Button>
                                                    </>
                                                );
                                            }}
                                        </FormikFieldArray>
                                    </div>
                                </div>
                                <div className={cx('block')}>
                                    <div className={cx('block__title')}>
                                        {t('common:new-order-details.field-group.contact')}
                                    </div>
                                    <div className={cx('block__content')}>
                                        {pointContactsFormValues.map((pointContactFormValues, index) => {
                                            const routePoint = routePoints[index];
                                            if (routePoint?.driveThru) {
                                                return null;
                                            }

                                            return (
                                                <Card
                                                    key={`point-contacts-card-${index}`}
                                                    initIsOpen
                                                    className={cx('contacts-card')}
                                                    iconNode={
                                                        <NumberIcon
                                                            number={(routePoint?.index || 0) + 1}
                                                            fillColor={StyleGuideColorsEnum.charcoal}
                                                        />
                                                    }
                                                    titleNode={formatAddressComponents(routePoint.address)}
                                                    titleClassName={cx('contacts-card-title')}
                                                    rightNode={
                                                        <ErrorIndicatorLabel
                                                            fieldNamesPrefix={FieldsEnum.contacts}
                                                            index={index}
                                                        />
                                                    }
                                                >
                                                    <PointContactsPicker contactIndex={index} />
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                        <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                        <FooterSideBarLayout hasPaddings topNode={<FooterSideBarAlertsLayout />}>
                            <div className={cx('actions')}>
                                <Button
                                    theme={ButtonThemeEnum.secondary}
                                    className={cx('actions__action', 'actions__action--place-and-next-new-order')}
                                    onClick={() => {
                                        isCreateNextOrderRef.current = true;
                                        formik.handleSubmit();
                                    }}
                                    type="submit"
                                    testSelector="next-step"
                                    isLoading={createOrderRequestStatus.loading}
                                >
                                    {t('common:new-order-details.submit.place-and-next-new-order')}
                                </Button>
                                <Button
                                    theme={ButtonThemeEnum.primary}
                                    className={cx('actions__action', 'actions__action--place')}
                                    type="submit"
                                    testSelector="next-step"
                                    isLoading={createOrderRequestStatus.loading}
                                    rightIcon={
                                        isNumber(reserveExpireTime) ? (
                                            <ButtonTimer
                                                showTime={10 * MS_IN_MIN}
                                                expireTime={reserveExpireTime}
                                                tooltipMessage={t(
                                                    'common:new-order-form.price-offer-expiration.tooltip',
                                                )}
                                                onExpire={() => {
                                                    sideBarFooterAlertsContext?.showAlert({
                                                        theme: AlertThemeEnum.orange,
                                                        content: <ExpirationRFQAlertContent />,
                                                    });

                                                    history.push(OMSRoutesEnum.newOrder);
                                                }}
                                            />
                                        ) : null
                                    }
                                >
                                    {t('common:new-order-details.submit.place')}
                                </Button>
                            </div>
                        </FooterSideBarLayout>
                    </div>
                </form>
            </FormikContext.Provider>
        </FormAsyncErrorsContext.Provider>
    );
});

export default CargoDetailsForm;
