import { TrailerTypeEnum } from '../constants';

const parseTrailerModel = (trailerMode: string | null | undefined): [string | null, string | null] => {
    if (!trailerMode) {
        return [null, null];
    }

    const [firstPart, secondPart] = trailerMode.split(', ') || [];

    const availableTrailerTypes = Object.values(TrailerTypeEnum) as Array<string>;
    if (firstPart && availableTrailerTypes.includes(firstPart)) {
        return [secondPart || null, firstPart];
    }

    return [trailerMode, null];
};

export default parseTrailerModel;
