import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ModalDialogActions.scss';
import Button, { ButtonPropsT } from 'common/components/Button/Button';
import isEmpty from 'lodash/isEmpty';

const cx = classNames.bind(styles);

export type ModalDialogActionT = ButtonPropsT & { children: React.ReactNode };

export type ModalDialogActionsPropsT = {
    actions?: ModalDialogActionT[];
    className?: string;
};

const ModalDialogActions: React.FC<ModalDialogActionsPropsT> = (props) => {
    const { actions, className } = props;

    if (!actions || isEmpty(actions)) {
        return null;
    }

    return (
        <div className={cs(cx('actions'), className)}>
            {actions.map((action, actionIndex) => (
                <Button key={actionIndex} type="button" className={cx('actions__action')} {...action} />
            ))}
        </div>
    );
};

export default ModalDialogActions;
