import { ApiDictDocumentT, DocumentRequiredEnum, DocumentTypeEnum } from 'common/utils/api/models';
import { DictDocumentT } from 'common/store/documents-dict/models';

const prepareDictDocument = (rawDocument: ApiDictDocumentT): DictDocumentT => {
    return {
        id: String(rawDocument.id),
        type: rawDocument.documentType as DocumentTypeEnum,
        required: rawDocument.required as DocumentRequiredEnum,
        name: rawDocument.name || '',
    };
};

export default prepareDictDocument;
