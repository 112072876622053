import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './SaveFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
};

const SaveFooterContent: React.FC<PropsT> = React.memo(() => {
    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const { t } = useTranslation();

    return (
        <div className={cx('content')}>
            <Button
                theme={ButtonThemeEnum.primary}
                className={cx('action')}
                onClick={remoteFormActionsContext.remoteFormCallbacks?.submit}
                isLoading={remoteFormActionsContext.remoteFormRequest?.isLoading}
                isDisabled={remoteFormActionsContext.remoteFormRequest?.isLoading}
            >
                {t('common:document.actions.save')}
            </Button>
        </div>
    );
});

export default SaveFooterContent;
