import * as React from 'react';
import { formatTimeInterval } from 'common/utils/time';
import LocationCellLayout from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import { DirectionEnum } from 'common/constants';
import { RFQT } from 'common/store/rfqs/models';

type PropsT = {
    rfq: RFQT;
    type: DirectionEnum;
};

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { rfq, type } = props;

    let time = null;
    let address = null;

    const points = rfq?.points || [];

    const lastPoint = points[points.length - 1] || null;
    if (type === DirectionEnum.destination && lastPoint) {
        address = lastPoint.addressStr;
        time = formatTimeInterval(lastPoint.from, lastPoint.to);
    }

    const firstPoint = points[0] || null;
    if (type === DirectionEnum.origin && firstPoint) {
        address = firstPoint.addressStr;
        time = formatTimeInterval(firstPoint.from, firstPoint.to);
    }

    return <LocationCellLayout address={address} time={time} />;
});

export default LocationCell;
