import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './FullNameCell.scss';

type PropsT = {
    fullName: string;
};

const cx = classNames.bind(styles);

const FullNameCell: React.FC<PropsT> = React.memo((props) => {
    const { fullName } = props;

    return <div className={cx('content')}>{fullName}</div>;
});

export default FullNameCell;
