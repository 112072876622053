import * as React from 'react';
import EditShipperContractLaneSidebarContent from './EditShipperContractLaneSidebarContent/EditShipperContractLaneSidebarContent';
import ShipperContractLaneDetailsSidebarContent from './ShipperContractLaneDetailsSidebarContent/ShipperContractLaneDetailsSidebarContent';
import { useDispatch, useSelector } from 'react-redux';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import {
    selectShipperContractLaneDetailsState,
    selectUpdateShipperContractLaneRequest,
} from 'common/store/shipper-contract-lane-details/selectors';
import RevokeShipperLaneConfirmationModal, {
    RevokeShipperLaneConfirmationDataT,
} from './dialogs/RevokeShipperLaneConfirmationModal/RevokeShipperLaneConfirmationModal';
import {
    fetchShipperContractLaneDetails,
    revokeShipperContractLane,
    updateShipperContractLane,
} from 'common/store/shipper-contract-lane-details/slice';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { shipperContractLaneDetailsRefreshChannel } from 'common/store/shipper-contract-lane-details/channels';
import { UpdateShipperContractLaneChangesT } from 'common/store/shipper-contract-lane-details/models';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import { useEffect } from 'react';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { fetchShipperContractDetails } from 'common/store/shipper-contract-details/slice';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';

type PropsT = {
    partnerId: PartnerIdT;
    shipperContractId: ShipperContractIdT;
    shipperContractLaneId: ShipperContractLaneIdT;

    onGoBack?: () => void;
    onClose?: () => void;

    onOpenUserDetails?: (userId: UserIdT) => void;

    onChangeOriginRadiusKm?: (radiusKm: number) => void;
    onChangeDestinationRadiusKm?: (radiusKm: number) => void;

    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;

    isReadOnly?: boolean;
};

const CommonEditableShipperContractLaneDetailsLayout: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        shipperContractId,
        shipperContractLaneId,

        onGoBack,
        onClose,

        onOpenUserDetails,

        onChangeOriginRadiusKm,
        onChangeDestinationRadiusKm,

        setNeedCloseConfirmation,

        isReadOnly,
    } = props;

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    useEffect(() => {
        dispatch(
            fetchShipperContractLaneDetails({
                partnerId,
                shipperContractId,
                laneId: shipperContractLaneId,
            }),
        );

        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId,
            }),
        );
    }, [partnerId, shipperContractId, shipperContractLaneId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(
            fetchShipperContractLaneDetails({
                partnerId,
                shipperContractId,
                laneId: shipperContractLaneId,
            }),
        );

        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId,
            }),
        );
    }, [partnerId, shipperContractId, shipperContractLaneId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(
            fetchShipperContractLaneDetails({
                partnerId,
                shipperContractId,
                laneId: shipperContractLaneId,
                options: { isForceUpdate: true },
            }),
        );

        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId,
            }),
        );
    }, [partnerId, shipperContractId, shipperContractLaneId]);
    useChannelSubscribe(shipperContractLaneDetailsRefreshChannel, refreshPageHandler);

    const contractDetailsState = useSelector(
        selectShipperContractDetailsState(shipperContractId as ShipperContractIdT),
    );

    const [isActiveEditing, setActiveEditing] = React.useState<boolean>(false);

    const updateRequestStatus = useSelector(selectUpdateShipperContractLaneRequest);

    React.useEffect(() => {
        if (updateRequestStatus.ok) {
            setActiveEditing(false);
        }
    }, [updateRequestStatus.ok]);

    const { data, fetchRequest } = useSelector(selectShipperContractLaneDetailsState(shipperContractLaneId));

    const handleOpenEdit = () => {
        setActiveEditing(true);
    };

    const handleEditShipperContractLane = (detailsChanges: UpdateShipperContractLaneChangesT) => {
        dispatch(
            updateShipperContractLane({
                partnerId,
                shipperContractId,
                laneId: shipperContractLaneId,
                detailsChanges,
            }),
        );
    };

    const revokeShipperLaneConfirmation = useModalDialog<RevokeShipperLaneConfirmationDataT>();
    const handleRevokeConfirm = (data: RevokeShipperLaneConfirmationDataT): void => {
        const { shipperContractId, partnerId, laneId } = data;

        dispatch(
            revokeShipperContractLane({
                partnerId,
                shipperContractId,
                laneId,
            }),
        );
    };

    const handleRevoke = () => {
        revokeShipperLaneConfirmation.setData({
            partnerId,
            shipperContractId,
            laneId: shipperContractLaneId,
            laneNumber: data?.tztLaneId || '',
        });
    };

    useCloseModalDialogAfterRequest(updateRequestStatus, [revokeShipperLaneConfirmation]);

    return (
        <>
            {isActiveEditing ? (
                <EditShipperContractLaneSidebarContent
                    data={data}
                    onClose={onClose}
                    onGoBack={onGoBack}
                    onCancel={() => {
                        setActiveEditing(false);
                    }}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onOpenUserDetails={onOpenUserDetails}
                    onChangeOriginRadiusKm={onChangeOriginRadiusKm}
                    onChangeDestinationRadiusKm={onChangeDestinationRadiusKm}
                    onEditShipperContractLane={handleEditShipperContractLane}
                    contractDetails={contractDetailsState?.data || null}
                />
            ) : (
                <ShipperContractLaneDetailsSidebarContent
                    data={data}
                    isReadOnly={isReadOnly}
                    onClose={onClose}
                    onGoBack={onGoBack}
                    onRevoke={handleRevoke}
                    onEdit={handleOpenEdit}
                    onOpenUserDetails={onOpenUserDetails}
                />
            )}
            {fetchRequest.loading && <LoaderOverlay />}
            <RevokeShipperLaneConfirmationModal
                data={revokeShipperLaneConfirmation.data}
                onCancel={revokeShipperLaneConfirmation.onCancel}
                onClose={revokeShipperLaneConfirmation.onClose}
                onConfirm={handleRevokeConfirm}
                requestStatus={updateRequestStatus}
            />
        </>
    );
};

export default CommonEditableShipperContractLaneDetailsLayout;
