import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor?: StyleGuideColorsEnum;
    strokeColor?: StyleGuideColorsEnum;
};

const ContactBadgeIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M10.45 9.1h-.9c-.747 0-1.35-.603-1.35-1.35H4.15v8.1h11.7v-8.1H11.8c0 .747-.603 1.35-1.35 1.35zm-2.7.9a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25zM10 14.5H5.5v-.432c0-.45.27-.855.684-1.044A3.963 3.963 0 017.75 12.7c.558 0 1.08.117 1.566.324.414.18.684.585.684 1.044v.432zm4.5-.9h-2.7v-1.35h2.7v1.35zm0-2.25h-2.7V10h2.7v1.35z"
                />
                <path
                    fill={strokeColor}
                    d="M10.45 2.8c.747 0 1.35.603 1.35 1.35V6.4h4.05c.747 0 1.35.603 1.35 1.35v8.1c0 .747-.603 1.35-1.35 1.35H4.15c-.747 0-1.35-.603-1.35-1.35v-8.1c0-.747.603-1.35 1.35-1.35H8.2V4.15c0-.747.603-1.35 1.35-1.35zM8.2 7.75H4.15v8.1h11.7v-8.1H11.8c0 .747-.603 1.35-1.35 1.35h-.9c-.747 0-1.35-.603-1.35-1.35zm-.45 4.95c.558 0 1.08.117 1.566.324.414.18.684.585.684 1.044v.432H5.5v-.432c0-.459.27-.864.684-1.044A3.963 3.963 0 017.75 12.7zm6.75-.45v1.35h-2.7v-1.35h2.7zM7.75 10a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25zm6.75 0v1.35h-2.7V10h2.7zm-4.05-5.85h-.9v3.6h.9v-3.6z"
                />
            </g>
        </svg>
    );
};

ContactBadgeIcon.displayName = 'ContactBadgeIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default ContactBadgeIcon;
