import { ADD_ALERT, AddAlertActionT, DELETE_ALERT, DeleteAlertActionT } from 'common/store/alerts/types';
import { AnyAlert } from 'common/store/alerts/models';

export const addAlert = (anyAlert: AnyAlert): AddAlertActionT => ({
    type: ADD_ALERT,
    anyAlert,
});

export const deleteAlert = (alertId: AlertIdT): DeleteAlertActionT => ({
    type: DELETE_ALERT,
    alertId,
});
