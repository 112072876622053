import * as React from 'react';
import { useEffect } from 'react';

import styles from './RevokeContractConfirmation.scss';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import { RequestStatusT } from 'common/utils/request-status';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import { logWarning } from 'common/utils/logger';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { prepareRevokeInfo } from './prepare-revoke-data';
import ActiveContractDropdown from 'common/components/ActiveContractDropdown/ActiveContractDropdown';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import { getContractName } from 'common/components/ContractName/ContractName';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import classNames from 'classnames/bind';
import { ApiRevokePartnerContractInfoT, RevokeContractActionEnum } from 'common/utils/api/models';
import NumberInput from 'common/components/NumberInput/NumberInput';
import FieldValue from 'common/components/FieldValue/FieldValue';
import Money from 'common/components/Money/Money';
import RevokeContractActionDropdown from 'common/layouts/BaseCarrierContractsLayout/dialogs/RevokeContractConfirmation/RevokeContractActionDropdown/RevokeContractActionDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';

const cx = classNames.bind(styles);

export type ConfirmRevokeContractModalDataT = {
    id: CarrierContractIdT;
    name?: string;
    truckRateKm?: number;
    boxRateKm?: number;
    tiltRateKm?: number;
};

type PropsT = {
    data: ConfirmRevokeContractModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (contractId: CarrierContractIdT, revokeInfo: ApiRevokePartnerContractInfoT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'revoke-contract-confirmation';

const RevokeContractConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, onConfirm, requestStatus } = props;
    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const excludedContractIds = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return [data.id];
    }, [data]);

    const isShow = !!data;

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.action]: RevokeContractActionEnum.setNewContract,
            [FieldsEnum.nextContractId]: null,
            [FieldsEnum.boxesRatePerKm]: '',
            [FieldsEnum.tiltsRatePerKm]: '',
            [FieldsEnum.trucksRatePerKm]: '',
        };
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const revokeInfo = prepareRevokeInfo(values);

            if (!data?.id) {
                logWarning('failed to revoke contract, empty data?.id');
                return;
            }

            if (!revokeInfo) {
                logWarning('failed to revoke contract, empty revokeInfo');
                return;
            }

            onConfirm(data.id, revokeInfo);
            formikHelpers.setTouched({});
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [isShow]);

    if (!isShow) {
        return null;
    }

    const selectedAction = formik.values[FieldsEnum.action];

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <form onSubmit={formik.handleSubmit}>
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.keep'),
                                theme: ButtonThemeEnum.secondary,
                                testSelector: `${TEST_SELECTOR}_keep`,
                                isDisabled: requestStatus.loading,
                                onClick: () => {
                                    onClose();
                                },
                            },
                            {
                                children: t('common:error-modal.actions.revoke'),
                                theme: ButtonThemeEnum.danger,
                                testSelector: `${TEST_SELECTOR}_ok`,
                                isLoading: requestStatus.loading,
                                isDisabled: requestStatus.loading,
                                type: 'submit',
                            },
                        ]}
                        icon={<BigTrashIcon />}
                        title={t('common:carrier-contracts.revoke-confirmation.title')}
                        message={t('common:carrier-contracts.revoke-confirmation.description', {
                            name: getContractName(data.name, t),
                        })}
                        body={
                            <>
                                <FormikField
                                    name={FieldsEnum.action}
                                    error={formik.errors[FieldsEnum.action]}
                                    meta={formik.getFieldMeta(FieldsEnum.action)}
                                    label={t('common:carrier-contracts.revoke-confirmation.fields.action.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <RevokeContractActionDropdown
                                            value={formik.values[FieldsEnum.action]}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            onSelect={props.onChange}
                                        />
                                    )}
                                </FormikField>
                                {selectedAction === RevokeContractActionEnum.setNewContract && (
                                    <FormikField
                                        name={FieldsEnum.nextContractId}
                                        error={formik.errors[FieldsEnum.nextContractId]}
                                        meta={formik.getFieldMeta(FieldsEnum.nextContractId)}
                                        label={t(
                                            'common:carrier-contracts.revoke-confirmation.fields.next-contract.label',
                                        )}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <ActiveContractDropdown
                                                excludedContractIds={excludedContractIds}
                                                value={formik.values[FieldsEnum.nextContractId]}
                                                isDisabled={false}
                                                onChange={props.onChange}
                                                placeholder={t(
                                                    'common:carrier-contracts.revoke-confirmation.fields.next-contract.placeholder',
                                                )}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                companyId={partnerId}
                                            />
                                        )}
                                    </FormikField>
                                )}
                                {selectedAction === RevokeContractActionEnum.setNewRate && (
                                    <FieldGroup>
                                        <FormikField
                                            name={FieldsEnum.trucksRatePerKm}
                                            error={formik.errors[FieldsEnum.trucksRatePerKm]}
                                            meta={formik.getFieldMeta(FieldsEnum.trucksRatePerKm)}
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.truck-rate-per-km.label',
                                            )}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <NumberInput
                                                    name={FieldsEnum.trucksRatePerKm}
                                                    unitType={UnitTypeEnum.euroAbbreviation}
                                                    value={formik.values[FieldsEnum.trucksRatePerKm]}
                                                    placeholder={t(
                                                        'common:carrier-contracts.revoke-confirmation.fields.truck-rate-per-km.placeholder',
                                                    )}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    step={1}
                                                />
                                            )}
                                        </FormikField>
                                        <FormikField
                                            name={FieldsEnum.boxesRatePerKm}
                                            error={formik.errors[FieldsEnum.boxesRatePerKm]}
                                            meta={formik.getFieldMeta(FieldsEnum.boxesRatePerKm)}
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.box-rate-per-km.label',
                                            )}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <NumberInput
                                                    name={FieldsEnum.boxesRatePerKm}
                                                    unitType={UnitTypeEnum.euroAbbreviation}
                                                    value={formik.values[FieldsEnum.boxesRatePerKm]}
                                                    placeholder={t(
                                                        'common:carrier-contracts.revoke-confirmation.fields.box-rate-per-km.placeholder',
                                                    )}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    step={1}
                                                />
                                            )}
                                        </FormikField>
                                        <FormikField
                                            name={FieldsEnum.tiltsRatePerKm}
                                            error={formik.errors[FieldsEnum.tiltsRatePerKm]}
                                            meta={formik.getFieldMeta(FieldsEnum.tiltsRatePerKm)}
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.tilt-rate-per-km.label',
                                            )}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <NumberInput
                                                    name={FieldsEnum.tiltsRatePerKm}
                                                    unitType={UnitTypeEnum.euroAbbreviation}
                                                    value={formik.values[FieldsEnum.tiltsRatePerKm]}
                                                    placeholder={t(
                                                        'common:carrier-contracts.revoke-confirmation.fields.tilt-rate-per-km.placeholder',
                                                    )}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    step={1}
                                                />
                                            )}
                                        </FormikField>
                                    </FieldGroup>
                                )}
                                {selectedAction === RevokeContractActionEnum.keepOldRate && (
                                    <FieldGroup>
                                        <FieldValue
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.truck-rate-per-km.label',
                                            )}
                                            value={
                                                data?.truckRateKm ? (
                                                    <Money amount={data.truckRateKm} currency={CurrencyEnum.EUR} />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            placeholder={t('common:fields.placeholder.empty')}
                                        />
                                        <FieldValue
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.box-rate-per-km.label',
                                            )}
                                            value={
                                                data?.boxRateKm ? (
                                                    <Money amount={data.boxRateKm} currency={CurrencyEnum.EUR} />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            placeholder={t('common:fields.placeholder.empty')}
                                        />
                                        <FieldValue
                                            label={t(
                                                'common:carrier-contracts.revoke-confirmation.fields.tilt-rate-per-km.label',
                                            )}
                                            value={
                                                data?.tiltRateKm ? (
                                                    <Money amount={data.tiltRateKm} currency={CurrencyEnum.EUR} />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            placeholder={t('common:fields.placeholder.empty')}
                                        />
                                    </FieldGroup>
                                )}
                            </>
                        }
                    />
                </form>
            </ModalContent>
        </Modal>
    );
});

export default RevokeContractConfirmation;
