import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './HeaderSideBarContent.scss';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import isNil from 'lodash/isNil';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export type HeaderSidebarActionT = DropdownControlOptionT | null;

type PropsT = {
    className?: string;
    title?: React.ReactNode;
    dropdownOptions?: Array<HeaderSidebarActionT | SpecialOptionEnum>;
    afterTitleNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    afterDropdownNode?: React.ReactNode;
    beforeDropdownNode?: React.ReactNode;
    dropdownOverlayClassName?: string;
    testSelector?: string;
    onGoBack?: (() => void) | null;
    onClose?: (() => void) | null;
};

const HeaderSideBarContent: React.FC<PropsT> = React.memo((props) => {
    const {
        title,
        onGoBack,
        dropdownOptions,
        afterTitleNode,
        afterDropdownNode,
        beforeDropdownNode,
        rightNode,
        onClose,
        dropdownOverlayClassName,
        className,
        testSelector,
    } = props;

    const fullTestSelector = `${testSelector}_sidebar-header`;

    const { t } = useTranslation();

    return (
        <div className={cs(cx('header'), className)}>
            {onGoBack && (
                <Tooltip
                    position={TooltipPositionEnum.centerRight}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={80}>
                            {t('common:sidebar.go-back')}
                        </TooltipContent>
                    }
                >
                    {() => (
                        <TransparentTrigger
                            onClick={onGoBack}
                            className={cx('control', 'control--back-button')}
                            leftIcon={<LeftArrowIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                            testSelector={`${fullTestSelector}_back`}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    )}
                </Tooltip>
            )}
            {!isNil(title) && (
                <div className={cx('title')} data-test-selector={`${fullTestSelector}_title`}>
                    {title}
                </div>
            )}
            {isNil(title) && <div className={cx('skeleton')} />}
            {afterTitleNode && <div className={cx('after-title-node')}>{afterTitleNode}</div>}
            <div className={cx('spacer')} />
            {rightNode && <div className={cx('right-node')}>{rightNode}</div>}
            {beforeDropdownNode && (
                <div
                    className={cx('control', {
                        'control--has-right-padding': true,
                    })}
                >
                    {beforeDropdownNode}
                </div>
            )}
            {!!dropdownOptions?.length && (
                <DropdownControl
                    className={cx('control', {
                        'control--has-right-padding': !!onClose || !!afterDropdownNode,
                    })}
                    overlayClassName={dropdownOverlayClassName}
                    options={dropdownOptions}
                    renderTrigger={(isActive, onClick) => (
                        <TransparentTrigger
                            isPressed={isActive}
                            onClick={onClick}
                            leftIcon={<MoreIcon />}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    )}
                    overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                />
            )}
            {afterDropdownNode && (
                <div
                    className={cx('control', {
                        'control--has-right-padding': !!onClose,
                    })}
                >
                    {afterDropdownNode}
                </div>
            )}
            {onClose && (
                <Tooltip
                    position={TooltipPositionEnum.centerLeft}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={80}>
                            {t('common:sidebar.close')}
                        </TooltipContent>
                    }
                >
                    {() => (
                        <TransparentTrigger
                            className={cx('control', 'control--close-button')}
                            onClick={onClose}
                            leftIcon={<CloseIcon />}
                            testSelector={`${fullTestSelector}_close`}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    )}
                </Tooltip>
            )}
        </div>
    );
});

export default HeaderSideBarContent;
