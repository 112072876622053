import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const UploadFileIcon: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M15.557 6.558l-3.622-3.623a1.498 1.498 0 00-1.058-.435H5.5c-.825 0-1.492.675-1.492 1.5L4 16c0 .825.668 1.5 1.492 1.5H14.5c.825 0 1.5-.675 1.5-1.5V7.622c0-.397-.158-.78-.443-1.064zM12.1 12.25h-1.35v2.25c0 .412-.338.75-.75.75a.752.752 0 01-.75-.75v-2.25H7.907a.371.371 0 01-.262-.637l2.1-2.093a.385.385 0 01.533 0l2.092 2.093c.225.232.06.637-.27.637zm-.6-4.5a.752.752 0 01-.75-.75V3.625l4.125 4.125H11.5z"
                />
            </g>
        </svg>
    );
};

UploadFileIcon.displayName = 'UploadFileIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default UploadFileIcon;
