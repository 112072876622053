import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { ContractDetailsChangesT } from 'common/store/carrier-contracts/models';

export type ConfirmApproveContractModalDataT = {
    contractId: CarrierContractIdT;
    contractChanges: ContractDetailsChangesT;
};

type PropsT = {
    data: ConfirmApproveContractModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: ConfirmApproveContractModalDataT) => void;
    isLoading: boolean;
    name: string | undefined;
};

const TEST_SELECTOR = 'approve-contract-confirmation';

const ApproveContractConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { name, data, onClose, onConfirm, isLoading } = props;
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: isLoading,
                            onClick: () => {
                                onClose();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.approve'),
                            theme: ButtonThemeEnum.primary,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading,
                            isDisabled: isLoading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                    title={t('common:carrier-contracts.approve-confirmation.title')}
                    message={t('common:carrier-contracts.approve-confirmation.description', {
                        contractName: name,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default ApproveContractConfirmation;
