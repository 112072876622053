import { RequestStatusT } from 'common/utils/request-status';

import { UserChangesT, UserT } from './models';
import { ApiUserT, ApiUserUpdateT } from 'common/utils/api/models';
import { AuthUserT } from 'common/utils/api/auth/base-auth-service';

export const FETCH_USER_REQUEST = 'common/user/FETCH_USER_REQUEST';
export const FETCH_USER_REQUEST_BEGIN = 'common/user/FETCH_USER_REQUEST_BEGIN';
export const FETCH_USER_REQUEST_ERROR = 'common/user/FETCH_USER_REQUEST_ERROR';
export const FETCH_USER_REQUEST_SUCCESS = 'common/user/FETCH_USER_REQUEST_SUCCESS';

export const PATCH_USER_REQUEST = 'common/user/PATCH_USER_REQUEST';
export const PATCH_USER_REQUEST_BEGIN = 'common/user/PATCH_USER_REQUEST_BEGIN';
export const PATCH_USER_REQUEST_ERROR = 'common/user/PATCH_USER_REQUEST_ERROR';
export const PATCH_USER_REQUEST_SUCCESS = 'common/user/PATCH_USER_REQUEST_SUCCESS';

export type UserStateT = {
    currentUserRequest: RequestStatusT;
    currentUser: UserT | null;
    patchUserRequest: RequestStatusT;
};

export type FetchUserActionT = {
    type: typeof FETCH_USER_REQUEST;
};

export type FetchUserBeginActionT = {
    type: typeof FETCH_USER_REQUEST_BEGIN;
};

export type FetchUserSuccessActionT = {
    type: typeof FETCH_USER_REQUEST_SUCCESS;
    currentUser: ApiUserT;
};

export type FetchUserErrorActionT = {
    type: typeof FETCH_USER_REQUEST_ERROR;
    error: Error;
};

export type PatchUserActionT = {
    type: typeof PATCH_USER_REQUEST;
    user: UserT | null;
    authUser: AuthUserT | null;
    changes: UserChangesT;
};

export type PatchUserBeginActionT = {
    type: typeof PATCH_USER_REQUEST_BEGIN;
};

export type PatchUserSuccessActionT = {
    type: typeof PATCH_USER_REQUEST_SUCCESS;
    currentUser: ApiUserT;
    currentUserUpdate: ApiUserUpdateT;
};

export type PatchUserErrorActionT = {
    type: typeof PATCH_USER_REQUEST_ERROR;
    error: Error;
};

export type UserActionT =
    | FetchUserActionT
    | FetchUserBeginActionT
    | FetchUserSuccessActionT
    | FetchUserErrorActionT
    | PatchUserActionT
    | PatchUserBeginActionT
    | PatchUserSuccessActionT
    | PatchUserErrorActionT;
