import * as React from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authRoutesEnum, QueryKeysEnum } from 'common/constants';
import { selectIsPendingUser } from 'common/store/user/selectors';
import { formatQuery } from 'common/utils/query';
import { getAuthReturnUrl } from 'common/utils/auth-return-url';

type PropsT = {};

const RedirectPendingUser: React.FC<PropsT> = React.memo(() => {
    const isPendingUser = useSelector(selectIsPendingUser);
    if (isPendingUser) {
        return (
            <Redirect
                to={{
                    pathname: authRoutesEnum.continueSignUp,
                    search: formatQuery({
                        [QueryKeysEnum.returnUrl]: getAuthReturnUrl(),
                    }),
                }}
            />
        );
    }

    return null;
});

export default RedirectPendingUser;
