import { SharableStateT } from '../models';
import { initialPartnerShipperContractLanesState, PartnerShipperContractLanesStateT } from './slice';

const selectCompanyState = (
    state: SharableStateT,
    partnerId: PartnerIdT,
    contractId: ShipperContractIdT,
): PartnerShipperContractLanesStateT => {
    return state.shipperContractLanes?.[partnerId]?.[contractId] || initialPartnerShipperContractLanesState;
};

export const selectShipperContractLanesPages = (partnerId: PartnerIdT, contractId: ShipperContractIdT) => {
    return (state: SharableStateT): PartnerShipperContractLanesStateT['pages'] => {
        return selectCompanyState(state, partnerId, contractId).pages;
    };
};

export const selectShipperContractLanesQuery = (partnerId: PartnerIdT, contractId: ShipperContractIdT) => {
    return (state: SharableStateT): PartnerShipperContractLanesStateT['query'] =>
        selectCompanyState(state, partnerId, contractId).query;
};

export const selectShipperContractLanesTotal = (partnerId: PartnerIdT, contractId: ShipperContractIdT) => {
    return (state: SharableStateT): PartnerShipperContractLanesStateT['total'] =>
        selectCompanyState(state, partnerId, contractId).total;
};

export const selectShipperContractLanesByIds = (partnerId: PartnerIdT, contractId: ShipperContractIdT) => {
    return (state: SharableStateT): PartnerShipperContractLanesStateT['byId'] =>
        selectCompanyState(state, partnerId, contractId).byId;
};
