import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const UpdateUserIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M8.53 12.25l.03.008A4.8 4.8 0 008.5 13c0 .525.075 1.027.218 1.5H4v-.488c0-.262.12-.502.308-.607A8.167 8.167 0 018.5 12.25c.023 0 .037 0 .06.008zM8.5 5.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                />
                <path
                    fill={strokeColor}
                    d="M14.5 9.25l.225 1.117c.3.113.57.27.81.473l1.088-.367.75 1.312-.856.75c.023.158.046.307.046.473 0 .165-.023.315-.046.472l.856.75-.75 1.297-1.088-.367c-.24.203-.51.36-.81.473L14.5 16.75H13l-.225-1.133c-.3-.112-.57-.27-.81-.472l-1.088.367-.75-1.297.855-.75c-.022-.158-.044-.307-.044-.473 0-.165.022-.315.044-.472l-.854-.75.75-1.297 1.087.367c.24-.203.51-.36.81-.473L13 9.25h1.5zm-6 1.5c.172 0 .338.008.503.023-.218.457-.368.96-.443 1.485-.023-.008-.037-.008-.06-.008a8.167 8.167 0 00-4.192 1.155c-.188.113-.308.353-.308.607v.488h4.718c.165.54.412 1.05.727 1.5H2.5v-1.988c0-.772.382-1.507 1.042-1.897A9.657 9.657 0 018.5 10.75zm5.25.75c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zM8.5 4a3 3 0 110 6 3 3 0 110-6zm0 1.5C7.675 5.5 7 6.175 7 7s.675 1.5 1.5 1.5S10 7.825 10 7s-.675-1.5-1.5-1.5z"
                />
            </g>
        </svg>
    );
};

UpdateUserIcon.displayName = 'UpdateUserIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default UpdateUserIcon;
