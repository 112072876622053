import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    opacity?: number;
};

const StopWarningIcon: React.FC<PropsT> = (props) => {
    const { fillColor, opacity, className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fillOpacity={opacity || 1}
                    fill={fillColor}
                    d="M12.797 3.25H7.202L3.25 7.202v5.595l3.952 3.953h5.595l3.953-3.953V7.202L12.797 3.25zM10 13.975A.973.973 0 019.025 13c0-.54.435-.975.975-.975s.975.435.975.975-.435.975-.975.975zm.75-3.225h-1.5v-4.5h1.5v4.5z"
                />
            </g>
        </svg>
    );
};

StopWarningIcon.displayName = 'StopWarningIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.tomatoRed,
    opacity: 1,
};

export { storyProps };
export default StopWarningIcon;
