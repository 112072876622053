import * as React from 'react';
import { OrderStatusEnum, ApiOrderStatusT } from 'common/utils/api/models';
import ColoredStatusLabel, {
    PropsT as ColoredStatusLabelPropsT,
} from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import OrderStatus from 'common/components/status/OrderStatus/OrderStatus';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiOrderStatusT | null | undefined;
};

export const ORDER_STATUS_COLOR_MAP: Record<ApiOrderStatusT, StyleGuideColorsEnum> = {
    [OrderStatusEnum.placed]: StyleGuideColorsEnum.light,
    [OrderStatusEnum.inProgress]: StyleGuideColorsEnum.light,
    [OrderStatusEnum.canceled]: StyleGuideColorsEnum.light,
    [OrderStatusEnum.delivered]: StyleGuideColorsEnum.light,
    [OrderStatusEnum.done]: StyleGuideColorsEnum.mediumGreen,
};

const OrderColoredStatus: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    const color = ORDER_STATUS_COLOR_MAP[status];

    return <ColoredStatusLabel {...restProps} label={<OrderStatus status={status} />} color={color} />;
});

export default OrderColoredStatus;
