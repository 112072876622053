export enum FieldsEnum {
    dictTrailerId = 'dictTrailerId',

    address = 'address',

    validFromDates = 'validFromDates',
    validTillDates = 'validTillDates',

    createdDates = 'createdDates',

    createdById = 'createdById',
    createdByName = 'createdByName',
}

export type FormValuesT = {
    [FieldsEnum.dictTrailerId]: string | null;

    [FieldsEnum.address]: string | null;

    [FieldsEnum.validTillDates]: DateRangeT | null;
    [FieldsEnum.validFromDates]: DateRangeT | null;

    [FieldsEnum.createdDates]: DateRangeT | null;

    [FieldsEnum.createdById]: string | null;
    [FieldsEnum.createdByName]: string | null;
};
