import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    number: number;
};

const NumberIcon: React.FC<PropsT> = (props) => {
    const { className, fillColor, number } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <circle cx="10" cy="10" r="10" fill={fillColor} />
                <text fill="#FFF" fontFamily="Roboto-Black, Roboto" fontSize="14" fontWeight="800">
                    <tspan x="5.946" y="15">
                        {number}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

NumberIcon.displayName = 'NumberIcon';

const storyProps: PropsT = {
    number: 1,
    fillColor: StyleGuideColorsEnum.slate,
};

export { storyProps };
export default NumberIcon;

export const NumberIconProps = {
    getAssignmentControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): Omit<PropsT, 'number'> => {
        if (hasError) {
            return {
                fillColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                fillColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                fillColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                fillColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.light,
        };
    },
};
