import * as React from 'react';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import TruckIcon from 'common/icons/TruckIcon';

export type PropsT = {
    className?: string;
    plateNumber: string | null | undefined;
    truckId: TruckIdT;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
};

const LinkedTruckAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, plateNumber, truckId, onOpenTruckDetails } = props;

    const { t } = useTranslation();

    return (
        <Alert
            className={className}
            icon={
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.white}
                    fillColor={StyleGuideColorsEnum.transparent}
                />
            }
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.gray}
            actionText={t('common:trailers-page.add-trailer.linked-truck.actions.open-details')}
            onClickAction={() => {
                onOpenTruckDetails(truckId);
            }}
        >
            {t('common:trailers-page.add-trailer.linked-truck.title', {
                plateNumber: plateNumber || '',
            })}
        </Alert>
    );
});

export default LinkedTruckAlert;
