import isEmpty from 'lodash/isEmpty';
import { CountryCodeT, CountryT } from 'common/store/countries-dict/models';

export const checkIsExistCountry = (
    location: LocationT | null,
    countriesByCode: Partial<Record<CountryT['code'], CountryT>>,
): boolean => {
    if (isEmpty(countriesByCode) || !location) {
        return true;
    }

    const countryCode = location?.addressComponents?.countryCode;
    if (!countryCode) {
        // check only has countryCode
        return true;
    }

    return !!countriesByCode[countryCode.toUpperCase() as CountryCodeT];
};

export const checkIsAllowedCountry = (location: LocationT | null, excludedCountries: Array<CountryCodeT>): boolean => {
    if (!location) {
        return true;
    }

    const countryCode = (location.addressComponents?.countryCode || '').toUpperCase() as CountryCodeT;

    return !excludedCountries.includes(countryCode);
};
