import { RolesEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    role = 'role',
    successorUserId = 'successor-user-id',
}

export type FormValuesT = {
    [FieldsEnum.role]: RolesEnum | null;
    [FieldsEnum.successorUserId]: UserIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
