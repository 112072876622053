export const MAX_RADIUS_KM = 300;
export const MIN_VALID_FROM_VALID_TILL_DAYS = 7;

export enum FieldsEnum {
    pickUpLocationRadius = 'pickUpLocationRadius',

    dropOffLocationRadius = 'dropOffLocationRadius',

    validFrom = 'validFrom',
    validTill = 'validTill',

    layoverLimit = 'layoverLimit',

    externalLaneId = 'externalLaneId',
}

export type FormTouchedT = Partial<Record<FieldsEnum, boolean>>;

export type FormValuesT = {
    [FieldsEnum.pickUpLocationRadius]: string;

    [FieldsEnum.dropOffLocationRadius]: string;

    [FieldsEnum.validFrom]: Date | null;
    [FieldsEnum.validTill]: Date | null;

    [FieldsEnum.layoverLimit]: string;

    [FieldsEnum.externalLaneId]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
