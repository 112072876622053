import deepEqual from 'deep-equal';
import { EntityConverterT, PageResponseT } from './models';
import isNumber from 'lodash/isNumber';
import { ViewEventChannel } from 'common/utils/view-event-channel';

const checkIsSameQuery = <Q>(queryA: Q, queryB: Q): boolean => {
    return deepEqual(queryA || {}, queryB || {});
};

const convertPageEntities = <E, F>(
    pageResponse: PageResponseT<E> | null,
    converter: EntityConverterT<E, F>,
): PageResponseT<F> => {
    return {
        ...(pageResponse || {}),
        content: (pageResponse?.content || []).map((entity) => converter(entity)),
    };
};

type AnyPaginationChannelT = ViewEventChannel<{
    pageNumber: number;
}>;

const checkShouldEmitChangePage = <E, F extends AnyPaginationChannelT>(
    currentPage: number,
    pageResponse: PageResponseT<E> | null,
    channel: F,
) => {
    if (!pageResponse) {
        return;
    }

    const { totalPages } = pageResponse;
    if (!isNumber(totalPages)) {
        return;
    }

    if (totalPages === 0 && currentPage === 0) {
        // empty state, skip
        return;
    }

    if (currentPage >= totalPages) {
        channel.emit({
            pageNumber: Math.max(totalPages - 1, 0),
        });
    }
};

export { checkIsSameQuery, convertPageEntities, checkShouldEmitChangePage };
