import { SharableStateT } from '../models';
import { ShipperSuggestStateT } from './types';
import { initialShipperSuggestState } from 'common/store/shipper-suggest/reducer';

export const selectFindShippersQuery = (state: SharableStateT): ShipperSuggestStateT['query'] => {
    return state?.shipperSuggest?.query || initialShipperSuggestState.query;
};
export const selectFindShippersRequest = (state: SharableStateT): ShipperSuggestStateT['requestStatus'] => {
    return state?.shipperSuggest?.requestStatus || initialShipperSuggestState.requestStatus;
};

export const selectShippersIds = (state: SharableStateT): ShipperSuggestStateT['ids'] => {
    return state?.shipperSuggest?.ids || initialShipperSuggestState.ids;
};

export const selectShippersById = (state: SharableStateT): ShipperSuggestStateT['byId'] => {
    return state?.shipperSuggest?.byId || initialShipperSuggestState.byId;
};
