import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './ColoredDiff.scss';
import isNil from 'lodash/isNil';

type PropsT = {
    className?: string;
    diff: number | null;
    isInverted?: boolean;
};

const cx = classNames.bind(styles);

const ColoredDiff: React.FC<PropsT> = React.memo((props) => {
    const { className, diff, isInverted, children } = props;

    if (isNil(diff)) {
        return <>{children}</>;
    }

    return (
        <span
            className={cs(
                cx('margin', {
                    'margin--positive': isInverted ? diff < 0 : diff > 0,
                    'margin--negative': isInverted ? diff > 0 : diff < 0,
                }),
                className,
            )}
        >
            {children}
        </span>
    );
});

export default ColoredDiff;
