import { ApiReserveT, ReserveT, ReserveTimeWindowT } from '../models';
import { MS_IN_SEC } from 'common/utils/time';

const preparePreview = (apiReserve: ApiReserveT): ReserveT => {
    const { points: apiPoints, ...restApiReserve } = apiReserve;

    const expireTime = apiReserve.secondsToExpire * MS_IN_SEC + Date.now();

    return {
        ...restApiReserve,
        expireTime,
        points: apiPoints.map((apiPoint): ReserveT['points'][number] => {
            const { defaultFrom, defaultTo, ...restApiPoint } = apiPoint;

            const defaultTimeWindow: ReserveTimeWindowT = {
                start: defaultFrom,
                end: defaultTo,
            };

            return {
                ...restApiPoint,
                defaultTimeWindow,
            };
        }),
    };
};

export default preparePreview;
