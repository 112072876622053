import React from 'react';
import classNames from 'classnames/bind';

import styles from './PhoneNumberDropdownTrigger.scss';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { PHONE_NUMBER_PREFIX } from 'common/constants';

const cx = classNames.bind(styles);

export type PropsT = {
    name: string;

    countryCode?: string;
    onClickCountryCode: () => void;
    isSelectedCountry?: boolean;

    phoneNumber: string;
    onChangePhoneNumber: (phoneNumber: string) => void;

    autoFocus?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    isDisabled?: boolean;
    autoComplete?: string;

    testSelector?: string;
};

const PhoneNumberDropdownTrigger = React.memo((props: PropsT): React.ReactElement => {
    const {
        name,

        countryCode,
        onClickCountryCode,
        isSelectedCountry,

        phoneNumber,
        onChangePhoneNumber,

        autoFocus,
        onBlur,
        onFocus,
        isDisabled,
        autoComplete,

        testSelector,
    } = props;

    const fullTestSelector = `${testSelector}__phone-number`;

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value || '';
        onChangePhoneNumber(value);
    };

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        autoFocus: !!autoFocus,
        value: phoneNumber,
        onChange: handleChange,
        onBlur,
        onFocus,
        name,
        type: 'text',
        className: cx('inner-input', {
            'inner-input--isDisabled': isDisabled,
        }),
        disabled: isDisabled,
        autoComplete: autoComplete || 'off',
        // @ts-ignore
        'data-test-selector': `${fullTestSelector}__input`,
    };

    return (
        <div className={cx('trigger')} data-test-selector={`${testSelector}_trigger`}>
            <div className={cx('trigger__country')}>
                <div
                    className={cx('country', {
                        'country--isSelected': isSelectedCountry,
                        'country--isClickable': !isDisabled,
                    })}
                    onClick={onClickCountryCode}
                >
                    <FlagIcon countryCode={countryCode} />
                    <ArrowsIcon />
                </div>
            </div>
            <div
                className={cx('prefix', {
                    'prefix--isDisabled': isDisabled,
                })}
            >
                {PHONE_NUMBER_PREFIX}
            </div>
            <div className={cx('input')}>
                <input {...inputProps} />
            </div>
        </div>
    );
});

export default PhoneNumberDropdownTrigger;
