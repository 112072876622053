import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchShipperContractLanesPage,
    fetchShipperContractLanesPageBegin,
    fetchShipperContractLanesPageError,
    fetchShipperContractLanesPageSuccess,
    resetShipperContractLanes,
} from './slice';
import { FetchShipperContractLanesActionT } from './types';
import { CompanyTypeEnum } from 'common/constants';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import checkNeedRequest from 'common/utils/check-need-request';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import { selectShipperContractLanesPages, selectShipperContractLanesQuery } from './selectors';
import { shipperContractLanesPaginationChannel } from './channels';

function getFetchCarrierContractLanesSaga(companyType: CompanyTypeEnum) {
    return function* (action: FetchShipperContractLanesActionT): WrapGeneratorT<void> {
        const { pageNumber, query: rawQuery, partnerId, contractId } = action.payload;

        const query: typeof rawQuery = {
            ...rawQuery,
        };

        const prevQuery: ReReturnT<typeof selectShipperContractLanesQuery> = yield select(
            selectShipperContractLanesQuery(partnerId, contractId),
        );
        const pages: ReReturnT<typeof selectShipperContractLanesPages> = yield select(
            selectShipperContractLanesPages(partnerId, contractId),
        );
        const isSameQuery = checkIsSameQuery(query, prevQuery);
        const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
        if (isSameQuery && !isNeedRequest) {
            return;
        }
        if (!isSameQuery) {
            yield put(
                resetShipperContractLanes({
                    partnerId,
                    contractId,
                    mutationListOptions: { savingPageNumber: pageNumber },
                }),
            );
        }

        yield put(fetchShipperContractLanesPageBegin({ query, pageNumber, partnerId, contractId }));

        let result: ReturnApiT<
            | typeof shipperTranziitApi.fetchShipperContractLanes
            | typeof brokerTranziitApi.fetchPartnerShipperContractLanes
        >;
        if (checkIsDefaultCompanyId(partnerId)) {
            result = yield shipperTranziitApi.fetchShipperContractLanes(contractId, {
                ...query,
                page: pageNumber,
            });
        } else {
            result = yield brokerTranziitApi.fetchPartnerShipperContractLanes(partnerId, contractId, {
                ...query,
                page: pageNumber,
            });
        }

        const [error, response] = result;

        if (error) {
            yield put(fetchShipperContractLanesPageError({ query, pageNumber, error, partnerId, contractId }));
            return;
        }

        yield put(fetchShipperContractLanesPageSuccess({ query, pageNumber, response, partnerId, contractId }));

        checkShouldEmitChangePage(pageNumber, response, shipperContractLanesPaginationChannel);
    };
}

function* shipperContractLanesSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchShipperContractLanesPage.type, getFetchCarrierContractLanesSaga(companyType));
}

export { shipperContractLanesSaga };
