import {
    FETCH_USER_REQUEST,
    FETCH_USER_REQUEST_BEGIN,
    FETCH_USER_REQUEST_ERROR,
    FETCH_USER_REQUEST_SUCCESS,
    FetchUserActionT,
    FetchUserBeginActionT,
    FetchUserErrorActionT,
    FetchUserSuccessActionT,
    PATCH_USER_REQUEST,
    PATCH_USER_REQUEST_BEGIN,
    PATCH_USER_REQUEST_ERROR,
    PATCH_USER_REQUEST_SUCCESS,
    PatchUserActionT,
    PatchUserBeginActionT,
    PatchUserErrorActionT,
    PatchUserSuccessActionT,
} from './types';

import { UserChangesT, UserT } from './models';
import { ApiUserT, ApiUserUpdateT } from 'common/utils/api/models';
import { AuthUserT } from 'common/utils/api/auth/base-auth-service';

export const fetchUser = (): FetchUserActionT => ({
    type: FETCH_USER_REQUEST,
});

export const fetchUserBegin = (): FetchUserBeginActionT => ({
    type: FETCH_USER_REQUEST_BEGIN,
});

export const fetchUserSuccess = (user: ApiUserT): FetchUserSuccessActionT => ({
    type: FETCH_USER_REQUEST_SUCCESS,
    currentUser: user,
});

export const fetchUserError = (error: Error): FetchUserErrorActionT => ({
    type: FETCH_USER_REQUEST_ERROR,
    error,
});

export const patchUser = (user: UserT | null, authUser: AuthUserT | null, changes: UserChangesT): PatchUserActionT => ({
    type: PATCH_USER_REQUEST,
    user,
    authUser,
    changes,
});

export const patchUserBegin = (): PatchUserBeginActionT => ({
    type: PATCH_USER_REQUEST_BEGIN,
});

export const patchUserSuccess = (currentUser: UserT, currentUserUpdate: ApiUserUpdateT): PatchUserSuccessActionT => ({
    type: PATCH_USER_REQUEST_SUCCESS,
    currentUser,
    currentUserUpdate,
});

export const patchUserError = (error: Error): PatchUserErrorActionT => ({
    type: PATCH_USER_REQUEST_ERROR,
    error,
});
