import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Toast.scss';
import { MS_IN_SEC } from 'common/utils/time';

const cx = classNames.bind(styles);

type CloseCallbackT = () => void;

export type PropsT = {
    onClose: CloseCallbackT;
    testSelector?: string;
    className?: string;
    children: (onClose: CloseCallbackT) => React.ReactNode;
};

const TTL = 5 * MS_IN_SEC;

const ANIMATION_DURATION = 300;

type TimerT = {
    startTimer: number | null;
    timeoutId: NodeJS.Timeout | null;
    lost: number | null;
};

const Toast: React.FC<PropsT> = React.memo((props) => {
    const { testSelector, className, onClose, children } = props;

    const [isShow, setShow] = React.useState<boolean>(false);
    const handleClose = (): void => {
        setShow(false);

        setTimeout(() => {
            onClose();
        }, ANIMATION_DURATION);
    };

    const [closeTimer, setCloseTimer] = React.useState<TimerT>({
        startTimer: null,
        timeoutId: null,
        lost: null,
    });

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleClose();
        }, TTL);

        setCloseTimer({
            startTimer: Date.now(),
            timeoutId,
            lost: TTL,
        });

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            setCloseTimer({
                startTimer: null,
                timeoutId: null,
                lost: null,
            });
        };
    }, []);

    const handleMouseLeave = () => {
        const lost = closeTimer.lost || TTL;

        const timeoutId = setTimeout(() => {
            handleClose();
        }, lost);

        setCloseTimer({
            timeoutId,
            startTimer: Date.now(),
            lost,
        });
    };

    const handleMouseMove = (): void => {
        if (!closeTimer.timeoutId || !closeTimer.startTimer || !closeTimer.lost) {
            return;
        }

        clearTimeout(closeTimer.timeoutId);

        let lost = closeTimer.lost - (Date.now() - closeTimer.startTimer);
        const lostLimit = TTL / 2;
        if (lost < lostLimit) {
            lost = lostLimit;
        }

        setCloseTimer({
            timeoutId: null,
            startTimer: null,
            lost,
        });
    };

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 0);
    }, []);

    return (
        <div
            className={cs(
                cx('toast', {
                    'toast--is-show': isShow,
                }),
                className,
            )}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            {children(handleClose)}
        </div>
    );
});

export default Toast;
