import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { OrderDetailsT } from 'common/store/order-details/models';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import { RequestStatusT } from 'common/utils/request-status';

export type ConfirmCancelDialogDataT = {
    id: OrderDetailsT['id'];
    number: OrderDetailsT['number'];
};

type PropsT = {
    requestStatus: RequestStatusT | null;
    data: ConfirmCancelDialogDataT | null;
    onConfirm: (data: ConfirmCancelDialogDataT) => void;
    onCancel: () => void;
    onClose: () => void;
};

const TEST_SELECTOR = 'cancel-confirmation';

const ConfirmCancelDialog: React.FC<PropsT> = React.memo((props) => {
    const { data, requestStatus, onConfirm, onCancel, onClose } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.keep'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus?.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: requestStatus?.loading,
                            isDisabled: requestStatus?.loading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('order-details.confirm-cancel.title', {
                        number: data.number,
                    })}
                    message={t('order-details.confirm-cancel.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default ConfirmCancelDialog;
