import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    width?: number;
    height?: number;
};

const UpArrowIcon: React.FC<PropsT> = (props) => {
    const { fillColor, width = 20, height = 20 } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <defs>
                <path id="ixmsz480va" d="M13 11H7l3-3z" />
            </defs>
            <use fill={fillColor} fillRule="evenodd" xlinkHref="#ixmsz480va" />
        </svg>
    );
};

UpArrowIcon.displayName = 'UpArrowIcon';

const storyProps: PropsT = {
    width: DEFAULT_ICON_SIZE,
    height: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default UpArrowIcon;
