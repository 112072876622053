import { TemperatureModeEnum, temperatureRanges } from 'common/constants';
import values from 'lodash/values';
import isNil from 'lodash/isNil';

export const parseTemperatureMode = (
    lowestTemperature: number | null | undefined,
    highestTemperature: number | null | undefined,
): TemperatureModeEnum | null => {
    if (isNil(lowestTemperature) || isNil(highestTemperature)) {
        return null;
    }

    return (
        values(TemperatureModeEnum).find((temperatureMode) => {
            const range = temperatureRanges[temperatureMode] || [];

            return (
                range[0] === lowestTemperature &&
                lowestTemperature <= highestTemperature &&
                highestTemperature === range[1]
            );
        }) || null
    );
};
