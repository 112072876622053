import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './TimeZoneSwitcher.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { TimeZonesEnum } from '../../constants';
import { getTimeZoneLabel } from 'common/utils/timezones';

const cx = classNames.bind(styles);

type ValueT = TimeZonesEnum | null;

type PropsT = {
    value: ValueT;
    onChange?: (timeZone: ValueT) => void;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
};

type OptionT = {
    label: React.ReactNode;
    value: ValueT;
};

const TimeZoneSwitcher: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();

    const { value, hasChanges, onChange, onBlur, onFocus } = props;

    const options = React.useMemo((): Array<OptionT> => {
        return [
            TimeZonesEnum['UTC-0'],
            TimeZonesEnum['UTC+1'],
            TimeZonesEnum['UTC+2'],
            TimeZonesEnum['UTC+3'],
            TimeZonesEnum['UTC+4'],
        ].map((value) => ({
            label: getTimeZoneLabel(t, value),
            value,
        }));
    }, [t]);

    const handleOptionClick = (selectedTimeZone: ValueT): void => {
        if (selectedTimeZone === null) {
            return;
        }

        if (onChange) {
            onChange(selectedTimeZone);
        }
    };

    const renderOption = (option: OptionT | null | undefined): React.ReactElement | null =>
        option ? <span>{option?.label}</span> : null;

    const getOptionValue = (option: OptionT) => option?.value;

    return (
        <DropdownInput<OptionT, ValueT>
            selectedValue={value}
            placeholder={t('common:select-timezone')}
            options={options}
            onSelect={handleOptionClick}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
};

export default TimeZoneSwitcher;
