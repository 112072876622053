import * as React from 'react';
import { ShipperSidebarDataT } from './models';
import { ShipperSidebarsTypeEnum } from './constants';
import { GetSidebarCloseConfirmationT, RenderSidebarContentT } from 'common/layouts/SideBars/models';
import OrdersFiltersSidebarContent from 'shipper/layouts/SideBars/OrdersFiltersSidebarContent/OrdersFiltersSidebarContent';

export const renderSidebarContent: RenderSidebarContentT<ShipperSidebarDataT> = (props) => {
    const { data, ...restProps } = props;

    switch (data.type) {
        case ShipperSidebarsTypeEnum.ordersFilter: {
            return <OrdersFiltersSidebarContent data={data} {...restProps} />;
        }

        case ShipperSidebarsTypeEnum.order: {
            return <div>id</div>;
        }

        default: {
            return null;
        }
    }
};

export const getSidebarCloseConfirmation: GetSidebarCloseConfirmationT<ShipperSidebarDataT> = (t, data) => {
    return null;
};
