import * as React from 'react';
import { useTranslation } from 'react-i18next';

import LeftMenuLayoutBase from 'common/layouts/LeftMenuLayout/LeftMenuLayout';
import { BadgeTypeEnum, MenuLinkT } from 'common/layouts/LeftMenuLayout/Navigation/Navigation';
import { routesEnum } from '../../constants';
import { RFQRoutesEnum, ProfileRoutesEnum } from 'common/constants';
import {
    DatabaseMenuIcon,
    PlusMenuIcon,
    ProfileMenuIcon,
    TeamsMenuIcon,
} from 'common/layouts/LeftMenuLayout/menu-icons';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import NewIcon, { getNewIconProps } from 'common/icons/NewIcon';
import InvoiceIcon, { getInvoiceIconProps } from 'common/icons/InvoiceIcon';
import { OMSRoutesEnum } from 'broker-admin/constants';
import { selectCompanyVerificationProblems } from 'common/store/company/selectors';

const badges = {
    [routesEnum.orders]: [
        {
            type: BadgeTypeEnum.info,
            text: 10,
        },
    ],
};

type PropsT = {};

const LeftMenuLayout: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;
    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);
    const verificationProblems = useSelector(selectCompanyVerificationProblems);

    const menuLinks: Array<MenuLinkT | null> = [
        !verificationProblems.any
            ? {
                  renderIcon: (isActive) => <PlusMenuIcon isActive={isActive} />,
                  text: t('menu.new-order'),
                  to: OMSRoutesEnum.newOrder,
                  matchPathname: OMSRoutesEnum.newOrder,
                  testSelector: 'new-order',
              }
            : null,
        {
            renderIcon: (isActive) => <DatabaseMenuIcon isActive={isActive} />,
            text: t('menu.OMS'),
            to: routesEnum.orders,
            matchPathname: routesEnum.orders,
            excludeMatchPathname: OMSRoutesEnum.newOrder,
            testSelector: 'orders',
        },
        {
            renderIcon: (isActive) => <NewIcon {...getNewIconProps(isActive ? 'menu-item-selected' : 'menu-item')} />,
            text: t('menu.RFQ'),
            to: RFQRoutesEnum.list,
            matchPathname: routesEnum.rfqs,
            testSelector: 'rfqs',
        },
        permissions?.canViewInvoices
            ? {
                  renderIcon: (isActive) => <InvoiceIcon {...getInvoiceIconProps(isActive ? 'selected' : 'default')} />,
                  text: t('menu.invoices'),
                  to: routesEnum.invoices,
                  matchPathname: routesEnum.invoices,
                  testSelector: 'invoices',
              }
            : null,
        permissions.canViewTeamMembers
            ? {
                  renderIcon: (isActive) => <TeamsMenuIcon isActive={isActive} />,
                  text: t('menu.team'),
                  to: routesEnum.team,
                  matchPathname: routesEnum.team,
                  testSelector: 'team',
              }
            : null,
        {
            renderIcon: (isActive) => <ProfileMenuIcon isActive={isActive} />,
            text: t('menu.profile'),
            to: ProfileRoutesEnum.basic,
            matchPathname: routesEnum.profile,
            testSelector: 'profile',
        },
    ];

    return (
        <LeftMenuLayoutBase
            menuLinks={menuLinks}
            accountLink={routesEnum.account}
            logoLink={routesEnum.home}
            badges={badges}
            isAllowHelpButton
        >
            {children}
        </LeftMenuLayoutBase>
    );
});

export default LeftMenuLayout;
