import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { RequestStatusT } from 'common/utils/request-status';
import BigTrashIcon from 'common/icons/BigTrashIcon';

export type RevokeShipperLaneConfirmationDataT = {
    partnerId: PartnerIdT;
    shipperContractId: ShipperContractIdT;
    laneId: ShipperContractLaneIdT;
    laneNumber: string;
};

type PropsT = {
    data: RevokeShipperLaneConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: RevokeShipperLaneConfirmationDataT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'revoke-shipper-lane-confirmation';

const RevokeShipperLaneConfirmationModal: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, requestStatus, onConfirm } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.revoke'),
                            theme: ButtonThemeEnum.danger,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('common:shipper-lane-details.dialogs.revoke.title', {
                        number: data.laneNumber,
                    })}
                    message={t('common:shipper-lane-details.dialogs.revoke.message')}
                />
            </ModalContent>
        </Modal>
    );
});

export default RevokeShipperLaneConfirmationModal;
