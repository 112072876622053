import { RFQSourceEnum, RFQsQuickFilterEnum, RFQsSortEnum, RFQStatusEnum } from 'common/utils/api/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { FetchRFQsPageQueryT } from 'common/store/rfqs/models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

const getQueryFromQuickFilters = (quickFilter?: RFQsQuickFilterEnum): FetchRFQsPageQueryT => {
    switch (quickFilter) {
        case RFQsQuickFilterEnum.closed: {
            return {
                status: RFQStatusEnum.closed,
            };
        }
        case RFQsQuickFilterEnum.completed: {
            return {
                status: RFQStatusEnum.completed,
            };
        }
        case RFQsQuickFilterEnum.preExpire: {
            return {
                status: RFQStatusEnum.expireSoon,
            };
        }
        case RFQsQuickFilterEnum.expired: {
            return {
                status: RFQStatusEnum.expired,
            };
        }
        case RFQsQuickFilterEnum.open: {
            return {
                status: RFQStatusEnum.open,
            };
        }
        default:
            return {};
    }
};

const SORT_VALUE_MAP: Record<RFQsSortEnum, FetchRFQsPageQueryT['sortBy']> = {
    [RFQsSortEnum.creationDate]: 'CREATED',
    [RFQsSortEnum.deadline]: 'EXPIRATION',
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<RFQsSortEnum> | null,
): FetchRFQsPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

    const source = queryFilters[QueryFiltersKeysEnum.source];

    return {
        trailerType: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,
        origin: queryFilters[QueryFiltersKeysEnum.pickupAddress] || undefined,
        destination: queryFilters[QueryFiltersKeysEnum.dropoffAddress] || undefined,

        // @ts-expect-error TODO wait backend https://tranziit.atlassian.net/browse/TZT-6730
        searchText: queryFilters[QueryFiltersKeysEnum.searchText],

        pickupFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        pickupTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo] || undefined,

        dropOffFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        dropOffTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo] || undefined,

        createdFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        createdBy: queryFilters[QueryFiltersKeysEnum.createdById] || undefined,

        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        imported: source ? source === RFQSourceEnum.imported : undefined,

        ...(sort?.value
            ? {
                  sortBy: SORT_VALUE_MAP[sort.value],
                  sortAsc: sort.direction === SortDirectionEnum.ASC,
              }
            : {}),
    };
};

export { prepareFetchPageQuery };
