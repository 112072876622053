import { DictDocumentT } from 'common/store/documents-dict/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { useSelector } from 'react-redux';
import { selectDocumentsDictById } from 'common/store/documents-dict/selectors';

const useDictDocumentById = (
    partnerType: PartnerTypeEnum | null | undefined,
    dictDocumentId: DictDocumentIdT | null | undefined,
): DictDocumentT | null => {
    const documentsDictById = useSelector(selectDocumentsDictById(partnerType as PartnerTypeEnum));

    return documentsDictById?.[dictDocumentId as DictDocumentIdT] || null;
};

export { useDictDocumentById };
