import * as React from 'react';
import { useFormik } from 'formik';
import AuthLayout from 'common/layouts/AuthLayout/AuthLayout';

import styles from './ChangePasswordPage.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from 'common/store/auth/actions';
import { selectUpdatePasswordRequestStatus } from 'common/store/auth/selectors';
import useQuery from 'common/utils/hooks/useQuery';
import validateForm from './validate-form';
import { FieldsEnum, FormValuesT } from './constants';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import useAsyncFormErrorMessage from 'common/utils/hooks/useAsyncFormErrorMessage';
import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import RedirectSignedUser from 'common/components/RedirectSignedUser/RedirectSignedUser';
import { authApi } from 'common/utils/api/auth/factory';

const cx = classNames.bind(styles);

const INITIAL_VALUES: FormValuesT = { [FieldsEnum.password]: '' };

type PropsT = {};

type QueryT = {
    oobCode: string;
};

const ChangePasswordPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useQuery<QueryT>();
    const requestStatus = useSelector(selectUpdatePasswordRequestStatus);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, INITIAL_VALUES);
    }, [t, INITIAL_VALUES]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues: INITIAL_VALUES,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!query.oobCode) {
                return;
            }

            const password = simpleStringFormatter(values[FieldsEnum.password]);
            dispatch(updatePassword(query.oobCode, password));

            formikHelpers.setTouched({});
        },
    });

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(requestStatus);
    }, [requestStatus.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const asyncPasswordError = asyncFormErrors[FieldsEnum.password];

    let passwordAsyncError = useAsyncFormErrorMessage(asyncPasswordError);
    if (asyncPasswordError === AuthErrorTypeEnum.expiredActionCode) {
        passwordAsyncError = t('common:change-password.errors.expired-link');
    }
    if (asyncPasswordError === AuthErrorTypeEnum.invalidActionCode) {
        passwordAsyncError = t('common:change-password.errors.invalid-link');
    }

    if (requestStatus.ok) {
        return <Redirect to={authApi.createSignInUrlAfterChangePassword()} />;
    }

    return (
        <>
            <RedirectSignedUser />
            <AuthLayout title={t('common:change-password.title')} testSelector="change-password-page">
                <PageTitle title={t('common:page-titles.change-password')} />
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('fields')}>
                        <div className={cx('input')}>
                            <FormikField
                                name={FieldsEnum.password}
                                error={formik.errors[FieldsEnum.password]}
                                meta={formik.getFieldMeta(FieldsEnum.password)}
                                label={t('common:change-password.fields.password.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                asyncError={passwordAsyncError}
                                resetAsyncError={resetAsyncFormErrors}
                            >
                                {(props) => (
                                    <Input
                                        type="password"
                                        name={FieldsEnum.password}
                                        value={formik.values[FieldsEnum.password]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </div>
                    <Button
                        isLoading={requestStatus.loading}
                        theme={ButtonThemeEnum.primary}
                        type="submit"
                        className={cx('submit')}
                    >
                        {t('common:change-password.submit')}
                    </Button>
                </form>
            </AuthLayout>
        </>
    );
});

export default ChangePasswordPage;
