import * as React from 'react';
import { ApiEmissionStandartT } from 'common/utils/api/models';
import { EmissionStandartEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    isCompact?: boolean;
    emissionStandart: ApiEmissionStandartT | null | undefined;
};

export const EMISSION_STANDART_MAP: Record<ApiEmissionStandartT, string> = {
    [EmissionStandartEnum.euro5]: 'V',
    [EmissionStandartEnum.euro6]: 'VI',
};

export const EMISSION_STANDART_I18N_MAP: Record<ApiEmissionStandartT, string> = {
    [EmissionStandartEnum.euro5]: 'common:emission-classes.EURO_V',
    [EmissionStandartEnum.euro6]: 'common:emission-classes.EURO_VI',
};

const EmissionStandartLabel: React.FC<PropsT> = React.memo((props) => {
    const { emissionStandart, isCompact } = props;

    const { t } = useTranslation();

    if (!emissionStandart) {
        return null;
    }

    if (isCompact) {
        return <span>{EMISSION_STANDART_MAP[emissionStandart]}</span>;
    }

    return <span>{t(EMISSION_STANDART_I18N_MAP[emissionStandart])}</span>;
});

export default EmissionStandartLabel;
