import moment from 'moment';
import { FieldsEnum, FormValuesT } from './constants';
import { ApiContactFormalEnum, ContactPositionsEnum, CountryCodeT } from 'common/utils/api/models';
import { CompanyContactDetailsT } from 'common/store/members/models';
import { PHONE_NUMBER_PREFIX, SocialMediaEnum } from 'common/constants';

const DEFAULT_VALUES: FormValuesT = {
    [FieldsEnum.id]: null,
    [FieldsEnum.formal]: ApiContactFormalEnum.mr,
    [FieldsEnum.firstName]: '',
    [FieldsEnum.lastName]: '',

    [FieldsEnum.position]: ContactPositionsEnum.operator,
    [FieldsEnum.isDefault]: false,

    [FieldsEnum.desktopPhone]: PHONE_NUMBER_PREFIX,
    [FieldsEnum.mobilePhone]: PHONE_NUMBER_PREFIX,
    [FieldsEnum.faxNumber]: '',
    [FieldsEnum.email]: '',

    [FieldsEnum.webpageLink]: '',

    [FieldsEnum.birthday]: null,
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.language]: null,
};

const getInitialValues = (memberDetails?: CompanyContactDetailsT | null): FormValuesT => {
    const values = { ...DEFAULT_VALUES };

    if (memberDetails?.id) {
        values[FieldsEnum.id] = memberDetails.id;
    }

    if (memberDetails?.formal) {
        values[FieldsEnum.formal] = memberDetails.formal as ApiContactFormalEnum;
    }

    if (memberDetails?.name) {
        values[FieldsEnum.firstName] = memberDetails.name;
    }

    if (memberDetails?.surname) {
        values[FieldsEnum.lastName] = memberDetails.surname;
    }

    if (memberDetails?.position) {
        values[FieldsEnum.position] = memberDetails.position as ContactPositionsEnum;
    }

    if (memberDetails?.main) {
        values[FieldsEnum.isDefault] = memberDetails.main;
    }

    if (memberDetails?.deskPhone) {
        values[FieldsEnum.desktopPhone] = memberDetails.deskPhone;
    }

    if (memberDetails?.mobilePhone) {
        values[FieldsEnum.mobilePhone] = memberDetails.mobilePhone;
    }

    if (memberDetails?.fax) {
        values[FieldsEnum.faxNumber] = memberDetails.fax;
    }

    if (memberDetails?.email) {
        values[FieldsEnum.email] = memberDetails.email;
    }

    memberDetails?.medias.forEach((media) => {
        const { login, type } = media;

        values[type as SocialMediaEnum] = login;
    });

    if (memberDetails?.birthday) {
        values[FieldsEnum.birthday] = moment(memberDetails.birthday, 'YYYY-MM-DD').toDate();
    }

    if (memberDetails?.residence) {
        values[FieldsEnum.countryCode] = (memberDetails.residence as CountryCodeT) || null;
    }

    if (memberDetails?.language) {
        values[FieldsEnum.language] = memberDetails.language;
    }

    return values;
};

export default getInitialValues;
