import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const ContactIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M10.877 4L14.5 7.622V16h-9V4h5.377M10 11.5c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5-1.5.675-1.5 1.5.675 1.5 1.5 1.5zm3 2.572c0-.607-.36-1.147-.915-1.387a5.213 5.213 0 00-4.17 0c-.555.24-.915.78-.915 1.387v.428h6v-.428z"
                />
                <path
                    fill={strokeColor}
                    d="M10.877 4L14.5 7.622V16h-9V4h5.377m.623-1.5h-6C4.675 2.5 4 3.175 4 4v12c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5V7l-4.5-4.5zm-1.5 9c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5-1.5.675-1.5 1.5.675 1.5 1.5 1.5zm3 2.572c0-.607-.36-1.147-.915-1.387a5.213 5.213 0 00-4.17 0c-.555.24-.915.78-.915 1.387v.428h6v-.428z"
                />
            </g>
        </svg>
    );
};

ContactIcon.displayName = 'ContactIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default ContactIcon;
