import { parseSimpleNumber } from 'common/utils/input-parsers';
import { FieldsEnum, FormValuesT } from './constants';
import isNumber from 'lodash/isNumber';
import { ContractDetailsChangesT } from 'common/store/carrier-contracts/models';
import moment from 'moment/moment';

const prepareChanges = (contractId: CarrierContractIdT | null, values: FormValuesT): ContractDetailsChangesT | null => {
    const truckRateKm = parseSimpleNumber(values[FieldsEnum.truckRate]);
    const tiltRateKm = parseSimpleNumber(values[FieldsEnum.tiltRate]);
    const boxRateKm = parseSimpleNumber(values[FieldsEnum.boxRate]);
    const boxExpectedKm = parseSimpleNumber(values[FieldsEnum.boxExpectedMileage]);
    const tiltExpectedKm = parseSimpleNumber(values[FieldsEnum.tiltExpectedMileage]);
    const truckExpectedKm = parseSimpleNumber(values[FieldsEnum.truckExpectedMileage]);
    const deadheadExpectedKm = parseSimpleNumber(values[FieldsEnum.deadheadExpectedMileage]);
    const rawExpiration = values[FieldsEnum.expiration];
    const expiration = rawExpiration ? moment(rawExpiration).format('YYYY-MM-DD') : null;

    if (
        !expiration ||
        !isNumber(truckRateKm) ||
        !isNumber(tiltRateKm) ||
        !isNumber(boxRateKm) ||
        !isNumber(tiltExpectedKm) ||
        !isNumber(truckExpectedKm) ||
        !isNumber(boxExpectedKm) ||
        !isNumber(deadheadExpectedKm)
    ) {
        return null;
    }

    return {
        validTill: expiration,
        number: values[FieldsEnum.number],
        name: values[FieldsEnum.name],
        truckRateKm,
        tiltRateKm,
        boxRateKm,
        boxExpectedKm,
        tiltExpectedKm,
        truckExpectedKm,
        deadheadExpectedKm,
    };
};

export default prepareChanges;
