import { ViewEventChannel, ViewEventHandlerT } from 'common/utils/view-event-channel';
import { useEffect } from 'react';

export const useChannelSubscribe = <EventT extends {}>(
    channel: ViewEventChannel<EventT>,
    handler: ViewEventHandlerT<EventT>,
) => {
    useEffect(() => {
        channel.subscribe(handler);

        return () => {
            channel.unsubscribe(handler);
        };
    }, [handler]);
};
