import * as React from 'react';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';

import classNames from 'classnames/bind';
import styles from './AccountPage.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AccountPreview from './AccountPreview/AccountPreview';
import { selectCurrentUser, selectPatchUserRequestStatus } from 'common/store/user/selectors';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import LanguageSwitcher from 'common/components/LanguageSwitcher/LanguageSwitcher';
import TimeZoneSwitcher from 'common/components/TimeZoneSwitcher/TimeZoneSwitcher';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { patchUser } from 'common/store/user/actions';
import { selectUser } from 'common/store/auth/selectors';
import { FieldsEnum, FormValuesT } from './constants';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import validateForm from './validate-form';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import { AUTO_COMPLETE_OFF_FIX, PHONE_NUMBER_PREFIX } from 'common/constants';
import isNumber from 'lodash/isNumber';
import { LangEnum } from 'common/locales/i18n';
import Widget from 'common/components/Widget/Widget';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import ScrollableContent from '../LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from '../LeftMenuLayout/ContentMargins/ContentMargins';
import useContainerScroll from 'common/utils/hooks/useContainerScroll';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import { parsePhoneNumber } from 'common/utils/input-parsers';

import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import LeftCardLayout from 'common/layouts/LeftCardLayout/LeftCardLayout';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import useAsyncFormErrorMessage from 'common/utils/hooks/useAsyncFormErrorMessage';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { PartnerTypeEnum } from 'common/utils/api/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { authApi } from 'common/utils/api/auth/factory';
import { AuthAccountUrlsT } from 'common/utils/api/auth/base-auth-service';
import { useEffect } from 'react';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import DevelopmentSettings from 'common/layouts/AccountPage/DevelopmentSettings/DevelopmentSettings';
import { checkIsProduction } from 'common/utils/test-urls';

const cx = classNames.bind(styles);

type PropsT = {};

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges([
    FieldsEnum.firstName,
    FieldsEnum.lastName,
    FieldsEnum.phoneNumber,
    FieldsEnum.language,
    FieldsEnum.timezone,
    FieldsEnum.newPassword,
]);

const AccountPage: React.FC<PropsT> = React.memo((props) => {
    const { partnerType } = usePartnerContext();

    const isBroker = partnerType === PartnerTypeEnum.broker;

    const { t } = useTranslation();
    const user = useSelector(selectCurrentUser);
    const saveRequestStatus = useSelector(selectPatchUserRequestStatus);
    const firebaseUser = useSelector(selectUser);
    const dispatch = useDispatch();

    const { containerRef, onContainerScroll, scrollTop } = useContainerScroll();

    const scrollBarWidth = React.useMemo(() => getScrollbarWidth(containerRef?.current), [containerRef?.current]);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.firstName]: user?.name || '',
            [FieldsEnum.lastName]: user?.surname || '',
            [FieldsEnum.phoneNumber]: user?.phone || PHONE_NUMBER_PREFIX,
            [FieldsEnum.timezone]: isNumber(user?.timezone) ? (user?.timezone as number) : null,
            [FieldsEnum.language]: (user?.language as LangEnum) || null,
            [FieldsEnum.currentPassword]: '',
            [FieldsEnum.newPassword]: '',
        };

        const errors = validate(values);

        return [values, errors];
    }, [user]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const timezone = values[FieldsEnum.timezone];

            dispatch(
                patchUser(user, firebaseUser, {
                    firstName: simpleStringFormatter(values[FieldsEnum.firstName]),
                    lastName: simpleStringFormatter(values[FieldsEnum.lastName]),
                    phoneNumber: parsePhoneNumber(values[FieldsEnum.phoneNumber]),
                    timezone: isNumber(timezone) ? timezone : null,
                    language: values[FieldsEnum.language] || null,
                    currentPassword: simpleStringFormatter(values[FieldsEnum.currentPassword]) || null,
                    newPassword: simpleStringFormatter(values[FieldsEnum.newPassword]) || null,
                }),
            );
            formikHelpers.setTouched({});
        },
    });

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const hasChanges = useWatchAnyFieldValueChanges(formik.values, initialValues) && formik.dirty && user;

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(saveRequestStatus);
    }, [saveRequestStatus?.error]);

    React.useEffect(() => {
        if (saveRequestStatus?.ok) {
            formik.setValues((values) => ({
                ...values,
                [FieldsEnum.currentPassword]: '',
                [FieldsEnum.newPassword]: '',
            }));
        }
    }, [saveRequestStatus?.ok]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const currentPasswordAsyncError = useAsyncFormErrorMessage(asyncFormErrors[FieldsEnum.currentPassword]);
    const currentPasswordError = formik.errors[FieldsEnum.currentPassword];

    const isShowNewPasswordError =
        !!formik.values[FieldsEnum.currentPassword] && !currentPasswordError && !currentPasswordAsyncError;

    const [accountUrls, setAccountUrls] = React.useState<AuthAccountUrlsT | null>(null);

    useEffect(() => {
        (async () => {
            const result = await authApi.getAccountUrls();
            setAccountUrls(result?.[1] || null);
        })();
    }, [authApi]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')} autoComplete="off">
            <PageTitle title={t('common:page-titles.account-settings')} />
            <ScrollableContent
                scrollContainerRef={containerRef}
                onContainerScroll={onContainerScroll}
                stickyHeaderNode={
                    <TopBar isSticky isWhite={scrollTop > 10} scrollBarWidth={scrollBarWidth}>
                        <TopBarContent
                            title={t('common:account-page.title')}
                            rightNode={
                                <>
                                    {(hasChanges || saveRequestStatus.loading) && (
                                        <div className={cx('header__controls')}>
                                            <Button
                                                theme={ButtonThemeEnum.primary}
                                                type="submit"
                                                className={cx('control__save')}
                                                isLoading={saveRequestStatus.loading}
                                            >
                                                {t('common:account-page.buttons.save')}
                                            </Button>
                                            <Button
                                                theme={ButtonThemeEnum.secondary}
                                                onClick={() => {
                                                    formik.resetForm({});
                                                }}
                                            >
                                                {t('common:account-page.buttons.cancel')}
                                            </Button>
                                        </div>
                                    )}
                                    <NotificationsBarTrigger />
                                </>
                            }
                        />
                    </TopBar>
                }
            >
                <ContentMargins>
                    {!user && <LoaderOverlay />}
                    <LeftCardLayout testSelector="account-page" cardNode={<AccountPreview />}>
                        <Widget
                            className={cx('widget')}
                            title={t('common:account-page.sections.base')}
                            rightNode={
                                <WidgetChangesDetector
                                    fields={[FieldsEnum.firstName, FieldsEnum.lastName, FieldsEnum.phoneNumber]}
                                />
                            }
                        >
                            <div className={cx('widget__inner')}>
                                <FieldGroup>
                                    <FormikField
                                        className={cx('field')}
                                        name={FieldsEnum.firstName}
                                        error={formik.errors[FieldsEnum.firstName]}
                                        meta={formik.getFieldMeta(FieldsEnum.firstName)}
                                        label={t('common:account-page.field-labels.first-name')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <Input
                                                name={FieldsEnum.firstName}
                                                value={formik.values[FieldsEnum.firstName]}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                    <FormikField
                                        className={cx('field')}
                                        name={FieldsEnum.lastName}
                                        error={formik.errors[FieldsEnum.lastName]}
                                        meta={formik.getFieldMeta(FieldsEnum.lastName)}
                                        label={t('common:account-page.field-labels.last-name')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <Input
                                                name={FieldsEnum.lastName}
                                                value={formik.values[FieldsEnum.lastName]}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                </FieldGroup>
                                <FieldGroup>
                                    <FormikField
                                        className={cx('field')}
                                        name={FieldsEnum.phoneNumber}
                                        error={formik.errors[FieldsEnum.phoneNumber]}
                                        meta={formik.getFieldMeta(FieldsEnum.phoneNumber)}
                                        label={t('common:account-page.field-labels.phone-number')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <PhoneNumberInput
                                                name={FieldsEnum.phoneNumber}
                                                value={formik.values[FieldsEnum.phoneNumber]}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                    <FieldGroupEmptyItem className={cx('field')} />
                                </FieldGroup>
                            </div>
                        </Widget>
                        <Widget
                            className={cx('widget')}
                            title={t(
                                isBroker
                                    ? 'common:account-page.sections.language-and-timezone'
                                    : 'common:account-page.sections.language',
                            )}
                            rightNode={<WidgetChangesDetector fields={[FieldsEnum.language, FieldsEnum.timezone]} />}
                        >
                            <div className={cx('widget__inner')}>
                                <FieldGroup>
                                    <FormikField
                                        className={cx('field', 'field--language-switcher')}
                                        name={FieldsEnum.language}
                                        error={formik.errors[FieldsEnum.language]}
                                        meta={formik.getFieldMeta(FieldsEnum.language)}
                                        label={t('common:account-page.field-labels.language')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <LanguageSwitcher
                                                lang={formik.values[FieldsEnum.language]}
                                                isFullSize
                                                onChange={props.onChange}
                                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                    {isBroker ? (
                                        <FormikField
                                            className={cx('field')}
                                            name={FieldsEnum.timezone}
                                            error={formik.errors[FieldsEnum.timezone]}
                                            meta={formik.getFieldMeta(FieldsEnum.timezone)}
                                            label={t('common:account-page.field-labels.timezone')}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <TimeZoneSwitcher
                                                    value={formik.values[FieldsEnum.timezone]}
                                                    onChange={(timeZone) => {
                                                        formik.setFieldValue(FieldsEnum.timezone, timeZone);
                                                    }}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasChanges={props.hasChanges}
                                                />
                                            )}
                                        </FormikField>
                                    ) : (
                                        <FieldGroupEmptyItem className={cx('field')} />
                                    )}
                                </FieldGroup>
                            </div>
                        </Widget>
                        <Widget
                            className={cx('widget')}
                            title={t('common:account-page.sections.change-password')}
                            rightNode={<WidgetChangesDetector fields={[FieldsEnum.newPassword]} />}
                        >
                            {accountUrls?.changeCreds ? (
                                <div className={cx('account-link__wrap')}>
                                    <Link
                                        className={cx('account-link')}
                                        to={accountUrls.changeCreds}
                                        theme={LinkThemeEnum.boldBrandDark}
                                        size={LinkSizeEnum.extraSmall}
                                        isExternal
                                    >
                                        {t('common:account-page.account-links.change-password')}
                                    </Link>
                                </div>
                            ) : (
                                <div className={cx('widget__inner')}>
                                    <FieldGroup>
                                        <FormikField
                                            className={cx('field')}
                                            name={FieldsEnum.currentPassword}
                                            asyncError={currentPasswordAsyncError}
                                            resetAsyncError={resetAsyncFormErrors}
                                            error={currentPasswordError}
                                            meta={formik.getFieldMeta(FieldsEnum.currentPassword)}
                                            label={t('common:account-page.field-labels.current-password')}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <Input
                                                    type="password"
                                                    name={FieldsEnum.currentPassword}
                                                    value={formik.values[FieldsEnum.currentPassword]}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    autoComplete={AUTO_COMPLETE_OFF_FIX}
                                                />
                                            )}
                                        </FormikField>
                                        <FormikField
                                            className={cx('field')}
                                            name={FieldsEnum.newPassword}
                                            error={
                                                isShowNewPasswordError ? formik.errors[FieldsEnum.newPassword] : null
                                            }
                                            meta={formik.getFieldMeta(FieldsEnum.newPassword)}
                                            label={t('common:account-page.field-labels.new-password')}
                                            setFieldValue={formik.setFieldValue}
                                            setFieldTouched={formik.setFieldTouched}
                                        >
                                            {(props) => (
                                                <Input
                                                    type="password"
                                                    name={FieldsEnum.newPassword}
                                                    value={formik.values[FieldsEnum.newPassword]}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    hasChanges={props.hasChanges}
                                                    autoComplete={AUTO_COMPLETE_OFF_FIX}
                                                />
                                            )}
                                        </FormikField>
                                    </FieldGroup>
                                </div>
                            )}
                        </Widget>
                        {!checkIsProduction && (
                            <Widget className={cx('widget')} title={t('common:account-page.sections.development')}>
                                <DevelopmentSettings />
                            </Widget>
                        )}
                    </LeftCardLayout>
                </ContentMargins>
            </ScrollableContent>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
        </form>
    );
});

export default AccountPage;
