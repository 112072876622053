import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './BaseShipperContractLanesLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApiShipperContractLaneStatusT, ShipperContractLanesSortEnum } from 'common/utils/api/models';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import TableError from 'common/components/Table/TableError/TableError';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import Pagination from 'common/components/Table/Pagination/Pagination';
import BaseShipperContractLanesTable from 'common/layouts/BaseShipperContractLanesLayout/BaseShipperContractLanesTable/BaseShipperContractLanesTable';
import { useQueryParams } from 'use-query-params';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import noop from 'lodash/noop';
import { isNonNil } from 'common/utils';
import {
    selectShipperContractLanesByIds,
    selectShipperContractLanesPages,
    selectShipperContractLanesTotal,
} from 'common/store/shipper-contract-lanes/selectors';
import { fetchShipperContractLanesPage } from 'common/store/shipper-contract-lanes/slice';
import {
    shipperContractLanesPaginationChannel,
    shipperContractLanesRefreshChannel,
} from 'common/store/shipper-contract-lanes/channels';
import { ShipperContractLanesTableRowT } from 'common/layouts/BaseShipperContractLanesLayout/BaseShipperContractLanesTable/models';
import ShipperContractLaneStatusDropdownControl from 'common/components/controls/ShipperContractLaneStatusDropdownControl/ShipperContractLaneStatusDropdownControl';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import FiltersSidebarContent from './FiltersSidebarContent/FiltersSidebarContent';
import SideBar from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import { selectPermissions } from 'common/store/auth/selectors';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import CalendarIcon from 'common/icons/CalendarIcon';
import { getSignedDayDiff } from 'common/utils/time';
import { MIN_VALID_FROM_VALID_TILL_DAYS } from 'common/layouts/CommonEditableShipperContractLaneDetailsLayout/EditShipperContractLaneSidebarContent/ShipperContractLaneEditForm/constants';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkQueryFiltersKeys } from 'common/components/Table/utils/check-query-filters-keys';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    contractId: ShipperContractIdT;

    navigationTabs?: React.ReactNode;

    onCreateShipperContractLane?: () => void;

    onOpenShipperContractLaneDetails: (laneId: ShipperContractLaneIdT) => void;
};

const BaseShipperContractLanesLayout: React.FC<PropsT> = React.memo((props) => {
    const { navigationTabs, partnerId, contractId, onOpenShipperContractLaneDetails, onCreateShipperContractLane } =
        props;

    const { t } = useTranslation();

    const [isShowFilters, triggerFilters] = React.useState<boolean>(false);
    const showFilters = (): void => {
        triggerFilters(true);
    };
    const hideFilters = (): void => {
        triggerFilters(false);
    };

    const dispatch = useDispatch();

    const permissions = useSelector(selectPermissions);

    const contractDetailsState = useSelector(selectShipperContractDetailsState(contractId));
    const contractDetails = contractDetailsState.data;

    const lostDayDiff = useMemo((): number => {
        const now = new Date();
        if (!contractDetails?.validTill) {
            return 0;
        }

        const dayDiff = getSignedDayDiff(contractDetails.validTill, now);
        return dayDiff;
    }, [contractDetails]);

    const isShowContractExpirationAlert = !!contractDetails && lostDayDiff < MIN_VALID_FROM_VALID_TILL_DAYS;
    const isAllowShowAddLaneTrigger = !!contractDetails && !isShowContractExpirationAlert;

    const isAllowAddLane = !!onCreateShipperContractLane && permissions.canUploadShipperContracts;

    const total = useSelector(selectShipperContractLanesTotal(partnerId, contractId));
    const pages = useSelector(selectShipperContractLanesPages(partnerId, contractId));
    const byId = useSelector(selectShipperContractLanesByIds(partnerId, contractId));

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.shipperContractLanesPage]: createPageNumberParam(),
        [QueryKeysEnum.shipperContractLanesSort]: createSortParams<ShipperContractLanesSortEnum>({
            value: ShipperContractLanesSortEnum.addedDate,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.shipperContractLanesFilters]: createJsonParams<QueryFiltersT>({}),
    });

    const selectedSort = query[QueryKeysEnum.shipperContractLanesSort];
    const pageNumber = query[QueryKeysEnum.shipperContractLanesPage];
    const queryFilters = query[QueryKeysEnum.shipperContractLanesFilters];

    const page = pages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(
            fetchShipperContractLanesPage({
                contractId,
                pageNumber,
                query,
                partnerId,
                options: { isForceUpdate: false },
            }),
        );
    }, [pageNumber, selectedSort, queryFilters, partnerId, contractId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(
            fetchShipperContractLanesPage({
                contractId,
                pageNumber,
                query,
                partnerId,
                options: { isForceUpdate: true },
            }),
        );
    }, [pageNumber, selectedSort, queryFilters, partnerId, contractId]);
    useChannelSubscribe(shipperContractLanesRefreshChannel, refreshPageHandler);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.shipperContractLanesPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof shipperContractLanesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(shipperContractLanesPaginationChannel, setPageHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchShipperContractLanesPage({ pageNumber, query, partnerId, contractId }));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSort, queryFilters, partnerId, contractId]);

    const shipperContractLanesTableRows = React.useMemo((): Array<ShipperContractLanesTableRowT> => {
        return (ids || []).map((id) => byId[id]).filter(isNonNil);
    }, [ids, byId]);

    const sortOptions: Array<SortDropdownOptionT<ShipperContractLanesSortEnum>> = React.useMemo(
        () => [
            {
                label: t('common:shipper-contract-lanes.table.sorts.added-date'),
                value: ShipperContractLanesSortEnum.addedDate,
            },
            {
                label: t('common:shipper-contract-lanes.table.sorts.valid-till-date'),
                value: ShipperContractLanesSortEnum.validTillDate,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<ShipperContractLanesSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.shipperContractLanesPage]: 0,
            [QueryKeysEnum.shipperContractLanesSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.shipperContractLanesPage]: 0,
            [QueryKeysEnum.shipperContractLanesFilters]: selectedQueryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.shipperContractLanesFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.shipperContractLanesPage]: 0,
            [QueryKeysEnum.shipperContractLanesFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.address]: undefined,
            [QueryFiltersKeysEnum.text]: searchText || undefined,
        });
    };
    const handleSelectStatusFilter = (status: ApiShipperContractLaneStatusT | null) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.status]: status || undefined,
        });
    };

    const handleOpenShipperContractLaneDetails = (event: React.MouseEvent, row: ShipperContractLanesTableRowT) => {
        onOpenShipperContractLaneDetails(row.id);
    };

    const renderEmptyState = () => {
        if (!checkNeedRenderEmptyState(shipperContractLanesTableRows, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasAnyFilters } = checkQueryFiltersKeys(queryFilters, [], []);
        if (hasAnyFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:shipper-contract-lanes.table.messages.not-found.title')}
                    description={t('common:shipper-contract-lanes.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('common:shipper-contract-lanes.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="not-found"
                />
            );
        }

        const isShowAction = isAllowAddLane && isAllowShowAddLaneTrigger;

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:shipper-contract-lanes.table.messages.empty.title')}
                description={isShowAction ? t('common:shipper-contract-lanes.table.messages.empty.description') : null}
                isShowAction={isShowAction}
                actionTitle={t('common:shipper-contract-lanes.table.messages.empty.action')}
                actionTheme={ButtonThemeEnum.primary}
                onActionClick={isShowAction ? onCreateShipperContractLane : noop}
                testSelector="empty"
            />
        );
    };

    return (
        <>
            <ListPageLayout>
                <ListPageHeaderLayout
                    leftToolsNode={
                        <>
                            {!!navigationTabs && <div>{navigationTabs}</div>}
                            <FiltersTrigger
                                className={cx('filters-trigger')}
                                title={t('common:shipper-contract-lanes.filters.trigger')}
                                isActive={isShowFilters}
                                onClick={showFilters}
                            />
                            <SortDropdown
                                className={cx('sort-trigger')}
                                overlayPosition={SortDropdownOverlayPositionEnum.left}
                                selectedValue={selectedSort}
                                options={sortOptions}
                                onSelect={handleSelectSort}
                            />
                            <ShipperContractLaneStatusDropdownControl
                                className={cx('status')}
                                value={queryFilters[QueryFiltersKeysEnum.status] || null}
                                onChange={handleSelectStatusFilter}
                            />
                            <SearchControl
                                placeholder={t('common:shipper-contract-lanes.table.search.placeholder')}
                                searchText={queryFilters[QueryFiltersKeysEnum.text]}
                                onChangeSearchText={handleSearch}
                            />
                        </>
                    }
                    filterTagsNode={
                        <SelectedFilters
                            isCompact
                            queryFilters={queryFilters}
                            setQueryFilters={handleSetQueryFilters}
                        />
                    }
                    rightToolsNode={
                        isAllowAddLane && isAllowShowAddLaneTrigger ? (
                            <Button
                                theme={ButtonThemeEnum.primary}
                                className={cx('action', 'action--start')}
                                onClick={onCreateShipperContractLane}
                            >
                                {t('common:shipper-contract-lanes.actions.upload')}
                            </Button>
                        ) : null
                    }
                />
                {isAllowAddLane && isShowContractExpirationAlert && (
                    <Alert
                        icon={
                            <CalendarIcon
                                size={DEFAULT_ICON_SIZE}
                                strokeColor={StyleGuideColorsEnum.white}
                                fillColor={StyleGuideColorsEnum.gray}
                            />
                        }
                        size={AlertSizeEnum.small}
                        theme={AlertThemeEnum.gray}
                        className={cx('alert')}
                    >
                        {t('common:shipper-contract-lanes.alert.disable-add-contract-expiration')}
                    </Alert>
                )}
                {renderEmptyState()}
                <BaseShipperContractLanesTable
                    tableName="shipper-contract-lanes"
                    rows={shipperContractLanesTableRows}
                    onSelectRow={handleOpenShipperContractLaneDetails}
                    isLoading={requestStatus?.loading}
                />
            </ListPageLayout>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <SideBar isShow={isShowFilters} onClose={hideFilters}>
                {(onClose) => (
                    <FiltersSidebarContent
                        onClose={onClose}
                        queryFilters={queryFilters}
                        setQueryFilters={handleSetQueryFilters}
                    />
                )}
            </SideBar>
        </>
    );
});

export default BaseShipperContractLanesLayout;
