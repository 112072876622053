import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import { AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';

const asyncValidate = (requestError: RequestStatusT): AsyncFormErrorsT => {
    if (!requestError.error) {
        return {};
    }

    if (
        checkIsTranziitApiRequestError(requestError.error) &&
        requestError?.error?.subType === TranziitApiRequestErrorSubTypeEnum.notFoundUser
    ) {
        return {
            [FieldsEnum.email]: AuthErrorTypeEnum.userNotFound,
        };
    }

    if (requestError?.error) {
        return {
            [FieldsEnum.email]: AuthErrorTypeEnum.unknown,
        };
    }

    return {};
};

export default asyncValidate;
