import { put, select, takeEvery } from 'redux-saga/effects';
import {
    addMemberBegin,
    addMemberError,
    addMemberSuccess,
    deleteMemberBegin,
    deleteMemberError,
    deleteMemberSuccess,
    fetchContactDetails,
    fetchContactDetailsBegin,
    fetchContactDetailsError,
    fetchContactDetailsSuccess,
    fetchContactsBegin,
    fetchContactsError,
    fetchContactsSuccess,
    updateMemberBegin,
    updateMemberError,
    updateMemberSuccess,
} from './actions';

import {
    ADD_CONTACT_REQUEST,
    AddContactActionT,
    CANCEL_INVITE,
    CancelUserActionT,
    CHANGE_USER_ROLE,
    CHANGE_USER_ROLE_WITH_SUCCESSOR,
    ChangeUserRoleActionT,
    ChangeUserRoleWithSuccessorActionT,
    DELETE_CONTACT,
    DELETE_USER,
    DELETE_USER_WITH_SUCCESSOR,
    DeleteContactActionT,
    DeleteUserActionT,
    DeleteUserWithSuccessorActionT,
    FETCH_CONTACT_DETAILS_BY_USER_ID,
    FETCH_CONTACT_DETAILS_REQUEST,
    FETCH_CONTACTS_REQUEST,
    FetchContactDetailsActionT,
    FetchContactDetailsByUserIdActionT,
    FetchContactsActionT,
    INVITE_USER_REQUEST,
    InviteUserActionT,
    MAKE_MAIN_CONTACT,
    MakeMainContactActionT,
    RESTORE_USER,
    UPDATE_CONTACT,
    UpdateContactActionT,
} from './types';
import { selectContactById, selectContactIdByUserId, selectFetchContactsRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsDefaultCompanyId } from 'common/utils';
import { ApiCompanyContactRequestT } from 'common/utils/api/models';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { CompanyContactT } from 'common/store/members/models';
import { prepareCompanyContactDetails } from 'common/store/members/utils';
import { membersRefreshChannel } from './channels';
import { partnerDetailsRefreshChannel } from 'broker-admin/store/partner/details/channels';

function* fetchContactsSaga(action: FetchContactsActionT): WrapGeneratorT<void> {
    const { companyId, options } = action;

    const requestStatus: ReReturnT<typeof selectFetchContactsRequest> = yield select(
        selectFetchContactsRequest(companyId),
    );
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchContactsBegin(companyId));

    let response: ReturnApiT<
        typeof commonTranziitApi.fetchCompanyContacts | typeof brokerTranziitApi.fetchCompanyContacts
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.fetchCompanyContacts();
    } else {
        response = yield brokerTranziitApi.fetchCompanyContacts(companyId);
    }
    const [error, companyContacts] = response;

    if (error) {
        yield put(fetchContactsError(error, companyId));
    } else {
        yield put(fetchContactsSuccess(companyContacts || [], companyId));
    }
}

function* inviteUserSaga(action: InviteUserActionT): WrapGeneratorT<void> {
    const { newUser, companyId } = action;

    yield put(addMemberBegin(companyId));

    let response: ReturnApiT<
        typeof commonTranziitApi.addUserToCurrentCompany | typeof brokerTranziitApi.addUserToPartner
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.addUserToCurrentCompany(newUser);
    } else {
        response = yield brokerTranziitApi.addUserToPartner(newUser, companyId);
    }
    const [error] = response;

    if (error) {
        yield put(addMemberError(error, companyId));
    } else {
        yield put(addMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.emailInvited,
                    data: {
                        email: newUser.email,
                    },
                }),
            ),
        );
    }
}

function* addContactSaga(action: AddContactActionT): WrapGeneratorT<void> {
    const { newContact, companyId } = action;

    yield put(addMemberBegin(companyId));

    let response: ReturnApiT<
        typeof commonTranziitApi.addContactToCurrentCompany | typeof brokerTranziitApi.addContactToPartner
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.addContactToCurrentCompany(newContact);
    } else {
        response = yield brokerTranziitApi.addContactToPartner({ companyId, ...newContact });
    }
    const [error] = response;

    if (error) {
        yield put(addMemberError(error, companyId));
    } else {
        yield put(addMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.contactAdded,
                    data: {
                        fullName: `${newContact.name || ''} ${newContact.surname || ''}`,
                    },
                }),
            ),
        );
    }
}

function* getContactById(companyId: CompanyIdT, contactId: ContactIdT): WrapGeneratorT<CompanyContactT | null> {
    const contactById: ReReturnT<typeof selectContactById> = yield select(selectContactById(companyId));

    return contactById[contactId] || null;
}

function* getContactByUserId(companyId: CompanyIdT, userId: UserIdT): WrapGeneratorT<CompanyContactT | null> {
    const contactIdByUserId: ReReturnT<typeof selectContactIdByUserId> = yield select(
        selectContactIdByUserId(companyId),
    );
    const contactById: ReReturnT<typeof selectContactById> = yield select(selectContactById(companyId));

    const contactId = contactIdByUserId?.[userId];
    return contactById?.[contactId] || null;
}

function* deleteContactSaga(action: DeleteContactActionT): WrapGeneratorT<void> {
    const { companyId, contactId } = action;

    yield put(deleteMemberBegin(companyId));

    let result: ReturnApiT<typeof commonTranziitApi.deleteContact | typeof brokerTranziitApi.deleteContact> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.deleteContact(contactId);
    } else {
        result = yield brokerTranziitApi.deleteContact(contactId);
    }
    if (!result) {
        return;
    }

    const [error] = result;

    if (error) {
        yield put(deleteMemberError(error, companyId));
    } else {
        yield put(deleteMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        const contact = yield* getContactById(companyId, contactId);
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.contactDeleted,
                    data: {
                        fullName: contact?.fullName || '',
                    },
                }),
            ),
        );
    }
}

function* deleteUserSaga(action: DeleteUserActionT): WrapGeneratorT<void> {
    const { companyId, userId } = action;

    yield put(deleteMemberBegin(companyId));

    let result: ReturnApiT<typeof commonTranziitApi.deleteUser | typeof brokerTranziitApi.deleteUser> | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.deleteUser(userId);
    } else {
        result = yield brokerTranziitApi.deleteUser(userId);
    }
    if (!result) {
        return;
    }

    const [error] = result;

    if (error) {
        yield put(deleteMemberError(error, companyId));
    } else {
        yield put(deleteMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        const contact = yield* getContactByUserId(companyId, userId);
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.userDeactivated,
                    data: {
                        fullName: contact?.fullName || '',
                    },
                }),
            ),
        );
    }
}

function* deleteUserWithSuccessorSaga(action: DeleteUserWithSuccessorActionT): WrapGeneratorT<void> {
    const { companyId, userId, successorUserId, successorRole } = action;

    yield put(deleteMemberBegin(companyId));

    const successorResult: ReturnApiT<typeof brokerTranziitApi.setSuccessor> = yield brokerTranziitApi.setSuccessor(
        userId,
        successorUserId,
        successorRole,
    );
    if (!successorResult) {
        return;
    }

    const [successorError] = successorResult || [];
    if (successorError) {
        yield put(deleteMemberError(successorError, companyId));
        return;
    }

    const result: ReturnApiT<typeof brokerTranziitApi.deleteUser> = yield brokerTranziitApi.deleteUser(userId);
    if (!result) {
        return;
    }

    const [error] = result || [];
    if (error) {
        yield put(deleteMemberError(error, companyId));
        return;
    }

    yield put(deleteMemberSuccess(companyId));

    membersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});

    const contact = yield* getContactByUserId(companyId, userId);
    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.userDeactivated,
                data: {
                    fullName: contact?.fullName || '',
                },
            }),
        ),
    );
}

function* cancelUserInviteSaga(action: CancelUserActionT): WrapGeneratorT<void> {
    const { companyId, userId } = action;

    yield put(updateMemberBegin(companyId));

    let result: ReturnApiT<
        typeof commonTranziitApi.cancelUserInvite | typeof brokerTranziitApi.cancelUserInvite
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.cancelUserInvite(userId);
    } else {
        result = yield brokerTranziitApi.cancelUserInvite(userId);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateMemberError(error, companyId));
    } else {
        yield put(updateMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        const contact = yield* getContactByUserId(companyId, userId);
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.inviteCanceled,
                    data: {
                        email: contact?.email || '',
                    },
                }),
            ),
        );
    }
}
function* restoreUserSaga(action: CancelUserActionT): WrapGeneratorT<void> {
    const { companyId, userId } = action;

    yield put(updateMemberBegin(companyId));

    let result: ReturnApiT<typeof commonTranziitApi.restoreUser | typeof brokerTranziitApi.restoreUser> | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.restoreUser(userId);
    } else {
        result = yield brokerTranziitApi.restoreUser(userId);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateMemberError(error, companyId));
    } else {
        yield put(updateMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        const contact = yield* getContactByUserId(companyId, userId);
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.userRestored,
                    data: {
                        fullName: contact?.fullName || '',
                    },
                }),
            ),
        );
    }
}

function* changeUserRoleSaga(action: ChangeUserRoleActionT): WrapGeneratorT<void> {
    const { companyId, userId, role } = action;

    yield put(updateMemberBegin(companyId));

    let result: ReturnApiT<typeof commonTranziitApi.assignUserRole | typeof brokerTranziitApi.assignUserRole> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.assignUserRole(userId, role);
    } else {
        result = yield brokerTranziitApi.assignUserRole(userId, role);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateMemberError(error, companyId));
    } else {
        yield put(updateMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        const contact = yield* getContactByUserId(companyId, userId);

        if (contact?.id) {
            yield put(fetchContactDetails(contact.id, companyId));
        }

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.userRoleChanged,
                    data: {
                        fullName: contact?.fullName || '',
                        newRole: role,
                    },
                }),
            ),
        );
    }
}

function* changeUserRoleWithSuccessorSaga(action: ChangeUserRoleWithSuccessorActionT): WrapGeneratorT<void> {
    const { companyId, userId, role, successorUserId, successorRole } = action;

    yield put(updateMemberBegin(companyId));

    const successorResult: ReturnApiT<typeof brokerTranziitApi.setSuccessor> = yield brokerTranziitApi.setSuccessor(
        userId,
        successorUserId,
        successorRole,
    );
    if (!successorResult) {
        return;
    }
    const [successorError] = successorResult || [];
    if (successorError) {
        yield put(updateMemberError(successorError, companyId));
        return;
    }

    const result: ReturnApiT<typeof brokerTranziitApi.assignUserRole> = yield brokerTranziitApi.assignUserRole(
        userId,
        role,
    );
    const [error] = result || [];
    if (error) {
        yield put(updateMemberError(error, companyId));
        return;
    }

    yield put(updateMemberSuccess(companyId));

    membersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});

    const contact = yield* getContactByUserId(companyId, userId);

    if (contact?.id) {
        yield put(fetchContactDetails(contact.id, companyId));
    }

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.userRoleChanged,
                data: {
                    fullName: contact?.fullName || '',
                    newRole: role,
                },
            }),
        ),
    );
}

function* updateContactSaga(action: UpdateContactActionT): WrapGeneratorT<void> {
    const { companyId, contactId, contactChanges } = action;

    yield put(updateMemberBegin(companyId));

    let result: ReturnApiT<typeof commonTranziitApi.updateContact | typeof brokerTranziitApi.updateContact> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield commonTranziitApi.updateContact(contactId, contactChanges);
    } else {
        result = yield brokerTranziitApi.updateContact(contactId, contactChanges);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateMemberError(error, companyId));
    } else {
        yield put(updateMemberSuccess(companyId));

        membersRefreshChannel.emit({});
        partnerDetailsRefreshChannel.emit({});

        yield put(fetchContactDetails(contactId, companyId));

        const contact = yield* getContactById(companyId, contactId);
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.contactEdited,
                    data: {
                        fullName: contact?.fullName || '',
                    },
                }),
            ),
        );
    }
}

function* makeMainContactSaga(action: MakeMainContactActionT): WrapGeneratorT<void> {
    const { companyId, contactId } = action;

    yield put(updateMemberBegin(companyId));

    let detailsResponse: ReturnApiT<
        typeof commonTranziitApi.fetchContactDetails | typeof brokerTranziitApi.fetchContactDetails
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        detailsResponse = yield commonTranziitApi.fetchContactDetails(contactId);
    } else {
        detailsResponse = yield brokerTranziitApi.fetchContactDetails(contactId);
    }
    const [detailsError, details] = detailsResponse;

    if (detailsError) {
        yield put(updateMemberError(detailsError, companyId));
        return;
    }

    if (!details) {
        yield put(updateMemberError(new Error('empty details'), companyId));
        return;
    }

    const contactDetailsChanges: ApiCompanyContactRequestT = {
        ...details,
        main: true,
    };

    let updateResponse: ReturnApiT<typeof commonTranziitApi.updateContact | typeof brokerTranziitApi.updateContact>;
    if (checkIsDefaultCompanyId(companyId)) {
        updateResponse = yield commonTranziitApi.updateContact(contactId, contactDetailsChanges);
    } else {
        updateResponse = yield brokerTranziitApi.updateContact(contactId, contactDetailsChanges);
    }
    const [updateError] = updateResponse;

    if (updateError) {
        yield put(updateMemberError(updateError, companyId));
        return;
    }

    yield put(updateMemberSuccess(companyId));

    membersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});

    yield put(fetchContactDetails(contactId, companyId));

    const contact = yield* getContactById(companyId, contactId);
    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.mainContactChanged,
                data: {
                    fullName: contact?.fullName || '',
                },
            }),
        ),
    );
}

function* fetchContactDetailsSaga(action: FetchContactDetailsActionT): WrapGeneratorT<void> {
    const { companyId, contactId } = action;

    yield put(fetchContactDetailsBegin(companyId));

    let response: ReturnApiT<
        typeof commonTranziitApi.fetchContactDetails | typeof brokerTranziitApi.fetchContactDetails
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.fetchContactDetails(contactId);
    } else {
        response = yield brokerTranziitApi.fetchContactDetails(contactId);
    }
    const [error, details] = response;

    if (error) {
        yield put(fetchContactDetailsError(error, companyId));
    } else {
        const preparedDetails = prepareCompanyContactDetails(details);
        yield put(fetchContactDetailsSuccess(contactId, preparedDetails, companyId));
    }
}

function* fetchContactDetailsByUserIdSaga(action: FetchContactDetailsByUserIdActionT): WrapGeneratorT<void> {
    const { companyId, userId } = action;

    yield put(fetchContactDetailsBegin(companyId));

    let response: ReturnApiT<
        typeof commonTranziitApi.fetchContactDetailsByUserId | typeof brokerTranziitApi.fetchContactDetailsByUserId
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.fetchContactDetailsByUserId(userId);
    } else {
        response = yield brokerTranziitApi.fetchContactDetailsByUserId(userId);
    }
    const [error, details] = response;

    if (error) {
        yield put(fetchContactDetailsError(error, companyId));
        return;
    }

    if (details?.id) {
        const preparedDetails = prepareCompanyContactDetails(details);
        yield put(fetchContactDetailsSuccess(details.id, preparedDetails, companyId));
    }
}

function* companyMembersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_CONTACTS_REQUEST, fetchContactsSaga);
    yield takeEvery(INVITE_USER_REQUEST, inviteUserSaga);
    yield takeEvery(ADD_CONTACT_REQUEST, addContactSaga);
    yield takeEvery(DELETE_CONTACT, deleteContactSaga);
    yield takeEvery(DELETE_USER, deleteUserSaga);
    yield takeEvery(DELETE_USER_WITH_SUCCESSOR, deleteUserWithSuccessorSaga);
    yield takeEvery(CANCEL_INVITE, cancelUserInviteSaga);
    yield takeEvery(RESTORE_USER, restoreUserSaga);
    yield takeEvery(CHANGE_USER_ROLE, changeUserRoleSaga);
    yield takeEvery(CHANGE_USER_ROLE_WITH_SUCCESSOR, changeUserRoleWithSuccessorSaga);
    yield takeEvery(MAKE_MAIN_CONTACT, makeMainContactSaga);
    yield takeEvery(UPDATE_CONTACT, updateContactSaga);
    yield takeEvery(FETCH_CONTACT_DETAILS_REQUEST, fetchContactDetailsSaga);
    yield takeEvery(FETCH_CONTACT_DETAILS_BY_USER_ID, fetchContactDetailsByUserIdSaga);
}

export default companyMembersSaga;
