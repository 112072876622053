import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ContractNameCell.scss';
import ContractName from 'common/components/ContractName/ContractName';

type PropsT = {
    name: string;
};

const cx = classNames.bind(styles);

const ContractNameCell: React.FC<PropsT> = React.memo((props) => {
    const { name } = props;

    return (
        <div className={cx('name')}>
            <ContractName name={name} />
        </div>
    );
});

export default ContractNameCell;
