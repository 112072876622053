import { OrderDetailsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_ORDER_REQUEST = 'common/order-details/FETCH_ORDER_REQUEST';
export const FETCH_ORDER_REQUEST_BEGIN = 'common/order-details/FETCH_ORDER_REQUEST_BEGIN';
export const FETCH_ORDER_REQUEST_ERROR = 'common/order-details/FETCH_ORDER_REQUEST_ERROR';
export const FETCH_ORDER_REQUEST_SUCCESS = 'common/order-details/FETCH_ORDER_REQUEST_SUCCESS';

export type OrderDetailsStateT = {
    order: OrderDetailsT | null;
    orderRequestStatus: RequestStatusT;
};

export type OrderDetailsByIdStateT = Record<OrderIdT, OrderDetailsStateT>;

export type FetchOrderRequestActionT = {
    type: typeof FETCH_ORDER_REQUEST;
    id: OrderIdT;
    options?: FetchOptionsT;
};

export type FetchOrderRequestBeginActionT = {
    type: typeof FETCH_ORDER_REQUEST_BEGIN;
    id: OrderIdT;
};

export type FetchOrderRequestErrorActionT = {
    type: typeof FETCH_ORDER_REQUEST_ERROR;
    error: Error;
    id: OrderIdT;
};

export type FetchOrderRequestSuccessActionT = {
    type: typeof FETCH_ORDER_REQUEST_SUCCESS;
    order: OrderDetailsT | null;
    id: OrderIdT;
};

export type OrderDetailsActionT =
    | FetchOrderRequestActionT
    | FetchOrderRequestBeginActionT
    | FetchOrderRequestErrorActionT
    | FetchOrderRequestSuccessActionT;
