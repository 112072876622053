import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './CityName.scss';

const cx = classNames.bind(styles);

type PropsT = {
    cityName: React.ReactNode;
};

const CityName: React.FC<PropsT> = React.memo((props) => {
    const { cityName } = props;

    return <span className={cx('city')}>{cityName}</span>;
});

export default CityName;
