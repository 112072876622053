import { ApiCountryT } from 'common/utils/api/models';

type ComparatorT = (a: string, b: string) => number;

export const byAlphabetASC: ComparatorT = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

export const getCountryCompByName = (comp: ComparatorT) => (countryA: ApiCountryT, countryB: ApiCountryT) => {
    return comp(countryA.userLangDisplayName, countryB.userLangDisplayName);
};
