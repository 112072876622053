import React from 'react';
import {
    AnySidebarDataT,
    CommonSidebarDataT,
    CommonSidebarMetaT,
    SidebarQueryKeysEnum,
    SidebarDataWithMetaT,
} from 'common/layouts/SideBars/models';
import { useQueryParam } from 'use-query-params';
import { JSONArrayParam } from 'common/utils/query';

const TAIL_LIMIT = 5;

export const pushSidebarsArray = <T,>(array: Array<T>, data: T): Array<T> => {
    return [...array, data].slice(-1 * TAIL_LIMIT);
};

export const createOpenSidebarHook = <T extends AnySidebarDataT>(queryKey: SidebarQueryKeysEnum) => {
    return () => {
        const [sidebarsData, setSidebarsData] = useQueryParam<Array<SidebarDataWithMetaT<T>>>(queryKey, JSONArrayParam);

        const openHandler = React.useCallback(
            (data: T, meta?: CommonSidebarMetaT): void => {
                const newSidebarsData = pushSidebarsArray(sidebarsData, {
                    ...data,
                    ...meta,
                });

                setSidebarsData(newSidebarsData);
            },
            [sidebarsData],
        );

        return openHandler;
    };
};

export const useOpenedSidebar = <T extends AnySidebarDataT>(type: T['type']): SidebarDataWithMetaT<T> | null => {
    const [leftSidebarsData] = useQueryParam<Array<SidebarDataWithMetaT<T>>>(
        SidebarQueryKeysEnum.leftSidebar,
        JSONArrayParam,
    );
    const [rightSidebarsData] = useQueryParam<Array<SidebarDataWithMetaT<T>>>(
        SidebarQueryKeysEnum.rightSidebar,
        JSONArrayParam,
    );

    const openedSidebarData = React.useMemo(() => {
        return (
            leftSidebarsData.find((data) => data.type === type) ||
            rightSidebarsData.find((data) => data.type === type) ||
            null
        );
    }, [leftSidebarsData, rightSidebarsData, type]);

    return openedSidebarData;
};

export const useCheckOpenedSidebar = <T extends AnySidebarDataT>(type: T['type']): boolean => {
    const openedSidebar = useOpenedSidebar<T>(type);

    return !!openedSidebar;
};

export const useOpenLeftSidebar = createOpenSidebarHook<CommonSidebarDataT>(SidebarQueryKeysEnum.leftSidebar);

export const useOpenRightSidebar = createOpenSidebarHook<CommonSidebarDataT>(SidebarQueryKeysEnum.rightSidebar);
