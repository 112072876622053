import * as React from 'react';

type PropsT = {
    model: string | null | undefined;
    plateNumber: string | null | undefined;
};

const AssetLabelFormatter: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber } = props;

    return <span>{[plateNumber, model].filter(Boolean).join(' / ')}</span>;
});

export default AssetLabelFormatter;
