import { logWarning } from 'common/utils/logger';

const pickFile = (accept: string, onPick: (file: File) => void) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = accept;
    fileInput.click();
    fileInput.onchange = () => {
        const file = fileInput.files?.[0];
        if (!file) {
            logWarning('empty file!');
            return;
        }

        onPick(file);
    };
};

export { pickFile };
