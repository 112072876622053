import React from 'react';
import styles from './DocumentSummary.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { StyleGuideColorsEnum } from 'common/constants';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    title: React.ReactNode;
    tooltipContentNode: React.ReactNode;
    id: DocumentIdT | CarrierContractIdT | null;
    onDownloadFile: (id: DocumentIdT | CarrierContractIdT) => void;
    canDownload: boolean;
};

const DocumentSummary: React.FC<PropsT> = (props) => {
    const { tooltipContentNode, className, title, canDownload, id, onDownloadFile } = props;

    return (
        <div className={cs(cx('info'), className)}>
            {id && canDownload && (
                <Tooltip
                    className={cx('download')}
                    position={TooltipPositionEnum.centerRight}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={80}>
                            {tooltipContentNode}
                        </TooltipContent>
                    }
                >
                    {(isShow) => (
                        <TransparentTrigger
                            isPressed={isShow}
                            onClick={() => {
                                onDownloadFile(id);
                            }}
                            leftIcon={<PdfFileIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                        />
                    )}
                </Tooltip>
            )}
            <div className={cx('title')}>{title}</div>
        </div>
    );
};

export default DocumentSummary;
