import React from 'react';

import { CompanyContactT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import Modal from 'common/components/Modal/Modal';
import { useSelector } from 'react-redux';
import { selectContactById, selectUpdateMemberRequest } from 'common/store/members/selectors';
import { logWarning } from 'common/utils/logger';

export type CancelInviteConfirmationDataT = {
    contactId: CompanyContactT['id'];
    partnerId: CompanyIdT;
};

export type CancelInviteConfirmationPropsT = {
    data: CancelInviteConfirmationDataT | null;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (partnerId: PartnerIdT, userId: UserIdT) => void;
};

const TEST_SELECTOR = 'cancel-user-invite';

const CancelInviteConfirmation: React.FC<CancelInviteConfirmationPropsT> = React.memo((props) => {
    const { data, onClose, onCancel, onConfirm } = props;

    const { t } = useTranslation();

    const contactById = useSelector(selectContactById(data?.partnerId || null));
    const updateMemberRequest = useSelector(selectUpdateMemberRequest(data?.partnerId || null));

    if (!data?.partnerId || !data?.contactId) {
        return null;
    }

    const contact = contactById[data.contactId];

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:team-members.dialogs.cancel-user-invite.keep'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: updateMemberRequest.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: updateMemberRequest.loading,
                            onClick: () => {
                                if (contact?.userId) {
                                    onConfirm(data.partnerId, contact.userId);
                                } else {
                                    logWarning('empty user id');
                                }
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('common:team-members.dialogs.cancel-user-invite.title', {
                        email: contact?.email,
                    })}
                    message={t('common:team-members.dialogs.cancel-user-invite.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default CancelInviteConfirmation;
