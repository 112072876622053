import * as React from 'react';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiShipperContractStatusT, ShipperContractStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';

type PropsT = {
    ordersLeft: number | null | undefined;
    maxNumberOfOrders: number | null | undefined;
    status: ApiShipperContractStatusT | null | undefined;
    isSymmetrical?: boolean;
};

const THEME_BY_STATUS: Record<ApiShipperContractStatusT, PillLabelThemeEnum> = {
    [ShipperContractStatusEnum.active]: PillLabelThemeEnum.charcoal,
    [ShipperContractStatusEnum.expired]: PillLabelThemeEnum.grey,
    [ShipperContractStatusEnum.revoked]: PillLabelThemeEnum.grey,
};

const DEFAULT_THEME = PillLabelThemeEnum.grey;

const ShipperContractLaneOrderCounterPill: React.FC<PropsT> = React.memo((props) => {
    const { ordersLeft, maxNumberOfOrders, status, isSymmetrical } = props;

    const { t } = useTranslation();

    const isShowNoLanesLabel = ordersLeft === null && status === ShipperContractStatusEnum.active;
    if (isShowNoLanesLabel) {
        return (
            <PillLabel isSymmetrical={isSymmetrical} theme={PillLabelThemeEnum.grey}>
                {t('common:shipper-contracts.table.no-lanes')}
            </PillLabel>
        );
    }

    const theme = status ? THEME_BY_STATUS[status] : DEFAULT_THEME;

    if (isNumber(ordersLeft) && isNumber(maxNumberOfOrders)) {
        return (
            <PillLabel isSymmetrical={isSymmetrical} theme={theme}>{`${ordersLeft}/${maxNumberOfOrders}`}</PillLabel>
        );
    }

    return null;
});

export default ShipperContractLaneOrderCounterPill;
