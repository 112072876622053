import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { CompanyChangesT, CompanyT } from 'common/store/company/models';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';

export type ConfirmChangeAddressConfirmationDataT = {
    companyChanges: CompanyChangesT | null;
    hasVerifiedLegalAddressChanges: boolean;
    hasVerifiedBillingAddressChanges: boolean;
};

type PropsT = {
    company: CompanyT | null;
    data: ConfirmChangeAddressConfirmationDataT | null;
    requestStatus: RequestStatusT;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (company: CompanyT, companyChanges: CompanyChangesT) => void;
};

const TEST_SELECTOR = 'address-confirmation';

const ChangeVerifiedAddressConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { requestStatus, company, data, onClose, onCancel, onConfirm } = props;
    const { t } = useTranslation();

    if (!company || !data) {
        return null;
    }

    const { companyChanges, hasVerifiedBillingAddressChanges, hasVerifiedLegalAddressChanges } = data;
    if (!companyChanges) {
        return null;
    }

    let message: React.ReactNode = null;
    if (hasVerifiedBillingAddressChanges) {
        message = t('common:profile-page.veified-address-change-confirmation.description.billing-address');
    }
    if (hasVerifiedLegalAddressChanges) {
        message = t('common:profile-page.veified-address-change-confirmation.description.legal-address');
    }
    if (hasVerifiedBillingAddressChanges && hasVerifiedLegalAddressChanges) {
        message = t('common:profile-page.veified-address-change-confirmation.description.legal-and-billing-address');
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.change'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: requestStatus.loading,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onConfirm(company, companyChanges);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('common:profile-page.veified-address-change-confirmation.title')}
                    message={message}
                />
            </ModalContent>
        </Modal>
    );
});

export default ChangeVerifiedAddressConfirmation;
