import * as React from 'react';
import { memo } from 'react';
import classNames from 'classnames/bind';
import styles from './ControlLoaderLabel.scss';
import cs from 'classnames';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

export type PropsT = {
    className?: string;
    isInvert?: boolean;
};

const cx = classNames.bind(styles);

const ControlLoaderLabel: React.FC<PropsT> = memo((props) => {
    const { className, children, isInvert } = props;

    return (
        <div
            className={cs(
                cx('loader', {
                    'loader--is-invert': isInvert,
                }),
                className,
            )}
        >
            <ControlLoaderIcon
                opacity={1}
                fillColor={isInvert ? StyleGuideColorsEnum.light : StyleGuideColorsEnum.slate}
                size={DEFAULT_ICON_SIZE}
            />
            <span
                className={cx('loader__text', {
                    'loader__text--is-invert': isInvert,
                })}
            >
                {children}
            </span>
        </div>
    );
});

export default ControlLoaderLabel;
