import * as React from 'react';

type PropsT = {};

const HiddenSubmitButtonForKeyboardEnter: React.FC<PropsT> = React.memo((props) => {
    return (
        <button type="submit" hidden>
            submit
        </button>
    );
});

export default HiddenSubmitButtonForKeyboardEnter;
