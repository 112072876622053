import * as React from 'react';
import { DependencyList } from 'react';

function useOnlyChangesEffect(callback: () => void, dependencyList: DependencyList) {
    const firstUpdate = React.useRef(true);

    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        callback();
    }, dependencyList);
}

export default useOnlyChangesEffect;
