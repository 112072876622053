import * as React from 'react';
import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import scrollIntoView from 'scroll-into-view';
import { MS_IN_SEC } from 'common/utils/time';

type PropsT = {
    submitCount: number;
    errors: Record<string, any>;
};

const FOCUS_TIME = MS_IN_SEC / 3;

const ScrollToFirstError: React.FC<PropsT> = React.memo((props) => {
    const { submitCount, errors } = props;

    const ref = React.createRef<HTMLDivElement>();

    useEffect(() => {
        const hasErrors = !isEmpty(errors);
        if (!hasErrors) {
            return;
        }

        if (!ref.current) {
            return;
        }

        const formNode = ref.current.closest('form');
        if (!formNode) {
            return;
        }

        const fieldErrorNodes = formNode.querySelectorAll('[data-error-message]');
        const firstErrorFieldNode = fieldErrorNodes?.[0];
        if (!firstErrorFieldNode) {
            return;
        }

        scrollIntoView(firstErrorFieldNode as HTMLElement, {
            time: FOCUS_TIME,
            align: {
                top: 0.25,
            },
        });
    }, [submitCount]);

    return <div ref={ref} hidden />;
});

export default ScrollToFirstError;
