type AnyValuesT = any;

class ValuesStorage {
    persistStorageDateKey: string;

    constructor(formName: string) {
        this.persistStorageDateKey = formName;
    }

    saveValues = (values: AnyValuesT): void => {
        try {
            const { persistStorageDateKey } = this;
            localStorage.setItem(persistStorageDateKey, JSON.stringify(values));
        } catch (error) {
            console.error(error);
        }
    };

    getValues = (): AnyValuesT | null => {
        try {
            const { persistStorageDateKey } = this;

            const rawValues = localStorage.getItem(persistStorageDateKey);
            if (!rawValues) {
                return null;
            }

            const parsedValues = JSON.parse(rawValues);

            return parsedValues;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    reset = (): void => {
        const { persistStorageDateKey } = this;
        localStorage.removeItem(persistStorageDateKey);
    };
}

export default ValuesStorage;
