import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AttentionTextFormatter.scss';

type PropsT = {
    isActive: boolean;
};

const cx = classNames.bind(styles);

const AttentionTextFormatter: React.FC<PropsT> = React.memo((props) => {
    const { isActive, children } = props;

    return <span className={isActive ? cx('warning') : ''}>{children}</span>;
});

export default AttentionTextFormatter;
