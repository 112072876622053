import { SharableStateT } from 'common/store/models';
import { initialRoutingGeometryState } from './slice';
import { RoutingGeometrySliceT } from './types';

export const selectRoutingGeometryState =
    (polylineId: PolylineIdT | null | undefined) =>
    (state: SharableStateT): RoutingGeometrySliceT => {
        if (!polylineId) {
            return initialRoutingGeometryState;
        }

        return state.routingGeometry[polylineId] || initialRoutingGeometryState;
    };
