import * as React from 'react';

import { OrderT } from 'common/store/orders/models';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';

type PropsT = {
    order: OrderT;
    onOpenUser: (userId: UserIdT) => void;
};

const CreatedCell: React.FC<PropsT> = React.memo((props) => {
    const { order, onOpenUser } = props;

    return (
        <EventCell
            userFullName={order.createdByName}
            userId={order.createdById}
            date={order.createdDate}
            openUserDetails={onOpenUser}
            byBroker={order.createdByBroker}
        />
    );
});

export default CreatedCell;
