import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusChangedCard.scss';
import { Trans } from 'react-i18next';
import NotificationSubjectType from '../entities/NotificationSubjectType/NotificationSubjectType';
import { ApiNotificationSubjectTypeT } from 'common/utils/api/models';
import CityName from '../entities/CityName/CityName';
import SubjectLink from '../entities/SubjectLink/SubjectLink';

const cx = classNames.bind(styles);

export type StatusChangedNodePropsT = {
    subjectType: ApiNotificationSubjectTypeT | null | undefined;
    subjectNumber: React.ReactNode;
    originCity: React.ReactNode;
    destinationCity: React.ReactNode;
    actionType: ApiNotificationSubjectTypeT | null | undefined;
    actionStatus: React.ReactNode;
};

const ActionStatus: React.FC<{ actionStatus: React.ReactNode }> = ({ actionStatus }) => {
    return <span className={cx('status')}>{actionStatus}</span>;
};
const Action: React.FC<{ actionType: ApiNotificationSubjectTypeT | null | undefined }> = ({ actionType }) => (
    <b className={cx('action')}>
        <NotificationSubjectType isUppercase subjectType={actionType} />
    </b>
);

const StatusChangedCard: React.FC<StatusChangedNodePropsT> = React.memo((props) => {
    const { subjectType, subjectNumber, originCity, destinationCity, actionStatus, actionType } = props;

    return (
        <div className={cx('wrap')}>
            <div className={cx('message')}>
                <Trans
                    i18nKey="common:notifications.status-changed"
                    components={{
                        subjectType: <NotificationSubjectType isCapitalized subjectType={subjectType} />,
                        subjectLink: <SubjectLink number={subjectNumber} />,
                        cityFrom: <CityName cityName={originCity} />,
                        cityTo: <CityName cityName={destinationCity} />,
                    }}
                />
            </div>
            <div className={cx('description')}>
                <Trans
                    i18nKey="common:notifications.status-changed-2"
                    components={{
                        subjectType: <Action actionType={actionType} />,
                        status: <ActionStatus actionStatus={actionStatus} />,
                    }}
                />
            </div>
        </div>
    );
});

export default StatusChangedCard;
