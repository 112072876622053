import { FieldsEnum, FormValuesT } from './constants';
import moment from 'moment';
import { DocumentDetailsChangesT } from 'common/store/documents/models';
import { FAKE_NO_LIMIT_VALID_TILL_YEAR } from 'common/constants';

const VALID_TILL_FORMAT = 'YYYY-MM-DD';

const prepareApproveData = (
    formValues: FormValuesT,
    { isShowExpirationDate }: { isShowExpirationDate: boolean },
): DocumentDetailsChangesT | null => {
    const rawExpiration = formValues[FieldsEnum.expiration];

    if (!isShowExpirationDate) {
        return {
            validTill: moment().add(FAKE_NO_LIMIT_VALID_TILL_YEAR, 'year').format(VALID_TILL_FORMAT),
        };
    }

    if (!rawExpiration) {
        return null;
    }

    return {
        validTill: moment(rawExpiration).format(VALID_TILL_FORMAT),
    };
};

export default prepareApproveData;
