import keyBy from 'lodash/keyBy';
import requestStatus from 'common/utils/request-status';
import { PagedEntityT, PageResponseT, PageStateT, PaginationStateT } from './models';
import { checkIsSameQuery } from './utils';
import { isNonNil } from 'common/utils';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

const getPages = (prevPages: PageStateT[], pageNumber: number): PageStateT[] => {
    const pages = [];

    const count = Math.max(pageNumber + 1, prevPages.length);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < count; index++) {
        if (prevPages[index]) {
            pages[index] = prevPages[index];
        } else {
            pages[index] = {
                requestStatus: requestStatus.initial,
                ids: [],
            };
        }
    }

    return pages;
};

const followingPageSuccessReducer = <E extends PagedEntityT, Q>(
    prevPaginationState: PaginationStateT<E, Q>,
    pageNumber: number,
    query: Q,
    pageResponse: PageResponseT<E>,
): PaginationStateT<E, Q> => {
    const { content, totalPages, totalElements } = pageResponse;

    const entities = content || [];

    const ids = entities.map((entity) => entity.id).filter(isNonNil);

    const pages = getPages(prevPaginationState.pages, Math.max((totalPages || 0) - 1, 0));

    const byId = keyBy(entities, 'id');

    const newById: typeof prevPaginationState.byId = {};
    ids.forEach((id) => {
        if (!isEqual(byId[id], prevPaginationState.byId[id])) {
            newById[id] = byId[id];
        }
    });
    const hasDetailChanges = !isEmpty(newById);

    const prevIds = prevPaginationState.pages[pageNumber]?.ids;
    const hasIdsChanges = !isEqual(prevIds, ids);

    pages.splice(pageNumber, 1, {
        ...prevPaginationState.pages[pageNumber],
        requestStatus: requestStatus.ok,
        ids: hasIdsChanges || hasDetailChanges ? ids : prevIds,
    });

    return {
        query,
        total: {
            pageCount: totalPages || 0,
            elementCount: totalElements || 0,
        },
        pages,
        byId: hasDetailChanges
            ? {
                  ...prevPaginationState.byId,
                  ...newById,
              }
            : prevPaginationState.byId,
    };
};

const pageSuccessReducer = <T extends PagedEntityT, Q>(
    prevPaginationState: PaginationStateT<T, Q>,
    pageNumber: number,
    query: Q,
    pageResponse: PageResponseT<T> | null,
): PaginationStateT<T, Q> => {
    if (!pageResponse) {
        return prevPaginationState;
    }

    if (!checkIsSameQuery(query, prevPaginationState.query)) {
        return prevPaginationState;
    }

    return followingPageSuccessReducer<T, Q>(prevPaginationState, pageNumber, query, pageResponse);
};

const pageErrorReducer = <T extends PagedEntityT, Q>(
    prevPaginationState: PaginationStateT<T, Q>,
    pageNumber: number,
    query: Q,
    error: Error,
): PaginationStateT<T, Q> => {
    if (!checkIsSameQuery(query, prevPaginationState.query)) {
        return prevPaginationState;
    }

    const pages = getPages(prevPaginationState.pages, pageNumber);

    pages.splice(pageNumber, 1, {
        ...pages[pageNumber],
        requestStatus: requestStatus.setError(error),
    });

    return {
        ...prevPaginationState,
        pages,
    };
};

const pageBeginReducer = <E extends PagedEntityT, Q>(
    prevPaginationState: PaginationStateT<E, Q>,
    pageNumber: number,
    query: Q,
): PaginationStateT<E, Q> => {
    const pages = getPages(prevPaginationState.pages, pageNumber);

    pages.splice(pageNumber, 1, {
        ...pages[pageNumber],
        requestStatus: requestStatus.loading,
    });

    return {
        ...prevPaginationState,
        query,
        pages,
    };
};

const resetPagesCacheReducer = <E extends PagedEntityT, Q>(
    initialPaginationState: PaginationStateT<E, Q>,
    prevPaginationState: PaginationStateT<E, Q>,
    mutationListOptions: MutationListOptionsT,
): PaginationStateT<E, Q> => {
    const initialPages = [];

    const { savingPageNumber } = mutationListOptions;

    if (typeof savingPageNumber === 'number') {
        initialPages[savingPageNumber] = prevPaginationState.pages[savingPageNumber];
    }

    return {
        ...omit(prevPaginationState, ['total', 'pages', 'byId', 'query']),
        total: initialPaginationState.total,
        pages: initialPages,
        byId: prevPaginationState.byId,
        query: prevPaginationState.query,
    };
};

export { pageBeginReducer, pageSuccessReducer, pageErrorReducer, resetPagesCacheReducer };
