import * as React from 'react';

import RFQDetailsPageLayout from 'common/layouts/RFQDetailsPageLayout/RFQDetailsPageLayout';
import { urlFactory } from 'shipper/utils/urls';
import history from 'common/utils/history';
import { useOpenLeftSidebar } from 'shipper/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { logWarning } from 'common/utils/logger';

type PropsT = {};

const RFQDetailsPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const handleGoToBack = () => {
        const url = urlFactory.rfqList();
        history.push(url);
    };

    const openLeftSidebar = useOpenLeftSidebar();

    const handleGoToUserDetails = (userId: UserIdT | null) => {
        if (!userId || !partnerId || !partnerType) {
            logWarning('failed to open contact details in rfq details, empty userId or partnerId or partnerType');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.contact,
                partnerId,
                partnerType,
                userId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    return (
        <>
            <RFQDetailsPageLayout goToBack={handleGoToBack} goToUserDetails={handleGoToUserDetails} />
            <SideBars />
        </>
    );
});

export default RFQDetailsPage;
