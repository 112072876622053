import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { selectOrderCreationMs } from 'common/store/settings/selectors';
import { useSelector } from 'react-redux';
import { getMinutes } from 'common/utils/time';

type PropsT = {};

const ExpirationRFQAlertContent: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    const orderCreationMs = useSelector(selectOrderCreationMs);

    let formattedMinutes;
    if (orderCreationMs) {
        const minutes = getMinutes(orderCreationMs);

        formattedMinutes = minutes
            ? `${minutes} ${t('common:minutes', {
                  postProcess: 'interval',
                  count: minutes,
              })}`
            : null;
    }

    return (
        <Trans
            i18nKey="common:new-order-details.validate-messages.rfq-expiration"
            components={{
                contextLifetime: formattedMinutes,
            }}
        />
    );
});

export default ExpirationRFQAlertContent;
