import React from 'react';
import classNames from 'classnames/bind';

import styles from './PhoneNumberIcon.scss';
import { parsePhoneNumber } from 'react-phone-number-input';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

type PropsT = {
    phoneNumber?: string;
};

const PhoneNumberIcon: React.FC<PropsT> = (props) => {
    const { phoneNumber } = props;

    const [countryCode, setCountryCode] = React.useState<string | null>(null);

    React.useEffect(() => {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '');

        if (countryCode !== parsedPhoneNumber?.country) {
            setCountryCode(parsedPhoneNumber?.country || null);
        }
    }, [phoneNumber]);

    if (!countryCode) {
        return <div className={cx('empty-flag')} />;
    }

    return <FlagIcon countryCode={countryCode} />;
};

export default PhoneNumberIcon;
